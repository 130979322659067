import { Component, Input, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import { ChatTypes } from '@app/core/chat/types';
import { BoardChatSummary, isBoardDirectChatSummary } from '../../types';
import { ChatItemComponent } from '@app/screens/chat/components/chat-item/chat-item.component';
import { PuiIconModule, PuiInputModule, PuiScrollableModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { FilterChatListPipe } from '@app/screens/chat/components/chats-list/pipes/filter-chat-list.pipe';

@Component({
  selector: 'app-chats-list',
  templateUrl: './chats-list.component.html',
  styleUrls: ['./chats-list.component.scss'],
  standalone: true,
  host: { class: 'ui-app-chats-list' },
  imports: [
    ChatItemComponent,
    PuiTypographyModule,
    CdkScrollableModule,
    PuiScrollableModule,
    FormsModule,
    TranslocoModule,
    FilterChatListPipe,
    PuiInputModule,
    PuiIconModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatsListComponent {
  readonly ChatTypes = ChatTypes;

  // @ts-expect-error TS2564
  activeChats: BoardChatSummary[];

  // @ts-expect-error TS2564
  archivedChats: BoardChatSummary[];

  // @ts-expect-error TS2564
  @Input() chats: BoardChatSummary[];

  @Input()
  // @ts-expect-error TS2564
  selectedChatId: string;

  @Output()
  selectChat = new EventEmitter<{ chatId: string; workspaceId: number }>();

  // @ts-expect-error TS2564
  searchText: string;

  emitSelectChat(chat: BoardChatSummary): void {
    this.selectChat.emit({
      chatId: isBoardDirectChatSummary(chat) && chat.draft ? `${chat.userId}W${chat.workspaceId}` : `${chat.id}`,
      workspaceId: chat.workspaceId
    });
  }
}
