import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { QuizQuestionType } from '@app/core/quizzes/types';
import { clipboardPasteMatcher, keyboardBindings, toolbarDefaultModules } from '@app/modules/text-editor/modules';
import { ILabelledProgramOption } from '@app/screens/guide/guide-programs/types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-quiz-module',
  templateUrl: './quiz-module.component.html',
  styleUrls: ['../../../../../modules/guide-service-editor/common-styles/forms.scss', './quiz-module.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'quiz-module'
  }
})
export class QuizModuleComponent {
  @Input()
  // @ts-expect-error TS2564
  hideInstructor: boolean;

  @Input()
  // @ts-expect-error TS2564
  moduleForm: UntypedFormGroup;

  @Input()
  // @ts-expect-error TS2564
  moduleActivations: ILabelledProgramOption<string>[];

  @Output()
  addQuestion = new EventEmitter<QuizQuestionType>();

  @Output()
  addQuestionOption = new EventEmitter<{ questionIndex: number }>();

  @Output()
  removeQuestion = new EventEmitter<{ questionIndex: number }>();

  @Output()
  removeQuestionOption = new EventEmitter<{ questionIndex: number; optionIndex: number }>();

  @Output()
  titleChange = new EventEmitter<{ html: string }>();

  @Output()
  descriptionChange = new EventEmitter<{ html: string }>();

  @Output()
  loadQuiz = new EventEmitter<{ moduleId: number }>();

  readonly quillModules = {
    clipboard: clipboardPasteMatcher,
    keyboard: keyboardBindings,
    toolbar: toolbarDefaultModules
  };

  readonly QuizQuestionType = QuizQuestionType;

  showQuestionTypes = false;

  explanationsEnabled = false;

  get quizQuestions(): UntypedFormArray {
    // @ts-expect-error TS2531
    return this.moduleForm.get('quiz').get('questions') as UntypedFormArray;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  toggleQuestionTypes() {
    this.showQuestionTypes = !this.showQuestionTypes;
  }

  // @ts-expect-error TS7006
  getQuizQuestionOptions(questionIndex): UntypedFormArray {
    // @ts-expect-error TS2531
    const question = (this.moduleForm.get('quiz').get('questions') as UntypedFormArray).at(questionIndex);
    return question.get('options') as UntypedFormArray;
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line id-length
  handleQuestionOptionKeyUp(e, questionFormGroup, questionIndex, sourceOptionIndex): void {
    const { options } = questionFormGroup.value;

    if (sourceOptionIndex === options.length - 1) {
      this.addQuestionOption.emit({ questionIndex });
    }
  }

  removeQuestionAtIndex(questionIndex: number): void {
    this.moduleForm.markAsDirty();
    this.removeQuestion.emit({ questionIndex });
  }

  removeQuestionOptionAtIndex(questionIndex: number, optionIndex: number): void {
    this.moduleForm.markAsDirty();
    this.removeQuestionOption.emit({ questionIndex, optionIndex });
  }

  onLoadQuiz(): void {
    this.loadQuiz.emit({ moduleId: this.moduleForm.getRawValue().localId });
  }
}
