<div class="ui-selector-container ui-selector-origin" matAutocompleteOrigin #defaultOrigin="matAutocompleteOrigin">
  <input
    #input="matInput"
    #trigger="matAutocompleteTrigger"
    type="text"
    matInput
    [ngModel]="value"
    [placeholder]="placeholder"
    [matAutocompleteConnectedTo]="autocompleteConnectedTo || defaultOrigin"
    [matAutocomplete]="auto"
    (ngModelChange)="onTyped($event)"
    [disabled]="disabled"
    [matAutocompleteDisabled]="disabled" />

  <pui-icon
    class="selector-icon"
    *ngIf="!auto.isOpen && !disabled"
    (click)="click($event, trigger)"
    size="s"
    svgIcon="dropdown-arrow"></pui-icon>
</div>

<ui-autocomplete
  class="ui-autocomplete ui-selector-autocomplete mat-elevation-z0 {{ overlayPanelClassName }}"
  #auto="uiAutocomplete"
  [displayWith]="displayWith"
  (optionSelected)="onOptionSelected($event)"
  (opened)="opened.emit()"
  (closed)="onClosed(input)"
  disableRipple>
  <mat-option
    class="ui-option"
    *ngFor="let option of options"
    [value]="option.value"
    [ngClass]="option.classList"
    [disabled]="option.disabled"
    [id]="option.id">
    <ng-template
      *ngTemplateOutlet="optionTemplateRef || option.content || defaultOptionTemplateRef;
      context: {$implicit: option.value}"></ng-template>
  </mat-option>

  <!--  Option for empty list-->
  <mat-option class="ui-option empty-list" *ngIf="!options?.length && emptyListTemplateRef">
    <ng-template *ngTemplateOutlet="emptyListTemplateRef"></ng-template>
  </mat-option>
</ui-autocomplete>

<ng-template #defaultOptionTemplateRef let-option>
  <div class="option-default-wrapper">{{ option | displayVia : getOptionLabel }}</div>
</ng-template>
