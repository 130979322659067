import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SharedModule } from '@app/shared';
import { GuideServicesAndEventsRoutingModule } from './guide-services-and-events-routing.module';

import { EditLinkComponent } from './components/edit-link/edit-link.component';
import { GuideProgramItemComponent } from './components/guide-program-item/guide-program-item.component';
import { QuizCardComponent } from './components/quiz-card/quiz-card.component';
import { ServiceTemplateItemComponent } from './components/service-template-item/service-template-item.component';
import { ServicesAndEventsBoardComponent } from './components/services-and-events-board/services-and-events-board.component';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';
import { PuiButtonModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { ClientsSelectFeatureDirective } from '@app/features/clients-select-feature/clients-select-feature.directive';

@NgModule({
  imports: [
    CommonModule,
    GuideServicesAndEventsRoutingModule,
    NgbModule,
    NgScrollbarModule,
    SharedModule,
    PlatformStyleModule,
    PuiTypographyModule,
    PuiButtonModule,
    ClientsSelectFeatureDirective
  ],
  declarations: [
    EditLinkComponent,
    GuideProgramItemComponent,
    QuizCardComponent,
    ServiceTemplateItemComponent,
    ServicesAndEventsBoardComponent
  ]
})
export class GuideServicesAndEventsModule {}
