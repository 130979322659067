import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BrandingService } from '@app/core/branding/branding.service';
import { CurrencyService } from '@app/core/currency/currency.service';
import { IQualification } from '@app/shared/interfaces/qualification';
import { GlobalConfig } from '@cnf/types';
import { environment } from '@env/environment';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-guide-card',
  templateUrl: './guide-card.component.html',
  styleUrls: ['./guide-card.component.scss']
})
export class GuideCardComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  // @ts-expect-error TS7008
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _guide;

  currencySign;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set guide(value: any) {
    this._guide = value;
    this.visibleTags = value && value.tags ? value.tags.slice(0, 6) : [];
  }

  qualifications: IQualification[] = [];

  config = {
    hideProfileLocationLanguage: true,
    hideCoachReviews: true,
    showPriceStartsFrom: false
  };

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures, @typescript-eslint/no-explicit-any
  get guide(): any {
    return this._guide;
  }

  // @ts-expect-error TS7008
  visibleTags;
  GUIDES_LIST_ROUTE = `/${environment.guidesRoute}`;

  constructor(private _brandingService: BrandingService, public currency: CurrencyService) {
    this.currencySign = this.currency.defaultCurrencySign;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnInit() {
    this._brandingService.qualifications$.pipe(takeUntil(this.destroy$)).subscribe(qualifications => {
      this.qualifications = qualifications;
    });
    this._brandingService.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => this.setConfig(config));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get badge(): string {
    // eslint-disable-next-line id-length
    const qualification = this.qualifications.find(q => q.id === this.guide.qualification);
    return qualification ? qualification.badge : '';
  }

  get hasHourlyRate(): boolean {
    return !!this._guide && this._guide.pricePerHour != null && !this._guide.freeAllSessions;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get isPromoting(): any {
    return (
      this.guide &&
      // @ts-expect-error TS7006
      // eslint-disable-next-line id-length
      (this.guide.bookForFree || (this.guide.tags && this.guide.tags.length && this.guide.tags.some(t => t.isPromo)))
    );
  }

  get sessionCostPer60Min(): number {
    const sessionCost = +this.guide.pricePerHour;
    return Math.round(sessionCost * 100) / 100;
  }

  get sessionCostWithDiscountApplied(): number | null {
    let value = null;

    if (this.guide && !this.isPromoting && this.guide.discount) {
      value = Math.round(this.sessionCostPer60Min * (1 - this.guide.discount.value) * 100) / 100;
    }

    return value;
  }

  stop(event: Event): void {
    event.stopPropagation();
  }

  tzOffset(): number {
    if (!this.guide) {
      return 0;
    }
    return (DateTime.local().setZone(this.guide.timezone).offset - DateTime.local().offset) / 60;
  }

  private setConfig({ hideProfileLocationLanguage, hideCoachReviews, showPriceStartsFrom }: GlobalConfig): void {
    this.config.hideProfileLocationLanguage = hideProfileLocationLanguage;
    this.config.hideCoachReviews = hideCoachReviews;
    this.config.showPriceStartsFrom = showPriceStartsFrom;
  }
}
