import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TimePickerGridConfig } from '../../time-picker-grid.config';
import { ITimeSlot } from '@app/modules/schedule-boards';

export const TIME_PICKER_GRID_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TimePickerGridComponent),
  multi: true
};

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-time-picker-grid',
  templateUrl: './time-picker-grid.component.html',
  styleUrls: ['./time-picker-grid.component.scss'],
  providers: [TIME_PICKER_GRID_VALUE_ACCESSOR],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'time-picker-grid',
    '[class.disabled]': 'isDisabled || null',
    '[attr.disabled]': 'isDisabled || null'
  }
})
export class TimePickerGridComponent<T extends Pick<ITimeSlot, 'label' | 'guide'>> implements ControlValueAccessor {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _time: T | null;

  @Input()
  items: T[] = [];

  @Input()
  itemsComparator: (item1: T, item2: T) => boolean;

  @Input()
  // @ts-expect-error TS2564
  isModal: boolean;

  @Input()
  set time(value: T) {
    this.writeValue(value);
  }

  @Output()
  readonly timeChange = new EventEmitter<T>();

  isDisabled = false;

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get time(): T | null {
    return this._time;
  }

  constructor(config: TimePickerGridConfig) {
    this.itemsComparator = config.itemsComparator;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  selectTime(time: T): void {
    if (this.isDisabled) {
      return;
    }

    this._time = time;
    this.onChange(time);
    this.timeChange.emit(time);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(time: T): void {
    // @ts-expect-error TS2322
    this._time = this.items && this.items.length ? this.items.find(item => this.itemsComparator(time, item)) : null;
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private onChange = _ => {};

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private onTouched = () => {};
}
