import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { PUI_DIALOG_DATA, PuiButtonModule, PuiDialogRef, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { ClientsSelectComponent } from '@app/components/clients-select/clients-select.component';
import { ClientsSelectInputDirective } from '@app/components/clients-select/clients-select-input.directive';
import { GuideClient } from '@app/core/users/types';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'clients-select-modal',
  standalone: true,
  templateUrl: 'clients-select-modal.component.html',
  imports: [ClientsSelectComponent, ClientsSelectInputDirective, PuiTypographyModule, PuiButtonModule, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientsSelectModalComponent implements OnInit {
  protected data = inject(PUI_DIALOG_DATA);
  protected clients: GuideClient[] = this.data.clients as GuideClient[];
  protected selectedClients = [];

  private dialogRef = inject(PuiDialogRef);

  ngOnInit() {}

  submit() {
    const clientIds = this.selectedClients.map(({ id }) => id);

    this.dialogRef.close(clientIds);
  }

  cancel() {
    this.dialogRef.close();
  }
}
