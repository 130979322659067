import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PHRASES, UiKitPhrases } from '@app/modules/ui-kit/_base/_common/localize';
import { USERS_SEARCH_SERVICE_PROVIDER } from '@app/modules/ui-kit/users-typeahead';
import { ProgramInstructorsApiService } from '@app/screens/guide/guide-programs/services/program-instructors-api.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModuleInstructor } from '../../types';
import { ProgramInstructorsService } from '../../services/program-instructors.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-module-instructor',
  templateUrl: './module-instructor.component.html',
  styleUrls: ['./module-instructor.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'module-instructor'
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ModuleInstructorComponent),
      multi: true
    },
    ProgramInstructorsApiService,
    ProgramInstructorsService,
    {
      provide: USERS_SEARCH_SERVICE_PROVIDER,
      useClass: ProgramInstructorsService
    }
  ]
})
export class ModuleInstructorComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _instructor: ModuleInstructor;

  users$: Observable<ModuleInstructor[]>;

  @Input()
  set instructor(value: ModuleInstructor) {
    this._instructor = value;
    this.writeValue(value);
  }

  get instructor(): ModuleInstructor {
    return this._instructor;
  }

  @Output()
  instructorChange = new EventEmitter<ModuleInstructor>();

  readonly instructorControl = new UntypedFormControl(null);

  readonly placeholder = PHRASES[UiKitPhrases.ClientSelectorChooseInstructor];

  constructor(readonly programInstructors: ProgramInstructorsService) {
    this.programInstructors.search();
    this.users$ = programInstructors.users$;
  }

  ngOnInit(): void {
    this.instructorControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(formValue => {
      this._onTouched();
      this._onChange(formValue);
      this.instructorChange.emit(formValue);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.instructorControl.disable();
    } else {
      this.instructorControl.enable();
    }
  }

  writeValue(instructor: ModuleInstructor): void {
    this.instructorControl.patchValue(instructor, { emitEvent: false });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private _onChange = (_: any) => {};

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type
  private _onTouched = () => {};

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  filter($event: string) {
    this.programInstructors.search($event);
  }
}
