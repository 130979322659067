import { NotificationsService } from '@awarenow/profi-ui-core';
import { combineLatest, Observable, of, Subject, timer } from 'rxjs';
import { map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, forwardRef, Inject } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { InternalEvents } from '@app/core/analytics/types';
import { AuthService } from '@app/core/auth/services';
import { BrandingService } from '@app/core/branding/branding.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { ZoomService } from '@app/core/zoom/zoom.service';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { CallInitiator, SessionTemplateLocation } from '@app/screens/guide/guide-sessions-templates/types';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { WorkspacesTypes } from '@app/shared/enums/workspaces-types';
import { PuiDestroyService } from '@awarenow/profi-ui-core';
import { GlobalConfig } from '@cnf/types';

interface ConnectionTypeOption {
  connectionType: string;

  /**
   * @deprecated to display connection type name use {ConnectionTypePipe}
   */
  name: string;
  icon: string;
}

@Component({
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.scss'],
  providers: [
    PuiDestroyService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LocationFormComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationFormComponent implements ControlValueAccessor {
  readonly SessionConnectionTypes = SessionConnectionTypes;
  readonly callInitiator = CallInitiator;
  readonly platformName: string;
  readonly zoomErr$ = new Subject<boolean>();
  readonly connectionTypes: ConnectionTypeOption[];
  readonly platformIconURL: string;
  private readonly defaultLocationValue: SessionTemplateLocation = {
    connectionType: SessionConnectionTypes.IN_PLATFORM
  };

  get isPlatformAdmin(): boolean {
    return this.authService.isPlatformAdmin();
  }

  get disableConnectionTypeTooltip(): boolean {
    return !this.form.get('connectionType')?.disabled || this.isPlatformAdmin;
  }

  get showAddress(): boolean {
    return this.form.get('connectionType')?.value === SessionConnectionTypes.IN_PERSON;
  }

  get showPhoneOptions(): boolean {
    return this.form.get('connectionType')?.value === SessionConnectionTypes.PHONE;
  }

  form: UntypedFormGroup = this.formBuilder.group({
    connectionType: [
      SessionConnectionTypes.IN_PLATFORM,
      [Validators.required],
      [
        (input: UntypedFormControl) => {
          return timer(500).pipe(
            switchMap(() => (input.value !== SessionConnectionTypes.ZOOM ? of(true) : this.zoomService.account$)),
            map(valid => {
              if (input.value === SessionConnectionTypes.ZOOM) {
                this.zoomErr$.next(!valid);
              } else {
                this.zoomErr$.next(false);
              }
              return valid ? null : { connectionTypeError: true };
            }),
            take(1)
          );
        }
      ]
    ],
    address: [null, []],
    hideAddress: [false, []],
    callInitiator: [CallInitiator.CLIENT, []]
  });

  readonly zoomNotification$: Observable<{ warning: boolean; error: boolean }> = combineLatest([
    this.workspaceService.activeWorkspace$,
    this.zoomErr$,
    this.form.controls.connectionType.valueChanges
  ]).pipe(
    map(([workspace, hasZoomError, connectionType]) => {
      return {
        error: hasZoomError,
        warning:
          connectionType === SessionConnectionTypes.ZOOM &&
          workspace.type === WorkspacesTypes.TEAM &&
          workspace.admin.id === this.authService.user.id
      };
    })
  );

  constructor(
    private readonly authService: AuthService,
    private readonly workspaceService: WorkspacesService,
    readonly zoomService: ZoomService,
    private readonly analyticsService: AnalyticsService,
    private readonly notifyService: NotificationsService,
    private readonly formBuilder: UntypedFormBuilder,
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>,
    private readonly runtimeConfigService: RuntimeConfigService,
    private readonly brandingService: BrandingService
  ) {
    this.platformName = this.runtimeConfigService.get('platformName') || '';
    this.platformIconURL = this.runtimeConfigService.get('faviconUrl') || '';

    this.connectionTypes = [
      {
        connectionType: SessionConnectionTypes.IN_PLATFORM,
        name: this.runtimeConfigService.get('videoLocationCustomName') || this.platformName,
        icon: 'pui:profi'
      },
      {
        connectionType: SessionConnectionTypes.ZOOM,
        name: 'Zoom',
        icon: 'pui:zoom'
      }
    ];

    this.brandingService.globalConfig$.pipe(take(1), takeUntil(this.destroy$)).subscribe((config: GlobalConfig) => {
      if (config.inPersonSessionsAvailable) {
        this.connectionTypes.push({
          connectionType: SessionConnectionTypes.IN_PERSON,
          name: `In-person`,
          icon: 'pui:place'
        });
      }

      if (config.inCallSessionsAvailable) {
        this.connectionTypes.push({
          connectionType: SessionConnectionTypes.PHONE,
          name: `Phone call`,
          icon: 'pui:phone'
        });
      }
    });
  }

  onTouched: () => void = () => {};

  writeValue(value: SessionTemplateLocation): void {
    if (value) {
      this.form.patchValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: (value: SessionTemplateLocation) => void): void {
    this.form.valueChanges
      .pipe(
        startWith(this.defaultLocationValue),
        map((value: SessionTemplateLocation) => {
          const { connectionType, address, hideAddress, callInitiator } = value;
          if (
            value.connectionType === SessionConnectionTypes.IN_PLATFORM ||
            value.connectionType === SessionConnectionTypes.ZOOM
          ) {
            return {
              connectionType
            };
          } else if (value.connectionType === SessionConnectionTypes.IN_PERSON) {
            return {
              connectionType,
              address,
              hideAddress: hideAddress || false
            };
          } else if (value.connectionType === SessionConnectionTypes.PHONE) {
            const callInitiatorValue = callInitiator ?? CallInitiator.CLIENT;
            this.form.patchValue({ callInitiator: callInitiatorValue }, { emitEvent: false });

            return {
              connectionType,
              callInitiator: callInitiatorValue
            };
          }

          return this.defaultLocationValue;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  connectZoom(): void {
    this.zoomService
      .authenticate$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.analyticsService.event(InternalEvents.ZOOM_INTEGRATED, {});
          const title = `Zoom connected`;
          this.notifyService.success(title);
          this.zoomErr$.next(false);
          this.form.controls.connectionType.setErrors(null);
        },
        error => {
          if (error.isAborted) {
            return;
          }
          const title = `Zoom connection failed`;
          this.notifyService.error(title, `${error.message}`);
        }
      );
  }
}
