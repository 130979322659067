import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '@app/core/auth/services/auth.service';
import { LocalStorageService } from '@app/core/local-storage/local-storage.service';

@Injectable()
export class ClientInvitationActivationGuard {
  constructor(private _router: Router, private _auth: AuthService, private _storage: LocalStorageService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this._auth.isAuthorized) {
      return true;
    }

    // TODO: replace magic string with global constant
    this._storage.setItem('client_invitation_code', route.paramMap.get('hash'));
    this._router.navigate(['/auth/sign-in']);

    return false;
  }
}
