import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AfterContentInit, ChangeDetectionStrategy, Component, forwardRef, Inject, Input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import { LocaleService } from '@app/core/locale/locale.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { IsoDayTime } from '@app/screens/guide/guide-sessions/types';
import { SimpleTimeGenerator } from '@app/screens/guide/guide-sessions/utils/time-generators';
import { PuiDestroyService } from '@awarenow/profi-ui-core';
import {
  findIndicesPair,
  setEndHours
} from '@app/modules/session-forms/forms/working-time-form/components/working-time-slot/utils';

export interface Override {
  startTime: string;
  endTime: string;
}

export interface DateOverridesForm {
  date: Date;
  overrides: Override[];
  unavailable: boolean;
}

export enum OverridesTime {
  DEFAULT_START_TIME = '09:00',
  DEFAULT_END_TIME = '18:00',
  DEFAULT_TIME = '00:00'
}

@Component({
  selector: 'app-date-overrides-form',
  templateUrl: './date-overrides-form.component.html',
  styleUrls: ['./date-overrides-form.component.scss'],
  providers: [
    PuiDestroyService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateOverridesFormComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateOverridesFormComponent implements ControlValueAccessor, AfterContentInit {
  form = this.formBuilder.group({
    date: [null, Validators.required],
    overrides: this.formBuilder.array([
      this.formBuilder.group({
        startTime: this.formBuilder.control(OverridesTime.DEFAULT_START_TIME),
        endTime: this.formBuilder.control(OverridesTime.DEFAULT_END_TIME)
      })
    ]),
    unavailable: [false]
  });

  readonly minDate = DateTime.now().startOf('day').toJSDate();

  fullDayHours!: IsoDayTime[];
  startHours!: IsoDayTime[];
  endHours!: IsoDayTime[];

  intervals: {
    [key: string]: IsoDayTime & { unavailable: boolean };
  } = {};

  @Input() startInterval = OverridesTime.DEFAULT_START_TIME;

  @Input() endInterval = OverridesTime.DEFAULT_END_TIME;

  @Input() excludedDates: string[] = [];

  constructor(
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly localeService: LocaleService,
    private readonly runtimeConfigService: RuntimeConfigService
  ) {
    this.fullDayHours = new SimpleTimeGenerator(this.localeService).generate(
      this.runtimeConfigService.get('guideCalendarWorkingTimeInterval')
    );

    this.fullDayHours.forEach(dayHours => {
      this.intervals[dayHours.iso] = { ...dayHours, unavailable: true };
    });

    this.startHours = this.fullDayHours;
    this.endHours = this.fullDayHours;
  }

  ngAfterContentInit(): void {
    this.getDayControlById()
      .get('startTime')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        const endHours = this.setEndHours(value, value);
        this.getDayControlById().get('endTime')?.setValue(endHours[0].iso);
      });
  }

  onTouched: () => void = () => {};

  writeValue(value: {
    date: Date;
    overrides: {
      startTime: string;
      endTime: string;
    }[];
  }): void {
    if (value) {
      if (value.overrides.length > this.form.get('overrides')?.value.length) {
        for (let i = 1; i < value.overrides.length; i++) {
          (this.form.get('overrides') as UntypedFormArray).push(
            this.formBuilder.group({
              startTime: this.formBuilder.control(OverridesTime.DEFAULT_START_TIME),
              endTime: this.formBuilder.control(OverridesTime.DEFAULT_END_TIME)
            })
          );
        }
      }

      const start = value.overrides[0]?.startTime;
      const end = value.overrides[0]?.endTime;

      this.form.patchValue(
        {
          ...value,
          unavailable:
            value.overrides.length === 1 && start === OverridesTime.DEFAULT_TIME && end === OverridesTime.DEFAULT_TIME
        },
        { emitEvent: false }
      );
    }
  }

  registerOnChange(fn: (value: { [key: string]: unknown }) => void): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  addControl(): void {
    if ((this.form.get('overrides') as UntypedFormArray).length < 6) {
      (this.form.get('overrides') as UntypedFormArray)?.push(
        this.formBuilder.group({
          startTime: this.formBuilder.control(OverridesTime.DEFAULT_START_TIME),
          endTime: this.formBuilder.control(OverridesTime.DEFAULT_END_TIME)
        })
      );
    }
  }

  removeControl(index: number): void {
    (this.form.get('overrides') as UntypedFormArray)?.removeAt(index);
  }

  getDayControlById(dayId: string | number = 0): AbstractControl {
    return this.form.get(['overrides', `${Number(dayId)}`]) as AbstractControl;
  }

  private setEndHours(startHour: string, endHour: string): IsoDayTime[] {
    return setEndHours(startHour, endHour, this.fullDayHours);
  }

  private findIndicesPair<T>(arr: T[], predicate1: (a: T) => boolean, predicate2: (a: T) => boolean): [number, number] {
    return findIndicesPair(arr, predicate1, predicate2);
  }

  toggleUnavailableByAllDay(): void {
    const isUnavailable = this.form.get('unavailable')?.value;
    const overrides = this.form.get('overrides') as UntypedFormArray;

    if (isUnavailable) {
      const length = overrides?.controls.length || 0;

      for (let i = 1; i < length; i++) {
        overrides.removeAt(1);
      }

      overrides.patchValue([
        {
          startTime: OverridesTime.DEFAULT_TIME,
          endTime: OverridesTime.DEFAULT_TIME
        }
      ]);
    } else {
      overrides?.patchValue([
        {
          startTime: OverridesTime.DEFAULT_START_TIME,
          endTime: OverridesTime.DEFAULT_END_TIME
        }
      ]);
    }
  }
}
