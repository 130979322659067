import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { PuiDestroyService } from '@awarenow/profi-ui-core';
import { takeUntil } from 'rxjs/operators';
import { WorkingTimes } from '@app/modules/session-forms/forms/working-time-form/working-time-form.component';
import { IsoDayTime } from '@app/screens/guide/guide-sessions/types';
import { findIndicesPair, setEndHours, convertToNumber } from './utils';

@Component({
  selector: 'app-working-time-slot',
  templateUrl: './working-time-slot.component.html',
  styleUrls: ['./working-time-slot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PuiDestroyService]
})
export class WorkingTimeSlotComponent {
  @Input('startControl') set setStartControl(control: UntypedFormControl) {
    this.startControl = control;

    if (!this.startSub && this.dayId) {
      this.startSub = this.startControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
        if (value !== WorkingTimes.START_DAY) {
          this.endHours = this.setEndHours(value, value);

          const startInterval = convertToNumber(value);
          const endInterval = convertToNumber(this.endControl.value);

          if (startInterval >= endInterval) {
            this.endControl.setValue(this.endHours[0].iso);

            this.cdr.markForCheck();
          }
        }
      });
    }
  }
  @Input()
  endControl!: UntypedFormControl;

  @Input()
  startHours: IsoDayTime[] = [];

  @Input()
  dayId!: string;
  @Input()
  index!: number;
  @Input()
  endHours: IsoDayTime[] = [];

  @Input()
  fullDayHours: IsoDayTime[] = [];

  @Output()
  removeControl = new EventEmitter();

  startSub: Subscription | undefined;
  startControl: UntypedFormControl | undefined;

  constructor(@Inject(PuiDestroyService) private readonly destroy$: Observable<void>, private cdr: ChangeDetectorRef) {}

  getEndTime(startValue: string): IsoDayTime[] {
    return this.endHours.filter((day: { iso: string; label: string }) => day.iso > startValue);
  }

  onRemoveControl() {
    this.removeControl.emit();
  }

  private setEndHours(startHour: string, endHour: string): IsoDayTime[] {
    return setEndHours(startHour, endHour, this.fullDayHours);
  }

  private findIndicesPair<T>(arr: T[], predicate1: (a: T) => boolean, predicate2: (a: T) => boolean): [number, number] {
    return findIndicesPair(arr, predicate1, predicate2);
  }
}
