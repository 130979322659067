<div class="auth-body">
  <div class="auth-form">
    <div class="pui-space_bottom-2">
      <h2 pui-typography="h2" i18n="@@forgotPassword">Forgot Password?</h2>
    </div>

    <p pui-typography="body-s" i18n="@@resetPasswordInstructions">
      To reset your password, enter your login email and we'll send you instructions.
    </p>

    <form class="pui-form pui-space_top-4" [formGroup]="forgotPasswordForm" novalidate>
      <div class="pui-form__row">
        <div class="pui-form__field">
          <label for="email" i18n="@@email" pui-input-label>Email</label>

          <pui-input-field size="s">
            <input
              size="s"
              formControlName="email"
              (change)="onEmailChanged()"
              id="email"
              type="email"
              placeholder="Email"
              class="form-control"
              i18n-placeholder="@@email"
              pui-input-text />
          </pui-input-field>

          <app-form-control-error [control]="forgotPasswordForm.controls['email']" [show]="wrongEmail">
            <ng-template [ngIf]="wrongEmail" [ngIfElse]="invalidEmail" i18n="@@emailDoesntExist">
              This email doesn't exist. Please check the spelling.
            </ng-template>
            <ng-template #invalidEmail>
              <ng-container i18n="@@invalidEmail">Invalid email.</ng-container>
            </ng-template>
          </app-form-control-error>
        </div>
      </div>
    </form>

    <div class="auth-buttons">
      <button size="s" pui-button (click)="sendInstructions()" i18n="@@sendInstructions">Send instructions</button>
      <a pui-button size="s" appearance="flat" [routerLink]="['/auth/sign-in']">
        <ng-container i18n="@@returnToSignIn">Return to Sign In</ng-container>
      </a>
    </div>
  </div>
</div>
