import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { UserRoles } from '@app/shared/enums/user-roles';
import { AuthService } from './services/auth.service';
import { extractUrlFromRouterNavigation } from './utils';
import { LocalStorageKeys } from '@app/cdk/enums';

@Injectable()
export class GuideGuard {
  constructor(private _injector: Injector, private _router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.checkLoginAndRole(state.url);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
    // @ts-expect-error TS2345
    const url = extractUrlFromRouterNavigation(this._router.getCurrentNavigation());
    return this.checkLoginAndRole(url);
  }

  // @ts-expect-error TS7006
  private async checkLoginAndRole(url): Promise<boolean> {
    const authService = this._injector.get(AuthService);
    const isGuide = authService.isAuthorized && authService.user.RoleId === UserRoles.GUIDE;

    const search: string = window.location.search;
    if (search.includes(LocalStorageKeys.CREATE_TEAM)) {
      this._router.navigate(['/team-create'], { replaceUrl: true }).then();
      return isGuide;
    }

    if (!isGuide) {
      if (authService.isAuthorized) {
        this._router.navigate(['/']).then();
      } else {
        authService.redirectUrl = url;
        this._router.navigate(['/auth']).then();
      }
    }

    return isGuide;
  }
}
