import { Directive, ElementRef, Renderer2, inject, ChangeDetectorRef } from '@angular/core';

@Directive({ selector: 'input[type="password"][appPasswordField]', exportAs: 'appPasswordField', standalone: true })
export class PasswordFieldDirective {
  private elementRef = inject(ElementRef<HTMLInputElement>);
  private renderer = inject(Renderer2);
  private cdRef = inject(ChangeDetectorRef);

  isShowed = false;

  show() {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'type', 'text');
    this.isShowed = true;
    this.cdRef.markForCheck();
  }

  hide() {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'type', 'password');
    this.isShowed = false;
    this.cdRef.markForCheck();
  }

  toggle() {
    this.elementRef.nativeElement.type === 'password' ? this.show() : this.hide();
  }
}
