import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/core/auth/services';
import { LocaleService } from '@app/core/locale/locale.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { makeUriFromString } from '@app/screens/blog/utils';
import { ProgramAccessRoles } from '@app/screens/guide/guide-programs/types';
import { AllGuideServicesApiService } from '@app/screens/guide/guide-services/services/all-guide-services-api.service';
import { GuideServicesService } from '@app/screens/guide/guide-services/services/guide-services.service';
import { GuideServicesUtilsService } from '@app/screens/guide/services/guide-services-utils.service';
import { UserRoles } from '@app/shared/enums/user-roles';
import { GuideService, GuideServiceTypes } from '@app/shared/interfaces/services';
import { environment } from '@env/environment';
import { ILocale } from '@env/locale.interface';

@Component({
  selector: 'app-guide-services-board',
  templateUrl: './guide-services-board.component.html',
  styleUrls: ['./guide-services-board.component.scss'],
  providers: [GuideServicesService, AllGuideServicesApiService],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-app-guide-services-board'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideServicesBoardComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  readonly packagesEnabled: boolean;

  readonly GuideServiceTypes = GuideServiceTypes;

  readonly ProgramAccessRoles = ProgramAccessRoles;

  readonly guideRoute = environment.guideRoute;

  private locale: ILocale;

  searchText!: string;
  isAdmin = this.auth.isPlatformAdmin();

  userRole = UserRoles.GUIDE;

  canCreateService$ = this.guidesServiceUtils.canCreateService$;

  get role(): string {
    return this.userRole === UserRoles.GUIDE ? `${environment.guideRoute}` : 'admin';
  }

  get serviceRoute(): string {
    return this.userRole === UserRoles.GUIDE ? `${this.role}/services` : `${this.role}/default-service`;
  }

  constructor(
    readonly guideServices: GuideServicesService,
    private readonly auth: AuthService,
    private readonly runtimeConfigService: RuntimeConfigService,
    private readonly localeService: LocaleService,
    private readonly guidesServiceUtils: GuideServicesUtilsService
  ) {
    this.locale = localeService.getLocale();

    this.packagesEnabled = !!this.runtimeConfigService.get('guidePackagesEnabled');
  }

  ngOnInit(): void {
    this.auth
      .onAuth()
      .pipe(
        filter(user => !!user),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.guideServices.refresh());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getPublicLink(service: GuideService): string {
    const { name, id, type: serviceType } = service;
    switch (serviceType) {
      case GuideServiceTypes.GROUP_SESSION:
      case GuideServiceTypes.SESSION:
        return id && name && !this.isAdmin ? `${this.locale.baseUrl}/sessions/${makeUriFromString(name, id)}` : '';
      case GuideServiceTypes.PACKAGE:
        return id && name && !this.isAdmin ? `${this.locale.baseUrl}/packages/${makeUriFromString(name, id)}` : '';
      case GuideServiceTypes.PROGRAM:
        return id && name && !this.isAdmin ? `${this.locale.baseUrl}/programs/${makeUriFromString(name, id)}` : '';
      default:
        return '';
    }
  }

  getEditorLink(service: GuideService): string {
    const { id, type } = service;
    const prefix = this.isAdmin ? '/admin/default-service/' : `/${this.guideRoute}/services/`;
    switch (type) {
      case GuideServiceTypes.GROUP_SESSION:
      case GuideServiceTypes.SESSION:
        return id ? `${prefix}sessions/${id}/settings` : '';
      case GuideServiceTypes.PACKAGE:
        return id ? `${prefix}packages/${id}/settings` : '';
      case GuideServiceTypes.PROGRAM:
        const prefixPrograms = this.isAdmin ? '/admin/default-service/' : `/${this.guideRoute}/`;
        return id ? `${prefixPrograms}programs/${id}/edit` : '';
      default:
        return '';
    }
  }

  trackByIndex(index: number): number {
    return index;
  }
}
