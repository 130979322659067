import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, pluck, takeUntil } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NOTES_UPDATE_TOKEN } from '@app/modules/guide-client/types/notes-update-token';
import { GuideNotesBoardComponent } from '@app/modules/guide-notes/components/guide-notes-board/guide-notes-board.component';
import { ShareNoteConfirmModalComponent } from '@app/modules/guide-notes/components/share-note-confirm-modal/share-note-confirm-modal.component';
import { GuideEventsNotesBoardService } from '@app/modules/guide-notes/guide-events-notes-board.service';
import { GuideNote } from '@app/modules/guide-notes/guide-notes.types';
import { SessionTypes } from '@app/shared/enums/session-types';
import { IServerUser } from '@app/shared/interfaces/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-guide-events-notes-board',
  templateUrl: './guide-events-notes-board.component.html',
  styleUrls: ['./guide-events-notes-board.component.scss'],
  providers: [GuideEventsNotesBoardService]
})
export class GuideEventsNotesBoardComponent implements OnInit, OnDestroy {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _notesBoardRef: GuideNotesBoardComponent;

  private destroy$ = new Subject<void>();

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _eventId: number;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _clientIds: number[];

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _user: IServerUser;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _search: string;

  @Input()
  set user(value: IServerUser) {
    this._user = value;
    const clientIds = [value.id];
    this._clientIds = clientIds;
    this._guideNotesBoard.setRelationList(clientIds);
    // @ts-expect-error TS2345
    this._guideNotesBoard.setState({ ...this._guideNotesBoard.state, clientIds });
    this._guideNotesBoard.loadNotes();
  }

  get user(): IServerUser {
    return this._user;
  }

  @Input()
  // @ts-expect-error TS2564
  serviceType: SessionTypes;

  @Input()
  // @ts-expect-error TS2564
  searchControl: UntypedFormControl;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set eventId(value: any) {
    this._eventId = value;
    // @ts-expect-error TS2345
    this._guideNotesBoard.setState({ ...this._guideNotesBoard.state, eventId: value });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get eventId() {
    return this._eventId;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get clientIds() {
    return this._clientIds;
  }

  get hideSharing(): boolean {
    return this.serviceType !== SessionTypes.SESSION;
  }

  @ViewChild(GuideNotesBoardComponent)
  set notesBoard(value: GuideNotesBoardComponent) {
    this._notesBoardRef = value;
  }

  // @ts-expect-error TS2564
  reset: symbol;

  readonly notes$: Observable<GuideNote[]> = this._guideNotesBoard.state$.pipe(filter(Boolean), pluck('notes'));

  readonly isLoading$ = this._guideNotesBoard.isLoading$;

  constructor(
    private modal: NgbModal,
    private readonly _guideNotesBoard: GuideEventsNotesBoardService,
    private readonly _cdr: ChangeDetectorRef,
    @Inject(NOTES_UPDATE_TOKEN) private readonly _notesUpdate: Subject<void>
  ) {}

  ngOnInit(): void {
    // @ts-expect-error TS2339
    this._guideNotesBoard.state$.pipe(takeUntil(this.destroy$)).subscribe(({ scrollTo }) => {
      if (scrollTo) {
        this._notesBoardRef.scrollToCard(scrollTo);
      }
    });

    this._guideNotesBoard.resetEditorState$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.reset = Symbol();
    });

    this.searchControl?.valueChanges.pipe(debounceTime(600), takeUntil(this.destroy$)).subscribe(search => {
      this._search = search;
      this._guideNotesBoard.loadNotes(search);
    });

    this._notesUpdate
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this._guideNotesBoard.loadNotes(this._search);
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onNoteDelete(event) {
    this._guideNotesBoard.delete(event);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onGrantViewerAccess(event) {
    const { componentInstance, result } = this.modal.open(ShareNoteConfirmModalComponent, { centered: true });
    // TODO: refactor
    componentInstance.name = `${this.user.firstName} ${this.user.lastName}`;
    componentInstance.email = '';
    componentInstance.photo = this.user.photo;
    result
      .then(confirm => {
        if (confirm) {
          this._guideNotesBoard.grantViewerAccess(event);
        }
      })
      .catch(() => {});
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onRevokeViewerAccess(event) {
    this._guideNotesBoard.revokeViewerAccess(event);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onNoteUpdate(event) {
    this._guideNotesBoard.update(event, false);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onNoteEndEditing(event) {
    this._guideNotesBoard.update(event, true);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onNoteCreate(event) {
    this._guideNotesBoard.create(event);
  }

  onLoadMoreNotes(): void {
    // @ts-expect-error TS2345
    this._guideNotesBoard.loadMoreNotes(null, this._search);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onNotePin(event) {
    this._guideNotesBoard.pinNote(event);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onNoteUnpin(event) {
    this._guideNotesBoard.unpinNote(event);
  }

  // @ts-expect-error TS7006
  onManageAccess(event): void {
    this._guideNotesBoard.manageAccess(event);
  }
}
