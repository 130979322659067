import { NotificationsService, PuiDialogService } from '@awarenow/profi-ui-core';
import { DateTime } from 'luxon';
import { combineLatest, EMPTY, iif, Observable, of, partition } from 'rxjs';
import { catchError, filter, finalize, map, mapTo, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/auth/services/auth.service';
import { BrandingService } from '@app/core/branding/branding.service';
import { LocaleService } from '@app/core/locale/locale.service';
import { GLOBAL_SESSION_STORAGE } from '@app/core/session-storage/session-storage-provider';
import { AuthModalComponent } from '@app/modules/auth/components/auth-modal/auth-modal.component';
import { IGuideService, IServiceBookingOptions, ServiceBookingService } from '@app/modules/book-service';
import { ClientProgramsService } from '@app/modules/client-programs/services/client-programs.service';
import { IPayWithModalOptions } from '@app/modules/current-payment/components/pay-with-modal/pay-with-modal.component';
import { PaymentOptionsModalComponent } from '@app/modules/current-payment/components/payment-options-modal/payment-options-modal.component';
import { CurrentPaymentService } from '@app/modules/current-payment/services/current-payment.service';
import { IPublicProgram } from '@app/modules/program/types';
import { makeUriFromString } from '@app/screens/blog/utils';
import { SwitchToAlternativeAccountConfirmModalComponent } from '@app/shared/components/switch-to-alternative-account-confirm-modal/switch-to-alternative-account-confirm-modal.component';
import { PaymentOptions } from '@app/shared/enums/payment-options';
import { UserRoles } from '@app/shared/enums/user-roles';
import { ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { normalizeFullName } from '@app/shared/utils/full-name';
import { modalResultToObservable$ } from '@app/shared/utils/modal-result-to-observable';
import { PuiDestroyService } from '@awarenow/profi-ui-core';
import { GlobalConfig } from '@cnf/types';
import { environment } from '@env/environment';
import { ILocale } from '@env/locale.interface';
import { MetaTagService } from '@libs/services/meta-tag/meta-tag.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlatformStyle } from '@platformStyle/services/platform-style.service';

import { PublicProgramsService } from '../../services/public-programs.service';

@Component({
  selector: 'app-program-landing',
  templateUrl: './program-landing.component.html',
  styleUrls: ['./program-landing.component.scss'],
  providers: [PublicProgramsService, PuiDestroyService, MetaTagService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgramLandingComponent implements OnInit, OnDestroy {
  readonly ModuleTypes = ModuleTypes;

  private locale: ILocale;

  env = environment;

  guideRoute = environment.guideRoute;

  isLoading = false;

  config: {
    meta: Partial<
      Pick<
        GlobalConfig,
        'metaDescriptionProgramPage' | 'metaImageProgramPage' | 'metaKeywordsProgramPage' | 'metaTitleProgramPage'
      >
    >;
  } = {
    meta: {
      metaDescriptionProgramPage: undefined,
      metaImageProgramPage: undefined,
      metaKeywordsProgramPage: undefined,
      metaTitleProgramPage: undefined
    }
  };

  readonly programId$: Observable<number> = this.route.paramMap.pipe(
    map(params => params.get('id')),
    filter<string>(paramId => !!paramId),
    map(paramId => {
      const id = Number(paramId.replace(/^(\d+)(.+)?/, '$1'));

      if (isNaN(id)) {
        throw Error(`Program ID is invalid!`);
      }

      return id;
    })
  );

  readonly program$: Observable<IPublicProgram> = this.programId$.pipe(
    switchMap(programId => this.loadProgram$(programId).pipe(filter<IPublicProgram>(Boolean)))
  );

  constructor(
    private auth: AuthService,
    private brandingService: BrandingService,
    private localeService: LocaleService,
    private location: Location,
    private modal: NgbModal,
    private notifications: NotificationsService,
    private currentPayment: CurrentPaymentService,
    private programService: PublicProgramsService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(GLOBAL_SESSION_STORAGE)
    private sessionStorage: Storage,
    private platformStyle: PlatformStyle,
    private readonly serviceBooking: ServiceBookingService<IServiceBookingOptions<IGuideService>, void>,
    private readonly clientProgramsService: ClientProgramsService,
    private readonly destroy$: PuiDestroyService,
    private readonly metaTagService: MetaTagService
  ) {
    this.locale = this.localeService.getLocale();
  }

  ngOnInit(): void {
    this.brandingService.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => {
      this.setConfig(config);
    });

    this.program$.pipe(take(1), takeUntil(this.destroy$)).subscribe((program: IPublicProgram) => {
      this.location.replaceState(`/programs/${makeUriFromString(program.name, program.id)}`);
      this.setProgram(program);
      this.setOGMeta(program);
    });

    combineLatest(
      this.program$.pipe(take(1)),
      this.route.queryParamMap.pipe(
        map(params => params.get('invitation')),
        filter<string>(invitation => !!invitation)
      )
    )
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(([program, invitation]: [IPublicProgram, string]) => {
        if (program.isAlreadyEnrolled) {
          return;
        }

        this.sessionStorage.setItem(this.buildProgramInvitationKey(program.id), invitation);
        this.location.replaceState(this.location.path().split('?').shift()!);
        this.auth.hasClientProgramInvitation = true;
      });
  }

  enroll(program: IPublicProgram): void {
    if (!program) {
      return;
    }

    const isFree = program.isFree || program.hidePrice || this.auth.user.free;

    const paymentOption$ = isFree
      ? of(null)
      : this.selectPaymentOptions$(program).pipe(
          switchMap(paymentOption => this.selectPaymentType$(paymentOption!, program).pipe(mapTo(paymentOption)))
        );

    paymentOption$
      .pipe(
        switchMap(paymentOption => {
          return this.currentPayment
            .pay(isFree)
            .program$(program.id, { serviceType: GuideServiceTypes.PROGRAM, paymentOption });
        }),
        switchMap(() => iif(() => isFree, of(true), this.clientProgramsService.wasEnrolledProgram$)),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => {
          // TODO https://profi-io.atlassian.net/browse/PR-3525
          if (this.serviceBooking._modalRef) {
            this.serviceBooking._modalRef.close();
            // @ts-expect-error TS2322
            this.serviceBooking._modalRef = null;
          }
          this.notifications.success(`You've been successfully enrolled!`);
          this.navigateToProgramModules(program);
        },
        error => {
          if (error && error.error && error.error.msg) {
            this.notifications.error(error.error.msg);
          } else {
            this.notifications.error(`Failed to book`);
          }
        }
      );
  }

  enrollOrOpen(program: IPublicProgram): void {
    if (!program) {
      return;
    }

    if (!this.auth.isAuthorized) {
      this.authorise(program);
      return;
    }

    if (this.auth.user.RoleId === UserRoles.GUIDE) {
      this.switchToClientRole();
      return;
    }

    if (program.isAlreadyEnrolled) {
      this.navigateToProgramModules(program);
      return;
    }

    const invitationCode = this.sessionStorage.getItem(this.buildProgramInvitationKey(program.id));
    if (invitationCode) {
      this.activateInvitation(invitationCode, program);
      return;
    }

    this.enroll(program);
  }

  private switchToClientRole(): void {
    const { componentInstance, result } = this.modal.open(SwitchToAlternativeAccountConfirmModalComponent, {
      windowClass: 'switch-modal',
      centered: true
    });
    componentInstance.role = UserRoles.CLIENT;

    modalResultToObservable$(result)
      .pipe(
        switchMap(() => this.auth.signinAlternativeAccount(true)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private activateInvitation(invitationCode: string, program: IPublicProgram): void {
    if (!program) {
      return;
    }

    this.programService
      .activateInvitation$(invitationCode)
      .pipe(
        map(response => response.isCustomerEnrolled),
        catchError(() => of(false)),
        tap(isEnrolled => (program.isAlreadyEnrolled = isEnrolled)),
        finalize(() => this.removeInvitation(program)),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.enrollOrOpen(program));
  }

  private authorise(program: IPublicProgram): void {
    if (!program) {
      throw Error('Missed required parameter!');
    }

    const invitationCode = this.sessionStorage.getItem(this.buildProgramInvitationKey(program.id));

    const { componentInstance } = this.modal.open(AuthModalComponent, {
      windowClass: 'auth-modal'
    });

    componentInstance.onlyClient = true;

    componentInstance.signInCanNot
      .pipe(take(1))
      .subscribe(() =>
        this.notifications.error(
          'Sorry!',
          `You need create alternative account, open user menu and click "Sign up as Client"`
        )
      );

    const [client$, guide$] = partition(
      componentInstance.afterSignIn.pipe(take(1)),
      () => this.auth.user.RoleId === UserRoles.CLIENT
    );

    guide$.pipe(takeUntil(this.destroy$)).subscribe(() => this.router.navigate(['/', this.guideRoute, 'programs']));

    client$
      .pipe(
        tap(
          () =>
            invitationCode && this.sessionStorage.setItem(this.buildProgramInvitationKey(program.id), invitationCode)
        ),
        switchMap(() => (invitationCode ? of(null) : this.programService.isAlreadyEnrolled$(program.id))),
        tap(isEnrolled => (program.isAlreadyEnrolled = isEnrolled != null ? isEnrolled : program.isAlreadyEnrolled)),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.enrollOrOpen(program));
  }

  private buildProgramInvitationKey(programId: number): string {
    return `program_${programId}_invitation`;
  }

  private loadProgram$(programId: number): Observable<IPublicProgram> {
    this.isLoading = true;

    return this.programService.getProgram$(programId).pipe(
      catchError(({ notFound }) => {
        if (notFound) {
          this.router.navigate(['/not-found'], { replaceUrl: true });
        }

        return EMPTY;
      }),
      finalize(() => (this.isLoading = false))
    );
  }

  private navigateToProgramModules(program: IPublicProgram): void {
    if (!program) {
      return;
    }

    this.router.navigate(['/client', 'programs', program.id, 'modules']);
  }

  private removeInvitation(program: IPublicProgram): void {
    if (!program) {
      return;
    }

    this.sessionStorage.removeItem(this.buildProgramInvitationKey(program.id));
  }

  private selectPaymentOptions$(program: IPublicProgram): Observable<PaymentOptions | null> {
    if (program && program.price && program.subscriptionPrice && program.totalPayments) {
      const { componentInstance, result } = this.modal.open(PaymentOptionsModalComponent);
      componentInstance.fullPrice = program.price;
      // eslint-disable-next-line prefer-spread
      componentInstance.installmentsPrices = Array.apply(null, Array(program.totalPayments)).map((val, index) => ({
        amount: program.subscriptionPrice,
        paymentDate: DateTime.local().plus({ [program.subscriptionRecurrency!]: index })
      }));
      return modalResultToObservable$(result);
    }

    if (program?.price) {
      return of(PaymentOptions.FULL_PRICE);
    }

    if (program?.subscriptionPrice) {
      return of(PaymentOptions.INSTALLMENTS);
    }

    return of(null);
  }

  private selectPaymentType$(paymentOption: PaymentOptions, program: IPublicProgram): Observable<void> {
    const isSubscription = paymentOption === PaymentOptions.INSTALLMENTS;

    const modalOptions: IPayWithModalOptions = {
      disableNewCardSave: isSubscription,
      isSubscription,
      hideWallet: isSubscription,
      amount: program.price || undefined
    };

    return this.serviceBooking.selectPaymentType$(modalOptions);
  }

  private setOGMeta(program: IPublicProgram): void {
    this.metaTagService.upsertMetaTags({
      title: `${this.config.meta.metaTitleProgramPage} ${program.name}`,
      description: program.description || this.config.meta.metaDescriptionProgramPage,
      image: program.coverImage || this.config.meta.metaImageProgramPage,
      keywords: this.config.meta.metaKeywordsProgramPage,
      url: `${this.locale.baseUrl}${this.router.url}`,
      type: 'website',
      'og:program:author': normalizeFullName(program.guide) || ''
    });
  }

  private setProgram(program: IPublicProgram): void {
    if (!program) {
      return;
    }

    if (program.isAlreadyEnrolled) {
      this.removeInvitation(program);
    }
  }

  private setConfig({
    metaKeywordsProgramPage,
    metaTitleProgramPage,
    metaDescriptionProgramPage,
    metaImageProgramPage
  }: GlobalConfig): void {
    this.config.meta.metaKeywordsProgramPage = metaKeywordsProgramPage;
    this.config.meta.metaTitleProgramPage = metaTitleProgramPage;
    this.config.meta.metaDescriptionProgramPage = metaDescriptionProgramPage;
    this.config.meta.metaImageProgramPage = metaImageProgramPage;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
