import { UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { QuizSendPolicy } from '@app/core/quizzes/types';

export class QuestionnairesFormManager {
  private readonly destroy$ = new Subject<void>();
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _questionnairesForm: UntypedFormArray;

  get questionnairesForm(): UntypedFormArray {
    return this._questionnairesForm;
  }

  constructor(private readonly _formBuilder: UntypedFormBuilder) {}

  destroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setQuestionnairesForm(originalQuestionnaires: any): void {
    if (!this.questionnairesForm) {
      this.initializeQuestionnairesForm(originalQuestionnaires);
    } else {
      this.updateQuestionnairesForm(originalQuestionnaires);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toQuestionnaires(): any {
    // @ts-expect-error TS7006
    const formValues = this._questionnairesForm.value.map(item => {
      if (item.sendPolicy !== QuizSendPolicy.Scheduling) {
        item.schedule = null;
      }

      return item;
    });

    return formValues;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private initializeQuestionnairesForm(questionnaires: any): void {
    this._questionnairesForm = this._formBuilder.array(
      // @ts-expect-error TS7006
      questionnaires.map(questionnaire =>
        this._formBuilder.group({
          id: [questionnaire.id],
          survey: [questionnaire.survey],
          sendPolicy: [questionnaire.sendPolicy || QuizSendPolicy.AfterBuyProgram],
          schedule: [questionnaire.schedule]
        })
      )
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private updateQuestionnairesForm(questionnaires: any[]): void {
    this._questionnairesForm = this._formBuilder.array([]);
    questionnaires.forEach(raw => this._questionnairesForm.push(this._formBuilder.group(raw)));
  }
}
