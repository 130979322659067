import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { filter, take, takeUntil } from 'rxjs/operators';
import { nonEmptyValidator } from '@app/shared/form-validators/non-empty.validator';
import { AuthService } from '@app/core/auth/services';
import { FormService } from '@app/core/form/form.service';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { Subject } from 'rxjs';
import { WorkspacesTypes } from '@app/shared/enums/workspaces-types';
import { IUser } from '@app/shared/interfaces/user';
import { UserNamePipe } from '@app/shared/pipes/user-name/user-name.pipe';
import { notOnlyNumbersValidator } from '@app/shared/form-validators/not-only-numbers.validator';
import { Router } from '@angular/router';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-create-team-step',
  templateUrl: './create-team-step.component.html',
  styleUrls: ['./create-team-step.component.scss']
})
export class CreateTeamStepComponent implements OnInit {
  private destroy$ = new Subject<void>();

  @Output()
  workspaceCreate = new EventEmitter<boolean>();

  creatingWorkspace = false;
  MAX_NAME_LENGTH = 200;
  // @ts-expect-error TS2564
  user: IUser;
  // @ts-expect-error TS2564
  newWorkspaceForm: UntypedFormGroup;

  constructor(
    private authService: AuthService,
    private _formBuilder: UntypedFormBuilder,
    private _formService: FormService,
    private workspaceService: WorkspacesService,
    private userNamePipe: UserNamePipe,
    private _notificationsService: NotificationsService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.authService
      .onAuth()
      .pipe(
        filter(user => user),
        takeUntil(this.destroy$)
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((user: any) => {
        this.user = user;
        this.newWorkspaceForm = this._formBuilder.group({
          name: [
            `${this.userNamePipe.transform(this.user)}'s team`,
            [nonEmptyValidator, Validators.maxLength(this.MAX_NAME_LENGTH), notOnlyNumbersValidator]
          ]
        });
      });
  }

  createWorkspace(): void {
    if (this._formService.markInvalidForm(this.newWorkspaceForm)) {
      return;
    }
    this.creatingWorkspace = true;
    const { name } = this.newWorkspaceForm.value;
    this.workspaceService
      .createWorkspace(name, WorkspacesTypes.TEAM)
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(
        res => {
          this.creatingWorkspace = false;
          const { workspace } = res;
          this.authService.changeToken(workspace.token);
          this.authService.setUserMembership(workspace.subscription);
          this.workspaceCreate.emit(true);
          this.workspaceService.getWorkspaces();
          this._router.navigate(['/dashboard'], { replaceUrl: true }).then();
        },
        response => {
          const { error } = response;
          const workspaceExists = error.errors.some(
            // @ts-expect-error TS7006
            error => error.type === 'unique violation' && error.path === 'name'
          );
          if (workspaceExists) {
            const workspaceNameControl = this.newWorkspaceForm.get('name');
            // @ts-expect-error TS2531
            workspaceNameControl.markAsTouched();
            // @ts-expect-error TS2531
            workspaceNameControl.markAsDirty();
            // @ts-expect-error TS2531
            workspaceNameControl.setErrors({ unique: true });
          }
          this.creatingWorkspace = false;
        }
      );
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
