import { Observable, timer } from 'rxjs';

// @ts-expect-error TS7006
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function bufferOnDemand(duration) {
  // @ts-expect-error TS7034
  let buffer = [];
  let isBuffering = false;
  // @ts-expect-error TS7034
  let timerSubscription;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const cleanUp = () => {
    // @ts-expect-error TS7005
    if (timerSubscription) {
      timerSubscription.unsubscribe();
      buffer = [];
      isBuffering = false;
    }
  };

  // @ts-expect-error TS7006
  return source$ =>
    new Observable(observer =>
      source$.subscribe(
        /* next */
        // @ts-expect-error TS7006
        value => {
          try {
            buffer.push(value);

            if (!isBuffering) {
              isBuffering = true;

              timerSubscription = timer(duration).subscribe(
                () => {
                  // @ts-expect-error TS7005
                  observer.next(buffer);
                  buffer = [];
                },
                () => (isBuffering = false),
                () => (isBuffering = false)
              );
            }
          } catch (error) {
            cleanUp();
            observer.error(error);
          }
        },
        /* error */
        // @ts-expect-error TS7006
        error => {
          cleanUp();
          observer.error(error);
        },
        /* complete */
        () => {
          cleanUp();
          observer.complete();
        }
      )
    );
}
