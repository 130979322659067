import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { QuizService } from '@app/screens/guide/guide-surveys/services/quiz.service';
import { MiniQuiz } from '@app/screens/guide/guide-surveys/types/quiz';
import { QuizSendPolicy } from '@app/core/quizzes/types';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { IProgramAuthor } from '@app/screens/guide/guide-programs/types';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-guide-service-questionnaires-editor',
  templateUrl: './guide-service-questionnaires-editor.component.html',
  styleUrls: [
    '../common-styles/forms.scss',
    '../common-styles/program.scss',
    './guide-service-questionnaires-editor.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideServiceQuestionnairesEditorComponent implements OnInit, OnDestroy {
  // @ts-expect-error TS7008
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _formQuestionnaires;

  readonly QuizSendPolicy = QuizSendPolicy;

  private destroy$: Subject<void> = new Subject<void>();

  // @ts-expect-error TS2564
  surveys: MiniQuiz[];

  @Input()
  // @ts-expect-error TS2564
  author: IProgramAuthor;

  @Input()
  // @ts-expect-error TS2564
  type: GuideServiceTypes;

  @Input()
  set formQuestionnaires(_formQuestionnaires: UntypedFormArray) {
    this._formQuestionnaires = _formQuestionnaires;
  }

  get formQuestionnaires(): UntypedFormArray {
    return this._formQuestionnaires;
  }

  get isFormDisabled(): boolean {
    return !this.author.permissions.canEditSettings;
  }

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _quizService: QuizService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._quizService
      .getQuizzes$(this.author.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((quizzes: MiniQuiz[]) => {
        this.surveys = quizzes;
        this.formQuestionnaires.markAsPristine();

        this.cdr.detectChanges();
      });

    if (this.isFormDisabled) {
      this.formQuestionnaires.disable();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  removeQuestionnaire(index) {
    this.formQuestionnaires.removeAt(index);
  }

  addQuestionnaire(): void {
    this.formQuestionnaires.push(
      this._formBuilder.group({
        survey: [],
        sendPolicy: [QuizSendPolicy.AfterBuyProgram],
        schedule: []
      })
    );
  }
}
