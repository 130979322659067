<!--DESKTOP ACTION BAR-->
<div *ngIf="!(isMobile$ | async)" class="buttons">
  <button class="pui-space_right-3" pui-button appearance="flat" (click)="preview()" i18n="@@preview">Preview</button>
  <button pui-button [isLoading]="isSaving" (click)="saveChanges()" data-qa-id="session-template-settings__save-button">
    <ng-container i18n="@@sessionTemplateSaveButton">Save changes</ng-container>
  </button>
</div>

<div class="session-settings-form__container">
  <div
    *ngIf="!(isMobile$ | async)"
    class="session-settings-form__menu d-flex flex-column pui-col-3"
    data-qa-id="session-template-anchors">
    <ng-container *ngFor="let menuItem of menuItems">
      <button
        *ngIf="menuItem?.showFor.includes(auth.user.RoleId) || isTeamAdmin"
        class="pui-space_bottom-4"
        pui-link
        (click)="scrollTo(menuItem.blockId)">
        <pui-icon class="pui-space_right-2" [svgIcon]="menuItem.icon"></pui-icon>
        <span>
          {{ menuItem.name }}
        </span>
      </button>
    </ng-container>
  </div>

  <form
    [formGroup]="form"
    class="pui-col-6 pui-col_mobile-12 session-settings-form"
    appScrollToFirstInvalid
    triggers="manual"
    [offset]="100">
    <!-- Basic info -->
    <div class="scroll-margin" id="basicInfo">
      <app-basic-info-form formControlName="basicInfo" [appearance]="basicInfoAppearance"></app-basic-info-form>
    </div>

    <!-- Pricing -->
    <div id="pricing" class="pui-space_bottom-8 scroll-margin">
      <app-pricing-form formControlName="pricing"></app-pricing-form>
    </div>

    <!-- Location and Hosts -->
    <div *ngIf="isTeamAdmin" id="locationsAndHosts" class="pui-space_bottom-8 scroll-margin">
      <div class="pui-space_bottom-4">
        <h3 pui-typography="h3" i18n="@@LocationsAndHosts">Locations and hosts</h3>
      </div>

      <div class="pui-space_bottom-5">
        <app-location-form formControlName="location"></app-location-form>
      </div>

      <app-hosts-form formControlName="hosts"></app-hosts-form>
    </div>
    <!-- Session availability -->
    <div *ngIf="!isPlatformAdmin" id="sessionAvailability" class="pui-space_bottom-8 scroll-margin">
      <app-session-availability-form formControlName="availability"></app-session-availability-form>
    </div>

    <!-- Participants and guests -->
    <div id="participantsAndGuests" class="pui-space_bottom-8 scroll-margin">
      <app-participants-and-guests-form formControlName="participantsAndGuests">
        <div configuration-content>
          <app-group-interactions-form
            formControlName="communityConfig"
            [serviceName]="serviceName"></app-group-interactions-form>
        </div>
      </app-participants-and-guests-form>
    </div>

    <!-- Restrictions -->
    <app-restrictions-form
      formControlName="restrictions"
      id="restrictions"
      data-qa-id="session-template-restrictions"></app-restrictions-form>

    <!-- Testimonials -->
    <div *ngIf="!isPlatformAdmin" class="pui-space_bottom-8">
      <div class="pui-space_bottom-4">
        <div class="pui-space_bottom-2">
          <h3 class="scroll-margin" id="testimonials" pui-typography="h3" i18n="@@sessionTemplateTestimonialsTitle">
            Testimonials
          </h3>
        </div>

        <p
          class="session-settings__description-text"
          pui-typography="body-s"
          i18n="@@sessionTemplateTestimonialsDescription">
          Share your clients' reviews to help future clients understand if you are a right fit for them.
        </p>
      </div>

      <app-testimonials-form formControlName="testimonials"></app-testimonials-form>
    </div>
  </form>
</div>

<!--MOBILE ACTION BAR-->
<pui-bar *ngIf="isMobile$ | async">
  <button pui-button (click)="saveChanges()" data-qa-id="session-template-settings__save-button">
    <ng-container i18n="@@sessionTemplateSaveButton">Save changes</ng-container>
  </button>
  <button pui-button appearance="secondary" (click)="preview()" i18n="@@preview">Preview</button>
</pui-bar>
