// eslint-disable-next-line no-restricted-imports
import { isEqual } from 'lodash';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormService } from '@app/core';
import { AuthService } from '@app/core/auth/services';
import { PackageSessionTemplateInterface } from '@app/modules/package/types/package-session-template';
import { ProgramSessionTemplateInterface } from '@app/modules/program/types';
import { UpdateValueAndValidityService } from '@app/modules/session-forms/directives/update-value-and-validity/update-value-and-validity.service';
import {
  DRAWER_DEFAULT_SESSION_TEMPLATE_VALUES_PROVIDER,
  isSessionTemplatePackageData,
  isSessionTemplateProgramData,
  SessionTemplateDrawerData
} from '@app/modules/session-forms/drawers/session-template-drawer/services/session-template-drawer.service';
import { SessionTemplatesDrawerService } from '@app/modules/session-forms/drawers/session-templates-drawer/session-templates-drawer.service';
import { BasicInfoFormAppearanceName } from '@app/modules/session-forms/forms/basic-info-form';
import { BasicInfoForm } from '@app/modules/session-forms/forms/basic-info-form/types';
import { HostsForm } from '@app/modules/session-forms/forms/hosts-form/hosts-form.component';
import { ParticipantsAndGuestsForm } from '@app/modules/session-forms/forms/participants-and-guests-form/participants-and-guests-form.component';
import { RestrictionsForm } from '@app/modules/session-forms/forms/restrictions-form/types';
import { SessionTemplateAvailabilityForm } from '@app/modules/session-forms/forms/session-availability-form/types';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { ProgramAccessRoles, ProgramSessionModuleInfo } from '@app/screens/guide/guide-programs/types';
import {
  SessionTemplateEditorService,
  SessionTemplatePermissionsService,
  SessionTemplatePredefinedFieldsApiService
} from '@app/screens/guide/guide-sessions-templates/services';
import {
  ServiceAssigneePermission,
  SessionTemplateHost,
  SessionTemplateInterface,
  SessionTemplateLocation
} from '@app/screens/guide/guide-sessions-templates/types';
import { GuideDiscardChangesDialogComponent } from '@app/screens/guide/guide-shared/components/guide-discard-changes-dialog/guide-discard-changes-dialog.component';
import { SessionType } from '@app/shared/enums/session-type';
import { UserRoles } from '@app/shared/enums/user-roles';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { PuiDestroyService, PuiDialogService, PuiDrawerRef } from '@awarenow/profi-ui-core';

interface DrawerSessionTemplateForm {
  basicInfo: Omit<
    BasicInfoForm,
    | 'coverImage'
    | 'coverImageThumb'
    | 'descriptionRepresentation'
    | 'descriptionText'
    | 'descriptionMarkup'
    | 'recurring'
    | 'status'
  >;
  restrictions: RestrictionsForm;
  location: SessionTemplateLocation;
  availability: Pick<
    SessionTemplateAvailabilityForm,
    'sessionTemplateScheduleId' | 'availabilities' | 'customAvailability'
  >;
  participantsAndGuests: Pick<ParticipantsAndGuestsForm, 'disableGuests' | 'seatsPerTimeSlot' | 'hasAutoConfirmation'>;
  hosts: HostsForm;
}

const createForm = (fb: UntypedFormBuilder): UntypedFormGroup =>
  fb.group({
    basicInfo: fb.control(null),
    location: fb.control(null),
    availability: fb.control(null),
    hosts: fb.control(null),
    participantsAndGuests: fb.control(null),
    restrictions: fb.control(null)
  });

@Component({
  selector: 'app-session-template-drawer',
  templateUrl: './session-template-drawer.component.html',
  styleUrls: ['./session-template-drawer.component.scss'],
  providers: [
    forwardRef(() => DRAWER_DEFAULT_SESSION_TEMPLATE_VALUES_PROVIDER),
    SessionTemplatePredefinedFieldsApiService,
    SessionTemplateEditorService,
    SessionTemplatePermissionsService,
    PuiDestroyService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionTemplateDrawerComponent implements OnInit {
  form = createForm(this.fb);

  formSpanShot: DrawerSessionTemplateForm | undefined;

  submitButtonLabel = {
    [GuideServiceTypes.PACKAGE]: `Add to package`,
    [GuideServiceTypes.PROGRAM]: `Add to program`,
    save: `Save`
  };

  get parentType(): GuideServiceTypes.PROGRAM | GuideServiceTypes.PACKAGE | undefined {
    return this.drawerRef.data?.parentType;
  }

  get basicInfoAppearance(): BasicInfoFormAppearanceName | undefined {
    if (this.parentType === GuideServiceTypes.PACKAGE && this.workspacesService.isTeamAdmin) {
      return BasicInfoFormAppearanceName.DRAWER_PACKAGE_WORKSPACE_ADMIN;
    } else if (this.parentType === GuideServiceTypes.PACKAGE) {
      return BasicInfoFormAppearanceName.DRAWER_PACKAGE;
    } else if (this.parentType === GuideServiceTypes.PROGRAM && this.workspacesService.isTeamAdmin) {
      return BasicInfoFormAppearanceName.PROGRAM_WORKSPACE_ADMIN;
    } else if (this.parentType === GuideServiceTypes.PROGRAM) {
      return BasicInfoFormAppearanceName.PROGRAM;
    } else {
      return;
    }
  }

  get restrictionsAppearance(): 'package' | 'program' | 'full' | undefined {
    if (this.parentType === GuideServiceTypes.PACKAGE) {
      return 'package';
    } else if (this.parentType === GuideServiceTypes.PROGRAM) {
      return 'program';
    } else {
      return 'full';
    }
  }

  get isTeamAdmin(): boolean {
    return this.workspacesService.isTeamAdmin;
  }

  get isProgramInstructorRole(): boolean {
    if (isSessionTemplateProgramData({ parentType: this.parentType! })) {
      const role = (this.drawerRef.data.sessionInfo as ProgramSessionModuleInfo)?.accessRole;
      return role === ProgramAccessRoles.INSTRUCTOR;
    } else {
      return false;
    }
  }

  get isPlatformAdmin(): boolean {
    return this.auth.user.RoleId === UserRoles.ADMIN;
  }

  get isDraft(): boolean {
    return !this.drawerRef.data.sessionInfo?.sessionTemplateId;
  }

  get isModified(): boolean {
    return !!this.drawerRef.data.sessionInfo?.sessionTemplateDraft;
  }

  // ToDo(lihih) delete this after Service builder will completed
  private loadedTemplateWithRecurring = false;

  constructor(
    private readonly auth: AuthService,
    private readonly drawerRef: PuiDrawerRef<SessionTemplateDrawerData, SessionTemplateDrawerData>,
    private readonly fb: UntypedFormBuilder,
    private readonly workspacesService: WorkspacesService,
    private readonly editor: SessionTemplateEditorService,
    private readonly sessionTemplatePermissionsService: SessionTemplatePermissionsService,
    private readonly formUtils: FormService,
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>,
    private readonly updateNestedForms: UpdateValueAndValidityService,
    private readonly sessionTemplatesDrawerService: SessionTemplatesDrawerService,
    private readonly dialog: PuiDialogService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initFormValue();
    this.initFormRenderSub();
  }

  createSessionTemplateDraft(): void {
    let response: SessionTemplateDrawerData | undefined;

    if (!this.isFormValid()) {
      return;
    }

    if (this.didFormChange()) {
      let newFormData: DrawerSessionTemplateForm;

      if (
        [ServiceAssigneePermission.OWNER, ServiceAssigneePermission.OWNER_X_PROVIDER].includes(
          this.editor.originalTemplate?.permission!
        )
      ) {
        newFormData = this.form.getRawValue();
      } else {
        const basicInfo = this.form.get('basicInfo')?.value || {};
        // Update only location
        newFormData = {
          ...this.formSpanShot!,
          basicInfo: {
            ...basicInfo,
            location: basicInfo?.location
          }
        };
      }

      const { count } = newFormData.basicInfo;
      const { expirationPeriod } = newFormData.restrictions;

      const sessionTemplateId = this.drawerRef.data.sessionInfo?.sessionTemplateId;

      if (isSessionTemplatePackageData({ parentType: this.parentType! })) {
        response = {
          parentType: GuideServiceTypes.PACKAGE,
          sessionInfo: {
            sessionTemplateId,
            expirationPeriod,
            count,
            sessionTemplateDraft: this.createPackageSessionTemplate(newFormData)
          }
        };
      } else if (isSessionTemplateProgramData({ parentType: this.parentType! })) {
        const accessRole = (this.drawerRef.data.sessionInfo as ProgramSessionModuleInfo)?.accessRole;
        response = {
          parentType: GuideServiceTypes.PROGRAM,
          showRecurringAlert: this.loadedTemplateWithRecurring,
          sessionInfo: {
            sessionTemplateId,
            sessionTemplateDraft: this.createProgramSessionTemplate(newFormData),
            accessRole
          }
        };
      }
    }

    this.drawerRef.close(response);
  }

  loadTemplate(): void {
    this.sessionTemplatesDrawerService
      .open$()
      .afterClosed$.pipe(
        take(1),
        filter<{ id: number; recurring: object }>(value => !!value),
        switchMap(({ id: sessionTemplateId, recurring }) =>
          this.didFormChange()
            ? this.dialog
                .open(GuideDiscardChangesDialogComponent, { data: sessionTemplateId })
                .afterClosed$.pipe(map(value => (value === null ? { sessionTemplateId, recurring } : null)))
            : of({ sessionTemplateId, recurring })
        ),
        filter<{ sessionTemplateId: number; recurring: object }>(value => !!value),
        switchMap(({ sessionTemplateId, recurring }) => {
          this.loadedTemplateWithRecurring = Object.entries(recurring || {}).length > 0;
          return this.editor.refresh$(sessionTemplateId, true);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private isFormValid(): boolean {
    if (this.formUtils.markInvalidForm(this.form)) {
      this.updateNestedForms.next();
      return false;
    }

    return true;
  }

  didFormChange(): boolean {
    const currentFormState = this.form.getRawValue();
    const prevFormState = this.formSpanShot;

    return !!prevFormState && !isEqual(currentFormState, prevFormState);
  }

  private createPackageSessionTemplate(formData: DrawerSessionTemplateForm): PackageSessionTemplateInterface {
    const basicInfo = formData.basicInfo;
    const { hosts, sessionType } = formData.hosts;
    const participantsAndGuests = formData.participantsAndGuests;
    const restrictions = formData.restrictions;
    const availability = formData.availability;
    const location =
      this.workspacesService.isSolo || !this.workspacesService.isTeamAdmin
        ? formData.basicInfo.location
        : formData.location;

    return {
      afterEventBuffer: restrictions.afterEventBuffer,
      beforeEventBuffer: restrictions.beforeEventBuffer,
      bookingLimitsFrequency: restrictions.limitBookingFrequency,
      customAvailability: availability.customAvailability || null,
      disableGuests: participantsAndGuests.disableGuests,
      duration: basicInfo.length,
      hasAutoConfirmation: participantsAndGuests.hasAutoConfirmation,
      hasChat: false, // [ToDo]
      hosts: hosts.selectedHosts,
      location: location,
      minimumBookingNotice: restrictions.minimumBookingNotice,
      name: basicInfo.name,
      permission: this.editor.originalTemplate!.permission,
      removeFromChatOnSessionEnd: false, // [ToDo]
      scheduleId: availability.sessionTemplateScheduleId || null,
      seatsPerTimeSlot: participantsAndGuests.seatsPerTimeSlot,
      seatsShowAttendees: false, // [ToDo]
      serviceType: this.editor.originalTemplate!.serviceType,
      sessionType: sessionType,
      slotInterval: restrictions.slotInterval,
      viewChatHistory: false, // [ToDo],
      workspaceId: this.editor.originalTemplate!.workspaceId,
      restrictClientBooking: basicInfo.restrictClientBooking
    };
  }

  private createProgramSessionTemplate(formData: DrawerSessionTemplateForm): ProgramSessionTemplateInterface {
    const basicInfo = formData.basicInfo;
    const { hosts, sessionType } = formData.hosts;
    const participantsAndGuests = formData.participantsAndGuests;
    const restrictions = formData.restrictions;
    const availability = formData.availability;
    const location =
      this.workspacesService.isSolo || !this.workspacesService.isTeamAdmin
        ? formData.basicInfo.location
        : formData.location;

    if (hosts.selectedHosts.length === 0) {
      const host: SessionTemplateHost = {
        firstName: this.auth.user.firstName,
        lastName: this.auth.user.lastName,
        photo: this.auth.user.photo,
        userId: this.auth.user.id,
        email: this.auth.user.email,
        role: this.auth.user.RoleId,
        location
      };

      hosts.selectedHosts.push(host);
    }

    return {
      afterEventBuffer: restrictions.afterEventBuffer,
      beforeEventBuffer: restrictions.beforeEventBuffer,
      bookingLimitsFrequency: restrictions.limitBookingFrequency,
      limitFutureBookings: restrictions.limitFutureBookings,
      customAvailability: availability.customAvailability || null,
      disableGuests: participantsAndGuests.disableGuests,
      duration: basicInfo.length,
      hasAutoConfirmation: participantsAndGuests.hasAutoConfirmation,
      hasChat: false, // [ToDo]
      hosts: hosts.selectedHosts,
      location: location,
      minimumBookingNotice: restrictions.minimumBookingNotice,
      name: basicInfo.name,
      permission: this.editor.originalTemplate!.permission,
      removeFromChatOnSessionEnd: false, // [ToDo]
      scheduleId: availability.sessionTemplateScheduleId || null,
      seatsPerTimeSlot: participantsAndGuests.seatsPerTimeSlot,
      seatsShowAttendees: false, // [ToDo]
      serviceType: this.editor.originalTemplate!.serviceType,
      sessionType: sessionType,
      slotInterval: restrictions.slotInterval,
      viewChatHistory: false, // [ToDo],
      workspaceId: this.editor.originalTemplate!.workspaceId,
      restrictClientBooking: basicInfo.restrictClientBooking
    };
  }

  private createSessionTemplateFormValue(
    template: SessionTemplateInterface,
    sessionInfo?: SessionTemplateDrawerData['sessionInfo']
  ): DrawerSessionTemplateForm {
    return {
      basicInfo: {
        name: template.name,
        length: template.duration,
        location: template.location,
        count: sessionInfo?.count || 1,
        restrictClientBooking: template.restrictClientBooking
      },
      restrictions: {
        afterEventBuffer: template.afterEventBuffer,
        beforeEventBuffer: template.beforeEventBuffer,
        minimumBookingNotice: template.minimumBookingNotice,
        slotInterval: template.slotInterval,
        limitBookingFrequency: template.bookingLimitsFrequency,
        limitFutureBookings: template.limitFutureBookings,
        expirationPeriod: sessionInfo?.expirationPeriod
      },
      location: template.location,
      participantsAndGuests: {
        disableGuests: template.disableGuests,
        seatsPerTimeSlot: template.seatsPerTimeSlot,
        hasAutoConfirmation: template.hasAutoConfirmation
      },
      availability: {
        availabilities: template.availabilities,
        sessionTemplateScheduleId: template.scheduleId,
        customAvailability: template.customAvailability
      },
      hosts: {
        sessionType: template.sessionType || SessionType.PERSONAL,
        hosts: {
          search: '',
          hostsById: {},
          selectedHosts: template.hosts
        }
      }
    };
  }

  private initFormRenderSub(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  private initFormValue(): void {
    const { sessionInfo } = this.drawerRef.data;

    of({
      isNew: this.isDraft,
      isModified: this.isModified
    })
      .pipe(
        switchMap(({ isNew, isModified }) => {
          if (isModified) {
            return this.editor
              .parseTemplate(sessionInfo?.sessionTemplateDraft as SessionTemplateInterface)
              .pipe(switchMap(() => this.editor.template$));
          } else if (!isNew) {
            return this.editor.refresh$(sessionInfo?.sessionTemplateId!).pipe(switchMap(() => this.editor.template$));
          } else {
            return this.editor.template$;
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((template: SessionTemplateInterface) => {
        const sessionTemplateFormValues = this.createSessionTemplateFormValue(template, sessionInfo);

        this.form.setValue(sessionTemplateFormValues);

        this.formSpanShot = this.formSpanShot || this.form.getRawValue();

        this.sessionTemplatePermissionsService.applyPermissions(this.form, template.permission);
      });
  }
}
