import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import config from '@app/core/config/config';
import { IWorkspaceMember } from '@app/modules/workspaces/types';
import { tap } from 'rxjs/operators';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { GuideRelationTypes } from '@app/core/users/types';

export interface CustomFields {
  client_tags: unknown[];
}

export interface Contacts {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  organization?: string;
}

export type Guide = IWorkspaceMember;

export interface CRMClient {
  id: number;
  clientId: number;
  guideId: number;
  relationId: number;
  type: GuideRelationTypes;
  createdAt: Date;
  tag?: string;
  archivedAt?: unknown;
  customFields: CustomFields;
  firstName: string;
  lastName: string;
  archived?: unknown;
  photo: string;
  email: string;
  phone?: string;
  organization?: string;
}

export interface GuideClientsResult {
  clients: CRMClient[];
}

export interface CreateGuideClient {
  contacts: {
    firstName: string;
    lastName: string;
    organization?: string;
    email: string;
    phone?: string;
    notes?: string;
    tag?: string;
  }[];
  sendInvitation: boolean;
}

export interface CreateGuideClientsResult {
  relations: Pick<CRMClient, 'id' | 'type'>[];
}

@Injectable()
export class GuideClientsApiService {
  static CREATE_CONTACT_ENDPOINT = `${config.apiPath}/user/guide/relations/contacts`;
  constructor(private readonly httpClient: HttpClient, private notificationsService: NotificationsService) {}

  create(data: CreateGuideClient): Observable<CreateGuideClientsResult> {
    return this.httpClient
      .post<CreateGuideClientsResult>(GuideClientsApiService.CREATE_CONTACT_ENDPOINT, {
        contactsDetails: data
      })
      .pipe(
        tap(() => {
          if (data.contacts.length === 1) {
            const { firstName, lastName } = data.contacts[0];
            this.notificationsService.success(`New client "${firstName} ${lastName}" has been successfully added`);
          } else {
            this.notificationsService.success(`New ${data.contacts.length} clients have been successfully added`);
          }
        })
      );
  }

  get(): Observable<GuideClientsResult> {
    return this.httpClient.get<GuideClientsResult>(`${config.apiPath}/user/guide/crm/clients`);
  }

  patchMany(update: Partial<CRMClient> & { id: CRMClient['id'] }[]): Observable<unknown> {
    return this.httpClient.patch<void>(`${config.apiPath}/user/guide/crm/update`, update);
  }

  assignGuides(id: CRMClient['id'], guideIds: Guide['userId'][]): Observable<unknown> {
    return this.httpClient.post<unknown>(`${config.apiPath}/user/guide/relations/clients/${id}/guides`, { guideIds });
  }
}
