import { AbstractControl, UntypedFormArray, ValidationErrors, ValidatorFn } from '@angular/forms';
import { QuizQuestionType } from '@app/core/quizzes/types';

export function quizQuestionOptionsValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // @ts-expect-error TS2531
    const type = control.parent?.parent?.parent.value.type;
    const needValidation = type === QuizQuestionType.MULTIPLE_CHOICE || type === QuizQuestionType.SINGLE_CHOICE;
    if (needValidation) {
      const optionForms = control.parent?.parent as UntypedFormArray;
      // @ts-expect-error TS2531
      const options = optionForms?.controls?.map(control => control.get('text').value);
      const isError = !options?.some(x => !!x);
      return isError ? { emptyOptions: 'Empty options' } : null;
    }

    return null;
  };
}
