import { Directive, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { NgScrollbar } from 'ngx-scrollbar';
import { Subject } from 'rxjs';

@Directive({
  selector: '[appBottomScroll]'
})
export class BottomScrollDirective implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @Output()
  readonly bottomScrolled = new EventEmitter<number>();

  constructor(private readonly _ngScrollBar: NgScrollbar) {}

  ngOnInit(): void {
    this.subscribeToScrollbar();
  }

  private subscribeToScrollbar(): void {
    if (this._ngScrollBar) {
      this._ngScrollBar.scrolled
        .pipe(
          filter(event => {
            const target = event.target as HTMLElement;
            return target.scrollHeight - target.scrollTop - target.clientHeight < 1;
          }),
          takeUntil(this.destroy$)
        )
        .subscribe(() => this.bottomScrolled.emit());
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
