import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/core/auth/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { combineLatest, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { QuizService } from '@app/screens/guide/guide-surveys/services/quiz.service';
import { ClientsSelectorModalComponent } from '@app/shared/components/clients-selector/components/clients-selector-modal/clients-selector-modal.component';
import { QuizProgramsService } from '@app/screens/guide/guide-surveys/services/quiz-programs.service';
import { GuideMiniProgram } from '@app/screens/guide/guide-surveys/types/program';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { InternalEvents } from '@app/core/analytics/types';
import { GuideRelationTypes, GuideRelation } from '@app/core/users/types';
import { GuideClientsService } from '@app/core/users/guide-clients.service';

export type BoardItemType = 'quiz';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBoardItem {
  type: BoardItemType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
  createdAt?: string;
}

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-services-and-events-board',
  templateUrl: './services-and-events-board.component.html',
  styleUrls: ['./services-and-events-board.component.scss']
})
export class ServicesAndEventsBoardComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _guideUsers: GuideRelation[] = [];

  items: IBoardItem[] = [];

  // @ts-expect-error TS2564
  programs: GuideMiniProgram[];

  // @ts-expect-error TS2564
  programsGroups: { name: string; entries: number[] }[];

  guideRoute = environment.guideRoute;

  constructor(
    private readonly _auth: AuthService,
    private readonly _modal: NgbModal,
    private readonly _chatBotService: QuizService,
    private readonly _quizProgramsService: QuizProgramsService,
    private readonly _notificationService: NotificationsService,
    private readonly _analyticsService: AnalyticsService,
    private readonly _guideClients: GuideClientsService
  ) {}

  ngOnInit(): void {
    this._auth
      .onAuth()
      .pipe(
        filter(user => !!user),
        switchMap(() => this._chatBotService.getQuizzes$()),
        takeUntil(this.destroy$)
      )
      .subscribe(quizzes => (this.items = this.convertToBoardItems('quiz', quizzes)));

    combineLatest([this._quizProgramsService.getAllClientsAndPrograms$(), this._guideClients.clientsNumberAllowed])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([{ users, programs }, numberAllowed]) => {
        this.programs = programs;

        this.programsGroups = programs
          .filter(i => i.customers.length)
          .map(i => ({
            name: `All from program "${i.name}"`,
            entries: i.customers.map(j => j.id)
          }));

        const guideUsers = users.filter(usr => usr.type === GuideRelationTypes.GUIDE_CLIENT);

        if (numberAllowed !== Infinity) {
          // @ts-expect-error TS2322
          guideUsers.forEach(user => (user.disabled = user.archived));
        }

        this._guideUsers = guideUsers;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  removeQuiz(id: number) {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._chatBotService.deleteQuiz$(id).subscribe();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  duplicateQuiz(id: number) {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._chatBotService.duplicateQuiz$(id).subscribe();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  sendToClient(formId: number, selectedClients: string[]) {
    if (!selectedClients || selectedClients.length === 0) {
      return;
    }

    this._chatBotService
      .addClients(formId, selectedClients)
      .pipe(tap(() => this._analyticsService.event(InternalEvents.QUIZ_INVITED_CLIENT, {})))
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil, @typescript-eslint/no-unused-vars
      .subscribe(() => {
        this._notificationService.success(`Form sent to ${selectedClients.length > 1 ? 'clients' : 'client'}`);
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private convertToBoardItems(type: BoardItemType, items: any[]): IBoardItem[] {
    return (items || []).map(payload => ({ type, payload, createdAt: payload.createdAt }));
  }
}
