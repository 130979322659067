import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StarRatingModule } from 'angular-star-rating';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { BlogModule } from '@app/screens/blog/blog.module';
import { SharedModule } from '@app/shared';
import { GuidePublicProfileRoutingModule } from './guide-public-profile-routing.module';
import { EmbeddableBookButtonModule } from '@app/modules/embeddable-book-button/embeddable-book-button.module';
import { SessionBookOptionsModule } from '@app/modules/session-book-options/session-book-options.module';
import { GuideProfilePrivacyModule } from '@app/modules/guide-profile-privicy/guide-profile-privacy.module';

import { GuidePublicProfileComponent } from './guide-public-profile.component';
import { GuideWidgetComponent } from './guide-widget/guide-widget.component';
import { PublicProfileComponent } from './public-profile/public-profile.component';

import { BlogReaderService } from '@app/screens/blog/services/blog-reader.service';
import { PuiAvatarModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { TranslocoRootModule } from '@libs/transloco';

@NgModule({
  imports: [
    BlogModule,
    CommonModule,
    EmbeddableBookButtonModule,
    FormsModule,
    GuidePublicProfileRoutingModule,
    NgbModule,
    NgScrollbarModule,
    ReactiveFormsModule,
    SharedModule,
    StarRatingModule,
    SessionBookOptionsModule,
    GuideProfilePrivacyModule,
    PuiAvatarModule,
    TranslocoRootModule,
    PuiTypographyModule
  ],
  declarations: [GuidePublicProfileComponent, GuideWidgetComponent, PublicProfileComponent],
  exports: [PublicProfileComponent],
  providers: [BlogReaderService]
})
export class GuidePublicProfileModule {}
