import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateOverridesDrawerComponent } from '@app/modules/session-forms/drawers/date-overrides-drawer/date-overrides-drawer.component';
import {
  OverridesMapper,
  ProfiOverrideFlat
} from '@app/modules/session-forms/forms/custom-availability-form/override-mapper';
import {
  DateOverridesForm,
  Override,
  OverridesTime
} from '@app/modules/session-forms/forms/date-overrides-form/date-overrides-form.component';
import { DRAWER_CONFIG } from '@app/modules/session-forms/forms/session-availability-form/types';
import { PuiDestroyService, PuiDrawerService } from '@awarenow/profi-ui-core';
import { SessionTemplateAvailabilityOverrides } from '@app/screens/guide/guide-sessions-templates/types';
import { UserTimezoneStore } from '@libs/core/user-timezone.store';

@Component({
  selector: 'app-custom-availability-form',
  templateUrl: './custom-availability-form.component.html',
  styleUrls: ['./custom-availability-form.component.scss'],
  providers: [
    PuiDestroyService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CustomAvailabilityComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomAvailabilityComponent implements ControlValueAccessor {
  form = this.formBuilder.group({
    template: [null],
    overrides: [null],
    timeZone: ['']
  });

  constructor(
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>,
    private readonly cdRef: ChangeDetectorRef,
    private readonly drawerService: PuiDrawerService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly userTimezone: UserTimezoneStore
  ) {
    this.userTimezone.timezone$.pipe(take(1), takeUntil(this.destroy$)).subscribe(timezone => {
      this.form.patchValue(
        {
          timeZone: timezone
        },
        { emitEvent: false }
      );
    });
  }

  onTouched: () => void = () => {};

  writeValue(value: { [key: string]: unknown }): void {
    if (value) {
      this.form.patchValue(
        {
          ...value,
          overrides: OverridesMapper.fromCalToProfi(value.overrides as SessionTemplateAvailabilityOverrides)
        },
        { emitEvent: false }
      );
    }
  }

  registerOnChange(fn: (value: { [key: string]: unknown }) => void): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value.overrides) {
        value.overrides = OverridesMapper.fromProfiToCal(value.overrides);
      }

      fn(value);
    });
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  openOverrides(): void {
    const ref = this.drawerService.open(
      DateOverridesDrawerComponent,
      {
        ...DRAWER_CONFIG,
        hideCloseButton: true
      },
      {
        excludedDates: this.getExcludedDates()
      }
    );

    ref.afterClosed$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      const formValue = value as unknown as DateOverridesForm;

      if (formValue?.overrides) {
        const isoDate = DateTime.fromISO(new Date(formValue.date).toISOString()).toISODate();

        this.form.get('overrides')?.patchValue([
          ...(this.form.get('overrides')?.value || []),
          {
            date: isoDate,
            values: formValue.overrides
          }
        ]);

        this.cdRef.markForCheck();
      }
    });
  }

  editOverride(override: ProfiOverrideFlat, editItemId: number): void {
    const ref = this.drawerService.open(
      DateOverridesDrawerComponent,
      {
        ...DRAWER_CONFIG,
        hideCloseButton: true
      },
      {
        override,
        excludedDates: this.getExcludedDates(override.date)
      }
    );

    ref.afterClosed$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      const formValue = value as unknown as DateOverridesForm;

      if (formValue?.overrides) {
        const isoDate = DateTime.fromISO(new Date(formValue.date).toISOString()).toISODate();

        const overrides = [...(this.form.get('overrides')?.value || [])];

        overrides[editItemId] = {
          date: isoDate,
          values: formValue.overrides
        };

        this.form.patchValue({ overrides });

        this.cdRef.markForCheck();
      }
    });
  }

  formatTime(timeString: string): string {
    const [hourString, minute] = timeString.split(':');
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ':' + minute + (hour < 12 ? 'am' : 'pm');
  }

  convertToDateString(date: string): string {
    return DateTime.fromISO(date).toFormat('yyyy LLL dd');
  }

  isUnavailable(override: Override & { date: Date }): boolean {
    return override.startTime === OverridesTime.DEFAULT_TIME && override.endTime === OverridesTime.DEFAULT_TIME;
  }

  removeControl(index: number): void {
    const overrides = (this.form.get('overrides')?.value || []).filter((item: Override, idx: number) => index !== idx);
    this.form.get('overrides')?.patchValue(overrides);
  }

  private getExcludedDates(currentDate?: string): string[] {
    const overrides = this.form.get('overrides')?.value;

    if (!overrides) {
      return [];
    }

    return overrides
      .map((override: { date: string }) => override.date)
      .filter((date: string) => (currentDate ? date !== currentDate : true));
  }
}
