import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  PuiAlertModule,
  PuiButtonModule,
  PuiGroupModule,
  PuiIconModule,
  PuiInputCheckboxModule,
  PuiInputModule,
  PuiToggleModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { ParticipantsAndGuestsFormComponent } from './participants-and-guests-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MaskitoDirective } from '@maskito/angular';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PuiToggleModule,
    PuiInputModule,
    PuiAlertModule,
    PuiIconModule,
    PuiGroupModule,
    PuiInputCheckboxModule,
    PuiTypographyModule,
    PuiButtonModule,
    MaskitoDirective
  ],
  declarations: [ParticipantsAndGuestsFormComponent],
  exports: [ParticipantsAndGuestsFormComponent]
})
export class ParticipantsAndGuestsFormModule {}
