import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { FormService } from '@app/core/form/form.service';
import { removeUndefined } from '@app/screens/guide/guide-services/utils/helpers';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-quiz-choice-answer',
  templateUrl: './quiz-choice-answer.component.html',
  styleUrls: ['../quiz-custom-answer/quiz-custom-answer.component.scss', './quiz-choice-answer.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuizChoiceAnswerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => QuizChoiceAnswerComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuizChoiceAnswerComponent implements ControlValueAccessor, OnDestroy, OnInit, Validator {
  private readonly destroy$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _explanation: { isCorrect: boolean; text: string } | null = null;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isDisabled: boolean;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _otherOptionId = -1;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _optionKeys: string[] = [];

  @Input()
  // @ts-expect-error TS2564
  key: number | null;

  @Input()
  // @ts-expect-error TS2564
  multi: boolean;

  @Input()
  isCorrectDisplay = true;

  answeredOptions = new Map<number, boolean | null>();

  form = this._formBuilder.group({
    id: [null],
    question: [null],
    type: [null],
    options: [[]],
    answer: [[], []],
    answerOptions: this._formBuilder.array([])
  });

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get explanation() {
    return this._explanation;
  }

  get isDisabled(): boolean {
    return this._isDisabled;
  }

  get formOptions(): UntypedFormArray {
    return this.form.get('answerOptions') as UntypedFormArray;
  }

  get optionKeys(): string[] {
    return this._optionKeys;
  }

  get question(): string {
    return this.form.value.question || '';
  }

  get isOtherSelected(): boolean {
    return [...this.answeredOptions.keys()].includes(this._otherOptionId);
  }

  constructor(private _formBuilder: UntypedFormBuilder, private formService: FormService) {}

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ id, question, type, options, answerOptions, answer }) => {
        if (answerOptions) {
          this.setAnsweredOptions(answerOptions, options);
        }

        if (id || question || type || options || answer) {
          this.onChange({
            id,
            question,
            options,
            type,
            answer: {
              text: answer,
              options: [...this.answeredOptions.keys()].map(optionId => ({ id: optionId }))
            }
          });
          if (this.isOtherSelected) {
            // @ts-expect-error TS2531
            this.form.get('answer').setValidators([Validators.required]);
          } else {
            // @ts-expect-error TS2531
            this.form.get('answer').clearValidators();
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  trackBy(_: number, control: AbstractControl) {
    return control.value?.option?.id;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
    (<any>control).markAsDirtyAndTouched = () => {
      this.form.markAsTouched();
      this.form.markAsDirty();
      this.formService.markFormDirtyAndTouched(this.form);
    };
    return this.form.valid ? null : { invalidForm: { valid: false } };
  }

  setDisabledState(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
    if (isDisabled) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(controlValue: any): void {
    const { id, question, type, answer, options } = controlValue;
    this.applyAnswer(answer);

    // @ts-expect-error TS7006
    const other = options?.find(option => option.other);
    this._otherOptionId = other ? other.id : -1;

    this.form.patchValue(removeUndefined({ id, question, type, options, answer: answer ? answer.text : null }), {
      emitEvent: false
    });
    this.form.setControl('answerOptions', this._formBuilder.array(this.toAnswerOptions(options) || []));
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private applyAnswer(answer) {
    this.answeredOptions.clear();

    let isAnswerFullyCorrect = true;

    // @ts-expect-error TS7006
    ((answer && answer.options) || []).forEach(option => {
      this.answeredOptions.set(option.id, option.isCorrect != null ? option.isCorrect : null);
      isAnswerFullyCorrect = isAnswerFullyCorrect && !!option.isCorrect;
    });

    // somehow explanation property gets filled with \r\n symbols if more than one option were selected
    if (answer.explanation && answer.explanation.replace(/\r?\n|\r/g, '').length > 0) {
      this._explanation = { isCorrect: isAnswerFullyCorrect, text: answer.explanation };
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onChange: any = () => {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onTouched: any = () => {};

  // @ts-expect-error TS7006
  private refreshFormAnswerOptions(answerOptions): void {
    this.formOptions.setValue(
      // @ts-expect-error TS7031
      answerOptions.map(({ text, option }) => ({
        text: text,
        option: { id: option.id, isSelected: this.answeredOptions.has(option.id) }
      })),
      { emitEvent: false }
    );
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private setAnsweredOptions(answerOptions, options) {
    const answeredOptions = answerOptions
      // @ts-expect-error TS7031
      .filter(({ option: { isSelected } }) => isSelected)
      // @ts-expect-error TS7031
      .map(({ option: { id } }) => id);

    if (answeredOptions.length && !this.multi && this.answeredOptions.size) {
      // @ts-expect-error TS7006
      const answeredOptionsNotIncludedYet = answeredOptions.filter(id => !this.answeredOptions.has(id));

      if (answeredOptionsNotIncludedYet.length) {
        this.answeredOptions.clear();
        // @ts-expect-error TS7006
        answeredOptionsNotIncludedYet.forEach(id =>
          // @ts-expect-error TS7006
          this.answeredOptions.set(id, options?.find(opt => opt.id === id).isCorrect)
        );
        this.refreshFormAnswerOptions(answerOptions);
      }
    } else {
      this.answeredOptions.clear();
      // @ts-expect-error TS7006
      answeredOptions.forEach(id => this.answeredOptions.set(id, options?.find(opt => opt.id === id).isCorrect));
    }
  }

  // @ts-expect-error TS7006
  private toAnswerOptions(questionOptions): UntypedFormGroup[] {
    // @ts-expect-error TS7031
    return questionOptions?.map(({ id, text }) =>
      this._formBuilder.group({
        text,
        option: this._formBuilder.control({ id, isSelected: this.answeredOptions.has(id) })
      })
    );
  }
}
