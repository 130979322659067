<div
  class="pui-container"
  [@hiding]="hide ? 'hide' : 'show'"
  [ngClass]="alert.className"
  [attr.data-qa-id]="'alert-' + alert.template">
  <div class="pui-row">
    <div class="pui-col">
      <div class="app-alert__container">
        <div class="app-alert__icon"></div>
        <div class="app-alert__content">
          <ng-template
            [ngIf]="
              alert.template !== AlertKey.INCOMPLETE_GUIDE_PROFILE &&
              alert.template !== AlertKey.TRIAL_SUBSCRIPTION_MEMBERSHIP &&
              alert.template !== AlertKey.TRIAL_LTD_MEMBERSHIP &&
              alert.template !== AlertKey.CLIENT_LIMIT_REACHED &&
              alert.template !== AlertKey.TEAM_MEMBERSHIP &&
              alert.template !== AlertKey.TRIAL_SUBSCRIPTION_MEMBERSHIP_TEAM &&
              alert.template !== AlertKey.TRIAL_SUBSCRIPTION_MEMBERSHIP_TEAM_FINISHED
            ">
            <div pui-typography="h3" [innerHTML]="alert.title" data-qa-id="alert-title"></div>
            <div
              pui-typography="body-s"
              class="app-alert__description"
              [innerHTML]="alert.description"
              data-qa-id="alert-description"></div>
          </ng-template>
          <ng-template [ngIf]="alert.template === AlertKey.INCOMPLETE_GUIDE_PROFILE">
            <div pui-typography="h3" [innerHTML]="alert.title" data-qa-id="alert-title"></div>
            <div pui-typography="body-s" class="app-alert__description" data-qa-id="alert-description">
              {{ alert.description }}
              <a routerLink="/{{ guideRoute }}/settings/edit-profile" i18n="@@clickToEditProfile">
                Click to edit profile
              </a>
            </div>
          </ng-template>
          <ng-template
            [ngIf]="
              alert.template === AlertKey.TRIAL_SUBSCRIPTION_MEMBERSHIP_TEAM ||
              alert.template === AlertKey.TRIAL_SUBSCRIPTION_MEMBERSHIP_TEAM_FINISHED
            ">
            <div pui-typography="h3" [innerHTML]="alert.title" data-qa-id="alert-title"></div>
            <button [id]="alert.template" i18n="@@bookCall" (click)="openIntercom()">Book a call</button>
          </ng-template>
          <ng-template
            [ngIf]="
              alert.template === AlertKey.TRIAL_LTD_MEMBERSHIP ||
              alert.template === AlertKey.TRIAL_SUBSCRIPTION_MEMBERSHIP
            ">
            <div pui-typography="h3" [innerHTML]="alert.title" data-qa-id="alert-title"></div>
            <a routerLink="/platform-subscription" i18n="@@seletPlan" data-qa-id="alert-select-plan-button">
              Select plan
            </a>
          </ng-template>
          <ng-template [ngIf]="alert.template === AlertKey.TEAM_MEMBERSHIP">
            <div pui-typography="h3" [innerHTML]="alert.title" data-qa-id="alert-title"></div>
            <button i18n="@@talkToSupport" (click)="openIntercom()">Talk to support</button>
          </ng-template>
          <ng-template [ngIf]="alert.template === AlertKey.CLIENT_LIMIT_REACHED">
            <div pui-typography="h3" [innerHTML]="alert.title" data-qa-id="alert-title"></div>
            <a routerLink="/platform-subscription" i18n="@@increaseLimitByUpgrading">by upgrading.</a>
          </ng-template>
        </div>
        <div>
          <button pui-icon-button (click)="close()" aria-label="close" appearance="flat-secondary">
            <pui-icon svgIcon="pui:x" [size]="40" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
