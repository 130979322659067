import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, forwardRef, Inject } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Timezones } from '@app/shared/utils/timezones';
import { PuiDestroyService } from '@awarenow/profi-ui-core';

interface TimezoneOption {
  name: string;
  value: string;
}

export interface TimezoneForm {
  search: string;
  timezone: string;
}

@Component({
  selector: 'app-timezone-form',
  templateUrl: './timezone-form.component.html',
  styleUrls: ['./timezone-form.component.scss'],
  providers: [
    PuiDestroyService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TimezoneFormComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimezoneFormComponent implements ControlValueAccessor {
  timezoneControl = new UntypedFormControl(DateTime.local().zoneName);
  searchControl = new UntypedFormControl('');

  timezones: TimezoneOption[] = this.generateTimezones();

  constructor(@Inject(PuiDestroyService) private readonly destroy$: Observable<void>) {}

  readonly filterTimezonesFn = (search: string) => (timezoneItem: TimezoneOption) => {
    if (!search?.trim()) {
      return true;
    }

    return (
      timezoneItem.name?.toLowerCase().includes(search.toLowerCase()) ||
      timezoneItem?.value?.toLowerCase() === this.timezoneControl.value?.toLowerCase()
    );
  };

  onTouched: () => void = () => {};

  writeValue(value: { [key: string]: unknown }): void {
    if (value) {
      this.timezoneControl.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: (value: { [key: string]: unknown }) => void): void {
    this.timezoneControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  private generateTimezones(): TimezoneOption[] {
    const getTimezoneOption = (tzName: string): TimezoneOption => {
      const dt = DateTime.local().setZone(tzName);
      return {
        name: `${tzName} (${dt.toFormat('ZZZZ')})`,
        value: tzName
      };
    };

    const localTimezone = DateTime.local().zoneName;

    return ([] as TimezoneOption[]).concat(
      [getTimezoneOption(localTimezone)],
      Timezones.filter(tzName => tzName !== localTimezone).map(tzName => getTimezoneOption(tzName))
    );
  }
}
