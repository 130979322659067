import { environment } from '@env/environment';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@app/core/auth/services/auth.service';
import { Router } from '@angular/router';
import { UserRoles } from '@app/shared/enums/user-roles';
import { BrandingService } from '@app/core/branding/branding.service';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-dashboard',
  template: ''
})
export class DashboardComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private router: Router,
    private brandingService: BrandingService,
    @Inject(GLOBAL_WINDOW) private _browserWindow: Window
  ) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnInit() {
    this.brandingService.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(({ homePageRoute }) => {
      let navigateUrl;
      switch (this.authService.user.RoleId) {
        case UserRoles.CLIENT:
          navigateUrl = '/client/dashboard';
          break;
        case UserRoles.GUIDE:
          navigateUrl = `/${environment.guideRoute}/dashboard`;
          break;
        case UserRoles.ADMIN:
          navigateUrl = '/admin/dashboard';
          break;
        default:
          navigateUrl = homePageRoute;
          break;
      }
      this.router.navigate([navigateUrl], { replaceUrl: true }).then(() => {
        // In a situation where an unauthorized user opens the site root "/",
        // the main layout module does not get the user role. In this case, we need to reload the page.
        // We need to figure out how to make the loading of the application sequential.
        // Authentication first, then loading the main application.
        if (!this.authService.user?.RoleId) {
          this._browserWindow.location?.reload();
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
