import { IEnvironment } from '@env/enviroment.interface';

export const environment: IEnvironment = {
  NAME: 'development',
  production: true,

  baseUrl: 'https://test.awarenow.io',
  signalHost: 'https://test-signal.awarenow.io',
  chatHost: 'https://test-messenger.awarenow.io',
  notificationsHost: 'https://test-notif.awarenow.io',

  apiHost: 'https://test-api.awarenow.io',
  sentryKey: 'https://9cf7806dccf7437e83671d8c7ce96895@o651554.ingest.sentry.io/5762883',

  disqusId: 'awarenow-test', // 'awarenow-test',

  bookWidgetBaseHref: '/book',

  guidesRoute: 'coaches',
  guideRoute: 'coach',

  dateTimeLocale: 'en-US',

  membershipRoute: 'subscription',

  enableLinksInMainHeader: true,
  publicEventsRoute: 'public-events',

  /**
   * @description Sets the time format
   * @type {string}
   * @see https://angular.io/api/common/DatePipe#pre-defined-format-options
   */
  timeFormat: 'h:mma',

  featureFlagServiceId: '62391d3c7c57a814b9c0c6ee',

  platformStyle: {
    DAY: 'd',
    WEEK_DAY: 'EEEE',
    MONTH: 'MMM',
    YEAR: 'yyyy',
    TIME: 'HH:mm',
    HUMANIZE_MILLISECONDS: 24 * 60 * 60 * 1000,
    DEFAULT: 'yyyy-MM-dd',

    // custom for 'en-US'
    TIME_WITH_MINUTES: 'h:mma',
    TIME_WITHOUT_MINUTES: 'ha'
  },

  alvisId: 'wxvjy5ad',
  alvisCheckListId: 'wxvjy5ad',
  graphqlHost: 'https://service.test.awarenow.io/graphql'
};
