import { Injectable, InjectionToken, Injector } from '@angular/core';
import { ConnectionPositionPair, Overlay, OverlayConfig, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Subject } from 'rxjs';
import { FloatingPopoverRef } from './floating-popover-ref';
import { FloatingPopoverComponent } from './floating-popover.component';
import { IFloatingPopoverParams } from './types';

export const POPOVER_COMPONENT_DATA = new InjectionToken<{}>('POPOVER_COMPONENT_DATA');

@Injectable({
  providedIn: 'root'
})
export class FloatingPopoverService {
  closeModalSource = new Subject<boolean | void>();

  constructor(private _overlay: Overlay, private _injector: Injector) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  open<T>({
    origin,
    content,
    data,
    width,
    height,
    position,
    componentData,
    positionPair,
    panelClass
  }: IFloatingPopoverParams<T>) {
    const overlayRef = this._overlay.create(
      this.getOverlayConfig({ origin, width, height, position, positionPair, panelClass })
    );
    const popoverRef = new FloatingPopoverRef(overlayRef, content, data);
    const injector = this.createInjector(popoverRef, this._injector, componentData);
    overlayRef.attach(new ComponentPortal(FloatingPopoverComponent, null, injector));

    return popoverRef;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private createInjector(popoverRef: FloatingPopoverRef, injector: Injector, data: any) {
    const tokens = new WeakMap();
    tokens.set(FloatingPopoverRef, popoverRef);
    tokens.set(POPOVER_COMPONENT_DATA, data);
    return new PortalInjector(injector, tokens);
  }

  // @ts-expect-error TS7031
  private getOverlayConfig({ origin, width, height, position, positionPair, panelClass }): OverlayConfig {
    return new OverlayConfig({
      panelClass,
      width,
      height,
      hasBackdrop: true,
      backdropClass: 'popover-backdrop',
      positionStrategy: position
        ? this.getGlobalOverlayPosition(position)
        : this.getConnectedOverlayPosition(origin, positionPair),
      scrollStrategy: this._overlay.scrollStrategies.reposition()
    });
  }

  private getConnectedOverlayPosition(origin: HTMLElement, positionPair: ConnectionPositionPair[]): PositionStrategy {
    return this._overlay
      .position()
      .flexibleConnectedTo(origin)
      .withPositions(positionPair ?? this.getPositions())
      .withPush(false);
  }

  // @ts-expect-error TS7031
  private getGlobalOverlayPosition({ top, right, bottom, left }): PositionStrategy {
    const positionStrategy = this._overlay.position().global();

    if (top) {
      positionStrategy.top(top);
    } else {
      positionStrategy.bottom(bottom);
    }

    if (left) {
      positionStrategy.left(left);
    } else {
      positionStrategy.right(right);
    }

    return positionStrategy;
  }

  private getPositions(): ConnectionPositionPair[] {
    return [
      {
        originX: 'end',
        overlayX: 'start',
        originY: 'top',
        overlayY: 'top'
      },
      {
        originX: 'start',
        overlayX: 'end',
        originY: 'top',
        overlayY: 'top'
      },
      {
        originX: 'end',
        overlayX: 'start',
        originY: 'bottom',
        overlayY: 'bottom'
      },
      {
        originX: 'start',
        overlayX: 'end',
        originY: 'bottom',
        overlayY: 'bottom'
      }
    ];
  }
}
