import { Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { nonEmptyValidator } from '@app/shared/form-validators/non-empty.validator';
import { SubscriptionRecurrency, SubscriptionLength } from '@app/shared/enums/subscription';
import { Testimonial } from '@app/modules/guide-service-editor/types/testimonial';
import { FAQuestion } from '@app/modules/guide-service-editor/types/faq';
import { User } from '@app/shared/interfaces/user';
import { ProgramCoauthorsService } from '../../services/program-coauthors.service';
import { GuideProgramOptionsService } from '../../services/guide-program-options.service';
import { ProgramContent, IProgramOptions, IProgramContent } from '../../types';
import { servicePricingValidator } from '../../validators/service-pricing.validator';
import { AuthService } from '@app/core/auth/services';

export type ContentProgramPaymentTypes = 'free' | 'paid' | 'subscription';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({ template: '' })
// tslint:disable-next-line:component-class-suffix
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ContentFormManager implements OnDestroy {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _contentForm: UntypedFormGroup;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _coauthorSubscriptions: Subscription[] = [];

  private readonly destroy$ = new Subject<void>();

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _programOptionsValues: IProgramOptions;

  get contentForm(): UntypedFormGroup {
    return this._contentForm;
  }

  constructor(
    readonly programOptions: GuideProgramOptionsService,
    readonly programCoauthors: ProgramCoauthorsService,
    protected readonly _formBuilder: UntypedFormBuilder,
    protected readonly _auth: AuthService
  ) {
    this.programOptions.options$
      .pipe(takeUntil(this.destroy$))
      .subscribe(optionsValues => (this._programOptionsValues = optionsValues));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.resetCoauthorSubscriptions();
  }

  addCoauthor(): void {
    const coauthorsFormArray = this._contentForm.get('coauthors') as UntypedFormArray;
    const newCoauthorGroup = this._formBuilder.group({
      name: [],
      id: [],
      firstName: [],
      lastName: []
    });
    coauthorsFormArray.push(newCoauthorGroup);
    this.addToCoauthorSubscriptions(newCoauthorGroup, coauthorsFormArray.length - 1);
  }

  addCoverImages(urls: { coverImage: string | null; coverImageThumb: string | null }): void {
    this._contentForm.patchValue({
      coverImage: urls.coverImage,
      coverImageThumb: urls.coverImageThumb
    });
  }

  addFAQuestion(): void {
    const newFAQuestion = new FAQuestion();

    const newFAQuestionFormGroup = this._formBuilder.group({
      localId: [newFAQuestion.localId],
      question: [newFAQuestion.question],
      answer: [newFAQuestion.answer]
    });

    (this._contentForm.get('faq') as UntypedFormArray).push(newFAQuestionFormGroup);
  }

  addKeyPoint(): void {
    (this._contentForm.get('keyPoints') as UntypedFormArray).push(this._formBuilder.control(''));
  }

  addLanguage(): void {
    const newLanguageFormGroup = this._formBuilder.control(null);
    (this._contentForm.get('languages') as UntypedFormArray).push(newLanguageFormGroup);
  }

  removeCoauthor(index: number): void {
    this.removeFromCoauthorSubscriptions(index);
    (this._contentForm.get('coauthors') as UntypedFormArray).removeAt(index);
  }

  removeFAQuestion(index: number): void {
    (this._contentForm.get('faq') as UntypedFormArray).removeAt(index);
  }

  removeKeyPoint(index: number): void {
    (this._contentForm.get('keyPoints') as UntypedFormArray).removeAt(index);
  }

  setContentForm(originalContent: ProgramContent): void {
    const content = this.applyDefaultContentValues(originalContent);

    if (!this._contentForm) {
      this.initializeContentForm(content);
    } else {
      this.updateContentForm(content);
    }

    if (content.startType === 'user_enrollment') {
      // @ts-expect-error TS2531
      this._contentForm.get('startDate').disable();
    }
  }

  togglePriceValidation(enablePriceValidation: boolean): void {
    const pricingFormGroup = this._contentForm.get('pricingFormGroup');

    // @ts-expect-error TS2531
    if (enablePriceValidation && pricingFormGroup.validator == null) {
      // @ts-expect-error TS2531
      pricingFormGroup.setValidators(servicePricingValidator());
      // @ts-expect-error TS2531
    } else if (!enablePriceValidation && pricingFormGroup.validator != null) {
      // @ts-expect-error TS2531
      pricingFormGroup.clearValidators();
    }

    // @ts-expect-error TS2531
    pricingFormGroup.updateValueAndValidity();
  }

  toProgramContent(): IProgramContent {
    const {
      keyPoints,
      coauthors,
      pricingFormGroup: {
        paymentType,
        price,
        subscriptionPrice,
        subscriptionRecurrency,
        subscriptionLength,
        totalPayments,
        isInstallmentsEnabled,
        doNotShowPrice
      },
      ...content
    } = this._contentForm.getRawValue();

    content.keyPoints = keyPoints
      // @ts-expect-error TS7006
      .map(keyPoint => keyPoint.trim())
      // @ts-expect-error TS7006
      .filter(keyPoint => keyPoint)
      .join('\n');
    // @ts-expect-error TS7031
    content.coauthors = coauthors.map(({ id, firstName, lastName }) => new User(id, firstName, lastName));

    content.isFree = paymentType === 'free';
    content.fixedPrice = paymentType === 'paid';
    content.hidePrice = paymentType === 'free' && doNotShowPrice;
    content.price = paymentType === 'paid' ? price : 0;
    content.subscriptionPrice =
      paymentType === 'subscription' || (paymentType === 'paid' && isInstallmentsEnabled) ? subscriptionPrice : 0;
    content.subscriptionRecurrency =
      paymentType === 'subscription' || (paymentType === 'paid' && isInstallmentsEnabled)
        ? subscriptionRecurrency
        : null;
    content.totalPayments =
      (paymentType === 'subscription' && subscriptionLength === SubscriptionLength.Finite) ||
      (paymentType === 'paid' && isInstallmentsEnabled)
        ? totalPayments
        : null;

    return content;
  }

  private addToCoauthorSubscriptions(control: UntypedFormGroup, index: number): void {
    this._coauthorSubscriptions.push(
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      control.valueChanges.pipe(debounceTime(200)).subscribe(({ name }) => {
        this.programCoauthors.refresh(
          index,
          name,
          // @ts-expect-error TS2531
          this._contentForm.get('coauthors').value.map(({ id }) => +id)
        );
      })
    );
  }

  private applyDefaultContentValues(content: ProgramContent): ProgramContent {
    const newContent = content.clone();

    const isAuthor: boolean = this._auth.user.id === content.authorId;

    newContent.startType = newContent.startType || 'program_release';
    newContent.startDate = newContent.startDate || DateTime.local().startOf('day').toISO();

    newContent.subscriptionRecurrency = newContent.subscriptionRecurrency || SubscriptionRecurrency.Month;

    // @ts-expect-error TS2531
    if (!newContent.faq.length && isAuthor) {
      // @ts-expect-error TS2531
      newContent.faq.push(new FAQuestion());
    }

    // @ts-expect-error TS2531
    if (!newContent.testimonials.length && isAuthor) {
      // @ts-expect-error TS2531
      newContent.testimonials.push(new Testimonial());
    }

    newContent.isHiddenForBook = true;

    return newContent;
  }

  private initializeContentForm(content: ProgramContent): void {
    this._contentForm = this._formBuilder.group({
      additionalNotes: [content.additionalNotes],
      approaches: [content.approaches],
      coverImage: [content.coverImage],
      coverImageThumb: [content.coverImageThumb],
      description: [content.description],
      issues: [content.issues],
      languages: [content.languages],
      level: [content.level],
      length: [content.length],
      name: [content.name, [Validators.required, nonEmptyValidator, Validators.maxLength(254)]],
      startDate: [content.startDate],
      startType: [content.startType],
      status: [content.status],
      isHiddenForBook: [content.isHiddenForBook],
      coauthors: this._formBuilder.array(this.toCoauthorsFormControls(content)),
      faq: this._formBuilder.array(this.toFAQFormGroups(content)),
      keyPoints: this._formBuilder.array(this.toKeyPoints(content)),
      testimonials: this._formBuilder.array(this.toTestimonialsFormGroups(content)),
      pricingFormGroup: this.toPricingFormGroup(content)
    });

    // @ts-expect-error TS2531
    this._contentForm
      .get('startType')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (value === 'program_release') {
          // @ts-expect-error TS2531
          this._contentForm.get('startDate').enable();
        } else {
          // @ts-expect-error TS2531
          this._contentForm.get('startDate').disable();
        }
      });
  }

  private getPaymentType(content: ProgramContent): ContentProgramPaymentTypes {
    if (content.isFree) {
      return 'free';
    }

    if (content.fixedPrice) {
      return 'paid';
    }

    return 'subscription';
  }

  private removeFromCoauthorSubscriptions(index: number): void {
    if (this._coauthorSubscriptions[index]) {
      this._coauthorSubscriptions[index].unsubscribe();
      this._coauthorSubscriptions.splice(index, 1);
    }
  }

  private resetCoauthorSubscriptions(): void {
    if (this._coauthorSubscriptions && this._coauthorSubscriptions.length) {
      this._coauthorSubscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }

  private toCoauthorsFormControls(content: ProgramContent): UntypedFormGroup[] {
    this.resetCoauthorSubscriptions();

    return content.coauthors
      ? content.coauthors.map((coauthor, index) => {
          const coauthorGroup = this._formBuilder.group({
            name: [coauthor.name],
            id: [coauthor.id],
            firstName: [coauthor.firstName],
            lastName: [coauthor.lastName]
          });
          this.addToCoauthorSubscriptions(coauthorGroup, index);
          return coauthorGroup;
        })
      : [];
  }

  private toFAQFormGroups(content: ProgramContent): UntypedFormGroup[] {
    return content.faq
      ? content.faq.map(faQuestion =>
          this._formBuilder.group({
            id: [faQuestion.id],
            localId: [faQuestion.localId],
            question: [faQuestion.question],
            answer: [faQuestion.answer]
          })
        )
      : [];
  }

  private toKeyPoints(content: ProgramContent): UntypedFormControl[] {
    return content.keyPoints ? content.keyPoints.split('\n').map(keyPoint => this._formBuilder.control(keyPoint)) : [];
  }

  private toPricingFormGroup(content: ProgramContent): UntypedFormGroup {
    return this._formBuilder.group(
      {
        paymentType: [this.getPaymentType(content)],
        doNotShowPrice: [content.hidePrice],
        price: [content.price],
        subscriptionPrice: [content.subscriptionPrice],
        subscriptionRecurrency: [content.subscriptionRecurrency],
        totalPayments: [content.totalPayments],
        isInstallmentsEnabled: [!!content.price && !!content.subscriptionPrice],
        subscriptionLength: [content.totalPayments ? SubscriptionLength.Finite : SubscriptionLength.Infinite]
      },
      {
        validator: servicePricingValidator()
      }
    );
  }

  private toTestimonialsFormGroups(content: ProgramContent): UntypedFormGroup[] {
    return content.testimonials
      ? content.testimonials.map(testimonial =>
          this._formBuilder.group({
            id: [testimonial.id],
            localId: [testimonial.localId],
            clientInfo: [testimonial.clientInfo],
            text: [testimonial.text]
          })
        )
      : [];
  }

  private updateContentForm(content: ProgramContent): void {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      coauthors,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      faq,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      keyPoints,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      testimonials,
      price,
      subscriptionPrice,
      subscriptionRecurrency,
      totalPayments,
      hidePrice,
      ...otherContent
    } = content;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (otherContent as any).pricingFormGroup = {
      paymentType: this.getPaymentType(content),
      price,
      subscriptionPrice,
      subscriptionRecurrency,
      totalPayments,
      doNotShowPrice: hidePrice,
      isInstallmentsEnabled: !!price && !!subscriptionPrice,
      subscriptionLength: totalPayments ? SubscriptionLength.Finite : SubscriptionLength.Infinite
    };

    this._contentForm.patchValue(otherContent);

    this._contentForm.setControl('coauthors', this._formBuilder.array(this.toCoauthorsFormControls(content)));
    this._contentForm.setControl('faq', this._formBuilder.array(this.toFAQFormGroups(content)));
    this._contentForm.setControl('keyPoints', this._formBuilder.array(this.toKeyPoints(content)));
    this._contentForm.setControl('testimonials', this._formBuilder.array(this.toTestimonialsFormGroups(content)));
  }
}
