<ng-container *ngIf="activeChat$ | async as chat">
  <ng-container *ngIf="chat && chat.messages && chat.messages.length">
    <ng-scrollbar visibility="hover" class="chat-messages-infinite-scroll">
      <div class="chat-messages-offset"></div>

      <div class="chat-messages-container" #chatMessagesRef>
        <div *ngFor="let message of chat.messages; trackBy: trackMessage" [ngSwitch]="message.type" #messageRef>
          <app-chat-date-separator
            *ngSwitchCase="ChatBoardMessageTypes.DATE_SEPARATOR"
            [date]="message.payload"></app-chat-date-separator>

          <app-chat-service-separator
            *ngSwitchCase="ChatBoardMessageTypes.SERVICE_SEPARATOR"
            [service]="message.payload"></app-chat-service-separator>

          <app-chat-new-user
            *ngSwitchCase="ChatBoardMessageTypes.JOINED_CHAT"
            [message]="message.payload"></app-chat-new-user>

          <app-chat-message
            *ngSwitchCase="'MESSAGE'"
            [message]="message.payload"
            [sender]="chat.users[message.payload.sender.id]"
            (sendMessage)="sendMessage.emit($event)"
            [ngClass]="{
              sessioned: message.payload?.meta?.eventId,
              'last-message': message.payload?.meta?.lastSessionMessage
            }"></app-chat-message>

          <app-chat-file
            *ngSwitchCase="'FILE'"
            [message]="message.payload"
            [sender]="chat.users[message.payload.sender.id]"
            [ngClass]="{
              sessioned: message.payload?.meta?.eventId,
              'last-message': message.payload?.meta?.lastSessionMessage
            }"></app-chat-file>
        </div>
      </div>
    </ng-scrollbar>
  </ng-container>
</ng-container>
<button *ngIf="hasButton" class="scroll-down" (click)="scrollToLastMessage(true)">
  <pui-icon svgIcon="pui:show-more-small" size="20"></pui-icon>
</button>
