<h3 pui-typography="h3">Select clients</h3>

<div class="pui-space_top-4">
  <clients-select [(ngModel)]="selectedClients" [clients]="clients" />
</div>

<div class="pui-space_top-5">
  <button class="pui-space_right-3" pui-button [disabled]="selectedClients.length === 0" (click)="submit()">
    Submit
  </button>
  <button pui-button appearance="secondary" (click)="cancel()">Cancel</button>
</div>
