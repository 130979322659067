import { Pipe, PipeTransform } from '@angular/core';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { ConnectionType } from '@awarenow/profi-json-joy-kit';

@Pipe({
  name: 'connectionType',
  standalone: true
})
export class ConnectionTypePipe implements PipeTransform {
  private readonly platformName =
    this.runtimeConfigService.get('videoLocationCustomName') || this.runtimeConfigService.get('platformName');

  constructor(private readonly runtimeConfigService: RuntimeConfigService) {}

  transform(connectionType: SessionConnectionTypes | ConnectionType): string {
    if (!connectionType) {
      return '';
    }

    return this.getConnectionType(connectionType);
  }

  private getConnectionType(connectionType: SessionConnectionTypes | ConnectionType): string {
    switch (connectionType) {
      case 'in_person':
        return `In person`;
      case 'in_platform':
        return this.platformName || 'Platform';
      case 'zoom':
        return `Zoom`;
      case 'phone':
        return `Phone call`;
      default:
        return `Unknown`;
    }
  }
}
