import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ITestimonial, Testimonial } from '@app/modules/guide-service-editor/types/testimonial';

@Injectable()
export class GuideTestimonialsEditorService {
  constructor(private _formBuilder: UntypedFormBuilder) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  createTestimonialsSettings() {
    return this._formBuilder.array([this._formBuilder.group(new Testimonial())]);
  }

  testimonialsSettingsToForm(data: ITestimonial[]): Testimonial[] {
    return data.map(testimonial => new Testimonial().setValues(testimonial));
  }

  // prepareTestimonialsData(testimonialsFormValue: ITestimonialsSettingsForm) {
  // }
}
