<div class="modal-header">
  <app-button data-qa-id="invite-clients-modal-close-button" className="close" (click)="close()"></app-button>
</div>

<div class="modal-body">
  <h4 data-qa-id="invite-clients-modal-invite-clients-title" class="title" i18n="@@inviteClients">Invite clients</h4>

  <div class="note" data-qa-id="invite-clients-modal-note-text">
    <ng-container i18n="@@inviteClientsNotePart1">They will be invited to the</ng-container>
    <app-service-type [type]="type"></app-service-type>
    <ng-container i18n="@@inviteClientsNotePart2">via email.</ng-container>
  </div>

  <form [formGroup]="invitationsForm" class="form">
    <clients-select
      [clients]="clients"
      formControlName="invitations"
      data-qa-id="invite-clients-modal-clients-select" />

    <div class="invite-clients-modal__footer pui-space_top-6">
      <div style="flex-grow: 1">
        <app-checkbox formControlName="isPrepaid" data-qa-id="invite-clients-modal-enroll-without-payment-checkbox">
          <ng-container i18n="@@enrollClientsInThe">Enroll client(s) in the</ng-container>
          <app-service-type [type]="type"></app-service-type>
          <ng-container i18n="@@withoutPayment">without payment</ng-container>
        </app-checkbox>
      </div>
      <button
        pui-button
        i18n="@@send"
        [disabled]="invitationsForm.invalid"
        (click)="send()"
        size="m"
        data-qa-id="invite-clients-modal-send-button">
        Send
      </button>
      <button
        pui-button
        appearance="secondary"
        data-qa-id="invite-clients-modal-cancel-button"
        size="m"
        (click)="close()"
        i18n="@@cancel">
        Cancel
      </button>
    </div>
  </form>
</div>
