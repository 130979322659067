import { ChangeDetectionStrategy, Component, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PuiDialogService, PuiDialog } from '@awarenow/profi-ui-core';
import { ChatUser } from '@app/core/chat/types';
import { AuthService } from '@app/core/auth/services/auth.service';
import { UserRoles } from '@app/shared/enums/user-roles';
import { ChatsService } from '@app/core/chat/chats.service';
import { ActiveChat, IChatBoardMessage } from '../../types';
import { ChatParticipantsModalComponent } from '../chat-participants-modal/chat-participants-modal.component';

enum MyDialogResult {
  CLOSE,
  LEAVE
}

@Component({
  selector: 'app-in-group-chat-banner',
  templateUrl: './in-group-chat-banner.component.html',
  styleUrls: ['./in-group-chat-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InGroupChatBannerComponent implements OnDestroy {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _chat: ActiveChat<IChatBoardMessage, ChatUser>;

  private readonly destroy$ = new Subject<void>();

  isCoach = false;

  @Input()
  set chat(chat: ActiveChat<IChatBoardMessage, ChatUser>) {
    this._chat = chat;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get chat() {
    return this._chat;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get numOfParticipants() {
    return (
      (this.chat &&
        Object.values(this.chat.users).filter(user => !user.isBlocked && user.role !== UserRoles.GUIDE).length) ||
      0
    );
  }

  constructor(
    private _router: Router,
    private _modal: NgbModal,
    private _changeDetector: ChangeDetectorRef,
    private _dialog: PuiDialogService,
    private _auth: AuthService,
    private _notifications: NotificationsService,
    private _chatService: ChatsService
  ) {
    this.isCoach = this._auth.user.RoleId === UserRoles.GUIDE;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  openLeaveChatModal() {
    const dialog = new PuiDialog();

    dialog.title = `Leave ${this.chat.name}?`;
    dialog.description = `You won't be able to rejoin.`;

    dialog.buttons = [
      {
        label: 'Cancel',
        appearance: 'secondary',
        click: ref => {
          ref.close(MyDialogResult.CLOSE);
        }
      },
      {
        label: 'Leave',
        appearance: 'error',
        click: ref => {
          ref.close(MyDialogResult.LEAVE);
        }
      }
    ];

    const ref = this._dialog.open(dialog, {
      hasCloseButton: false,
      size: 's'
    });

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    ref.afterClosed$.pipe(take(1)).subscribe(result => {
      if (result === MyDialogResult.LEAVE) {
        return this.leaveChat();
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  openParticipantsModal() {
    const ref = this._dialog.open(ChatParticipantsModalComponent, {
      data: {
        chat: this.chat
      }
    });

    ref.afterClosed$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this._changeDetector.detectChanges();
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  goToServiceLanding() {
    if (!this.chat.meta?.serviceId) {
      return;
    }

    this._router.navigate([`/sessions`, this.chat.meta.serviceId]);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private leaveChat() {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._chatService.leaveChat(this._chat.id).subscribe(() => {
      const message = `Chat removed`;
      this._notifications.success(message);
    });
  }
}
