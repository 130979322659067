<pui-toggle
  class="restrictions-form__toggle pui-space_bottom-2"
  [ngModel]="enabled.value"
  (ngModelChange)="limitBookingToggle($event)"></pui-toggle>
<div class="d-flex flex-column w-100 toggle-description">
  <div class="d-flex flex-column pui-space_bottom-2">
    <span pui-typography="body-s-bold" i18n="@@LimitBookingFrequency">Limit booking frequency</span>
    <span
      class="restrictions-form__toggle-description"
      pui-typography="body-s"
      i18n="@@LimitHowBookingFrequencyDescription"
      >Limit how many times this event can be booked.</span
    >
  </div>

  <ng-container *ngIf="enabled.value" [formGroup]="form">
    <div *ngFor="let control of form.controls; let i = index">
      <div class="restrictions-form__frequency-row pui-space_bottom-2" [formGroupName]="i">
        <pui-input-field class="restrictions-form__frequency-num">
          <input
            formControlName="bookingLimitsNum"
            data-qa-id="guide-service-editor-limit-booking-frequency-number"
            pui-input-text
            type="text"
            inputmode="numeric"
            [maskito]="mask" />
        </pui-input-field>
        <pui-select
          class="restrictions-form__frequency-cycle"
          [maxHeight]="300"
          [valueTemplate]="valueContentTemplate"
          [optionContent]="optionContentTemplate"
          data-qa-id="guide-service-editor-limit-booking-frequency-type"
          formControlName="bookingLimitsType">
          <ng-container *puiLet="notSelectedOptions(control.get('bookingLimitsType').value) as options">
            <pui-option
              *ngFor="let option of options"
              [value]="option.value"
              [data]="{
                label: option.label,
                selected: control.get('bookingLimitsType').value === option.value
              }"></pui-option>
          </ng-container>
        </pui-select>

        <ng-template #valueContentTemplate let-option="option">
          <span>{{ option.data.label }}</span>
        </ng-template>
        <ng-template #optionContentTemplate let-option="option">
          <span>{{ option.data.label }}</span>
        </ng-template>
        <button
          (click)="removeFrequencyControl(i)"
          class="restrictions-form__remove-button"
          pui-icon-button
          appearance="flat"
          [disabled]="form.disabled">
          <pui-icon svgIcon="pui:trash"></pui-icon>
        </button>
      </div>
      <pui-input-error
        class="pui-space_bottom-2"
        [formControl]="form.at(i)?.get('bookingLimitsNum')"
        role="alert"></pui-input-error>
    </div>

    <button
      *ngIf="form.controls.length < 4"
      (click)="addFrequencyControl()"
      class="restrictions-form__add-button"
      pui-link
      [disabled]="form.disabled">
      <pui-icon size="s" svgIcon="pui:plus-circle"></pui-icon>
      <span>Add limit</span>
    </button>
  </ng-container>
</div>
