import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, race, timer } from 'rxjs';
import {
  ScreenSize,
  ScreenState,
  StyleBreakpointsProviderService
} from '@app/modules/ui-kit/_base/_common/screen/services/style-breakpoints-provider.service';
import { map, mapTo } from 'rxjs/operators';
import { BREAKPOINTS } from '@app/modules/ui-kit/_base/_common/screen/config';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '@app/core/auth/services';
import { SidenavModeService } from '@app/modules/sidenav';

export type PrimaryLayoutSidenavOptions = Pick<MatSidenav, 'mode' | 'opened'>;

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPrimaryLayoutView {
  isMobile: boolean;
  sidenav: PrimaryLayoutSidenavOptions;
}

type ResolutionAccordingToMode = {
  [key in keyof typeof BREAKPOINTS]: MatDrawerMode;
};

type PrimaryLayoutScreenBreakpoints = Pick<ResolutionAccordingToMode, 'Mobile' | 'STablet' | 'MTablet' | 'Desktop'>;

const DEFAULT_VIEW_STATE: IPrimaryLayoutView = {
  // @ts-expect-error TS2322
  isMobile: undefined,
  sidenav: {
    mode: 'side',
    opened: false
  }
};

@Injectable()
export class PrimaryLayoutService {
  static DEFAULT_VIEW_STATE: IPrimaryLayoutView = DEFAULT_VIEW_STATE;

  resolutionAccordingToMode: PrimaryLayoutScreenBreakpoints = {
    Mobile: 'over',
    STablet: 'over',
    MTablet: 'side',
    Desktop: 'side'
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _breakpoints$: Observable<Partial<ScreenState>>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isCollapsed$: Observable<boolean>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isForceHideHeader = new BehaviorSubject(false);

  constructor(
    breakpoints: StyleBreakpointsProviderService,
    private readonly _modeService$: SidenavModeService,
    private readonly _authService: AuthService
  ) {
    this._breakpoints$ = breakpoints.observe([
      ScreenSize.Mobile,
      ScreenSize.STablet,
      ScreenSize.MTablet,
      ScreenSize.Desktop
    ]);
    this._isCollapsed$ = _modeService$.pipe(map(mode => mode === 'short'));
  }

  getView$(): Observable<IPrimaryLayoutView> {
    // @ts-expect-error TS2322
    return combineLatest([
      this._breakpoints$,
      this._isCollapsed$,
      this._isForceHideHeader,
      race(this._authService.onAuth(), timer(500)).pipe(mapTo(this._authService.isAuthorized))
    ]).pipe(
      map(([breakpoints, collapsed, isForceHideHeader, isAuthorized]) => {
        return {
          isMobile: breakpoints[ScreenSize.Mobile],
          sidenav: {
            mode: this._resolveMode(breakpoints),
            opened: (isAuthorized && (breakpoints.MTablet || breakpoints.Desktop)) || !collapsed,
            isForceHideHeader
          }
        };
      })
    );
  }

  toggle(): void {
    this._modeService$.toggle();
  }

  hideHeader(value: boolean): void {
    this._isForceHideHeader.next(value);
  }

  /**
   * FROM
   * {
      Mobile: true,     |
      Tablet: false,    |   &  isCollapsed
      Desktop: false,   |
    }
   *
   * TO: 'over' | 'push' | 'side'
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _resolveMode = (state: Partial<ScreenState>): MatDrawerMode => {
    let sidenavMode = PrimaryLayoutService.DEFAULT_VIEW_STATE.sidenav.mode;

    Object.entries(this.resolutionAccordingToMode).forEach(([resolution, mode]) => {
      // @ts-expect-error TS7053
      if (state[resolution]) {
        sidenavMode = mode;
      }
    });

    return sidenavMode;
  };
}
