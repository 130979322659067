import { NgModule } from '@angular/core';
import { Apollo, APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { type ApolloClientOptions, InMemoryCache, split } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '@env/environment';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { Kind } from 'graphql';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import extractFiles from 'extract-files/extractFiles.mjs';
import { AuthService } from '@app/core/auth/services';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { IWorkspace } from '@app/modules/workspaces/types';
import { getOfferPolicy } from '@app/features/offer/policies/get-offer.policy';

const uri = environment.graphqlHost;

export function createApollo(httpLink: HttpLink, authSevice: AuthService): ApolloClientOptions<unknown> {
  const http = httpLink.create({
    uri,
    withCredentials: true,
    extractFiles
  });

  const ws = new GraphQLWsLink(
    createClient({
      url: uri?.replace(/^(http:\/\/)|(https:\/\/)/, match => (match === 'http://' ? 'ws://' : 'wss://'))!,
      shouldRetry: () => true,
      connectionParams: {
        token: authSevice.getTokenFromCookie()
      }
    })
  );

  const link = split(
    // Split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === Kind.OPERATION_DEFINITION && definition.operation === 'subscription';
    },
    ws,
    http
  );

  return {
    link,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            ...getOfferPolicy
          }
        }
      }
    })
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AuthService]
    }
  ]
})
export class GraphQLModule {
  constructor(
    private readonly apollo: Apollo,
    private workspacesService: WorkspacesService
  ) {
    this.handleActiveWorkspaceChange();
  }

  private handleActiveWorkspaceChange() {
    this.workspacesService.activeWorkspace$
      .pipe(
        filter(Boolean),
        distinctUntilChanged((prev: IWorkspace | undefined, curr: IWorkspace) => prev?.id === curr.id)
      )
      .subscribe(() => {
        this.apollo.client.resetStore();
      });
  }
}
