import { NotificationsService } from '@awarenow/profi-ui-core';
import { DateTime } from 'luxon';
import { of, Subject } from 'rxjs';
import { mergeMap, take, takeUntil, tap } from 'rxjs/operators';

import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/auth/services/auth.service';
import { ChatsBotsService } from '@app/core/chat/chats-bots.service';
import { ChatsUsersService } from '@app/core/chat/chats-users.service';
import { GuideOfferApiService } from '@app/core/session/guide-offer-api.service';
import { SessionsService } from '@app/core/session/sessions.service';
import { UserWalletService } from '@app/core/user-wallet/user-wallet.service';
import { ClientGuidesService } from '@app/core/users/client-guides.service';
import { GuideRelationTypes } from '@app/core/users/types';
import { buildUsersGuideMapKey, getUserKey } from '@app/core/users/utils';
import { IGuideService, IServiceBookingOptions, ServiceBookingService } from '@app/modules/book-service';
import { BookingService } from '@app/modules/book-service/services/booking.service';
import { IPayWithModalOptions } from '@app/modules/current-payment/components/pay-with-modal/pay-with-modal.component';
import { CurrentPaymentService } from '@app/modules/current-payment/services/current-payment.service';
import { ServiceReschedulingService } from '@app/modules/rescheduling-service';
import { ServiceSchedulingService } from '@app/modules/service-scheduling/services/service-scheduling.service';
import { ClientSessionsActionsService } from '@app/screens/client/client-dashboard/services/client-sessions-actions.service';
import { IClientGuideSession } from '@app/screens/client/client-dashboard/types/types';
import { GuideSessionsService } from '@app/screens/guide/guide-sessions/services/events';
import { SessionStatuses } from '@app/shared/enums/session-statuses';
import { SessionTypes } from '@app/shared/enums/session-types';
import { UserRoles } from '@app/shared/enums/user-roles';
import { Session, SimpleSession } from '@app/shared/interfaces/session';
import { ClientSessionActionsService } from '@libs/business/client/client-session';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-in-chat-session-manager',
  templateUrl: './in-chat-session-manager.component.html',
  styleUrls: ['./in-chat-session-manager.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'ui-in-chat-session-manager' },
  providers: [GuideOfferApiService, ClientSessionsActionsService, GuideSessionsService]
})
export class InChatSessionManagerComponent implements OnDestroy {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _participantId: number;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _participant: any;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _bot = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _participantIdChanged$ = new Subject<{ id: number | string; bot?: boolean; workspaceId?: number }>();

  private readonly destroy$ = new Subject<void>();

  extendedInfo = false;

  timezone = DateTime.local().zoneName;

  @Input()
  set participantDetails(value: { id: number; bot?: boolean; workspaceId?: number }) {
    this._participantId = value.id;
    this._bot = !!value.bot;
    const key =
      !this._bot && value.workspaceId ? { id: value.id, workspaceId: value.workspaceId } : { id: getUserKey(value) };
    this._participantIdChanged$.next(key);
  }

  // @ts-expect-error TS2564
  @Input() session: Session;

  @Output()
  goToUser = new EventEmitter<{ id: number; type: GuideRelationTypes }>();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get bot() {
    return this._bot;
  }

  get isCurrentUserGuide(): boolean {
    return this._auth.isAuthorized && this._auth.user.RoleId === UserRoles.GUIDE;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get participant(): any {
    return this._participant;
  }

  get isIndividualSessionRequest(): boolean {
    return (
      !!this.session &&
      (([SessionStatuses.PENDING_APPROVEMENT, SessionStatuses.RESCHEDULE_BY_CLIENT].includes(this.session.status) &&
        this.isCurrentUserGuide) ||
        ([SessionStatuses.GUIDE_OFFER, SessionStatuses.RESCHEDULE_BY_GUIDE].includes(this.session.status) &&
          !this.isCurrentUserGuide &&
          this.session.serviceType === SessionTypes.SESSION))
    );
  }

  get isGroupSessionRequest(): boolean {
    return (
      !!this.session &&
      (([SessionStatuses.PENDING_APPROVEMENT, SessionStatuses.RESCHEDULE_BY_CLIENT].includes(this.session.status) &&
        this.isCurrentUserGuide) ||
        ([SessionStatuses.GUIDE_OFFER, SessionStatuses.RESCHEDULE_BY_GUIDE].includes(this.session.status) &&
          !this.isCurrentUserGuide)) &&
      this.session.serviceType === SessionTypes.GROUP_INSTANCE
    );
  }

  constructor(
    private _serviceBooking: ServiceBookingService<IServiceBookingOptions<IGuideService>, void>,
    private _auth: AuthService,
    private _router: Router,
    private _sessionsActor: ClientSessionsActionsService,
    private serviceSchedulingService: ServiceSchedulingService,
    private _userWallet: UserWalletService,
    private _users: ChatsUsersService,
    private _bots: ChatsBotsService,
    private _guideSessions: GuideSessionsService,
    private clientGuidesService: ClientGuidesService,
    private _currentPaymentService: CurrentPaymentService,
    private _rescheduler: ServiceReschedulingService,
    private _notificationsService: NotificationsService,
    private _sessions: SessionsService,
    private bookingService: BookingService,
    private readonly guideSessionsService: GuideSessionsService,
    private readonly clientSessionActions: ClientSessionActionsService
  ) {
    this._participantIdChanged$
      .pipe(
        mergeMap(({ id, workspaceId }) => {
          if (id) {
            if (this.bot) {
              return this._bots.getUser$(id as number);
            }
            return this._users.getUser$({ id, workspaceId });
          }
          return of(null);
        }),
        tap(user => (this._participant = user)),
        tap(user => {
          if (this.bot) {
            this.clientGuidesService
              // @ts-expect-error TS2531
              .getUser$(buildUsersGuideMapKey({ id: user.parent, workspaceId: user.workspaceId }))
              .pipe(takeUntil(this.destroy$))
              .subscribe(guide => {
                this._participant.guide = guide;
              });
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  goToParticipant() {
    if (this._bot || !this._participant) {
      return;
    }

    if (this._auth.user.RoleId === UserRoles.CLIENT) {
      return this._router.navigate([`/${this._participant.namedUrl}`]);
    }

    return this.goToUser.emit({ id: this._participant.id, type: GuideRelationTypes.GUIDE_CLIENT });
  }

  acceptOffer(): void {
    const clientSession = {
      guide: this.participant,
      session: this.session,
      timezone: this.timezone
    } as IClientGuideSession;
    this.clientSessionActions.acceptOffer(clientSession);
  }

  acceptRequest(): void {
    this._sessions.acceptSessionRequest$(this.session).pipe(takeUntil(this.destroy$)).subscribe();
  }

  approveReschedulement(): void {
    const clientSession = {
      guide: this.participant,
      session: this.session,
      timezone: this.timezone
    } as IClientGuideSession;
    this.clientSessionActions.approveReschedulement(clientSession, true);
  }

  bookSession(): void {
    this.bookingService.guideIndividualPage(this._participant.id, this._participant.workspaceId);
  }

  clientCancelSession(): void {
    const clientSession = {
      guide: this.participant,
      session: this.session,
      timezone: this.timezone
    } as IClientGuideSession;

    this.clientSessionActions.cancelSession(clientSession);
  }

  createOffer(): void {
    this.serviceSchedulingService.proposeService({ clientsIds: [this._participant.id] }, { onlyClients: true });
  }

  declineOffer(): void {
    const clientSession = {
      guide: this.participant,
      session: this.session,
      timezone: this.timezone
    } as IClientGuideSession;

    this.clientSessionActions.declineOffer(clientSession);
  }

  declineRequest(): void {
    this.guideSessionsService.decline(this.session as SimpleSession);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  guideCancelSession(session: SimpleSession) {
    this._guideSessions.decline(session);
  }

  reschedule(): void {
    const isGuide = this._auth.user.RoleId === UserRoles.GUIDE;
    if (isGuide) {
      this.guideSessionsService.reschedule(new SimpleSession(this.session));
    } else {
      const clientSession = {
        guide: this.participant,
        session: this.session,
        timezone: this.timezone
      } as IClientGuideSession;
      this.clientSessionActions.rescheduleSession(clientSession);
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _processSessionOfferAcceptance(offer: Session, isFree = false): void {
    this._currentPaymentService.pay(isFree).sessionOffer$(offer.id).pipe(takeUntil(this.destroy$)).subscribe();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private async _selectPaymentType(offer: Session): Promise<void> {
    const modalOptions: IPayWithModalOptions = {
      title: 'Accept offer',
      // @ts-expect-error TS2322
      amount: offer.payRate
    };

    const result$ = this._serviceBooking.selectPaymentType$(modalOptions);

    result$.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => {
      this._processSessionOfferAcceptance(offer);
    });
  }

  isEmail(email: unknown): boolean {
    return !Validators.email({ value: email } as AbstractControl);
  }
}
