<!--<div class="auth-body">-->
<!--  <div class="auth-form">-->
<!--    <ul>-->
<!--      <li *ngFor="let error of errors">-->
<!--        {{ error }}-->
<!--      </li>-->
<!--    </ul>-->

<!--    <h2 i18n="@@resetPassword">Reset Password</h2>-->

<!--    <form [formGroup]="resetPasswordForm" novalidate>-->
<!--      <label for="password" i18n="@@newPassword">New password</label>-->
<!--      <input-->
<!--        formControlName="password"-->
<!--        id="password"-->
<!--        type="password"-->
<!--        placeholder="Password"-->
<!--        class="form-control"-->
<!--        i18n-placeholder="@@password" />-->
<!--    </form>-->

<!--    <div class="auth-buttons">-->
<!--      <app-button className="btn-primary btn-lg" (click)="resetPassword()" i18n="@@reset">Reset</app-button>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<div class="pui-space_bottom-4">
  <h2 pui-typography i18n="@@resetPassword">Reset Password</h2>
</div>
<form class="pui-form" [formGroup]="form" (submit)="$event.preventDefault(); resetPassword()">
  <div class="pui-form__row">
    <div class="pui-form__field">
      <!--Password-->
      <label pui-input-label i18n="@@newPassword" for="new-password"> New password </label>
      <pui-input-field>
        <input
          id="new-password"
          autocomplete
          formControlName="newPassword"
          pui-input-text
          type="password"
          placeholder="Enter password" />
      </pui-input-field>

      <pui-input-error formControlName="newPassword" role="alert"></pui-input-error>
    </div>
  </div>
  <div class="pui-form__row">
    <div class="pui-form__field">
      <!--Password-->
      <label pui-input-label i18n="@@repeatPassword" for="new-password-repeat"> Repeat password </label>
      <pui-input-field>
        <input
          id="new-passworod-repeat"
          formControlName="newPasswordRepeat"
          pui-input-text
          type="password"
          placeholder="Enter password" />
      </pui-input-field>
      <pui-input-error formControlName="newPasswordRepeat" role="alert"></pui-input-error>
    </div>
  </div>
  <div class="pui-form__buttons">
    <button pui-button i18n="@@reset">Reset</button>
  </div>
</form>
