import { NotificationsService } from '@awarenow/profi-ui-core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';

import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@app/core/auth/services/auth.service';
import { SessionsService } from '@app/core/session/sessions.service';
import {
  GuideEventActionTypes,
  IEventActionResult,
  LinkTypes,
  SessionEvent
} from '@app/core/shared-event-actions/types';
import { GuideClientsService } from '@app/core/users/guide-clients.service';
import { GuideClient } from '@app/core/users/types';
import { WindowService } from '@app/core/window/window.service';
import { SessionActionResultModalComponent } from '@app/modules/session-action-result/components/session-action-result-modal/session-action-result-modal.component';
import { CancelSessionModalComponent } from '@app/modules/session-cancellation/components/cancel-session-modal/cancel-session-modal.component';
import { IGuideClientAvailableSession, IGuideClientSession } from '@app/screens/guide/guide-dashboard/types';
import { GuideDeclineDrawerService } from '@app/screens/guide/guide-sessions/components/drawers/guide-decline-drawer/guide-decline-drawer.service';
import { GuideRescheduleDrawerService } from '@app/screens/guide/guide-sessions/components/drawers/guide-reschedule-drawer/guide-reschedule-drawer.service';
import { sessionsMapRecurringSessions } from '@app/screens/guide/guide-sessions/components/guide-sessions-board/session-map-recurring.utils';
import { GuideServiceScheduleApiService } from '@app/screens/guide/guide-sessions/services/events/guide-service-schedule-api.service';
import { FullCalendarEvent } from '@app/screens/guide/guide-sessions/types';
import {
  buildGuideClientsMap,
  futureSessionsToFullCalendarEvents,
  pastSessionsToFullCalendarEvents,
  prepareGuideClientSessions,
  sessionOffersToFullCalendarEvents,
  sessionRequestsToFullCalendarEvents
} from '@app/screens/guide/guide-sessions/utils/event-transformers';
import {
  filterFromGroupRequests,
  filterFromSimpleRequests,
  filterPastSessions
} from '@app/screens/guide/guide-sessions/utils/filters';
import { compose } from '@app/screens/guide/guide-sessions/utils/helpers';
import { isOffer, SessionStatuses } from '@app/shared/enums/session-statuses';
import { SessionTypes } from '@app/shared/enums/session-types';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import {
  AvailableSession,
  GroupSession,
  isGroupSession,
  isSimpleSession,
  Session,
  SimpleSession
} from '@app/shared/interfaces/session';
import { User } from '@app/shared/interfaces/user';
import { generateTimezoneOptions } from '@app/shared/utils/generate-timezones';
import { modalResultToObservable$ } from '@app/shared/utils/modal-result-to-observable';
import { PuiDrawerRef } from '@awarenow/profi-ui-core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class GuideSessionsService implements OnDestroy {
  private destroy$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _futureSessions$!: Observable<IGuideClientSession[]>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _guideClientsMap$!: Observable<{ [id: number]: GuideClient }>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _pastSessions$!: Observable<IGuideClientSession[]>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _availableSessions$!: Observable<IGuideClientAvailableSession[]>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _requests$!: Observable<IGuideClientSession[]>;
  private privateRequestSessions$!: Observable<IGuideClientSession[]>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _sessions$!: Observable<FullCalendarEvent[]>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _timezones: { name: string; value: string }[] | null = null;

  get futureSessions$(): Observable<IGuideClientSession[]> {
    return this._futureSessions$;
  }

  get pastSessions$(): Observable<IGuideClientSession[]> {
    return this._pastSessions$;
  }

  get availableSessions$(): Observable<IGuideClientAvailableSession[]> {
    return this._availableSessions$;
  }

  get requests$(): Observable<IGuideClientSession[]> {
    return this._requests$;
  }

  get requestSessions$(): Observable<IGuideClientSession[]> {
    return this.privateRequestSessions$;
  }

  get sessions$(): Observable<FullCalendarEvent[]> {
    return this._sessions$;
  }

  get timezones(): { name: string; value: string }[] {
    if (!this._timezones) {
      this._timezones = generateTimezoneOptions();
    }

    return this._timezones;
  }

  constructor(
    private _auth: AuthService,
    private _sessions: SessionsService,
    private _guideClients: GuideClientsService,
    private _modal: NgbModal,
    private _runningSessionsWindow: WindowService,
    private readonly declineDrawer: GuideDeclineDrawerService,
    private readonly rescheduleDrawer: GuideRescheduleDrawerService,
    private readonly notifications: NotificationsService,
    private readonly guideServiceScheduleApi: GuideServiceScheduleApiService
  ) {
    this.initializeGuideClientsMap();
    this.initializeAllSessions();
    this.initializeSessions();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  accept(session: SimpleSession, showResult = false): void {
    if (session.status === SessionStatuses.PENDING_APPROVEMENT) {
      this._sessions.acceptSessionRequest$(session).subscribe(() => {
        this._guideClients.refresh();
      });
    } else {
      this._sessions.approveReschedule$(session.id, session.collectionType).subscribe(() => {
        if (showResult) {
          const { user } = session;
          this.showSessionActionResult(
            this.createGuideSessionActionResult(GuideEventActionTypes.ACCEPT_RESCHEDULE, session, user)
          );
        }
      });
    }
  }

  // ATTENTION: temp workaround for backward compatibility (with old sessions dashboard)
  acceptRequest(session: SimpleSession): void {
    this._sessions.acceptSessionRequest$(session).subscribe();
  }

  archive(session: Session): void {
    this._sessions.archiveSession$(session).subscribe();
  }

  markAsMissed(session: Session, description: string): Observable<void> {
    return this._sessions.markAsMissed$(session, description);
  }

  markAsAbsent(id: number, description: string): Observable<void> {
    return this._sessions.markAsAbsent$(id, description);
  }

  markAsPresent(eventId: number): void {
    this._sessions.markAsPresent$(eventId).subscribe();
  }

  markAsCompleted(session: Session): Observable<void> {
    return this._sessions.markAsCompleted$(session);
  }

  decline(
    session: SimpleSession,
    showResult = true,
    callback?: () => void,
    additionalData?: { clientName?: string; date?: string }
  ): void {
    this.declineDrawer
      .openDeclineConformation$(session, additionalData?.clientName, additionalData?.date)
      .pipe(
        switchMap(reason => {
          const cancelAllRecurring = session.recurringSessionsDetails?.length !== 1;
          if (isOffer(session.status)) {
            return this._sessions.declineSessionOffer$(
              session.id,
              reason as string,
              session.collectionType,
              session.clientId,
              session,
              cancelAllRecurring
            );
          }

          return this._sessions.refuseSession$(
            session.id,
            reason,
            session.collectionType,
            session.clientId,
            session,
            cancelAllRecurring
          );
        })
      )
      .subscribe(() => {
        if (callback) {
          callback();
        }

        if (showResult) {
          const isRecurringResult =
            Array.isArray(session.recurringSessionsDetails) && session.recurringSessionsDetails?.length > 1;
          if (isRecurringResult) {
            this.notifications.success(
              `The request for ${session.recurringSessionsDetails?.length} x ${session.name} is declined`
            );
          } else {
            this.notifications.success(`The request for ${session.name} is declined`);
          }
        }
      });
  }

  // @ts-expect-error TS7006
  end(session): void {
    this._sessions.endSession$(session.eventId).subscribe();
    this._runningSessionsWindow.closeSessionPopup();
  }

  // TODO to return obs
  reschedule(session: Session, showResult = true, parent: PuiDrawerRef | boolean | null = null): void {
    if (!isSimpleSession(session)) {
      return;
    }

    this.rescheduleDrawer
      .openRescheduleConfirmation$(session, !!parent)
      .pipe(
        switchMap(result => {
          const request = {
            eventId: session.eventId,
            date: result!.date,
            timezone: result?.timezone,
            reasonMessage: result?.reason,
            doubleBooking: result?.doubleBooking
          };

          return this.guideServiceScheduleApi.rescheduleEvent$(request);
        }),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (parent instanceof PuiDrawerRef) {
          parent.close();
        }
        if (showResult) {
          const message = `The request to reschedule ${session.name} is submitted`;
          this.notifications.success(message);
        }
      });
  }

  showSessionActionResult(actionResult: IEventActionResult): void {
    this._showSessionActionResult$(actionResult).subscribe();
  }

  confirmSessionCancellation$(
    session: Session,
    serviceType: GuideServiceTypes | SessionTypes = GuideServiceTypes.SESSION,
    numOfParticipants = 1,
    hideDate = false
  ): Observable<string | null> {
    const user = isSimpleSession(session) ? session.user : null;

    const { componentInstance, result } = this._modal.open(CancelSessionModalComponent);
    componentInstance.user = user;
    componentInstance.serviceType = serviceType;
    componentInstance.numOfParticipants = numOfParticipants;
    componentInstance.sessionName = session.name;

    if (!hideDate) {
      componentInstance.sessionDate = session.dateStart;
    }

    if (session.status === SessionStatuses.PENDING_APPROVEMENT) {
      componentInstance.cancellationType = 'decline';
    }

    return modalResultToObservable$(result);
  }

  createGuideSessionActionResult(
    actionType: GuideEventActionTypes,
    session: Session,
    user: User | null = null,
    replacements = {}
  ): IEventActionResult {
    return {
      complete: true,
      actionType,
      eventDetails: new SessionEvent({
        user,
        date: session.dateStart,
        duration: session.duration,
        status: session.status,
        name: session.name,
        // eslint-disable-next-line id-length
        links: session.zoomMeetings ? session.zoomMeetings.map(m => ({ ...m, type: LinkTypes.ZOOM })) : null,
        ...replacements
      })
    };
  }

  private initializeAllSessions(): void {
    this._requests$ = this.initializeSessionObservable(
      this._sessions.sessionRequests$.pipe(map(sessions => sessionsMapRecurringSessions(sessions)))
    ) as Observable<IGuideClientSession[]>;
    this.privateRequestSessions$ = this.initializeSessionObservable(this._sessions.sessionRequests$) as Observable<
      IGuideClientSession[]
    >;
    this._futureSessions$ = this.initializeSessionObservable(this._sessions.futureSessions$) as Observable<
      IGuideClientSession[]
    >;
    this._pastSessions$ = this.initializeSessionObservable(this._sessions.pastSessions$) as Observable<
      IGuideClientSession[]
    >;
    this._availableSessions$ = this.initializeSessionObservable(this._sessions.availableSessions$, false) as Observable<
      IGuideClientAvailableSession[]
    >;
  }

  private initializeGuideClientsMap(): void {
    this._guideClientsMap$ = combineLatest([this._guideClients.clients$, this._guideClients.clientsNumberLeft]).pipe(
      map(([clients, clientsNumberLeft]) => buildGuideClientsMap(clients, clientsNumberLeft)),
      takeUntil(this.destroy$)
    );
  }

  private initializeSessionObservable(
    source$: Observable<Session[] | AvailableSession[]>,
    unwrapGroupSessions = true
  ): Observable<IGuideClientSession[] | IGuideClientAvailableSession[]> {
    const {
      user: { timezone }
    } = this._auth;
    const guide = { timezone };

    const sessions$ = unwrapGroupSessions
      ? source$.pipe(
          map(sessions => {
            // @ts-expect-error TS7034
            const response = [];

            sessions.forEach(session => {
              if (isSimpleSession(session)) {
                response.push(session);
              } else if (isGroupSession(session) && session.sessions && session.sessions.length) {
                session.sessions.forEach(groupSessionInstance => response.push(groupSessionInstance));
              }
            });

            // @ts-expect-error TS7005
            return response;
          })
        )
      : source$;

    return combineLatest([sessions$, this._guideClientsMap$]).pipe(
      map(([sessions, clients]) => prepareGuideClientSessions(sessions, guide, clients)),
      takeUntil(this.destroy$)
    );
  }

  private initializeSessions(): void {
    this._sessions$ = combineLatest([
      this._sessions.sessionRequests$.pipe(map(compose(sessionRequestsToFullCalendarEvents, filterFromGroupRequests))),
      this._sessions.pastSessions$.pipe(map(compose(pastSessionsToFullCalendarEvents, filterPastSessions))),
      this._sessions.futureSessions$.pipe(
        map(sessions =>
          sessions.reduce(
            (pr, cur) => {
              if (
                isSimpleSession(cur) &&
                [
                  SessionStatuses.GUIDE_OFFER,
                  SessionStatuses.RESCHEDULE_BY_GUIDE,
                  SessionStatuses.RESCHEDULE_BY_CLIENT,
                  SessionStatuses.PENDING
                ].includes(cur.status)
              ) {
                // @ts-expect-error TS2345
                pr.offers.push(cur);
              } else if (isSimpleSession(cur) || !cur.sessions) {
                // @ts-expect-error TS2345
                pr.approved.push(cur);
              } else {
                // @ts-expect-error TS2345
                pr.approved.push(new GroupSession(cur));
              }

              return pr;
            },
            { approved: [], offers: [] }
          )
        ),
        switchMap(({ approved, offers }) =>
          this._sessions.sessionRequests$.pipe(
            map(filterFromSimpleRequests),
            map(requests =>
              requests.reduce(
                (pr, cur: GroupSession) => {
                  // @ts-expect-error TS2339
                  const existingGroupSession = pr.approved.find(session => session.eventId === cur.eventId);

                  if (existingGroupSession) {
                    // @ts-expect-error TS2532
                    const newSessions = cur.sessions.filter(
                      // @ts-expect-error TS2339
                      // eslint-disable-next-line id-length
                      s => !existingGroupSession.sessions.some(session => session.id === s.id)
                    );
                    if (newSessions.length) {
                      // @ts-expect-error TS2339
                      existingGroupSession.sessions.push(...newSessions);
                    }
                  } else {
                    if (
                      [
                        SessionStatuses.GUIDE_OFFER,
                        SessionStatuses.RESCHEDULE_BY_GUIDE,
                        SessionStatuses.RESCHEDULE_BY_CLIENT,
                        SessionStatuses.PENDING
                      ].includes(cur.status)
                    ) {
                      // @ts-expect-error TS2345
                      pr.offers.push(cur);
                    } else {
                      // @ts-expect-error TS2345
                      pr.approved.push(cur);
                    }
                  }

                  return pr;
                },
                { approved, offers }
              )
            )
          )
        ),
        map(({ approved, offers }) => [
          ...futureSessionsToFullCalendarEvents(approved),
          ...sessionOffersToFullCalendarEvents(offers)
        ])
      )
      // @ts-expect-error TS2769
    ]).pipe(map(sessions => [].concat(...sessions)));
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _showSessionActionResult$(actionResult: IEventActionResult): Observable<void> {
    const { actionType, eventDetails } = actionResult;

    const { componentInstance, result } = this._modal.open(SessionActionResultModalComponent);
    componentInstance.actionResult = { actionType, session: eventDetails };

    return modalResultToObservable$(result);
  }
}
