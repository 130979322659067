import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { take, filter, finalize } from 'rxjs/operators';

import { PhoneVerificationService } from '../../services/phone-verification.service';
import { NotificationsService } from '@awarenow/profi-ui-core';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-phone-verification-modal',
  templateUrl: './phone-verification-modal.component.html',
  styleUrls: ['./phone-verification-modal.component.scss']
})
export class PhoneVerificationModalComponent implements OnInit {
  // @ts-expect-error TS2564
  code: number;
  isLoading = false;

  get seconds(): Observable<number> {
    return this._service.secondsForWaiting;
  }

  constructor(
    private _service: PhoneVerificationService,
    private _notifyService: NotificationsService,
    private _modal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.seconds
      .pipe(
        take(1),
        // eslint-disable-next-line id-length
        filter(s => s === 0)
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(() => this.generateCode());
  }

  onRepeat(): void {
    this.generateCode();
  }

  // @ts-expect-error TS7006
  onSubmit(valid): void {
    if (!valid) {
      return;
    }

    this.isLoading = true;

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._service.verifyCode(this.code).subscribe(
      () => this.verifyCodeHandler(),
      err => this.verifyCodeErrorHandler(err)
    );
  }

  private generateCode(): void {
    this.isLoading = true;
    this._service
      .generateCode()
      .pipe(finalize(() => (this.isLoading = false)))
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe({
        error: err => this.generateCodeErrorHandler(err)
      });
  }

  private verifyCodeHandler(): void {
    this._notifyService.success('Your phone is verified', '');
    this._modal.close(true);
  }

  // @ts-expect-error TS7006
  private generateCodeErrorHandler(err): void {
    switch (err.message) {
      case 'already_generated':
        this._notifyService.error('SMS code', 'has already been sent');
        break;
      case 'already_exists':
        this._notifyService.error('Error', 'This phone number is already exists and verified by another account');
        this._modal.close(false);
        break;
      case 'invalid_phone':
        this._notifyService.error('Error', 'Invalid phone');
        this._modal.close(false);
        break;
      default:
        console.error('Unhandled error!', err);
        break;
    }
  }

  // @ts-expect-error TS7006
  private verifyCodeErrorHandler(err): void {
    switch (err.message) {
      case 'not_found':
        this._notifyService.error('Error', 'Wrong verification code');
        break;
      case 'not_verified':
        this._notifyService.error('Error', 'Unable to verify, please try again later');
        this._modal.close(false);
        break;
      default:
        console.error('Unhandled error!', err);
        break;
    }
  }
}
