import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// import { locale } from '@env/locale';
import { formatDate } from '@angular/common';
import { concat, Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import config from '@app/core/config/config';
import { QuizPattern, QuizSchedule } from '@app/core/quizzes/types';
import { DateTime } from 'luxon';
import { timeAt } from '@app/shared/utils/time-at-localization';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { InternalEvents } from '@app/core/analytics/types';
import { LocaleService } from '@app/core/locale/locale.service';
import { Responses } from '../types/responses';
import { MiniQuiz, Quiz } from '../types/quiz';
import { IUser } from '@app/shared/interfaces/user';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { AuthService } from '@app/core/auth/services';

// const dateTimeLocale = locale.dateTimeLocale;

@Injectable()
export class QuizService {
  // @ts-expect-error TS7008
  private allBots;

  private allBots$ = new Subject<Quiz[]>();

  private readonly dateTimeLocale: string;
  private readonly servicesEndpoint: string;

  constructor(
    private _http: HttpClient,
    private _analyticsService: AnalyticsService,
    private _localeService: LocaleService,
    private _notifications: NotificationsService,
    private readonly authService: AuthService
  ) {
    this.dateTimeLocale = this._localeService.getLocale().dateTimeLocale;
    this.servicesEndpoint = this.authService.isPlatformAdmin() ? 'admin/forms' : 'guide/chat-bot';
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  newQuiz(data: Quiz): Observable<any> {
    return this._http.post(`${config.apiPath}/user/${this.servicesEndpoint}`, data).pipe(
      tap(() => this._analyticsService.event(InternalEvents.QUIZ_CREATED, {})),
      catchError(error => throwError({ error }))
    );
  }

  updateQuiz(id: number, data: Quiz): Observable<Quiz> {
    return this._http
      .put<Quiz>(`${config.apiPath}/user/${this.servicesEndpoint}/${id}`, data)
      .pipe(catchError(error => throwError({ error })));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveAvatar(avatar: File): Observable<any> {
    const formData: FormData = new FormData();

    formData.append('quizimage', avatar, avatar.name);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this._http.post<{ photo: string }>(
      `${config.apiPath}/user/${this.servicesEndpoint}/upload-photo`,
      formData,
      { headers }
    );
  }

  /**
   * Returns quizzes created by Current User.
   * Additional cases:
   * When request initiator is Workspace Team Member, also returns quizzes created by Workspace Admin
   * When request initiator is Workspace Admin, we pass @param {number} extraAuthors, which is program Author Identifier
   * @param {number} [extraAuthors]
   */
  getQuizzes$(extraAuthors?: IUser['id']): Observable<MiniQuiz[]> {
    let params = new HttpParams();

    if (extraAuthors) {
      params = params.set('extraAuthors', extraAuthors.toString());
    }

    return concat(
      this._http.get<MiniQuiz[]>(`${config.apiPath}/user/${this.servicesEndpoint}`, { params }),
      this.allBots$
    ).pipe(
      catchError(error => throwError({ error })),
      tap(i => (this.allBots = i))
    );
  }

  deleteQuiz$(id: number): Observable<void> {
    return this._http.delete<void>(`${config.apiPath}/user/${this.servicesEndpoint}/${id}`).pipe(
      catchError(error => throwError({ error })),
      // @ts-expect-error TS7006
      tap(() => this.allBots$.next(this.allBots.filter(i => +i.id !== +id)))
    );
  }

  duplicateQuiz$(id: number): Observable<Quiz> {
    return this._http.post<Quiz>(`${config.apiPath}/user/${this.servicesEndpoint}/${id}/duplicate`, {}).pipe(
      catchError(error => throwError({ error })),
      tap(quiz => this.allBots$.next([quiz, ...this.allBots]))
    );
  }

  /**
   * Duplicates form for workspace members
   * @param {number} id - initial form identifier
   * @param {Array<number[]>} members - Workspace members identifiers
   */
  shareQuiz$(id: number, members: number[]): Observable<void> {
    return this._http.post<void>(`${config.apiPath}/user/guide/chat-bot/${id}/share`, { members }).pipe(
      tap(() => this._notifications.success(`${`Form shared successfully`}`)),
      catchError(error => throwError({ error }))
    );
  }

  getResponses$(id: number): Observable<Responses> {
    return this._http
      .get<Responses>(`${config.apiPath}/user/guide/chat-bot/${id}/responses`)
      .pipe(catchError(error => throwError({ error })));
  }

  getQuiz$(id: number): Observable<Quiz> {
    return this._http
      .get<Quiz>(`${config.apiPath}/user/${this.servicesEndpoint}/${id}`)
      .pipe(catchError(error => throwError({ error })));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  downloadQuiz(id: number, userIds: number[]): Observable<any> {
    return this._http
      .get(`${config.apiPath}/user/guide/chat-bot/export/${id}/${userIds.join(',')}`, {
        responseType: 'text'
      })
      .pipe(catchError(error => throwError({ error })));
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  addClients(quizTemplateId: number, selected) {
    return this._http
      .post(`${config.apiPath}/user/guide/chat-bot/${quizTemplateId}/clients`, {
        clients: selected
      })
      .pipe(catchError(error => throwError({ error })));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  scheduleToUserReadableText(schedule: QuizSchedule) {
    // TODO: Put this text to template

    let result = 'Every ';
    let tag = '';

    switch (schedule.pattern) {
      case QuizPattern.Daily:
        tag = 'day';
        break;
      case QuizPattern.Weekly:
        tag = `week on ${this.toReadableWeekdays(schedule)}`;
        break;
      case QuizPattern.Monthly:
        tag = `month of the ${this.ordinalSuffix(schedule.monthday)}`;
        break;
    }

    result += this.prependDays(schedule, tag);

    result += ` ${timeAt(this.dateTimeLocale)}${formatDate(schedule.date, 'shortTime', this.dateTimeLocale)}`;

    return result;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private toReadableWeekdays(schedule: QuizSchedule) {
    const date = DateTime.local();
    const weekdays = schedule.weekdays.split('').map(i => +i);

    return weekdays.map(weekday => formatDate(date.set({ weekday }).toISO(), 'EEEE', this.dateTimeLocale)).join(', ');
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private prependDays(schedule: QuizSchedule, tag: string) {
    return (schedule.days > 1 ? `${this.ordinalSuffix(schedule.days)} ` : '') + tag;
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private ordinalSuffix(i) {
    const j = i % 10;
    // eslint-disable-next-line id-length
    const k = i % 100;
    let result = '';
    if (j === 1 && k !== 11) {
      result = `${i}st`;
    } else if (j === 2 && k !== 12) {
      result = `${i}nd`;
    } else if (j === 3 && k !== 13) {
      result = `${i}rd`;
    } else {
      result = `${i}th`;
    }
    return result;
  }
}
