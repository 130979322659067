import { Injectable } from '@angular/core';
import { ClientView } from '@app/components/clients-select/type';
import { ComponentStore } from '@ngrx/component-store';

interface ClientsSelectState {
  clients: ReadonlyArray<ClientView>;
  selectedClients: ClientView[];
}

@Injectable()
export class ClientsSelectStore extends ComponentStore<ClientsSelectState> {
  clients$ = this.select(state => state.clients);
  selectedClients$ = this.select(state => state.selectedClients);

  clientsWithSelection$ = this.select(this.clients$, this.selectedClients$, (clients, selectedClients) =>
    clients.map(client => ({
      ...client,
      selected: selectedClients.some(selected => selected.id === client.id) // Adjust 'id' as needed
    }))
  );

  isAllSelected$ = this.select(this.clients$, this.selectedClients$, (clients, selectedClients) =>
    clients.every(
      client => selectedClients.some(selected => selected.id === client.id) // Adjust 'id' as needed
    )
  );

  constructor() {
    super({
      clients: [],
      selectedClients: []
    });
  }

  selectClient(client: ClientView) {
    this.patchState(state => ({
      selectedClients: [
        ...state.selectedClients.filter(c => c.id !== client.id), // Replace 'id' with the unique property of ClientView
        client
      ]
    }));
  }

  selectClients(clients: ClientView[]) {
    this.patchState(state => ({
      selectedClients: [
        ...state.selectedClients.filter(
          c => !clients.some(newClient => newClient.id === c.id) // Remove duplicates based on 'id'
        ),
        ...clients
      ]
    }));
  }

  selectAll() {
    this.patchState(({ clients }) => ({
      selectedClients: [...clients]
    }));
  }

  unselectClient(client: ClientView) {
    this.patchState(state => ({
      selectedClients: state.selectedClients.filter(c => c.id !== client.id) // Replace 'id' with the unique property of ClientView
    }));
  }

  unselectAll() {
    this.patchState(({ clients }) => ({
      selectedClients: []
    }));
  }
}
