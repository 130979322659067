import { ModuleCompletionTypes } from '@app/shared/interfaces/programs/program-module';
import { environment } from '@env/environment';
import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepicker,
  NgbDateStruct,
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import { NgbDateTimeStringAdapter } from '@app/shared/ng-bootstrap/ngb-date-time-string-adapter';
import { CUSTOM_NGB_DATE_PARSER_CONFIG } from '@app/shared/ng-bootstrap/ngb-custom-date-parser-formatter';
import { ngbCustomDateFormatterFactory } from '@app/shared/ng-bootstrap/ngb-custom-date-formatter-factory';
import { CurrencyService } from '@app/core/currency/currency.service';
import resolve from '@platformStyle/utils/resolve';
import { LocaleService } from '@app/core/locale/locale.service';
import { AbsComponentWithGuideBillingValidation } from '@app/modules/billing/abs';
import { BillingService } from '@app/modules/billing/services';
import { PlatformConfigurationService } from '@app/core/platform-configuration';
import { ServicePricingTypes } from '@app/modules/guide-service-editor/types/pricing';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { IProgramAuthor, IProgramOptions } from '../../types';
import { AuthService } from '@app/core/auth/services';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-program-settings',
  templateUrl: './program-settings.component.html',
  styleUrls: [
    '../../../../../../scss/datepicker/datepicker.scss',
    '../../../../../modules/guide-service-editor/common-styles/forms.scss',
    '../../../../../modules/guide-service-editor/common-styles/radio.scss',
    '../../../../../modules/guide-service-editor/common-styles/layout.scss',
    './program-settings.component.scss'
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateTimeStringAdapter },
    { provide: CUSTOM_NGB_DATE_PARSER_CONFIG, useValue: resolve('DATE', 'toFormat') },
    {
      provide: NgbDateParserFormatter,
      useFactory: ngbCustomDateFormatterFactory,
      deps: [CUSTOM_NGB_DATE_PARSER_CONFIG, LocaleService]
    }
  ]
})
export class ProgramSettingsComponent extends AbsComponentWithGuideBillingValidation implements OnInit {
  settingsFormControlsTexts: {
    showBook: Record<'label' | 'description', string>;
    showMarketplace: Record<'label' | 'description', string>;
  } = {
    showBook: {
      label: `Show program on booking page`,
      description: `Allow everyone to view and enroll in this program from team booking page.`
    },
    showMarketplace: {
      label: `Show program on marketplace`,
      description: `Allow everyone to view and enroll in this program from the marketplace. The program will be shown on the marketplace after admin approval.`
    }
  };

  private minDateSource: NgbDateStruct;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _options: IProgramOptions;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _settingsForm: UntypedFormGroup;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _changesUnsubscribe: Subject<null> = new Subject<null>();

  guideRoute = environment.guideRoute;

  @Input()
  // @ts-expect-error TS2564
  author: IProgramAuthor;

  @Input()
  set options(value: IProgramOptions) {
    this._options = value;
  }

  @Input()
  // @ts-expect-error TS2564
  allCustomersCount: number;

  @Input()
  // @ts-expect-error TS2564
  subscriptionInfo: {
    subscriptionDeactivated: boolean;
    subscriptionDeactivatedDate: string | null;
    subscribersNumber: string | number;
  };

  @Input()
  set settingsForm(settingsForm: UntypedFormGroup) {
    this._settingsForm = settingsForm;
    this._changesUnsubscribe.next(null);
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.setStartDatePickerState());
  }

  @Output()
  disconnectProgram = new EventEmitter<number>();

  @Output()
  deactivateProgram = new EventEmitter<{ date: Date | null; message: string }>();

  @ViewChild('datePicker', { static: false })
  // @ts-expect-error TS2564
  datePicker: NgbDatepicker;

  platformName: string;

  readonly ModuleCompletionTypes = ModuleCompletionTypes;

  isAdmin = this.authService.isPlatformAdmin();

  get formEvents(): UntypedFormArray {
    return this._settingsForm.get('events') as UntypedFormArray;
  }

  get minDate(): NgbDateStruct {
    return this.minDateSource;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get options(): IProgramOptions {
    return this._options;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get settingsForm(): UntypedFormGroup {
    return this._settingsForm;
  }

  get isFormDisabled(): boolean {
    return !this.author.permissions.canEditSettings;
  }

  constructor(
    _modal: NgbModal,
    _billing: BillingService,
    _platformConfiguration: PlatformConfigurationService,
    private authService: AuthService,
    public currency: CurrencyService,
    private _ngZone: NgZone,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {
    super(_modal, _billing, _platformConfiguration);
    this.minDateSource = DateTime.local().toObject() as NgbDateStruct;
    // @ts-expect-error TS2322
    this.platformName = this._runtimeConfigService.get('platformName');
  }

  ngOnInit(): void {
    if (this.isFormDisabled) {
      this.settingsForm.disable();
    }
  }

  calculateIsHiddenForBookToggleState(value: boolean): boolean {
    return !value;
  }

  trackPricingChange(value: ServicePricingTypes): void {
    const paymentTypeControl: AbstractControl = this.getPaymentTypeControl();

    this.validatePaidService(value, paymentTypeControl);
  }

  private setStartDatePickerState(): void {
    // @ts-expect-error TS2531
    const startDate = this._settingsForm.get('startDate').value;

    if (this.datePicker && startDate && DateTime.fromISO(startDate).toSeconds() < DateTime.local().toSeconds()) {
      this.minDateSource = DateTime.fromISO(startDate).toObject() as NgbDateStruct;
      const min = DateTime.local().startOf('day').toSeconds();
      // eslint-disable-next-line id-length
      this.datePicker.markDisabled = (d: NgbDate) => min > DateTime.fromObject(d).startOf('day').toSeconds();
    } else {
      this.minDateSource = DateTime.local().toObject() as NgbDateStruct;
    }
  }

  private getPaymentTypeControl(): AbstractControl {
    // @ts-expect-error TS2322
    return this.getPriceSettings().get('paymentType');
  }

  private getPriceSettings(): AbstractControl {
    // @ts-expect-error TS2322
    return this._settingsForm.get('pricingFormGroup');
  }
}
