import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { environment } from '@env/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { IWorkspace } from '@app/modules/workspaces/types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-invite-team-members',
  templateUrl: './invite-team-step.component.html',
  styleUrls: ['./invite-team-step.component.scss']
})
export class InviteTeamStepComponent implements OnInit {
  invitationsForm: UntypedFormGroup;

  emails: string[] = [];

  separatorKeysCodes: number[] = [ENTER, COMMA];

  // @ts-expect-error TS2322
  private activeWorkspace: IWorkspace = null;

  @Input() canSkip = true;

  @Output() invited = new EventEmitter<boolean>();

  GUIDE_LIST_ROUTE = environment.guideRoute;

  constructor(
    readonly modal: NgbActiveModal,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _workspaceService: WorkspacesService
  ) {
    this.invitationsForm = this._formBuilder.group({
      invitations: this._formBuilder.control([], [Validators.required])
    });
  }

  ngOnInit(): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._workspaceService.activeWorkspace$.subscribe(workspace => {
      this.activeWorkspace = workspace;
    });
    this._workspaceService.getActiveWorkspace();
  }

  onInputTokenEnd(email: string): void {
    if (email?.length === 0) {
      return;
    }
    const validationResult = Validators.email({ value: email } as AbstractControl);

    if (email && validationResult === null) {
      this.addInvitation(email);
    } else {
      this.invitationsForm.controls.invitations.setErrors({ email: true });
    }
  }

  addInvitation(email: string): void {
    const selected = this.invitationsForm.get('invitations')?.value ?? [];
    // @ts-expect-error TS2531
    this.invitationsForm.get('invitations').setValue([...selected, email]);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  onChipRemove(emailToRemove: any) {
    let emails = this.invitationsForm.get('invitations')?.value ?? [];
    // @ts-expect-error TS7006
    emails = emails.filter(email => email !== emailToRemove);

    // @ts-expect-error TS2531
    this.invitationsForm.get('invitations').setValue(emails);

    if (emails.length === 0) {
      this.invitationsForm.controls.invitations.setErrors(null);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  inviteGuides() {
    const invitations = this.invitationsForm.get('invitations')?.value ?? [];
    const invitationsInfo = {
      teamId: this.activeWorkspace.workspaceId,
      invitations,
      isResend: false
    };
    // @ts-expect-error TS2345
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._workspaceService.sendInvitations(invitationsInfo).subscribe(
      () => {
        this.invited.emit(true);
      },
      () => {
        this.invited.emit(false);
      }
    );
  }
}
