import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ClientSessionActionsService } from './client-session-actions.service';
import { ClientSessionCommunicationsService } from './client-session-communications.service';
import { BookingTypes } from '@appWidget/modules/booking/enums/booking-types';

// ToDo refactor
@Injectable()
export class ClientSessionAdaptorService implements OnDestroy {
  private destroy$ = new Subject<void>();
  private subscriptionsInit = false;

  constructor(
    private _sessionActions: ClientSessionCommunicationsService,
    private _sessionsActor: ClientSessionActionsService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initializeSessionActions(): void {
    if (this.subscriptionsInit) {
      return;
    }

    this.subscriptionsInit = true;
    this._sessionActions.acceptSessionOffer$
      .pipe(takeUntil(this.destroy$))
      .subscribe(clientSession => this._sessionsActor.acceptOffer(clientSession));

    this._sessionActions.approveReschedulement$
      .pipe(takeUntil(this.destroy$))
      .subscribe(clientSession => this._sessionsActor.approveReschedulement(clientSession));

    this._sessionActions.archiveSession$
      .pipe(takeUntil(this.destroy$))
      .subscribe(clientSession => this._sessionsActor.archiveSession(clientSession));

    this._sessionActions.cancelSession$
      .pipe(takeUntil(this.destroy$))
      .subscribe(clientSession => this._sessionsActor.cancelSession(clientSession));

    this._sessionActions.declineSessionOffer$
      .pipe(takeUntil(this.destroy$))
      .subscribe(clientSession => this._sessionsActor.declineOffer(clientSession));

    this._sessionActions.leaveReview$
      .pipe(takeUntil(this.destroy$))
      .subscribe(clientSession => this._sessionsActor.findSessionAndLeaveReview(clientSession));

    this._sessionActions.rescheduleSession$
      .pipe(takeUntil(this.destroy$))
      .subscribe(clientSession => this._sessionsActor.rescheduleSession(clientSession));

    this._sessionActions.reorderSession$.pipe(takeUntil(this.destroy$)).subscribe(({ session, analyticSourceType }) =>
      this._sessionsActor.reorderSession({
        clientSession: session,
        analyticSourceType,
        bookingType: BookingTypes.INDIVIDUAL
      })
    );

    this._sessionActions.bookAvailableSession$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ session, analyticSourceType }) =>
        this._sessionsActor.bookAvailableSession(session, analyticSourceType)
      );
  }
}
