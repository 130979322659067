// https://github.com/angular/components/tree/master/src/material/icon

import { NgModule } from '@angular/core';
import { MatCommonModule } from '@angular/material/core';

/**
 * @deprecated
 */
@NgModule({
  exports: [MatCommonModule],
  imports: [MatCommonModule]
})
export class UiIconModule {}
