import { ChangeDetectionStrategy, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IBookButtonCustomizationOptions } from '../../types';

@Component({
  selector: 'app-book-button-customizer',
  templateUrl: './book-button-customizer.component.html',
  styleUrls: ['./book-button-customizer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BookButtonCustomizerComponent),
      multi: true
    }
  ]
})
export class BookButtonCustomizerComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  readonly defaultOptions = {
    text: '',
    backgroundColor: '#000000',
    color: '#ffffff'
  };

  customizationForm = this._formBuilder.group(this.defaultOptions);

  get backgroundColor(): string {
    // @ts-expect-error TS2531
    return this.customizationForm.get('backgroundColor').value;
  }

  get color(): string {
    // @ts-expect-error TS2531
    return this.customizationForm.get('color').value;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set backgroundColor(value: string) {
    // @ts-expect-error TS2531
    this.customizationForm.get('backgroundColor').setValue(value);
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set color(value: string) {
    // @ts-expect-error TS2531
    this.customizationForm.get('color').setValue(value);
  }

  constructor(private _formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.customizationForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(customizationOptions => {
      this.onChange(customizationOptions);
      this.onTouched();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDisabledState(isDisabled: boolean): void {}

  writeValue(customizationOptions: IBookButtonCustomizationOptions): void {
    this.customizationForm.patchValue(customizationOptions || this.defaultOptions, {
      emitEvent: false
    });
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private onChange = _ => {};

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private onTouched = () => {};
}
