import { Subject, throwError } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/core/auth/services/auth.service';
import { BrandingService } from '@app/core/branding/branding.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { UserRoles } from '@app/shared/enums/user-roles';
import { GlobalConfig } from '@cnf/types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ErrorService, FormService } from '../../../core';
import { NotificationsService } from '@awarenow/profi-ui-core';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-sign-up-guide',
  templateUrl: './sign-up-guide.component.html',
  styleUrls: ['./sign-up-guide.component.scss']
})
export class SignUpGuideComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  config = { urlPrivacy: '#', urlTerms: '#', showNewsletter: false };

  emailAlreadyExists = false;

  isFetching = false;

  // @ts-expect-error TS7008
  platformLogo;

  platformName: string;

  // @ts-expect-error TS2564
  signUpForm: UntypedFormGroup;

  @ViewChild('notification', { static: false })
  // @ts-expect-error TS2564
  notification: ElementRef;

  get createAlternativeAccount(): boolean {
    return this.authService.isAuthorized && this.authService.user.RoleId === UserRoles.CLIENT;
  }

  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private formService: FormService,
    private notifyService: NotificationsService,
    private fb: UntypedFormBuilder,
    private modal: NgbModal,
    private _brandingService: BrandingService,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {
    // @ts-expect-error TS2322
    this.platformName = this._runtimeConfigService.get('platformName');
  }

  ngOnInit(): void {
    this._brandingService.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => this.setConfig(config));

    this._brandingService.mainLogo$.pipe(takeUntil(this.destroy$)).subscribe(({ logo }) => (this.platformLogo = logo));

    this.buildForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onEmailChanged(): void {
    this.emailAlreadyExists = false;
  }

  signUp(): void {
    if (this.formService.markInvalidForm(this.signUpForm) || this.isFetching) {
      return;
    }

    this.emailAlreadyExists = false;
    this.isFetching = true;
    const notificationModal = this.modal.open(this.notification, {
      centered: true,
      windowClass: 'sign-up-guide-notification-modal'
    });

    let signUpMethod$;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const signUpParams: any = {
      firstName: this.signUpForm.value.firstName,
      lastName: this.signUpForm.value.lastName,
      phoneNumber: this.signUpForm.value.phoneNumberOrSkypeId.phoneNumber,
      skypeId: this.signUpForm.value.phoneNumberOrSkypeId.skypeId,
      linkedIn: this.signUpForm.value.linkedIn,
      aboutMe: this.signUpForm.value.about,
      subscribeNews: this.signUpForm.value.subscribeNews
    };

    if (this.createAlternativeAccount) {
      // @ts-expect-error TS7006
      signUpMethod$ = params => this.authService.signupAlternativeAccount(params);
    } else {
      // @ts-expect-error TS7006
      signUpMethod$ = params => this.authService.signupGuide(params);
      signUpParams.email = this.signUpForm.value.email;
      signUpParams.password = this.signUpForm.value.password;
    }

    signUpMethod$(signUpParams)
      .pipe(
        // eslint-disable-next-line id-length
        catchError(e => {
          if (e.status === 403) {
            this.emailAlreadyExists = true;
          } else {
            const errors = this.errorService.parseErrors(e);
            for (const error of errors) {
              this.notifyService.error('Something wrong', error);
            }
          }
          return throwError(e);
        }),
        finalize(() => {
          this.isFetching = false;
          notificationModal.close();
        })
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe();
  }

  private buildForm(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const signUpFormFields: any = {
      email: [''],
      password: [''],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      agree: ['', [Validators.requiredTrue]],
      subscribeNews: [false],
      phoneNumberOrSkypeId: this.fb.group(
        {
          phoneNumber: ['', []],
          skypeId: ['', []]
        },
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          validator: (control: AbstractControl): { [key: string]: any } => {
            // @ts-expect-error TS2322
            return !control.value.phoneNumber && !control.value.skypeId
              ? {
                  phoneNumberOrSkypeId: {
                    value: control.value
                  }
                }
              : null;
          }
        }
      ),
      linkedIn: [''],
      about: ['', []]
    };

    if (!this.createAlternativeAccount) {
      signUpFormFields.email.push([Validators.required, Validators.email]);
      signUpFormFields.password.push([Validators.required, Validators.minLength(6)]);
    }

    this.signUpForm = this.fb.group(signUpFormFields);

    this.signUpForm
      .get('phoneNumberOrSkypeId')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (
          !this.signUpForm.get('phoneNumberOrSkypeId')?.pristine &&
          !this.signUpForm.get('phoneNumberOrSkypeId')?.valid
        ) {
          this.signUpForm.get(['phoneNumberOrSkypeId', 'phoneNumber'])?.setErrors({
            invalidNumber: {
              valid: false
            }
          });
        }
      });
  }

  private setConfig({ urlPrivacy, urlTerms, showNewsletter }: GlobalConfig): void {
    this.config.urlTerms = urlTerms;
    this.config.urlPrivacy = urlPrivacy;
    this.config.showNewsletter = showNewsletter;
  }
}
