import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { sortTags } from '@app/shared/utils/sort-tags';
import { BLOG_EDITOR_ARTICLE_URL, BLOG_EDITOR_TAGS_URL, BLOG_IMAGE_UPLOAD_URL, BLOG_SEND_OUT } from '../endpoints';
import {
  IBlogArticleUpdate,
  blogAuthorFactory,
  BlogArticleStatuses,
  regularAndBusinessBlogItemsFactory
} from '../types';

@Injectable()
export class BlogEditorService {
  constructor(private _http: HttpClient, private _notifications: NotificationsService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createArticle$(articleUpdate: IBlogArticleUpdate): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.post<any>(BLOG_EDITOR_ARTICLE_URL, articleUpdate).pipe(
      tap(() => this._notifications.success(`Article created`)),
      map(({ id }) => id),
      catchError((httpError: HttpErrorResponse) => {
        this._notifications.error(`Cannot create article`);
        return throwError(httpError);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getArticle$(id: number): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.get<any>(`${BLOG_EDITOR_ARTICLE_URL}/${id}`).pipe(
      map(({ article }) => {
        const { author, ...articleData } = article;
        articleData.author = blogAuthorFactory(author);
        return articleData;
      }),
      catchError(error => {
        this._notifications.error(`Cannot load article`);
        return throwError(error);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getArticles$(status?: BlogArticleStatuses): Observable<any> {
    const params = status ? { status } : {};
    // @ts-expect-error TS2322
    const httpParams = new HttpParams({ fromObject: params });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.get<any>(`${BLOG_EDITOR_ARTICLE_URL}`, { params: httpParams }).pipe(
      map(response =>
        Object.keys(response.items).reduce((itemsGroups, itemsGroupName) => {
          // @ts-expect-error TS7053
          itemsGroups[itemsGroupName] = regularAndBusinessBlogItemsFactory(response.items[itemsGroupName]);
          return itemsGroups;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        }, {})
      ),
      catchError(error => {
        this._notifications.error(`Cannot load your stories`);
        return throwError(error);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getTags$(): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.get<{ tags: any[] }>(BLOG_EDITOR_TAGS_URL).pipe(
      map(({ tags }) => sortTags(tags)),
      catchError(error => {
        this._notifications.error(`Cannot load tags`);
        return throwError(error);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeArticle$(id: number): Observable<any> {
    return this._http.delete(`${BLOG_EDITOR_ARTICLE_URL}/${id}`).pipe(
      catchError(error => {
        this._notifications.error(`Cannot remove article`);
        return throwError(error);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateArticle$(id: number, articleUpdate: IBlogArticleUpdate): Observable<any> {
    if (!id) {
      // TODO: show error
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return new Observable<any>(x => x.complete());
    }

    return this._http.put(`${BLOG_EDITOR_ARTICLE_URL}/${id}`, articleUpdate).pipe(
      tap(() => this._notifications.success(`Article saved`)),
      catchError(error => {
        this._notifications.error(`Cannot update article`);
        return throwError(error);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendOutEmail$(id: number): Observable<any> {
    if (!id) {
      // TODO: show error
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return new Observable<any>(x => x.complete());
    }

    return this._http.post(`${BLOG_SEND_OUT}/${id}`, {}).pipe(
      tap(() => this._notifications.success(`Emails sent`)),
      catchError(error => {
        this._notifications.error(`Cannot send emails`);
        return throwError(error);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadImage$(image: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('blogimage', image, image.name);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return (
      this._http
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .post<any>(BLOG_IMAGE_UPLOAD_URL, formData, {
          headers,
          reportProgress: true,
          observe: 'events'
        })
        .pipe(
          catchError(error => {
            this._notifications.error(`Cannot upload blog image`);
            return throwError(error);
          })
        )
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get uploader(): (file: File) => Observable<any> {
    return this.uploadImage$.bind(this);
  }
}
