import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { InternalEvents } from '@app/core/analytics/types';
import { BrandingService } from '@app/core/branding/branding.service';
// import { locale } from '@env/locale';
import { LocaleService } from '@app/core/locale/locale.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { GlobalConfig } from '@cnf/types';
import { ILocale } from '@env/locale.interface';
import { MetaTagService } from '@libs/services/meta-tag/meta-tag.service';

import { WikiService } from '../../services/wiki.service';
import { WikiTerm } from '../../types';

enum WikiTabs {
  ALPHABET,
  HOLISTIC_PRACTICE
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface ITermsGroup {
  name: string;
  values: WikiTerm[];
}

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-wiki',
  templateUrl: './wiki.component.html',
  styleUrls: ['./wiki.component.scss'],
  providers: [WikiService, MetaTagService]
})
export class WikiComponent implements OnInit, OnDestroy {
  readonly Tabs = WikiTabs;
  readonly platformName: string;

  private destroy$ = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _locale: ILocale;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _activeTabTerms = {
    [WikiTabs.ALPHABET]: [] as ITermsGroup[],
    [WikiTabs.HOLISTIC_PRACTICE]: [] as ITermsGroup[]
  };

  activeTab = WikiTabs.ALPHABET;
  // @ts-expect-error TS2564
  terms: WikiTerm[];

  config = {
    meta: {
      metaKeywordsWikiPage: null,
      metaTitleWikiPage: null,
      metaDescriptionWikiPage: null,
      metaImageWikiPage: null
    }
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get activeTabTerms() {
    return this._activeTabTerms;
  }

  constructor(
    private _service: WikiService,
    private _analyticsService: AnalyticsService,
    private _title: Title,
    private _router: Router,
    private _localeService: LocaleService,
    private brandingService: BrandingService,
    private readonly _runtimeConfigService: RuntimeConfigService,
    private metaTagService: MetaTagService
  ) {
    this._locale = this._localeService.getLocale();
    // @ts-expect-error TS2322
    this.platformName = this._runtimeConfigService.get('platformName');
  }

  ngOnInit(): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._service.getWiki$().subscribe(terms => {
      this._activeTabTerms[WikiTabs.ALPHABET] = this.mapByAlphabet(terms);
      this._activeTabTerms[WikiTabs.HOLISTIC_PRACTICE] = this.mapByHolisticPractice(terms);
    });

    this._analyticsService.event(InternalEvents.WIKI);

    this.brandingService.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => this.setConfig(config));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private mapByAlphabet(terms: WikiTerm[]): ITermsGroup[] {
    const termsMap = terms.sort(this.sortByTermName).reduce((dictionary, term) => {
      const firstLetter = term.name.slice(0, 1);
      // @ts-expect-error TS7053
      if (!dictionary[firstLetter]) {
        // @ts-expect-error TS7053
        dictionary[firstLetter] = [];
      }
      // @ts-expect-error TS7053
      dictionary[firstLetter].push(term);

      return dictionary;
    }, {});

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Object.keys(termsMap).map(key => ({ name: key, values: termsMap[key] }));
  }

  private mapByHolisticPractice(terms: WikiTerm[]): ITermsGroup[] {
    const termsMap = terms.sort().reduce((dictionary, term) => {
      // @ts-expect-error TS2532
      if (!dictionary[term.category.name]) {
        // @ts-expect-error TS2532
        dictionary[term.category.name] = [];
      }
      // @ts-expect-error TS2532
      dictionary[term.category.name].push(term);

      return dictionary;
    }, {});

    return Object.keys(termsMap).map(key => ({
      name: key,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      values: termsMap[key]
    }));
  }

  private sortByTermName(term1: WikiTerm, term2: WikiTerm): number {
    const name1 = term1.name.toUpperCase();
    const name2 = term2.name.toUpperCase();

    if (name1 < name2) {
      return -1;
    }
    if (name1 > name2) {
      return 1;
    }

    return 0;
  }

  private setConfig({
    metaKeywordsWikiPage,
    metaTitleWikiPage,
    metaDescriptionWikiPage,
    metaImageWikiPage
  }: GlobalConfig): void {
    // @ts-expect-error TS2322
    this.config.meta.metaKeywordsWikiPage = metaKeywordsWikiPage;
    // @ts-expect-error TS2322
    this.config.meta.metaTitleWikiPage = metaTitleWikiPage;
    // @ts-expect-error TS2322
    this.config.meta.metaDescriptionWikiPage = metaDescriptionWikiPage;
    // @ts-expect-error TS2322
    this.config.meta.metaImageWikiPage = metaImageWikiPage;

    this.setWikiTitle();
    this.setOGMeta();
  }

  private setWikiTitle(): void {
    // @ts-expect-error TS2345
    this._title.setTitle(this.config.meta.metaTitleWikiPage);
  }

  private setOGMeta(): void {
    this.metaTagService.upsertMetaTags({
      keywords: this.config.meta.metaKeywordsWikiPage || '',
      type: 'website',
      title: this.config.meta.metaTitleWikiPage || '',
      description: this.config.meta.metaDescriptionWikiPage || '',
      image: this.config.meta.metaImageWikiPage || '',
      url: `${this._locale.baseUrl}${this._router.url}`
    });
  }
}
