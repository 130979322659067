import { NotificationsService } from '@awarenow/profi-ui-core';
import { BehaviorSubject, EMPTY, Observable, of, ReplaySubject } from 'rxjs';
import { filter, map, mapTo, take, tap } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/auth/services';
import { LocaleService } from '@app/core/locale/locale.service';
import { makeUriFromString } from '@app/screens/blog/utils';
import { DEFAULT_SESSION_TEMPLATE_VALUES } from '@app/screens/guide/guide-sessions-templates/services';
import { UserRoles } from '@app/shared/enums/user-roles';
import { environment } from '@env/environment';
import { ILocale } from '@env/locale.interface';

import { SessionTemplate, SessionTemplateInterface, SessionTemplatePatchInterface } from '../types';
import { SessionTemplateServerStoreService } from './session-template-server-store.service';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';

@Injectable()
export class SessionTemplateEditorService {
  private templateSubject$ = new BehaviorSubject<SessionTemplate | null>(null);

  private templateNameSubject$ = new ReplaySubject<string | null>(1);

  private locale: ILocale;

  get template$(): Observable<SessionTemplate> {
    return this.templateSubject$.pipe(filter<SessionTemplate>(template => !!template));
  }

  get originalTemplate(): SessionTemplate | null {
    return this.templateSubject$.getValue();
  }

  get templateLink$(): Observable<string> {
    return this.template$.pipe(
      map(({ id, name }) => (id && name ? `${this.locale.baseUrl}/sessions/${makeUriFromString(name, id)}` : ''))
    );
  }

  get templateName$(): Observable<string | null> {
    return this.templateNameSubject$.asObservable();
  }

  constructor(
    @Inject(DEFAULT_SESSION_TEMPLATE_VALUES)
    defaultSessionTemplateValues$: Observable<SessionTemplateInterface>,
    private templatesStore: SessionTemplateServerStoreService,
    private notifications: NotificationsService,
    private router: Router,
    private localeService: LocaleService,
    private authService: AuthService,
    private workspaceService: WorkspacesService
  ) {
    defaultSessionTemplateValues$.pipe(take(1)).subscribe(defaultSessionTemplate => {
      const sessionTemplateData = !this.originalTemplate
        ? defaultSessionTemplate
        : {
            ...this.originalTemplate,
            availabilities: defaultSessionTemplate?.availabilities || []
          };

      const sessionTemplate = new SessionTemplate(new SessionTemplate(sessionTemplateData));

      this.templateSubject$.next(sessionTemplate);
    });

    this.locale = this.localeService.getLocale();
  }

  refresh$(templateId: number, asOwner?: boolean): Observable<void> {
    return this.loadTemplate$(templateId, asOwner);
  }

  saveTemplate$(sessionTemplate: SessionTemplate): Observable<{ id: number; isCreating: boolean }> {
    const diff = this.getTemplateDiff(sessionTemplate);
    if (!diff) {
      this.close();
      return EMPTY;
    }

    const saveTemplate$ = diff.id
      ? this.templatesStore.updateTemplate$(diff.id, diff.patch)
      : this.templatesStore.createTemplate$(sessionTemplate);

    return saveTemplate$.pipe(
      tap(() => this.showNotification(!diff.id)),
      tap(persistentAttr =>
        this.templateSubject$.next(new SessionTemplate(sessionTemplate).setPersistenceAttributes(persistentAttr))
      ),
      map(({ id }) => ({ id, isCreating: !diff.id }))
    );
  }

  updateName(name: string): void {
    this.templateNameSubject$.next(name);
  }

  parseTemplate(template: SessionTemplateInterface): Observable<void> {
    const sessionTemplate = new SessionTemplate(template);

    this.templateSubject$.next(sessionTemplate);
    this.templateNameSubject$.next(template.name);

    return of(void 0);
  }

  private getTemplateDiff(
    newSessionTemplate: SessionTemplate
  ): { id: number; patch: SessionTemplatePatchInterface } | null {
    const currentSessionTemplate = this.templateSubject$.getValue();

    return currentSessionTemplate!.getDiffFrom(newSessionTemplate);
  }

  private loadTemplate$(templateId: number, asOwner?: boolean): Observable<void> {
    return this.templatesStore.getTemplate$(templateId, asOwner).pipe(
      map(template => {
        // Filter hosts if owner is team member and team admin sessionTemplate contains different hosts
        if (asOwner && !this.workspaceService.isTeamAdmin) {
          template.hosts = template.hosts.filter(host => host.userId === this.authService.user.id);
        }

        return template;
      }),
      tap(template => this.parseTemplate(template)),
      mapTo(void 0)
    );
  }

  private showNotification(isCreated: boolean): void {
    let notificationText;

    if (!isCreated) {
      notificationText = `Your session has been saved`;
    } else {
      notificationText = `Congratulations. Your session has been created`;
      notificationText += `. Please set up the time of the session`;
    }

    if (notificationText) {
      this.notifications.success(notificationText);
    }
  }

  close(): void {
    switch (this.authService.getUserRoleId()) {
      case UserRoles.ADMIN:
        this.router.navigate(['/admin/default-services']).then();
        break;
      case UserRoles.GUIDE:
        this.router.navigate([`/${environment.guideRoute}`, 'services']).then();
        break;
      default:
        this.router.navigate(['/']).then();
    }
  }
}
