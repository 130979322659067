import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PuiDialogService } from '@awarenow/profi-ui-core';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { SaveOnRouteChanged } from '@app/screens/guide/guide-shared/interfaces';
import {
  RedirectAfterSaveActions,
  canRedirectAfterSave
} from '@app/screens/guide/guide-shared/can-redirect-after-save';
import { GuideSaveChangesDialogComponent } from '@app/screens/guide/guide-shared/components/guide-save-changes-dialog/guide-save-changes-dialog.component';
import { AuthService } from '@app/core/auth/services';
import { canRedirectAfterSaveProgramsAdmin } from '@app/screens/admin/modules/default-services/guard/can-redirect-after-save-programs-admin';

@Injectable()
export class CanLeaveEditorTabGuard {
  constructor(
    private readonly _dialog: PuiDialogService,
    private readonly _notifications: NotificationsService,
    private readonly auth: AuthService
  ) {}

  async canDeactivate(
    component: SaveOnRouteChanged,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextRoute: RouterStateSnapshot
  ): Promise<boolean> {
    const result = this.auth.isPlatformAdmin()
      ? await canRedirectAfterSaveProgramsAdmin(this._notifications, component, nextRoute).toPromise()
      : await canRedirectAfterSave(
          this._dialog,
          this._notifications,
          component,
          nextRoute,
          GuideSaveChangesDialogComponent
        ).toPromise();

    return !(result === RedirectAfterSaveActions.CANCEL);
  }
}
