import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, noop, Subject } from 'rxjs';
import { filter, finalize, map, mergeMap, switchMap, take, takeUntil } from 'rxjs/operators';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { SubscriptionStatus } from '@app/shared/enums/subscription';
import { IPublicProgramItem } from '@app/shared/interfaces/programs';
import { ClientProgramsService } from '@app/modules/client-programs/services/client-programs.service';
import { SystemNotificationsService } from '@app/core/notifications/system-notifications.service';
import { ISystemNotification, SystemNotificationTypes } from '@app/modules/notifications/types';
import { MarketplaceProgramApiService } from '@app/modules/marketplace-filters/services/marketplace-program-api.service';
import { IPayWithModalOptions } from '@app/modules/current-payment/components/pay-with-modal/pay-with-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MarketplaceProgramFilterApiAdapterService } from '@app/modules/marketplace-filters/services/marketplace-program-filter-api-adapter.service';
import { ProgramsActiveTab, ProgramsQueryParams } from '@app/screens/programs/enums';
import { CurrentPaymentService } from '@app/modules/current-payment/services/current-payment.service';
import { IGuideService, IServiceBookingOptions, ServiceBookingService } from '@app/modules/book-service';
import { PublicProgramsService } from '../../services/public-programs.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['../../../../../scss/programs/programs-board-base.scss', './programs.component.scss'],
  providers: [PublicProgramsService]
})
export class ProgramsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _cursor: number | null;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _myPrograms$ = new BehaviorSubject<IPublicProgramItem[]>([]);

  activeId: ProgramsActiveTab = ProgramsActiveTab.MY;

  clientPrograms: IPublicProgramItem[] = [];

  marketplacePrograms: IPublicProgramItem[] = [];

  marketplaceProgramsAvailableCount = 0;

  constructor(
    private _clientProgramsService: ClientProgramsService,
    private _systemNotificationsService: SystemNotificationsService,
    private _publicPrograms: PublicProgramsService,
    private _marketplaceProgramApiService: MarketplaceProgramApiService,
    private _marketplaceFilterAdapter: MarketplaceProgramFilterApiAdapterService,
    private _router: Router,
    private _notifications: NotificationsService,
    private _route: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    private _currentPayment: CurrentPaymentService,
    private readonly _serviceBooking: ServiceBookingService<IServiceBookingOptions<IGuideService>, void>
  ) {}

  ngOnInit(): void {
    this._route.queryParamMap.pipe(takeUntil(this.destroy$)).subscribe(queryParams => {
      const activeId = queryParams.get(ProgramsQueryParams.ACTIVE_TAB) ?? ProgramsActiveTab.MY;
      this.activeId = activeId as ProgramsActiveTab;
    });

    this._loadClientRelatedPrograms();
    this._systemNotificationsService.programNotifications$
      .pipe(
        map(notifications =>
          notifications.filter(
            notification =>
              !notification.isRead &&
              [
                SystemNotificationTypes.PROGRAM_INVITE_INFORMATIVE,
                SystemNotificationTypes.PROGRAM_INVITE_PREPAID
              ].includes(notification.type)
          )
        ),
        filter(notifications => notifications.length > 0),
        switchMap(() => this._clientProgramsService.getOfferedPrograms$()),
        takeUntil(this.destroy$)
      )
      .subscribe(programs => {
        this._myPrograms$.next(programs);
      });

    combineLatest([this._myPrograms$, this._systemNotificationsService.programNotifications$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([programs, notifications]) => {
        const [informativeNotifications, prepaidNotifications, moduleAvailableNotifications] =
          this._groupNotificationsByType(notifications);
        const programsIdsUserInvitedAt = new Set(
          // @ts-expect-error TS2532
          informativeNotifications.filter(({ isRead }) => !isRead).map(({ details }) => details.programId)
        );
        const prepaidProgramsIdsUserInvitedAt = new Set(
          // @ts-expect-error TS2532
          prepaidNotifications.filter(({ isRead }) => !isRead).map(({ details }) => details.programId)
        );

        const updatedPrograms = programs
          .map(program => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { invited, notStarted, moduleAvailable, ...restProgramParameters } = program;
            let isInvited = false;
            let isNotStarted = false;
            let moduleAvailableId = 0;

            if (programsIdsUserInvitedAt.has(program.id)) {
              isInvited = true;
            }

            if (prepaidProgramsIdsUserInvitedAt.has(program.id)) {
              isNotStarted = true;
            }

            const moduleId = Math.max(
              ...moduleAvailableNotifications
                // @ts-expect-error TS2532
                .filter(notification => +notification.details.programId === +program.id && !notification.isRead)
                // @ts-expect-error TS2532
                .map(notification => notification.details.moduleId)
            );

            if (moduleId > 0) {
              moduleAvailableId = moduleId;
            }

            return {
              ...restProgramParameters,
              invited: isInvited,
              notStarted: isNotStarted,
              ...(moduleAvailable ? { moduleAvailableId } : {})
            };
          })
          .sort(this._comparator);

        this.clientPrograms = [...updatedPrograms];
        this._cdr.detectChanges();
      });

    this._marketplaceFilterAdapter
      .getQueryParams$()
      .pipe(
        mergeMap(queryPrams => this._publicPrograms.getMarketplacePrograms$(queryPrams)),
        takeUntil(this.destroy$)
      )
      .subscribe(({ programs, cursor }) => {
        this.marketplacePrograms = programs;
        this._cursor = cursor;
      });

    this._marketplaceProgramApiService
      .getProgramsCount$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(count => {
        this.marketplaceProgramsAvailableCount = count;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // @ts-expect-error TS7006
  isPastDueSubscription(program): boolean {
    return program.subscriptionStatus && program.subscriptionStatus === SubscriptionStatus.PAST_DUE;
  }

  handleTabChange(newValue: ProgramsActiveTab): void {
    this._router
      .navigate(['.'], { relativeTo: this._route, queryParams: { [ProgramsQueryParams.ACTIVE_TAB]: newValue } })
      .then(() => noop());
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  hasCursor() {
    return !!this._cursor;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  showMore() {
    this._marketplaceFilterAdapter
      // @ts-expect-error TS2322
      .getQueryParams$({ cursor: this._cursor })
      .pipe(
        mergeMap(queryPrams => this._publicPrograms.getMarketplacePrograms$(queryPrams)),
        take(1)
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(({ programs, cursor }) => {
        this.marketplacePrograms = [...this.marketplacePrograms, ...programs];
        this._cursor = cursor;
      });
  }

  composeProgramLink(program: IPublicProgramItem): string | null {
    if (!program.enrolled) {
      return `/programs/${program.id}`;
    }

    if (this.isPastDueSubscription(program)) {
      return null;
    }

    return `/client/programs/${program.id}/modules`;
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onInviteDeclined(programId) {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._publicPrograms.declineClientInvitation$(programId).subscribe(
      () => {
        this._loadClientRelatedPrograms();
      },
      () => {
        this._notifications.error(`Invitation not found.`);
      }
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onProgramSelect(program: IPublicProgramItem) {
    const programLink = this.composeProgramLink(program);

    if (program.enrolled && this.isPastDueSubscription(program)) {
      const modalOptions: IPayWithModalOptions = {
        disableNewCardSave: true,
        isSubscription: true,
        hideWallet: true
      };
      const result$ = this._serviceBooking.selectPaymentType$(modalOptions);

      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      result$.pipe(take(1)).subscribe(() => {
        this.reactivateSubscription(program.subscriptionId);
      });

      return;
    }

    this._router.navigate([programLink]).then(() => noop());
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
  private reactivateSubscription(subscriptionId) {
    this._currentPayment
      .pay()
      .subscriptionReactivate$(subscriptionId)
      .pipe(
        finalize(() => {
          this._loadClientRelatedPrograms();
          this._serviceBooking.cleanUp();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => {
          this._notifications.success(`New payment info is connected to subscription.`);
        },
        error => {
          if (error && error.error && error.error.errorMessage) {
            this._notifications.error(error.error.errorMessage);
          } else {
            this._notifications.error(`Failed to book`);
          }
        }
      );
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention, id-length
  private _comparator(a, b) {
    if ((a.notStarted || a.invited || a.moduleAvailable) && (b.notStarted || b.invited || b.moduleAvailable)) {
      return 0;
    }
    if (a.notStarted || a.invited || a.moduleAvailable) {
      return -1;
    }
    if (b.notStarted || b.invited || b.moduleAvailable) {
      return 1;
    }
    return 0;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _groupNotificationsByType(notifications: ISystemNotification[]): ISystemNotification[][] {
    // @ts-expect-error TS7034
    const informative = [];
    // @ts-expect-error TS7034
    const prepaid = [];
    // @ts-expect-error TS7034
    const moduleAvailable = [];
    notifications.forEach(notification => {
      switch (notification.type) {
        case SystemNotificationTypes.PROGRAM_INVITE_INFORMATIVE:
          informative.push(notification);
          break;
        case SystemNotificationTypes.PROGRAM_INVITE_PREPAID:
          prepaid.push(notification);
          break;
        case SystemNotificationTypes.PROGRAM_MODULE_AVAILABLE:
          moduleAvailable.push(notification);
          break;
      }
    });

    // @ts-expect-error TS7005
    return [informative, prepaid, moduleAvailable];
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _loadClientRelatedPrograms(): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._clientProgramsService.getOfferedPrograms$().subscribe(programs => {
      this._myPrograms$.next(programs);
    });
  }
}
