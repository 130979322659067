import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from '@awarenow/profi-ui-core';

@Component({
  selector: 'app-program-sharing-modal',
  templateUrl: './program-sharing-modal.component.html',
  styleUrls: ['./program-sharing-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgramSharingModalComponent {
  @Input()
  // @ts-expect-error TS2564
  programLink: string;

  @Output()
  linkCopied = new EventEmitter<void>();

  constructor(public modal: NgbActiveModal, private _notifications: NotificationsService) {}

  onCopied(): void {
    this.linkCopied.emit();
    this._notifications.success(`Copied`);
  }

  onCopyFailed(): void {
    this._notifications.error(`Copy failed`);
  }
}
