import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  assignGuidesForClientError,
  assignGuidesForClientSuccess,
  createClients,
  guideClientsFetchAll,
  guideClientsFetchAllSuccess,
  guideClientsRefetchAll,
  guideClientsResetAll,
  setClients,
  updateClients,
  updateClientsError,
  updateClientsSuccess
} from '@app/screens/guide/guide-clients/guide-client/store/guide-clients-store/guide-clients-store.actions';
import { catchError, debounceTime, map, skip, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { guideClientsStateAllClients } from '@app/screens/guide/guide-clients/guide-client/store/guide-clients-store/guide-clients-store.selectors';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { GuideClientsApiService } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { Observable, of, OperatorFunction } from 'rxjs';
import { NotificationsService } from '@awarenow/profi-ui-core';
// eslint-disable-next-line no-restricted-imports
import { merge } from 'lodash';

@Injectable()
export class GuideClientsStoreEffects {
  readonly fetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(guideClientsFetchAll, guideClientsRefetchAll),
      switchMap(() =>
        this.guideClientsApiService.get().pipe(
          map(({ clients }) => ({
            type: guideClientsFetchAllSuccess.type,
            clients: clients
          }))
        )
      )
    )
  );

  readonly createClients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createClients),
      switchMap(({ create }) =>
        this.guideClientsApiService.create(create).pipe(map(() => ({ type: guideClientsRefetchAll.type })))
      )
    )
  );

  readonly handleUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setClients),
      debounceAndMergeChanges(),
      map(({ update }) => ({
        type: updateClients.type,
        update
      }))
    )
  );

  readonly handleUpdates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateClients),
      withLatestFrom(this.store$.select(guideClientsStateAllClients)),
      switchMap(([{ update }, allClients]) => {
        const updates = Object.entries(update).map(([id, value]) => {
          // @ts-expect-error TS7015
          const client = allClients[id];

          return [client.id, client.relationId, value];
        });

        return this.guideClientsApiService
          .patchMany(updates.map(([id, relationId, value]) => ({ ...value, id, relationId })))
          .pipe(
            map(() => ({ type: updateClientsSuccess.type })),
            catchError(() =>
              of({
                type: updateClientsError.type
              })
            )
          );
      })
    )
  );

  readonly assignGuidesForClientSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(assignGuidesForClientSuccess),
        tap(() => {
          this.notificationsService.success(`Assigned team members saved`);
        })
      ),
    { dispatch: false }
  );

  readonly assignGuidesForClientError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(assignGuidesForClientError),
        tap(() => {
          this.notificationsService.success(translocoService.translate('assignedTeamMembersError'));
        })
      ),
    { dispatch: false }
  );

  readonly workspaceChange$ = createEffect(() =>
    this.workspacesService.activeWorkspace$.pipe(
      skip(1),
      map(() => ({
        type: guideClientsResetAll.type
      }))
    )
  );

  readonly reloadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(guideClientsResetAll),
      map(() => ({
        type: guideClientsFetchAll.type
      }))
    )
  );

  constructor(
    private readonly guideClientsApiService: GuideClientsApiService,
    private readonly actions$: Actions,
    private readonly store$: Store,
    private readonly workspacesService: WorkspacesService,
    private readonly notificationsService: NotificationsService
  ) {}
}

function debounceAndMergeChanges<T, R>(): OperatorFunction<T, R> {
  return (source: Observable<T>): Observable<R> => {
    return new Observable<R>(observer => {
      let result = {};

      return source
        .pipe(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          tap(({ payload }) => {
            result = merge(result, payload);

            return result;
          }),
          debounceTime(2000)
        )
        .subscribe({
          next() {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            observer.next({ update: result });
            result = {};
          }
        });
    });
  };
}
