import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { BrandingService } from '@app/core/branding/branding.service';
import { GlobalConfig } from '@cnf/types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-auth-container',
  templateUrl: './auth-container.component.html',
  styleUrls: ['./auth-container.component.scss']
})
export class AuthContainerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  config = {
    loginScreenBgUrl: null
  };

  constructor(private _brandingService: BrandingService) {}

  ngOnInit(): void {
    this._brandingService.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => this.setConfig(config));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setConfig({ loginScreenBgUrl }: GlobalConfig): void {
    // @ts-expect-error TS2322
    this.config.loginScreenBgUrl = loginScreenBgUrl;
  }
}
