import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { RecoverService } from '@app/core';
import { PuiDestroyService } from '@awarenow/profi-ui-core';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { matchPasswordValidator, passwordValidator } from '@libs/shared/validators/password.validators';

function markAllControlsAsDirty(form: UntypedFormGroup): void {
  const keys = Object.keys(form.controls);

  for (const key of keys) {
    const control = form.get(key as string);

    control?.markAsDirty();
  }
}

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-forgot-password-reset',
  templateUrl: './forgot-password-reset.component.html',
  providers: [PuiDestroyService],
  styleUrls: ['../auth.scss', './forgot-password-reset.component.scss']
})
export class ForgotPasswordResetComponent implements OnInit {
  email!: string;
  hash!: string;

  readonly form = new UntypedFormGroup({
    newPassword: new UntypedFormControl('', {
      validators: passwordValidator()
    }),
    newPasswordRepeat: new UntypedFormControl('', {
      validators: [matchPasswordValidator('newPassword')]
    })
  });

  constructor(
    private recoverService: RecoverService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>
  ) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnInit() {
    this.route.queryParams.pipe(take(1), takeUntil(this.destroy$)).subscribe(params => {
      const { email, hash } = params;

      if (email === undefined || hash === undefined) {
        this.router.navigate(['/auth/sign-in']);
      }

      this.email = params.email;
      this.hash = params.hash;
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  resetPassword() {
    markAllControlsAsDirty(this.form);

    if (this.form.invalid) {
      return;
    }

    this.recoverService
      .checkHash(this.email, this.hash, this.form.value.newPassword)
      .pipe(
        catchError(({ error }) => {
          this.notificationsService.error(`Error`, error.msg);

          return throwError(error);
        }),
        takeUntil(this.destroy$)
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil, @typescript-eslint/no-explicit-any
      .subscribe((data: any) => {
        if (data.result) {
          this.router.navigate(['/auth/sign-in']);
        }
      });
  }
}
