import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { AuthService } from '@app/core/auth/services/auth.service';
import { isPlatformBrowser } from '@angular/common';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-token-sign-in',
  template: ``
})
export class TokenSignInComponent implements OnInit, OnDestroy {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _subscription: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _notifications: NotificationsService,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(PLATFORM_ID) private _platformId: any,
    private _auth: AuthService
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this._platformId)) {
      this._subscription = this._route.paramMap
        .pipe(
          map(params => params.get('token')),
          mergeMap(token => this._auth.signinByToken(token)),
          catchError(error => {
            const timeOut = 5000;
            this._notifications.error(`Invalid token`, error.error.msg, {
              timeOut
            });
            this._router.navigate(['/dashboard']);
            return timer(timeOut + 100);
          })
        )
        // eslint-disable-next-line rxjs-angular/prefer-takeuntil, @typescript-eslint/no-unused-vars
        .subscribe(data => {
          this._router.navigate(['/dashboard']);
        });
    }
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }
}
