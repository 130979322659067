import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { Location } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { BrandingService } from '@app/core/branding/branding.service';
import { ILogoLink, prepareLogoUrlData } from '@app/shared/utils/url';
import { PublicUserInfo, AuthService } from '@app/core/auth/services/auth.service';
import { SharedEventActionService } from '@app/core/shared-event-actions/shared-event-action.service';
import { UrlTypes } from '@app/shared/enums/url-types';
import { environment } from '@env/environment';
import { UserRoles } from '@app/shared/enums/user-roles';
import { LocalStorageKeys } from '@app/cdk/enums';
import { TfaChallengeInfo } from '../../interfaces/tfa-challendge-info.interface';
import { PUI_DIALOG_DATA, PuiDialogRef } from '@awarenow/profi-ui-core';
import { SsoMode } from '@cnf/types/global-config';
import { AlternativeAuthProvidersService } from '@app/modules/auth/services/alternative-auth-providers.service';

export enum AuthStates {
  INIT,
  SIGN_IN,
  SIGN_UP,
  SIGN_UP_ALTERNATIVE,
  CONFIRM_EMAIL,
  SIGN_IN_ALTERNATIVE,
  TFA_APP_CHALLENGE,
  TFA_SMS_CHALLENGE,
  TFA_USE_BACK_UP_CODE
}

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['../../../../screens/auth/auth.scss', './auth-modal.component.scss']
})
export class AuthModalComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  @Input()
  activeState: AuthStates = AuthStates.INIT;

  @Input()
  disableCloseButton = false;

  @Input()
  onlyClient = false;

  @Input()
  onlyGuide = false;

  @Output()
  afterSignIn = new EventEmitter();

  @Output()
  signInCanNot = new EventEmitter();

  AuthStates = AuthStates;

  URL_TYPE = UrlTypes;

  actionCode = '';

  email = '';

  isFetching = false;

  // @ts-expect-error TS2564
  logoUrl: ILogoLink;

  mainLogo = '';

  // @ts-expect-error TS2564
  plan: string;

  // @ts-expect-error TS2564
  remember: boolean;

  // @ts-expect-error TS2564
  user: PublicUserInfo;

  signUpOnlyCoaches = false;

  signUpOnlyClients = false;

  tfaInfo: TfaChallengeInfo | null = null;

  readonly loaded$ = new BehaviorSubject<boolean>(false);
  readonly separatedAuth$ = new BehaviorSubject<boolean>(false);
  readonly showAuthMethodsSeparator$ = new BehaviorSubject<boolean>(false);

  constructor(
    readonly modal: NgbActiveModal,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _location: Location,
    private readonly _brandingService: BrandingService,
    private readonly _sharedEventActionService: SharedEventActionService,
    private readonly _authService: AuthService,
    @Inject(PUI_DIALOG_DATA)
    @Optional()
    puiDialogData: {
      activeState: AuthStates;
      disableCloseButton: boolean;
      onlyClient: boolean;
    },
    @Optional() private dialogRef: PuiDialogRef,
    private alternativeAuthProviders: AlternativeAuthProvidersService
  ) {
    if (puiDialogData) {
      this.activeState = puiDialogData.activeState;
      this.disableCloseButton = puiDialogData.disableCloseButton;
      this.onlyClient = puiDialogData.onlyClient;
    }

    if (dialogRef) {
      this.afterSignIn.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.dialogRef.close(true);
      });
    }
  }

  ngOnInit(): void {
    // WTF? this._router.events.pipe(takeUntil(this.destroy$)).subscribe(() => this.modal.dismiss());

    this._brandingService.mainLogo$.pipe(takeUntil(this.destroy$)).subscribe(({ logo }) => (this.mainLogo = logo));

    this._brandingService.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(({ urlInLogo }) => {
      this.logoUrl = prepareLogoUrlData(urlInLogo);
    });

    if (this._authService.workspaceMemberSignUpInfo) {
      const { email, authState } = this._authService.workspaceMemberSignUpInfo;
      this.onlyClient = false;
      this.onlyGuide = true;
      this.activeState = authState;
      this.email = email;
      this.user = { firstName: 'Team Member' };
    }

    if (this._sharedEventActionService.signupByToken) {
      const { email, withoutPassword, actionCode, firstName, role } = this._sharedEventActionService.signupByToken;
      this.email = email;
      this.actionCode = actionCode;
      // @ts-expect-error TS2322
      this._sharedEventActionService.signupByToken = null;
      if (!role || role === UserRoles.CLIENT) {
        this.onlyClient = true;
      }

      if (withoutPassword) {
        this.activeState = AuthStates.SIGN_UP;
      } else {
        this.activeState = AuthStates.INIT;
        // @ts-expect-error TS2322
        this.user = { firstName };
      }
    }

    if (this._authService.presetEmail) {
      this.onlyClient = true;
      this.onlyGuide = false;
      this.activeState = AuthStates.SIGN_UP;
      this.email = this._authService.presetEmail;
    }

    // TODO This line fix that bug https://profi-io.atlassian.net/browse/PR-7072
    // this._brandingService.loadBranding();

    combineLatest([this._brandingService.globalConfig$, this._route.queryParamMap])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([{ separatedAuth, ssoMode, isFacebookAuthEnabled, isGoogleAuthEnabled }, params]) => {
        this.separatedAuth$.next(separatedAuth);

        this.plan = params.get('plan') || '';

        if (separatedAuth && !this._authService.workspaceMemberSignUpInfo) {
          const guide = params.get('guide');

          if (guide || (localStorage && !!localStorage.getItem(LocalStorageKeys.CREATE_TEAM))) {
            this.signUpOnlyCoaches = true;
          } else {
            this.signUpOnlyClients = true;
          }
        }

        if (
          this._authService.workspaceMemberSignUpInfo &&
          (ssoMode === SsoMode.ALL_USERS || ssoMode === SsoMode.PRACTITIONER)
        ) {
          this.activeState = AuthStates.SIGN_IN_ALTERNATIVE;
        }

        if (this._authService.presetEmail && (ssoMode === SsoMode.ALL_USERS || ssoMode === SsoMode.CLIENT)) {
          this.activeState = AuthStates.SIGN_IN_ALTERNATIVE;
        }

        if (isFacebookAuthEnabled || isGoogleAuthEnabled || ssoMode !== SsoMode.DISABLED) {
          this.showAuthMethodsSeparator$.next(true);
        }

        this.loaded$.next(true);
      });

    this.subscribeOnOpenTfaStep();
    this.subscribeOnOpenConfirmEmailStep();
  }

  ngOnDestroy(): void {
    this._route.queryParams
      .pipe(
        take(1),
        filter(params => params['signUp'] || params['signIn'])
      )
      // @ts-expect-error TS2345
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(() => this._location.go(this._location.path().split('?').shift()));

    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onUserChecked({
    email,
    remember,
    user,
    showSSOSignIn
  }: {
    email: string;
    remember: boolean;
    user: PublicUserInfo | null;
    showSSOSignIn?: boolean;
  }) {
    this.email = email;
    this.remember = remember;

    if (showSSOSignIn) {
      this.activeState = AuthStates.SIGN_IN_ALTERNATIVE;

      return;
    }

    if (user) {
      this.user = user;

      if (user.withoutPassword) {
        this.activeState = AuthStates.CONFIRM_EMAIL;
      } else {
        this.activeState = AuthStates.SIGN_IN;
      }

      return;
    }

    this.activeState = AuthStates.SIGN_UP;

    if (this.plan) {
      this._authService.redirectUrl = `/${environment.membershipRoute}/${encodeURIComponent(this.plan)}`;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onSignUpWithAlternativeProvider() {
    this.activeState = AuthStates.SIGN_UP_ALTERNATIVE;

    if (this.plan) {
      this._authService.redirectUrl = `/${environment.membershipRoute}/${encodeURIComponent(this.plan)}`;
    }
  }

  onOpenTfaStep(tfaInfo: TfaChallengeInfo): void {
    this.tfaInfo = tfaInfo;
    this.activeState = tfaInfo.tfaAppEnabled ? AuthStates.TFA_APP_CHALLENGE : AuthStates.TFA_SMS_CHALLENGE;
  }

  openTfaBackupStep(): void {
    this.activeState = AuthStates.TFA_USE_BACK_UP_CODE;
  }

  private subscribeOnOpenTfaStep(): void {
    this._authService.openTfaAuthStep$.pipe(takeUntil(this.destroy$)).subscribe(tfaInfo => this.onOpenTfaStep(tfaInfo));
  }

  private subscribeOnOpenConfirmEmailStep(): void {
    this._authService.openConfirmEmailStep$.pipe(takeUntil(this.destroy$)).subscribe(userInfo => {
      this.email = userInfo.email;
      this.user = {
        emailVerified: true
      } as PublicUserInfo;

      this.activeState = AuthStates.CONFIRM_EMAIL;
    });
  }
}
