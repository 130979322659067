import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { take } from 'rxjs/operators';

import { NotificationsService } from '@awarenow/profi-ui-core';

import { StripeJsService } from '@app/core/stripe/stripe-js.service';
import { CurrentPaymentComponent } from '@app/modules/current-payment/components/current-payment/current-payment.component';

import { CurrentPaymentService } from '../../services/current-payment.service';
import { PaymentType } from '../../types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-pay-with-stripe-new',
  templateUrl: './pay-with-stripe-new.component.html',
  styleUrls: ['./pay-with-stripe-new.component.scss']
})
export class PayWithStripeNewComponent extends CurrentPaymentComponent implements OnInit, OnDestroy, AfterViewInit {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _ELEMENTS_NAMES: string[] = ['cardNumber', 'cardExpiry', 'cardCvc'];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _card: any = {};

  @Input() submitText = 'Submit';

  @Input() disableSavedCards = false;

  @Input() isFetching = false;

  @Output()
  submitEvent = new EventEmitter();

  @ViewChild('cardNumber', { static: false })
  // @ts-expect-error TS2564
  cardNumber: ElementRef;

  @ViewChild('cardExpiry', { static: false })
  // @ts-expect-error TS2564
  cardExpiry: ElementRef;

  @ViewChild('cardCvc', { static: false })
  // @ts-expect-error TS2564
  cardCvc: ElementRef;

  // @ts-expect-error TS2564
  error: string;

  // @ts-expect-error TS2564
  addCardForm: UntypedFormGroup;

  constructor(
    private _cd: ChangeDetectorRef,
    private _stripeJsService: StripeJsService,
    private _notifyService: NotificationsService,
    private _fb: UntypedFormBuilder,
    private _currentPayment: CurrentPaymentService
  ) {
    super(PaymentType.STRIPE, _currentPayment);
  }

  ngOnInit(): void {
    this.addCardForm = this._fb.group({
      saveCard: [false, []]
    });
  }

  ngAfterViewInit(): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._stripeJsService.stripe$.pipe(take(1)).subscribe(stripeJs => {
      if (!stripeJs) {
        return;
      }
      this._createStripeJsElements(stripeJs.elements);
    });
  }

  ngOnDestroy(): void {
    this._destroyStripeJsElements();
  }

  onSubmit(): void {
    if (this.isFetching) {
      return;
    }

    this.isFetching = true;

    this.currentPayment.paymentData = { ...this._card };
    this.currentPayment.saveCard = this.addCardForm.value.saveCard;

    this.submitEvent.emit();
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _createStripeJsElements(elements): void {
    for (const name of this._ELEMENTS_NAMES) {
      const element = elements.create(name);
      // @ts-expect-error TS7053
      element.mount(this[name].nativeElement);
      element.addEventListener('change', this._onChange.bind(this));
      this._card[name] = element;
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _destroyStripeJsElements(): void {
    for (const name of this._ELEMENTS_NAMES) {
      if (this._card[name]) {
        this._card[name].removeEventListener('change', this._onChange.bind(this));
        this._card[name].destroy();
        delete this._card[name];
      }
    }
  }

  // @ts-expect-error TS7031
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _onChange({ error }): void {
    this.error = error ? error.message : null;
    this._cd.detectChanges();
  }
}
