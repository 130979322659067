import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PuiAvatarModule, PuiToggleModule } from '@awarenow/profi-ui-core';

import { SharedModule } from '@app/shared';
import { AuthModule } from '@app/modules/auth/auth.module';

import { UserMenuComponent } from './user-menu.component';

@NgModule({
  imports: [
    AuthModule,
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    PuiAvatarModule,
    PuiToggleModule
  ],
  declarations: [UserMenuComponent],
  exports: [UserMenuComponent]
})
export class UserMenuModule {}
