import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

const OTP_CODE_LENGTHS = 6;

@Injectable()
export class OtpCodeService implements OnDestroy {
  private readonly otpCodeControl = new UntypedFormControl(null);
  private readonly showInvalidCodeError$ = new Subject<boolean>();
  private readonly destroy$ = new Subject<void>();

  get control(): UntypedFormControl {
    return this.otpCodeControl;
  }

  get showError$(): Subject<boolean> {
    return this.showInvalidCodeError$;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  subscribeOnOtpType(verifyTfaOtp: (v: string) => void, otpLength = OTP_CODE_LENGTHS): void {
    this.otpCodeControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        map(value => value?.replace(/\D/g, '')),
        takeUntil(this.destroy$)
      )
      .subscribe(value => {
        if (value && value.length === otpLength) {
          this.otpCodeControl.disable({ emitEvent: false });

          return verifyTfaOtp(value);
        }

        this.showInvalidCodeError$.next(false);
      });
  }
}
