import { NotificationsService } from '@awarenow/profi-ui-core';
import { CookieService } from '@libs/cookie-service/cookie.service';
import { Subject, throwError } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageKeys } from '@app/cdk/enums';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AuthService } from '@app/core/auth/services/auth.service';
import { BrandingService } from '@app/core/branding/branding.service';
import { ErrorService } from '@app/core/error/error.service';
import { FormService } from '@app/core/form/form.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { EMAIL_REGEXP } from '@app/shared/constants';
import { nonEmptyValidator } from '@app/shared/form-validators/non-empty.validator';
import { GlobalConfig } from '@cnf/types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: [
    '../../../../screens/auth/auth.scss',
    '../../styles/common.scss',
    '../../styles/auth.scss',
    './sign-up.component.scss'
  ]
})
export class SignUpComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  @Input()
  // @ts-expect-error TS2564
  email: string;

  @Input()
  onlyClient = false;

  @Input()
  // @ts-expect-error TS2564
  onlyGuide: boolean;

  @Input()
  // @ts-expect-error TS2564
  actionCode: boolean;

  @Output()
  afterSignIn = new EventEmitter();

  isFetching = false;

  hash = '';

  teamInvitationCode = '';

  // @ts-expect-error TS2564
  signUpForm: UntypedFormGroup;

  config = {
    urlPrivacy: '#',
    urlTerms: '#',
    showNewsletter: false
  };

  isClientDisabled = true;

  platformName: string;

  get passwordError(): null | string {
    let errors = null;

    // @ts-expect-error TS2531
    if (this.signUpForm.get('password').errors) {
      // @ts-expect-error TS2322
      errors = Object.keys(this.signUpForm.get('password').errors);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return errors && errors[0];
  }

  constructor(
    private readonly _analyticsService: AnalyticsService,
    private readonly _auth: AuthService,
    private readonly _brandingService: BrandingService,
    private readonly _cookieService: CookieService,
    private readonly _errors: ErrorService,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _formService: FormService,
    private readonly _notifications: NotificationsService,
    private readonly _router: Router,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {
    // @ts-expect-error TS2322
    this.platformName = this._runtimeConfigService.get('platformName');
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnInit() {
    this.buildForm();
    // @ts-expect-error TS2531
    this.signUpForm.get('email').setValue(this.email);
    this._brandingService.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => this._setConfig(config));

    if (this._auth.workspaceMemberSignUpInfo) {
      this.email = this._auth.workspaceMemberSignUpInfo.email;
      this.teamInvitationCode = this._auth.workspaceMemberSignUpInfo.invitationCode;
      // @ts-expect-error TS2531
      this.signUpForm.get('email').setValue(this.email);
      this.isClientDisabled = true;
      // @ts-expect-error TS2322
      this._auth.workspaceMemberSignUpInfo = null;
    }

    if (this._auth.signupByToken) {
      this.email = this._auth.signupByToken.email;
      this.hash = this._auth.signupByToken.hash;
      // @ts-expect-error TS2531
      this.signUpForm.get('email').setValue(this.email);
      // @ts-expect-error TS2531
      this.signUpForm.get('isClient').setValue(true);
      this.isClientDisabled = true;
      // @ts-expect-error TS2322
      this._auth.signupByToken = null;
    }

    if (!this.email) {
      this._router.navigate(['/auth/sign-in']).then();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  signUp() {
    if (
      this._formService.markInvalidForm(this.signUpForm) ||
      this.isFetching ||
      !this.signUpForm.value.agreeWithTerms
    ) {
      return;
    }

    this.isFetching = true;

    const analyticParams = this._analyticsService.getAnalyticParamsFromCookie();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const signUpParams: any = {
      email: this.signUpForm.value.email,
      password: this.signUpForm.value.password,
      firstName: this.signUpForm.value.firstName,
      lastName: this.signUpForm.value.lastName,
      subscribeNews: this.signUpForm.value.subscribeNews,
      hash: this.hash,
      teamInvitationCode: this.teamInvitationCode,
      actionCode: this.actionCode,
      firstpromoterTID: this._cookieService.get('_fprom_track') || null,
      ...analyticParams
    };

    if (this.onlyClient) {
      signUpParams.isClient = true;
    } else if (this.onlyGuide) {
      signUpParams.isGuide = true;
    } else {
      signUpParams.isGuide = this.signUpForm.value.isGuide;
      signUpParams.isClient = this.signUpForm.value.isClient;
    }

    if (this.signUpForm.value.promoCode) {
      signUpParams.promoCode = this.signUpForm.value.promoCode;
    }

    this._auth
      .signup(signUpParams)
      .pipe(
        // eslint-disable-next-line id-length
        catchError(e => {
          const errors = this._errors.parseErrors(e);
          for (const error of errors) {
            const title = `Something wrong`;
            this._notifications.error(title, error);
          }
          return throwError(e);
        }),
        takeUntil(this.destroy$),
        finalize(() => (this.isFetching = false))
      )
      .subscribe(() => this.afterSignIn.emit());
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  trimValue(formControl) {
    formControl.setValue(formControl.value.trim());
  }

  private buildForm(): void {
    const isGuide = !!localStorage.getItem(LocalStorageKeys.CREATE_TEAM);

    const extraFields =
      this.onlyClient || this.onlyGuide
        ? {}
        : {
            isGuide: [isGuide],
            isClient: [true]
          };

    this.signUpForm = this._formBuilder.group(
      {
        email: ['', [Validators.required, Validators.pattern(EMAIL_REGEXP)]],
        password: ['', [Validators.required, Validators.minLength(6), nonEmptyValidator]],
        firstName: ['', [Validators.pattern(/^\p{L}+([ _-]\p{L}+)*$/u), Validators.required]],
        lastName: ['', [Validators.pattern(/^\p{L}+([ _-]\p{L}+)*$/u), Validators.required]],
        promoCode: [''],
        subscribeNews: [false],
        agreeWithTerms: [false, [Validators.requiredTrue]],
        ...extraFields
      },
      {
        validators: [
          (group: UntypedFormGroup) => {
            if (this.onlyClient || this.onlyGuide) {
              return null;
            }

            const isGuide = group.controls['isGuide'];
            const isClient = group.controls['isClient'];

            if (!isGuide.value && !isClient.value) {
              return { roleError: true };
            }
          }
        ]
      }
    );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _setConfig({ urlPrivacy, urlTerms, showNewsletter }: GlobalConfig): void {
    this.config.urlTerms = urlTerms;
    this.config.urlPrivacy = urlPrivacy;
    this.config.showNewsletter = showNewsletter;
  }
}
