import { ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';
import { NgbDateStringAdapter } from '@app/shared/ng-bootstrap/ngb-date-string-adapter';
import { ModuleActivationTypes } from '@app/screens/guide/guide-programs/types';

export class IActivationOptions {
  // @ts-expect-error TS2564
  label: string;
  // @ts-expect-error TS2564
  value: string;
}

@Component({
  selector: 'app-module-activation-select',
  templateUrl: './module-activation-select.component.html',
  styleUrls: ['./module-activation-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ModuleActivationSelectComponent),
      multi: true
    },
    { provide: NgbDateAdapter, useClass: NgbDateStringAdapter }
  ]
})
export class ModuleActivationSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private readonly DEFAULT_DELIMITER = '@';
  private readonly SPECIFIC_DATE_KEY: ModuleActivationTypes = 'specific_date';

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _activationValues: IActivationOptions[] = [];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _options: IActivationOptions[] = [];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _delimiter = this.DEFAULT_DELIMITER;
  private destroy$ = new Subject<void>();

  @Input()
  set delimiter(delimiterValue: string) {
    this._delimiter = delimiterValue || this.DEFAULT_DELIMITER;
  }

  @Input()
  set options(optionsValues: IActivationOptions[]) {
    this._options = optionsValues;
    this.updateActivationValues();
  }

  @Input()
  showDate = true;

  @Input()
  set moduleFormTouched(isTouched: boolean) {
    if (isTouched) {
      this.activationDateControl.markAsTouched();
      this.activationValueControl.markAsTouched();
    } else {
      this.activationDateControl.markAsUntouched();
      this.activationValueControl.markAsUntouched();
    }
  }

  activationValueControl = new UntypedFormControl('');
  activationDateControl = new UntypedFormControl('', Validators.required);
  showDatepicker = false;

  get activationValues(): IActivationOptions[] {
    return this._activationValues;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get delimiter(): string {
    return this._delimiter;
  }

  get specificDateKey(): string {
    return this.SPECIFIC_DATE_KEY;
  }

  ngOnInit(): void {
    this.activationValueControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this._onChange(value);
      this.updateActivationDate();
    });

    this.activationDateControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(activationDate => {
      this.updateActivationValue(activationDate);
      this._onChange(this.activationValueControl.value);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(activationValue: string): void {
    // WARNING: { emitEvent: false } required, https://github.com/angular/angular/issues/14057#issuecomment-427599453
    this.activationValueControl.setValue(activationValue, { emitEvent: false });
    this.updateActivationValues();
    this.updateActivationDate();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.disableControls();
    } else {
      this.enableControls();
    }
  }

  private disableControls(): void {
    this.activationDateControl.disable();
    this.activationValueControl.disable();
  }

  private enableControls(): void {
    this.activationDateControl.enable();
    this.activationValueControl.enable();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private _onChange = (_: any) => {};

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type
  private _onTouched = () => {};

  private updateActivationDate(): void {
    const activationValue = this.activationValueControl.value;

    if (!activationValue) {
      return;
    }

    const [activationType, deferralValue] = activationValue.split(this.delimiter);
    this.showDatepicker = activationType === this.specificDateKey;

    let activationDate = null;

    if (this.specificDateKey) {
      activationDate = deferralValue ? new Date(deferralValue.replaceAll('-', '/')) : null;
    }

    this.activationDateControl.setValue(activationDate, { emitEvent: false });
  }

  private updateActivationValue(activationDate: string): void {
    if (!activationDate) {
      return;
    }

    const activationValue = this.generateSpecificDateValue(DateTime.fromJSDate(new Date(activationDate)).toISODate());
    this.activationValueControl.setValue(activationValue, { emitEvent: false });
    this.updateActivationValues();
  }

  // @ts-expect-error TS7006
  private generateSpecificDateValue(activationDate): string {
    return `${this.specificDateKey}${this.delimiter}${activationDate}`;
  }

  private updateActivationValues(): void {
    const options = this._options || [];
    const [activationValue, activationDate] = this.activationValueControl.value.split(this.delimiter);
    this._activationValues =
      activationValue === this.specificDateKey
        ? options.map(option => {
            if (option.value.startsWith(this.specificDateKey)) {
              option.value = this.generateSpecificDateValue(activationDate);
            }
            return option;
          })
        : options;
  }
}
