import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { WINDOW } from '@awarenow/profi-ui-core';
import { isPlatformBrowser } from '@angular/common';
import { filter, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ScriptLoaderService } from '@libs/services/script-loader/script-loader.service';
import { ALVIS_CONFIG } from '@libs/services/alvis/injections/alvis-config';
import { AlvisConfig } from '@libs/services/alvis/types/alvis-config';

type AlvisEvent = string;

@Injectable()
export class AlvisService {
  private readonly isBrowser: boolean;

  constructor(
    @Inject(WINDOW) private windowService: Window,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(ALVIS_CONFIG) private alvisConfig$: Observable<AlvisConfig>,
    private scriptLoader: ScriptLoaderService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  initialize(userId: string) {
    // Return if is NOT browser
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.alvisConfig$?.pipe(filter(Boolean), take(1)).subscribe(({ isEnabled, checklistId }: AlvisConfig) => {
      if (!isEnabled) {
        return;
      }

      if (!checklistId) {
        console.warn('[AlvisModule] Missed required parameter while initialization!');

        return;
      }

      Object.defineProperty(this.windowService, 'alvis_config', {
        value: { alvis_id: checklistId }
      });

      // Load Alvis script
      this.scriptLoader
        .load({
          src: '//assets.getalvis.com/alvis-onboard-1.0.19.js',
          async: true
        })
        .then(() => {
          this.emit('identify', userId);
        });
    });
  }

  isInitialized(): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.isBrowser && (this.windowService?.Alvis || this.windowService?._Alvis);
  }

  emit(...events: AlvisEvent[]): void {
    if (this.isBrowser) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (this.windowService.Alvis || this.windowService._Alvis) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.windowService.Alvis(...events);
      }
    }
  }
}
