import { AfterViewInit, Component, forwardRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { DateTime } from 'luxon';
import { NgbDate, NgbDateParserFormatter, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { User } from '@app/shared/interfaces/user';
import { QuizEnds, QuizPattern } from '@app/core/quizzes/types';
import { GuideMiniProgram } from '@app/screens/guide/guide-surveys/types/program';
import { CUSTOM_NGB_DATE_PARSER_CONFIG } from '@app/shared/ng-bootstrap/ngb-custom-date-parser-formatter';
import resolve from '@platformStyle/utils/resolve';
import { ngbCustomDateFormatterFactory } from '@app/shared/ng-bootstrap/ngb-custom-date-formatter-factory';
import { LocaleService } from '@app/core/locale/locale.service';
import { formatDate } from '@angular/common';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-quiz-scheduling',
  templateUrl: './quiz-scheduling.component.html',
  styleUrls: [
    '../../../../screens/guide/guide-surveys/components/quiz-basic-info/quiz-basic-info.component.scss',
    '../../../../shared/directives/radio/radio.scss'
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuizSchedulingComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => QuizSchedulingComponent),
      multi: true
    },
    { provide: CUSTOM_NGB_DATE_PARSER_CONFIG, useValue: resolve('DATE', 'toFormat') },
    {
      provide: NgbDateParserFormatter,
      useFactory: ngbCustomDateFormatterFactory,
      deps: [CUSTOM_NGB_DATE_PARSER_CONFIG, LocaleService]
    }
  ]
})
export class QuizSchedulingComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator, AfterViewInit {
  private onDestroy$ = new Subject<void>();
  DAYS = 1;
  users$ = new ReplaySubject<User[]>(1);
  form: UntypedFormGroup = new UntypedFormGroup({});
  dates: DateTime[];
  // @ts-expect-error TS2564
  programs: GuideMiniProgram[];
  minDate = DateTime.local().plus({ day: 1 }).toLocal();
  private readonly dateTimeLocale: string;

  @ViewChild('dAt', { static: false })
  // @ts-expect-error TS2564
  datePickerFrom: NgbDatepicker;

  @ViewChild('dUntil', { static: false })
  // @ts-expect-error TS2564
  datePickerUntil: NgbDatepicker;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
  get QuizEnds() {
    return QuizEnds;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
  get QuizPattern() {
    return QuizPattern;
  }

  constructor(private formBuilder: UntypedFormBuilder, private _localeService: LocaleService) {
    this.dates = new Array(24).fill(0).map((_, i) =>
      DateTime.local().set({
        hour: i,
        minute: 0,
        second: 0,
        millisecond: 0
      })
    );

    this.dateTimeLocale = this._localeService.getLocale().dateTimeLocale;
  }

  formatTime(date: DateTime): string {
    return formatDate(date.toISO(), 'shortTime', this.dateTimeLocale).replace(':00', '');
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnInit() {
    this.form = this.generateForm();
    this.fixStates();
    // @ts-expect-error TS2531
    this.form
      .get('ends')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.fixStates();
      });
  }

  ngAfterViewInit(): void {
    // @ts-expect-error TS2531
    const to = this.form.get('until').value;
    this.minDate = this.setStartDatePickerState(this.datePickerUntil, DateTime.fromObject(to), DateTime.local());
    this.form.updateValueAndValidity();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private generateForm() {
    const currentSelectedDate = this.dates.find(i => i.get('hour') >= DateTime.local().get('hour'));
    return this.formBuilder.group({
      repeat: [QuizPattern.Daily, []],
      // @ts-expect-error TS2532
      time: [currentSelectedDate.toISO(), []],
      sendQuestions: ['specific_date', []],
      from: [DateTime.local().toObject() as NgbDateStruct, []],
      ends: [QuizEnds.Never, []],
      weekdays: ['12345', []],
      days: [1, [Validators.required, Validators.min(this.DAYS)]],
      until: [this.minDate.toObject(), []],
      fireCount: [10, [Validators.required, Validators.min(1)]],
      monthday: [1, []]
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private fixStates() {
    // @ts-expect-error TS2531
    switch (this.form.get('ends').value) {
      case QuizEnds.Never:
        // @ts-expect-error TS2531
        this.form.get('until').disable();
        // @ts-expect-error TS2531
        this.form.get('fireCount').disable();
        break;
      case QuizEnds.FixedCount:
        // @ts-expect-error TS2531
        this.form.get('fireCount').enable();
        // @ts-expect-error TS2531
        this.form.get('until').disable();
        break;
      case QuizEnds.SpecificDate:
        // @ts-expect-error TS2531
        this.form.get('until').enable();
        // @ts-expect-error TS2531
        this.form.get('fireCount').disable();
        break;
    }
  }

  onTouched: () => void = () => {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(_val: any): void {
    if (_val) {
      const val = { ..._val };
      if (val.date) {
        // @ts-expect-error TS2532
        val.time = this.dates.find(i => i.get('hour') >= DateTime.fromISO(val.date).get('hour')).toISO();
      }
      if (val.until) {
        try {
          val.until = DateTime.fromISO(val.until).toObject() as NgbDateStruct;
          // this.minDate =
          //   this.setStartDatePickerState(this.datePickerUntil, DateTime.fromISO(val.until), DateTime.local().plus({ day: 1 }));
          // eslint-disable-next-line id-length
        } catch (e) {
          val.until = undefined;
        }
      } else {
        delete val.until;
      }

      if (val.from) {
        try {
          val.from = DateTime.fromISO(val.from).toObject() as NgbDateStruct;
          // eslint-disable-next-line id-length
        } catch (e) {
          val.from = undefined;
        }
      } else {
        delete val.from;
      }
      if (!val.time) {
        // @ts-expect-error TS2532
        val.time = this.dates.find(i => i.get('hour') >= DateTime.local().get('hour')).toISO();
      }
      if (val.repeat || val.pattern) {
        val.repeat = val.repeat || val.pattern;
      }
      this.form.patchValue(val, { emitEvent: false });
      this.fixStates();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.form.valueChanges
      .pipe(
        map(data => {
          // @ts-expect-error TS2531
          data.until = this.form.get('until').value;
          // @ts-expect-error TS2531
          data.fireCount = this.form.get('fireCount').value;

          data.until = data.until ? DateTime.fromObject(data.until).toISO() : null;
          data.from = DateTime.fromObject(data.from).toISO();

          return data;
        }),
        distinctUntilChanged((before, after) => JSON.stringify(before) === JSON.stringify(after)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(data => fn(data));
    fn(this.form.value);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // eslint-disable-next-line id-length, @typescript-eslint/no-unused-vars
  validate(c: AbstractControl): ValidationErrors | null {
    let valid = true;
    // @ts-expect-error TS7006
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
    (<any>Object).values(this.form.controls).forEach(control => {
      if (control === this.form.controls.from) {
        return;
      }
      valid = valid && !control.invalid;
    });
    return valid ? null : { invalidForm: { valid: false } };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  connectToProgram() {
    const value = this.form.value;
    this.form.removeControl('programId');
    if (value.connectToProgram) {
      this.form.addControl('programId', this.formBuilder.control(value.programId, [Validators.required]));
    } else {
      this.form.addControl('programId', this.formBuilder.control(value.programId, []));
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  toggleProgram() {
    // @ts-expect-error TS2531
    if (this.form.get('connectToProgram').value) {
      // @ts-expect-error TS2531
      this.form.get('programId').enable();
    } else {
      // @ts-expect-error TS2531
      this.form.get('programId').disable();
      // @ts-expect-error TS2531
      this.form.get('sendQuestions').setValue('specific_date');
    }
  }

  private setStartDatePickerState(datePicker: NgbDatepicker, from: DateTime, to: DateTime): DateTime {
    // eslint-disable-next-line id-length
    datePicker.markDisabled = (d: NgbDate) => {
      return (
        to.startOf('day') > DateTime.fromObject(d).startOf('day') &&
        +from.startOf('day') !== +DateTime.fromObject(d).startOf('day')
      );
    };

    return from > to ? to : from;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getArray(len: number) {
    return [...new Array(len)].map((_, i) => i);
  }
}
