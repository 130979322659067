import { NotificationsService } from '@awarenow/profi-ui-core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormService } from '@app/core/form/form.service';
import { GuideClientsService } from '@app/core/users/guide-clients.service';
import { GuideClient, GuideRelation, GuideRelationTypes, isGuideContact } from '@app/core/users/types';
import { ClientInvitation, ClientInvitationTypes, GuideClientProgram } from '@app/screens/guide/guide-programs/types';
import { NewGuideClient } from '@app/shared/interfaces/offers';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { IUser } from '@app/shared/interfaces/user';
import { CsvReader } from '@app/shared/utils/csv-reader';
import { PuiDestroyService } from '@awarenow/profi-ui-core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IInvitation {
  name?: string | null;
  email?: string;
  userId?: number;
  type: GuideRelationTypes;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IModalResponse {
  invitations: IInvitation[];
  type: ClientInvitationTypes;
}

// eslint-disable-next-line id-length
const clientToInvitation = (c: GuideClient & NewGuideClient): IInvitation => ({
  userId: +c.id ?? null,
  name: c.name,
  email: c.email ?? c.contacts?.email,
  type: c.type
});

@Component({
  selector: 'app-invite-clients-modal',
  templateUrl: './invite-clients-modal.component.html',
  styleUrls: [
    '../../../../../modules/guide-service-editor/common-styles/forms.scss',
    './invite-clients-modal.component.scss'
  ],
  providers: [PuiDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InviteClientsModalComponent implements OnInit {
  clients: (GuideRelation & NewGuideClient)[] = [];

  invitationsForm: UntypedFormGroup;

  @Input()
  completedClients: IUser[] = [];

  @Input()
  disableAddNewClient!: boolean;

  @Input()
  enrolledClients: IUser[] = [];

  @Input()
  guideRelations: GuideRelation[] = [];

  @Input()
  invitedClients: GuideClientProgram[] = [];

  @Input()
  invites: ClientInvitation[] = [];

  @Input()
  canAddByEmail = true;

  @Input()
  type!: GuideServiceTypes.PACKAGE | GuideServiceTypes.PROGRAM;

  get invitations(): UntypedFormControl | null {
    return this.invitationsForm ? (this.invitationsForm.get('invitations') as UntypedFormControl) : null;
  }

  constructor(
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>,
    private readonly _cdr: ChangeDetectorRef,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly formService: FormService,
    private readonly modal: NgbActiveModal,
    private readonly notificationsService: NotificationsService,
    private readonly guideClientsService: GuideClientsService
  ) {
    this.invitationsForm = this.formBuilder.group({
      invitations: this.formBuilder.control([], [Validators.required]),
      isPrepaid: false
    });
  }

  ngOnInit(): void {
    this.guideClientsService.clientsNumberAllowed.pipe(takeUntil(this.destroy$)).subscribe(allowedNumber => {
      this.clients = (
        this.guideRelations.length ? this.guideRelations.filter(client => this.checkNewClient(client)) : []
      ) as (GuideRelation & NewGuideClient)[];
      if (allowedNumber !== Infinity) {
        this.clients.forEach(client => {
          client.disabled = !!client.archived;
        });
      }
    });
  }

  addInvitation(email: string): void {
    const selected = this.invitationsForm.get('invitations')?.value ?? [];
    const hasClient = this.clients.find(i => i.contacts?.email === email);
    const guide: GuideClient | NewGuideClient = hasClient || new NewGuideClient(email);
    // @ts-expect-error TS2531
    this.invitationsForm.get('invitations').setValue([...selected, guide]);
  }

  addInvitationFromView(email: string): void {
    if (!email?.length) {
      return;
    }
    if (email && Validators.email({ value: email } as AbstractControl) === null && this.validateEmail(email) === null) {
      if (this.canAddByEmail) {
        this.addInvitation(email);
      }
    } else {
      this.notificationsService.error(`Invalid email`);
    }
  }

  checkCompleted(relation: GuideRelation): boolean {
    return !!this.completedClients.find(i => i.id === relation.id);
  }

  checkEnrolled(relation: GuideRelation): boolean {
    return !!this.enrolledClients.find(i => i.id === relation.id);
  }

  checkInvited(relation: GuideRelation): boolean {
    if (this.type === GuideServiceTypes.PROGRAM) {
      return !![...this.enrolledClients, ...this.invitedClients].find(i => i.id && i.id === relation.id);
    }

    return !!this.invitedClients.find(i => i.id && i.id === relation.id);
  }

  checkContactInvites(relation: GuideRelation): boolean {
    const contactEmail = relation.contacts?.email ?? null;
    return !!this.invites.find(i => i.email && i.email === contactEmail);
  }

  checkNewClient(relation: GuideRelation): boolean {
    if (isGuideContact(relation)) {
      return !this.checkContactInvites(relation);
    }

    return !this.checkInvited(relation);
  }

  close(): void {
    this.modal.close();
  }

  importCSV(files: FileList): void {
    if (files.length) {
      const file = files[0];
      CsvReader.parse(file, true)
        .pipe(takeUntil(this.destroy$))
        .subscribe(csv => {
          let count = 0;
          [{ cells: csv.header }, ...csv.rows].forEach(i => {
            if (i?.cells) {
              const [email, firstName, lastName] = i.cells;
              if (
                email &&
                firstName &&
                lastName &&
                Validators.email({ value: email.value } as AbstractControl) === null
              ) {
                this.addInvitation(email.value);
                count++;
              }
            }
          });
          if (count === 0 && csv.rows.length > 0) {
            const title = `Invalid file format use "Email | First Name | Last name | ..."`;
            this.notificationsService.error(title);
          }
          this._cdr.detectChanges();
        });
    }
  }

  send(): void {
    if (this.formService.markInvalidForm(this.invitationsForm)) {
      return;
    }

    const { invitations: selected, isPrepaid } = this.invitationsForm.value;
    const invitations: IInvitation[] = selected.map(clientToInvitation);

    const response: IModalResponse = {
      type: isPrepaid ? 'program_prepaid' : 'program_informative',
      invitations: [...invitations]
    };

    this.modal.close(response);
  }

  private validateEmail(email: string) {
    const emails = this.invites
      .filter(i => !!i.email)
      .filter(
        // @ts-expect-error TS2531
        i => i.codeType === (this.invitationsForm.get('isPrepaid').value ? 'program_prepaid' : 'program_informative')
      )
      .map(i => i.email);
    return emails.includes(email.trim()) ? { validateEmail: { valid: false } } : null;
  }
}
