import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BrandingService } from '@app/core/branding/branding.service';
import { ConnectionType } from '@awarenow/profi-json-joy-kit';

@Injectable()
export class ConnectionTypeService {
  private readonly brandingService = inject(BrandingService);

  readonly connectionTypes$ = this.brandingService.globalConfig$.pipe(
    map(config => {
      const connectionTypes = [ConnectionType.IN_PLATFORM, ConnectionType.ZOOM];

      if (config.inPersonSessionsAvailable) {
        connectionTypes.push(ConnectionType.IN_PERSON);
      }

      if (config.inCallSessionsAvailable) {
        connectionTypes.push(ConnectionType.PHONE);
      }

      return connectionTypes;
    })
  );
}
