import { Inject, Injectable, OnDestroy } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { UserRoles } from '@app/shared/enums/user-roles';
import { AuthService } from '@app/core/auth/services/auth.service';
import { SocketService } from '@app/core/socket/socket.service';
import { SIMPLE_ID } from '@app/core/simple-id';
import { Subject } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IGuideNoteEvent {
  serviceId: symbol | null;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IGuideNoteUpdateEvent extends IGuideNoteEvent {
  noteId: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IGuideNoteCreateEvent extends IGuideNoteEvent {
  noteId: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IGuideNoteDeleteEvent extends IGuideNoteEvent {
  noteId: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IGuideNoteShareEvent extends IGuideNoteEvent {
  noteIds: number[];
}

@Injectable({
  providedIn: 'root'
})
export class GuideNotesService implements OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _update$ = new Subject<IGuideNoteUpdateEvent>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _create$ = new Subject<IGuideNoteCreateEvent>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _delete$ = new Subject<IGuideNoteDeleteEvent>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _grantAccess$ = new Subject<IGuideNoteShareEvent>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _revokeAccess$ = new Subject<IGuideNoteShareEvent>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _deAuthenticate$ = new Subject<void>();

  private destroy$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get update$() {
    return this._update$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get create$() {
    return this._create$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get delete$() {
    return this._delete$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get share$() {
    return this._grantAccess$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get revokeAccess$() {
    return this._revokeAccess$.asObservable();
  }

  // @ts-expect-error TS7006
  constructor(private readonly _auth: AuthService, private _socket: SocketService, @Inject(SIMPLE_ID) private _token) {
    _auth
      .onAuth()
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        if (!user || user.RoleId !== UserRoles.GUIDE) {
          this._deAuthenticate$.next();
          return;
        }

        _socket
          .onUserNotesCreate()
          .pipe(
            filter(({ token }) => token !== _token),
            takeUntil(this._deAuthenticate$)
          )
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe(({ noteId }) => {
            this.createNote({ serviceId: null, noteId });
          });

        _socket
          .onUserNotesDelete()
          .pipe(
            filter(({ token }) => token !== _token),
            takeUntil(this._deAuthenticate$)
          )
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe(({ noteId }) => {
            this.deleteNote({ serviceId: null, noteId });
          });

        _socket
          .onUserNotesUpdate()
          .pipe(
            filter(({ token }) => token !== _token),
            takeUntil(this._deAuthenticate$)
          )
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe(({ noteId }) => {
            this.updateNote({ serviceId: null, noteId });
          });

        _socket
          .onUserNotesGrantAccess()
          .pipe(
            filter(({ token }) => token !== _token),
            takeUntil(this._deAuthenticate$)
          )
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe(({ noteIds }) => {
            this.grantAccess({ serviceId: null, noteIds });
          });

        _socket
          .onUserNotesRevokeAccess()
          .pipe(
            filter(({ token }) => token !== _token),
            takeUntil(this._deAuthenticate$)
          )
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe(({ noteIds }) => {
            this.revokeViewerAccess({ serviceId: null, noteIds });
          });
      });
  }

  ngOnDestroy(): void {
    this._deAuthenticate$.next();
    this._deAuthenticate$.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  createNote(event: IGuideNoteCreateEvent): void {
    this._create$.next(event);
  }

  deleteNote(event: IGuideNoteDeleteEvent): void {
    this._delete$.next(event);
  }

  updateNote(event: IGuideNoteUpdateEvent): void {
    this._update$.next(event);
  }

  grantAccess(event: IGuideNoteShareEvent): void {
    this._grantAccess$.next(event);
  }

  revokeViewerAccess(event: IGuideNoteShareEvent): void {
    this._revokeAccess$.next(event);
  }
}
