import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import {
  CONFIGURATOR_SCHEMA_CONFIG,
  preparePalettes,
  ThemeConfiguration
} from '@app/modules/ui-kit/_theming/config.mock';
import { UntypedFormGroup } from '@angular/forms';
import { LIGHT_THEME_BACKGROUND_PALETTE, LIGHT_THEME_FOREGROUND_PALETTE } from '@app/modules/ui-kit/kit-branding/const';
import { KitBrandingService } from '@app/modules/ui-kit/kit-branding/services';

@Component({
  selector: 'app-theme-configurator-form',
  template: `
    <div class="form-container d-flex">
      <form [formGroup]="form">
        <formly-form
          [model]="model"
          [fields]="schema"
          [options]="options"
          [form]="form"
          (modelChange)="onModelChange($event)"></formly-form>
      </form>
    </div>
  `,
  styles: [
    `
      .form-container {
        overflow-x: auto;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfiguratorFormComponent {
  schema: FormlyFieldConfig[] = CONFIGURATOR_SCHEMA_CONFIG;

  form: UntypedFormGroup = new UntypedFormGroup({});

  configuration: ThemeConfiguration = {
    palettes: preparePalettes([LIGHT_THEME_BACKGROUND_PALETTE, LIGHT_THEME_FOREGROUND_PALETTE]),
    defaults: {}
  };

  options: FormlyFormOptions = {
    formState: {
      configuration: this.configuration
    }
  };

  model = {
    palettes: [
      {
        paletteName: 'background'
      },
      {
        paletteName: 'foreground'
      }
    ],
    defaults: {
      primary: '#0389a4',
      warn: '#f44336'
    }
  };

  constructor(private _kit: KitBrandingService) {}

  onModelChange(newModel: ThemeConfiguration): void {
    this.updateConfiguration(newModel);
    const palettes = this.configuration?.palettes;
    const newBackground = (palettes && palettes[0]?.colors) || [];
    const newForeground = (palettes && palettes[1]?.colors) || [];
    const { defaults } = this.configuration;

    this._kit.loadBackground(true, newBackground);
    this._kit.loadForeground(true, newForeground);
    this._kit.loadDefaults(defaults);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateConfiguration(model: any): void {
    const primary = model.defaults?.primary;
    const warn = model.defaults?.warn;

    Object.values(model.palettes).forEach(({ paletteName, color: { colorName, colorHex } }) => {
      const changedColor = this.configuration.palettes
        .find(({ name }) => name === paletteName)
        ?.colors.find(color => color.name === colorName);

      if (changedColor) {
        changedColor.name = colorName;
        changedColor.hex = colorHex;
      }
    });

    this.configuration.defaults.primary = primary;
    this.configuration.defaults.warn = warn;
  }
}
