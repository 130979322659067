import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatChipEvent, MatChipSelectionChange } from '@angular/material/chips';

@Component({
  selector: 'ui-chip',
  template: `
    <div #content>
      <ng-content></ng-content>
    </div>
  `,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-chip'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UiChip {
  @Input() value: unknown;

  @Input() disabled: boolean | undefined;

  @Input() selectable: boolean | undefined;

  @Input() removable: boolean | undefined;

  @Input() selected: boolean | undefined;

  @Input() tabIndex!: number;

  @Input() color: ThemePalette;

  @ViewChild('content', { static: true }) content!: ElementRef<HTMLElement>;

  @Output() removed: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Output() selectionChange = new EventEmitter<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() focus: EventEmitter<any> = new EventEmitter<any>();

  get classList(): string {
    return this._elm?.nativeElement?.classList?.toString() ?? '';
  }

  get styles(): string {
    return this._elm?.nativeElement?.style?.cssText ?? '';
  }

  get innerText(): string {
    return this.content?.nativeElement?.innerHTML ?? '';
  }

  constructor(private _elm: ElementRef<HTMLElement>) {}

  remove(event: MatChipEvent): void {
    if (!event.chip.removable) {
      return;
    }

    // if (event.chip.selected) {
    //   event.chip.toggleSelected(true);
    // }

    this.removed.emit(event.chip.value);
  }
}
