<clients-select-field pui-dropdown [pui-dropdown-content]="optionsTemplate" />

<ng-template #optionsTemplate>
  @let clients = store.clientsWithSelection$ | async;

  @if (clients.length > 0) {
    <clients-select-all-option />
    @for (client of store.clientsWithSelection$ | async; track client.id) {
      <clients-select-option [client]="client" [isSelected]="client.selected" />
    }
  } @else if (clients.length === 0) {
    <div style="padding: 24px">
      <span pui-typography="body-s"> Clients not found </span>
    </div>
  }
</ng-template>
