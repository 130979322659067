import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiIconModule } from '@app/modules/ui-kit/_base/ui-icon';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { UiCardModule } from '@app/modules/ui-kit/_base/ui-card';
import { WorkspacesServerStoreService } from '@app/modules/workspaces/services/workspaces-server-store.service';
import { SharedModule } from '@app/shared';
import { CreateTeamModalComponent } from '@app/modules/workspaces/components/create-team-modal/create-team-modal.component';
import { CreateTeamStepComponent } from '@app/modules/workspaces/components/create-team-step/create-team-step.component';
import { InviteTeamStepComponent } from '@app/modules/workspaces/components/invite-team-step/invite-team-step.component';
import { CreateTeamStepperComponent } from '@app/modules/workspaces/components/create-team-stepper/create-team-stepper.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormModule } from '@app/modules/ui-kit/form/form.module';
import { UiClientSelectorMultipleModule } from '@app/modules/ui-kit/widgets/ui-client-selector-multiple';
import { UiChipListModule } from '@app/modules/ui-kit/_base/ui-chips-list';
import { UiKitHelpersModule } from '@app/modules/ui-kit/_base/_common/helpers';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { MatStepperModule } from '@app/modules/ui-kit/_base/ui-stepper';
import { RouterModule } from '@angular/router';
import { UiInputModule } from '@app/modules/ui-kit/_base/ui-input';
import { UiSelectorMultipleModule } from '@app/modules/ui-kit/_base/ui-selector-multiple';
import { UiOptionModule } from '@app/modules/ui-kit/_base/ui-option';
import {
  PuiAvatarModule,
  PuiButtonModule,
  PuiCardModule,
  PuiTooltipModule,
  PuiIconModule,
  PuiLinkModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { WorkspaceSubheaderPipe } from '@app/modules/workspaces/pipes/workspace-subheader.pipe';
import { WorkspaceAvatarPipe } from '@app/modules/workspaces/pipes/workspace-avatar.pipe';
import { POPOVER_COMPONENT_DATA } from '../floating-popover/floating-popover.service';
import { WorkspacesMenuComponent } from './components/workspaces-menu/workspaces-menu.component';
import { WorkspaceIsSoloPipe } from './pipes/workspace-solo.pipe';
import { WorkspaceTeamPipe } from './pipes/workspace-team.pipe';
import { TeamMembersSelectComponent } from './components/team-members-select/team-members-select.component';
import { MemberRoleTooltipPipe } from './pipes/member-role-tooltip.pipe';
import { WorkspaceRoleAdminPipe } from './pipes/workspace-role-admin.pipe';
import { WorkspaceRoleMemberPipe } from './pipes/workspace-role-member.pipe';
import {
  isTeamWorkspaceProvider,
  isTeamWorkspaceValueProvider
} from '@app/modules/workspaces/providers/is-team-workspace';
import {
  isSoloWorkspaceProvider,
  isSoloWorkspaceValueProvider
} from '@app/modules/workspaces/providers/is-solo-workspace';
import { isAdminWorkspaceProvider, isAdminWorkspaceValueProvider } from './providers/is-admin-workspace';
import { WorkspaceRoleTextPipe } from './pipes/workspace-role-text.pipe';
import { UserNamePipe } from '@app/shared/pipes/user-name/user-name.pipe';
import { myGuideIdProvider, myGuideIdValueProvider } from '@app/modules/workspaces/providers/my-guide-id';
import { workspaceTypeProvider } from '@app/modules/workspaces/providers/workspace-type.provider';

@NgModule({
  declarations: [
    CreateTeamModalComponent,
    CreateTeamStepComponent,
    InviteTeamStepComponent,
    CreateTeamStepperComponent,
    TeamMembersSelectComponent,
    WorkspacesMenuComponent,
    WorkspaceIsSoloPipe,
    WorkspaceTeamPipe,
    WorkspaceSubheaderPipe,
    MemberRoleTooltipPipe,
    WorkspaceAvatarPipe,
    WorkspaceRoleAdminPipe,
    WorkspaceRoleMemberPipe,
    WorkspaceRoleTextPipe
  ],
  providers: [
    { provide: MatBottomSheetRef, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
    { provide: POPOVER_COMPONENT_DATA, useValue: {} },
    WorkspacesServerStoreService,
    UserNamePipe,
    // Util providers
    isTeamWorkspaceProvider,
    isTeamWorkspaceValueProvider,
    isSoloWorkspaceProvider,
    isSoloWorkspaceValueProvider,
    isAdminWorkspaceProvider,
    isAdminWorkspaceValueProvider,
    myGuideIdProvider,
    myGuideIdValueProvider,
    workspaceTypeProvider
  ],
  imports: [
    CommonModule,
    UiIconModule,
    UiCardModule,
    SharedModule,
    ReactiveFormsModule,
    FormModule,
    UiClientSelectorMultipleModule,
    UiChipListModule,
    UiKitHelpersModule,
    NgbNavModule,
    MatStepperModule,
    RouterModule,
    UiInputModule,
    UiSelectorMultipleModule,
    UiOptionModule,
    FormsModule,
    PuiIconModule,
    PuiCardModule,
    PuiTooltipModule,
    PuiAvatarModule,
    PuiLinkModule,
    PuiButtonModule,
    PuiTypographyModule
  ],
  exports: [
    CreateTeamModalComponent,
    CreateTeamStepComponent,
    InviteTeamStepComponent,
    CreateTeamStepperComponent,
    WorkspacesMenuComponent,
    WorkspaceIsSoloPipe,
    WorkspaceTeamPipe,
    WorkspacesMenuComponent,
    TeamMembersSelectComponent,
    MemberRoleTooltipPipe,
    WorkspaceRoleAdminPipe,
    WorkspaceRoleMemberPipe,
    WorkspaceRoleTextPipe
  ]
})
export class WorkspacesModule {}
