import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewEncapsulation
} from '@angular/core';
import { AppFormFieldControl } from '@app/modules/ui-kit/form/components/form-field/form-field.component';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { SupportedCountries } from '@app/modules/billing/types';
import { PHRASES, UiKitPhrases } from '@app/modules/ui-kit/_base/_common/localize';
import { ISupportedCountry } from '@app/modules/billing/interfaces';
import { MatAutocompleteOrigin } from '@angular/material/autocomplete';

@Component({
  selector: 'ui-country-selector',
  templateUrl: 'template.html',
  styleUrls: ['./country-selector.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-country-selector'
  },
  providers: [{ provide: AppFormFieldControl, useExisting: forwardRef(() => CountrySelector) }],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CountrySelector implements OnInit, ControlValueAccessor, AppFormFieldControl, OnDestroy {
  // @ts-expect-error TS2564
  keyword: string;

  readonly text = PHRASES;

  readonly countryControl = new UntypedFormControl(null);

  // @ts-expect-error TS2564
  @Input() countries: SupportedCountries;

  // @ts-expect-error TS2564
  @Input() autocompleteConnectedTo: MatAutocompleteOrigin;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get value(): any {
    return this._value;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set value(newValue: any) {
    if (newValue !== this._value) {
      this._value = newValue;
      this._onChange(newValue);
      this._onTouched();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _value: any;

  @Input()
  required = false;

  @Input()
  placeholder: string = this.text[UiKitPhrases.CountrySelectorDefaultPlaceholder];

  @Output() selected: EventEmitter<unknown> = new EventEmitter<unknown>();

  // @ts-expect-error TS2564
  focused: boolean;

  stateChanges = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  _onChange: (value: any) => void = () => {};

  // eslint-disable-next-line @typescript-eslint/naming-convention
  _onTouched: () => void = () => {};

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private destroy$: Subject<void> = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.countryControl.valueChanges.subscribe(value => {
      this._onTouched();
      this._onChange(value?.id);
    });
  }

  ngOnInit(): void {
    this.countryControl.patchValue(this.ngControl.value);
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  displayWith = (option: ISupportedCountry) => option?.name;

  filteredCountries(): SupportedCountries {
    if (!this.countries) {
      return [];
    }

    // eslint-disable-next-line id-length
    return this.countries.filter((c: ISupportedCountry) =>
      this.keyword ? c.name.toLowerCase().includes(this.keyword.toLowerCase()) : true
    );
  }

  onPanelOpen(): void {
    this.focused = true;
    this.stateChanges.next();
  }

  onPanelClose(): void {
    this.focused = false;
    this.stateChanges.next();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  writeValue(obj: unknown): void {
    this.value = obj;
  }

  onTyped(value: string): void {
    this.keyword = value;
  }
}
