import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Params, Router, RouterStateSnapshot } from '@angular/router';
import { BrandingService } from '@app/core/branding/branding.service';
import { IMembershipSettings } from '@app/core/membership/types';
import { UserRoles } from '@app/shared/enums/user-roles';
import { GlobalConfig } from '@cnf/types';
import { environment } from '@env/environment';

import { AuthService } from '../auth/services/auth.service';
import { MembershipService } from './membership.service';

@Injectable()
export class MembershipGuard {
  constructor(
    private readonly _route: ActivatedRoute,
    private _router: Router,
    private _injector: Injector,
    private _brandingService: BrandingService,
    private _membershipService: MembershipService
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> {
    return combineLatest(
      [this._membershipService.membershipSettings$, this._brandingService.globalConfig$],
      (memSettings, globalConfig) => {
        return {
          memSettings,
          globalConfig
        };
      }
    ).pipe(
      map(combainedResult => {
        if (
          (combainedResult.memSettings.enabledForClients || combainedResult.memSettings.enabledForCoaches) &&
          combainedResult.memSettings.hidePublicRoutes
        ) {
          return this.checkMembershipSubscription(
            combainedResult.memSettings,
            route.queryParams,
            combainedResult.globalConfig
          );
        }
        return true;
      }),
      take(1)
    );
  }

  checkMembershipSubscription(
    membershipSettings: IMembershipSettings,
    params: Params,
    globalConfig: GlobalConfig
  ): boolean {
    const { enabledForClients, enabledForCoaches, defaultCoachPlan, defaultClientPlan } = membershipSettings;
    const authService = this._injector.get(AuthService);

    if (!authService.isAuthorized) {
      if (globalConfig.separatedAuth) {
        if (params.guide) {
          this._router.navigate([`/${environment.membershipRoute}/${encodeURIComponent(defaultCoachPlan)}`], {
            relativeTo: this._route,
            queryParams: {
              guide: params.guide
            },
            queryParamsHandling: 'merge'
          });
        } else {
          this._router.navigate([`/${environment.membershipRoute}/${encodeURIComponent(defaultClientPlan)}`]);
        }
        return false;
      } else {
        if (enabledForClients) {
          this._router.navigate([`/${environment.membershipRoute}/${encodeURIComponent(defaultClientPlan)}`]);
        }
        if (enabledForCoaches) {
          this._router.navigate([`/${environment.membershipRoute}/${encodeURIComponent(defaultCoachPlan)}`]);
        }
        return false;
      }
    }

    const hasMembership = authService.userHasActiveMembership;
    const userRole = authService.user.RoleId;

    if (userRole === UserRoles.GUIDE && enabledForCoaches && !hasMembership) {
      this._router.navigate([`/${environment.membershipRoute}/${encodeURIComponent(defaultCoachPlan)}`]);
      return false;
    }

    if (userRole === UserRoles.CLIENT && enabledForClients && !hasMembership) {
      this._router.navigate([`/${environment.membershipRoute}/${encodeURIComponent(defaultClientPlan)}`]);
      return false;
    }

    return true;
  }
}
