import { ExternalCalendarSources } from '@app/core/calendars/types';

export enum ExternalEventTypes {
  SINGLE = 'single',
  RECURRING = 'recurring'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IExternalEvent {
  readonly id: number;
  readonly type: ExternalEventTypes;
  readonly source: ExternalCalendarSources;
  readonly name: string;
  readonly description: string;
  readonly start: string;
  readonly end: string;
  readonly isBusy: boolean;
  readonly allDay?: boolean;
  // TODO: make required after google multi-account implementation
  readonly calendarId?: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IExternalEvents {
  readonly [source: string]: readonly IExternalEvent[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IEventsRequestOptions {
  readonly source?: ExternalCalendarSources;
  readonly calendars?: readonly number[]; // for future use
  readonly start?: number; // for future use
  readonly end?: number; // for future use
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IExternalEventCompositeKey {
  readonly id: number;
  readonly type: ExternalEventTypes;
  readonly source: ExternalCalendarSources;
}
