import { ChangeDetectionStrategy, Component, HostBinding, OnInit, computed, input } from '@angular/core';
import { PuiAvatarModule, PuiBadgeModule } from '@awarenow/profi-ui-core';
import { SharedModule } from '@app/shared';
import { DatePipe } from '@angular/common';
import { isSameDay } from 'date-fns';

@Component({
  selector: 'chat-item',
  standalone: true,
  styleUrl: 'chat-item.component.scss',
  host: {
    '[class._archived]': 'archived()',
    '[class._selected]': 'selected()'
  },
  templateUrl: 'chat-item.component.html',
  imports: [PuiAvatarModule, PuiBadgeModule, SharedModule, DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatItemComponent {
  avatar = input<string>();
  alt = input<string>();
  title = input<string>();
  subtitle = input<string>();
  date = input<string>();
  unread = input<number>();
  archived = input<boolean>();
  selected = input<boolean>();

  protected today = new Date();
  protected isSendDateToday = computed(() => isSameDay(this.today, new Date(this.date() as string)));
}
