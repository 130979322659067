import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { BlogArticleStatuses, IBlogArticleUpdate } from '../../types';
import { Observable } from 'rxjs';
import { TextEditorImageComponent } from '@app/modules/text-editor/components';
import { imageAsyncValidator, PuiDestroyService } from '@awarenow/profi-ui-core';
import { switchMap, takeUntil } from 'rxjs/operators';

function openGraphImageValidator() {
  return imageAsyncValidator(image => {
    const { width, height, src } = image;
    const ratio = width / height;

    if (ratio < 1.5 || ratio > 4.5) {
      return {
        ratio: `5 for optimal display on social media.`
      };
    }

    if (width < 600 || height < 315) {
      return {
        resolutionError: `The minimum required image resolution is 600x315 pixels, yours is ${width}x${height}.`
      };
    }

    if (!/(image\/jpeg|image\/png|image\/jpg|image\/webp)/.test(src)) {
      return {
        imageType: `Invalid image format. Unsupported MIME type. Please use a supported image format.`
      };
    }

    return null;
  });
}

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-blog-article-form',
  templateUrl: './blog-article-form.component.html',
  styleUrls: ['./blog-article-form.component.scss']
})
export class BlogArticleFormComponent implements OnInit {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _articleId: number;

  readonly statuses = BlogArticleStatuses;

  readonly fileControl = new UntypedFormControl(null, [], [openGraphImageValidator()]);

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set article(article: any) {
    if (!article) {
      return;
    }

    if (article.status === BlogArticleStatuses.PUBLISHED) {
      this.published = true;
    }

    this.emailSent = article.emailSent;
    this.updateForm(article);
  }

  // TODO: looks like this form component is incorrect place for send out functionality, but this is a quick solution
  @Input()
  // @ts-expect-error TS2564
  canSendOut: boolean;

  @Input()
  // @ts-expect-error TS2564
  isBusinessTypeAllowed: boolean;

  @Input()
  // @ts-expect-error TS2564
  isSaveDisabled: boolean;

  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tags: any[];

  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploaderService: (file: File) => Observable<any>;

  @Output()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  save = new EventEmitter<any>();

  @Output()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendOut = new EventEmitter<any>();

  @Output()
  uploadCover = new EventEmitter<File>();

  @ViewChild('textEditorInstance', { static: true, read: TextEditorImageComponent })
  // @ts-expect-error TS2564
  private textEditor: TextEditorImageComponent;

  published = false;
  emailSent = false;
  // @ts-expect-error TS2564
  form: UntypedFormGroup;
  errorTitle = `Publish error`;
  coverImageState: 'idle' | 'loading' | 'ready' = 'idle';

  constructor(
    private _notifications: NotificationsService,
    @Inject(PuiDestroyService) private destroy$: Observable<void>
  ) {}

  ngOnInit(): void {
    this.fileControl.valueChanges
      .pipe(
        switchMap(() => this.fileControl.statusChanges),
        takeUntil(this.destroy$)
      )
      .subscribe(status => {
        console.log(status);

        if (status === 'VALID') {
          this.onCoverImageInputChange(this.fileControl.value);
        }
      });

    this.initForm();
  }

  onCoverImageInputChange([file]: File[]): void {
    this.emitUploadCoverImage(file);
  }

  emitSave(status: BlogArticleStatuses): void {
    if (this.isSaveDisabled) {
      return;
    }

    const formValue = this.form.value;
    const quillDeltaOps = formValue.contentRepresentation.ops;
    const content = this.textEditor.getContentMarkup();

    if (!this.areFormValuesCorrect(formValue, content, quillDeltaOps)) {
      return;
    }

    const { error: parsingError, description } = this.parseDescription(quillDeltaOps);
    if (parsingError) {
      this._notifications.error(this.errorTitle, parsingError.message);
      return;
    }

    const newArticle: IBlogArticleUpdate = {
      title: formValue.title,
      url: formValue.url,
      description,
      coverImage: formValue.coverImageUrl,
      contentRepresentation: JSON.stringify(formValue.contentRepresentation),
      contentText: this.textEditor.getContentText(),
      content,
      tags: formValue.articleTags,
      isPodcast: formValue.isPodcast,
      status
    };

    if (this._articleId) {
      newArticle.id = this._articleId;
    }

    if (this.isBusinessTypeAllowed && formValue.isBusiness) {
      newArticle.isBusiness = true;
    }

    this.save.emit(newArticle);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  resetCoverImage() {
    this.form.controls.coverImageUrl.reset();
    this.coverImageState = 'idle';
  }

  sendOutEmit(): void {
    this.sendOut.emit(this._articleId);
  }

  // set image from parent component
  setCoverImage(imageUrl: string): void {
    this.form.controls.coverImageUrl.setValue(imageUrl);
    this.coverImageState = imageUrl ? 'ready' : 'idle';
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      articleTags: new UntypedFormControl([]),
      contentRepresentation: new UntypedFormControl(''),
      coverImageUrl: new UntypedFormControl(''),
      title: new UntypedFormControl(''),
      url: new UntypedFormControl(''),
      isPodcast: new UntypedFormControl(false),
      isBusiness: new UntypedFormControl(false)
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private updateForm(article: any): void {
    this.form.setValue(
      {
        title: article.title || '',
        url: article.url || '',
        coverImageUrl: article.coverImage || '',
        articleTags: article.tags || [],
        contentRepresentation: JSON.parse(article.contentRepresentation) || '',
        isPodcast: article.isPodcast,
        isBusiness: article.isBusiness
      },
      { emitEvent: false }
    );

    if (article.id) {
      this._articleId = article.id;
    }
  }

  private emitUploadCoverImage(file: File): void {
    this.coverImageState = 'loading';
    this.uploadCover.emit(file);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  private areFormValuesCorrect(formValue, content: string, deltaOps: any[]): boolean {
    if (!content) {
      const message = `Article cannot be empty`;
      this._notifications.error(this.errorTitle, message);
      return false;
    }
    // Maybe in future this check will be needed
    // if (!formValue.articleTags.length) {
    //   this._notifications.error(`Publish error', 'Article tags required`);
    //   return false;
    // }

    if (!formValue.title) {
      const message = `Article title required`;
      this._notifications.error(this.errorTitle, message);
      return false;
    }

    if (!formValue.coverImageUrl) {
      const message = `Article cover image required`;
      this._notifications.error(this.errorTitle, message);
      return false;
    }

    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private parseDescription(content: any[] | null): {
    error: { message: string } | null;
    description: string;
  } {
    const response = {} as { error: { message: string } | null; description: string };

    const firstParagraph = content
      ? content.find(node => typeof node.insert === 'string' && /\S/.test(node.insert))
      : null;

    if (firstParagraph) {
      response.description = firstParagraph.insert.trim();
    } else {
      response.error = { message: 'Article should have at least one paragraph of text' };
    }
    return response;
  }
}
