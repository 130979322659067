import { NotificationsService } from '@awarenow/profi-ui-core';
import { EMPTY, Subject, Subscription, timer } from 'rxjs';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';

import { isPlatformBrowser, Location } from '@angular/common';
import { Component, HostBinding, Inject, NgZone, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { InternalEvents } from '@app/core/analytics/types';
import { AuthService } from '@app/core/auth/services/auth.service';
import { BrandingService } from '@app/core/branding/branding.service';
import { LocaleService } from '@app/core/locale/locale.service';
import { IGuideService, IServiceBookingOptions, ServiceBookingService } from '@app/modules/book-service';
import { BookingService } from '@app/modules/book-service/services/booking.service';
import { BlogReaderService } from '@app/screens/blog/services/blog-reader.service';
import { DisqusWidgetsService } from '@app/screens/blog/services/disqus-widgets.service';
import { EmbedDisqusCountScriptService } from '@app/screens/blog/services/embed-disqus-count-script.service';
import { BlogArticle, BlogAuthorGuide, IArticleSelection, IBlogTag, IBookEvent } from '@app/screens/blog/types';
import { makeUriFromString } from '@app/screens/blog/utils';
import { UserRoles } from '@app/shared/enums/user-roles';
import { environment } from '@env/environment';
import { ILocale } from '@env/locale.interface';
import { MetaTagService } from '@libs/services/meta-tag/meta-tag.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-blog-article',
  templateUrl: './blog-article.component.html',
  styleUrls: ['./blog-article.component.scss'],
  providers: [BlogReaderService, EmbedDisqusCountScriptService, DisqusWidgetsService]
})
export class BlogArticleComponent implements OnInit, OnDestroy {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _routeSubscription: Subscription;
  private destroy$ = new Subject<void>();
  private locale: ILocale;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  article: any;
  // @ts-expect-error TS2564
  disqusIdentifier: string;
  // @ts-expect-error TS2564
  disqusTitle: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  latestArticles: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  relatedArticles: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  relatedGuides: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  relatedTags: any;
  // @ts-expect-error TS2564
  defaultAppTitle: string;

  @HostBinding('class.container')
  isContainer = true;

  get canEditBlogArticle(): boolean {
    return !!this.article && this.isAuthenticatedArticleAuthorOrAdmin();
  }

  constructor(
    protected _route: ActivatedRoute,
    protected _router: Router,
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID) protected platformId,
    protected _title: Title,
    protected _auth: AuthService,
    protected _service: BlogReaderService,
    protected _serviceBooking: ServiceBookingService<IServiceBookingOptions<IGuideService>, void>,
    protected _embedDisqusCountScript: EmbedDisqusCountScriptService,
    protected _disqusWidgets: DisqusWidgetsService,
    protected _analytics: AnalyticsService,
    protected _branding: BrandingService,
    protected _location: Location,
    protected _zone: NgZone,
    protected _localeService: LocaleService,
    protected _notificationsService: NotificationsService,
    private bookingService: BookingService,
    private metaTagService: MetaTagService
  ) {
    this.locale = this._localeService.getLocale();
  }

  ngOnInit(): void {
    this._route.data.pipe(takeUntil(this.destroy$)).subscribe(
      ({ article }) => {
        const source = article.url || article.title;
        this.article = article;
        this._location.replaceState(`/blog/post/${makeUriFromString(source, article.id)}`);
        this.setDisqusMeta();

        this._zone.run(() => {
          this.setOGMeta(article);
          this.setArticleTitle();
        });
      },
      () => this._router.navigate(['/blog'], { replaceUrl: true })
    );

    this.loadLatestArticles();
    this.loadRelatedArticles();
    this.loadRelatedTags();

    this._branding.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(({ metaTitleMainPage }) => {
      this.defaultAppTitle = metaTitleMainPage;
    });

    this._analytics.event(InternalEvents.BLOG_ARTICLE);
  }

  ngOnDestroy(): void {
    // TODO: refactor subscription
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }

    this.destroy$.next();
    this.destroy$.complete();
    this.setTitle(this.defaultAppTitle);
  }

  setTitle(title = ''): void {
    this._title.setTitle(title);
  }

  bookSession(bookEvent: IBookEvent): void {
    if (bookEvent && bookEvent.guide && bookEvent.guide.isBookAllowed) {
      this.bookingService.guideIndividualPage(bookEvent.guide.id, bookEvent.guide.workspaceId);
    }
  }

  openAuthor(namedUrl: string): void {
    if (namedUrl) {
      this._router.navigate(['/', namedUrl]);
    }
  }

  selectRelatedArticle(event: IArticleSelection): void {
    this._router.navigate(['/blog', event.authorUrl, 'posts', event.articleId]);
  }

  selectTag(tag: IBlogTag): void {
    this._router.navigate(['/blog', tag ? { tags: [tag.name] } : {}]);
  }

  private isAuthenticatedArticleAuthorOrAdmin(): boolean {
    return (
      this._auth.isAuthorized &&
      (this._auth.user.RoleId === UserRoles.ADMIN ||
        (this._auth.user.namedUrl && this._auth.user.namedUrl === this.article.author.namedUrl) ||
        this._auth.user.id === +this.article.author.namedUrl)
    );
  }

  private loadLatestArticles(): void {
    this._service
      .getLatestArticles$()
      .pipe(
        tap(articles => (this.latestArticles = articles)),
        mergeMap(() => (isPlatformBrowser(this.platformId) ? timer(0) : EMPTY)),
        tap(() => this.updateDisqusCounters()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private loadRelatedArticles(): void {
    this._service
      .getBlogArticleRelatedArticles$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(articles => (this.relatedArticles = articles));
  }

  private loadRelatedTags(): void {
    this._service
      .getBlogArticleRelatedTags$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(tags => (this.relatedTags = tags));
  }

  private setDisqusMeta(): void {
    if (environment.disqusId && this.article.disqus) {
      this.disqusIdentifier = ''; // this.article.disqus.id; // removed temprorary
      this.disqusTitle = this.article.disqus.title;
    }
  }

  private setArticleTitle(): void {
    this._title.setTitle(`${this.article.title}`);
  }

  private setOGMeta(article: BlogArticle): void {
    // @ts-expect-error TS7006
    const tags = this.article.tags.map(tag => tag.name).join(', ');

    this.metaTagService.upsertMetaTags({
      title: article.title,
      description: article.description,
      image: article.coverImage,
      url: `${this.locale.baseUrl}${this._router.url}`,
      type: 'article',
      keywords: tags,
      'article:author': article.author instanceof BlogAuthorGuide ? article.author.name : article.author.namedUrl,
      'article:published_time': article.isoDate,
      'article:tag': tags
    });
  }

  private updateDisqusCounters(): void {
    if (this._disqusWidgets.DISQUSWIDGETS) {
      this._disqusWidgets.DISQUSWIDGETS.getCount({ reset: true });
    }
  }
}
