import { DateTime } from 'luxon';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { IPublicProgramItem } from '@app/shared/interfaces/programs';
import { CurrencyService } from '@app/core/currency/currency.service';
import { SubscriptionStatus } from '@app/shared/enums/subscription';
import { makeUriFromString } from '@app/screens/blog/utils';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-program-item',
  templateUrl: './program-item.component.html',
  styleUrls: ['../../../../../scss/programs/program-item-base.scss', './program-item.component.scss']
})
export class ProgramItemComponent {
  readonly SubscriptionStatus = SubscriptionStatus;

  readonly subscriptionReqStrings = {
    month: `month`,
    year: `year`,
    week: `week`
  };

  @Input()
  // @ts-expect-error TS2564
  program: IPublicProgramItem;

  // @ts-expect-error TS2564
  @Input() isCommunity: boolean;

  @Input()
  // @ts-expect-error TS7008
  routerLink;

  @Input()
  // @ts-expect-error TS7008
  programLink;

  @Input()
  displayProgress = true;

  @Output()
  declineInvite = new EventEmitter();

  @Output()
  programSelect = new EventEmitter();

  @HostListener('click')
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  selectProgram() {
    this.programSelect.emit(this.program);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get completed() {
    return this.program && this.program.progress === 1;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get programEnrolledDateCaption() {
    return `after enrollment`;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get programStartDate() {
    if (this.program.startType === 'user_enrollment') {
      return this.program.enrolledAt || this.today;
    }
    return this.program.startDate;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get today() {
    return new Date();
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  preventBubbling(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  inviteDecline(event) {
    this.declineInvite.emit(this.program.id);
    this.preventBubbling(event);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(public currency: CurrencyService) {}

  get isNotActiveSubscription(): boolean {
    // @ts-expect-error TS2322
    return (
      this.program && this.program.subscriptionStatus && this.program.subscriptionStatus !== SubscriptionStatus.ACTIVE
    );
  }

  get canceledSubscriptionEndDate(): string | null {
    if (!this.program || !this.program.subscriptionStatus) {
      return null;
    }

    if (
      this.program.subscriptionStatus !== SubscriptionStatus.CANCELED &&
      this.program.subscriptionStatus !== SubscriptionStatus.CANCELED_BY_OWNER
    ) {
      return null;
    }

    if (
      this.program.subscriptionCurrentPeriodEnd &&
      DateTime.fromISO(this.program.subscriptionCurrentPeriodEnd).toMillis() < DateTime.local().toMillis()
    ) {
      return null;
    }

    // @ts-expect-error TS2322
    return this.program.subscriptionCurrentPeriodEnd;
  }

  get link(): string {
    // @ts-expect-error TS2345
    return makeUriFromString(this.program.name, this.program.id);
  }
}
