<div class="primary-layout__container">
  <!--SIDE NAVIGATION-->
  <!--  TODO Back mobile menu behavior-->
  <aside class="primary-layout__aside">
    <app-sidenav />
  </aside>
  <!--MAIN-->
  <main class="primary-layout__main">
    <!--  TODO Back mobile header behavior -->
    <!--MOBILE HEADER-->
    <!--    @if (!view.sidenav.isForceHideHeader) {-->
    <!--      <app-primary-layout-header *MobileAOrSmallTablet (toggle-sidenav)="toggle()" />-->
    <!--    }-->

    <!--USER ALERTS BLOCK-->
    <app-alerts-provider />

    <!--PAGE CONTENT-->
    <pui-content class="primary-layout__content">
      <router-outlet></router-outlet>
    </pui-content>
  </main>
</div>
