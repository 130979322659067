<ng-container [ngSwitch]="module?.status">
  <ng-container *ngSwitchCase="'allowed'">
    <ng-container *ngIf="module.completionType === ModuleCompletionTypes.GUIDE">
      <ng-container *ngTemplateOutlet="incompleteModuleGuideCompletion"></ng-container>
    </ng-container>

    <ng-container *ngIf="module.completionType === ModuleCompletionTypes.CLIENT; else quizActionsContainer">
      <ng-container *ngTemplateOutlet="incompleteModuleClientCompletion"></ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'seen'">
    <ng-container *ngIf="doesModuleHaveActions; else quizActionsContainer">
      <ng-container *ngTemplateOutlet="completeModuleActions"></ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #incompleteModuleGuideCompletion>
  <div class="incomplete-module-title" i18n="moduleActionsBoardIncompleteModuleTitle">Module is not completed</div>
  <div class="incomplete-module-note" i18n="moduleActionsBoardIncompleteModuleNote">
    Your instructor will mark this module as complete.
  </div>
</ng-template>

<ng-template #incompleteModuleClientCompletion>
  <div class="incomplete-module-actions">
    <ng-container *ngTemplateOutlet="quizActions"></ng-container>
    <button pui-button (click)="complete.emit()" i18n="@@moduleActionsBoardCompleteBtn">Mark as complete</button>
  </div>
</ng-template>

<ng-template #completeModuleActions>
  <div class="row">
    <div class="col-lg-12">
      <div class="row">
        <div>
          <div class="complete-module-badge-container d-flex align-items-center">
            <pui-icon class="complete-icon" svgIcon="pui:status-done"></pui-icon>
            <div class="complete-module-badge" pui-typography="body-s-bold" i18n="@@moduleActionsBoardTextCompleted">
              Completed
            </div>
          </div>
        </div>

        <div class="col-lg-auto flex-shrink-0 pl-4" *ngIf="isNextModuleAvailable">
          <div class="incomplete-module-actions">
            <ng-container *ngTemplateOutlet="quizActions"></ng-container>
            <button pui-button i18n="@@moduleActionsBoardNextModuleBtn" (click)="nextModule.emit()">Next</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #quizActionsContainer>
  <div *ngIf="canRetryQuiz || canFinishQuiz" class="quiz-actions-container">
    <ng-container *ngTemplateOutlet="quizActions"></ng-container>
  </div>
</ng-template>

<ng-template #quizActions>
  <button pui-button appearance="secondary" *ngIf="canRetryQuiz" (click)="retryQuiz.emit({ moduleId: module.id })">
    Try again
  </button>
  <button pui-button *ngIf="canFinishQuiz" (click)="finishQuiz.emit({ moduleId: module.id })">Finish test</button>
</ng-template>
