import { NotificationsService } from '@awarenow/profi-ui-core';
import { concat, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, finalize, map, skip, switchMap, takeUntil, tap } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { IOnlineStatuses } from '@app/shared/interfaces/user';

import config from '../config/config';
import { OnlineStatusService } from '../status/online-status.service';
import { CloneableUser } from './types';

// TODO: Add Angular decorator.
@Injectable()
export abstract class RelatedUsers<TId, T extends CloneableUser> implements OnDestroy {
  protected readonly ENDPOINT;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _users = new Map<TId, T>([]);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _users$ = new ReplaySubject<T[]>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _refresh$ = new Subject<TId[] | null | void>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _refreshUsersOnlineStatuses$ = new Subject<void>();

  protected destroy$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _areUsersLoading = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _http: HttpClient;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _onlineStatuses: OnlineStatusService;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _notifications: NotificationsService;

  get users$(): Observable<T[]> {
    return this._users$.asObservable();
  }

  // @ts-expect-error TS7006
  protected constructor(endpoint, http, notifications, onlineStatuses) {
    this.ENDPOINT = endpoint;
    this._http = http;
    this._notifications = notifications;
    this._onlineStatuses = onlineStatuses;

    this._refresh$
      .pipe(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        switchMap(ids => this.loadUsers$(ids)),
        tap(() => this._refreshUsersOnlineStatuses$.next()),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this._refreshUsersOnlineStatuses$
      .pipe(
        map(() => this.getUserIdsToTrackOnlineStatus()),
        switchMap(ids => this.refreshOnlineStatusesInfo$(ids)),
        tap(statuses => this.refreshUsersOnlineStatuses(statuses)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkNewUsers(ids: TId[]): void {
    if (this._areUsersLoading) {
      return;
    }

    const notLoadedUsersIds = ids.filter(id => !this._users.has(id));
    if (notLoadedUsersIds.length) {
      this._refresh$.next(notLoadedUsersIds);
    }
  }

  getUser$(id: TId): Observable<T> {
    if (!id) {
      // TODO: remove after testing
      throw new Error('HERE CANT GET USER');
    }
    const initiator = this._users.has(id) ? of(null) : this.loadUser$(id);
    return concat(initiator, this._users$).pipe(
      skip(1), // skip initiator value
      map(() => {
        const user = this._users.get(id);
        // @ts-expect-error TS2532
        return user.clone();
      })
    );
  }

  refresh(): void {
    if (this._areUsersLoading) {
      return;
    }

    this._refresh$.next();
  }

  reset(): void {
    this._users.clear();
    this._users$.next([]);
  }

  protected fireUsersUpdated(): void {
    this._users$.next([...this._users.values()]);
  }

  protected abstract getUserIdsToTrackOnlineStatus(): number[];

  protected abstract loadUser$(id: TId, workspaceId?: number | string): Observable<T>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected loadUsers$(ids?: TId[] | null): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: ids && ids.length ? this.prepareHttpParamsObjectForUsersLoader(ids) : {}
    });

    this._areUsersLoading = true;
    return this._http.get(this.ENDPOINT, { params: httpParams }).pipe(
      map(response => this.mapToUsers(response)),
      tap(users => this.updateUsers(users)),
      catchError(() => {
        const title = `Session error.`;
        const content = `Unexpected error.`;
        this._notifications.error(title, content);
        return of([]);
      }),
      finalize(() => {
        this._areUsersLoading = false;
      })
    );
  }

  // @ts-expect-error TS7010
  protected abstract mapToUsers(serverResponse);

  // @ts-expect-error TS7010
  protected abstract prepareHttpParamsObjectForUsersLoader(ids: TId[]);

  protected refreshUsersOnlineStatuses(statuses: IOnlineStatuses): void {
    let changesCounter = 0;
    this._users.forEach(user => {
      const newStatus = statuses[user.id];

      if (user.isOnline !== newStatus) {
        user.isOnline = newStatus;
        changesCounter++;
      }

      if (user.id === -1) {
        user.isOnline = true;
      }
    });

    if (changesCounter) {
      this.fireUsersUpdated();
    }
  }

  protected refreshOnlineStatusesInfo$(ids: number[]): Observable<IOnlineStatuses> {
    if (!ids || !ids.length) {
      return of([]);
    }

    return this._onlineStatuses.getOnlineStatuses$(ids, config.pingOnlineInterval);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected abstract updateUsers(serverUsers: any): void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected addGuides$(userId: number, guideIds: number[]): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.post<any>(`${this.ENDPOINT}/clients/${userId}/guides`, { guideIds });
  }
}
