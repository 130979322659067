import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { GoogleAnalyticsCategories, InternalEvents } from '@app/core/analytics/types';
import config from '@app/core/config/config';
import { PaymentMethods } from '@app/shared/enums/payment-methods';
import { GuideServiceTypes, IBookingResult } from '@app/shared/interfaces/services';
import { IGiftCardPaymentDetails } from '@app/shared/interfaces/gift-coupons';
import { IPaymentInfo } from '@app/shared/interfaces/session';
import { AuthService } from '@app/core/auth/services/auth.service';
import { IUserMembership } from '@app/core/membership/types';
import { SessionsService } from '@app/core/session/sessions.service';
import { PaymentOptions } from '@app/shared/enums/payment-options';
import { SessionsTypes } from '@app/shared/enums/sessions-types';
import { Payment, PayService } from '../types';

const APPROVE_SESSION_OFFER_ENDPOINT = `${config.apiPath}/user/client/sessions/offers/approve`;

const GIFT_CARD_ENDPOINT = `${config.apiPath}/coupons/gifts/order`;

const PROGRAMS_ENDPOINT = `${config.apiPath}/user/client/programs`;

const PACKAGES_ENDPOINT = `${config.apiPath}/user/client/packages`;

const PLATFORM_PLAN_ENDPOINT = `${config.apiPath}/membership`;

const SUBSCRIPTIONS = `${config.apiPath}/user/common/subscriptions`;

const REPAY_OVERDRAFT_ENDPOINT = `${config.apiPath}/user/client/payments/pay-overdraft`;

const SERVICE_ENDPOINT = `${config.apiPath}/user/client/services/books`;

const OFFER_RESCHEDULE_ENDPOINT = `${config.apiPath}/user/client/sessions/reschedule`;

const _ERROR_NOTIFICATION_TIME_OUT = 10000;

const ALREADY_ENROLLED = 400;

@Injectable({
  providedIn: 'root'
})
export class PayWithOtherPaymentsService implements PayService {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _payment: Payment;

  constructor(
    private _http: HttpClient,
    private _notifyService: NotificationsService,
    private _analytics: AnalyticsService,
    private _authService: AuthService,
    private _sessions: SessionsService
  ) {}

  get paymentInfo(): IPaymentInfo | null {
    if (!this._payment) {
      return null;
    }

    const { paymentData, saveCard, isNewPaymentMethod } = this._payment;

    return {
      id: paymentData,
      save: saveCard,
      new: isNewPaymentMethod
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  init(payment: Payment) {
    this._payment = payment;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sessionOffer$(id: number): Observable<any> {
    // @ts-expect-error TS7034
    let _session = null;
    return this._sessions.startAcceptSessionOffer$(id).pipe(
      tap(session => (_session = session)),
      switchMap(() =>
        this._http.post(APPROVE_SESSION_OFFER_ENDPOINT, this._extendParams({ id, withAllRecurring: true }))
      ),
      // @ts-expect-error TS2345
      tap(() => this._sessions.acceptSessionOfferSuccess(_session)),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tap((data: any = {}) => {
        // Todo: ACCEPT_SESSION_OFFER event is deprecated. Ask Vasily about the possibility of deletion
        this._analytics.event(InternalEvents.ACCEPT_SESSION_OFFER, {
          session: data.session,
          paymentMethod: PaymentMethods.CREDIT_CARD
        });
      }),
      // @ts-expect-error TS7005
      catchError(error => this._sessions.acceptSessionOfferFailure$(error, _session))
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  service$(bookingDetails: any): Observable<IBookingResult> {
    const paymentInfo = this.paymentInfo
      ? {
          ...this.paymentInfo,
          oneTimePayment: bookingDetails?.paymentOption === PaymentOptions.FULL_PRICE,
          save:
            this.paymentInfo.save ||
            (this.paymentInfo.new && bookingDetails?.paymentOption === PaymentOptions.INSTALLMENTS)
        }
      : null;

    return this._http
      .post<{ bookingResult: IBookingResult }>(SERVICE_ENDPOINT, this._extendParams(bookingDetails, paymentInfo))
      .pipe(map(({ bookingResult }) => bookingResult)) as Observable<IBookingResult>;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  session$(bookingDetails: any): Observable<IBookingResult> {
    return this.service$(bookingDetails);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reschedule$(id: number, date: string, sessionType: SessionsTypes, message?: string): Observable<any> {
    // @ts-expect-error TS7034
    let _session = null;
    return this._sessions.startRescheduleSession$(id, sessionType).pipe(
      tap(session => (_session = session)),
      switchMap(() => this._http.post(OFFER_RESCHEDULE_ENDPOINT, this._extendParams({ id, date, message }))),
      // @ts-expect-error TS2322
      catchError(error => this._sessions.rescheduleSessionFailure$(error, { sessionToRevert: _session, sessionType }))
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  coupon$(giftCoupon: IGiftCardPaymentDetails): Observable<any> {
    return this._http.post(GIFT_CARD_ENDPOINT, this._extendParams({ giftCoupon })).pipe(
      catchError((httpError: HttpErrorResponse) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errorDetails = {} as any;

        const { error } = httpError;
        if ((error && error.msg) || (error && error.payload)) {
          const msg = error.msg ?? error.payload?.error;

          switch (msg) {
            case -1:
              errorDetails.message = 'Internal error occurred during order processing';
              break;
            case 1:
              errorDetails.message = 'Please add sender name and email';
              break;
            case 2:
              errorDetails.message = 'Cannot identify authorized user';
              break;
            case 3:
              errorDetails.message = 'Billing info required';
              break;
            case 4:
              errorDetails.message = 'Internal error occurred during payment processing';
              break;
            default:
              errorDetails.message = msg;
              break;
          }
        } else {
          errorDetails.message = 'Something wrong happened';
        }

        return throwError(errorDetails);
      })
    );
  }

  program$(
    programId: number,
    meta?: { serviceHost: number; serviceType: GuideServiceTypes; paymentOption?: PaymentOptions | null }
  ): Observable<IBookingResult> {
    const paymentInfo = this.paymentInfo
      ? {
          ...this.paymentInfo,
          id: this.paymentInfo.id ?? '',
          oneTimePayment: meta?.paymentOption === PaymentOptions.FULL_PRICE,
          save: this.paymentInfo.save || (this.paymentInfo.new && meta?.paymentOption === PaymentOptions.INSTALLMENTS)
        }
      : null;

    return (
      this._http
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .post<any>(
          `${PROGRAMS_ENDPOINT}/${programId}/orders`,
          this._extendParams(
            {
              serviceId: programId,
              // @ts-expect-error TS2532
              type: meta.serviceType,
              // @ts-expect-error TS2532
              serviceHost: meta.serviceHost
            },
            paymentInfo
          )
        )
        .pipe(
          catchError(error => {
            let response;
            if (error.status === ALREADY_ENROLLED) {
              const message = `Something went wrong`;
              this._notifyService.error(error.error.errors || message);
              response = throwError(error);
            } else {
              response = throwError(error);
            }

            return response;
          }),
          mapTo({ id: programId, serviceId: programId, serviceType: GuideServiceTypes.PROGRAM })
        )
    );
  }

  package$(
    packageId: number,
    meta?: { serviceType: GuideServiceTypes; paymentOption?: PaymentOptions | null }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    const paymentInfo = this.paymentInfo
      ? {
          ...this.paymentInfo,
          id: this.paymentInfo.id ?? '',
          oneTimePayment: meta?.paymentOption === PaymentOptions.FULL_PRICE,
          save: this.paymentInfo.save || (this.paymentInfo.new && meta?.paymentOption === PaymentOptions.INSTALLMENTS)
        }
      : null;

    return (
      this._http
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .post<any>(
          `${PACKAGES_ENDPOINT}/${packageId}/orders`,
          this._extendParams(
            {
              serviceId: packageId,
              // @ts-expect-error TS2532
              type: meta.serviceType
            },
            paymentInfo
          )
        )
        .pipe(
          catchError(httpError => {
            let response;
            if (httpError.status === 400) {
              this._notifyService.error(httpError.error.errors || 'Something went wrong');
              response = throwError(httpError);
            } else {
              response = throwError(httpError);
            }

            return response;
          }),
          mapTo({ id: packageId, serviceId: packageId, serviceType: GuideServiceTypes.PACKAGE })
        )
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  platformPlan$(planType: string, planRecurrency: any): Observable<any> {
    const paymentInfo = {
      ...(this.paymentInfo || {}),
      save: this.paymentInfo?.save || (this.paymentInfo?.new && !!planRecurrency),
      selectedRecurrency: planRecurrency
    };

    return this._http
      .post<{ membership: IUserMembership; isFirstPaymentOfSubscription: boolean }>(
        `${PLATFORM_PLAN_ENDPOINT}/${encodeURIComponent(planType)}/switch`,
        this._extendParams({}, paymentInfo)
      )
      .pipe(
        tap(({ membership, isFirstPaymentOfSubscription }) => {
          if (membership) {
            this._authService.setUserMembership(membership);
          }

          if (membership && isFirstPaymentOfSubscription) {
            this._analytics.event(InternalEvents.PLATFORM_SUBSCRIBED, {
              amount: membership.amount,
              category: GoogleAnalyticsCategories.SUBSCRIPTION,
              planName: membership.name
            });
          }
        })
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subscriptionReactivate$(id: number): Observable<any> {
    return this._http.post(`${SUBSCRIPTIONS}/${id}/reactivate`, this._extendParams({}));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  overdraft$(): Observable<any> {
    return this._http.post(REPAY_OVERDRAFT_ENDPOINT, this._extendParams({})).pipe(
      catchError(err => {
        this._notifyService.error('Server error', err.error.msg, {
          timeOut: _ERROR_NOTIFICATION_TIME_OUT
        });
        return throwError(err);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  private _extendParams(params: any, paymentInfo: any = this.paymentInfo) {
    if (paymentInfo) {
      return { ...params, paymentInfo };
    }

    return params;
  }
}
