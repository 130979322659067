<div class="user-menu-wrapper" *ngIf="authService.isAuthorized">
  <div class="user-info" (click)="toggleDropdown()" [ngClass]="{ light: commonHeader, isOpen: expand }">
    <dl class="d-none d-md-block">
      <dt>{{ user.firstName }} {{ user.lastName }}</dt>
      <dd
        *ngIf="
          config.hideSignInAs ||
          userRole === UserRoles.ADMIN ||
          (userRole === UserRoles.CLIENT && !user.hasAlternativeProfile)
        ">
        {{ user.email }}
      </dd>
      <dd
        *ngIf="
          !config.hideSignInAs &&
          (userRole === UserRoles.GUIDE || (userRole === UserRoles.CLIENT && user.hasAlternativeProfile))
        "
        class="switcher"
        (click)="stopPropagation($event)">
        <span [ngClass]="{ active: userRole === UserRoles.GUIDE }" (click)="switchToAlternativeAccount(false)">
          <ng-container transloco="practitioner">Practitioner</ng-container>
        </span>
        <pui-toggle
          [ngModel]="isClient"
          (ngModelChange)="switchToAlternativeAccount($event); isClient = $event"
          placement="top"
          tooltipClass="switcher-tooltip"
          [ngbTooltip]="userRole === UserRoles.GUIDE ? clientTooltip : guideTooltip"></pui-toggle>
        <span [ngClass]="{ active: userRole === UserRoles.CLIENT }" (click)="switchToAlternativeAccount(true)">
          <ng-container i18n="@@client">Client</ng-container>
        </span>
      </dd>
    </dl>
    <!--    <app-user-photo class="user-photo" [size]="window.innerWidth < 768 ? 36 : 45" [photoUrl]="user.photo">-->
    <!--    </app-user-photo>-->
    <!--    TODO Avatar size 36x36 or 45 not exist-->
    <!--    TODO [size]="window.innerWidth < 768 ? 36 : 45" This worst decision ever seen-->
    <pui-avatar
      class="user-photo"
      [avatarUrl]="user.photo"
      [text]="user | userName"
      [style.width]="window.innerWidth < 768 ? 36 : 45"
      [style.height]="window.innerWidth < 768 ? 36 : 45"></pui-avatar>
    <a href="javascript:;" class="pointer"></a>
  </div>
  <div *ngIf="expand" class="dropdown" (clickOutside)="hideDropdown()" [exclude]="'.user-info'">
    <ul (click)="hideDropdown()">
      <ng-template [ngIf]="userRole === UserRoles.CLIENT && fullMenu">
        <li routerLinkActive="active">
          <a class="public-page" routerLink="/client/settings/edit-profile" i18n="@@myAccount">My account</a>
        </li>
        <li routerLinkActive="active" *ngIf="!config.hideWallet">
          <a class="wallet" routerLink="/client/settings/wallet" i18n="@@wallet">Wallet</a>
        </li>
        <li routerLinkActive="active" *ngIf="showSubscriptionsForClient">
          <a class="subscription" routerLink="/client/settings/subscription" i18n="@@subscription">Subscription</a>
        </li>
        <ng-template [ngIf]="userHavePromoterDashboard">
          <li *ngIf="promoterUrl !== null">
            <a
              class="promoter"
              href="{{ promoterUrl }}"
              target="_blank"
              rel="nofollow noreferrer noopener"
              i18n="@@promoterDashboard">
              Earn affiliate rewards
            </a>
          </li>
          <li *ngIf="promoterUrl === null">
            <a class="promoter" (click)="createPromoter()" i18n="@@promoterDashboard">Earn affiliate rewards</a>
          </li>
        </ng-template>
      </ng-template>
      <ng-template [ngIf]="userRole === UserRoles.GUIDE && fullMenu">
        <li routerLinkActive="active">
          <a class="public-page" routerLink="/{{ guideRoute }}/settings/edit-profile" i18n="@@userMenuublicPage">
            Public page
          </a>
        </li>
        <li routerLinkActive="active" *ngIf="!config.hideWallet">
          <a class="wallet" routerLink="/{{ guideRoute }}/wallet" i18n="@@userMenuPayments">Payments</a>
        </li>
        <li routerLinkActive="active">
          <a class="branding" routerLink="/{{ guideRoute }}/settings/branding" i18n="@@branding">Branding</a>
        </li>
        <li routerLinkActive="active" *ngIf="showSubscriptionsForGuide">
          <a class="subscription" routerLink="/{{ guideRoute }}/settings/subscription" i18n="@@subscription">
            Subscription
          </a>
        </li>
        <li routerLinkActive="active">
          <a class="personal-info" routerLink="/{{ guideRoute }}/settings/account" i18n="@@userMenuPersonalInfo">
            Personal info
          </a>
        </li>
        <ng-template [ngIf]="userHavePromoterDashboard">
          <li *ngIf="promoterUrl !== null">
            <a
              class="promoter"
              href="{{ promoterUrl }}"
              target="_blank"
              rel="nofollow noreferrer noopener"
              i18n="@@promoterDashboard">
              Earn affiliate rewards
            </a>
          </li>
          <li *ngIf="promoterUrl === null">
            <a class="promoter" (click)="createPromoter()" i18n="@@promoterDashboard">Earn affiliate rewards</a>
          </li>
        </ng-template>
        <ng-template [ngIf]="canActivateWhatsNew">
          <li class="splitter"></li>
          <li>
            <div class="whats-new-button" #whatsNew i18n="@@whatsNew">What's new</div>
          </li>
        </ng-template>
        <li class="splitter d-block d-sm-none"></li>
      </ng-template>
      <ng-template
        [ngIf]="
          userRole !== UserRoles.ADMIN &&
          !user.hasAlternativeProfile &&
          userRole === UserRoles.CLIENT &&
          !config.hideSignUpAs
        ">
        <li class="alternative-account">
          <a (click)="createAlternativeAccount()" transloco="signUpAsCoach">Sign up as practitioner</a>
        </li>
      </ng-template>
      <li class="alternative-account d-block d-sm-none">
        <ng-template
          [ngIf]="
            userRole !== UserRoles.ADMIN &&
            !user.hasAlternativeProfile &&
            userRole === UserRoles.GUIDE &&
            !config.hideSignUpAs
          ">
          <a (click)="createAlternativeAccount()" i18n="@@signUpAsClient">Sign up as client</a>
        </ng-template>
        <ng-template
          [ngIf]="
            userRole !== UserRoles.ADMIN &&
            user.hasAlternativeProfile &&
            userRole === UserRoles.GUIDE &&
            !config.hideSignInAs
          ">
          <a (click)="signinAlternativeAccount()" i18n="@@signInAsClient">Sign in as client</a>
        </ng-template>
        <ng-template
          [ngIf]="
            userRole !== UserRoles.ADMIN &&
            user.hasAlternativeProfile &&
            userRole === UserRoles.CLIENT &&
            !config.hideSignInAs
          ">
          <a (click)="signinAlternativeAccount()" transloco="signInAsCoach">Sign in as practitioner</a>
        </ng-template>
      </li>
      <ng-template [ngIf]="userRole === UserRoles.ADMIN">
        <li routerLinkActive="active">
          <a class="profile-info" routerLink="/dashboard" i18n="@@adminDashboard">Admin Dashboard</a>
        </li>
      </ng-template>
      <li class="splitter" *ngIf="fullMenu"></li>
      <li><a class="sign-out" (click)="signOut()" i18n="@@signOut">Sign out</a></li>
    </ul>
  </div>
</div>

<div class="login-block" *ngIf="!authService.isAuthorized && fullMenu">
  <app-button class="btn-sign-up d-none d-sm-inline-block sign-up" className="btn-primary" (click)="openSignIn()">
    <ng-container i18n="@@signIn">Sign in</ng-container>
  </app-button>
</div>
