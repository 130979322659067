import { filter, takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ProgramSessionTemplateInterface } from '@app/modules/program/types';
import {
  SessionTemplateDrawerService,
  SessionTemplateProgramData
} from '@app/modules/session-forms/drawers/session-template-drawer/services/session-template-drawer.service';
import { IActivationOptions } from '@app/screens/guide/guide-programs/components/module-activation-select/module-activation-select.component';
import { ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { assertNever } from '@app/shared/utils/assertions';
import { PuiDestroyService } from '@awarenow/profi-ui-core';

import { IProgramServiceDetails, ProgramAccessRoles, SessionModule } from '../../types';

@Component({
  selector: 'app-session-module',
  templateUrl: './session-module.component.html',
  styleUrls: [
    '../../../../../modules/guide-service-editor/common-styles/forms.scss',
    './session-module.component.scss'
  ],
  providers: [PuiDestroyService, SessionTemplateDrawerService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionModuleComponent {
  @Input() moduleForm: UntypedFormGroup | undefined;

  get serviceDetails(): IProgramServiceDetails | undefined {
    return this.moduleForm?.value?.service;
  }

  get sessionTemplateDraft(): ProgramSessionTemplateInterface | undefined {
    return this.moduleForm?.value?.sessionTemplateDraft;
  }

  get showRecurringAlert(): boolean {
    return !!this.moduleForm?.value?.showRecurringAlert;
  }

  readonly ModuleTypes = ModuleTypes;

  @Input()
  disableServiceChange = false;

  @Input()
  programAccessRole!: ProgramAccessRoles;

  @Input()
  moduleActivations: IActivationOptions[] | undefined;

  constructor(
    private readonly sessionTemplateDrawerService: SessionTemplateDrawerService,
    private readonly destroy$: PuiDestroyService
  ) {}

  editSessionTemplate(): void {
    if (this.disableServiceChange) {
      return;
    }

    this.updateSessionModule();
  }

  private updateSessionModule(): void {
    if (!this.moduleForm) {
      assertNever(this.moduleForm);
    }

    const module = this.moduleForm.value as SessionModule;

    this.sessionTemplateDrawerService
      .open$<SessionTemplateProgramData>({
        parentType: GuideServiceTypes.PROGRAM,
        sessionInfo: {
          sessionTemplateId: module.serviceId,
          sessionTemplateDraft: module.sessionTemplateDraft,
          accessRole: this.programAccessRole
        }
      })
      .afterClosed$.pipe(
        filter<SessionTemplateProgramData>(data => !!data?.sessionInfo),
        takeUntil(this.destroy$)
      )
      .subscribe(({ sessionInfo, showRecurringAlert }) => {
        module.sessionTemplateDraft = sessionInfo?.sessionTemplateDraft;
        module.showRecurringAlert = showRecurringAlert;

        this.moduleForm!.setValue(module);
        this.moduleForm!.markAsDirty();
      });
  }
}
