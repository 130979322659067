import { Inject, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { throwError, Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticClientTypes, AnalyticSourceTypes, InternalEvents } from '@app/core/analytics/types';
import { GuideClientsService } from '@app/core/users/guide-clients.service';
import { IContact } from '@app/core/users/types';
import { AddContactModalComponent } from '@app/modules/guide-client/components';
import { GUIDE_CONTACTS_STORE, IGuideContactsStore } from '../types';

@Injectable()
export class GuideContactsService {
  private clientsNumberLeft = Infinity;

  constructor(
    private _modal: NgbModal,
    private _analyticsService: AnalyticsService,
    private _notifications: NotificationsService,
    private _guideClients: GuideClientsService,
    @Inject(GUIDE_CONTACTS_STORE) private _store: IGuideContactsStore
  ) {
    this._guideClients.clientsNumberLeft.subscribe(number => {
      this.clientsNumberLeft = number;
    });
  }

  async addContact(): Promise<void> {
    try {
      const { result, componentInstance } = this._modal.open(AddContactModalComponent, {
        windowClass: 'add-contact-modal'
      });
      componentInstance.clientsNumberLeft = this.clientsNumberLeft;

      const newContactsDetails = await result;
      this._store
        .addContacts$({ contacts: newContactsDetails, sendInvitation: false })
        .pipe(
          catchError(error => {
            this._notifications.error(`Contacts not added.`);
            return throwError(error);
          }),
          tap(() =>
            this._analyticsService.event(InternalEvents.CLIENT_INVITE, {
              clientType: AnalyticClientTypes.EXTERNAL,
              numberOfClients: 1,
              source: AnalyticSourceTypes.CLIENTS
            })
          )
        )
        .subscribe();
      // eslint-disable-next-line id-length
    } catch (e) {}
  }

  updateContact$(id: number, values: Partial<IContact>): Observable<void> {
    return this._store.updateContact$(id, values).pipe(
      catchError(error => {
        this._notifications.error(`Contact not updated`);
        return throwError(error);
      })
    );
  }
}
