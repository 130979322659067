import { Injectable } from '@angular/core';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CHAT_FILES_UPLOADER_ENDPOINT } from '@app/shared/constants/endpoints';
import { HttpClient, HttpHeaders, HttpEvent } from '@angular/common/http';
import { IFileInfoResponse } from '@app/core/files/types';

@Injectable()
export class ChatsFilesUploaderService {
  constructor(private _http: HttpClient, private _notification: NotificationsService) {}

  uploadFile$(file: File): Observable<HttpEvent<IFileInfoResponse>> {
    const formData = new FormData();
    formData.append('chatfile', file, file.name);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this._http
      .post<IFileInfoResponse>(CHAT_FILES_UPLOADER_ENDPOINT, formData, {
        headers,
        reportProgress: true,
        observe: 'events'
      })
      .pipe(
        catchError(error => {
          const message = `Chat file upload error`;
          this._notification.error(message);
          return throwError(error);
        })
      );
  }
}
