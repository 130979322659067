import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { MembershipService } from '@app/core/membership/membership.service';

@Injectable()
export class SubscriptionPageGuard {
  constructor(private readonly router: Router, private readonly membershipService: MembershipService) {}

  canActivate(): Observable<boolean> {
    return this.membershipService.membershipSettings$.pipe(
      map(settings => {
        if (!settings.enabledForCoaches) {
          this.router.navigate(['/']);
          return false;
        }

        return true;
      }),
      take(1)
    );
  }
}
