import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { FlatListComponent } from '@app/modules/ui-kit/check-list/components/flat-list/flat-list.component';
import { MarketplaceProgramApiService } from '@app/modules/marketplace-filters/services/marketplace-program-api.service';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-marketplace-user-typeahead',
  templateUrl: './marketplace-user-typeahead.component.html',
  styleUrls: ['./marketplace-user-typeahead.component.scss']
})
export class MarketplaceUserTypeaheadComponent {
  guideSuggestInput = this.fb.control('');

  @Output()
  selectItem = new EventEmitter();

  // @ts-expect-error TS2564
  @ViewChild('flatList', { static: true }) flatList: FlatListComponent;

  constructor(private fb: UntypedFormBuilder, private _marketplaceProgramApiService: MarketplaceProgramApiService) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getForm() {
    return this.flatList.getForm();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getFormArray() {
    return this.getForm().controls.items as UntypedFormArray;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getValues() {
    return this.flatList.getValues();
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  updateForm(practitioners) {
    // @ts-expect-error TS7031
    const practitionersIds = practitioners.map(({ id }) => id);
    this.flatList.updateForm(practitioners, practitionersIds);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  resetForm() {
    return this.flatList.resetValues();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter(term => term.trim().length > 2),
      switchMap(term =>
        this._marketplaceProgramApiService.getGuidesShortInfoByName$(
          term,
          // @ts-expect-error TS7031
          this.getForm().controls.items.value.map(({ id }) => '' + id)
        )
      )
    );
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  formatter = (x: { name: string }) => x.name;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onCoachSelected(event: NgbTypeaheadSelectItemEvent) {
    const { id, name } = event.item;
    const controlGroup = this.fb.group({
      id: [id],
      name: [name],
      selected: [true]
    });
    this.getFormArray().push(controlGroup);
    this.selectItem.emit(this.getValues());
    this.guideSuggestInput.reset();
    event.preventDefault();
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
  onChange(selectedIds) {
    // @ts-expect-error TS7031
    const selectedItems = this.getFormArray().value.filter(({ selected }) => selected);
    // @ts-expect-error TS7031
    const selectedItemsIds = selectedItems.map(({ id }) => id);
    this.flatList.updateForm(selectedItems, selectedItemsIds);
    this.selectItem.emit(this.getValues());
  }
}
