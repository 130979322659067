import { ChangeDetectionStrategy, Component, OnInit, forwardRef, inject, DestroyRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PuiDropdownModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { ClientsSelectFieldComponent } from './components/clients-select-field/clients-select-field.component';
import { ClientsSelectOptionComponent } from './components/clients-select-option/clients-select-option.component';
import { AsyncPipe } from '@angular/common';
import { ClientsSelectStore } from '@app/components/clients-select/clients-select.store';
import { ClientView } from '@app/components/clients-select/type';
import { ClientsSelectAllOptionComponent } from '@app/components/clients-select/components/clients-select-all-option/clients-select-all-option.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'clients-select',
  standalone: true,
  templateUrl: 'clients-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ClientsSelectFieldComponent,
    PuiDropdownModule,
    ClientsSelectOptionComponent,
    AsyncPipe,
    ClientsSelectAllOptionComponent,
    PuiTypographyModule
  ],
  providers: [
    ClientsSelectStore,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ClientsSelectComponent),
      multi: true
    }
  ]
})
export class ClientsSelectComponent implements ControlValueAccessor, OnInit {
  protected store = inject(ClientsSelectStore);

  isDisabled: boolean = false;

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};
  private destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.store.selectedClients$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(selectedClients => {
      this.onValueChange(selectedClients);
    });
  }

  writeValue(clients: ClientView[]): void {
    if (!clients) {
      return;
    }

    this.store.selectClients(clients);
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  // Call this method when the value changes
  private onValueChange(selectedClients: ClientView[]) {
    this.onChange(selectedClients);
    this.onTouched();
  }
}
