import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ComponentFactoryResolver,
  Injector,
  ViewContainerRef,
  ViewChild,
  ComponentFactory,
  ComponentRef,
  Inject,
  OnDestroy,
  ChangeDetectorRef,
  AfterViewInit,
  Output,
  EventEmitter
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IChatMessage, ChatUser, isChatUserDetailed, MessageTypes } from '@app/core/chat/types';
import { ButtonComponent } from '@app/shared/components/button/button.component';
import { QuizService } from '@app/core/quizzes/quiz.service';
import { AuthService } from '@app/core/auth/services';
import { UserRoles } from '@app/shared/enums/user-roles';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageComponent implements OnDestroy, AfterViewInit {
  // @ts-expect-error TS2564
  private buttonFactory: ComponentFactory<ButtonComponent>;

  private buttons: ComponentRef<ButtonComponent>[] = [];

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _message: IChatMessage;

  readonly MessageTypes = MessageTypes;

  containText = false;

  // @ts-expect-error TS2564
  form: UntypedFormGroup;

  editMode = false;

  userRole: UserRoles;

  UserRoles = UserRoles;

  @ViewChild('container', { read: ViewContainerRef, static: true })
  // @ts-expect-error TS2564
  container: ViewContainerRef;

  @Input()
  set message(message: IChatMessage) {
    this._message = message;
    this.createQuestion();
  }

  get message(): IChatMessage {
    return this._message;
  }

  @Input()
  // @ts-expect-error TS2564
  sender: ChatUser;

  @Output()
  sendMessage = new EventEmitter<string>();

  get expiredTag(): boolean {
    // @ts-expect-error TS2322
    return (
      isChatUserDetailed(this.sender) &&
      this.sender.bot &&
      this.message.text &&
      this.message.text.includes('"Fill daily journaling"')
    );
  }

  get expiredMessageParts(): string[] {
    return this.message.text.split('"Fill daily journaling"');
  }

  get doesSenderHaveNamedUrl(): boolean {
    return !!this.sender && isChatUserDetailed(this.sender) && !!this.sender.namedUrl;
  }

  isSenderABot(): boolean {
    return !!this.sender && isChatUserDetailed(this.sender) && !!this.sender.bot;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private _injector: Injector,
    private _viewContainer: ViewContainerRef,
    private _resolver: ComponentFactoryResolver,
    private _router: Router,
    private _quizService: QuizService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _formBuilder: UntypedFormBuilder,
    private readonly authService: AuthService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.userRole = this.authService.getUserRoleId();
  }

  ngOnDestroy(): void {
    this.buttons.forEach(i => i.destroy());
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngAfterViewInit() {
    this.buttonFactory = this._resolver.resolveComponentFactory(ButtonComponent);
    this.recursiveParseHtml(this.container.element.nativeElement);
    this.updateView();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  messageClicksHandler(event: any) {
    if (event && event.target) {
      this.updateView();
      const link = this.getAttr(event.target, 'router-link');
      if (link) {
        const scheduleId = this.getAttr(event.target, 'data-schedule-id') || this.getAttr(event.target, 'schedule-id');
        const moduleId = this.getAttr(event.target, 'data-module-id') || this.getAttr(event.target, 'module-id');
        if (scheduleId) {
          this._router.navigate([link], { queryParams: { scheduleId } }).then();
        } else if (moduleId) {
          this._router.navigate([link], { queryParams: { moduleId } }).then();
        } else {
          this._router.navigate([link]).then();
        }
        event.preventDefault();
        event.stopPropagation();
      }
      const bot = this.getAttr(event.target, 'bot-start');
      if (bot) {
        this._quizService.startQuiz(+bot);
        event.preventDefault();
        event.stopPropagation();
      }
      const answer = this.getAttr(event.target, 'data-answer');
      if (answer) {
        this.sendMessage.emit(answer);
        event.preventDefault();
        event.stopPropagation();
      }
      const submit = this.getAttr(event.target, 'data-submit');
      if (submit) {
        const question = this.form.controls.questions.value[0];
        this._quizService
          .saveUserLatestAnswers({
            quizId: this._message.meta && this._message.meta.quizId,
            messageId: this._message.id,
            answers: [
              {
                ...question.answer,
                questionId: this._message.meta && this._message.meta.questionId
              }
            ]
          })
          // eslint-disable-next-line rxjs-angular/prefer-takeuntil
          .subscribe(() => {
            this.editMode = true;
            this.updateView();
          });
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private createQuestion() {
    if (!this._message.type || this._message.type === MessageTypes.MESSAGE || !this._message.meta) {
      return;
    }

    this.form = this._formBuilder.group({
      questions: [
        [
          {
            question: this._message.text,
            id: this._message.meta && this._message.meta.questionId,
            type: this._message.type,
            options: (this._message.meta && this._message.meta.options) || [],
            answer: this._message.answer
          }
        ],
        []
      ]
    });
    this.editMode = !!this.form.controls.questions.value[0].answer;
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.form.valueChanges.subscribe(val => {
      if (
        val &&
        val.questions &&
        val.questions.length &&
        // @ts-expect-error TS7006
        // eslint-disable-next-line id-length
        val.questions.find(q => q.type === MessageTypes.FILE_UPLOAD)
      ) {
        this.updateView();
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private recursiveParseHtml(elementRef: Element) {
    for (const child of Array.from(elementRef.children)) {
      if (child.classList.contains('text')) {
        this.containText = true;
      }
      if (child.tagName.toLowerCase() === 'app-button'.toLowerCase()) {
        // @ts-expect-error TS2345
        const element = this._document.createTextNode(child.textContent);
        const component = this._viewContainer.createComponent(this.buttonFactory, undefined, this._injector, [
          [element]
        ]);
        this.buttons.push(component);
        this.fillButtonProperties(child, component);
        // @ts-expect-error TS2531
        child.parentNode.replaceChild(component.location.nativeElement, child);
      } else {
        this.recursiveParseHtml(child);
        const routerLink = child.getAttribute('data-router-link');
        if (routerLink) {
          this.setAttribute(child, 'router-link', routerLink);
          this.setAttribute(child, 'href', routerLink);
        }
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private setAttribute(element: Element, name: string, attr: string) {
    if (attr) {
      element.setAttribute(name, attr);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private fillButtonProperties(element: Element, component: ComponentRef<ButtonComponent>) {
    // @ts-expect-error TS2322
    component.instance.className = element.getAttribute('className');
    // @ts-expect-error TS2345
    this.setButtonAttribute(component, 'router-link', element.getAttribute('data-router-link'));
    // @ts-expect-error TS2345
    this.setButtonAttribute(component, 'schedule-id', element.getAttribute('data-schedule-id'));
    // @ts-expect-error TS2345
    this.setButtonAttribute(component, 'bot-start', element.getAttribute('data-bot-start'));
    // @ts-expect-error TS2345
    this.setButtonAttribute(component, 'module-id', element.getAttribute('data-module-id'));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private setButtonAttribute(component: ComponentRef<ButtonComponent>, name: string, attr: string) {
    if (attr) {
      component.location.nativeElement.querySelector('button').setAttribute(name, attr);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private getAttr(target: Element, attr: string) {
    let value = target.getAttribute(attr);
    while (!value && target !== this.container.element.nativeElement) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      target = <Element>target.parentNode;
      value = target.getAttribute(attr);
    }
    return value || null;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private updateView() {
    setTimeout(() => {
      this._changeDetectorRef.detectChanges();
    });
  }
}
