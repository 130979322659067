import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { DateTime } from 'luxon';
import { take } from 'rxjs/operators';
import {
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepicker,
  NgbDateStruct,
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import { NgbDateTimeStringAdapter } from '@app/shared/ng-bootstrap/ngb-date-time-string-adapter';
import { CUSTOM_NGB_DATE_PARSER_CONFIG } from '@app/shared/ng-bootstrap/ngb-custom-date-parser-formatter';
import { ngbCustomDateFormatterFactory } from '@app/shared/ng-bootstrap/ngb-custom-date-formatter-factory';
import { IUser, User } from '@app/shared/interfaces/user';
import { CurrencyService } from '@app/core/currency/currency.service';
import { SubscriptionRecurrency, SubscriptionLength } from '@app/shared/enums/subscription';
import resolve from '@platformStyle/utils/resolve';
import { LocaleService } from '@app/core/locale/locale.service';
import { BillingService } from '@app/modules/billing/services';
import { PlatformConfigurationService } from '@app/core/platform-configuration';
import { AbsComponentWithGuideBillingValidation } from '@app/modules/billing/abs';
import { ServicePricingTypes } from '@app/modules/guide-service-editor/types/pricing';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { IProgramAuthor, IProgramOptions } from '../../types';
import { VariantSettings } from '../program-cover-crop-modal/variant-settings';
import { AuthService } from '@app/core/auth/services';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-program-content',
  templateUrl: './program-content.component.html',
  styleUrls: [
    '../../../../../../scss/datepicker/datepicker.scss',
    '../../../../../modules/guide-service-editor/common-styles/forms.scss',
    '../../../../../modules/guide-service-editor/common-styles/radio.scss',
    '../../../../../modules/guide-service-editor/common-styles/layout.scss',
    './program-content.component.scss'
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateTimeStringAdapter },
    { provide: CUSTOM_NGB_DATE_PARSER_CONFIG, useValue: resolve('DATE', 'toFormat') },
    {
      provide: NgbDateParserFormatter,
      useFactory: ngbCustomDateFormatterFactory,
      deps: [CUSTOM_NGB_DATE_PARSER_CONFIG, LocaleService]
    }
  ]
})
export class ProgramContentComponent extends AbsComponentWithGuideBillingValidation implements OnInit {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _contentForm: UntypedFormGroup;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _disableSettings: boolean | null;

  minDate: NgbDateStruct = DateTime.local().toObject() as NgbDateStruct;
  isAdmin = this.authService.isPlatformAdmin();
  readonly SubscriptionRecurrency = SubscriptionRecurrency;

  readonly SubscriptionLength = SubscriptionLength;

  hideCoAuthor;

  @ViewChild('datePicker', { static: false })
  // @ts-expect-error TS2564
  datePicker: NgbDatepicker;

  @Input()
  // @ts-expect-error TS2564
  author: IProgramAuthor;

  @Input()
  set contentForm(contentForm: UntypedFormGroup) {
    this._contentForm = contentForm;
    this.updateFormSettings();

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.setStartDatePickerState());
  }

  @Input()
  set disableSettings(value: boolean) {
    this._disableSettings = value;
    this.updateFormSettings();
  }

  @Input()
  // @ts-expect-error TS2564
  options: IProgramOptions;

  @Input()
  // @ts-expect-error TS7008
  possibleCoauthors;

  @Output()
  addCoauthor = new EventEmitter();

  @Output()
  addFAQuestion = new EventEmitter();

  @Output()
  addKeyPoint = new EventEmitter();

  @Output()
  addLanguage = new EventEmitter();

  @Output()
  removeCoauthor = new EventEmitter<number>();

  @Output()
  removeFAQuestion = new EventEmitter<number>();

  @Output()
  removeKeyPoint = new EventEmitter<number>();

  @Output()
  skipCoauthorPropositions = new EventEmitter();

  @Output()
  togglePriceValidation = new EventEmitter();

  @Output()
  uploadCovers = new EventEmitter<{ coverImage: File; coverImageThumb: File }>();

  @Output()
  removeCover = new EventEmitter<null>();

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get contentForm(): UntypedFormGroup {
    return this._contentForm;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get disableSettings(): boolean {
    // @ts-expect-error TS2322
    return this._disableSettings;
  }

  get formCoauthors(): UntypedFormArray {
    return this.contentForm.get('coauthors') as UntypedFormArray;
  }

  get formFAQuestions(): UntypedFormArray {
    return this.contentForm.get('faq') as UntypedFormArray;
  }

  get formKeyPoints(): UntypedFormArray {
    return this.contentForm.get('keyPoints') as UntypedFormArray;
  }

  get formTestimonials(): UntypedFormArray {
    return this.contentForm.get('testimonials') as UntypedFormArray;
  }

  get isFormDisabled(): boolean {
    return !this.author.permissions.canEditSettings;
  }

  get shouldDisplayTestimonials(): boolean {
    return this.author.permissions.canEditSettings || this.formTestimonials.controls.length > 0;
  }

  get shouldDisplayFAQ(): boolean {
    return this.author.permissions.canEditSettings || this.formFAQuestions.controls.length > 0;
  }

  coverVariantsSettings: VariantSettings[] = [
    new VariantSettings({ aspectRatio: 1200 / 440, resizeToWidth: 1200 }),
    new VariantSettings({ aspectRatio: 29 / 18, resizeToWidth: 375 })
  ];

  constructor(
    readonly currencyService: CurrencyService,
    private readonly _ngZone: NgZone,
    private readonly _runtimeConfigService: RuntimeConfigService,
    readonly modal: NgbModal,
    readonly billing: BillingService,
    readonly _platformConfiguration: PlatformConfigurationService,
    private authService: AuthService
  ) {
    super(modal, billing, _platformConfiguration);
    this.hideCoAuthor = this._runtimeConfigService.get('hideCoAuthorInProgram');
  }

  ngOnInit(): void {
    if (!this.author.permissions.canEditSettings) {
      this.contentForm.disable();
    }
  }

  selectProposedCoauthor(index: number, coauthor: IUser): void {
    const coauthorUser = new User(coauthor.id, coauthor.firstName, coauthor.lastName);
    this.formCoauthors.at(index).setValue(
      {
        id: coauthorUser.id,
        name: coauthorUser.name,
        firstName: coauthorUser.firstName,
        lastName: coauthorUser.lastName
      },
      { emitEvent: false }
    );
    this.skipCoauthorPropositions.emit();
  }

  findCoauthors(coauthor: AbstractControl, typed: string): void {
    const nameControl: AbstractControl = (coauthor as UntypedFormGroup).controls.name;
    nameControl.setValue(typed);
  }

  trackPricingChange(value: ServicePricingTypes): void {
    const paymentTypeControl: AbstractControl = this.getPaymentTypeControl();

    this.validatePaidService(value, paymentTypeControl);
  }

  removeImage(): void {
    this.uploadCovers.emit({
      // @ts-expect-error TS2322
      coverImage: null,
      // @ts-expect-error TS2322
      coverImageThumb: null
    });
  }

  private setStartDatePickerState(): void {
    // @ts-expect-error TS2531
    const startDate = this._contentForm.get('startDate').value;

    if (this.datePicker && startDate && DateTime.fromISO(startDate).toSeconds() < DateTime.local().toSeconds()) {
      this.minDate = DateTime.fromISO(startDate).toObject() as NgbDateStruct;
      const min = DateTime.local().startOf('day').toSeconds();
      // eslint-disable-next-line id-length
      this.datePicker.markDisabled = (d: NgbDate) => min > DateTime.fromObject(d).startOf('day').toSeconds();
    } else {
      this.minDate = DateTime.local().toObject() as NgbDateStruct;
    }
  }

  private updateFormSettings(): void {
    if (this._disableSettings !== null && this._contentForm !== null && !this.isFormDisabled) {
      this.togglePriceValidation.emit(!this._disableSettings);
    }
  }

  private getPaymentTypeControl(): AbstractControl {
    // @ts-expect-error TS2322
    return this.getPriceSettings().get('paymentType');
  }

  private getPriceSettings(): AbstractControl {
    // @ts-expect-error TS2322
    return this.contentForm.get('pricingFormGroup');
  }
}
