import { DateTime } from 'luxon';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { LocalStorageService } from '@app/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { IntercomUserProperties } from '@app/core/analytics/types';
import { AuthService } from '@app/core/auth/services/auth.service';
import { IntercomService } from '@app/core/intercom/intercom.service';
import { LocaleService } from '@app/core/locale/locale.service';
import { IUserMembership } from '@app/core/membership/types';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { WindowService } from '@app/core/window/window.service';
import { AlertsService } from '@app/modules/alerts/services/alerts.service';
import { AlertKey } from '@app/modules/alerts/types/alert';
import { AbsComponentWithBranding } from '@app/modules/ui-kit/kit-branding/abs';
import { KitBrandingService } from '@app/modules/ui-kit/kit-branding/services';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { GuideBalanceService } from '@app/screens/guide/services/guide-balance.service';
import { PaymentGateways } from '@app/shared/enums/payment-gateways';
import { resolveRedirectUrl } from '@app/shared/utils/url';
import { ThirdPartyToolsService } from '@app/third-party-tools.service';
import { PuiDestroyService, PuiDialogService } from '@awarenow/profi-ui-core';
import { GlobalConfig } from '@cnf/types';
import { ILocale } from '@env/locale.interface';
import { MetaTagService } from '@libs/services/meta-tag/meta-tag.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BrandingService } from './core/branding/branding.service';
import { GLOBAL_WINDOW } from './core/browser-window/window-provider';
import { ChatsService } from './core/chat/chats.service';
import { CookieCollectionService } from './core/cookie-collection/cookie-collection.service';
import { LogService } from './core/log/log.service';
import { MembershipService } from './core/membership/membership.service';
import { PreloaderService } from './core/preloader/preloader.service';
import { SignatureService } from './core/signature/signature.service';
import { SocketService } from './core/socket/socket.service';
import { StripeJsService } from './core/stripe/stripe-js.service';
import { SystemTimeService } from './core/system-time/system-time.service';
import { UserWalletNotificationsService } from './core/user-wallet/user-wallet-notifications.service';
import { ClientGuidesService } from './core/users/client-guides.service';
import { GuideClientsService } from './core/users/guide-clients.service';
import { BodyClasses } from './core/window';
import { WrongSystemTimeModalComponent } from './shared/components/wrong-system-time-modal/wrong-system-time-modal.component';
import { UserRoles } from './shared/enums/user-roles';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [PuiDestroyService, MetaTagService]
})
export class AppComponent extends AbsComponentWithBranding implements OnInit, AfterViewInit {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _combineIsTeamAndMembershipSettingsSubscription$: Subscription | null;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _membershipSubscription$: Subscription | null;

  isRouting = false;

  isAuth = false;

  isBrowser: boolean;

  cookieCollectionShow = false;

  isSession = false;

  @HostListener('window:load')
  @HostListener('window:resize')
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onResize() {
    // @ts-expect-error TS2531
    this.document
      .querySelector<HTMLElement>(':root')
      .style.setProperty('--vh', `${this.browserWindow.innerHeight / 100}px`);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  locale: ILocale;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private readonly paymentGateway;

  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/no-explicit-any
  private faviconEl: any;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    kitBrandingService: KitBrandingService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(GLOBAL_WINDOW) private browserWindow: Window,
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID) private platformId,
    private _alerts: AlertsService,
    private analyticsService: AnalyticsService,
    private brandingService: BrandingService,
    private chatService: ChatsService,
    private clientGuidesService: ClientGuidesService,
    private guideClientsService: GuideClientsService,
    private localeService: LocaleService,
    private location: Location,
    private logService: LogService,
    private membershipService: MembershipService,
    private metaTagService: MetaTagService,
    private modal: NgbModal,
    private readonly _balanceService: GuideBalanceService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _intercomService: IntercomService,
    private readonly _thirdPartyToolsService: ThirdPartyToolsService,
    private readonly _windowService: WindowService,
    private router: Router,
    private signatureService: SignatureService,
    private stripeJsService: StripeJsService,
    private systemTime: SystemTimeService,
    private title: Title,
    private userWalletNotificationsService: UserWalletNotificationsService,
    private readonly _workspacesService: WorkspacesService,
    private readonly _runtimeConfigService: RuntimeConfigService,
    public authService: AuthService,
    public cookieCollectionService: CookieCollectionService,
    public preloader: PreloaderService,
    public socketService: SocketService,
    private readonly route: ActivatedRoute,
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>,
    public dialog: PuiDialogService
  ) {
    super(kitBrandingService);
    this.paymentGateway = this._runtimeConfigService.get('paymentGateway');
    this.isBrowser = isPlatformBrowser(platformId);
    this.cookieCollectionShow = !cookieCollectionService.isCookieCollectionAllowed();
    this.locale = this.localeService.getLocale();
    this.checkUserPreferredLanguage();
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      this._thirdPartyToolsService.initThirdPartyTools();

      let modalOpened = false;
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      this.systemTime.wrongSystemTime$().subscribe(() => {
        if (!modalOpened) {
          const { result } = this.modal.open(WrongSystemTimeModalComponent, {
            centered: true,
            beforeDismiss: () => false
          });
          modalOpened = true;
          result.finally(() => (modalOpened = false));
        }
      });

      this.analyticsService.storeUserStatistics();

      this.faviconEl = this.document.getElementById('favicon');
    }

    this.setRtlLanguage();

    this.startRoutingListener();

    const invitationToken = this.getTeamInvitationToken();

    this.authService.tryToSignIn(invitationToken);

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.authService.onAuth().subscribe(user => {
      this.socketService.disconnect();

      if (this._combineIsTeamAndMembershipSettingsSubscription$) {
        this._combineIsTeamAndMembershipSettingsSubscription$.unsubscribe();
        this._combineIsTeamAndMembershipSettingsSubscription$ = null;
      }

      if (this._membershipSubscription$) {
        this._membershipSubscription$.unsubscribe();
        this._membershipSubscription$ = null;
      }

      if (user) {
        this.checkUserPreferredLanguage(user.preferredLanguage);
        this.socketService.init(user.authToken, user.RoleId);
        this.chatService.initialize();
        this._thirdPartyToolsService.configureThirdPartyTools(user);
        this.membershipService.updateMembershipSettings();

        this._windowService.addBodyClasses([
          user.RoleId
            ? {
                [UserRoles.CLIENT]: '_client',
                [UserRoles.GUIDE]: '_guide',
                [UserRoles.ADMIN]: '_admin',
                [UserRoles.SUPER_ADMIN]: '_super-admin'
              }[user.RoleId as UserRoles]
            : '_undefined'
        ]);

        this._combineIsTeamAndMembershipSettingsSubscription$ = combineLatest([
          this.membershipService.membershipSettings$,
          this._workspacesService.isTeam$
        ])
          .pipe(filter(() => user.RoleId === UserRoles.GUIDE))
          // eslint-disable-next-line rxjs/no-nested-subscribe, rxjs-angular/prefer-takeuntil
          .subscribe(([membershipSettings, isTeamWorkspace]) => {
            if (!membershipSettings.enabledForCoaches || this.authService.userHasActiveMembership || isTeamWorkspace) {
              this.guideClientsService.refresh();
            }
          });

        // eslint-disable-next-line rxjs/no-nested-subscribe, rxjs-angular/prefer-takeuntil
        this._membershipSubscription$ = this.membershipService.membershipSettings$.subscribe(membershipSettings => {
          if (user.RoleId === UserRoles.GUIDE) {
            // temporary commented as we do not promote marketplace atm
            // this.guideProfileNotificationsService.init();

            if (!user.confirmed) {
              this.socketService
                .onGuideConfirmation()
                // eslint-disable-next-line rxjs/no-nested-subscribe, rxjs-angular/prefer-takeuntil
                .subscribe(confirmed => this.authService.addToUser({ confirmed }));
            }

            this._workspacesService.getActiveWorkspace();
            this._workspacesService.getMembers();
            this._workspacesService.getWorkspaces();
          }

          if (user.RoleId === UserRoles.CLIENT) {
            this.userWalletNotificationsService.init();
            if (!membershipSettings.enabledForClients || this.authService.userHasActiveMembership) {
              this.clientGuidesService.refresh();
            }
          }
        });

        this.chatService.initialize();
        this._thirdPartyToolsService.configureThirdPartyTools(user);

        if (user.RoleId === UserRoles.GUIDE) {
          this._balanceService.loadBalance();
        }
      }

      if (!user) {
        this.socketService.disconnect();
      }
    });

    this.subscribeToUpdateAlert();

    if (this.paymentGateway === PaymentGateways.STRIPE) {
      this.stripeJsService.initialize();
    }

    this.membershipService.updatePlans();

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.brandingService.globalConfig$.subscribe(config => this.setConfig(config));
    this.brandingService.loadBranding();

    this.signatureService.initialize();

    this.checkIfSession();

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._workspacesService.activeWorkspace$.pipe(filter(workspace => !!workspace)).subscribe(workspace => {
      if (this.faviconEl) {
        if (workspace.branding.icon) {
          this.faviconEl.setAttribute('href', workspace.branding.icon);
        } else {
          this.faviconEl.setAttribute('href', this._runtimeConfigService.get('faviconUrl'));
        }
      }
    });
  }

  ngAfterViewInit() {
    this.onResize();
  }

  private subscribeToUpdateAlert(): void {
    combineLatest([this.authService.onMembershipChanged$(), this._workspacesService.isTeam$])
      .pipe(
        tap(() => this.closeAllAlerts()),
        tap(([membership, isTeam]) => {
          if (membership) {
            this.setMembershipAlert(membership, isTeam);
          }
        }),
        filter(([membership, isTeam]) => isTeam && !membership),
        switchMap(() => this.membershipService.getLastMembership$()),
        filter(membership => !!membership?.isTrial),
        tap(() => this.setTrialExpiredAlert()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private setTrialExpiredAlert(): void {
    this._alerts.template(AlertKey.TRIAL_SUBSCRIPTION_MEMBERSHIP_TEAM_FINISHED, {
      platformName: this._runtimeConfigService.get('platformName')
    });
  }

  private setMembershipAlert(membership: IUserMembership, isTeam: boolean): void {
    this._intercomService.update({ [IntercomUserProperties.PLAN]: membership.name });

    if (membership.isTrial) {
      const days = Math.round(
        // @ts-expect-error TS2345
        DateTime.fromISO(membership.currentPeriodEnd).diff(DateTime.local(), 'days').toObject().days
      );
      const platformName = this._runtimeConfigService.get('platformName');
      const recurrenceTemplateKey = membership.recurrency
        ? AlertKey.TRIAL_SUBSCRIPTION_MEMBERSHIP
        : AlertKey.TRIAL_LTD_MEMBERSHIP;

      if (!isNaN(days)) {
        this._alerts.template(isTeam ? AlertKey.TRIAL_SUBSCRIPTION_MEMBERSHIP_TEAM : recurrenceTemplateKey, {
          days,
          platformName
        });
      }
    }
  }

  private closeAllAlerts(): void {
    this._alerts.closeByTemplate(AlertKey.TRIAL_SUBSCRIPTION_MEMBERSHIP);
    this._alerts.closeByTemplate(AlertKey.TRIAL_SUBSCRIPTION_MEMBERSHIP_TEAM);
    this._alerts.closeByTemplate(AlertKey.TRIAL_SUBSCRIPTION_MEMBERSHIP_TEAM_FINISHED);
    this._alerts.closeByTemplate(AlertKey.TRIAL_LTD_MEMBERSHIP);
  }

  allowCookie(): void {
    this.cookieCollectionService.setCookieCollectionAllowed();
    this.cookieCollectionShow = false;
  }

  startRoutingListener(): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.isRouting = true;
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.isAuth = event.url.substr(0, 5) === '/auth';
        this.isRouting = false;
      }

      this.metaTagService.upsertMetaTags({
        url: `${this.locale.baseUrl}${this.router.url}`
      });
    });
  }

  private checkIfSession(): void {
    if (this.location.path() === '/session') {
      this.isSession = true;
    }
  }

  private getTeamInvitationToken(): string {
    const path = this.location.path();
    if (path.includes('team-invitation')) {
      return path.split('/')[2];
    }
    return '';
  }

  private checkUserPreferredLanguage(userLang = ''): void {
    if (!this._runtimeConfigService.get('enableLanguageSwitch')) {
      return;
    }

    const path = this.location.path();
    let redirectUrl;

    if (!userLang) {
      const preferredLanguage = this._localStorageService.getItem('preferredLanguage');

      if (preferredLanguage && preferredLanguage !== this.locale.language) {
        // @ts-expect-error TS2345
        redirectUrl = resolveRedirectUrl(preferredLanguage, path, this._runtimeConfigService.get('defaultLanguage'));
        this._windowService.redirect(redirectUrl);
      }
    } else if (userLang && userLang !== this.locale.language) {
      this._localStorageService.setItem('preferredLanguage', userLang);
      // @ts-expect-error TS2345
      redirectUrl = resolveRedirectUrl(userLang, path, this._runtimeConfigService.get('defaultLanguage'));
      this._windowService.redirect(redirectUrl);
    }
  }

  private setConfig({
    metaKeywordsMainPage,
    metaTitleMainPage,
    metaDescriptionMainPage,
    metaImageMainPage
  }: GlobalConfig): void {
    this.metaTagService.upsertMetaTags({
      title: metaTitleMainPage,
      keywords: metaKeywordsMainPage,
      description: metaDescriptionMainPage,
      image: metaImageMainPage
    });
    this.setTitle(metaTitleMainPage);
  }

  private setRtlLanguage(): void {
    if (this.locale.language === 'ar') {
      this._windowService.addBodyClasses([BodyClasses.RTL]);
    }
  }

  private setTitle(title: string): void {
    this.title.setTitle(title);
  }
}
