import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiBadge, UiBadgeIcon } from '@app/modules/ui-kit/_base/ui-badge/badge';

@NgModule({
  declarations: [UiBadge, UiBadgeIcon],
  exports: [UiBadge, UiBadgeIcon],
  imports: [CommonModule]
})
export class UiBadgeModule {}
