import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SessionTemplateEditorService } from '@app/screens/guide/guide-sessions-templates/services/session-template-editor.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-session-template-questionnaires',
  templateUrl: './session-template-questionnaires.component.html',
  styleUrls: [
    '../../../../../../scss/guide-services/layout.scss',
    '../../../../../../scss/guide-services/forms.scss',
    '../../../../../../scss/guide-services/radio.scss',
    '../../styles/common.scss',
    './session-template-questionnaires.component.scss'
  ]
})
export class SessionTemplateQuestionnairesComponent {
  // @ts-expect-error TS2564
  form: UntypedFormGroup;

  constructor(public editor: SessionTemplateEditorService) {}

  saveChanges(): void {
    //
  }
}
