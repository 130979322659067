<h4 class="title" transloco="createYourClientAccount" *ngIf="onlyClient">Create your client account</h4>
<h4 class="title" transloco="createYourGuideAccount" *ngIf="onlyGuide">Create your practitioner account</h4>

<div class="auth-form">
  <form [formGroup]="signUpForm" novalidate (ngSubmit)="signUp()" class="pui-form">
    <!--EMAIL-->
    <div class="pui-form__row">
      <div class="pui-form__field">
        <label for="email" i18n="@@email" pui-input-label>Email</label>
        <pui-input-field>
          <input
            pui-input-text
            formControlName="email"
            id="email"
            type="email"
            readonly
            data-qa-id="sign-up-textfield-email" />
        </pui-input-field>
      </div>
    </div>

    <!--PASSWORD-->
    <div class="pui-form__row">
      <div class="pui-form__field">
        <label i18n="@@password" for="password" pui-input-label>Password</label>
        <pui-input-field>
          <input
            appPasswordField
            #passwordField="appPasswordField"
            pui-input-text
            id="password"
            formControlName="password"
            type="password" />

          <app-password-field-icon [passwordField]="passwordField" pui-input-field-right />
        </pui-input-field>

        <app-form-control-error [control]="signUpForm.get('password')" *ngIf="passwordError as error">
          <ng-container [ngSwitch]="error">
            <ng-container *ngSwitchCase="'required'" i18n="@@passwordRequired">Password required.</ng-container>

            <ng-container *ngSwitchCase="'minlength'" i18n="@@passwordTooShort">
              Password must be at least 6 chars long.
            </ng-container>

            <ng-container *ngSwitchCase="'empty'" i18n="@@passwordIsEmpty">
              Password shouldn't contain only whitespaces.
            </ng-container>
          </ng-container>
        </app-form-control-error>
      </div>
    </div>

    <!--FIRST NAME-->
    <div class="pui-form__row">
      <div class="pui-form__field">
        <label for="fName" i18n="@@firstName" pui-input-label>First name</label>
        <pui-input-field>
          <input
            pui-input-text
            formControlName="firstName"
            id="fName"
            (change)="trimValue(signUpForm.controls['firstName'])"
            type="text"
            data-qa-id="sign-up-textfield-firstname" />
        </pui-input-field>
        <app-form-control-error [control]="signUpForm.controls['firstName']" i18n="@@firstNameRequiredOnlyLetters">
          Please, use only letters. First name required
        </app-form-control-error>
      </div>
    </div>

    <!--LAST NAME-->
    <div class="pui-form__row">
      <div class="pui-form__field">
        <label for="lName" i18n="@@signUpLastName" pui-input-label>Last name</label>
        <pui-input-field>
          <input
            pui-input-text
            formControlName="lastName"
            id="lName"
            (change)="trimValue(signUpForm.controls['lastName'])"
            type="text"
            data-qa-id="sign-up-textfield-lastname" />
        </pui-input-field>
        <app-form-control-error [control]="signUpForm.controls['lastName']" i18n="@@lastNameRequiredOnlyLetters">
          Please, use only letters. Last name required
        </app-form-control-error>
      </div>
    </div>

    <ng-container *ngIf="!onlyClient && !onlyGuide">
      <div class="pui-form__row">
        <fieldset class="sign-up-fieldset">
          <legend i18n="@@signUpAsA" class="pui-space_bottom-3">
            <span pui-typography="body-s-bold">Sign up as a</span>
          </legend>

          <!--          -->
          <label
            id="isGuide"
            formControlName="isGuide"
            class="auth-checkbox sign-up-as"
            data-qa-id="sign-up-practitioner-checkbox"
            pui-input-checkbox>
            <ng-container transloco="practitioner">Practitioner</ng-container>
          </label>

          <!--          -->
          <label
            id="isClient"
            formControlName="isClient"
            class="auth-checkbox sign-up-as"
            [disabled]="isClientDisabled"
            pui-input-checkbox>
            <ng-container i18n="@@client">Client</ng-container>
          </label>
        </fieldset>

        <app-form-control-error
          [show]="
            signUpForm.errors &&
            signUpForm.errors.roleError &&
            ((signUpForm.controls['isGuide'].dirty && signUpForm.controls['isGuide'].touched) ||
              (signUpForm.controls['isClient'].dirty && signUpForm.controls['isClient'].touched))
          "
          i18n="@@invalidRole">
          Choose at least one role.
        </app-form-control-error>
      </div>

      <p
        *ngIf="signUpForm.controls['isClient'].value && signUpForm.controls['isGuide'].value"
        class="info-text"
        transloco="bothRolesInfoText">
        Great! We’ll set up your practitioner dashboard first.
      </p>
    </ng-container>

    <div class="pui-form__row" style="flex-direction: column">
      <label
        *ngIf="config.showNewsletter"
        id="agreeEmail"
        formControlName="subscribeNews"
        i18n="@@agreeToReceiveEmails"
        pui-input-checkbox>
        I agree to receive {{ platformName }} email newsletter
      </label>
      <label
        pui-input-checkbox
        id="agreeWithTerms"
        formControlName="agreeWithTerms"
        i18n="@@signUpConfirmTermsAndPrivacyPolicy"
        data-qa-id="sign-up__checkbox-agreements">
        <p pui-typography="body-s">
          I authorize the processing of personal data and agree with
          <a pui-link href="{{ config.urlTerms }}" target="_blank" rel="noopener">Terms&Conditions</a>
          and
          <a pui-link href="{{ config.urlPrivacy }}" target="_blank" rel="noopener">Privacy Policy</a>.
        </p>
      </label>
      <app-form-control-error
        [control]="signUpForm.controls['agreeWithTerms']"
        i18n="@@signUpMustAcceptPolicies"
        class="policies-error">
        You must accept the policies
      </app-form-control-error>
    </div>

    <div class="auth-buttons">
      <button
        [isLoading]="isFetching"
        pui-button
        type="submit"
        i18n="@@createAccount"
        data-qa-id="sign-up__button-submit">
        Create account
      </button>
    </div>

    <div class="pui-space_top-4 text-center">
      <small pui-typography="body-s">
        This site is protected by reCAPTCHA and the Google
        <a target="_blank" rel="noopener" href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a target="_blank" rel="noopener" href="https://policies.google.com/terms">Terms of Service</a> apply.
      </small>
    </div>
  </form>
</div>
