import { NotificationsService } from '@awarenow/profi-ui-core';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, mapTo, takeUntil, tap } from 'rxjs/operators';

import { Injectable, OnDestroy } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { InternalEvents } from '@app/core/analytics/types';
import { SocketService } from '@app/core/socket/socket.service';

import { ExternalCalendarsApiService } from './external-calendars-api.service';
import { ExternalCalendarSources, ICalendarsRequestOptions, IExternalAccount, IExternalCalendars } from './types';

@Injectable()
export class ExternalCalendarsService implements OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _calendars$ = new BehaviorSubject<IExternalCalendars>({});

  private readonly destroy$ = new Subject<void>();

  get calendars$(): Observable<IExternalCalendars> {
    return this._calendars$.asObservable();
  }

  constructor(
    private readonly _analyticsService: AnalyticsService,
    private readonly _api: ExternalCalendarsApiService,
    private readonly _socket: SocketService,
    private readonly _notifications: NotificationsService
  ) {
    this._socket
      .onExternalCalendarsConnected$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(details => {
        const refreshOptions = {
          source: details.source
        };

        this.refresh(refreshOptions);
      });

    this._socket
      .onExternalCalendarsDisconnected$()
      .pipe(takeUntil(this.destroy$))
      // @ts-expect-error TS2345
      .subscribe(details => this.clearCalendarsSource(details.source, details.accountId));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeCalendarSyncMode$(
    calendarId: number,
    source: ExternalCalendarSources,
    newSyncMode: boolean
  ): Observable<boolean> {
    return this._api.changeCalendarSyncMode$(calendarId, source, newSyncMode).pipe(
      catchError(() => {
        const message = `Cannot update calendar sync mode.`;
        this._notifications.error(message);
        return throwError(null);
      }),
      mapTo(true)
    );
  }

  disconnect(source: ExternalCalendarSources, accountId: number): void {
    this.disconnect$(source, accountId)
      .pipe(
        tap(() =>
          this._analyticsService.event(InternalEvents.CALENDAR_DISCONNECT, {
            calendarType: source
          })
        )
      )
      .subscribe();
  }

  disconnect$(source: ExternalCalendarSources, accountId: number): Observable<void> {
    // @ts-expect-error TS2322
    return this._api.disconnect$(source, accountId).pipe(
      catchError(() => {
        const message = `Failed to disconnect account.`;
        this._notifications.error(message);
        return of(null);
      }),
      mapTo(null)
    );
  }

  refresh(options: ICalendarsRequestOptions = {}): void {
    this._api.loadCalendars$(options).subscribe(calendars => this.mergeCalendars(calendars));
  }

  private mergeCalendars(calendars: IExternalCalendars): void {
    const existingCalendars = this._calendars$.getValue();

    const newCalendars = {
      ...existingCalendars,
      ...calendars
    };

    this._calendars$.next(newCalendars);
  }

  private clearCalendarsSource(sourceToClear: ExternalCalendarSources, accountId?: number): void {
    const existingCalendars = this._calendars$.getValue();

    const newCalendars = Object.entries(existingCalendars)
      .map(([source, accounts]) => {
        if (source !== sourceToClear) {
          return [source, accounts] as [string, readonly IExternalAccount[]];
        }

        const newSourceAccounts = accountId
          ? [source, accounts.filter(account => account.id !== accountId) as readonly IExternalAccount[]]
          : [source, [] as readonly IExternalAccount[]];

        return newSourceAccounts as [string, readonly IExternalAccount[]];
      })
      .reduce(
        (calendars, [source, accounts]) => ({
          ...calendars,
          [source]: accounts
        }),
        {} as IExternalCalendars
      );

    this._calendars$.next(newCalendars);
  }
}
