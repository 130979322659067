import { Observable, BehaviorSubject, Subject } from 'rxjs';

import { Alert } from '../../types/alert';

export abstract class AlertsStore {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _counter = 0;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _alerts$: BehaviorSubject<Alert[]> = new BehaviorSubject<Alert[]>([]);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _deletedAlert$: Subject<Alert> = new Subject<Alert>();

  alerts$: Observable<Alert[]> = this._alerts$.asObservable();

  deletedAlert$: Observable<Alert> = this._deletedAlert$.asObservable();

  protected addAlert(alert: Alert): void {
    if (alert) {
      const alerts: Alert[] = this._alerts$.getValue();
      this._alerts$.next(
        alerts.concat({
          id: this._counter,
          ...alert
        })
      );
      this._counter += 1;
    }
  }

  protected deleteAlert(id: number): void {
    const alerts: Alert[] = this._alerts$.getValue();

    const alert = alerts.find(alert => alert.id === id);

    if (!alert) {
      return;
    }

    this._deletedAlert$.next(alert);
    this._alerts$.next([alert]);
  }

  protected deleteAlertsByTemplate(templateKey: string): void {
    const alerts: Alert[] = this._alerts$.getValue();
    this._alerts$.next(alerts.filter(alert => alert.template !== templateKey));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected updateAlertsByTemplate(templateKey: string, updateData: any): void {
    const alerts: Alert[] = this._alerts$.getValue();
    this._alerts$.next(alerts.map(alert => ({ ...alert, ...updateData })));
  }
}
