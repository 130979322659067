import { ClipboardModule } from 'ngx-clipboard';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GuideServiceModule } from '@app/modules/guide-service/guide-service.module';
import { NewServiceButtonModule } from '@app/modules/new-service-button/new-service-button.module';
import { DropdownMenuModule } from '@app/modules/ui-kit/_base/dropdown-menu/dropdown-menu.module';
import { UiIconModule } from '@app/modules/ui-kit/_base/ui-icon';
import { SearchInputModule } from '@app/modules/ui-kit/search-input/search-input.module';
import { GuidePackageService } from '@app/screens/guide/guide-packages/guide-package.service';
import { GuideServicesActionMenuPipe } from '@app/screens/guide/guide-services/components/guide-services-board/guide-services-action-menu.pipe';
import { RemoveServiceBlockersModalComponent } from '@app/screens/guide/guide-services/modals/remove-service-blockers-modal/remove-service-blockers-modal.component';
import { SharedModule } from '@app/shared';
import {
  PuiAlertModule,
  PuiButtonModule,
  PuiDropdownModule,
  PuiIconModule,
  PuiKeyboardWidgetModule,
  PuiList2Module,
  PuiScrollableModule
} from '@awarenow/profi-ui-core';
import { NgbDropdownModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

import { GuideServiceSelectorModule } from '../guide-service-selector/guide-service-selector.module';
import { GuideServiceItemComponent } from './components/guide-service-item/guide-service-item.component';
import { GuideServicesBoardComponent } from './components/guide-services-board/guide-services-board.component';
import { GuideServicesRoutingModule } from './guide-services-routing.module';
import { ProgramSharingModalComponent } from './modals/program-sharing-modal/program-sharing-modal.component';
import { RemoveServiceModalComponent } from './modals/remove-service-modal/remove-service-modal.component';

@NgModule({
  imports: [
    ClipboardModule,
    CommonModule,
    GuideServiceModule,
    GuideServicesRoutingModule,
    RouterModule,
    SharedModule,
    NgbPopoverModule,
    NgbDropdownModule,
    NgScrollbarModule,
    FormsModule,
    SearchInputModule,
    UiIconModule,
    PuiButtonModule,
    PuiIconModule,
    DropdownMenuModule,
    PuiList2Module,
    PuiKeyboardWidgetModule,
    PuiAlertModule,
    GuideServiceSelectorModule,
    PuiDropdownModule,
    PuiScrollableModule,
    NewServiceButtonModule
  ],
  exports: [GuideServiceItemComponent, GuideServicesBoardComponent],
  declarations: [
    GuideServiceItemComponent,
    GuideServicesBoardComponent,
    ProgramSharingModalComponent,
    RemoveServiceModalComponent,
    RemoveServiceBlockersModalComponent,
    GuideServicesActionMenuPipe
  ],
  providers: [GuidePackageService]
})
export class GuideServicesModule {}
