import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { NotificationsService } from '@awarenow/profi-ui-core';
import config from '@app/core/config/config';
import { CustomUrlQueryEncoder } from '@app/shared/utils/custom-url-query-encoder';
import { IServerPublicEventsResponse, IFilterSearchParams } from '../types';

@Injectable({
  providedIn: 'root'
})
export class PublicEventsApiService {
  private readonly PUBLIC_EVENTS_ENDPOINT = `${config.apiPath}/public/events`;

  private readonly PUBLIC_EVENTS_TYPES_ENDPOINT = `${this.PUBLIC_EVENTS_ENDPOINT}/types`;

  private readonly PUBLIC_EVENTS_AVAILABILITY_ENDPOINT = `${this.PUBLIC_EVENTS_ENDPOINT}/available`;

  constructor(private _http: HttpClient, private _notifications: NotificationsService) {}

  getPublicEvents({ appendResults, ...searchParams }: IFilterSearchParams): Observable<IServerPublicEventsResponse> {
    return this._http
      .get<IServerPublicEventsResponse>(this.PUBLIC_EVENTS_ENDPOINT, {
        params: new HttpParams({
          encoder: new CustomUrlQueryEncoder(),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          fromObject: searchParams as any
        })
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this._notifications.error(`Failed to load public events.`);
          return throwError(err);
        }),
        switchMap(events => {
          return of({ ...events, appendResults });
        })
      );
  }

  getPublicEventsAvailability(): Observable<{ available: boolean }> {
    return this._http.get<{ available: boolean }>(this.PUBLIC_EVENTS_AVAILABILITY_ENDPOINT).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    );
  }

  getPublicEventsTypes(): Observable<string[]> {
    return this._http.get<string[]>(this.PUBLIC_EVENTS_TYPES_ENDPOINT).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    );
  }
}
