import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/auth/services/auth.service';
import { BrandingService } from '@app/core/branding/branding.service';
import { CurrencyService } from '@app/core/currency/currency.service';
import { IDiscount } from '@app/shared/interfaces/discount';
import { GlobalConfig } from '@cnf/types';

@Component({
  selector: 'app-session-book-options',
  templateUrl: './session-book-options.component.html',
  styleUrls: ['./session-book-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'session-book-options'
  },
  animations: [
    trigger('hiding', [
      state(
        'show',
        style({
          height: '*',
          opacity: 1
        })
      ),
      state(
        'hide',
        style({
          height: '0px',
          opacity: 0
        })
      ),
      transition(
        'hide => show',
        animate(
          500,
          keyframes([
            style({ opacity: 0, height: 0 }),
            style({ opacity: 0, height: '82px' }),
            style({ opacity: 1, height: '82px' })
          ])
        )
      )
    ])
  ]
})
export class SessionBookOptionsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @Input()
  // @ts-expect-error TS2564
  isSessionFree: boolean;

  @Input()
  // @ts-expect-error TS2564
  freeSessionCount: number;

  @Input()
  // @ts-expect-error TS2564
  isPromoting: boolean;

  @Input()
  // @ts-expect-error TS2564
  freeAllSessions: boolean;

  @Input()
  // @ts-expect-error TS2564
  priceStartsFrom: number;

  @Input()
  // @ts-expect-error TS2564
  discount: IDiscount;

  @Input()
  // @ts-expect-error TS2564
  disablePrice: boolean;

  isAuth = false;
  config = {
    emailHelp: '',
    displayMoneyBackGuarantee: false,
    showPriceStartsFrom: false
  };
  duration: 15 | 30 | 60 | 90 = 60;
  isFreeUser = false;

  currencySign = '';

  get isFree(): boolean {
    return this.isFreeUser || this.freeAllSessions || this.isPromoting;
  }

  get priceLineThrough(): boolean {
    return this.isFree;
  }

  constructor(
    public currency: CurrencyService,
    private _authService: AuthService,
    private _router: Router,
    private _brandingService: BrandingService
  ) {}

  ngOnInit(): void {
    this._brandingService.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => this.setConfig(config));
    this._authService
      .onAuth()
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(user => {
        this.isAuth = Boolean(user);
        this.isFreeUser = user.free;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setConfig({ displayMoneyBackGuarantee, emailHelp, showPriceStartsFrom }: GlobalConfig): void {
    this.config.emailHelp = emailHelp;
    this.config.displayMoneyBackGuarantee = displayMoneyBackGuarantee;
    this.config.showPriceStartsFrom = showPriceStartsFrom;
  }
}
