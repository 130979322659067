import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CHAT_FILES_UPLOADER_ENDPOINT } from '@app/shared/constants/endpoints';
import { map, tap } from 'rxjs/operators';
import { FileWithURL } from '@awarenow/profi-ui-core';
import { combineLatest, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileUploaderService {
  // TODO Write self path
  private uploadUrl = CHAT_FILES_UPLOADER_ENDPOINT;

  constructor(private httpClient: HttpClient) {}

  uploadFiles(files: FileList | File[]) {
    const uploadFiles$ = Array.from(files).map((file: File) => this.uploadFile(file));

    return combineLatest(uploadFiles$);
  }

  uploadFile(file: File): Observable<FileWithURL> {
    const formData = new FormData();

    formData.append('chatfile', file, file.name);

    return this.httpClient.post<{ url: string }>(this.uploadUrl, formData).pipe(
      map(({ url }) => {
        (file as FileWithURL).url = url;

        return file as FileWithURL;
      })
    );
  }

  // foo(files) {
  //   Array.from(files).map(file => {
  //     const formData = new FormData();
  //
  //     formData.append('chatfile', file, file.name);
  //     const headers = new HttpHeaders();
  //     headers.append('Content-Type', 'multipart/form-data');
  //     headers.append('Accept', 'application/json');
  //
  //     .pipe(
  //       map(event => {
  //         if (event.type === HttpEventType.Response) {
  //           const { url } = event.body;
  //
  //         }
  //       })
  //     );
  //   });
  // }
}
