import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { UserRoles } from '@app/shared/enums/user-roles';

import { AuthService } from './services/auth.service';

@Injectable()
export class AdminGuard {
  constructor(private _router: Router, private _auth: AuthService, private _notifications: NotificationsService) {}

  canActivate(): boolean {
    const isAdmin = this._auth.isAuthorized && this._auth.user.RoleId === UserRoles.ADMIN;
    if (!isAdmin) {
      const title = `Only for Admins`;
      const message = `Sign In as a Admin, please`;
      this._notifications.error(title, message);
      this._router.navigate(['/auth/sign-in']);
    }
    return isAdmin;
  }

  canLoad(): boolean {
    return this.canActivate();
  }
}
