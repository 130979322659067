import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { environment } from '@env/environment';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import config from '../config/config';
import { AuthService } from '@app/core/auth/services';
import { PostPopupWindowService } from '../paypal/post-popup-window-service';
import { AnalyticsService } from '../analytics/analytics.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IZoomAccount {
  email: string;
}

@Injectable()
export class ZoomService implements OnDestroy {
  private readonly ZOOM_SHARED_API_ENABLED: boolean;
  private readonly SHARED_API_UUID: string;

  private readonly API_HOST;
  private readonly AUTHENTICATION_ENDPOINT;
  private readonly ZOOM_ACCOUNT_ENDPOINT = `${config.apiPath}/user/guide/alternative-connections/zoom/accounts`;
  private readonly MESSAGE_TYPE = 'zoom_integration_response';
  private readonly WINDOW_TITLE = 'zoom_integration_window';

  private destroy$ = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _account$ = new ReplaySubject<IZoomAccount | null>(1);

  get account$(): Observable<IZoomAccount> {
    // @ts-expect-error TS2322
    return this._account$.asObservable();
  }

  constructor(
    private _auth: AuthService,
    private _popupWindow: PostPopupWindowService,
    private _http: HttpClient,
    private _analyticsService: AnalyticsService,
    private _notifications: NotificationsService,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {
    // @ts-expect-error TS2322
    this.ZOOM_SHARED_API_ENABLED = this._runtimeConfigService.get('useSharedApiForZoom');
    // @ts-expect-error TS2322
    this.SHARED_API_UUID = this._runtimeConfigService.get('sharedApiUuid');
    this.API_HOST = this.ZOOM_SHARED_API_ENABLED
      ? this._runtimeConfigService.get('sharedApiHost')
      : environment.apiHost;
    this.AUTHENTICATION_ENDPOINT = this.ZOOM_SHARED_API_ENABLED
      ? `${this.API_HOST}/auth/zoom`
      : `${config.apiPath}/auth/zoom`;

    if (!this._auth.isPlatformAdmin()) {
      this.loadAccountIfExists$();
    }
  }

  ngOnDestroy(): void {
    this._account$.complete();

    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  authenticate$(): Observable<any> {
    const postData: { platformId?: string } = {};
    if (this.ZOOM_SHARED_API_ENABLED) {
      postData.platformId = this.SHARED_API_UUID;
    }
    return this.runPopupForAuthorisedUser(this.AUTHENTICATION_ENDPOINT, postData).pipe(
      tap(zoomAccount => {
        if (zoomAccount && zoomAccount.zoomEmail) {
          this._account$.next({ email: zoomAccount.zoomEmail });
        }
      })
    );
  }

  disconnect$(): Observable<boolean> {
    return this._http.delete(this.ZOOM_ACCOUNT_ENDPOINT).pipe(
      map(() => true),
      catchError(() => {
        const title = `Failed to disconnect zoom account`;
        this._notifications.error(title);
        return of(false);
      }),
      tap(disconnected => {
        if (disconnected) {
          this._account$.next(null);
        }
      })
    );
  }

  private loadAccountIfExists$(): void {
    this._http
      .get<{ account: IZoomAccount }>(this.ZOOM_ACCOUNT_ENDPOINT)
      .pipe(
        map(({ account }) => account),
        catchError(() => {
          const title = `Failed to load zoom account info`;
          this._notifications.error(title);
          return of(null);
        })
      )
      .subscribe(account => this._account$.next(account));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private runPopupForAuthorisedUser(endpoint: string, postData: any): Observable<any> {
    if (!this._auth.isAuthorized || !this._auth.user.authToken) {
      return throwError('Not authorised.');
    }

    return this.runPopup(endpoint, postData);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private runPopup(endpoint: string, postData: any): Observable<any> {
    const fullPostData = { ...postData, token: this._auth.user.authToken };
    return (
      this._popupWindow
        // @ts-expect-error TS2345
        .openPopup$(this.WINDOW_TITLE, endpoint, fullPostData, this.API_HOST, this.MESSAGE_TYPE)
        .pipe(
          concatMap(message => {
            if (message.error) {
              return throwError(message.error);
            } else {
              return of(message.payload || null);
            }
          })
        )
    );
  }
}
