import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  NgZone,
  OnDestroy
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ReplaySubject, Subject, combineLatest } from 'rxjs';
import { finalize, map, takeUntil, take } from 'rxjs/operators';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ScriptService } from 'ngx-script-loader';
import { DomSanitizer } from '@angular/platform-browser';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { WistiaService } from '../../../services';
import { WistiaProject, WistiaVideo } from '../../../interfaces';

@Component({
  selector: 'app-video-uploader',
  templateUrl: './video-uploader.component.html',
  styleUrls: ['./video-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoUploaderComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly isBrowser: boolean;
  // @ts-expect-error TS2564
  uploadVideoForm: UntypedFormGroup;
  // readonly wistiaCssUrl = 'https://fast.wistia.com/assets/external/uploader.css';
  readonly wistiaJsUrl = 'https://fast.wistia.com/assets/external/api.js';
  wistiaVideoUploaded = false;
  private onDestroy$ = new Subject<void>();
  private afterViewInit$ = new ReplaySubject<void>(1);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private wistiaUploader: any = null;

  isLoading = true;
  wistiaVideos: WistiaVideo[] = [];

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
  private uploadEmbeddable = (file, media, embedCode, oembedResponse) => {
    if (media && media.url) {
      this.zone.run(() => {
        this.wistiaVideoUploaded = true;
        this.uploadVideoForm.controls['videoUrl'].setValue(media.url);
        this.changeDetectorRef.markForCheck();
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    public sanitizer: DomSanitizer,
    private modal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private scriptService: ScriptService,
    private changeDetectorRef: ChangeDetectorRef,
    private zone: NgZone,
    private wistiaService: WistiaService,
    private readonly _runtimeConfigService: RuntimeConfigService,
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID) private platformId,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(GLOBAL_WINDOW) private browserWindow: any
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser && !this.browserWindow._wapiq) {
      this.browserWindow._wapiq = this.browserWindow._wapiq || [];
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      this.scriptService.loadScript(this.wistiaJsUrl).subscribe();
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnInit() {
    this.uploadVideoForm = this.formBuilder.group({
      videoUrl: ['', [Validators.required]]
    });

    combineLatest([this.wistiaService.getWistiaProjectWithVideos$(), this.afterViewInit$])
      .pipe(
        map(([wistiaProject]) => wistiaProject),
        take(1),
        takeUntil(this.onDestroy$),
        finalize(() => {
          this.isLoading = false;
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe((wistiaProject: WistiaProject) => {
        this.initWistiaUploader(wistiaProject.id);
        this.wistiaVideos = wistiaProject.videos;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();

    if (this.wistiaUploader) {
      this.wistiaUploader.unbind('uploadembeddable', this.uploadEmbeddable);
    }
  }

  ngAfterViewInit(): void {
    this.afterViewInit$.next();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  save() {
    if (!this.uploadVideoForm.valid) {
      return;
    }
    const url = this.extractVideoUrl(this.uploadVideoForm.controls['videoUrl'].value);
    this.modal.close(url);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  cancel() {
    this.modal.dismiss();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  selectVideo(video: WistiaVideo) {
    this.zone.run(() => {
      this.uploadVideoForm.controls['videoUrl'].setValue(`https://fast.wistia.net/embed/iframe/${video.id}`);
      this.changeDetectorRef.markForCheck();
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private initWistiaUploader(projectId: string) {
    if (!this.isBrowser) {
      return;
    }

    // @ts-expect-error TS7006
    // eslint-disable-next-line id-length
    this.browserWindow._wapiq.push(W => {
      this.wistiaUploader = new W.Uploader({
        accessToken: this._runtimeConfigService.get('wistiaUploadToken'),
        dropIn: 'wistia_uploader',
        projectId
      });
      this.wistiaUploader.bind('uploadembeddable', this.uploadEmbeddable);
    });
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private extractVideoUrl(url) {
    let match =
      url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/) ||
      url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/);
    if (match) {
      return `${match[1] || 'https'}://www.youtube.com/embed/${match[2]}?showinfo=0`;
    }

    match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/);

    if (match) {
      return `${match[1] || 'https'}://player.vimeo.com/video/${match[2]}/`;
    }

    // tslint:disable-next-line
    match = url.match(
      /^(?:(https?):\/\/)?[^.]+\.(?:wistia\.com|wi\.st|wistia\.net)\/(?:embed\/iframe|medias|embed)\/([a-zA-Z0-9_-]+)/
    );

    if (match) {
      return `https://fast.wistia.net/embed/iframe/${match[2]}`;
    }
    return url;
  }
}
