import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbsComponentWithClientValidation } from '@app/modules/billing/abs';
import { BillingService } from '@app/modules/billing/services';
import { PlatformConfigurationService } from '@app/core/platform-configuration';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IBillingInfo } from '@app/modules/billing/interfaces';

enum PaymentStep {
  Billing = 'billing',
  Payment = 'payment'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPayWithModalOptions {
  disableNewCardSave?: boolean;
  isSubscription?: boolean;
  title?: string;
  disableSavedCards?: boolean;
  isPlatformSubscription?: boolean;
  hideWallet?: boolean;
  amount?: number;
  hidePaymentLink?: boolean;
  disablePayPal?: boolean;
}

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-pay-with-modal',
  templateUrl: './pay-with-modal.component.html',
  styleUrls: ['./pay-with-modal.component.scss']
})
export class PayWithModalComponent extends AbsComponentWithClientValidation implements IPayWithModalOptions {
  @Input() submitButtonText = 'Next';

  @Input() title = '';

  @Input() disableNewCardSave? = false;

  @Input() disableSavedCards? = false;

  @Input() isSubscription? = false;

  @Input() isPlatformSubscription? = false;

  @Input() hideWallet?: boolean;

  @Input() hidePaymentLink?: boolean;

  @Input() amount?: number;

  @Input() disablePayPal?: boolean;

  // @ts-expect-error TS2564
  @Input() paymentTypeSelected: () => void;

  // @ts-expect-error TS2564
  billingForm: UntypedFormGroup;

  step$: Observable<PaymentStep> = this.shouldDisplay$.pipe(
    map((shouldDisplay: boolean) => (shouldDisplay ? PaymentStep.Billing : PaymentStep.Payment))
  );

  steps: typeof PaymentStep = PaymentStep;

  constructor(
    public modal: NgbActiveModal,
    private _cdr: ChangeDetectorRef,
    _billing: BillingService,
    _platformConfiguration: PlatformConfigurationService
  ) {
    super(_billing, _platformConfiguration);
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._billing.getBillingForm$().subscribe(form => {
      this.billingForm = form;
      this._cdr.detectChanges();
    });
  }

  title$(): Observable<string> {
    return this.step$.pipe(
      map((step: PaymentStep) => {
        switch (step) {
          case PaymentStep.Billing:
            return `Please enter your billing information to complete your booking`;
          default:
            return this.title;
        }
      })
    );
  }

  isPayment$(): Observable<boolean> {
    return this.step$.pipe(map((step: PaymentStep) => step === this.steps.Payment));
  }

  isBilling$(): Observable<boolean> {
    return this.step$.pipe(map((step: PaymentStep) => step === this.steps.Billing));
  }

  handleBillingSubmit(billingData: IBillingInfo): void {
    this._billing.updateBillingForm(billingData);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  handlePaymentSelected() {
    this.paymentTypeSelected();
  }
}
