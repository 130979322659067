import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AuthService } from '@app/core/auth/services/auth.service';
import { ContentFormManager } from './content-form-manager';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { GuideProgramOptionsService } from '../../services/guide-program-options.service';
import { IProgramContent, IProgramSettings, ProgramContent, ProgramModule, ProgramSettings } from '../../types';
import { InternalEvents } from '@app/core/analytics/types';
import { ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { ModulesFormManager } from './modules-form-manager';
import { ProgramCoauthorsService } from '../../services/program-coauthors.service';
import { PuiDialogService, PuiDrawerService } from '@awarenow/profi-ui-core';
import { QuestionnairesFormManager } from './questionnaires-form-manager';
import { QuizQuestionType } from '@app/core/quizzes/types';
import { SettingsFormManager } from './settings-form-manager';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { ProgramSessionTemplateInterface } from '@app/modules/program/types';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgramFormBaseComponent implements OnDestroy {
  isAdmin = this.auth.isPlatformAdmin();

  private contentFormManager: ContentFormManager;
  private modulesFormManager: ModulesFormManager;
  private questionnairesFormManager: QuestionnairesFormManager;
  private savedModules: ProgramModule[] | undefined;
  private savedQuestionnaires: unknown;
  private savedSettings: ProgramSettings | undefined;
  private settingsFormManager: SettingsFormManager;

  get contentForm(): UntypedFormGroup {
    return this.contentFormManager.contentForm;
  }

  get modulesForm(): UntypedFormGroup {
    return this.modulesFormManager.modulesForm;
  }

  get settingsForm(): UntypedFormGroup {
    return this.settingsFormManager.settingsForm;
  }

  get questionnairesForm(): UntypedFormArray {
    return this.questionnairesFormManager.questionnairesForm;
  }

  savedContent: ProgramContent | undefined;

  constructor(
    readonly programOptions: GuideProgramOptionsService,
    readonly programCoauthors: ProgramCoauthorsService,
    protected readonly formBuilder: UntypedFormBuilder,
    protected readonly analyticsService: AnalyticsService,
    protected readonly auth: AuthService,
    protected readonly workspaceService: WorkspacesService,
    protected readonly drawer: PuiDrawerService,
    protected readonly dialog: PuiDialogService
  ) {
    this.contentFormManager = new ContentFormManager(programOptions, programCoauthors, formBuilder, auth);
    this.modulesFormManager = new ModulesFormManager(programOptions, formBuilder, workspaceService, drawer, dialog);
    this.settingsFormManager = new SettingsFormManager(formBuilder, auth);
    this.questionnairesFormManager = new QuestionnairesFormManager(formBuilder);
  }

  init(): void {
    this.setContentForm(this.savedContent);
    this.setModulesForm(this.savedModules);
    this.setQuestionnairesForm(this.savedQuestionnaires);
    this.setSettingsForm(this.savedSettings);
  }

  ngOnDestroy(): void {
    this.modulesFormManager.destroy();
    this.questionnairesFormManager.destroy();
  }

  addCoauthor(): void {
    this.contentFormManager.addCoauthor();
  }

  addCoverImages(urls: { coverImage: string | null; coverImageThumb: string | null }): void {
    this.contentFormManager.addCoverImages(urls);
  }

  addFAQuestion(): void {
    this.contentFormManager.addFAQuestion();
  }

  addKeyPoint(): void {
    this.contentFormManager.addKeyPoint();
  }

  addLanguage(): void {
    this.contentFormManager.addLanguage();
  }

  addModule({
    type: moduleType,
    data,
    showRecurringAlert
  }: {
    type: ModuleTypes;
    data?: ProgramSessionTemplateInterface;
    showRecurringAlert?: boolean;
  }): void {
    this.analyticsService.event(InternalEvents.PROGRAM_MODULE_ADDED, {});
    this.modulesFormManager.addModule(moduleType, data, showRecurringAlert);
  }

  addQuestion(event: { moduleId: number; type: QuizQuestionType }): void {
    this.modulesFormManager.addQuestion(event);
  }

  addQuestionOption(event: { moduleId: number; questionIndex: number }): void {
    this.modulesFormManager.addQuestionOption(event);
  }

  cloneModule(moduleIndex: number): void {
    this.modulesFormManager.cloneModule(moduleIndex);
  }

  contentFormToProgramContent(): IProgramContent {
    return this.contentFormManager.toProgramContent();
  }

  modulesFormToProgramModules(modules?: unknown): ProgramModule[] {
    return this.modulesFormManager.toProgramModules(modules);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  questionnairesFormToProgramSurveys(): any {
    return this.questionnairesFormManager.toQuestionnaires();
  }

  removeCoauthor(index: number): void {
    this.contentFormManager.removeCoauthor(index);
  }

  removeFAQuestion(index: number): void {
    this.contentFormManager.removeFAQuestion(index);
  }

  removeKeyPoint(index: number): void {
    this.contentFormManager.removeKeyPoint(index);
    this.contentForm.markAsDirty();
  }

  removeModule(moduleIndex: number): void {
    this.modulesFormManager.removeModule(moduleIndex);
  }

  removeQuestionAtIndex(event: { moduleId: number; questionIndex: number }): void {
    this.modulesFormManager.removeQuestionAtIndex(event);
  }

  removeQuestionOptionAtIndex(event: { moduleId: number; questionIndex: number; optionIndex: number }): void {
    this.modulesFormManager.removeQuestionOptionAtIndex(event);
  }

  reorderModules(event: { previousIndex: number; currentIndex: number }): void {
    this.modulesFormManager.reorderModules(event);
  }

  setContentForm(content: ProgramContent | undefined): void {
    if (!content) {
      return;
    }

    this.savedContent = content;
    this.contentFormManager.setContentForm(content);
    this.contentFormManager.contentForm.markAsPristine();
  }

  setModulesForm(modules: ProgramModule[] | undefined): void {
    if (!modules) {
      return;
    }

    this.savedModules = modules;
    this.modulesFormManager.setModulesForm(modules);
    this.modulesFormManager.modulesForm.markAsPristine();
  }

  setQuestionnairesForm(questionnaires: unknown): void {
    this.savedQuestionnaires = questionnaires;

    this.questionnairesFormManager.setQuestionnairesForm(questionnaires);
    this.questionnairesFormManager.questionnairesForm.markAsPristine();
  }

  setSettingsForm(settings: ProgramSettings | undefined): void {
    if (!settings) {
      return;
    }

    this.savedSettings = settings;
    this.settingsFormManager.setSettingsForm(settings);
    this.settingsFormManager.settingsForm.markAsPristine();
  }

  settingsFormToProgramSettings(): IProgramSettings {
    return this.settingsFormManager.toProgramSettings();
  }

  togglePriceValidation(enablePriceValidation: boolean): void {
    this.contentFormManager.togglePriceValidation(enablePriceValidation);
  }

  updateModuleTitle(event: { moduleId: number; title: string }): void {
    this.modulesFormManager.updateModuleTitle(event);
  }

  updateModuleDescription(event: { moduleId: number; description: string | null }): void {
    this.modulesFormManager.updateModuleDescription(event);
  }

  loadQuiz({ moduleId }: { moduleId: number }): void {
    this.modulesFormManager.loadModuleForm(moduleId);
  }
}
