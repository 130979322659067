import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { ChatsService } from '../../../core/chat/chats.service';

@Injectable()
export class ChatAvailableGuard {
  constructor(private _chats: ChatsService, private _notifier: NotificationsService) {}

  canActivate(): Observable<boolean> {
    return this._chats.isAlive$.pipe(
      tap(isChatServiceAlive => {
        if (!isChatServiceAlive) {
          this._notifier.error('Chat service cannot connect to server');
        }
      })
    );
  }
}
