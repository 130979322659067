import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

@Injectable()
export class AreGuidePackagesAccessibleGuard {
  constructor(private readonly _router: Router, private readonly _runtimeConfigService: RuntimeConfigService) {}

  canActivate(): boolean | UrlTree {
    return this._runtimeConfigService.get('guidePackagesEnabled') || this._router.parseUrl('/dashboard');
  }

  canLoad(): boolean {
    if (!this._runtimeConfigService.get('guidePackagesEnabled')) {
      this._router.navigate(['/dashboard']);
    }

    // @ts-expect-error TS2322
    return this._runtimeConfigService.get('guidePackagesEnabled');
  }
}
