import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GuideClientModule } from '@app/modules/guide-client/guide-client.module';
import { SafePipeModule } from '@app/shared/pipes/safe/safe.module';
import { SharedModule } from '@app/shared';
import {
  PuiAlertModule,
  PuiAvatarGroupModule,
  PuiAvatarModule,
  PuiButtonModule,
  PuiLetModule,
  PuiIconModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { AlertsService } from './services/alerts.service';
import { AlertsProviderComponent } from './components/alerts-provider/alerts-provider.component';
import { AlertComponent } from './components/alert/alert.component';
import { SharedModule as CommonSharedModule } from '@libs/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    GuideClientModule,
    PuiLetModule,
    SafePipeModule,
    SharedModule,
    PuiAvatarGroupModule,
    PuiAvatarModule,
    PuiButtonModule,
    CommonSharedModule,
    PuiAlertModule,
    PuiIconModule,
    PuiTypographyModule
  ],
  declarations: [AlertComponent, AlertsProviderComponent],
  providers: [AlertsService],
  exports: [AlertComponent, AlertsProviderComponent]
})
export class AlertsModule {}
