import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UpdateValueAndValidityModule } from '@app/modules/session-forms/directives/update-value-and-validity/update-value-and-validity.module';
import {
  PuiButtonModule,
  PuiGroupModule,
  PuiInputModule,
  PuiSelectModule,
  PuiToggleModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { SharedModule } from '@libs/shared/shared.module';

import { RecurrenceFormComponent } from './recurrence-form.component';
import { MaskitoDirective } from '@maskito/angular';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PuiToggleModule,
    PuiInputModule,
    PuiGroupModule,
    PuiSelectModule,
    PuiTypographyModule,
    PuiButtonModule,
    SharedModule,
    UpdateValueAndValidityModule,
    MaskitoDirective
  ],
  declarations: [RecurrenceFormComponent],
  exports: [RecurrenceFormComponent]
})
export class RecurrenceFormModule {}
