import { ChangeDetectionStrategy, Component, Input, output } from '@angular/core';
import { PuiButtonModule, PuiIconModule, PuiTypographyModule } from '@awarenow/profi-ui-core';

@Component({
  selector: 'pui-chip',
  exportAs: 'puiChip',
  standalone: true,
  styleUrl: 'pui-chip.component.scss',
  imports: [PuiIconModule, PuiButtonModule, PuiTypographyModule],
  templateUrl: 'pui-chip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PuiChipComponent {
  @Input()
  label!: string;

  @Input()
  hasDelete!: boolean;

  delete = output<void>();
}
