import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, take, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '@app/core/auth/services/auth.service';
import { BlogEditorService } from '../../services/blog-editor.service';
import { BlogArticleFormComponent } from '../blog-article-form/blog-article-form.component';
import { UserRoles } from '@app/shared/enums/user-roles';
import { BlogArticleStatuses } from '@app/screens/blog/types';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { InternalEvents } from '@app/core/analytics/types';
import { Observable, Subject } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { HttpEventType, HttpResponse } from '@angular/common/http';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-blog-article-creator',
  templateUrl: './blog-article-creator.component.html',
  styleUrls: ['./blog-article-creator.component.scss'],
  providers: [BlogEditorService]
})
export class BlogArticleCreatorComponent implements OnInit, OnDestroy {
  @ViewChild(BlogArticleFormComponent, { static: false })
  // @ts-expect-error TS2564
  builderRef: BlogArticleFormComponent;
  private destroy$ = new Subject<void>();
  isSaving = false;
  tags = [];

  get author(): { namedUrl: string; name: string } | null {
    return this._auth.isAuthorized
      ? {
          namedUrl: this._auth.user.namedUrl || this._auth.user.id,
          name: `${this._auth.user.firstName} ${this._auth.user.lastName}`
        }
      : null;
  }

  get canCreateBusinessArticles(): boolean {
    return this._auth.isAuthorized && this._auth.user.RoleId === UserRoles.ADMIN;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get uploaderService(): (file: File) => Observable<any> {
    return this._service.uploader;
  }

  constructor(
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _service: BlogEditorService,
    private _analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.loadInitialData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadInitialData(): void {
    this._service
      .getTags$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(tags => (this.tags = tags));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  publish(article: any): void {
    this.isSaving = true;
    this._service
      .createArticle$(article)
      .pipe(
        tap(() => {
          if (article.status === BlogArticleStatuses.DRAFT) {
            this._analyticsService.event(InternalEvents.BLOG_STORY_DRAFT_SAVED, {});
          }
          if (article.status === BlogArticleStatuses.PUBLISHED) {
            this._analyticsService.event(InternalEvents.BLOG_STORY_PUBLISHED, {});
          }
        }),
        takeUntil(this.destroy$),
        finalize(() => (this.isSaving = false))
      )
      .subscribe(id => {
        if (article.isBusiness) {
          this._route.paramMap
            .pipe(take(1))
            // eslint-disable-next-line rxjs/no-nested-subscribe, rxjs-angular/prefer-takeuntil
            .subscribe(params => this._router.navigate(['/business-blog', params.get('guide'), 'posts', id]));
        } else {
          this._router.navigate(['../', id], { relativeTo: this._route });
        }
      });
  }

  uploadCover(image: File): void {
    this._service
      .uploadImage$(image)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        if (response.type === HttpEventType.Response) {
          const { url } = response.body;

          if (!url) {
            return;
          }

          this.builderRef.setCoverImage(url);
        }
      });
  }
}
