import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { CheckboxModule } from '@app/modules/ui-kit/checkbox/checkbox.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { FormModule } from '@app/modules/ui-kit/form/form.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@app/shared/components/button/button.module';
import { UiChipListModule } from '@app/modules/ui-kit/_base/ui-chips-list';
import { UiClientSelectorMultipleModule } from '@app/modules/ui-kit/widgets/ui-client-selector-multiple';
import { ThemeConfiguratorService } from '@app/modules/ui-kit/_theming/services/theme-configurator.service';
import { UiIconModule } from '@app/modules/ui-kit/_base/ui-icon';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { UiCardModule } from '@app/modules/ui-kit/_base/ui-card';
import { ThemingRoutingModule } from '@app/modules/ui-kit/_theming/theming-routing.module';
import { FormlySelectType } from '@app/modules/ui-kit/_theming/components/theme-configurator/types/select.type';
import { MatSelectModule } from '@angular/material/select';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { MatBadgeModule } from '@angular/material/badge';
import { UiBadgeModule } from '@app/modules/ui-kit/_base/ui-badge';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@app/modules/ui-kit/_base/ui-stepper';
import { ConfiguratorFormComponent } from '@app/modules/ui-kit/_theming/components';
import { ThemingComponent } from './pages';
import { FormlyListType, FormlyNgxColorPickerType } from './components';
import { bottomSheetOptions } from './theming.options';

@NgModule({
  declarations: [
    ThemingComponent,
    ConfiguratorFormComponent,
    FormlyListType,
    FormlyNgxColorPickerType,
    FormlySelectType
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatBottomSheetModule,
    UiChipListModule,
    CheckboxModule,
    ColorPickerModule,
    ReactiveFormsModule,
    UiClientSelectorMultipleModule,
    ButtonModule,
    FormlyMaterialModule,
    FormlyModule.forRoot({
      types: [
        { name: 'list', component: FormlyListType },
        { name: 'ui-select', component: FormlySelectType },
        { name: 'ngx-color-picker', component: FormlyNgxColorPickerType }
      ]
    }),
    FormModule,
    UiIconModule,
    UiCardModule,
    ThemingRoutingModule,
    MatSelectModule,
    MatPseudoCheckboxModule,
    FormlySelectModule,
    MatBadgeModule,
    UiBadgeModule,
    MatInputModule,
    MatStepperModule
  ],
  providers: [
    {
      provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS,
      useValue: bottomSheetOptions
    },
    ThemeConfiguratorService
  ]
})
export class ThemingModule {
  constructor(_configurator: ThemeConfiguratorService) {
    _configurator.init();
  }
}
