import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { interval, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { TimelineDate, TimelineDateEvent } from '@app/shared/components/timeline-component/types';
import { LocaleService } from '@app/core/locale/locale.service';
// import { locale } from '@env/locale';
//
// const dateTimeLocale = locale.dateTimeLocale;

interface TimelineDateMod extends TimelineDate {
  mDisabled: boolean;
  today: boolean;
}

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-timeline-component',
  templateUrl: './timeline-component.component.html',
  styleUrls: ['./timeline-component.component.scss']
})
export class TimelineComponentComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  private stopAnimation$ = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _dates: TimelineDateMod[] = [];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _origDates: TimelineDate[] = [];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _dateTimeLocale: string;

  datesScrollLeft = 1000000;
  selectedSlot = -1;

  @ViewChild('datesScroll', { static: false })
  // @ts-expect-error TS2564
  datesScroll: ElementRef;

  @ViewChild('ngSelect', { static: false })
  // @ts-expect-error TS7008
  ngSelect;

  @Input()
  // eslint-disable-next-line id-length
  set dates(d: TimelineDate[]) {
    this._origDates = d;
    // eslint-disable-next-line id-length
    this.selectedSlot = d.reduce((a, i, index) => (i.selected ? index : a), 0);
    // @ts-expect-error TS2322
    this._dates = d.map(i => ({
      ...i,
      disabled: undefined,
      selected: undefined,
      mDisabled: i.disabled,
      today: this.isToday(i.date)
    }));
    if (this.ngSelect) {
      this.ngSelect.select(this.mDates[this.selectedSlot]);
    }
    setTimeout(() => {
      this.ngSelect.select(this.mDates[this.selectedSlot]);
      const scrollTo = this.calculateWidth(this.selectedSlot);
      const width = this.datesScroll.nativeElement.offsetWidth;
      this.datesScroll.nativeElement.scrollTo(
        scrollTo - width / 2 + this.getWidth(this.mDates[this.selectedSlot]) / 2,
        0
      );
    });
  }

  @Input()
  injected = false;

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures, @typescript-eslint/explicit-function-return-type
  get dates() {
    return this._origDates;
  }

  get mDates(): TimelineDateMod[] {
    return this._dates;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get datesWidth() {
    return this.calculateWidth(this._dates.length);
  }

  @Output()
  selectSlot = new EventEmitter<TimelineDateEvent>();

  constructor(private _localeService: LocaleService) {
    this._dateTimeLocale = this._localeService.getLocale().dateTimeLocale;
  }

  ngOnInit(): void {
    if (this.ngSelect && this.selectedSlot >= 0) {
      this.ngSelect.select(this.mDates[this.selectedSlot]);
    }
    if (this.injected) {
      this.datesScrollLeft = 0;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.stopAnimation$.next();
    this.stopAnimation$.complete();
  }

  calculateWidth(to: number): number {
    // eslint-disable-next-line id-length
    return this._dates.slice(0, to).reduce((a, i) => a + this.getWidth(i), 0);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getWidth(item: TimelineDateMod) {
    return item.today ? 120 : 75;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  isToday(date: DateTime) {
    return date.setLocale(this._dateTimeLocale).toRelativeCalendar() === 'today';
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  scrollDatesLeft() {
    this.stopAnimation$.next();
    const width = this.datesScroll.nativeElement.offsetWidth;
    const start = this.datesScroll.nativeElement.scrollLeft;
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.animation$().subscribe(i => {
      this.datesScroll.nativeElement.scrollTo(start - i * width, 0);
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  scrollDatesRight() {
    this.stopAnimation$.next();
    const width = this.datesScroll.nativeElement.offsetWidth;
    const start = this.datesScroll.nativeElement.scrollLeft;
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.animation$().subscribe(i => {
      this.datesScroll.nativeElement.scrollTo(start + i * width, 0);
    });
  }

  private animation$(time: number = 400): Observable<number> {
    // @ts-expect-error TS7006
    // eslint-disable-next-line id-length, @typescript-eslint/explicit-function-return-type
    const c = t => 1 - Math.sin(Math.acos(t));

    // @ts-expect-error TS7006
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    function easeInOutCirc(timeFraction) {
      if (timeFraction < 0.5) {
        return c(2 * timeFraction) / 2;
      } else {
        return (2 - c(2 * (1 - timeFraction))) / 2;
      }
    }
    const stop$ = new Subject<void>();
    let nextStop = false;
    return interval(30).pipe(
      map(i => [i, easeInOutCirc((i * 30) / time)]),
      map(([i, j]) => [i, isNaN(j) ? 1 : j]),
      map(([i, j]) => {
        if (nextStop) {
          stop$.next();
        }
        if (i * 30 >= time) {
          nextStop = true;
          return 1;
        } else {
          return j;
        }
      }),
      // tslint:disable-next-line
      takeUntil(stop$),
      takeUntil(this.stopAnimation$)
    );
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, id-length
  datesScrollListener(e) {
    this.datesScrollLeft = e.target.scrollLeft;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  selectDate(i: number) {
    if (i < 0 || i >= this._dates.length) {
      return;
    }
    const j = this.selectedSlot;
    this.selectedSlot = i;
    this.ngSelect.select(this.mDates[i]);
    this.selectSlot.emit({
      ...this.dates[i],
      rollback: () => {
        this.selectedSlot = j;
        this.ngSelect.select(this.mDates[j]);
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, id-length, @typescript-eslint/no-explicit-any
  getIndex(e: any) {
    if (!e) {
      return this.selectedSlot;
    }
    return this.dates.findIndex(i => i.date.toSeconds() === e.date.toSeconds());
  }
}
