import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';

import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { NgScrollbar } from 'ngx-scrollbar';

import { GuideNote } from '@app/modules/guide-notes/guide-notes.types';
import { INoteCardUpdateContent, INoteEditorContent } from '@app/shared/interfaces/notes';
import { TextEditorNotesComponent } from '@app/modules/text-editor/components';
import { PuiDrawerService } from '@awarenow/profi-ui-core';
import { GuideRelationShareNotesComponent } from '@app/modules/guide-client/components/guide-relation-share-notes/guide-relation-share-notes.component';
import { DRAWER_CONFIG } from '@app/modules/guide-client/services';
import { CLIENT_VIEW_TAB_TOKEN } from '@app/modules/guide-client/types/client-view-tab-token';
import { GuideClientCardTab } from '@app/modules/guide-client/components';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-guide-notes-board',
  templateUrl: './guide-notes-board.component.html',
  styleUrls: ['./guide-notes-board.component.scss']
})
export class GuideNotesBoardComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _scrollbarRef: NgScrollbar;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _reset: symbol;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _focusToTextFieldSubj = new Subject<void>();
  readonly focusToTextField$ = this._focusToTextFieldSubj.asObservable();

  @Input()
  hideSharing = false;

  @Input()
  notes: GuideNote[] = [];

  @Input()
  // @ts-expect-error TS2564
  clientId: number;

  @Input()
  set reset(val: symbol) {
    this.addNoteEditor.reset();
    this._reset = val;
  }

  @Input()
  // @ts-expect-error TS2564
  isLoading: boolean;

  @Input()
  isAssigned = true;

  @Output()
  noteCreate = new EventEmitter<INoteEditorContent>();

  @Output()
  loadMoreNotes = new EventEmitter<void>();

  @Output()
  noteUpdate = new EventEmitter<INoteCardUpdateContent>();

  @Output()
  noteEndEditing = new EventEmitter<INoteCardUpdateContent>();

  @Output()
  noteDelete = new EventEmitter<number>();

  @Output()
  grantViewerAccess = new EventEmitter<number>();

  @Output()
  revokeViewerAccess = new EventEmitter<number>();

  @Output()
  manageAccess = new EventEmitter<number>();

  @Output()
  pinNote = new EventEmitter<number>();

  @Output()
  unpinNote = new EventEmitter<number>();

  @ViewChild('addNoteEditor', { static: true })
  // @ts-expect-error TS2564
  addNoteEditor: TextEditorNotesComponent;

  @ViewChild(NgScrollbar, { static: false })
  set scrollBar(value: NgScrollbar) {
    this._scrollbarRef = value;
  }

  constructor(
    private readonly _ngZone: NgZone,
    private readonly _drawer: PuiDrawerService,
    private elementRef: ElementRef,
    @Inject(CLIENT_VIEW_TAB_TOKEN) private readonly selectedTab: Subject<GuideClientCardTab>
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  scrollToCard(id: number): void {
    if (this._scrollbarRef) {
      this._ngZone.onStable.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => {
        const noteElement = (this.elementRef.nativeElement as HTMLElement).querySelector(`#note-card-${id}`);

        if (noteElement) {
          this._scrollbarRef.scrollToElement(`#note-card-${id}`);
        }
      });
    }
  }

  notesTrackBy(index: number, note: GuideNote): number {
    return note.id;
  }

  createNew(): void {
    this._focusToTextFieldSubj.next();
  }

  // TODO: PR-5035 Remove repeated request when scrolling at the bottom of the page in notes
  onBottomScrolled(): void {
    if (this.notes.length) {
      this.loadMoreNotes.emit();
    }
  }

  openSharingSettings(): void {
    this._drawer.open(
      GuideRelationShareNotesComponent,
      {
        ...DRAWER_CONFIG,
        hideCloseButton: true
      },
      { clientId: this.clientId }
    );
  }

  toDetailsTab(): void {
    this.selectedTab.next(GuideClientCardTab.DETAILS);
  }

  createNote(note: INoteEditorContent) {
    this.noteCreate.emit(note);
  }
}
