import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpEvent } from '@angular/common/http';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { CHAT_FILES_UPLOADER_ENDPOINT } from '@app/shared/constants/endpoints';

@Injectable()
export class TextEditorImageDownloaderService {
  constructor(private _http: HttpClient, private _notification: NotificationsService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get uploadFileWithContext(): (file: File) => Observable<HttpEvent<any>> {
    return this.uploadFile$.bind(this);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadFile$(file: File): Observable<HttpEvent<any>> {
    const formData = new FormData();

    formData.append('chatfile', file, file.name);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this._http
      .post(CHAT_FILES_UPLOADER_ENDPOINT, formData, {
        headers,
        reportProgress: true,
        observe: 'events'
      })
      .pipe(
        catchError(error => {
          this._notification.error(`Image upload error`);
          return throwError(error);
        })
      );
  }
}
