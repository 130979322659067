import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { PUI_DRAWER_DATA, PuiDrawerRef } from '@awarenow/profi-ui-core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FullDateComponent } from '@app/modules/service-scheduling/modules/service-form/components/full-date/full-date.component';
import { UserSelectComponent } from '@app/modules/service-scheduling/components/user-select/user-select.component';
import { Subject } from 'rxjs';
import {
  RescheduleData,
  RescheduleResult
} from '@app/screens/guide/guide-sessions/components/drawers/guide-reschedule-drawer/guide-reschedule-drawer.types';
import { UserTimezoneStore } from '@libs/core/user-timezone.store';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AvailabilityStore } from '@libs/stores/availability/availability.store';

@Component({
  selector: 'app-guide-reschedule-drawer',
  templateUrl: './guide-reschedule-drawer.component.html',
  styleUrls: ['./guide-reschedule-drawer.component.scss'],
  providers: [AvailabilityStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideRescheduleDrawerComponent implements OnDestroy, OnInit {
  private readonly destroy$ = new Subject<void>();
  @ViewChild(FullDateComponent) readonly dateForm!: FullDateComponent;
  @ViewChild(UserSelectComponent) readonly userSelectForm!: UserSelectComponent;

  readonly form = this.fb.group({
    service: this.fb.control({ id: this.data.sessionTemplateId, sessionType: this.data.sessionType }, [
      Validators.required
    ]),
    schedule: this.fb.control({
      date: null,
      time: null,
      timezone: this.userTimezoneStore.timezone,
      extended: false
    }),
    host: this.fb.control({ userId: this.data.hostId }, [Validators.required]),
    note: this.fb.control(''),
    doubleBooking: this.fb.control(false, [Validators.requiredTrue])
  });

  get isUnavailableDateTime(): boolean {
    return this.form?.controls.schedule.value.available === false;
  }

  get invalid(): boolean {
    return this.form?.invalid || this.dateForm?.form?.invalid || this.userSelectForm?.form?.invalid;
  }

  get title(): string {
    return `Reschedule ${this.data.title}?`;
  }

  readonly hasBackButton = this.data.hasBackButton;

  constructor(
    private readonly fb: UntypedFormBuilder,
    readonly userTimezoneStore: UserTimezoneStore,
    @Inject(PUI_DRAWER_DATA) private readonly data: RescheduleData,
    @Optional() private readonly ref?: PuiDrawerRef<RescheduleResult>
  ) {}

  reschedule({ schedule, note, doubleBooking } = this.form.getRawValue()): void {
    if (!this.invalid) {
      this.ref?.close({
        reason: note,
        date: schedule.date,
        timezone: schedule.timezone,
        doubleBooking
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.form.controls.schedule.valueChanges
      .pipe(
        distinctUntilChanged((first, second) => JSON.stringify(first) === JSON.stringify(second)),
        takeUntil(this.destroy$)
      )
      .subscribe(schedule => {
        if (schedule.available) {
          this.form?.get('doubleBooking')?.disable();
        } else {
          this.form?.get('doubleBooking')?.enable();
        }
      });
    this.userTimezoneStore.timezone$
      .pipe(takeUntil(this.destroy$))
      .subscribe(x => this.form.patchValue({ schedule: { timezone: x, extended: false } }));

    this.form.patchValue({
      service: { id: this.data.sessionTemplateId, sessionType: this.data.sessionType },
      schedule: {
        date: null,
        time: null,
        timezone: this.userTimezoneStore.timezone,
        extended: false
      },
      host: { userId: this.data.hostId },
      note: ''
    });
  }

  back(): void {
    this.ref?.close();
  }
}
