<div class="auth-body">
  <h1 data-qa-id="welcome-title" class="title" i18n="@@welcomeTitle">Welcome!</h1>
  <app-alternative-auth-providers
    [remember]="remember"
    [onlyClient]="onlyClient"
    [onlyGuide]="onlyGuide"
    [teamInvitationCode]="teamInvitationCode"
    [showSSOWelcome]="showSSOWelcome"
    (signUp)="signUpWithAlternativeProvider.emit()"
    (afterSignIn)="afterSignIn.emit()"
    (signInCanNot)="signInCanNot.emit()"
    class="alternative-auth-providers"></app-alternative-auth-providers>
  <ng-container *ngIf="!showSSOWelcome; else continueButton">
    <div *ngIf="showAuthMethodsSeparator" class="or" i18n="@@or">or</div>
    <p data-qa-id="welcome-enter-email-address-subtitle" class="subtitle" i18n="@@enterYourEmailText">
      Enter your email address to get started
    </p>
    <div>
      <form [formGroup]="signInForm" (ngSubmit)="checkUserExist()" class="pui-form">
        <div class="pui-form__row">
          <div class="pui-form__field">
            <pui-input-field>
              <input
                pui-input-text
                formControlName="email"
                id="email"
                type="email"
                placeholder="example@mail.com"
                i18n-placeholder="@@emailPlaceholder"
                data-qa-id="welcome-form__textfield-email" />
            </pui-input-field>
          </div>
        </div>

        <div class="pui-form__row">
          <div class="pui-form__field">
            <label formControlName="remember" pui-input-checkbox>Remember me on this computer.</label>
          </div>
        </div>

        <div class="auth-buttons">
          <button pui-button type="submit">Continue</button>
        </div>
      </form>
    </div>
  </ng-container>

  <ng-template #continueButton>
    <div *ngIf="showContinueButton" class="auth-form">
      <div class="auth-buttons">
        <button pui-button type="submit">Continue</button>
      </div>
    </div>
  </ng-template>
</div>
