import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { DateTime } from 'luxon';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { saveAs } from 'file-saver';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { InternalEvents } from '@app/core/analytics/types';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { QuizQuestion, QuizQuestionType, QuizUserSchedule } from '@app/core/quizzes/types';
import { TimelineDate, TimelineDateEvent } from '@app/shared/components/timeline-component/types';
import { User } from '@app/shared/interfaces/user';
import { environment } from '@env/environment';
import { ClientsSelectorModalComponent } from '@app/shared/components/clients-selector/components/clients-selector-modal/clients-selector-modal.component';
import { GuideRelation, GuideRelationTypes } from '@app/core/users/types';
import { GuideMiniProgram } from '../../types/program';
import { IProgramModuleQuizAnswer, IProgramModuleQuizQuestion } from '../../types/program-modules';
import { Quiz } from '../../types/quiz';
import { QuizProgramsService } from '../../services/quiz-programs.service';
import { QuizService } from '../../services/quiz.service';
import { Responses } from '../../types/responses';
import { GuideClientCardService } from '@app/screens/guide/guide-clients/guide-client/modules/guide-client-card/guide-client-card.service';

interface QuizSlot {
  // @ts-expect-error TS7008
  QuizUserSchedule;
  date: DateTime;
  questions: IProgramModuleQuizQuestion[];
  answers?: IProgramModuleQuizAnswer[];
}

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-quiz-stats',
  templateUrl: './quiz-stats.component.html',
  styleUrls: ['./quiz-stats.component.scss']
})
export class QuizStatsComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private filterString = '';

  private guideUsers: GuideRelation[] = [];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _selectedUserId = null;

  guideRoute = environment.guideRoute;

  // @ts-expect-error TS2564
  mResponses: Responses;

  // @ts-expect-error TS2564
  programs: GuideMiniProgram[];

  // @ts-expect-error TS2564
  programsGroups: { name: string; entries: number[] }[];

  questions: { [key: number]: { question: QuizQuestion; index: number } } = {};

  selectedSlot = 0;

  // @ts-expect-error TS2564
  selectedUser: User;

  // @ts-expect-error TS7008
  selectedUserIndex;

  slotsAndAnswers: { [key: number]: QuizSlot } = {};

  slotsByUserId: { [key: number]: QuizUserSchedule[] } = {};

  // @ts-expect-error TS2564
  timelineDates: TimelineDate[];

  @ViewChild('datesScroll', { static: false })
  // @ts-expect-error TS2564
  datesScroll: ElementRef;

  @Input()
  // @ts-expect-error TS2564
  id: number;

  @Input()
  // @ts-expect-error TS2564
  quiz: Quiz;

  @Input()
  set responses(quizData: Responses) {
    this.mResponses = quizData;
    if (quizData) {
      this.questions = {};
      quizData.questions.forEach((i, index) => {
        // if quiz does not have options it should behave like long answer
        if (i.type === QuizQuestionType.QUIZ && !Array.isArray(i.options)) {
          i.type = QuizQuestionType.LONG_ANSWER;
        }

        this.questions[i.id] = { question: i, index };
      });
      this.slotsByUserId = {};
      // @ts-expect-error TS2532
      quizData.timeSlots.forEach(i => {
        if (!this.slotsByUserId[i.userId]) {
          this.slotsByUserId[i.userId] = [];
        }
        this.slotsByUserId[i.userId].push(i);
      });
      this.slotsAndAnswers = this.getSlotsAnsAnswers();
      const index = this._selectedUserId && this.findIndexByUserId(this._selectedUserId);
      if (index) {
        this.selectUser(index, false);
      } else {
        this.selectUser(0, true);
      }
    }
  }

  @Input()
  // @ts-expect-error TS7032
  set selectedUserId(id) {
    this._selectedUserId = id;
    if (!id) {
      return;
    }

    const index = this.findIndexByUserId(id);
    if (index) {
      this.selectUser(index, false);
    }
  }

  @Output()
  loadResponses: EventEmitter<null> = new EventEmitter<null>();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get filteredGroups() {
    return this.programsGroups;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get responses(): Responses {
    return this.mResponses;
  }

  get users(): GuideRelation[] {
    if (!this.responses || !this.responses.timeSlots || !this.responses.timeSlots.length) {
      return [];
    }

    // @ts-expect-error TS2322
    return Array.from(
      new Set(this.responses.clients.map(userId => this.guideUsers.find(i => userId === i.id)).filter(i => !!i))
    );
  }

  get filteredUsers(): GuideRelation[] {
    return this.filterString.length
      ? this.users.filter(user => QuizStatsComponent.checkHasUserNameSubstring(user, this.filterString))
      : this.users;
  }

  get scrollbarHeight(): number {
    const count = this.filteredUsers.length;
    return count < 10 ? count * 48 : 480;
  }

  get user(): GuideRelation | null {
    return this.filteredUsers[this.selectedUserIndex] || null;
  }

  get guideClients(): GuideRelation[] {
    return this.guideUsers.filter(usr => usr.type === GuideRelationTypes.GUIDE_CLIENT);
  }

  private static checkHasUserNameSubstring(user: GuideRelation, substring: string): boolean {
    const sanitizedSubString = substring.toLocaleLowerCase();
    return (
      user.firstName.toLocaleLowerCase().includes(sanitizedSubString) ||
      user.lastName.toLocaleLowerCase().includes(sanitizedSubString) ||
      user.name.toLocaleLowerCase().includes(sanitizedSubString) ||
      // @ts-expect-error TS2532
      // eslint-disable-next-line @typescript-eslint/prefer-includes
      user.contacts?.email?.toLocaleLowerCase()?.indexOf(sanitizedSubString) > -1
    );
  }

  constructor(
    private readonly _quizProgramsService: QuizProgramsService,
    private readonly _router: Router,
    private readonly _modal: NgbModal,
    private readonly _notificationService: NotificationsService,
    private readonly _quizService: QuizService,
    private readonly _analyticsService: AnalyticsService,
    private readonly clientCardService: GuideClientCardService
  ) {
    this._quizProgramsService
      .getAllClientsAndPrograms$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ users, programs }) => {
        this.programsGroups = programs
          .filter(i => i.customers.length)
          .map(i => ({
            name: `All from program "${i.name}"`,
            entries: i.customers.map(j => j.id)
          }));
        this.programs = programs;
        this.guideUsers = users;
        this.selectUser(0, true);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  search(value: string): void {
    if (this.selectedUserIndex !== 'undefined') {
      this.selectedUserIndex = 0;
    }

    this.filterString = value.trim();
  }

  selectUser(i: number, onLoad = false): void {
    if (!onLoad || (onLoad && !this.selectedUserIndex)) {
      this.selectedUserIndex = i;
    }

    const selectedUser = this.filteredUsers[this.selectedUserIndex];

    if (selectedUser === this.selectedUser) {
      return;
    }

    this.selectedUser = selectedUser;

    if (this.selectedUser) {
      if (
        this.slotsByUserId &&
        this.slotsByUserId[this.selectedUser.id] &&
        this.slotsByUserId[this.selectedUser.id].length &&
        this.slotsByUserId[this.selectedUser.id][0]
      ) {
        this.selectedSlot = this.slotsByUserId[this.selectedUser.id][0].id;
      }

      this.timelineDates = (this.slotsByUserId[this.selectedUser.id] || []).map(slot => {
        const now = DateTime.fromISO(slot.date);
        return {
          date: now.endOf('day'),
          disabled: false,
          payload: slot.id
        };
      });

      if (this.timelineDates.length > 0) {
        const timelineDate = this.timelineDates[this.timelineDates.length - 1];
        timelineDate.selected = true;
        this.selectedSlot = timelineDate.payload;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, id-length
  dateSelected(e: TimelineDateEvent) {
    this.selectedSlot = e.payload;
    this.loadResponses.emit();
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private findIndexByUserId(id) {
    const index = this.users.findIndex(user => user.id === id);
    return index !== -1 ? index : null;
  }

  private getSlotsAnsAnswers(): { [key: number]: QuizSlot } {
    // @ts-expect-error TS2532
    return this.responses.timeSlots.reduce((acc, slot) => {
      const answers = this.getAnswersBySlot(slot);
      // @ts-expect-error TS7053
      acc[slot.id] = {
        ...slot,
        date: DateTime.fromISO(slot.date),
        questions: answers
      };
      return acc;
    }, {});
  }

  private getAnswersBySlot(slot: QuizUserSchedule): IProgramModuleQuizQuestion[] {
    return (
      Object.keys(this.questions)
        .map(i => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const question = this.questions[i];
          // @ts-expect-error TS2532
          const answers = slot.answers.filter(ans => ans.questionId === +i);
          const answer = answers.map(ans => ans.optionId);
          const answerText = answers.find(ans => ans.text);
          const options = question.question.options
            ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              question.question.options.filter(option => answer.includes(option.id))
            : [];
          return {
            id: question.question.id,
            question: question.question.question,
            type: question.question.type,
            index: question.index + 1,
            answer: {
              text: answerText ? answerText.text : null,
              options: question.question.type === QuizQuestionType.SIGNATURE ? null : options
            },
            options: question.question.options || null,
            createdAt: 'string'
          };
        })
        // eslint-disable-next-line id-length
        .sort((a, b) => a.index - b.index)
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  export() {
    // @ts-expect-error TS2345
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._quizService.downloadQuiz(this.responses.id, [this.users[this.selectedUserIndex].id]).subscribe(data => {
      const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), data], {
        type: 'text/csv;charset=utf-8'
      });
      saveAs(blob, `${DateTime.local().toISO()}-report.csv`);
    });
  }

  addClients(selectedClients: GuideRelation[]) {
    if (!selectedClients || selectedClients.length === 0) {
      return;
    }

    this._quizService
      .addClients(this.id, selectedClients)
      .pipe(
        tap(() => {
          this._analyticsService.event(InternalEvents.QUIZ_INVITED_CLIENT, {});
        }),
        switchMap(() => this._quizService.getResponses$(this.id))
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(data => {
        const sel = this.selectedUserIndex;

        const { selectedSlot } = this;
        this.responses = data;
        this.selectUser(sel);
        this.selectedSlot = selectedSlot;

        this._router.navigate([this._router.url]).then();
        this._notificationService.success(`Quiz', 'Successfully added`);
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  exportAll() {
    this._quizService
      .downloadQuiz(
        this.id,
        this.guideUsers.map(i => i.id)
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(data => {
        const blob = new Blob([data], { type: 'text/csv' });
        saveAs(blob, `${DateTime.local().toISO()}-report.csv`);
      });
  }

  showClientDetails(clientId: number): void {
    this.clientCardService.openDetails(0, clientId);
  }
}
