import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { UiKitHelpersModule } from '../../_base/_common/helpers';
import { UserInfoModule } from '../../user-info/user-info.module';
import { UiOptionModule } from '../../_base/ui-option';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UiIconModule } from '../../_base/ui-icon';
import { UiClientSelectorMultiple } from './client-selector';
import { UiSelectorMultipleModule } from '../../_base/ui-selector-multiple';
import { FilterByNameOrEmailModule } from '@app/shared/pipes/filter-by-name-or-email/filter-by-name-or-email.module';
import { PuiIconModule } from '@awarenow/profi-ui-core';

@NgModule({
  declarations: [UiClientSelectorMultiple],
  exports: [UiClientSelectorMultiple],
  imports: [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    UiKitHelpersModule,
    UserInfoModule,
    UiSelectorMultipleModule,
    UiOptionModule,
    MatTooltipModule,
    UiIconModule,
    FilterByNameOrEmailModule,
    PuiIconModule
  ]
})
export class UiClientSelectorMultipleModule {}
