import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuillModule } from 'ngx-quill';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SharedModule } from '@app/shared';
import { GuideClientModule } from '@app/modules/guide-client/guide-client.module';
import {
  PuiDialogModule,
  PuiIconModule,
  PuiButtonModule,
  PuiTooltipModule,
  PuiList2Module,
  PuiKeyboardWidgetModule,
  PuiDropdownModule,
  PuiAvatarModule,
  fontSizes,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { ProgramModule } from '@app/modules/program/program.module';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';
import { NotYetRegisteredComponent } from '@app/screens/chat/components/not-yet-registered/not-yet-registered.component';
import { UiCardModule } from '@app/modules/ui-kit/_base/ui-card';
import { UiBadgeModule } from '@app/modules/ui-kit/_base/ui-badge';
import { UiIconModule } from '@app/modules/ui-kit/_base/ui-icon';
import { CurrentPaymentModule } from '@app/modules/current-payment/current-payment.module';
import { ChatRoutingModule } from './chat-routing.module';
import { ActiveChatComponent } from './components/active-chat/active-chat.component';
import { ChatDateSeparatorComponent } from './components/chat-date-separator/chat-date-separator.component';
import { ChatServiceSeparatorComponent } from './components/chat-service-separator/chat-service-separator.component';
import { ChatFileComponent } from './components/chat-file/chat-file.component';
import { ChatMessageComponent } from './components/chat-message/chat-message.component';
import { ChatMessageBoxComponent } from './components/chat-message-box/chat-message-box.component';
import { ChatScrollComponent } from './components/chat-scroll/chat-scroll.component';
import { ChatsBoardComponent } from './components/chats-board/chats-board.component';
import { ChatsListComponent } from './components/chats-list/chats-list.component';
import { DirectChatSummaryComponent } from './components/direct-chat-summary/direct-chat-summary.component';
import { GroupChatIconComponent } from './components/group-chat-icon/group-chat-icon.component';
import { GroupChatSummaryComponent } from './components/group-chat-summary/group-chat-summary.component';
import { InChatClientSessionDetailsComponent } from './components/in-chat-client-session-details/in-chat-client-session-details.component';
import { InChatGuideSessionDetailsComponent } from './components/in-chat-guide-session-details/in-chat-guide-session-details.component';
import { InChatSessionManagerComponent } from './components/in-chat-session-manager/in-chat-session-manager.component';
import { InGroupChatBannerComponent } from './components/in-group-chat-banner/in-group-chat-banner.component';
import { NoActiveChatComponent } from './components/no-active-chat/no-active-chat.component';
import { NoChatMessagesComponent } from './components/no-chat-messages/no-chat-messages.component';
import { UploadChatImageModalComponent } from './components/upload-image-modal/upload-chat-image-modal.component';
import { WaitingRoomChatComponent } from './components/waiting-room-chat/waiting-room-chat.component';
import { ChatNewUserComponent } from './components/chat-new-user/chat-new-user.component';
import { ChatParticipantsModalComponent } from './components/chat-participants-modal/chat-participants-modal.component';

import { FirstLettersPipe } from './pipes/first-letters.pipe';

import { ActiveChatUserResolver } from './resolvers/active-chat-user.resolver';

import { ChatAvailableGuard } from './guards/chat-available.guard';
import { LastActiveChatRedirectionGuard } from './guards/last-active-chat-redirection.guard';
import { NoChatIfIncompleteProfileGuard } from './guards/no-chat-if-incomplete-profile.guard';
import { NoSelfChatGuard } from './guards/no-self-chat.guard';
import { QuillEditorImageModule } from '@app/modules/quill-editor-image/quill-editor-image.module';
import { SharedModule as SharedCommonModule } from '@libs/shared/shared.module';
import { TranslocoRootModule } from '@libs/transloco';

@NgModule({
  imports: [
    ChatRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GuideClientModule,
    NgbModule,
    NgScrollbarModule,
    QuillModule.forRoot({
      customOptions: [
        {
          import: 'attributors/style/size',
          whitelist: fontSizes
        }
      ]
    }),
    SharedModule,
    ProgramModule,
    PlatformStyleModule,
    UiCardModule,
    UiBadgeModule,
    UiIconModule,
    PuiButtonModule,
    PuiDialogModule,
    CurrentPaymentModule,
    PuiIconModule,
    PuiTooltipModule,
    PuiList2Module,
    PuiKeyboardWidgetModule,
    PuiDropdownModule,
    PuiAvatarModule,
    QuillEditorImageModule,
    SharedCommonModule,
    TranslocoRootModule,
    ChatsListComponent,
    PuiTypographyModule
  ],
  declarations: [
    ActiveChatComponent,
    ChatDateSeparatorComponent,
    ChatServiceSeparatorComponent,
    ChatFileComponent,
    ChatMessageComponent,
    ChatMessageBoxComponent,
    ChatScrollComponent,
    ChatsBoardComponent,
    DirectChatSummaryComponent,
    GroupChatIconComponent,
    GroupChatSummaryComponent,
    InChatClientSessionDetailsComponent,
    InChatGuideSessionDetailsComponent,
    InChatSessionManagerComponent,
    InGroupChatBannerComponent,
    NoActiveChatComponent,
    NoChatMessagesComponent,
    UploadChatImageModalComponent,
    WaitingRoomChatComponent,
    ChatNewUserComponent,
    ChatParticipantsModalComponent,
    FirstLettersPipe,
    NotYetRegisteredComponent
  ],
  providers: [
    ActiveChatUserResolver,
    ChatAvailableGuard,
    LastActiveChatRedirectionGuard,
    NoChatIfIncompleteProfileGuard,
    NoSelfChatGuard
  ],
  exports: [WaitingRoomChatComponent, FirstLettersPipe]
})
export class ChatModule {}
