import { Injectable, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { NotificationsService } from '@awarenow/profi-ui-core';
import config from '@app/core/config/config';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { InternalEvents } from '@app/core/analytics/types';
import {
  IEditableProgram,
  IProgramContent,
  IProgramContentPatch,
  IProgramContentPersistenceAttributes,
  IProgramModulePersistenceAttributes,
  IProgramSettingsPatch,
  IProgramStore,
  ProgramModule,
  ProgramQuestionnaire
} from '../types';
import { AuthService } from '@app/core/auth/services';

export const PROGRAMS_STORE = new InjectionToken('ProgramsStore');

// eslint-disable-next-line @typescript-eslint/naming-convention
interface ILoadProgramResponse {
  readonly program: IEditableProgram;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IModulesSaveResponse {
  readonly modules: Record<string, IProgramModulePersistenceAttributes>;
}

@Injectable()
export class GuideProgramServerStoreService implements IProgramStore {
  private readonly ENDPOINT = this._authService.isPlatformAdmin()
    ? `${config.apiPath}/user/admin/programs-templates`
    : `${config.apiPath}/user/guide/programs`;
  constructor(
    private readonly _http: HttpClient,
    private readonly _notifications: NotificationsService,
    private readonly _analyticsService: AnalyticsService,
    private readonly _authService: AuthService
  ) {}

  createContent$(content: IProgramContent): Observable<IProgramContentPersistenceAttributes> {
    return this._http.post<IProgramContentPersistenceAttributes>(`${this.ENDPOINT}`, { content }).pipe(
      tap(data => {
        if (data.isFree) {
          this._analyticsService.event(InternalEvents.FREE_PROGRAM_CREATED, {});
        } else {
          this._analyticsService.event(InternalEvents.PAID_PROGRAM_CREATED, {});
        }
      }),
      tap(() => this._notifications.success(`Program content saved`)),
      catchError(httpError => {
        const errorMessage =
          httpError && httpError.error.errors && httpError.error.errors.error
            ? httpError.error.errors.error
            : 'Cannot save program content';
        this._notifications.error(errorMessage);
        return throwError(httpError);
      })
    );
  }

  createModules$(programId: number, modules: ProgramModule[]): Observable<IModulesSaveResponse> {
    return this._http.post<IModulesSaveResponse>(`${this.ENDPOINT}/${programId}/modules`, { modules }).pipe(
      tap(() => this._notifications.success(`Program modules saved`)),
      catchError(error => {
        this._notifications.error(`Cannot save program modules`);
        return throwError(error);
      })
    );
  }

  getProgram$(programId: number): Observable<IEditableProgram> {
    return this._http.get<ILoadProgramResponse>(`${this.ENDPOINT}/${programId}`).pipe(
      map(({ program }) => program),
      catchError(error => {
        this._notifications.error(`Cannot load program`);
        return throwError(error);
      })
    );
  }

  storeCover$(
    coverImage: File,
    coverImageThumb: File,
    formPrefix = 'program'
  ): Observable<{ coverImage: string | null; coverImageThumb: string | null }> {
    const formData: FormData = new FormData();
    formData.append(`${formPrefix}cover`, coverImage, coverImage.name);
    formData.append(`${formPrefix}coverthumb`, coverImage, coverImageThumb.name);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this._http
      .post<{ coverImage: string | null; coverImageThumb: string | null }>(`${this.ENDPOINT}/upload-cover`, formData, {
        headers
      })
      .pipe(
        catchError(error => {
          this._notifications.error(`Image  failed to upload`);
          return throwError(error);
        })
      );
  }

  removeCover$(programId: string | number): Observable<null> {
    return this._http
      .put<null>(`${this.ENDPOINT}/${programId}`, {
        content: {
          coverImage: null,
          coverImageThumb: null
        }
      })
      .pipe(
        catchError(error => {
          this._notifications.error(`Image not deleted`);
          return throwError(error);
        })
      );
  }

  updateContent$(programId: number, content: IProgramContentPatch): Observable<IProgramContentPersistenceAttributes> {
    return this._http.put<IProgramContentPersistenceAttributes>(`${this.ENDPOINT}/${programId}`, { content }).pipe(
      tap(() => this._notifications.success(`Program content updated`)),
      catchError(httpError => {
        const errorMessage =
          httpError && httpError.error.errors && httpError.error.errors.error
            ? httpError.error.errors.error
            : 'Cannot update program content';
        this._notifications.error(errorMessage);
        return throwError(httpError);
      })
    );
  }

  updateModules$(programId: number, modules: ProgramModule[]): Observable<IModulesSaveResponse> {
    return this._http.put<IModulesSaveResponse>(`${this.ENDPOINT}/${programId}/modules`, { modules }).pipe(
      tap(() => this._notifications.success(`Program modules updated`)),
      catchError(error => {
        this._notifications.error(`Cannot update program modules`);
        return throwError(error);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateSettings$(programId: number, settings: IProgramSettingsPatch): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.put<any>(`${this.ENDPOINT}/${programId}/settings`, { settings }).pipe(
      tap(() => this._notifications.success(`Program settings updated`)),
      catchError(error => {
        this._notifications.error(`Cannot update program settings`);
        return throwError(error);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateSurveys$(programId: number, surveys: ProgramQuestionnaire[]): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.put<any>(`${this.ENDPOINT}/${programId}/surveys`, { surveys }).pipe(
      tap(() => this._notifications.success(`Program surveys updated`)),
      catchError(error => {
        this._notifications.error(`Cannot update program surveys`);
        return throwError(error);
      })
    );
  }

  deactivateProgram$(
    programId: number,
    data: { date: Date | null; message: string }
  ): Observable<{ lastActiveDay: string }> {
    return this._http.put<{ lastActiveDay: string }>(`${this.ENDPOINT}/${programId}/deactivate`, data).pipe(
      tap(() => this._notifications.success(`Program deactivated`)),
      catchError(error => {
        this._notifications.error(`Cannot deactivate program`);
        return throwError(error);
      })
    );
  }
}
