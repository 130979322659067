<app-guide-service-editor-landing
  *ngIf="!(programState.isProgramLoading$ | async)"
  type="program"
  [title]="programTitle"
  (closeEditor)="closeEditor()">
  <div class="tabs">
    <a
      data-qa-id="program-editor-clients-tab"
      routerLink="../clients"
      routerLinkActive="active"
      i18n="@@clients"
      *ngIf="!isAdmin">
      Clients
    </a>
    <a
      data-qa-id="program-editor-hosts-tab"
      routerLink="../assignees"
      routerLinkActive="active"
      i18n="@@hosts"
      *ngIf="assigneesTabVisible$ | async">
      Hosts
    </a>
    <a
      data-qa-id="program-editor-public-page-tab"
      routerLink="../public"
      routerLinkActive="active"
      i18n="@@publicPage"
      *ngIf="accessRole === ProgramAccessRoles.AUTHOR || accessRole === ProgramAccessRoles.INSTRUCTOR">
      Public page
    </a>
    <a data-qa-id="program-editor-content-tab" routerLink="../content" routerLinkActive="active" i18n="@@content">
      Content
    </a>
    <a
      data-qa-id="program-editor-settings-tab"
      routerLink="../settings"
      routerLinkActive="active"
      i18n="@@settings"
      *ngIf="accessRole === ProgramAccessRoles.AUTHOR || accessRole === ProgramAccessRoles.INSTRUCTOR">
      Settings
    </a>
    <a
      data-qa-id="program-editor-forms-tab"
      routerLink="../questionnaires"
      routerLinkActive="active"
      i18n="@@forms"
      *ngIf="(accessRole === ProgramAccessRoles.AUTHOR || accessRole === ProgramAccessRoles.INSTRUCTOR) && !isAdmin">
      Forms
    </a>
    <a
      data-qa-id="program-editor-sharing-tab"
      routerLink="../sharing"
      routerLinkActive="active"
      i18n="@@sharing"
      *ngIf="!isAdmin">
      Sharing
    </a>
  </div>

  <main>
    <div class="container">
      <ng-container [ngSwitch]="selectedTabId">
        <ng-template [ngSwitchCase]="tabs.Clients">
          <app-program-clients
            [programId]="programId"
            [programEvents]="programEvents"
            [programLink]="baseUrl + '/programs/' + makeProgramUrl(programTitle, programId)"
            [programPolicy]="programPolicy"
            [subscriptionDeactivated]="subscriptionInfo.subscriptionDeactivated"
            [editable]="canEditClients"
            class="editor-section program-customers-section"></app-program-clients>
        </ng-template>

        <ng-template [ngSwitchCase]="tabs.PublicTab">
          <div class="content-buttons">
            <app-button className="btn-preview-eye" (click)="previewPublicPage()" i18n="@@preview">Preview</app-button>
            <app-button
              className="btn-primary save-btn"
              *ngIf="author.permissions.canEditSettings"
              (click)="updatePublicInfo()"
              i18n="@@saveChanges">
              Save changes
            </app-button>
          </div>

          <div class="row">
            <div class="col-12">
              <app-program-content
                [author]="author"
                [contentForm]="contentForm"
                [disableSettings]="true"
                [options]="programOptions.options$ | async"
                [possibleCoauthors]="programCoauthors.coauthors$ | async"
                (addCoauthor)="addCoauthor()"
                (addFAQuestion)="addFAQuestion()"
                (addKeyPoint)="addKeyPoint()"
                (addLanguage)="addLanguage()"
                (removeCoauthor)="removeCoauthor($event)"
                (removeFAQuestion)="removeFAQuestion($event)"
                (removeKeyPoint)="removeKeyPoint($event)"
                (skipCoauthorPropositions)="programCoauthors.reset()"
                (togglePriceValidation)="togglePriceValidation($event)"
                (uploadCovers)="uploadImage($event)"
                (removeCover)="removeCover()"
                class="program-content"
                appScrollToFirstInvalid
                triggers="manual"></app-program-content>
            </div>
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="tabs.Content">
          <div class="content-buttons">
            <app-button className="btn-preview-eye" (click)="previewContent()" i18n="@@preview">Preview</app-button>
            <app-button
              data-qa-id="program-editor-save-changes-button"
              className="btn-primary save-btn"
              (click)="updateContent()"
              i18n="@@saveChanges">
              Save changes
            </app-button>
          </div>

          <div class="row editor-section program-modules-section">
            <div class="col-12">
              <app-program-modules
                [modulesForm]="modulesForm"
                [editable]="accessRole === ProgramAccessRoles.AUTHOR"
                [isTeamAdmin]="isTeamAdmin"
                [editorId]="editorId"
                [options]="programOptions.options$ | async"
                [programAccessRole]="accessRole"
                (addModule)="addModule($event)"
                (addQuestion)="addQuestion($event)"
                (addQuestionOption)="addQuestionOption($event)"
                (cloneModule)="cloneModule($event)"
                (moduleSelected)="handleModuleSelection($event)"
                (removeModule)="removeModule($event)"
                (removeQuestion)="removeQuestionAtIndex($event)"
                (removeQuestionOption)="removeQuestionOptionAtIndex($event)"
                (reorderModules)="reorderModules($event)"
                (updateModuleTitle)="updateModuleTitle($event)"
                (updateModuleDescription)="updateModuleDescription($event)"
                (loadQuiz)="loadQuiz($event)"
                class="program-modules"></app-program-modules>
            </div>
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="tabs.Settings">
          <div class="content-buttons">
            <app-button
              className="btn-primary save-btn"
              (click)="updateSettings()"
              i18n="@@saveChanges"
              *ngIf="author.permissions.canEditSettings">
              Save changes
            </app-button>
          </div>

          <div class="row">
            <div class="col-12">
              <app-program-settings
                [author]="author"
                [settingsForm]="settingsForm"
                [options]="programOptions.options$ | async"
                [allCustomersCount]="allCustomersCount"
                [subscriptionInfo]="subscriptionInfo"
                (deactivateProgram)="deactivateProgram($event)"
                appScrollToFirstInvalid
                triggers="manual"
                invalidElementSelector=".ng-invalid.ng-dirty.ng-touched"
                [offset]="-300"></app-program-settings>
            </div>
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="tabs.Forms">
          <div class="content-buttons">
            <app-button
              className="btn-primary save-btn"
              (click)="updateQuestionnaires()"
              i18n="@@saveChanges"
              *ngIf="author.permissions.canEditSettings">
              Save changes
            </app-button>
          </div>
          <div class="row">
            <div class="col-12">
              <app-guide-service-questionnaires-editor
                [author]="author"
                [formQuestionnaires]="questionnairesForm"
                type="program"></app-guide-service-questionnaires-editor>
            </div>
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="tabs.Sharing">
          <div class="row section editor-section">
            <div class="col-12">
              <app-program-promotion
                (linkCopied)="linkCopied($event)"
                [programLink]="
                  baseUrl + '/programs/' + makeProgramUrl(programTitle, programId)
                "></app-program-promotion>
            </div>
          </div>
        </ng-template>
        <ng-template [ngSwitchCase]="tabs.Assignees">
          <app-program-assignees [programId]="programId"></app-program-assignees>
        </ng-template>
      </ng-container>
    </div>
  </main>
</app-guide-service-editor-landing>
