import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { CurrencyService } from '@app/core/currency/currency.service';

import { NgbDatepicker, NgbDatepickerI18n, NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

import { MarketplaceProgramApiService } from '../../services/marketplace-program-api.service';
import { MarketplaceProgramFilterService } from '../../services/programs-filter.service';
import { CategoryListComponent } from '@app/modules/ui-kit/check-list/components/category-list/category-list.component';
// [TODO] Uncomment when programs will have languages again
// import { FlatListComponent } from '@app/modules/ui-kit/check-list/components/flat-list/flat-list.component';
import { MarketplaceRangeSliderComponent } from '@app/modules/ui-kit/range-slider/components/marketplace-range-slider/marketplace-range-slider.component';
import { RangeDatepickerComponent } from '@app/modules/ui-kit/range-datepicker/components/range-datepicker/range-datepicker.component';
import { MarketplaceUserTypeaheadComponent } from '@app/modules/marketplace-filters/components/marketplace-user-typeahead/marketplace-user-typeahead.component';
import { MarketplaceSortingFilterComponent } from '@app/modules/marketplace-filters/components/marketplace-sorting-filter/marketplace-sorting-filter.component';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-marketplace-filter-panel',
  templateUrl: './marketplace-filter-panel.component.html',
  styleUrls: ['./marketplace-filter-panel.component.scss']
})
export class MarketplaceFilterPanelComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  // @ts-expect-error TS2564
  issuesGroup: UntypedFormGroup;
  // @ts-expect-error TS2564
  approachesGroup: UntypedFormGroup;
  // [TODO] Uncomment when programs will have languages again
  // languagesGroup: FormGroup;
  // @ts-expect-error TS2564
  priceGroup: UntypedFormGroup;
  // @ts-expect-error TS2564
  sortingGroup: UntypedFormGroup;
  // @ts-expect-error TS2564
  startDateGroup: UntypedFormGroup;
  // @ts-expect-error TS2564
  practitionerGroup: UntypedFormGroup;

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  // @ts-expect-error TS2564
  @ViewChild('approachFilter', { static: true }) approachFilter: CategoryListComponent;
  // @ts-expect-error TS2564
  @ViewChild('issueFilter', { static: true }) issueFilter: CategoryListComponent;
  // [TODO] Uncomment when programs will have languages again
  // @ViewChild('languageFilter', { static: true }) languageFilter: FlatListComponent;
  // @ts-expect-error TS2564
  @ViewChild('priceFilter', { static: true }) priceFilter: MarketplaceRangeSliderComponent;
  // @ts-expect-error TS2564
  @ViewChild('startDateFilter', { static: true }) startDateFilter: RangeDatepickerComponent;
  @ViewChild('practitionerFilter', { static: true })
  // @ts-expect-error TS2564
  practitionerFilter: MarketplaceUserTypeaheadComponent;
  // @ts-expect-error TS2564
  @ViewChild('sortingFilter', { static: true }) sortingFilter: MarketplaceSortingFilterComponent;

  @ViewChild(NgbDatepicker, { static: true })
  // @ts-expect-error TS2564
  datepicker: NgbDatepicker;
  // @ts-expect-error TS2564
  minPrice: number;
  // @ts-expect-error TS2564
  maxPrice: number;
  // @ts-expect-error TS2564
  priceFrom: number;
  // @ts-expect-error TS2564
  priceTo: number;

  @Output()
  filterChange = new EventEmitter();

  constructor(
    private _marketplaceProgramApiService: MarketplaceProgramApiService,
    public marketplaceProgramFilterService: MarketplaceProgramFilterService,
    private _formBuilder: UntypedFormBuilder,
    calendar: NgbCalendar,
    public i18n: NgbDatepickerI18n,
    public currency: CurrencyService
  ) {
    this.fromDate = calendar.getToday();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnInit() {
    this.priceGroup = this.priceFilter.getForm();
    this.startDateGroup = this.startDateFilter.getForm();
    this.issuesGroup = this.issueFilter.getForm();
    this.approachesGroup = this.approachFilter.getForm();
    this.practitionerGroup = this.practitionerFilter.getForm();
    // [TODO] Uncomment when programs will have languages again
    // this.languagesGroup = this.languageFilter.getForm();
    this.sortingGroup = this.sortingFilter.getForm();

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.marketplaceProgramFilterService.getPrices$().subscribe(({ min, max, priceFrom, priceTo }) => {
      this.minPrice = min;
      this.maxPrice = max;
      this.priceFrom = priceFrom;
      this.priceTo = priceTo;
      this.priceFilter.updateForm({ from: priceFrom, to: priceTo, min, max });
    });

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.marketplaceProgramFilterService.getStartDate$().subscribe(data => {
      this.startDateFilter.updateForm(data);
    });

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.marketplaceProgramFilterService.getIssues$().subscribe(({ tags, issue }) => {
      this.issueFilter.updateForm(tags, issue);
    });

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.marketplaceProgramFilterService.getApproaches$().subscribe(({ tags, approach }) => {
      this.approachFilter.updateForm(tags, approach);
    });

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.marketplaceProgramFilterService.getPractitioners$().subscribe(practitioner => {
      this.practitionerFilter.updateForm(practitioner);
    });

    // [TODO] Uncomment when programs will have languages again
    // this.marketplaceProgramFilterService.getLanguages$().subscribe(({ languages, selectedLanguages }) => {
    //   this.languageFilter.updateForm(languages, selectedLanguages);
    // });

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.marketplaceProgramFilterService.getSorting$().subscribe(({ sorting }) => {
      this.sortingFilter.updateForm(sorting || 'default');
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setPriceFilter() {
    const form = this.priceFilter.getForm();
    const { from, to, min, max } = form.value;
    this.marketplaceProgramFilterService.setPriceFilterAlter({
      from: from !== min ? from : null,
      to: to !== max ? to : null
    });
    this.filterChange.emit();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  resetPriceFilter() {
    this.priceFilter.resetForm();
    this.marketplaceProgramFilterService.setPriceFilterAlter({ from: null, to: null });
    this.filterChange.emit();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setStartDateFilter() {
    const form = this.startDateFilter.getForm();
    this.marketplaceProgramFilterService.setStartDateFilterAlt(form.value);
    this.filterChange.emit();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  resetStartDateFilter() {
    this.startDateFilter.resetForm();
    this.marketplaceProgramFilterService.setStartDateFilterAlt({
      from: null,
      to: null,
      selfPaced: null
    });
    this.filterChange.emit();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setIssueFilter() {
    this.marketplaceProgramFilterService.setIssueFilterAlt(this.issueFilter.getValues());
    this.filterChange.emit();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  resetIssueFilter() {
    this.issueFilter.resetValues();
    this.marketplaceProgramFilterService.setIssueFilterAlt([]);
    this.filterChange.emit();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setApproachFilter() {
    this.marketplaceProgramFilterService.setApproachFilterAlt(this.approachFilter.getValues());
    this.filterChange.emit();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  resetApproachFilter() {
    this.approachFilter.resetValues();
    this.marketplaceProgramFilterService.setApproachFilterAlt([]);
    this.filterChange.emit();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setPractitionerFilter() {
    this.marketplaceProgramFilterService.setPractitionerFilterAlt(this.practitionerFilter.getValues());
    this.filterChange.emit();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  resetPractitionerFilter() {
    this.practitionerFilter.resetForm();
    this.marketplaceProgramFilterService.setPractitionerFilterAlt([]);
    this.filterChange.emit();
  }

  // [TODO] Uncomment when programs will have languages again
  // setLanguageFilter() {
  //   this.marketplaceProgramFilterService.setLanguageFilterAlt(this.languageFilter.getValues());
  //   this.filterChange.emit();
  // }

  // [TODO] Uncomment when programs will have languages again
  // resetLanguageFilter() {
  //   this.languageFilter.resetValues();
  //   this.marketplaceProgramFilterService.setLanguageFilterAlt([]);
  //   this.filterChange.emit();
  // }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setSortingFilter() {
    this.marketplaceProgramFilterService.setSortingFilterAlt(this.sortingFilter.getValues());
    this.filterChange.emit();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  resetSortingFilter() {
    this.marketplaceProgramFilterService.setSortingFilterAlt({ sorting: null });
    this.filterChange.emit();
  }
}
