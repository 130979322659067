import { Injectable } from '@angular/core';
import { GuideRelationShareNotesApiService } from '@app/modules/guide-client/services/guide-relation-share-notes-api.service';
import { Observable, throwError } from 'rxjs';
import { ITeamMembersSharingSettings } from '@app/modules/guide-client/types/team-members';
import { catchError, map } from 'rxjs/operators';
import { NotificationsService } from '@awarenow/profi-ui-core';

export enum TeamMemberAccessType {
  VIEW = 'view',
  FORBIDDEN = 'forbidden'
}

export enum TeamMemberSharingSettingsUserType {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  TEAM_MEMBER = 'team_member',
  CLIENT = 'client'
}

@Injectable()
export class GuideRelationShareNotesService {
  constructor(
    private readonly _guideRelationShareNotesApiService: GuideRelationShareNotesApiService,
    private readonly _notificationService: NotificationsService
  ) {}

  getPossibleTeamMembers$(clientId: number, noteId?: number): Observable<ITeamMembersSharingSettings[]> {
    return this._guideRelationShareNotesApiService.getPossibleTeamMembers$(clientId, noteId).pipe(
      map(teamMembers =>
        teamMembers.map(teamMember => ({
          ...teamMember,
          name: `${teamMember.firstName} ${teamMember.lastName}`
        }))
      ),
      catchError(err => {
        this._notificationService.error(err.error || err.statusText);
        return throwError(err);
      })
    );
  }

  setAccess$(clientId: number, userId: number, accessType: TeamMemberAccessType, noteId?: number): Observable<unknown> {
    return this._guideRelationShareNotesApiService.setAccess$(clientId, userId, accessType, noteId).pipe(
      catchError(err => {
        this._notificationService.error(err.error.errors || err.statusText);
        return throwError(err);
      })
    );
  }
}
