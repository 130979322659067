import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { IGuideContactDetails } from '@app/modules/guide-client/types';
import { CSVCell, CsvReader } from '@app/shared/utils/csv-reader';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { FormService } from '@app/core';
import { GuideContactsServerStoreService } from '@app/modules/guide-client/services/guide-contacts-server-store.service';
import { catchError, map, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';
import { combineLatest, Observable, throwError } from 'rxjs';
import { AnalyticClientTypes, AnalyticSourceTypes, InternalEvents } from '@app/core/analytics/types';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { customValidatorWrapper } from '@app/screens/guide/guide-profile/components/guide-edit-profile/form-validators/custom-validator-wrapper';
import { GuideClientsService } from '@app/core/users/guide-clients.service';
import { PuiDestroyService, PuiDrawerRef } from '@awarenow/profi-ui-core';
import { SALES_CLIENT_TAGS } from '@app/base/consts';
import { EMAIL_REGEXP } from '@app/shared/constants';

@Component({
  templateUrl: './client-add.component.html',
  styleUrls: ['./client-add.component.scss'],
  providers: [GuideContactsServerStoreService, PuiDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientAddComponent implements OnInit {
  static DEFAULT_MAX_LENGTH = 254;

  prefix = 'clients';

  clientsForm: UntypedFormGroup;

  get clientsFormArray(): UntypedFormArray {
    return this.clientsForm.get(this.prefix) as UntypedFormArray;
  }

  get sendInvitationControl(): UntypedFormControl {
    return this.clientsForm.get('sendInvitation') as UntypedFormControl;
  }

  get canRemoveClient(): boolean {
    return this.clientsFormArray.length > 1;
  }

  get isDisabledAddClient$(): Observable<boolean> {
    return this._guideClients.clientsNumberLeft.pipe(
      startWith(Infinity),
      map(clientsNumberLeft => this.clientsFormArray.length >= clientsNumberLeft)
    );
  }

  /**
   * This is a simple solution to implement CRM.Stage.
   * It is not intended to be extended or further supported.
   * https://profi-io.atlassian.net/browse/PR-3803
   */
  readonly tags = SALES_CLIENT_TAGS;
  // @ts-expect-error TS7006
  readonly excludeCurrentTag = (currentTagName: string) => tag => tag.name !== currentTagName;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private _formService: FormService,
    private _fb: UntypedFormBuilder,
    private _notifications: NotificationsService,
    private _cdr: ChangeDetectorRef,
    private _store: GuideContactsServerStoreService,
    private _analyticsService: AnalyticsService,
    private _guideClients: GuideClientsService,
    private _destroy$: PuiDestroyService,
    private _dialogRef: PuiDrawerRef<IGuideContactDetails[]>
  ) {
    this.clientsForm = this._createForm();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnInit() {
    // @ts-expect-error TS2531
    combineLatest(this.clientsFormArray.controls.map(control => control.get('tag').valueChanges))
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this._cdr.markForCheck();
      });
  }

  addAnotherClient(contactDetails?: IGuideContactDetails): void {
    (this.clientsForm.get(this.prefix) as UntypedFormArray).push(this._createNewFormFields(contactDetails));
  }

  removeClientAt(index: number): void {
    (this.clientsForm.get(this.prefix) as UntypedFormArray).removeAt(index);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _parseCsvCells(
    firstNameCsv: CSVCell,
    lastNameCsv: CSVCell,
    emailCsv: CSVCell,
    phoneCsv: CSVCell,
    organizationCsv: CSVCell,
    notesCsv: CSVCell
  ): IGuideContactDetails | null {
    if (!firstNameCsv || !lastNameCsv || !emailCsv || !organizationCsv || !notesCsv) {
      return null;
    }

    const firstName = firstNameCsv.value;
    const lastName = lastNameCsv.value;
    const email = emailCsv.value;
    const phone = phoneCsv ? phoneCsv.value : '';
    const organization = organizationCsv ? organizationCsv.value : '';
    const notes = notesCsv ? notesCsv.value : '';

    if (!firstName || !lastName || !email || !!Validators.email({ value: email } as AbstractControl)) {
      return null;
    }

    return { firstName, lastName, email, phone, organization, notes };
  }

  importCSV(files: FileList): void {
    if (!files.length) {
      return;
    }

    this._removeEmptyContacts();

    const file = files[0];

    CsvReader.parse(file, true)
      .pipe(withLatestFrom(this._guideClients.clientsNumberLeft), takeUntil(this._destroy$))
      .subscribe(([csv, clientsNumberLeft]) => {
        let validRowsCount = 0;

        [{ cells: csv.header }, ...csv.rows].forEach(row => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          const [emailCsv, firstNameCsv, lastNameCsv, phoneCsv, organization, privateNotes] = row.cells;

          const contactDetails = this._parseCsvCells(
            firstNameCsv,
            lastNameCsv,
            emailCsv,
            phoneCsv,
            organization,
            privateNotes
          );

          if (contactDetails && this.clientsFormArray.length < clientsNumberLeft) {
            this.addAnotherClient(contactDetails);
            validRowsCount++;
          }
        });

        if (validRowsCount && csv.rows.length > 0) {
          this._cdr.markForCheck();
        } else {
          const text = `Invalid file format. Use "Email | First Name | Last name | Phone ..."`;
          this._notifications.error(text);
        }
      });
  }

  saveClients(): void {
    // @ts-expect-error TS2531
    const newContacts = this.clientsForm.get(this.prefix).value || [];
    // @ts-expect-error TS2531
    const sendInvitation = this.clientsForm.get('sendInvitation').value || false;

    if (this._formService.markInvalidForm(this.clientsForm)) {
      return;
    }

    this._store
      .addContacts$({ contacts: newContacts, sendInvitation })
      .pipe(
        catchError(error => {
          const text = `Contacts not added.`;
          this._notifications.error(text);
          return throwError(error);
        }),
        takeUntil(this._destroy$)
      )
      // TODO Return method not contains {IGuideContactDetails} model. Response is just {id, type}.
      .subscribe(() => {
        this.back(newContacts);
        let text = '';
        if (newContacts.length === 1) {
          const newContact = newContacts[0];
          const newContactName = `${newContact.firstName} ${newContact.lastName}`.trim();
          text = `New client “${newContactName}” has been successfully added.`;
        } else if (newContacts.length > 0) {
          text = `New ${newContacts.length} clients have been successfully added.`;
        }

        this._notifications.success(text);

        this._analyticsService.event(InternalEvents.CLIENT_INVITE, {
          clientType: AnalyticClientTypes.EXTERNAL,
          numberOfClients: 1,
          source: AnalyticSourceTypes.CLIENTS
        });
      });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _createForm(): UntypedFormGroup {
    return this._fb.group({
      clients: this._fb.array([this._createNewFormFields()]),
      sendInvitation: this._fb.control(false)
    });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _createNewFormFields(details?: IGuideContactDetails): UntypedFormGroup {
    const contactValues = {
      firstName: '',
      lastName: '',
      organization: '',
      email: '',
      phone: '',
      notes: '',
      tag: '',
      ...(details || {})
    };

    return this._fb.group({
      firstName: [
        contactValues.firstName,
        [
          customValidatorWrapper(Validators.required, `First name is required.`),
          customValidatorWrapper(
            Validators.maxLength(ClientAddComponent.DEFAULT_MAX_LENGTH),
            `Max length is ${ClientAddComponent.DEFAULT_MAX_LENGTH} characters.`
          )
        ]
      ],
      lastName: [
        contactValues.lastName,
        [
          customValidatorWrapper(Validators.required, `Last name required.`),
          customValidatorWrapper(
            Validators.maxLength(ClientAddComponent.DEFAULT_MAX_LENGTH),
            `Max length is ${ClientAddComponent.DEFAULT_MAX_LENGTH} characters.`
          )
        ]
      ],
      organization: [
        contactValues.organization,
        [
          customValidatorWrapper(
            Validators.maxLength(ClientAddComponent.DEFAULT_MAX_LENGTH),
            `Max length is ${ClientAddComponent.DEFAULT_MAX_LENGTH} characters.`
          )
        ]
      ],
      email: [
        contactValues.email,
        [
          customValidatorWrapper(Validators.required, `Email required.`),
          customValidatorWrapper(Validators.pattern(EMAIL_REGEXP), `Correct email is required.`),
          customValidatorWrapper(
            Validators.maxLength(ClientAddComponent.DEFAULT_MAX_LENGTH),
            `Max length is ${ClientAddComponent.DEFAULT_MAX_LENGTH} characters.`
          )
        ]
      ],
      phone: [contactValues.phone],
      tag: [contactValues.tag],
      notes: [contactValues.notes]
    });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _removeEmptyContacts(): void {
    this.clientsFormArray.controls
      .reduce((toRemove, control, index) => {
        if (Object.values(control.value).every((value: string | null) => !value || !value.trim())) {
          // @ts-expect-error TS2345
          toRemove.push(index);
        }

        return toRemove;
      }, [])
      .forEach(index => this.removeClientAt(index));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  back(response?: any): void {
    this._dialogRef.close(response);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getTag(tagName: string) {
    return !tagName ? null : this.tags.find(tag => tag.name === tagName);
  }
}
