import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { PuiButtonModule, PuiIconModule } from '@awarenow/profi-ui-core';
import { PasswordFieldDirective } from '@libs/components/password/password-field.directive';

@Component({
  selector: 'app-password-field-icon',
  standalone: true,
  template: `<button
    pui-icon-button
    appearance="flat-secondary"
    size="xs"
    type="button"
    (click)="passwordField.toggle()">
    <pui-icon [svgIcon]="passwordField.isShowed ? 'pui:eye-off' : 'pui:eye'" [size]="24" />
  </button>`,
  styles: `
    :host {
      display: flex;
      align-items: center;
    }
  `,
  imports: [PuiButtonModule, PuiIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordFieldIconComponent {
  @Input({ required: true })
  passwordField!: PasswordFieldDirective;
}
