import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IScheduleProvider } from '../../types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IScheduleTimeSlotsFactory<TTimeSlot> {
  readonly timeSlots$: Observable<TTimeSlot[]>;
}

@Injectable()
export abstract class ScheduleTimeSlotsFactory<TSchedule, TTimeSlot>
  implements IScheduleTimeSlotsFactory<TTimeSlot>, OnDestroy
{
  protected readonly destroy$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly _scheduleProvider: IScheduleProvider<TSchedule>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly _timeSlotsEmitter$: Subject<TTimeSlot[]>;

  get timeSlots$(): Observable<TTimeSlot[]> {
    return this._timeSlotsEmitter$.asObservable();
  }

  protected constructor(scheduleProvider: IScheduleProvider<TSchedule>, timeSlotsEmitter$?: Subject<TTimeSlot[]>) {
    if (!scheduleProvider) {
      throw new Error('Schedule provider required');
    }

    this._scheduleProvider = scheduleProvider;
    this._timeSlotsEmitter$ = timeSlotsEmitter$ || new Subject<TTimeSlot[]>();
  }

  ngOnDestroy(): void {
    this._timeSlotsEmitter$.complete();

    this.destroy$.next();
    this.destroy$.complete();
  }
}
