import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  Input,
  OnInit
} from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AuthService } from '@app/core/auth/services';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { IWorkspaceMember, MemberRoleEnum } from '@app/modules/workspaces/types';
import { SessionTemplateSchedule } from '@app/screens/guide/guide-sessions-templates/types';
import { PuiDestroyService, puiSortFunction } from '@awarenow/profi-ui-core';
import { ProfiAvailability } from '@libs/services/http/http-workspace-availability.service';

import { AvailabilityType, SessionTemplateAvailabilityForm } from './types';

@Component({
  selector: 'app-session-availability-form',
  templateUrl: './session-availability-form.component.html',
  styleUrls: ['./session-availability-form.component.scss'],
  providers: [
    PuiDestroyService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SessionAvailabilityFormComponent)
    }
  ]
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionAvailabilityFormComponent implements OnInit, ControlValueAccessor {
  @Input() showTitle = true;

  adminGroupName = `Team’s availability`;
  MemberRoleEnum = MemberRoleEnum;
  AvailabilityType = AvailabilityType;
  user$ = this.authService.onAuth();

  form = this.formBuilder.group({
    active: [false],
    availabilities: [null],
    selectedAvailability: [null],
    customAvailability: [null],
    availabilityType: [AvailabilityType.NONE]
  });

  constructor(
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>,
    readonly workspacesService: WorkspacesService,
    private cdRef: ChangeDetectorRef,
    private readonly formBuilder: UntypedFormBuilder,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.form
      .get('active')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (!this.form.get('selectedAvailability')?.value) {
          const formData: Partial<SessionTemplateAvailabilityForm> = {};
          let defaultAvailability: SessionTemplateSchedule | undefined;

          const availabilities = this.form.get('availabilities')?.value;

          if (availabilities) {
            this.form.get('availabilities')?.value.forEach((availabilityByHost: SessionTemplateSchedule[]) => {
              if (defaultAvailability) {
                return;
              }

              defaultAvailability = availabilityByHost.find(availability => availability.defaultSchedule);
            });
          }

          formData.availabilityType = AvailabilityType.WORKING_HOURS;
          formData.selectedAvailability = defaultAvailability;

          this.form.patchValue(formData);
        }
      });
  }

  onTouched: () => void = () => {};

  writeValue(value: SessionTemplateAvailabilityForm): void {
    if (value?.customAvailability || value?.sessionTemplateScheduleId || !!value?.availabilities?.length) {
      const formData: Partial<SessionTemplateAvailabilityForm> = { ...value };

      if (value.customAvailability) {
        formData.active = true;
        formData.availabilityType = AvailabilityType.CUSTOM;
      }

      if (value.sessionTemplateScheduleId && !value.customAvailability) {
        let existedAvailability: SessionTemplateSchedule | undefined;

        value.availabilities?.forEach(availabilityByHost => {
          if (existedAvailability) {
            return;
          }

          existedAvailability = availabilityByHost.find(
            availability => availability.id === value.sessionTemplateScheduleId
          );
        });

        formData.active = true;
        formData.availabilityType = AvailabilityType.WORKING_HOURS;
        formData.selectedAvailability = existedAvailability;
      }

      this.form.patchValue(formData, { emitEvent: false });
    } else {
      this.form.reset();
    }
  }

  registerOnChange(
    fn: (value: Pick<SessionTemplateAvailabilityForm, 'sessionTemplateScheduleId' | 'customAvailability'>) => void
  ): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: SessionTemplateAvailabilityForm) => {
      const changedValue: Pick<SessionTemplateAvailabilityForm, 'sessionTemplateScheduleId' | 'customAvailability'> = {
        sessionTemplateScheduleId: null
      };

      if (!value.active) {
        changedValue.sessionTemplateScheduleId = null;
        changedValue.customAvailability = null;
      } else if (value.selectedAvailability) {
        changedValue.sessionTemplateScheduleId = value.selectedAvailability.id;
      } else if (value.customAvailability) {
        changedValue.customAvailability = value.customAvailability;
      }

      fn(changedValue);
    });
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  selectAvailability(availability?: ProfiAvailability): void {
    if (availability) {
      this.form.patchValue({
        availabilityType: AvailabilityType.WORKING_HOURS,
        selectedAvailability: availability
      });
    } else {
      this.form.patchValue({
        availabilityType: AvailabilityType.CUSTOM,
        selectedAvailability: null
      });
    }

    this.cdRef.markForCheck();
  }

  getMember(availabilities: ProfiAvailability[]): IWorkspaceMember | undefined {
    const userId = availabilities[0].userId;
    return this.workspacesService.members.find(member => member.userId === userId);
  }

  sortWithPrior(availabilities: ProfiAvailability[][], userId: number): ProfiAvailability[][] {
    if (!availabilities) {
      return [];
    }

    // Team’s availability
    const teamAdminAvailability = availabilities.filter(availability => availability[0].userId === userId);
    // Team Members availabilities
    const teamAvailabilities = availabilities.filter(availability => availability[0].userId !== userId);
    // Sorted Team Members availabilities
    const sortedTeamAvailabilities = this.workspacesService.members
      .sort((tmOne, tmTwo) => puiSortFunction('asc')(tmOne.username, tmTwo.username))
      .map(teamMember => teamAvailabilities.find(availability => availability[0].userId === teamMember.userId)!)
      .filter(item => !!item);

    return teamAdminAvailability.concat(sortedTeamAvailabilities);
  }

  isTeamAdmin(memberRole: MemberRoleEnum): boolean {
    return memberRole === MemberRoleEnum.ADMIN && this.workspacesService.isTeam;
  }
}
