import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { BlogReaderService } from '@app/screens/blog/services/blog-reader.service';
import { BlogArticle } from '@app/screens/blog/types';

@Injectable()
export class ArticleResolver {
  constructor(private blogReaderService: BlogReaderService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<BlogArticle> {
    const paramMap = route.paramMap;
    // @ts-expect-error TS2531
    const id = +paramMap.get('id').split('-').shift();

    return this.blogReaderService.getBlogArticle$(id);
  }
}
