import { DateTime } from 'luxon';
import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { ReplaySubject, Subject, throwError, of, combineLatest, fromEvent, Subscription } from 'rxjs';
import { catchError, filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AuthService } from '@app/core/auth/services/auth.service';
import { ErrorService } from '@app/core/error/error.service';
import { FACEBOOK_AUTH_ENDPOINT, GOOGLE_AUTH_ENDPOINT, SSO_AUTH_ENDPOINT } from '@app/shared/constants/endpoints';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { UserRoles } from '@app/shared/enums/user-roles';
import { WindowService } from '@app/core/window/window.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

interface SignUpAlternativeProvider {
  isGuide: boolean;
  isClient: boolean;
  promo?: string;
  subscribeNews: boolean;
}

@Injectable()
export class AlternativeAuthProvidersService implements OnDestroy {
  private readonly MESSAGE_TYPE = 'alternative_auth_provider_response';

  private readonly WINDOW_TITLE = 'alternative_auth_provider_window';

  private readonly destroy$ = new Subject<void>();

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _authPopup: Window;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _authEnd$: ReplaySubject<boolean> = new ReplaySubject(1);

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _remember: boolean;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _onlyClient: boolean;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _onlyGuide: boolean;

  private readonly isGuideSSOEnabled: boolean;
  private readonly isClientSSOEnabled: boolean;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _renderer: Renderer2;

  private providerSubscription?: Subscription;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  signUpUser: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  signUpProvider: any;

  // @ts-expect-error TS2564
  signInCanNot: boolean;

  constructor(
    @Inject(GLOBAL_WINDOW) private readonly _browserWindow: Window,
    private readonly _analyticsService: AnalyticsService,
    private readonly _auth: AuthService,
    private readonly _errors: ErrorService,
    private readonly _newWindow: WindowService,
    private readonly _notifications: NotificationsService,
    private readonly _rendererFactory2: RendererFactory2,
    private readonly runtimeConfigService: RuntimeConfigService
  ) {
    this._renderer = this._rendererFactory2.createRenderer(null, null);

    this.isGuideSSOEnabled = this.runtimeConfigService.get('isGuideSSOEnabled') || false;
    this.isClientSSOEnabled = this.runtimeConfigService.get('isClientSSOEnabled') || false;
  }

  ngOnDestroy(): void {
    this.reset();
    this.destroy$.next();
    this.destroy$.complete();
  }

  onAuth(): ReplaySubject<boolean> {
    return this._authEnd$;
  }

  openFacebookAuthPopup(
    remember?: boolean | null,
    onlyClient?: boolean | null,
    onlyGuide?: boolean | null,
    teamInvitationCode = ''
  ): void {
    this.authenticateTo(FACEBOOK_AUTH_ENDPOINT, remember, onlyClient, onlyGuide, teamInvitationCode);
  }

  openGoogleAuthPopup(
    remember?: boolean | null,
    onlyClient?: boolean | null,
    onlyGuide?: boolean | null,
    teamInvitationCode = ''
  ): void {
    this.authenticateTo(GOOGLE_AUTH_ENDPOINT, remember, onlyClient, onlyGuide, teamInvitationCode);
  }

  openSSOAuthPopup(
    remember?: boolean | null,
    onlyClient?: boolean | null,
    onlyGuide?: boolean | null,
    teamInvitationCode = ''
  ): void {
    this.authenticateTo(SSO_AUTH_ENDPOINT, remember, onlyClient, onlyGuide, teamInvitationCode);
  }

  insertSSOAuthFrame(
    remember?: boolean | null,
    onlyClient?: boolean | null,
    onlyGuide?: boolean | null,
    teamInvitationCode = ''
  ): void {
    this.authenticateToViaFrame(SSO_AUTH_ENDPOINT, remember, onlyClient, onlyGuide, teamInvitationCode);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  signUp(data: SignUpAlternativeProvider) {
    if (!this.signUpUser) {
      return;
    }

    const analyticParams = this._analyticsService.getAnalyticParamsFromCookie();
    const teamInvitationCode = this._auth?.workspaceMemberSignUpInfo?.invitationCode;

    this._auth
      .signupAlternativeProvider(
        { ...this.signUpUser, ...data, ...analyticParams, teamInvitationCode },
        this._remember,
        {
          authProvider: this.signUpProvider,
          isSignUp: true
        }
      )
      .pipe(
        // eslint-disable-next-line id-length, @typescript-eslint/no-explicit-any
        tap((r: any) => {
          if (r.user && r.user.promoCodeError) {
            const title = `Invalid invite code`;
            this._notifications.error(title);
          }
          return r;
        }),
        // eslint-disable-next-line id-length
        catchError(e => {
          if (!e.error) {
            return throwError(e);
          }

          const errors = this._errors.parseErrors(e);
          for (const error of errors) {
            const title = `Something wrong`;
            this._notifications.error(title, error);
          }
          return throwError(e);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this._authEnd$.next(true));
  }

  applyAuthResponse(authResponse: unknown): void {
    this.handleAuthMessage(authResponse);
  }

  private authenticateTo(
    url: string,
    remember?: boolean | null,
    onlyClient?: boolean | null,
    onlyGuide?: boolean | null,
    teamInvitationCode?: string | null
  ): void {
    this.reset();

    this._remember = !!remember;
    this._onlyClient = !!onlyClient;
    this._onlyGuide = !!onlyGuide;

    const invitationCodeQueryParam = teamInvitationCode ? `&teamInvitationCode=${teamInvitationCode}` : '';

    this.openAuthPopup(`${url}?timezone=${DateTime.local().zoneName}${invitationCodeQueryParam}`);
  }

  private authenticateToViaFrame(
    url: string,
    remember?: boolean | null,
    onlyClient?: boolean | null,
    onlyGuide?: boolean | null,
    teamInvitationCode?: string | null
  ): void {
    this.reset();

    this._remember = !!remember;
    this._onlyClient = !!onlyClient;
    this._onlyGuide = !!onlyGuide;

    const invitationCodeQueryParam = teamInvitationCode ? `&teamInvitationCode=${teamInvitationCode}` : '';

    this.openAuthIframe(`${url}?timezone=${DateTime.local().zoneName}${invitationCodeQueryParam}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private messageEventHandler(event: any) {
    // TODO It should be removed because event.origin not should be equals environment.apiHost
    // if (environment.production && event.origin !== environment.apiHost) {
    //   this._authEnd$.next(false);
    //   return false;
    // }

    const message = event.data;

    if (message.type && message.type === this.MESSAGE_TYPE) {
      return this.handleAuthMessage(message);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleAuthMessage(message: any): boolean {
    if (message.type && message.type === this.MESSAGE_TYPE) {
      this.reset();

      if (message.error) {
        let title;
        if (
          message.error.status &&
          message.error.status === 403 &&
          message.error.payload &&
          message.error.payload.isBlocked
        ) {
          title = `Your account is blocked`;
        } else {
          title = `${message.error.message}`;
        }
        this._notifications.error(message.error.message || title);
      } else if (message.payload.user && message.payload.user.showResetPassword) {
        this._auth.openConfirmEmailStep(message.payload.user);

        return false;
      } else {
        // SSO enabled for Guide and open auth page without &guide=true
        if (!this._onlyGuide && message.payload.isSignUp && this.isGuideSSOEnabled && !this.isClientSSOEnabled) {
          this._notifications.error(`Account registration is required to log in with SSO. Please contact your admin.`);

          return false;
        }

        if (message.payload.isSignUp) {
          this.signUpUser = message.payload.user;
          this.signUpProvider = message.payload.authProvider;
          this._authEnd$.next(false);
        } else {
          const { user } = message.payload;

          if (user.tfaChallenge) {
            this._auth.openTfaAuthStep(user);

            return false;
          }

          this._auth
            .signinAlternativeProvider(
              user,
              this._remember,
              { authProvider: message.payload.authProvider, isSignUp: false },
              this._onlyGuide
            )
            .pipe(
              // eslint-disable-next-line id-length
              catchError(e => {
                return throwError(e);
              }),
              mergeMap(data => {
                const {
                  user: { RoleId: role }
                } = data;

                // Changed in [PR-7088], try check alternative account only if open guide link and user have client role
                // (this._onlyClient && role === UserRoles.GUIDE) ||
                if (this._onlyGuide && role === UserRoles.CLIENT) {
                  return combineLatest([of(data), this._auth.checkAlternativeAccount()]);
                }

                return of([data, null]);
              }),
              // eslint-disable-next-line id-length
              mergeMap(([r, checkRes]) => {
                if (checkRes) {
                  if (checkRes.hasAlternativeProfile) {
                    return this._auth.signinAlternativeAccount(true);
                  }

                  return throwError({ r, alternativeFailed: true });
                }

                return of(r);
              }),
              takeUntil(this.destroy$)
            )
            .subscribe(
              () => this._authEnd$.next(true),
              error => {
                if (error.alternativeFailed) {
                  this.signInCanNot = true;
                  this._authEnd$.next(true);
                  // not for book widget
                  if (this._auth.authorize) {
                    this._auth.authorize(error.r);
                  }
                }
              }
            );
        }

        return true;
      }
    }

    this._authEnd$.next(false);

    return false;
  }

  private openAuthPopup(url: string): void {
    this.handleMessage();

    this._authPopup = this._newWindow.openPopup(url, this.WINDOW_TITLE);
  }

  private openAuthIframe(url: string): void {
    this.handleMessage();

    const frame = document.createElement('iframe');

    frame.id = 'auth';
    frame.src = url;
    frame.setAttribute('style', `display: none;visibility: hidden`);
    frame.className = `hidden-frame`;

    document.documentElement.appendChild(frame);
  }

  reset(): void {
    // @ts-expect-error TS2322
    this.signInCanNot = null;
    this.signUpUser = null;
    this.signUpProvider = null;

    if (this._authPopup) {
      this.providerSubscription?.unsubscribe();
      this._authPopup.close();
      // @ts-expect-error TS2322
      this._authPopup = null;
    }
  }

  private handleMessage() {
    this.providerSubscription?.unsubscribe();
    this.providerSubscription = fromEvent<MessageEvent>(this._browserWindow, 'message', {
      capture: false
    })
      .pipe(
        map(({ data }) => data),
        filter(data => {
          return data.type && data.type === this.MESSAGE_TYPE;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        this.handleAuthMessage(data);
      });
  }
}
