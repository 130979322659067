import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { AuthService } from '@app/core/auth/services/auth.service';

@Injectable()
export class NoSelfChatGuard {
  constructor(private _notifications: NotificationsService, private _auth: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const userIdParam = route.paramMap.get('id');
    const userId = userIdParam ? +userIdParam : null;

    return this._auth.user.id !== userId;
  }
}
