import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GuideRelation } from '@app/core/users/types';
import { IUser } from '@app/shared/interfaces/user';
import { ClientInvitation, GuideClientProgram, IUserWithDeletionStatus } from '../../types';

export enum ClientsListTabs {
  Enrolled,
  Pending,
  Deleted
}

type PendingClient = InvitedClient | InvitedContact;

interface PendingClientBase {
  paid: boolean;
  prepaid: boolean;
  name: string;
}

interface InvitedClient extends PendingClientBase {
  id: number;
}

interface InvitedContact extends PendingClientBase {
  inviteId: number;
}

@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: [
    './clients-list.component.scss',
    '../../../../../modules/guide-service-editor/common-styles/popover.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientsListComponent {
  invitedClients: GuideClientProgram[] = [];
  invitations: ClientInvitation[] = [];
  activeTab = ClientsListTabs.Enrolled;
  activePopoverIndex: number | null = null;
  allInvited: PendingClient[] = [];
  selectedInvitedClientId: number | null = null;
  clients: GuideClientProgram[] = [];
  deletedClients: GuideClientProgram[] = [];
  searchText = '';
  selectedClient: IUser | null = null;
  clientsListTabs = ClientsListTabs;

  @Input()
  editable = false;

  @Input('clients')
  set setClients(value: {
    invitedClients: GuideClientProgram[];
    deletedClients: GuideClientProgram[];
    clients: GuideClientProgram[];
    invitations: ClientInvitation[];
  }) {
    this.clients = value.clients;
    this.deletedClients = value.deletedClients;
    this.invitedClients = value.invitedClients;
    this.invitations = value.invitations;
    this.selectedInvitedClientId = null;
    this.selectInvited.emit(null);
    this.calcAllInvited();
  }

  @Input()
  progress = {};

  @Input()
  selectedClientId: number | null = null;

  @Output()
  unEnroll = new EventEmitter<IUserWithDeletionStatus>();

  @Input()
  guideClients: GuideRelation[] = [];

  @Output()
  selectClient = new EventEmitter<number>();

  @Output()
  selectInvited = new EventEmitter<number | null>();

  @Output()
  deleteInvited = new EventEmitter<number>();

  private resetSearch(): void {
    this.searchText = '';
  }

  private calcAllInvited(): void {
    this.allInvited = [
      ...(this.invitedClients || []).map(user => ({
        id: user.id,
        paid: user.status === 'invited',
        prepaid: user.status === 'inactive',
        name: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.contacts?.email || ''
      })),
      ...this.invitations.map(i => ({
        inviteId: i.inviteId,
        paid: i.codeType === 'program_informative',
        prepaid: i.codeType === 'program_prepaid',
        name: i.firstName && i.lastName ? `${i.firstName} ${i.lastName}` : i.email || ''
      }))
    ];
  }

  selectInvitedClient(client: ClientInvitation): void {
    if (client.inviteId) {
      this.selectInvited.emit(client.inviteId);
      this.selectedInvitedClientId = client.inviteId;
    }
  }

  deleteInvitedClient(activePopoverIndex: number): void {
    const user = this.allInvited[activePopoverIndex];

    if ('id' in user) {
      this.deleteInvited.emit(user.id);
      this.activePopoverIndex = null;
    }
  }

  unEnrollEmit(user: IUser, deleted: boolean = false): void {
    if (!deleted) {
      this.unEnroll.emit(user);
    } else {
      this.unEnroll.emit({ ...user, deleted: true });
    }
  }

  select(id: number): void {
    this.selectClient.emit(id);
  }

  selectTab(tab: ClientsListTabs): void {
    this.resetSearch();
    this.activeTab = tab;
    if (tab === ClientsListTabs.Pending) {
      this.selectInvited.emit(this.selectedInvitedClientId);
    } else {
      this.selectInvited.emit(null);
    }
  }
}
