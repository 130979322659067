import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-checkbox-reactive',
  templateUrl: './checkbox-reactive.component.html',
  styleUrls: ['./checkbox-reactive.component.scss']
})
export class CheckboxReactiveComponent {
  @Input()
  // @ts-expect-error TS2564
  control: UntypedFormControl;
}
