import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, forwardRef, Inject, Input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { AuthService } from '@app/core/auth/services';
import { customValidatorWrapper } from '@app/screens/guide/guide-profile/components/guide-edit-profile/form-validators/custom-validator-wrapper';
import { UserRoles } from '@app/shared/enums/user-roles';
import { integerValidator } from '@app/shared/form-validators/integer.validator';
import { PuiDestroyService } from '@awarenow/profi-ui-core';

export interface ParticipantsAndGuestsForm {
  disableGuests: boolean;
  offerSeatsEnabled: boolean;
  seatsPerTimeSlot: number | null;
  hasAutoConfirmation: boolean;
}

const createForm = (fb: UntypedFormBuilder): UntypedFormGroup => {
  return fb.group({
    disableGuests: [false],
    offerSeatsEnabled: [false],
    seatsPerTimeSlot: [
      10,
      [
        customValidatorWrapper((control: AbstractControl) => {
          const offerSeatsEnabled = control.parent?.get('offerSeatsEnabled')?.value;
          const seatsPerTimeSlot = control.value;

          if (offerSeatsEnabled && !seatsPerTimeSlot) {
            return {
              required: true
            };
          }

          return null;
        }, `Value must be greater than or equal to 1.`),
        customValidatorWrapper(Validators.min(0), `Value must be greater than or equal to 1.`),
        integerValidator(`Value must be greater than or equal to 1.`)
      ]
    ],
    hasAutoConfirmation: [false]
  });
};

@Component({
  selector: 'app-participants-and-guests-form',
  templateUrl: './participants-and-guests-form.component.html',
  styleUrls: ['./participants-and-guests-form.component.scss'],
  providers: [
    PuiDestroyService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ParticipantsAndGuestsFormComponent)
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ParticipantsAndGuestsFormComponent),
      multi: true
    }
  ]
})
export class ParticipantsAndGuestsFormComponent implements ControlValueAccessor, Validator {
  form: UntypedFormGroup = createForm(this.fb);
  UserRoles = UserRoles;
  offseatsMask = [/\d/, /\d/, /\d/];

  @Input() showTitle = true;

  constructor(
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>,
    public auth: AuthService,
    private readonly fb: UntypedFormBuilder
  ) {}

  // TODO That method bind to (change) event of puiToggle, but by some reason it emit on save.
  toggleOfferSeats(checked: boolean | undefined): void {
    if (checked === undefined) {
      return;
    }

    const prevValue = this.form.get('offerSeatsEnabled')?.value;

    if (checked === prevValue) {
      return;
    }

    if (!checked) {
      this.form.patchValue({ seatsPerTimeSlot: null });
    } else {
      this.form.patchValue({ seatsPerTimeSlot: 10 });
    }
  }

  onTouched: () => void = () => {};

  writeValue(value: ParticipantsAndGuestsForm): void {
    if (value) {
      this.form.setValue({
        disableGuests: !value.disableGuests,
        offerSeatsEnabled: !!value.seatsPerTimeSlot,
        seatsPerTimeSlot: value.seatsPerTimeSlot,
        hasAutoConfirmation: value.hasAutoConfirmation
      });
    }
  }

  registerOnChange(
    fn: (value: Pick<ParticipantsAndGuestsForm, 'disableGuests' | 'seatsPerTimeSlot' | 'hasAutoConfirmation'>) => void
  ): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: ParticipantsAndGuestsForm) => {
      const formValue: Pick<ParticipantsAndGuestsForm, 'disableGuests' | 'seatsPerTimeSlot' | 'hasAutoConfirmation'> = {
        disableGuests: !value.disableGuests,
        hasAutoConfirmation: value.hasAutoConfirmation,
        seatsPerTimeSlot: value.offerSeatsEnabled ? Number(value.seatsPerTimeSlot) : null
      };

      fn(formValue);
    });
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  validate(): ValidationErrors | null {
    return {
      ...this.form.get('seatsPerTimeSlot')?.errors
    };
  }
}
