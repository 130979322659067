<div class="controls update" *ngIf="{ saveButtonDisabled: !!(saveButtonDisabled$ | async) } as options">
  <app-button className="btn-lite btn-lg" [disabled]="options.saveButtonDisabled" (click)="shareForm()">
    <ng-container i18n="@@shareForm">Share form</ng-container>
  </app-button>
  <app-button className="btn-primary btn-lg" [disabled]="options.saveButtonDisabled" (click)="saveChanges()">
    <ng-container i18n="@@saveChanges">Save changes</ng-container>
  </app-button>
</div>

<div class="form-wrapper">
  <div class="box">
    <div class="info">
      <h2 pui-typography="h2" i18n="@@automation">Automation</h2>
      <p pui-typography="body-s" style="color: #84929d; margin-top: 8px" i18n="@@setUpWhenAndToWhom">
        Set up when to deliver the form and to whom.
      </p>
    </div>
    <div class="form">
      <app-quiz-settings></app-quiz-settings>
    </div>
  </div>
</div>
