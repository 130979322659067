import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { DateTime } from 'luxon';
import { UntypedFormGroup } from '@angular/forms';

import { CurrencyService } from '@app/core/currency/currency.service';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { SubscriptionRecurrency } from '@app/shared/enums/subscription';

import { ServicePricingTypes } from '../types/pricing';
import { IntercomService } from '@app/core/intercom/intercom.service';
import { takeUntil } from 'rxjs/operators';
import { BrandingService } from '@app/core/branding/branding.service';
import { PuiDestroyService } from '@awarenow/profi-ui-core';
import { Observable } from 'rxjs';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { PackageAutoRenewalType } from '@app/screens/guide/guide-packages/types';

@Component({
  selector: 'app-guide-service-pricing-editor',
  templateUrl: './guide-service-pricing-editor.component.html',
  styleUrls: [
    '../common-styles/forms.scss',
    '../common-styles/program.scss',
    '../common-styles/radio.scss',
    './guide-service-pricing-editor.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideServicePricingEditorComponent implements OnInit, DoCheck {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _form: UntypedFormGroup;

  readonly GuideServiceTypes = GuideServiceTypes;

  readonly ServicePricingTypes = ServicePricingTypes;

  readonly SubscriptionRecurrency = SubscriptionRecurrency;

  readonly AutoRenewalTypes = PackageAutoRenewalType;

  readonly currentPlatformName = this.runtimeConfigService.get('platformName');

  readonly mainPlatformName = 'Profi';

  disabledInstallments = false;

  contactEmail = '';

  @Input()
  // @ts-expect-error TS2564
  disablePaymentTypeChange: boolean;

  @Input()
  // @ts-expect-error TS2564
  type: GuideServiceTypes;

  @Input()
  // @ts-expect-error TS2564
  subscriptionInfo: {
    subscriptionDeactivated: boolean;
    subscriptionDeactivatedDate: string;
    subscribersNumber: string;
  };

  @Input() set form(form: UntypedFormGroup) {
    this._form = form;
    // @ts-expect-error TS2531
    this.disabledInstallments = form.get('isInstallmentsEnabled').value && this.disablePaymentTypeChange;
  }

  @Output()
  deactivateOffer = new EventEmitter<{ date: Date | null; message: string }>();

  @Output() pricingChanged: EventEmitter<ServicePricingTypes> = new EventEmitter<ServicePricingTypes>();

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get form(): UntypedFormGroup {
    return this._form;
  }

  get isPackage(): boolean {
    return this.type === GuideServiceTypes.PACKAGE;
  }

  get wasSubscriptionDeactivated(): boolean {
    // @ts-expect-error TS2322
    return (
      this.subscriptionInfo &&
      this.subscriptionInfo.subscriptionDeactivated &&
      this.subscriptionInfo.subscriptionDeactivatedDate &&
      DateTime.fromISO(this.subscriptionInfo.subscriptionDeactivatedDate).toMillis() < DateTime.local().toMillis()
    );
  }

  get willSubscriptionBeDeactivated(): boolean {
    // @ts-expect-error TS2322
    return (
      this.subscriptionInfo &&
      this.subscriptionInfo.subscriptionDeactivated &&
      this.subscriptionInfo.subscriptionDeactivatedDate &&
      DateTime.fromISO(this.subscriptionInfo.subscriptionDeactivatedDate).toMillis() > DateTime.local().toMillis()
    );
  }

  constructor(
    public currency: CurrencyService,
    private _cdr: ChangeDetectorRef,
    private readonly intercom: IntercomService,
    private readonly brandingService: BrandingService,
    private readonly runtimeConfigService: RuntimeConfigService,
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>
  ) {}

  ngOnInit(): void {
    this.brandingService.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(({ emailHelp }) => {
      this.contactEmail = emailHelp;
    });
  }

  openIntercom(): void {
    this.intercom.openIntercom();
  }

  ngDoCheck(): void {
    this._cdr.detectChanges();
  }
}
