import { UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { Injectable } from '@angular/core';

import { ServicePricingTypes } from '@app/modules/guide-service-editor/types/pricing';
import { SubscriptionRecurrency, SubscriptionLength } from '@app/shared/enums/subscription';
import { PackageAutoRenewalType } from '@app/screens/guide/guide-packages/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IPriceSettingsForm {
  paymentType: ServicePricingTypes;
  price: number;
  subscriptionPrice: number;
  subscriptionRecurrency: SubscriptionRecurrency;
  totalPayments: number;
  isInstallmentsEnabled: boolean;
  subscriptionLength: SubscriptionLength;
  doNotShowPrice: boolean;
  autoRenewalEnabled: boolean;
  autoRenewal: PackageAutoRenewalType | null;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPriceSettings {
  isHiddenPrice?: boolean;
  isFixedPrice: boolean;
  isFree: boolean;
  price: number;
  subscriptionRecurrency?: SubscriptionRecurrency;
  subscriptionPrice?: number;
  totalPayments?: number;
  autoRenewal?: PackageAutoRenewalType | null;
}

@Injectable()
export class GuidePriceEditorService {
  constructor(private _formBuilder: UntypedFormBuilder) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  createPriceSettings() {
    return this._formBuilder.group(
      {
        paymentType: [ServicePricingTypes.Free],
        price: [1],
        subscriptionPrice: [1],
        subscriptionRecurrency: [SubscriptionRecurrency.Month],
        totalPayments: [1],
        isInstallmentsEnabled: [false],
        subscriptionLength: [SubscriptionLength.Infinite],
        doNotShowPrice: [false],
        autoRenewalEnabled: [false],
        autoRenewal: [null]
      },
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        validators: (control: AbstractControl): { [key: string]: any } => {
          if (control.value.paymentType === ServicePricingTypes.Free) {
            if (control.value.autoRenewalEnabled && control.value.autoRenewal === null) {
              return { invalidPrice: { value: control.value.autoRenewalEnabled } };
            }
            // @ts-expect-error TS2322
            return null;
          }

          if (
            (control.value.paymentType === ServicePricingTypes.Paid && control.value.price < 1) ||
            // @ts-expect-error TS2531
            !control.get('price').valid
          ) {
            return {
              invalidPrice: {
                value: control.value.price
              }
            };
          }

          if (
            control.value.paymentType === 'paid' &&
            control.value.isInstallmentsEnabled &&
            // @ts-expect-error TS2531
            (!control.get('subscriptionPrice').valid ||
              // @ts-expect-error TS2531
              control.get('subscriptionPrice').value < 1 ||
              !control.value.totalPayments)
          ) {
            return {
              invalidPrice: {
                value: control.value.price
              }
            };
          }

          if (
            control.value.paymentType === ServicePricingTypes.Subscription &&
            // @ts-expect-error TS2531
            (control.get('subscriptionPrice').value < 1 || !control.get('subscriptionPrice').valid)
          ) {
            return {
              invalidPrice: {
                value: control.value.subscriptionPrice
              }
            };
          }

          // @ts-expect-error TS2322
          return null;
        }
      }
    );
  }

  priceSettingsToForm(data: IPriceSettings): IPriceSettingsForm {
    const {
      isFixedPrice,
      isFree,
      price,
      subscriptionPrice,
      subscriptionRecurrency,
      totalPayments,
      isHiddenPrice,
      autoRenewal
    } = data;

    const formData = {} as IPriceSettingsForm;

    formData.price = price;
    // @ts-expect-error TS2322
    formData.subscriptionPrice = subscriptionPrice;
    formData.subscriptionRecurrency = subscriptionRecurrency || SubscriptionRecurrency.Month;
    // @ts-expect-error TS2322
    formData.totalPayments = totalPayments;
    formData.isInstallmentsEnabled = !!price && !!subscriptionPrice;
    formData.subscriptionLength = totalPayments ? SubscriptionLength.Finite : SubscriptionLength.Infinite;
    // @ts-expect-error TS2322
    formData.doNotShowPrice = isHiddenPrice;

    if (isFixedPrice) {
      formData.paymentType = ServicePricingTypes.Paid;
    } else if (isFree) {
      formData.paymentType = ServicePricingTypes.Free;
    } else {
      formData.paymentType = ServicePricingTypes.Subscription;
    }

    if (autoRenewal) {
      formData.autoRenewalEnabled = true;
      formData.autoRenewal = autoRenewal;
    }

    return formData;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  preparePriceData(priceFormValue: IPriceSettingsForm) {
    const isFree = priceFormValue.paymentType === ServicePricingTypes.Free;
    const isFixedPrice = priceFormValue.paymentType === ServicePricingTypes.Paid;
    const isSubscription = priceFormValue.paymentType === ServicePricingTypes.Subscription;
    const isHiddenPrice = isFree && priceFormValue.doNotShowPrice;
    const autoRenewal =
      isFree && priceFormValue.autoRenewalEnabled && priceFormValue.autoRenewal ? priceFormValue.autoRenewal : null;

    let price = priceFormValue.price;
    let subscriptionPrice = priceFormValue.subscriptionPrice;
    let subscriptionRecurrency = priceFormValue.subscriptionRecurrency;
    let totalPayments = priceFormValue.totalPayments;

    if (isFree || isSubscription) {
      price = 0;
    }
    if (isFree || (isFixedPrice && !priceFormValue.isInstallmentsEnabled)) {
      subscriptionPrice = 0;
      // @ts-expect-error TS2322
      subscriptionRecurrency = null;
      // @ts-expect-error TS2322
      totalPayments = null;
    }
    if (isSubscription && priceFormValue.subscriptionLength === SubscriptionLength.Infinite) {
      // @ts-expect-error TS2322
      totalPayments = null;
    }

    return {
      isFree,
      isFixedPrice,
      isSubscription,
      price,
      subscriptionPrice,
      subscriptionRecurrency,
      totalPayments,
      isHiddenPrice,
      autoRenewal
    };
  }
}
