import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { Observable, race, timer, of, combineLatest } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { take, map } from 'rxjs/operators';

import { MembershipService } from '@app/core/membership/membership.service';
import { SubscriptionPage } from '@app/core/membership/types';
import { UserRoles } from '@app/shared/enums/user-roles';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { WorkspacesTypes } from '@app/shared/enums/workspaces-types';
import { environment } from '@env/environment';

import { AuthService } from './services/auth.service';
import { extractUrlFromRouterNavigation } from './utils';

@Injectable()
export class AuthGuard {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _isBrowser: boolean;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(PLATFORM_ID) private readonly _platformId: any,
    private readonly _injector: Injector,
    private readonly _membershipService: MembershipService,
    private readonly _router: Router,
    private readonly _workspacesService: WorkspacesService
  ) {
    this._isBrowser = isPlatformBrowser(_platformId);
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._isBrowser ? this._checkLoginAndMembership$(state.url) : of(false);
  }

  canLoad(): Observable<boolean> {
    // ATTENTION: use util function to build url, https://github.com/angular/angular/issues/17359
    return this._isBrowser
      ? // @ts-expect-error TS2345
        this._checkLoginAndMembership$(extractUrlFromRouterNavigation(this._router.getCurrentNavigation()))
      : of(false);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _checkLoginAndMembership$(url: string): Observable<boolean> {
    const authService = this._injector.get(AuthService);

    return combineLatest([
      this._membershipService.membershipSettings$,
      authService.user.RoleId === UserRoles.GUIDE ? this._workspacesService.isTeam$ : of(null),
      authService.user.RoleId === UserRoles.GUIDE ? this._workspacesService.nextWorkspace$ : of(null),
      race(authService.onAuth(), timer(500))
    ]).pipe(
      map(([settings, isTeamWorkspace, nextWorkspace]) => {
        if (!authService.isAuthorized) {
          authService.redirectUrl = url;

          this._injector.get(NotificationsService).error('Auth required', 'Sign In, please');
          this._router.navigate(['/auth/sign-in']);

          return false;
        }

        if (
          authService.user.RoleId === UserRoles.CLIENT &&
          settings.enabledForClients &&
          !authService.userHasActiveMembership
        ) {
          if (settings.subscriptionPage === SubscriptionPage.MembershipWall) {
            this._router.navigate([
              `/${environment.membershipRoute}/${encodeURIComponent(settings.defaultClientPlan)}`
            ]);
          } else if (url === '/client/settings/subscription') {
            return true;
          } else {
            this._router.navigate(['/client/settings/subscription']);
          }

          return false;
        }
        if (
          authService.user.RoleId === UserRoles.GUIDE &&
          settings.enabledForCoaches &&
          !authService.userHasActiveMembership
        ) {
          if ((isTeamWorkspace && !nextWorkspace) || nextWorkspace?.type === WorkspacesTypes.TEAM) {
            const availableUrl = this._workspacesService.isTeamAdmin
              ? `/${environment.guideRoute}/settings/workspace-settings`
              : `/${environment.guideRoute}/settings/account`;
            if (url === availableUrl) {
              return true;
            }
            this._router.navigate([availableUrl]);
            return true;
          }

          if (settings.subscriptionPage === SubscriptionPage.MembershipWall) {
            this._router.navigate([`/${environment.membershipRoute}/${encodeURIComponent(settings.defaultCoachPlan)}`]);
          } else if (url === `/${environment.guideRoute}/settings/subscription`) {
            return true;
          } else {
            setTimeout(() => {
              this._router.navigate([`/${environment.guideRoute}/settings/subscription`]);
            });
          }

          return false;
        }

        return true;
      }),
      take(1)
    );
  }
}
