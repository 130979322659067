import { NgModule } from '@angular/core';
import { UploadImageModalComponent } from './upload-image-modal.component';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { PuiButtonModule, PuiIconModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { TranslocoModule } from '@jsverse/transloco';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [UploadImageModalComponent],
  imports: [ImageCropperComponent, PuiButtonModule, TranslocoModule, CommonModule, PuiIconModule, PuiTypographyModule],
  exports: [UploadImageModalComponent]
})
export class UploadImageModalModule {}
