import { NotificationsService } from '@awarenow/profi-ui-core';
import { Observable } from 'rxjs';

import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RETURN_AFTER_SAVE_PARAM } from '@app/modules/new-service-button/components/service-selector/service-selector.component';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { PUI_IS_MOBILE } from '@awarenow/profi-ui-core';

@Component({
  selector: 'app-guide-service-editor-header',
  templateUrl: './guide-service-editor-header.component.html',
  styleUrls: ['./guide-service-editor-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideServiceEditorHeaderComponent {
  @Input() type!: GuideServiceTypes;

  @Input() title!: string;

  @Input() link?: string;

  @Output() closeEditor = new EventEmitter();

  readonly GuideServiceTypes = GuideServiceTypes;

  constructor(
    private notifications: NotificationsService,
    private readonly route: ActivatedRoute,
    private location: Location,
    @Inject(PUI_IS_MOBILE) readonly isMobile$: Observable<boolean>
  ) {}

  onCopied(): void {
    const title = `Copied`;
    this.notifications.success(title);
  }

  onCopyFailed(): void {
    const title = `Copy failed`;
    this.notifications.error(title);
  }

  close(): void {
    const { [RETURN_AFTER_SAVE_PARAM]: shouldReturnBack = false } = this.route.snapshot.queryParams;

    if (shouldReturnBack) {
      this.locationBack();
    } else {
      this.closeEditor.emit();
    }
  }

  private locationBack(): void {
    this.location.back();
  }
}
