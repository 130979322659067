import { DateTime } from 'luxon';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { Injectable, OnDestroy } from '@angular/core';
import { GuideOfferApiService, ITimeRangesRequestOptions } from '@app/core/session/guide-offer-api.service';
import { GuideEventActionTypes } from '@app/core/shared-event-actions/types';
import { ISessionDuration, ISessionTimeFrame } from '@app/modules/book-session/services/types';
import { ServiceSchedulingService } from '@app/modules/service-scheduling/services/service-scheduling.service';
import {
  EventActionRangeModalComponent,
  EventActionRangeModalType,
  EventActionRangeType
} from '@app/screens/guide/guide-sessions/components/modals/event-action-range-modal/event-action-range-modal.component';
import { SessionsBlockedModalComponent } from '@app/screens/guide/guide-sessions/components/modals/sessions-blocked-modal/sessions-blocked-modal.component';
import { GuideEventRecordingsService } from '@app/screens/guide/guide-sessions/services/events/event-recordings-service';
import { Recording } from '@app/screens/guide/guide-sessions/types/recording';
import { SessionTypes } from '@app/shared/enums/session-types';
import { isSimpleSession, Session } from '@app/shared/interfaces/session';
import { generateTimezoneOptions } from '@app/shared/utils/generate-timezones';
import { modalResultToObservable$ } from '@app/shared/utils/modal-result-to-observable';
import { PuiDialogService } from '@awarenow/profi-ui-core';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GuideAvailabilityCalculatorService } from './guide-availability-calculator.service';
import { GuideServiceScheduleApiService } from './guide-service-schedule-api.service';
import { GuideSessionsService } from './guide-sessions.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ICalendarEventDraft {
  clientId?: number;
  templateId?: number;
  date?: string;
  duration?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recurrence?: any;
}
type CancelType = 'cancel' | 'archive' | 'cancel_and_archive';

@Injectable()
export class GuideCalendarEventService implements OnDestroy {
  readonly guideRoute = environment.guideRoute;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _timezones: { name: string; value: string }[] | null = null;

  private destroy$ = new Subject<void>();

  get timezones(): { name: string; value: string }[] {
    if (!this._timezones) {
      this._timezones = generateTimezoneOptions();
    }

    return this._timezones;
  }

  constructor(
    private readonly serviceSchedulingService: ServiceSchedulingService,
    private readonly _availabilityCalculator: GuideAvailabilityCalculatorService,
    private readonly _guideSessions: GuideSessionsService,
    private readonly _modal: NgbModal,
    private readonly _offersApi: GuideOfferApiService,
    private readonly _schedulesApi: GuideServiceScheduleApiService,
    private readonly dialogService: PuiDialogService,
    private readonly guideEventRecordingsService: GuideEventRecordingsService
  ) {
    _availabilityCalculator.roundRangeStart = (dateTime: DateTime) => {
      if (dateTime.minute === 0 || dateTime.minute === 30) {
        return dateTime;
      }

      if (dateTime.minute < 30) {
        return dateTime.set({ minute: 30 });
      }

      return dateTime.set({ minute: 0 }).plus({ hour: 1 });
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _availabilityCalculator.roundTimeFrameStart = duration => 30;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  addCalendarEventAtTime$(start: Date, end: Date) {
    return this.addCalendarEvent$({
      date: start.toISOString(),
      duration: end.valueOf() - start.valueOf()
    });
  }

  addServiceSchedule(serviceId?: number): void {
    this.addServiceSchedule$(serviceId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        if (response?.eventsIds.blockedEvents.length > 0) {
          this.dialogService.open(SessionsBlockedModalComponent, {
            data: {
              sessions: response?.eventsIds.blockedEvents,
              timezone: response?.eventsIds.timezone
            },
            size: 's'
          });
        }
      });
  }

  // @ts-expect-error TS7008
  addServiceSchedule$(serviceId?: number, fromEditor = false): Observable<{ eventsIds } | null> {
    return this.addCalendarEvent$({ templateId: serviceId }, fromEditor ? { fromEditor } : null);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cancelEvent$(session: Session, type: CancelType, isRecurring?: boolean, showResult?: boolean): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cancellationArgs: any = { type };
    const numOfParticipants = isSimpleSession(session)
      ? 1
      : (type !== 'archive' && session.sessions && session.sessions.length) || 0;

    const numOfParticipants$ = isRecurring
      ? this.determineEventActionRangeType$('cancel').pipe(
          switchMap((eventActionRangeType: EventActionRangeType) => {
            let startFrom = null;

            if (eventActionRangeType === 'all') {
              cancellationArgs.rule = 'all';
            } else if (eventActionRangeType === 'following') {
              cancellationArgs.rule = 'since';
              startFrom = session.dateStart;
            }

            if (type === 'archive') {
              return of(0);
            }

            if (eventActionRangeType === 'current') {
              return of(numOfParticipants);
            }

            return this._guideSessions.futureSessions$.pipe(
              take(1),
              map(sessions =>
                sessions.filter(
                  // eslint-disable-next-line id-length
                  s =>
                    s.session.scheduleId === session.scheduleId &&
                    (startFrom ? DateTime.fromISO(s.session.dateStart) >= DateTime.fromISO(startFrom) : true)
                )
              ),
              // eslint-disable-next-line id-length
              map(sessions => [...new Set(sessions.map(s => s.client.id))].length)
            );
          })
        )
      : of(numOfParticipants);

    return numOfParticipants$.pipe(
      switchMap(num => {
        if (num > 0) {
          const hideDate = cancellationArgs.rule && cancellationArgs.rule !== 'current';
          const sessionType =
            session.serviceType === SessionTypes.GROUP_INSTANCE || session.serviceType === SessionTypes.GROUP_SESSION
              ? SessionTypes.GROUP_SESSION
              : SessionTypes.SESSION;
          return this._guideSessions.confirmSessionCancellation$(session, sessionType, num, hideDate);
        }
        return of('');
      }),
      switchMap(reason => {
        return this._schedulesApi.cancelEvent$(session.eventId, cancellationArgs, reason);
      }),
      tap(() => {
        if (showResult) {
          this._guideSessions.showSessionActionResult(
            this._guideSessions.createGuideSessionActionResult(GuideEventActionTypes.CANCEL, session)
          );
        }
      })
    );
  }

  private addCalendarEvent$(
    value?: ICalendarEventDraft,
    options?: { fromEditor?: boolean; isReschedule?: boolean } | null
  ): Observable<{
    eventsIds: { blockedEvents: { start: string; end: string; type: string }[]; timezone: string };
  } | null> {
    return this.createCalendarEvent$(value, options);
  }

  private createCalendarEvent$(
    value?: ICalendarEventDraft,
    _options?: { fromEditor?: boolean; isReschedule?: boolean } | null
  ): Observable<null> {
    this.serviceSchedulingService.scheduleSession({
      predefinedData: {
        date: DateTime.fromISO(value?.date as string).toJSDate(),
        time: value?.date as string
      },
      filters: {
        serviceTypes: [],
        serviceIds: value?.templateId ? [value?.templateId] : []
      },
      clientsIds: value?.clientId ? [value?.clientId] : []
    });
    return of(null);
  }

  private determineEventActionRangeType$(
    modalType: EventActionRangeModalType,
    currentModeOptions?: {
      hideCurrent?: boolean;
      showOnlyCurrentMode?: boolean;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    const { componentInstance, result } = this._modal.open(EventActionRangeModalComponent, {
      windowClass: 'recurrence-type-modal'
    });

    componentInstance.modalType = modalType;

    if (currentModeOptions && currentModeOptions.showOnlyCurrentMode != null) {
      componentInstance.showCurrentOnly = currentModeOptions.showOnlyCurrentMode;
    } else if (currentModeOptions && currentModeOptions.hideCurrent != null) {
      componentInstance.hideCurrent = currentModeOptions.hideCurrent;
    }

    return modalResultToObservable$(result);
  }

  getFreeTimeRanges$(date: string, options: ITimeRangesRequestOptions): Observable<ISessionTimeFrame[]> {
    return this._offersApi.getFreeTimeRanges$(date, { ...options }).pipe(
      catchError(() => of([])),
      map(freeTimeRanges => {
        const selectedDateTime = DateTime.fromISO(date, { setZone: true });
        const now = DateTime.local();

        const timezone = options.timezone || now.zoneName;
        const { day, month, year } = selectedDateTime;

        this._availabilityCalculator.updateScheduleTz({ events: freeTimeRanges }, timezone, true);
        // NOTE: updateSessionDurations required
        this._availabilityCalculator.updateSessionDurations({ day, month, year, zone: timezone });
        this._availabilityCalculator.updateSessionTimeFrames({
          value: options.duration
        } as ISessionDuration);

        return this._availabilityCalculator.sessionTimeFrames;
      })
    );
  }

  getEventRecordings$(eventId: number): Observable<Recording[]> {
    return this.guideEventRecordingsService.getRecordings$(eventId);
  }
}
