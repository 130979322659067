import { NotificationsService } from '@awarenow/profi-ui-core';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  NgZone,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { InternalEvents } from '@app/core/analytics/types';
import { AuthService } from '@app/core/auth/services/auth.service';
import { BrandingService } from '@app/core/branding/branding.service';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { LocaleService } from '@app/core/locale/locale.service';
import { ProfileService } from '@app/core/public/profile.service';
import { SessionsService } from '@app/core/session/sessions.service';
import { UserService } from '@app/core/users/user.service';
import { AuthModalComponent } from '@app/modules/auth/components/auth-modal/auth-modal.component';
import { BookingService } from '@app/modules/book-service/services/booking.service';
import { ClientProgramsService } from '@app/modules/client-programs/services/client-programs.service';
import { BlogReaderService } from '@app/screens/blog/services/blog-reader.service';
import { IArticleSelection, IPersonalBlog } from '@app/screens/blog/types';
import { ClientSessionFeedbackComponent } from '@app/shared/components/client-session-feedback/client-session-feedback.component';
import { UserRoles } from '@app/shared/enums/user-roles';
import { IProfileInfo, IUser, User } from '@app/shared/interfaces/user';
import { buildDirectChatLinkId } from '@app/shared/utils/direct-chat-user-id-builder';
import { PuiDestroyService } from '@awarenow/profi-ui-core';
import { GlobalConfig } from '@cnf/types';
import { MetaTagService } from '@libs/services/meta-tag/meta-tag.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

export interface PublicProfile {
  id: number;
  workspaceId: number;
  workspaces: { id: number; namedUrl: string }[];
  firstName: string;
  lastName: string;
  photo: string | null;
  fullPhoto: string | null;
  videoUrl?: string | null;
  reviewsCount: number | null;
  shortQuote: string | null;
  reviews: PublicClientReview[];
  hasMoreReviews?: boolean;
  tags: {
    isPromo: boolean;
  }[];
  widget: PublicGuideWidget;
  preview: unknown;
  freeAllSessions: boolean;
  freeFirstSession: boolean;
  hidePricePerHour: boolean;
}

export interface PublicClientReview {
  client?: {
    name: string | null;
    photo: string | null;
  };
  clientMark: number;
  clientComment: string | null;
}

export interface PublicGuideWidget {
  title: string;
  note: string;
  content: string;
  icon: string;
  link?: {
    url: string;
    text: string;
  };
}

const getFirstTeamWorkspace = (workspaces: { id: number; namedUrl: string }[]): { id: number; namedUrl: string } => {
  if (workspaces.length > 1) {
    return workspaces[1];
  }

  return workspaces[0];
};

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-guide-public-profile',
  templateUrl: './guide-public-profile.component.html',
  styleUrls: ['./guide-public-profile.component.scss'],
  providers: [PuiDestroyService, MetaTagService]
})
export class GuidePublicProfileComponent implements OnInit, AfterViewInit {
  private namedUrlOrId!: string;

  private openWriteReview = false;

  mobile = false;

  profile: PublicProfile | null = null;

  reviewReadySessionId: number | null = null;

  hadSessionBefore = false;

  isBrowser: boolean;

  baseUrl: string;

  shouldScrollToReviews = false;

  bookVisible = true;

  blog: IPersonalBlog | null = null;

  config = {
    meta: {
      metaKeywordsGuideProfilePage: null,
      metaTitleGuideProfilePage: null,
      metaDescriptionGuideProfilePage: null,
      metaImageGuideProfilePage: null
    }
  };

  @ViewChild('bookButton', { static: false, read: ElementRef })
  bookButton!: ElementRef;

  @ViewChild('bookDown', { static: false })
  bookDown!: ElementRef;

  get canBook(): boolean {
    return (this.profile && (!this.authService.isAuthorized || this.profile.id !== this.authService.user.id)) || false;
  }

  get canSendMessage(): boolean {
    if (!this.profile) {
      return false;
    }

    return !this.authService.isAuthorized || (this.profile && this.profile.id !== this.authService.user.id);
  }

  get isGuide(): boolean {
    if (!this.profile) {
      return false;
    }

    return (
      this.authService.isAuthorized &&
      this.authService.user.RoleId === UserRoles.GUIDE &&
      this.authService.user.id === this.profile.id
    );
  }

  get isPromoting(): boolean {
    return (
      (this.profile && this.profile.tags && this.profile.tags.length && this.profile.tags.some(tag => tag.isPromo)) ||
      false
    );
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private profileService: ProfileService,
    private sessionsService: SessionsService,
    private modal: NgbModal,
    private notifications: NotificationsService,
    private blogReaderService: BlogReaderService,
    private title: Title,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private analyticsService: AnalyticsService,
    private brandingService: BrandingService,
    private zone: NgZone,
    private localeService: LocaleService,
    private clientProgramsService: ClientProgramsService,
    @Inject(GLOBAL_WINDOW) private browserWindow: Window,
    private readonly user$: UserService,
    private bookingService: BookingService,
    private readonly destroy$: PuiDestroyService,
    private metaTagService: MetaTagService,
    private readonly runtimeConfigService: RuntimeConfigService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.baseUrl = this.localeService.getLocale().baseUrl;
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      this.checkIfCanLeaveReview(this.openWriteReview);
    }

    this.route.fragment
      .pipe(
        filter(frag => !!frag && frag === 'reviews'),
        takeUntil(this.destroy$)
      )
      .subscribe(() => (this.shouldScrollToReviews = true));

    this.user$
      .pipe(
        filter<IUser>(user => !user || user.RoleId !== UserRoles.ADMIN),
        take(1),
        tap(() => {
          this.getUserProfileUrl();
        }),
        tap(() => {
          this.blogReaderService
            .getUserBlogArticles$(this.namedUrlOrId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(i => (this.blog = i));
        }),
        switchMap(() => {
          return this.getPublicProfile$();
        }),
        switchMap(() => {
          return this.brandingService.globalConfig$;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(config => {
        this.setConfig(config);
        this.setGuideTitle();
        this.setOGMeta();

        if (this.isBrowser) {
          this.checkIfCanLeaveReview(this.openWriteReview);
        }

        this.hadSessionBefore = false;
      });
  }

  ngAfterViewInit(): void {
    this.onScroll();
  }

  @HostListener('window:scroll')
  onScroll(): void {
    if (!this.isBrowser) {
      return;
    }
    this.mobile = this.document.body.clientWidth < 992;
    if (!this.authService.isAuthorized) {
      return;
    }
    if (!this.canBook || !this.bookButton) {
      return;
    }
    const rect = this.bookButton.nativeElement.getBoundingClientRect();
    const expandButton = this.document.querySelector('#expand-button') as HTMLElement;
    if (rect.top + rect.height < 0 || this.mobile) {
      this.bookVisible = false;
      this.document.body.style.paddingBottom = `${this.bookDown.nativeElement.offsetHeight}px`;
      if (expandButton) {
        expandButton.style.bottom = `${this.bookDown.nativeElement.offsetHeight + 35}px`;
      }
    } else {
      this.bookVisible = true;
      this.document.body.style.paddingBottom = '0';
      if (expandButton) {
        expandButton.style.bottom = '';
      }
    }
  }

  @HostListener('window:resize')
  onResize(): void {
    if (!this.isBrowser) {
      return;
    }
    this.onScroll();
  }

  book(): void {
    if (this.profile) {
      if (this.runtimeConfigService.get('teamWorkspaceAsDefault')) {
        const workspace = getFirstTeamWorkspace(this.profile.workspaces);
        const url = `${this.baseUrl}/book/t/${workspace.namedUrl}`;

        this.browserWindow.open(url, '_blank');
      } else {
        this.bookingService.guideIndividualPage(this.profile.id, this.profile.workspaceId);
      }
    }
  }

  callSendMessage(id: number, workspaceId?: number | undefined): void {
    const link = `/client/chat/${buildDirectChatLinkId({ id, workspaceId })}`;

    if (!this.authService.isAuthorized) {
      const ref = this.modal.open(AuthModalComponent, { windowClass: 'auth-modal' });
      ref.componentInstance.afterSignIn.subscribe(() =>
        this.router.navigate([link]).then(() => {
          // In a situation where an unauthorized user opens the site root "PrimaryLayout",
          // the main layout module does not get the user role. In this case, we need to reload the page.
          // We need to figure out how to make the loading of the application sequential.
          // Authentication first, then loading the main application.
          this.browserWindow.location?.reload();
        })
      );
    } else {
      this.router.navigate([link]).then();
    }
  }

  openBlogItem(event: IArticleSelection): void {
    this.router.navigate(['/blog', this.namedUrlOrId, 'posts', event.articleId]);
  }

  leaveReview(reviewReadySessionId: number): void {
    if (this.profile) {
      const sessionLikeObject = {
        id: reviewReadySessionId,
        user: new User(
          this.profile.id,
          this.profile.firstName,
          this.profile.lastName,
          this.profile.photo ? this.profile.photo : undefined
        )
      };
      this.showFeedbackForm(sessionLikeObject);
    }
  }

  loadMoreReviews(): void {
    this.profileService
      .getReviews(this.namedUrlOrId)
      .pipe(
        map(response => response.reviews),
        takeUntil(this.destroy$)
      )
      .subscribe((reviews: PublicClientReview[]) => {
        if (this.profile) {
          this.profile = { ...this.profile, reviews, hasMoreReviews: false };
        }
      });
  }

  private checkIfCanLeaveReview(open?: boolean): void {
    if (this.authService.isAuthorized && this.authService.user.RoleId === UserRoles.CLIENT) {
      this.sessionsService
        .getFeedbackReadySession$(this.namedUrlOrId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(sessionId => {
          this.reviewReadySessionId = sessionId;
          if (open && this.reviewReadySessionId) {
            this.leaveReview(this.reviewReadySessionId);
          }
        });
    }
  }

  private getPublicProfile$(): Observable<void | IProfileInfo> {
    return this.profileService.getByIdOrNamedUrl({ guideId: this.namedUrlOrId }).pipe(
      tap(data => this.analyticsService.event(InternalEvents.GUIDE_PROFILE, { profile: data.profile })),
      tap(({ profile }) => this.renderProfile(profile as PublicProfile)),
      catchError(err => {
        this.router.navigate(['/not-found'], { replaceUrl: true }).then();
        return throwError(err);
      })
    );
  }

  private getUserProfileUrl(): void {
    const url = this.router.url.substr(1);

    if (url.includes('/write-review')) {
      this.namedUrlOrId = url.split('/')[0];
      this.openWriteReview = true;
      return;
    }

    if (url.includes('/')) {
      this.router.navigate(['/not-found']).then();
      return;
    }

    this.namedUrlOrId = url.split('#')[0];
  }

  private renderProfile(profile: PublicProfile): void {
    if (!profile) {
      this.router.navigate(['/not-found'], { replaceUrl: true }).then();
    }

    if (!profile.photo) {
      profile.photo = '';
    }

    if (!profile.videoUrl || profile.videoUrl.substr(0, 5) !== 'https') {
      delete profile.videoUrl;
    }

    if (profile.reviewsCount && profile.reviews) {
      profile.hasMoreReviews = profile.reviewsCount !== profile.reviews.length;
    }

    this.profile = profile;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private showFeedbackForm(session: any): void {
    const { componentInstance, result } = this.modal.open(ClientSessionFeedbackComponent, {
      keyboard: false,
      centered: true,
      backdrop: 'static',
      windowClass: `session-feedback-modal`
    });

    componentInstance.session = session;
    result
      .then(({ sessionId, mark, comment, privateComment }) => {
        this.sessionsService
          .sendFeedback$(sessionId, mark, comment, privateComment)
          .pipe(
            switchMap(() => {
              return this.getPublicProfile$();
            }),
            takeUntil(this.destroy$)
          )
          .subscribe(() => {
            this.reviewReadySessionId = null;
          });
      })
      .catch(() => {});
  }

  private setGuideTitle(): void {
    const titlePrefix = this.config.meta.metaTitleGuideProfilePage
      ? `${this.config.meta.metaTitleGuideProfilePage} - `
      : '';

    if (this.profile) {
      this.title.setTitle(`${titlePrefix}${this.profile.firstName} ${this.profile.lastName}`);
    }
  }

  private setOGMeta(): void {
    if (!this.profile) {
      return;
    }

    const titlePrefix = this.config.meta.metaTitleGuideProfilePage
      ? `${this.config.meta.metaTitleGuideProfilePage} `
      : '';
    const title = `${titlePrefix}${this.profile.firstName} ${this.profile.lastName}`;

    this.metaTagService.upsertMetaTags({
      title,
      description: this.profile.shortQuote || this.config.meta.metaDescriptionGuideProfilePage || '',
      image: this.profile.fullPhoto || this.config.meta.metaImageGuideProfilePage || '',
      url: `${this.baseUrl}/${this.namedUrlOrId}`,
      type: 'profile',
      keywords: [
        ...(this.config.meta.metaKeywordsGuideProfilePage ? [this.config.meta.metaKeywordsGuideProfilePage] : []),
        // @ts-expect-error TS7006
        ...(this.profile.tags && this.profile.tags.length ? this.profile.tags.map(tag => tag.name) : [])
      ].join(', ')
    });
  }

  private setConfig({
    metaKeywordsGuideProfilePage,
    metaTitleGuideProfilePage,
    metaDescriptionGuideProfilePage,
    metaImageGuideProfilePage
  }: GlobalConfig): void {
    // @ts-expect-error TS2322
    this.config.meta.metaKeywordsGuideProfilePage = metaKeywordsGuideProfilePage;
    // @ts-expect-error TS2322
    this.config.meta.metaTitleGuideProfilePage = metaTitleGuideProfilePage;
    // @ts-expect-error TS2322
    this.config.meta.metaDescriptionGuideProfilePage = metaDescriptionGuideProfilePage;
    // @ts-expect-error TS2322
    this.config.meta.metaImageGuideProfilePage = metaImageGuideProfilePage;
  }
}
