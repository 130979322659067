import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GUIDE_SCHEDULES_ENDPOINT } from '@app/shared/constants/endpoints';
import { CustomUrlQueryEncoder } from '@app/shared/utils/custom-url-query-encoder';

export interface Availability {
  [date: string]: unknown;
}

export interface GetAvailabilityRequest {
  startTime: string;
  endTime: string;
  eventTypeId: number;
  hostId?: number;
  workspaceId?: number;
  timeZone: string;
  extended?: boolean;
}

export interface GetAvailabilityResponse {
  slots: Availability;
}

@Injectable()
export abstract class AvailabilityService {
  abstract getAvailability<RequestData extends GetAvailabilityRequest>(
    data: RequestData
  ): Observable<GetAvailabilityResponse>;
}

@Injectable({ providedIn: 'root' })
export class GuideAvailabilityApiService implements AvailabilityService {
  constructor(private httpClient: HttpClient) {}

  getAvailability({ hostId, ...data }: GetAvailabilityRequest): Observable<GetAvailabilityResponse> {
    return this.httpClient.get<GetAvailabilityResponse>(`${GUIDE_SCHEDULES_ENDPOINT}/free-time`, {
      params: new HttpParams({
        encoder: new CustomUrlQueryEncoder(),
        fromObject: hostId ? { ...data, hostId } : data
      })
    });
  }
}

@Injectable({ providedIn: 'root' })
export class PublicGuideAvailabilityService implements AvailabilityService {
  constructor(private httpClient: HttpClient) {}

  /**
   * @deprecated Not ready
   */
  getAvailability(data: GetAvailabilityRequest): Observable<GetAvailabilityResponse> {
    return this.httpClient.get<GetAvailabilityResponse>('', {
      params: new HttpParams({
        encoder: new CustomUrlQueryEncoder(),
        fromObject: { ...data }
      })
    });
  }
}
