<div>
  <!--HEADING-->
  <div class="heading pui-space_bottom-8">
    <h1 pui-typography="h1" i18n="@@signInTfaAppTitle">Two-factor authentication</h1>
  </div>
  <!--BODY-->
  <div class="auth-form">
    <div>
      <div class="pui-space_bottom-5">
        <p i18n="@@signInTfaBackupSubTitle" pui-typography="body-s">
          Enter your backup code here to securely access your account
        </p>
      </div>

      <pui-otp-input
        class="pui-otp-input_center"
        [formControl]="otpCodeControl"
        numOfDigits="8"
        puiPlaceholder="00000000"></pui-otp-input>

      <span
        *ngIf="showInvalidCodeError$ | async"
        class="invalid-code-error"
        pui-typography="body-s"
        i18n="@@signInTfaInvalidCode"
        >Invalid code provided. Please try again
      </span>
    </div>
  </div>
  <!--FOOTER-->
  <div class="pui-space_top-8 backup-code">
    <p pui-typography="body-s">
      <span i18n="@@signInTfaDontHaveBackupCode">Don’t have your backup codes?</span>
      <br />
      <span i18n="@@contactus"> Contact us </span>
      <a pui-typography="body-s" class="underlined" href="mailto:help@profi.io">help&#64;profi.io</a>
    </p>
  </div>
</div>
