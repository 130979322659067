import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { BlogReaderService } from './blog-reader.service';
import { blogItemsFactory, IBlog, IPersonalBlog, RecommendedArticle } from '@app/screens/blog/types';
import {
  ALL_BUSINESS_BLOG_ARTICLES_URL,
  BUSINESS_BLOG_ARTICLE_RELATED_ARTICLES_URL,
  LATEST_BUSINESS_ARTICLES_URL,
  USER_BUSINESS_BLOG_ARTICLES_URL
} from '@app/screens/blog/endpoints';

@Injectable()
export class BusinessBlogReaderService extends BlogReaderService {
  constructor(private _httpClient: HttpClient, private _notificationsService: NotificationsService) {
    super(_httpClient, _notificationsService);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getAllBlogsArticles$(params: any = {}): Observable<IBlog> {
    const httpParams = new HttpParams({ fromObject: params });

    return this.load(ALL_BUSINESS_BLOG_ARTICLES_URL, httpParams, this.mapBlog, 'Cannot load blog content');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getBlogArticleRelatedArticles$(): Observable<any> {
    // @ts-expect-error TS7006
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const mapper = response => response.articles.map(article => new RecommendedArticle(article));

    return this.load(
      BUSINESS_BLOG_ARTICLE_RELATED_ARTICLES_URL,
      new HttpParams(),
      mapper,
      'Cannot load related articles'
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getLatestArticles$(): Observable<any> {
    // @ts-expect-error TS7006
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const mapper = response => blogItemsFactory(response.articles);

    return this.load(LATEST_BUSINESS_ARTICLES_URL, new HttpParams(), mapper, 'Cannot load latest articles');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getUserBlogArticles$(userName: string, params: any = {}): Observable<IPersonalBlog> {
    const httpParams = new HttpParams({ fromObject: params });

    return this.load(
      `${USER_BUSINESS_BLOG_ARTICLES_URL}/${userName}`,
      httpParams,
      this.mapPersonalBlog,
      'Cannot load blog content'
    );
  }
}
