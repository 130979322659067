import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Input } from '@angular/core';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { Subject } from 'rxjs';
import { environment } from '@env/environment';
import { filter, finalize, takeUntil } from 'rxjs/operators';

import { AnalyticCopyTypes, AnalyticSourceTypes, InternalEvents } from '@app/core/analytics/types';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { BookShareInfoService } from '@app/modules/embeddable-book-button/services/book-share-info.service';
import { GuideProfileTypes } from '@app/shared/enums/guide-profile-types';

import { ProfileTypeService } from './profile-type.service';

@Component({
  selector: 'app-authorised-guide-info',
  templateUrl: './authorised-guide-info.component.html',
  styleUrls: ['./authorised-guide-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BookShareInfoService]
})
export class AuthorisedGuideInfoComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isUpdating = false;

  guideRoute = environment.guideRoute;

  // @ts-expect-error TS2564
  userProfileType: GuideProfileTypes;

  @Input()
  hideEditAction = false;

  get isUpdating(): boolean {
    return this._isUpdating;
  }

  get profileLink(): string {
    return this._bookShare.profileLink;
  }

  constructor(
    public profileTypeService: ProfileTypeService,
    private _bookShare: BookShareInfoService,
    private _notifications: NotificationsService,
    private _analyticsService: AnalyticsService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.profileTypeService.userProfile$
      .pipe(
        filter(userProfile => !!userProfile),
        takeUntil(this.destroy$)
      )
      .subscribe(userProfile => {
        this.userProfileType = userProfile.profileType;
        this._changeDetectorRef.markForCheck();
      });
    this.profileTypeService.refreshTypes();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // @ts-expect-error TS7006
  changeProfileType(profileTypeObject): void {
    this._isUpdating = true;

    this.profileTypeService
      .updateOwnType$(profileTypeObject.type)
      .pipe(
        finalize(() => {
          this._isUpdating = false;
          this._changeDetectorRef.markForCheck();
        })
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe();
  }

  onCopied(event: AnalyticCopyTypes): void {
    this._analyticsService.event(InternalEvents.LINK_COPY, {
      copy_type: event,
      link_type: AnalyticSourceTypes.PROFILE
    });
    this._notifications.success(`Copied`);
  }

  onCopyFailed(): void {
    this._notifications.error(`Copy failed`);
  }
}
