import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { throwError, Subject } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { AuthService, PublicUserInfo } from '@app/core/auth/services/auth.service';
import { FormService } from '@app/core';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { AlternativeAuthProvidersService } from '../../services/alternative-auth-providers.service';
import { EMAIL_REGEXP } from '@app/shared/constants';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['../../../../screens/auth/auth.scss', '../../styles/auth.scss', './welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  @Input() onlyClient!: boolean;
  @Input() onlyGuide!: boolean;
  @Input() showSSOWelcome!: boolean;
  @Input() showAuthMethodsSeparator!: boolean;

  @Output()
  afterUserChecked = new EventEmitter<{
    email: string;
    remember: boolean;
    user: PublicUserInfo | null;
    showSSOSignIn?: boolean;
  }>();

  @Output()
  signUpWithAlternativeProvider = new EventEmitter();

  @Output()
  afterSignIn = new EventEmitter();

  @Output()
  signInCanNot = new EventEmitter();

  signInForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.pattern(EMAIL_REGEXP)]),
    remember: new UntypedFormControl(true, [])
  });

  isFetching = false;
  remember = true;

  teamInvitationCode = '';

  get showContinueButton(): boolean {
    return !(this.isGuideSSOEnabled && this.isClientSSOEnabled && this.authService.workspaceMemberSignUpInfo);
  }

  private isGuideSSOEnabled = false;
  private isClientSSOEnabled = false;

  constructor(
    private readonly authService: AuthService,
    private readonly formService: FormService,
    private readonly runtimeConfigService: RuntimeConfigService,
    private readonly authProvider: AlternativeAuthProvidersService
  ) {}

  ngOnInit(): void {
    this.isGuideSSOEnabled = this.runtimeConfigService.get('isGuideSSOEnabled') || false;
    this.isClientSSOEnabled = this.runtimeConfigService.get('isClientSSOEnabled') || false;

    if (this.authService.workspaceMemberSignUpInfo) {
      this.onlyClient = false;
      this.onlyGuide = true;
      this.teamInvitationCode = this.authService.workspaceMemberSignUpInfo.invitationCode;

      this.signInForm.get('email')?.setValue(this.authService.workspaceMemberSignUpInfo.email);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkUserExist(): void {
    if (this.formService.markInvalidForm(this.signInForm) || this.isFetching) {
      return;
    }
    Object.keys(this.signInForm.controls).forEach(field => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const control = this.signInForm.get(field);

      control?.markAsTouched({ onlySelf: true });
    });

    const { email: rawEmail, remember } = this.signInForm.value;
    this.isFetching = true;
    const email = rawEmail.toLowerCase();

    this.authService
      .checkUserExist(email)
      .pipe(
        // eslint-disable-next-line id-length
        catchError(e => {
          return throwError(e);
        }),
        takeUntil(this.destroy$),
        finalize(() => (this.isFetching = false))
      )
      .subscribe(user => {
        const isGuideSSOEnabled = this.runtimeConfigService.get('isGuideSSOEnabled') || false;
        const isClientSSOEnabled = this.runtimeConfigService.get('isClientSSOEnabled') || false;

        if (
          !user &&
          ((isClientSSOEnabled && isGuideSSOEnabled) ||
            (this.onlyGuide && isGuideSSOEnabled) ||
            (this.onlyClient && isClientSSOEnabled))
        ) {
          return this.afterUserChecked.emit({
            showSSOSignIn: true,
            email,
            user,
            remember
          });
        }

        if ((isClientSSOEnabled || isGuideSSOEnabled) && user?.isSsoAuth) {
          return this.authProvider.openSSOAuthPopup(
            this.remember,
            this.onlyClient,
            this.onlyGuide,
            this.teamInvitationCode
          );
        }

        this.afterUserChecked.emit({
          email,
          remember,
          user
        });
      });
  }
}
