import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-quiz-questions-preview-modal',
  templateUrl: './quiz-questions-preview-modal.component.html',
  styleUrls: ['./quiz-questions-preview-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuizQuestionsPreviewModalComponent {
  @Input()
  form = new UntypedFormGroup({
    questions: new UntypedFormControl()
  });

  constructor(private activeModal: NgbActiveModal) {}

  dismiss(): void {
    this.activeModal.dismiss();
  }
}
