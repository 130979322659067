import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { VideoSession } from '@app/screens/session/types';
import { SessionTypes } from '@app/shared/enums/session-types';
import { PuiDrawerRef } from '@awarenow/profi-ui-core';

@Component({
  selector: 'app-session-widget',
  templateUrl: './session-widget.component.html',
  styleUrls: ['./session-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionWidgetComponent {
  @Input()
  session: VideoSession = this.ref.data.session;

  readonly searchControl = new UntypedFormControl();

  get serviceType(): SessionTypes {
    return this.session.isGroupSession ? SessionTypes.GROUP_SESSION : SessionTypes.SESSION;
  }

  constructor(private readonly ref: PuiDrawerRef<void, { session: VideoSession }>) {}
}
