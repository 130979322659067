// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { MatBadge, MatBadgePosition } from '@angular/material/badge';
import {
  Directive,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Optional,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { AriaDescriber } from '@angular/cdk/a11y';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';
import { MatIconRegistry } from '@angular/material/icon';
import { Subscription } from 'rxjs';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ThemePalette } from '@angular/material/core';

/** Directive to display a text badge. */
@Directive({
  selector: '[uiBadge]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-badge'
  }
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class UiBadge extends MatBadge implements OnDestroy {
  /** The content for the badge */
  @Input('uiBadge')
  get uiContent(): string | number | undefined | null {
    return this.content;
  }

  set uiContent(value: string | number | undefined | null) {
    this.content = value;
  }

  constructor(
    _ngZone: NgZone,
    _elementRef: ElementRef<HTMLElement>,
    _ariaDescriber: AriaDescriber,
    _renderer: Renderer2,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) _animationMode?: string
  ) {
    super(_ngZone, _elementRef, _ariaDescriber, _renderer);
  }
}

/** Directive to display a icon badge. */
@Directive({
  selector: '[uiIconBadge]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-badge-icon',
    '[class.mat-badge-icon-below]': 'true',
    '[class.mat-badge-icon-after]': 'true',
    '[class.ui-badge-icon-hidden]': 'iconHidden || !_hasIcon'
  }
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class UiBadgeIcon extends UiBadge implements OnChanges, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _badgeIconElement: HTMLElement | undefined;
  private renderer: Renderer2;
  private elementRef: ElementRef<HTMLElement>;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _hasIcon: boolean;

  @Input('uiIconBadge') icon: string | undefined | null;

  /** Whether the icon badge is hidden. */
  @Input('uiIconBadgeHidden')
  get iconHidden(): boolean {
    return this._iconHidden;
  }

  set iconHidden(val: boolean) {
    this._iconHidden = coerceBooleanProperty(val);
  }

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _iconHidden: boolean;

  /** The color of the icon badge. Can be `primary`, `accent`, or `warn`. */
  @Input('uiBadgeIconColor')
  get iconColor(): ThemePalette {
    return this._iconColor;
  }

  set iconColor(value: ThemePalette) {
    this._setIconColor(value);
    this._iconColor = value;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _iconColor: ThemePalette;

  iconSubscription: Subscription = Subscription.EMPTY;

  constructor(
    private registry: MatIconRegistry,
    _ngZone: NgZone,
    _elementRef: ElementRef<HTMLElement>,
    _ariaDescriber: AriaDescriber,
    _renderer: Renderer2,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) _animationMode?: string
  ) {
    super(_ngZone, _elementRef, _ariaDescriber, _renderer);
    this.renderer = _renderer;
    this.elementRef = _elementRef;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const iconChange = changes['icon'];

    if (iconChange) {
      const value = iconChange.currentValue;
      const hasIcon = value != null && `${value}`.trim().length > 0;
      this._hasIcon = true;
      if (hasIcon) {
        this._updateIconContent();
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnDestroy() {
    super.ngOnDestroy();
    this.iconSubscription.unsubscribe();

    const badgeIconElement = this._badgeIconElement;

    if (badgeIconElement) {
      // When creating a badge through the Renderer, Angular will keep it in an index.
      // We have to destroy it ourselves, otherwise it'll be retained in memory.
      if (this.renderer.destroyNode) {
        this.renderer.destroyNode(badgeIconElement);
      }
    }
  }

  /**
   * Gets the element into which the badge's content is being rendered.
   * Undefined if the element hasn't been created (e.g. if the badge doesn't have content).
   */
  getBadgeIconElement(): HTMLElement | undefined {
    return this._badgeIconElement;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _updateIconContent(): void {
    if (!this._badgeIconElement) {
      this._badgeIconElement = this._createBadgeIconElement();
    }

    // @ts-expect-error TS2533
    const [first, second] = this.icon.split(':');
    this.iconSubscription.unsubscribe();
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.iconSubscription = this.registry.getNamedSvgIcon(first ?? second, second ?? '').subscribe(icon => {
      // @ts-expect-error TS2532
      this.getBadgeIconElement().append(icon);
    });
  }

  /** Creates the icon badge element */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _createBadgeIconElement(): HTMLElement {
    const badgeIconElement = this.renderer.createElement('span');
    const activeClass = 'mat-badge-active';

    // Clear any existing badges which may have persisted from a server-side render.
    this._clearExistingIconBadges('mat-badge-icon-content');
    badgeIconElement.setAttribute('id', `mat-badge-content-icon-${this._id}`);
    badgeIconElement.classList.add('mat-badge-icon-content', 'mat-elevation-z1');

    this.elementRef.nativeElement.appendChild(badgeIconElement);

    badgeIconElement.classList.add(activeClass);

    return badgeIconElement;
  }

  /** Clears any existing icon badges that might be left over from server-side rendering. */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
  private _clearExistingIconBadges(cssClass: string) {
    const element = this.elementRef.nativeElement;
    let childCount = element.children.length;

    // Use a reverse while, because we'll be removing elements from the list as we're iterating.
    while (childCount--) {
      const currentChild = element.children[childCount];

      if (currentChild.classList.contains(cssClass)) {
        element.removeChild(currentChild);
      }
    }
  }

  /** Adds css theme class given the color to the component host */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
  private _setIconColor(colorPalette: ThemePalette) {
    if (colorPalette !== this._iconColor) {
      const classList = this.elementRef.nativeElement.classList;
      if (this._iconColor) {
        classList.remove(`mat-badge-icon-${this._iconColor}`);
      }
      if (colorPalette) {
        classList.add(`mat-badge-icon-${colorPalette}`);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  static ngAcceptInputType_iconHidden: BooleanInput;
}
