<div class="details">
  <div class="name">{{ source.name }}</div>

  <div class="description">{{ source.description }}</div>
</div>

<div class="buttons">
  <ng-container *ngIf="source.source !== 'google'; else googleSyncButton">
    <button
      pui-button
      data-qa-id="source-connection-invitation-outlook-connect-button"
      (click)="connect.emit()"
      i18n="@@calendarSourceCardConnectButton">
      Connect
    </button>
  </ng-container>

  <ng-template #googleSyncButton>
    <button
      google-button
      (click)="connect.emit()"
      data-qa-id="source-connection-invitation-sign-in-with-google-button"
      i18n-text="@@signInWithGoogle"
      text="Sign in with Google"></button>
  </ng-template>
</div>
