import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UniversalSelectorGroup } from '@app/shared/components/universal-selector/types';

@Component({
  selector: 'app-universal-selector-modal',
  templateUrl: './universal-selector-modal.component.html',
  styleUrls: ['./universal-selector-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UniversalSelectorModalComponent implements OnInit {
  tagsForm!: UntypedFormGroup;
  elements: UniversalSelectorGroup[] = [];
  filtered: UniversalSelectorGroup[] = [];
  selected: number[] = [];

  @Input()
  set selectedElements(ids: number[]) {
    this.selected = ids.map(i => i);
  }

  @Input()
  title = '';

  @Input()
  itemTemplate!: TemplateRef<{ item: unknown; active: boolean }>;

  @Input()
  groupTemplate!: TemplateRef<{ group: unknown }>;

  @Input()
  set all(elements: UniversalSelectorGroup[]) {
    this.elements = elements;
    this.filtered = this.elements;
  }

  @Input()
  limit = 5;

  constructor(private readonly modal: NgbActiveModal, private readonly formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.tagsForm = this.formBuilder.group({
      search: ['', [Validators.required]]
    });
  }

  cancel(): void {
    this.modal.dismiss();
  }

  save(): void {
    this.modal.close({ selected: this.selected });
  }

  select(id: number): void {
    if (!this.selected.includes(id)) {
      if (this.selected.length < this.limit) {
        this.selected.push(id);
      }
    } else {
      this.selected.splice(this.selected.indexOf(id), 1);
    }
  }

  filter(): void {
    const query = this.tagsForm.controls['search'].value.toLowerCase();
    if (query.trim().length === 0) {
      this.filtered = this.elements;
    } else {
      this.filtered = this.elements.map(element => ({
        ...element,
        children: element.children.filter(i => i.name.toLowerCase().includes(query))
      }));
    }
  }
}
