<div class="wrapper">
  <div class="users d-none d-md-block" [class.d-block]="selectedSlot === -1">
    <div class="users-wrap">
      <div class="search">
        <div class="search-control">
          <button class="btn search-btn" (click)="search(s.value)"></button>
          <input
            id="search"
            type="text"
            placeholder="Search"
            i18n-placeholder="@@quizStatsSearch"
            class="form-control search-input"
            autocomplete="off"
            autofill="off"
            #s
            (input)="search(s.value)" />
        </div>
      </div>

      <ng-container *ngIf="users.length">
        <ng-container *ngTemplateOutlet="controls"></ng-container>
        <ng-scrollbar [ngStyle]="{ 'height.px': scrollbarHeight }" [visibility]="'hover'" [appearance]="'compact'">
          <div
            class="user"
            *ngFor="let user of filteredUsers; let i = index"
            [class.active]="selectedUserIndex === i"
            (click)="selectUser(i)">
            <div class="user-info">
              <!--              <app-user-photo class="user-photo" [photoUrl]="user.photo" [size]="28"></app-user-photo>-->
              <!--              TODO Avatar size 28x28 not exist!-->
              <pui-avatar
                class="user-photo app-user-photo"
                [avatarUrl]="user.photo"
                [text]="user.name || user.contacts?.email"
                [style.width]="28"
                [style.height]="28"></pui-avatar>
              {{ user.name || user?.contacts?.email }}
            </div>
            <div class="info"></div>
          </div>
        </ng-scrollbar>
      </ng-container>

      <div *ngIf="!users.length" class="enrolled-clients">
        <ng-container i18n="@@enrolledClients">Enrolled clients</ng-container>
        (0)
      </div>
    </div>

    <ng-container *ngIf="users.length">
      <ng-container *ngTemplateOutlet="controls"></ng-container>
    </ng-container>
  </div>

  <ng-template #controls>
    <div class="controls-wrap">
      <div class="add-new-client">
        <a
          clientsSelect
          #clientsSelectRef="ClientsSelect"
          (click)="clientsSelectRef.open(guideClients)"
          (onClientsSelected)="addClients($event)"
          class="send-client-link"
          i18n="@@sendToClient"
          >Send to client</a
        >
      </div>

      <div class="export-all">
        <app-button className="btn-link btn-export" (click)="exportAll()">
          <ng-container i18n="@@exportAllResponses">Export all responses</ng-container>
          (csv)
        </app-button>
      </div>
    </div>
  </ng-template>

  <div class="info" *ngIf="selectedSlot >= 0 && users.length">
    <div class="header">
      <div class="user-column">
        <div class="back d-md-none" (click)="selectedSlot = -1"></div>
        <div class="user" *ngIf="user">
          <!--          <app-user-photo class="user-photo" [photoUrl]="user.photo" [size]="30"></app-user-photo>-->
          <!--          TODO Avatar size 30x30 not exist!-->
          <pui-avatar
            class="user-photo app-user-photo"
            [avatarUrl]="user.photo"
            [text]="user.name || user.contacts?.email"
            [style.width]="30"
            [style.height]="30"></pui-avatar>
          <span class="user-name" (click)="showClientDetails(user.id)">{{ user.name || user.contacts?.email }}</span>
        </div>
      </div>
      <div class="user-controls">
        <app-button className="btn-send-message-secondary" [routerLink]="'/' + guideRoute + '/chat/' + user?.id">
          <ng-container i18n="@@sendMsgInChat">Send message in chat</ng-container>
        </app-button>
        <app-button className="btn-link btn-export" (click)="export()" i18n="@@exportResponses">
          Export responses
        </app-button>
      </div>
    </div>

    <app-timeline-component
      *ngIf="timelineDates && timelineDates.length"
      [dates]="timelineDates"
      (selectSlot)="dateSelected($event)"></app-timeline-component>

    <div class="current-date" *ngIf="slotsAndAnswers[selectedSlot]">
      {{ slotsAndAnswers[selectedSlot].date | platformDate }}
    </div>

    <ng-container *ngIf="slotsAndAnswers[selectedSlot]">
      <app-module-quiz
        class="no-response-option"
        [value]="slotsAndAnswers[selectedSlot].questions"
        [editableAnswers]="false"
        [isCorrectDisplay]="true"
        [showNoResponseMessage]="
          !slotsAndAnswers[selectedSlot].answers || slotsAndAnswers[selectedSlot].answers.length === 0
        "></app-module-quiz>
    </ng-container>
  </div>

  <div class="info" *ngIf="!users.length">
    <div class="add-clients-block">
      <div class="wrap">
        <div class="image"></div>
        <h2 i18n="@@inviteClientsToForm">Invite clients to the form</h2>
        <button
          pui-button
          clientsSelect
          #clientsSelectRef="ClientsSelect"
          (click)="clientsSelectRef.open(guideClients)"
          (onClientsSelected)="addClients($event)">
          <ng-container i18n="@@inviteClients">Invite clients</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>
