import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ICategorizedTags } from '@app/shared/utils/categorize-tags';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent {
  form = this.fb.group({
    categories: this.fb.array([])
  });

  @Output()
  selectItem = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get categoriesFormArray() {
    return this.form.controls.categories as UntypedFormArray;
  }

  constructor(private fb: UntypedFormBuilder) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getForm() {
    return this.form;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  updateForm(categorizedTags: ICategorizedTags, selectedTagsIds: number[]) {
    this.categoriesFormArray.clear();
    Object.keys(categorizedTags).forEach(categoryId => {
      const category = categorizedTags[categoryId];
      const itemsArray = this.fb.array([]);

      category.items.forEach(({ id, name }) =>
        itemsArray.push(
          this.fb.group({
            id: [id],
            name: [name],
            selected: [selectedTagsIds.includes(id)]
          })
        )
      );

      const categoryGroup = this.fb.group({
        name: [category.name],
        items: itemsArray
      });
      this.categoriesFormArray.push(categoryGroup);
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onChange(itemControl: UntypedFormControl) {
    const { id, selected } = itemControl.value;
    this.categoriesFormArray.controls.forEach((control: UntypedFormGroup) => {
      (control.controls.items as UntypedFormArray).controls.forEach((item: UntypedFormGroup) => {
        if (item.value.id === id) {
          item.patchValue({ selected }, { emitEvent: false });
        }
      });
    });
    this.selectItem.emit(this.getValues());
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getValues() {
    return Object.keys(
      // @ts-expect-error TS7006
      this.form.value.categories.reduce((uniqValues, categoryItem) => {
        const { items: categoryItems } = categoryItem;
        return categoryItems.reduce(
          // @ts-expect-error TS7006
          (accum, { id, selected }) =>
            selected
              ? {
                  ...accum,
                  [id]: true
                }
              : accum,
          uniqValues
        );
      }, {})
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  resetValues() {
    (this.form.controls.categories as UntypedFormArray).controls.forEach((categoryGroup: UntypedFormGroup) => {
      (categoryGroup.controls.items as UntypedFormArray).controls.forEach(control =>
        control.patchValue({ selected: false }, { emitEvent: false })
      );
    });
    this.form.markAsPristine();
  }
}
