import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class TeamWorkspaceAdminGuard {
  canActivate(): Observable<boolean | UrlTree> {
    return this._workspacesService.isTeamAdmin$.pipe(
      take(1),
      map(isTeamAdmin => {
        if (!isTeamAdmin) {
          const title = `Only for workspace Admins`;
          this._notifications.error(title);
          return this._router.parseUrl('/');
        }

        return true;
      })
    );
  }

  canLoad(): Observable<boolean | UrlTree> {
    return this.canActivate();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private _router: Router,
    private _notifications: NotificationsService,
    private _workspacesService: WorkspacesService
  ) {}
}
