import { NotificationsService } from '@awarenow/profi-ui-core';

import { Location } from '@angular/common';
import { Component, Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AuthService } from '@app/core/auth/services/auth.service';
import { BrandingService } from '@app/core/branding/branding.service';
import { LocaleService } from '@app/core/locale/locale.service';
import { IGuideService, IServiceBookingOptions, ServiceBookingService } from '@app/modules/book-service';
import { BookingService } from '@app/modules/book-service/services/booking.service';
import { MetaTagService } from '@libs/services/meta-tag/meta-tag.service';

import { BlogReaderService } from '../../services/blog-reader.service';
import { BusinessBlogReaderService } from '../../services/business-blog-reader.service';
import { DisqusWidgetsService } from '../../services/disqus-widgets.service';
import { EmbedDisqusCountScriptService } from '../../services/embed-disqus-count-script.service';
import { IArticleSelection, IBlogTag } from '../../types';
import { BlogArticleComponent } from '../blog-article/blog-article.component';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-business-blog-article',
  templateUrl: '../blog-article/blog-article.component.html',
  styleUrls: ['../blog-article/blog-article.component.scss'],
  providers: [
    EmbedDisqusCountScriptService,
    DisqusWidgetsService,
    BusinessBlogReaderService,
    MetaTagService,
    {
      provide: BlogReaderService,
      useExisting: BusinessBlogReaderService
    }
  ]
})
export class BusinessBlogArticleComponent extends BlogArticleComponent {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    activatedRoute: ActivatedRoute,
    routerService: Router,
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID) platformIdService,
    titleService: Title,
    authService: AuthService,
    blogService: BusinessBlogReaderService,
    serviceBookingService: ServiceBookingService<IServiceBookingOptions<IGuideService>, void>,
    embedDisqusCountScriptService: EmbedDisqusCountScriptService,
    disqusWidgetsService: DisqusWidgetsService,
    analyticsService: AnalyticsService,
    brandingService: BrandingService,
    locationService: Location,
    zoneService: NgZone,
    localeService: LocaleService,
    notificationsService: NotificationsService,
    bookingService: BookingService,
    metaTagService: MetaTagService
  ) {
    super(
      activatedRoute,
      routerService,
      platformIdService,
      titleService,
      authService,
      blogService,
      serviceBookingService,
      embedDisqusCountScriptService,
      disqusWidgetsService,
      analyticsService,
      brandingService,
      locationService,
      zoneService,
      localeService,
      notificationsService,
      bookingService,
      metaTagService
    );
  }

  selectRelatedArticle(event: IArticleSelection): void {
    this._router.navigate(['/business-blog', event.authorUrl, 'posts', event.articleId]);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  selectTag(tag: IBlogTag) {
    this._router.navigate(['/business-blog', tag ? { tags: [tag.name] } : {}]);
  }
}
