import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientQuizAnswersComponent } from '../components/client-quiz-answers/client-quiz-answers.component';
import { ClientLeaveEditorModalComponent } from '../components/client-leave-editor-modal/client-leave-editor-modal.component';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class CanLeaveAnswersEditorGuard {
  constructor(private _modal: NgbModal, private _notificationService: NotificationsService) {}

  canDeactivate(component: ClientQuizAnswersComponent): Promise<boolean> {
    if (component.isSaved) {
      return Promise.resolve(true);
    }

    const { result } = this._modal.open(ClientLeaveEditorModalComponent, { centered: true });

    return (
      result
        // @ts-expect-error TS2345
        .then(({ save }) => {
          if (save) {
            return component
              .submit$()
              .pipe(
                // @ts-expect-error TS2554
                tap(() => {
                  const title = `Success`;
                  const message = `Saved`;
                  this._notificationService.success(title, message);
                }),
                // eslint-disable-next-line id-length
                map(r => !!r),
                catchError(() => {
                  const title = `Error`;
                  const message = `An error occurred`;
                  this._notificationService.error(title, message);
                  return of(false);
                })
              )
              .toPromise();
          }
          return true;
        })
        .catch(() => false)
    );
  }
}
