import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared/shared.module';
import { WikiRoutingModule } from './wiki-routing.module';

import { WikiComponent } from './components/wiki/wiki.component';
import { WikiTermComponent } from './components/wiki-term/wiki-term.component';

@NgModule({
  declarations: [WikiComponent, WikiTermComponent],
  imports: [CommonModule, NgbModule, ReactiveFormsModule, RouterModule, SharedModule, WikiRoutingModule],
  providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class WikiModule {}
