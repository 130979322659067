<span pui-typography="body-s">
  {{ label }}
</span>

@if (hasDelete) {
  <button
    size="xxs"
    pui-icon-button
    appearance="flat-secondary"
    (click)="$event.stopPropagation(); $event.preventDefault(); delete.emit()">
    <pui-icon [size]="20" svgIcon="pui:x-circle" />
  </button>
}
