import { Injectable, Injector } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { UserRoles } from '@app/shared/enums/user-roles';
import { AuthService } from '@app/core/auth/services/auth.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

@Injectable()
export class PublicEventsGuard {
  constructor(
    private _injector: Injector,
    private _router: Router,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkPublicEventsAccess();
  }

  canLoad(): boolean {
    return this.checkPublicEventsAccess();
  }

  private checkPublicEventsAccess(): boolean {
    const authService = this._injector.get(AuthService);
    const { isAuthorized } = authService;
    const isGuide = isAuthorized && authService.user.RoleId === UserRoles.GUIDE;
    const isClient = isAuthorized && authService.user.RoleId === UserRoles.CLIENT;
    const publicEventsEnabledForGuides = this._runtimeConfigService.get('publicEventsEnabledForGuides');
    const publicEventsEnabledForClients = this._runtimeConfigService.get('publicEventsEnabledForClients');

    if (isGuide && publicEventsEnabledForGuides) {
      return true;
    }

    if (isClient && publicEventsEnabledForClients) {
      return true;
    }

    this._router.navigate(['/']);
    return false;
  }
}
