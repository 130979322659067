import { NotificationsService } from '@awarenow/profi-ui-core';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { WithServicePermission } from '@app/base';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticCreateSourceTypes, AnalyticServiceTypes, InternalEvents } from '@app/core/analytics/types';
import { AuthService } from '@app/core/auth/services';
import { SocketService } from '@app/core/socket/socket.service';
import { GuideFAQEditorService } from '@app/modules/guide-service-editor/services/guide-faq-editor.service';
import {
  GuidePriceEditorService,
  IPriceSettings
} from '@app/modules/guide-service-editor/services/guide-price-editor.service';
import {
  GuideSharingEditorService,
  ServiceSharingTypes
} from '@app/modules/guide-service-editor/services/guide-sharing-editor.service';
import { GuideTestimonialsEditorService } from '@app/modules/guide-service-editor/services/guide-testimonials-editor.service';
import { IFAQuestion } from '@app/modules/guide-service-editor/types/faq';
import { ITestimonial } from '@app/modules/guide-service-editor/types/testimonial';
import { PackageIncludes } from '@app/modules/package/types/package-session-template';
import { CoverImageService } from '@app/modules/ui-kit/cover-image/services/cover-image.service';
import { WorkspaceUtility } from '@app/modules/workspaces/utils';
import { makeUriFromString } from '@app/screens/blog/utils';
import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { PackageAutoRenewalType, IPackageAssignee } from '@app/screens/guide/guide-packages/types';
import { ClientInvitation, ClientInvitationTypes } from '@app/screens/guide/guide-programs/types';
import { ServiceAssigneePermission } from '@app/screens/guide/guide-sessions-templates/types';
import { API_PATH, GUIDE_PACKAGES_ENDPOINT } from '@app/shared/constants/endpoints';
import { nonEmptyValidator } from '@app/shared/form-validators/non-empty.validator';
import { PackageUser } from '@app/shared/interfaces/package-user';
import { LOCATION_ORIGIN } from '../../../../consts';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPackageSettingsData extends IPriceSettings, Partial<WithServicePermission> {
  id?: number;
  guideId?: number;
  workspaceId?: number;
  title: string;
  description: string;
  sharing: ServiceSharingTypes;
  sessions?: PackageIncludes[];
  testimonials?: ITestimonial[];
  faq?: IFAQuestion[];
  coverImage?: string;
  coverImageThumb?: string;
  autoAssignHosts: boolean | null;
  enrollOnlyByGuide: boolean | null;
  restrictMultipleEnroll: boolean | null;
  autoRenewal: PackageAutoRenewalType | null;
}

export function groupClientsByTemplateId(clients: PackageUser[]): {
  [templateId: number]: PackageUser[];
} {
  const groupedClients: { [templateId: number]: PackageUser[] } = {};

  for (const client of clients) {
    for (const session of client.sessions) {
      const templateId = session.templateId || session['template.id'];

      if (!groupedClients[templateId]) {
        groupedClients[templateId] = [];
      }

      groupedClients[templateId].push(client);
    }
  }

  return groupedClients;
}

@Injectable({ providedIn: 'root' })
export class GuidePackageService implements OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _baseUrl = LOCATION_ORIGIN;

  private readonly destroy$ = new Subject<void>();

  // eslint-disable-next-line rxjs/no-ignored-replay-buffer,@typescript-eslint/naming-convention
  private _activeClients$ = new ReplaySubject<PackageUser[]>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _activeClientsCount$ = new Subject<number | null>();

  // eslint-disable-next-line rxjs/no-ignored-replay-buffer,@typescript-eslint/naming-convention
  private _completedClients$ = new ReplaySubject<PackageUser[]>();

  // eslint-disable-next-line rxjs/no-ignored-replay-buffer,@typescript-eslint/naming-convention
  private _invitedClients$ = new ReplaySubject<PackageUser[]>();

  // eslint-disable-next-line rxjs/no-ignored-replay-buffer,@typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  private _invites$ = new ReplaySubject<any[]>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _packageId$ = new ReplaySubject<string | null>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _packageId: string | null = null;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _packageSettings$ = new ReplaySubject<IPackageSettingsData>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _packageTitle$ = new ReplaySubject<string>(1);

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _settingsForm: UntypedFormGroup;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _authorIdBeh$: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _permissionBeh$: BehaviorSubject<ServiceAssigneePermission | null> =
    new BehaviorSubject<ServiceAssigneePermission | null>(null);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _packageTeam: BehaviorSubject<IPackageAssignee[]> = new BehaviorSubject<IPackageAssignee[]>([]);

  packageTeam$: Observable<IPackageAssignee[]> = this._packageTeam.asObservable();

  isSettingsFormSaved = false;

  isSettingsFormSubmitted = false;

  get activeClients$(): Observable<PackageUser[]> {
    return this._activeClients$.asObservable();
  }

  get authorId$(): Observable<number | undefined> {
    return this._authorIdBeh$.asObservable();
  }

  get canEditPackage$(): Observable<boolean> {
    return this._permissionBeh$.pipe(
      map((permission: ServiceAssigneePermission) =>
        permission ? WorkspaceUtility.editorPermissions.includes(permission) : true
      )
    );
  }

  get activeClientsCount$(): Observable<number | null> {
    return this._activeClientsCount$;
  }

  get allClients$(): Observable<[PackageUser[], PackageUser[], PackageUser[], ClientInvitation[]]> {
    return combineLatest([this.activeClients$, this.completedClients$, this.invitedClients$, this.invites$]);
  }

  readonly hasPackageAnyClients$ = this.allClients$.pipe(map(allClients => !!allClients.flat().length));

  get completedClients$(): Observable<PackageUser[]> {
    return this._completedClients$.asObservable();
  }

  get invitedClients$(): Observable<PackageUser[]> {
    return this._invitedClients$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get invites$(): Observable<ClientInvitation[]> {
    return this._invites$.asObservable();
  }

  get packageId(): string | null {
    return this._packageId;
  }

  get packageId$(): Observable<string | null> {
    return this._packageId$.asObservable();
  }

  get packageLink$(): Observable<string> {
    // @ts-expect-error TS2322
    return combineLatest([this.packageId$, this.packageTitle$]).pipe(
      // eslint-disable-next-line rxjs/no-unsafe-takeuntil
      takeUntil(this.destroy$),
      map(([packageId, packageTitle]) =>
        packageId && packageTitle ? `${this._baseUrl}/packages/${makeUriFromString(packageTitle, packageId)}` : null
      )
    );
  }

  get packageSettings$(): Observable<IPackageSettingsData> {
    return this._packageSettings$.asObservable();
  }

  get packageTitle$(): Observable<string> {
    return this._packageTitle$.asObservable();
  }

  get settingsForm(): UntypedFormGroup {
    return this._settingsForm;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set packageId(packageId: string | null) {
    this._packageId = packageId;
    this._packageId$.next(packageId);
  }

  set packageTitle(title: string) {
    this._packageTitle$.next(title);
  }

  get isPlatformAdmin(): boolean {
    return this._auth.isPlatformAdmin();
  }

  get endpoint(): string {
    return `${API_PATH}/user/${this._auth.getUserRole()}/packages`;
  }

  // @ts-expect-error TS2564
  private savedData: IPackageSettingsData;

  constructor(
    private readonly _analyticsService: AnalyticsService,
    private readonly _auth: AuthService,
    private _formBuilder: UntypedFormBuilder,
    private _http: HttpClient,
    private notifications: NotificationsService,
    private _guidePriceEditorService: GuidePriceEditorService,
    private _guideSharingEditorService: GuideSharingEditorService,
    private _guideTestimonialsEditorService: GuideTestimonialsEditorService,
    private _guideFAQEditorService: GuideFAQEditorService,
    private _socketService: SocketService,
    private _coverImageService: CoverImageService
  ) {
    this.setSubscriptions();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createSettingsForm(): void {
    this._settingsForm = this._formBuilder.group({
      title: ['', [Validators.required, nonEmptyValidator, Validators.maxLength(254)]],
      description: [''],
      coverImage: [null],
      coverImageThumb: [null],
      sharingSettings: this._guideSharingEditorService.createSharingSettings(),
      priceSettings: this._guidePriceEditorService.createPriceSettings(),
      sessions: this._formBuilder.array([], Validators.required),
      testimonials: this._guideTestimonialsEditorService.createTestimonialsSettings(),
      faq: this._guideFAQEditorService.createFAQForm(),
      autoAssignHosts: [false],
      enrollOnlyByGuide: [false],
      restrictMultipleEnroll: [false]
    });
  }

  loadSavedSettingsForm(): void {
    this.updateSettingsForm(this.savedData);
  }

  saveSettingFormData(data: IPackageSettingsData): void {
    this.savedData = data;
  }

  updateSettingsForm(data: IPackageSettingsData): void {
    this.saveSettingFormData(data);
    const {
      title,
      guideId,
      description,
      sessions,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      id,
      sharing,
      coverImage,
      coverImageThumb,
      testimonials,
      faq,
      permission,
      autoAssignHosts,
      enrollOnlyByGuide,
      restrictMultipleEnroll,
      ...priceSettings
    } = data;

    // @ts-expect-error TS2345
    this._permissionBeh$.next(permission);
    this._authorIdBeh$.next(guideId);

    const isAuthor: boolean = this._auth.user.id === guideId;

    this._settingsForm.patchValue({
      title,
      description,
      coverImage,
      coverImageThumb,
      sharingSettings: this._guideSharingEditorService.sharingSettingsToForm(sharing),
      priceSettings: this._guidePriceEditorService.priceSettingsToForm(priceSettings),
      autoAssignHosts,
      enrollOnlyByGuide,
      restrictMultipleEnroll
    });

    if (sessions) {
      this._settingsForm.setControl(
        'sessions',
        this._formBuilder.array(sessions.map(session => this._formBuilder.group(session)))
      );
    }
    if (!this.isPlatformAdmin) {
      this._settingsForm.setControl(
        'testimonials',
        this._formBuilder.array(
          this._guideTestimonialsEditorService
            // @ts-expect-error TS2345
            .testimonialsSettingsToForm(testimonials)
            .map(testimonial => this._formBuilder.group(testimonial))
        )
      );
      this._settingsForm.setControl('faq', this._guideFAQEditorService.createFAQForm(faq, isAuthor));
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  reset() {
    this.packageId = null;
    // @ts-expect-error TS2322
    this.packageTitle = null;
    // @ts-expect-error TS2322
    this._settingsForm = null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updatePackage$(): Observable<any> {
    this.isSettingsFormSaved = false;
    this.isSettingsFormSubmitted = true;

    if (!this.settingsForm.valid) {
      return of(null);
    }

    const data = this.prepareSettingsData();

    return this._http.put(`${this.endpoint}/${this._packageId}`, data).pipe(
      map(() => ({ redirect: false })),
      tap(() => {
        this.isSettingsFormSaved = true;
        this.isSettingsFormSubmitted = false;
      }),
      tap(() => this.notifications.success(`Package updated`)),
      catchError(error => {
        this.notifications.error(`Failed to update package`);
        return throwError(error);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createPackage$(): Observable<any> {
    this.isSettingsFormSaved = false;
    this.isSettingsFormSubmitted = true;

    if (!this.settingsForm.valid) {
      return of(null);
    }

    const data = this.prepareSettingsData();

    return this._http.post(this.endpoint, data).pipe(
      map((createdPackage: { id: number }) => ({ id: createdPackage.id, redirect: true })),
      tap(() => {
        this._analyticsService.event(InternalEvents.SERVICE_CREATE, {
          createSource: AnalyticCreateSourceTypes.SERVICE_LIST,
          servicePrice: data.price,
          serviceType: AnalyticServiceTypes.PACKAGE,
          sharing: data.sharing
        });
      }),
      tap(() => {
        this.isSettingsFormSaved = true;
        this.isSettingsFormSubmitted = false;
      }),
      tap(() => this.notifications.success(`Package created`)),
      catchError(error => {
        const errorMessage =
          error && error.error.errors && error.error.errors.message
            ? error.error.errors.message
            : 'Failed to create a package';
        this.notifications.error(errorMessage);
        return throwError(error);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveSettings$(): Observable<{ id: number; redirect: boolean } | null> {
    this.savedData = this.prepareSettingsData() as IPackageSettingsData;
    return this._packageId ? this.updatePackage$() : this.createPackage$();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  loadPackage() {
    return this._http.get<IPackageSettingsData>(`${this.endpoint}/${this._packageId}`).pipe(
      tap(settings => this._packageSettings$.next(settings)),
      tap(data => this.updateSettingsForm(data)),
      tap(({ title }) => (this.packageTitle = title)),
      catchError(error => throwError(error))
    );
  }

  cleanPackageSettings(): Observable<void> {
    this.packageTitle = '';
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this._packageSettings$.next(undefined);
    this._permissionBeh$.next(null);
    return of();
  }

  loadClients(): void {
    if (this._packageId && !this.isPlatformAdmin) {
      this._http
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .get<{ clients: PackageUser[] }>(`${GUIDE_PACKAGES_ENDPOINT}/${this._packageId}/clients`)
        .pipe(
          map(({ clients }) => this.mapClients(clients)),
          catchError(error => throwError(error))
        )
        .subscribe(([active, inactive, invited]) => {
          this._activeClients$.next(active);
          this._activeClientsCount$.next(active.length);
          this._completedClients$.next(inactive);
          this._invitedClients$.next(invited);
        });
    }
  }

  loadInvitations(): void {
    if (this._packageId && !this.isPlatformAdmin) {
      this._http
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .get<any[]>(`${GUIDE_PACKAGES_ENDPOINT}/${this._packageId}/invitations`)
        .pipe(catchError(error => throwError(error)))
        .subscribe(invites => {
          this._invites$.next(invites);
        });
    }
  }

  loadWorkspaceAssignees(): void {
    if (this._packageId) {
      this._http
        .get<IPackageAssignee[]>(`${GUIDE_PACKAGES_ENDPOINT}/${this._packageId}/assignees`)
        .pipe(catchError(error => throwError(error)))
        .subscribe((team: IPackageAssignee[]) => {
          this._packageTeam.next(team);
        });
    }
  }

  loadIncludes(): Observable<PackageIncludes[]> {
    return this._http
      .get<{ includes: PackageIncludes[] }>(
        `${API_PATH}/user/${this._auth.getUserRole()}/packages/${this._packageId}/includes`
      )
      .pipe(
        map(({ includes }) => includes),
        tap(includes => {
          this._settingsForm.setControl(
            'sessions',
            this._formBuilder.array(includes.map(session => this._formBuilder.group(session)))
          );
        }),
        catchError(error => throwError(error))
      );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  sendInvitations(
    invitations: { name?: string | null; email?: string; userId?: number | null; type?: string }[],
    type: ClientInvitationTypes
  ) {
    const invitationsWithoutClientIdForContacts = invitations.map(invitation => {
      return invitation.type === 'guideContact' ? { ...invitation, userId: undefined } : invitation;
    });

    return this._http
      .post<ClientInvitation[]>(`${GUIDE_PACKAGES_ENDPOINT}/${this._packageId}/invitations`, {
        invitations: invitationsWithoutClientIdForContacts,
        type
      })
      .pipe(
        tap(invites => this._invites$.next(invites)),
        tap(() => this.loadClients())
      );
  }

  deleteInvitation$(packageId: string, id: number): Observable<ClientInvitation[]> {
    return this._http.delete<ClientInvitation[]>(`${GUIDE_PACKAGES_ENDPOINT}/${packageId}/invitations/${id}`).pipe(
      tap(() => this.loadClients()),
      catchError(error => {
        this.notifications.error(`Failed to load client invitations`);
        return throwError(error);
      })
    );
  }

  getClientsBySessionTemplate$(packageId: number, sessionTemplateId: number): Observable<CRMClient[]> {
    const url = `${GUIDE_PACKAGES_ENDPOINT}/${packageId}/sessionTemplates/${sessionTemplateId}/clients`;
    const options = { params: new HttpParams() };
    return this._http.get<CRMClient[]>(url, options).pipe(
      catchError(error => {
        this.notifications.error(`Cant load clients`);
        return throwError(error);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  prepareSettingsData() {
    const { controls } = this.settingsForm;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isSubscription, ...priceInfo } = this._guidePriceEditorService.preparePriceData(
      controls.priceSettings.value
    );
    const sharing = this._guideSharingEditorService.prepareSharingData(controls.sharingSettings.value);

    return {
      title: controls.title.value,
      description: controls.description.value || '',
      sharing,
      sessions: controls.sessions.value as PackageIncludes[],
      testimonials: controls.testimonials.value,
      faq: controls.faq.value,
      coverImage: controls.coverImage.value,
      coverImageThumb: controls.coverImageThumb.value,
      autoAssignHosts: controls.autoAssignHosts.value,
      enrollOnlyByGuide: controls.enrollOnlyByGuide.value,
      restrictMultipleEnroll: controls.restrictMultipleEnroll.value,
      ...priceInfo
    };
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  deactivateClientSubscription(clientId) {
    this._http
      .delete(`${GUIDE_PACKAGES_ENDPOINT}/${this._packageId}/clients/${clientId}`)
      .pipe(
        catchError(error => {
          this.notifications.error(`Failed to deactivate client subscription`);
          return throwError(error);
        })
      )
      .subscribe(() => {
        this.loadClients();
        this.notifications.success(`Client' subscription deactivated`);
      });
  }

  private mapClients(clients: PackageUser[]): [PackageUser[], PackageUser[], PackageUser[]] {
    return clients.reduce(
      (mappedCustomers: [PackageUser[], PackageUser[], PackageUser[]], client) => {
        let index = -1;

        if (client.status === 'active' || client.status === 'subscription_canceled') {
          index = 0;
        } else if (client.status === 'inactive' || client.status === 'completed') {
          index = 1;
        } else if (client.status === 'invited') {
          index = 2;
        }

        if (index !== -1) {
          if (client.id) {
            client.name =
              `${client.firstName ?? ''} ${client.lastName ?? ''}`.trim() || client.email || 'name not specified';
          }
          mappedCustomers[index].push(client);
        }

        return mappedCustomers;
      },
      [[], [], []]
    );
  }

  private setSubscriptions(): void {
    this._socketService.onPackageEnrolled().subscribe(() => {
      this.loadClients();
      this.loadInvitations();
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadCover(images: { coverImage: File; coverImageThumb: File }): Observable<any> {
    return this._coverImageService.uploadCoverImage(
      images.coverImage,
      images.coverImageThumb,
      'package',
      this.endpoint
    );
  }

  removeCover(id: string): Observable<void> {
    // @ts-expect-error TS2322
    return this._coverImageService.removeCoverImage(this.endpoint, id);
  }

  changeSessionsCounters$(clientId: number, counters: Record<number, number>): Observable<Object> {
    return this._http.post(`${this.endpoint}/${this._packageId}/clients/${clientId}/sessions-count`, counters).pipe(
      tap(() => this.notifications.success(`Package updated`)),
      catchError(error => {
        this.notifications.error(`Failed to update package`);
        return throwError(error);
      })
    );
  }
}
