import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { catchError } from 'rxjs/operators';
import { NotificationsService } from '@awarenow/profi-ui-core';

@Injectable({ providedIn: 'root' })
export class EmailSubscribeService {
  constructor(private _http: HttpClient, private _notifications: NotificationsService) {}

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendEmailForSubscribe(data): Observable<any> {
    return this._http.post(`${environment.apiHost}/v2/email/subscribe`, data).pipe(
      catchError(error => {
        const message = `Cannot subscribe`;
        this._notifications.error(message);
        return throwError(error);
      })
    );
  }
}
