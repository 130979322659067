import { NotificationsService } from '@awarenow/profi-ui-core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { SessionBookedEvent } from '@app/core/analytics/events/session-booked.event';
import { SessionCanceledEvent } from '@app/core/analytics/events/session-canceled.event';
import { SessionRescheduledEvent } from '@app/core/analytics/events/session-rescheduled.event';
import config from '@app/core/config/config';
import { LogService } from '@app/core/log/log.service';

export interface ServiceSchedule {
  id: number;
  date: string;
  duration: number;
  timezone: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recurrence: any;
  templateId: number;
  doesScheduleHaveSessions?: boolean;
}

@Injectable()
export class GuideServiceScheduleApiService {
  readonly TEMPLATES_ENDPOINT = `${config.apiPath}/user/guide/services/templates`;
  readonly EVENTS_ENDPOINT = `${config.apiPath}/user/guide/events`;

  constructor(
    private _http: HttpClient,
    private _notifications: NotificationsService,
    private _logger: LogService,
    private analyticsService: AnalyticsService
  ) {}

  addServiceSchedule$(
    serviceId: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    schedule: { date: string; duration?: number; timezone?: string; recurrence?: any }
  ): Observable<{ scheduleId: number }> {
    return this._http.post<{ scheduleId: number }>(`${this.TEMPLATES_ENDPOINT}/${serviceId}/schedules`, {
      templateId: serviceId,
      ...schedule
    });
  }

  createEvents(
    serviceId: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    schedule: { date: string; duration?: number; timezone?: string; recurrence?: any }
  ): Observable<{ readonly eventsIds: number[] }> {
    return this._http
      .post<{ readonly eventsIds: number[] }>(this.EVENTS_ENDPOINT, {
        serviceId,
        ...schedule
      })
      .pipe(
        tap(() => this.analyticsService.emitEvent(new SessionBookedEvent())),
        catchError(error => {
          const title = `Offer cannot be made.`;
          this._notifications.error(title, error.error && error.error.msg ? error.error.msg : null);

          return throwError(error);
        })
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cancelEvent$(eventId: number, options: any, reason: string | null): Observable<null> {
    const requestBody = {
      ...options,
      id: eventId,
      reason
    };
    return this._http
      .post<null>(`${this.EVENTS_ENDPOINT}/cancel`, requestBody)
      .pipe(tap(() => this.analyticsService.emitEvent(new SessionCanceledEvent())));
  }

  rescheduleEvent$(request: {
    eventId: number;
    date: string;
    timezone?: string;
    doubleBooking?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }): Observable<any> {
    return this._http
      .post<null>(`${this.EVENTS_ENDPOINT}/reschedule`, request)
      .pipe(tap(() => this.analyticsService.emitEvent(new SessionRescheduledEvent())));
  }

  findServiceSchedules$(serviceId: number): Observable<{ schedules: ServiceSchedule[] }> {
    return this._http.get<{ schedules: ServiceSchedule[] }>(`${this.TEMPLATES_ENDPOINT}/${serviceId}/schedules/all`);
  }
}
