import { StarRatingModule } from 'angular-star-rating';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { switchMap } from 'rxjs/operators';

import { DatePipe, isPlatformBrowser, registerLocaleData } from '@angular/common';
import { APP_ID, APP_INITIALIZER, Inject, Injectable, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@app/core';
import { IntegrationsModule } from '@app/core/integrations/integrations.module';
import { LocaleService } from '@app/core/locale/locale.service';
import { NotFoundModule } from '@app/modules/not-found/not-found.module';
import { ProposeFormsModule } from '@app/modules/propose-forms/propose-forms.module';
// Our modules:
import { ReschedulingServiceModule } from '@app/modules/rescheduling-service';
import { SessionWidgetRootModule } from '@app/modules/session-widget/session-widget-root.module';
import { SystemVersionPopupModule } from '@app/modules/system-version-popup/system-version-popup.module';
import { TokenSignInModule } from '@app/modules/token-sign-in/token-sign-in.module';
import { iconInitialize } from '@app/modules/ui-kit/_base/ui-icon/icon';
import { IconStore } from '@app/modules/ui-kit/_base/ui-icon/icon-store';
import { ThemingModule } from '@app/modules/ui-kit/_theming';
import { KitBrandingModule } from '@app/modules/ui-kit/kit-branding/kit-branding.module';
import { SharedModule } from '@app/shared';
import { NgbDateCustomParserFormatter } from '@app/shared/utils/datepicker-formatter';
import {
  DEVICE_TYPE_PROVIDERS,
  FileWithURL,
  NotificationsModule,
  PuiDialogModule,
  puiIconInitialize,
  PuiIconStore,
  PuiRootModule,
  PuiTypographyModule,
  UPLOAD_FILE_ACTION,
  UploadFileAction
} from '@awarenow/profi-ui-core';
import {
  ClientSessionActionsService,
  ClientSessionAdaptorService,
  ClientSessionCommunicationsService
} from '@libs/business/client/client-session/services';
import { CLIENT_SESSION_ADAPTOR } from '@libs/business/client/client-session/tokens';
import { FeatureFlagService, FeatureFlagsModule } from '@libs/feature-flags';
import { AlvisModule } from '@libs/services/alvis/alvis.module';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
// Bootstrap component:
import { AppComponent } from './app.component';
import { AuthService } from './core/auth/services';
import { GLOBAL_WINDOW } from './core/browser-window/window-provider';
import { LayoutsModule } from './layouts/layouts.module';
import { AlertsModule } from './modules/alerts/alerts.module';
import { BookServiceModule } from './modules/book-service';
import { ClientInvitationActivationModule } from './modules/client-invitation/client-invitation-activation.module';
import { ClientProgramsModule } from './modules/client-programs/client-programs.module';
import { CouponActivationModule } from './modules/coupon-activation/coupon-activation.module';
import { EmailVerificationModule } from './modules/email-verification/email-verification.module';
import { GiftCouponsModule } from './modules/gift-coupons/gift-coupons.module';
import { GuideOfferModule } from './modules/guide-offer/guide-offer.module';
import { PhoneVerificationModule } from './modules/phone-verification/phone-verification.module';
import { ServiceSchedulingModule } from './modules/service-scheduling/service-scheduling.module';
import { SessionTemplateDrawerModule } from './modules/session-forms/drawers/session-template-drawer/session-template-drawer.module';
import { ScreensModule } from './screens/screens.module';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslocoRootModule } from '@libs/transloco';
import { ExternalLinkModule } from '@app/modules/external-link/external-link.module';
import { RuntimeConfigModule } from '@app/core/runtime-config/runtime-config.module';
import { GraphQLModule } from './graphql.module';
import { Observable } from 'rxjs';
import { FileUploaderService } from '@libs/file-uploader/file-uploader.service';

// @ts-expect-error TS7005
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const System;

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    AlertsModule,
    AppRoutingModule,
    RuntimeConfigModule.forRoot(),
    BookServiceModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'aware' }),
    ClientInvitationActivationModule,
    ClientProgramsModule,
    CoreModule,
    CouponActivationModule,
    EmailVerificationModule,
    GiftCouponsModule,
    GuideOfferModule,
    ProposeFormsModule,
    ServiceSchedulingModule,
    SessionTemplateDrawerModule,
    HammerModule,
    LayoutsModule,
    NgbModule,
    NotFoundModule,
    PhoneVerificationModule,
    ReschedulingServiceModule,
    ScreensModule,
    SharedModule,
    KitBrandingModule,
    PuiDialogModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25
    }),
    StarRatingModule.forRoot(),
    SystemVersionPopupModule,
    TokenSignInModule,
    ThemingModule,
    PuiRootModule,
    IntegrationsModule,
    FeatureFlagsModule.forRoot(() => ({ launchdarklyId: environment.featureFlagServiceId })),
    AlvisModule.forRoot(),
    SessionWidgetRootModule,
    TranslocoRootModule.forRoot({
      appName: 'awarenow',
      i18nProjectName: location.hostname
    }),
    // CommunityLibraryModule.forRoot(),
    PuiTypographyModule,
    ExternalLinkModule,
    GraphQLModule,
    NotificationsModule
  ],
  providers: [
    ...DEVICE_TYPE_PROVIDERS,
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    {
      provide: APP_INITIALIZER,
      useFactory: iconInitialize,
      deps: [IconStore],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      /**
       * Util for init library icons
       * @param initService PuiIconStore
       * @param @optional baseHref  Define custom baseHref
       */
      useFactory: puiIconInitialize,
      deps: [PuiIconStore],
      multi: true
    },
    DatePipe,
    {
      provide: CLIENT_SESSION_ADAPTOR,
      useClass: ClientSessionAdaptorService
    },
    ClientSessionCommunicationsService,
    ClientSessionActionsService,
    // Setup action for file {PuiFileUploaderExtension}
    {
      provide: UPLOAD_FILE_ACTION,
      useFactory:
        (uploaderService: FileUploaderService): UploadFileAction =>
        (files: FileList) =>
          uploaderService.uploadFiles(files),
      deps: [FileUploaderService]
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private authService: AuthService,
    private featureFlagService: FeatureFlagService
  ) {
    if (isPlatformBrowser(platformId)) {
      this.featureFlagService.init$(null, { environmentName: environment.NAME }).subscribe();

      // if environment is production,
      (environment.production
        ? // Then collects user identity for feature flags service,
          this.authService.onAuth().pipe(
            switchMap(user => {
              const newUser = user ? { key: user.id, email: user.email } : null;
              return this.featureFlagService.identify$(newUser, { environmentName: environment.NAME });
            })
          )
        : // Else use the anonymous client
          this.featureFlagService.identify$(null, { environmentName: environment.NAME })
      ).subscribe();
    }
  }
}
