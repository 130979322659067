import { Component, OnInit, ElementRef, HostBinding, HostListener } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

interface RangeValues {
  from: number;
  to: number;
}

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-abstract-range-slider',
  templateUrl: './abstract-range-slider.component.html',
  styleUrls: ['./abstract-range-slider.component.scss']
})
export class AbstractRangeSliderComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _form = this.fb.group({
    from: this.fb.control(0),
    to: this.fb.control(500),
    min: this.fb.control(0),
    max: this.fb.control(500)
  });

  fromDragging = false;
  toDragging = false;

  // @ts-expect-error TS2564
  sliderFrom: number;
  // @ts-expect-error TS2564
  sliderTo: number;

  // @ts-expect-error TS2564
  from: number;
  // @ts-expect-error TS2564
  to: number;
  // @ts-expect-error TS2564
  min: number;
  // @ts-expect-error TS2564
  max: number;

  @HostBinding('style')
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get style() {
    return this.sanitizer.bypassSecurityTrustStyle(`--from: ${this.sliderFrom}%;--to: ${this.sliderTo}%;`);
  }

  constructor(private sanitizer: DomSanitizer, private host: ElementRef, private fb: UntypedFormBuilder) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnInit() {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getForm() {
    return this._form;
  }

  // @ts-expect-error TS7031
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  updateForm({ from, to, min, max }) {
    this._form.patchValue({ from, to, min, max }, { emitEvent: false });
    this.from = from;
    this.to = to;
    this.min = min;
    this.max = max;

    if (from !== min || to !== max) {
      this._form.markAsDirty();
    }

    if (from === min && to === max) {
      this._form.markAsPristine();
    }

    this.setSliderValues(this.convertToSliderValues());
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  resetForm() {
    this.from = this.min;
    this.to = this.max;
    this._form.patchValue({ from: this.min, to: this.max }, { emitEvent: false });
    this._form.markAsPristine();
    this.setSliderValues(this.convertToSliderValues());
  }

  convertToSliderValues(): RangeValues {
    const rangeWidth = this.max - this.min;
    const from = (this.from / rangeWidth) * 100;
    const to = ((this.max - this.to) / rangeWidth) * 100;
    return { from, to };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setSliderValues(values: RangeValues) {
    const { from, to } = values;
    if (!from || from < 0) {
      this.sliderFrom = 0;
    }

    if (!to || to < 0) {
      this.sliderTo = 0;
    }

    if (from + to > 100) {
      this.sliderFrom = from;
      this.sliderTo = 100 - from;
    } else {
      this.sliderFrom = from;
      this.sliderTo = to;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  fromSliderActivated() {
    this.fromDragging = true;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  toSliderActivated() {
    this.toDragging = true;
  }

  @HostListener('window:mouseup')
  @HostListener('window:touchend')
  @HostListener('window:touchcancel')
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  mouseUp() {
    this.fromDragging = false;
    this.toDragging = false;
  }

  @HostListener('window:touchmove', ['$event'])
  @HostListener('window:mousemove', ['$event'])
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, id-length
  onMove(e: MouseEvent | TouchEvent) {
    const x = this.getPageX(e);
    const rect = this.host.nativeElement.getBoundingClientRect();

    if (this.fromDragging && x) {
      const sliderWidth = rect.right - rect.left;
      const range = this.max - this.min;
      const newFrom = this.min + (range * (x - rect.left)) / sliderWidth;
      this.from = newFrom > this.min && newFrom < this.to ? newFrom : this.min;
      this.setSliderValues(this.convertToSliderValues());
      this._form.patchValue({ from: Math.round(this.from) });
      this._form.controls.from.markAsDirty();
    }

    if (this.toDragging && x) {
      const sliderWidth = rect.right - rect.left;
      const range = this.max - this.min;
      const newTo = this.max - (range * (rect.right - x)) / sliderWidth;
      this.to = newTo < this.max && newTo > this.from ? newTo : this.max;
      this.setSliderValues(this.convertToSliderValues());
      this._form.patchValue({ to: Math.round(this.to) });
      this._form.controls.to.markAsDirty();
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, id-length
  private getPageX(e: MouseEvent | TouchEvent) {
    if (e instanceof MouseEvent && e.clientX) {
      return e.clientX;
    }
    if (e instanceof TouchEvent && e.touches) {
      if (e.touches[0].clientX) {
        return e.touches[0].clientX;
      }
    }
  }
}
