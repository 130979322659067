import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ServiceAssigneePermission } from '@app/screens/guide/guide-sessions-templates/types';

@Injectable()
export class SessionTemplatePermissionsService {
  applyPermissions(sessionTemplateForm: UntypedFormGroup, permission: ServiceAssigneePermission): void {
    if (permission === ServiceAssigneePermission.PROVIDER) {
      this.providerPermissions(sessionTemplateForm);
    } else if (
      permission === ServiceAssigneePermission.OWNER ||
      permission === ServiceAssigneePermission.OWNER_X_PROVIDER
    ) {
      this.ownerPermissions(sessionTemplateForm);
    } else {
      // [Session template] form disabled by default
      sessionTemplateForm.disable();
    }
  }

  private providerPermissions(sessionTemplateForm: UntypedFormGroup): void {
    sessionTemplateForm.get('basicInfo')?.disable(); // [Location] property not disable
    sessionTemplateForm.get('pricing')?.disable();
    sessionTemplateForm.get('availability')?.disable();
    sessionTemplateForm.get('participantsAndGuests')?.disable();
    sessionTemplateForm.get('restrictions')?.disable();
    sessionTemplateForm.get('testimonials')?.disable();
  }

  private ownerPermissions(sessionTemplateForm: UntypedFormGroup): void {
    sessionTemplateForm.get('basicInfo')?.enable();
    sessionTemplateForm.get('pricing')?.enable();
    sessionTemplateForm.get('sessionAvailability')?.enable();
    sessionTemplateForm.get('participantsAndGuests')?.enable();
    sessionTemplateForm.get('restrictions')?.enable();
    sessionTemplateForm.get('testimonials')?.enable();
  }
}
