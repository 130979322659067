import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { combineLatest, of, Subject, throwError } from 'rxjs';
import { catchError, finalize, mergeMap, takeUntil } from 'rxjs/operators';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { AuthService } from '@app/core/auth/services/auth.service';
import { FormService } from '@app/core';
import { UserRoles } from '@app/shared/enums/user-roles';
import { TfaChallengeInfo } from '../../interfaces/tfa-challendge-info.interface';
import { EMAIL_REGEXP } from '@app/shared/constants';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['../../../../screens/auth/auth.scss', '../../styles/auth.scss', './sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  // @ts-expect-error TS2564
  signInForm: UntypedFormGroup;
  isFetching = false;
  wrongPassword = false;
  teamInvitationCode = '';

  @Output()
  afterSignIn = new EventEmitter();

  @Output()
  signInCanNot = new EventEmitter();

  @Output()
  openTfaStep = new EventEmitter<TfaChallengeInfo>();

  @Input()
  onlyClient = true;

  @Input()
  // @ts-expect-error TS2564
  onlyGuide: boolean;

  @Input()
  // @ts-expect-error TS2564
  remember: boolean;

  @Input()
  // @ts-expect-error TS2564
  email: string;

  @Input()
  // @ts-expect-error TS2564
  firstName: string;

  constructor(
    private authService: AuthService,
    private notifyService: NotificationsService,
    private formService: FormService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.buildForm();
    if (this.authService.workspaceMemberSignUpInfo) {
      this.onlyClient = false;
      this.onlyGuide = true;
      this.teamInvitationCode = this.authService.workspaceMemberSignUpInfo.invitationCode;
      this.email = this.authService.workspaceMemberSignUpInfo.email;
    }
    // @ts-expect-error TS2531
    this.signInForm.get('email').setValue(this.email);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onPasswordChanged(): void {
    this.wrongPassword = false;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  signIn() {
    if (this.formService.markInvalidForm(this.signInForm) || this.isFetching) {
      return;
    }
    Object.keys(this.signInForm.controls).forEach(field => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const control = this.signInForm.get(field);
      // @ts-expect-error TS2531
      control.markAsTouched({ onlySelf: true });
    });

    this.wrongPassword = false;

    const { password } = this.signInForm.value;
    this.isFetching = true;

    this.authService
      .signin(this.email, password, this.remember, this.onlyClient, this.onlyGuide, this.teamInvitationCode)
      .pipe(
        // eslint-disable-next-line id-length
        catchError(e => {
          if (e.status === 403) {
            if (!e.error.payload) {
              this.wrongPassword = true;
            } else if (e.error.payload.isBlocked || e.error.payload.accessDenied) {
              this.notifyService.error(e.error.msg);
            }
          }
          return throwError(e);
        }),
        // eslint-disable-next-line id-length
        mergeMap(r => {
          if (
            (this.onlyClient && this.authService.user.RoleId === UserRoles.GUIDE) ||
            (this.onlyGuide && this.authService.user.RoleId === UserRoles.CLIENT)
          ) {
            return combineLatest([of(r), this.authService.checkAlternativeAccount()]);
          }

          return of([r, null]);
        }),
        // eslint-disable-next-line id-length
        mergeMap(([r, checkRes]) => {
          if (checkRes) {
            if (checkRes.hasAlternativeProfile) {
              return this.authService.signinAlternativeAccount(true);
            }
            return throwError({ r, alternativeFailed: true });
          } else {
            return of(r);
          }
        }),
        takeUntil(this.destroy$),
        finalize(() => (this.isFetching = false))
      )
      .subscribe(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ user }) => {
          if (user.tfaChallenge) {
            return this.openTfaStep.emit(user);
          }

          this.afterSignIn.emit();
        },
        error1 => {
          if (error1.alternativeFailed) {
            this.signInCanNot.emit();
            this.authService.authorize(error1.r);
          }
        }
      );
  }

  private buildForm(): void {
    this.signInForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(EMAIL_REGEXP)]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }
}
