import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { AuthService } from '@app/core/auth/services/auth.service';
import { UserRoles } from '@app/shared/enums/user-roles';

@Injectable()
export class NoChatIfIncompleteProfileGuard {
  constructor(private _router: Router, private _auth: AuthService, private _notifications: NotificationsService) {}

  canActivate(): boolean {
    const { firstName, lastName, RoleId } = this._auth.user;
    const isProfileComplete = !!firstName && !!lastName;

    if (!isProfileComplete) {
      let role = null;

      if (RoleId === UserRoles.GUIDE) {
        role = 'guide';
      } else if (RoleId === UserRoles.CLIENT) {
        role = 'client';
      }

      if (role) {
        this._notifications.error(`Incomplete profile', 'Please fill your profile`);
        this._router.navigate([`/${role}/edit-profile`]);
      }
    }

    return isProfileComplete;
  }
}
