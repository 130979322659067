import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { FormService } from '@app/core/form/form.service';
import { QuizQuestionType } from '@app/core/quizzes/types';

import { IModuleQuizQuestion } from './types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-module-quiz',
  templateUrl: './module-quiz.component.html',
  styleUrls: ['./module-quiz.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ModuleQuizComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ModuleQuizComponent),
      multi: true
    }
  ]
})
export class ModuleQuizComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  private destroy$ = new Subject<void>();

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _editableAnswers: boolean;

  readonly QuizQuestionType: typeof QuizQuestionType = QuizQuestionType;

  form: UntypedFormGroup = this._formBuilder.group({ questions: this._formBuilder.array([]) });

  get formQuestions(): UntypedFormArray {
    return this.form.get('questions') as UntypedFormArray;
  }

  get editableAnswers(): boolean {
    return this._editableAnswers ?? false;
  }

  @Input()
  set editableAnswers(value: boolean) {
    this._editableAnswers = value;
    // @ts-expect-error TS2722
    this.setDisabledState(!value);
  }

  @Input()
  isCorrectDisplay = true;

  @Input()
  displayKey = true;

  @Input()
  set value(questions: IModuleQuizQuestion[]) {
    const result = Object.keys(questions)
      .map(i => {
        let answerText = null;
        let options = null;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const question = questions[i] as IModuleQuizQuestion & {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          answers: any[];
        } & { index: number };

        if (Array.isArray(question.answers)) {
          const answers = question.answers;

          const answer = answers.map(ans => ans.optionId);
          answerText = answers.find(ans => ans.text)?.text || null;
          options = question.options ? question.options.filter(option => answer.includes(option.id)) : [];
        } else if (question.answer) {
          answerText = question.answer.text;
          options = question.answer.options;
        }

        return {
          id: question.id,
          question: question.question,
          type: question.type,
          index: question.index + 1,
          answer: {
            text: answerText,
            options
          },
          options: question.options || null,
          createdAt: ''
        };
      })
      // eslint-disable-next-line id-length
      .sort((a, b) => a.index - b.index);

    this.writeValue(result);
  }

  @Input()
  showNoResponseMessage = false;

  constructor(private _formBuilder: UntypedFormBuilder, private formService: FormService) {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(({ questions }) => {
      this.onChange(this.convertFormControlValuesToQuestions(questions));
      this.onTouched();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
    (<any>control).markAsDirtyAndTouched = () => {
      this.form.markAsTouched();
      this.form.markAsDirty();
      this.formService.markFormDirtyAndTouched(this.form);
    };
    return this.form.valid ? null : { invalidForm: { valid: false } };
  }

  writeValue(questions: IModuleQuizQuestion[]): void {
    if (!questions) {
      return;
    }

    questions.forEach(question => {
      if (
        question.type === QuizQuestionType.QUIZ &&
        (!Array.isArray(question.options) || question.options.length === 0)
      ) {
        question.type = QuizQuestionType.LONG_ANSWER;
      }
    });
    this.form.setControl('questions', this._formBuilder.array(this.convertQuestionsToFormControls(questions)));

    setTimeout(() => {
      this.form.setValue(this.form.value);
    });
  }

  showMultiChoice(question: IModuleQuizQuestion): boolean {
    return [QuizQuestionType.MULTIPLE_CHOICE, QuizQuestionType.QUIZ, QuizQuestionType.SINGLE_CHOICE].includes(
      question.type
    );
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private convertFormControlAnswerToQuestionAnswer(questionControlAnswer): { text: string } | any {
    if (questionControlAnswer.options || questionControlAnswer.fileInfo) {
      return questionControlAnswer;
    }
    const { text } = questionControlAnswer ?? { text: '' };
    return { text };
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private convertFormControlValuesToQuestions(questionControlValues) {
    // @ts-expect-error TS7006
    return questionControlValues.map(questionControlValue =>
      this.convertFormControlValueToQuestion(questionControlValue)
    );
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private convertFormControlValueToQuestion(questionControlValue) {
    const { answer, ...question } = questionControlValue;
    question.answer = this.convertFormControlAnswerToQuestionAnswer(answer);
    if (question.type === QuizQuestionType.SINGLE_CHOICE || question.type === QuizQuestionType.MULTIPLE_CHOICE) {
      // @ts-expect-error TS7006
      question.options.sort((optionA, optionB) => optionA.other - optionB.other);
    }

    return question;
  }

  private convertQuestionToFormControl(quizQuestion: IModuleQuizQuestion): UntypedFormControl {
    const { id, question, type, answer: questionAnswer, options } = quizQuestion;
    const isQuestionAnswered = !!questionAnswer && !questionAnswer.dirty;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formControlValue = { id, question, type } as any;
    if (questionAnswer) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { dirty, ...answer } = questionAnswer;
      formControlValue.answer = answer;
    } else {
      formControlValue.answer = { text: null };
    }

    if (options && options.length) {
      formControlValue.options = options;
    }

    return this._formBuilder.control({
      value: formControlValue,
      disabled: this._editableAnswers != null ? !this._editableAnswers : isQuestionAnswered
    });
  }

  // @ts-expect-error TS7006
  private convertQuestionsToFormControls(questions): UntypedFormControl[] {
    // @ts-expect-error TS7006
    return (questions && questions.map(question => this.convertQuestionToFormControl(question))) || [];
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private onChange = (_: any) => {};

  private onTouched: () => void = () => {};
}

export function quizzesAnswersMapper(
  quizzesAnswers: {
    id: number;
    answer: {
      text: string;
      options: { id: number }[];
    };
  }[]
) {
  return quizzesAnswers.map(({ id: questionId, answer: { text, options } }) => ({
    questionId,
    text: text,
    options: options?.map(({ id }) => id)
  }));
}
