<ng-container *ngIf="quizForm" [formGroup]="quizForm">
  <app-module-quiz
    formControlName="questions"
    [editableAnswers]="!notEditableAnswers"
    class="module-quiz"
    [displayKey]="false"
    [isCorrectDisplay]="false"></app-module-quiz>
</ng-container>

<div class="buttons">
  <app-button
    *ngIf="canRetryQuiz"
    (click)="retryQuiz.emit({ moduleId: module.id })"
    className="btn-lite btn-lg _try-again-button"
    i18n="@@tryAgain">
    Try again
  </app-button>

  <app-button
    *ngIf="canSubmitQuiz"
    (click)="emitSubmitQuiz()"
    [disabled]="allTextAnswersEmpty || isSubmitted"
    className="btn-primary btn-lg"
    class="submit-answers"
    i18n="@@submitAnswers">
    Submit answers
  </app-button>

  <app-button
    *ngIf="canFinishQuiz"
    (click)="finishQuiz.emit({ moduleId: module.id })"
    className="btn-primary btn-lg _finish-button"
    i18n="@@finishTest">
    Finish test
  </app-button>
</div>
