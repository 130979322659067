import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Observable, of, ReplaySubject } from 'rxjs';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { switchMap, take } from 'rxjs/operators';

@Injectable()
export class StripeJsService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _stripe: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _stripeSubject: ReplaySubject<any> = new ReplaySubject<any>(1);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get stripe$() {
    return this._stripeSubject.asObservable();
  }

  constructor(
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID) private readonly _platformId,
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {
    this._runtimeConfigService.configuration$
      .pipe(
        take(1),
        switchMap(() => {
          let request = of<unknown>();

          if (isPlatformBrowser(_platformId) && this._runtimeConfigService.get('stripeEnabled')) {
            if (this._stripe) {
              this._stripeSubject.next(this._stripe);
            } else {
              request = this.appendStripeJsScriptToBody();
            }
          }

          return request;
        })
      )
      .subscribe((stripe: unknown) => {
        // @ts-expect-error TS2571
        if (stripe && stripe['elements']) {
          // @ts-expect-error TS2571
          const result = { stripe, elements: stripe['elements']() };
          this._stripeSubject.next(result);
          this._stripe = result;
        }
      });
  }

  // ATTENTION: this empty method is a hack to make angular injector create instance of this class,
  // init logic left in constructor
  initialize(): void {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private appendStripeJsScriptToBody() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Observable<any>(observer => {
      const script = this._document.createElement('script');

      script.src = 'https://js.stripe.com/v3/';
      script.onload = () => {
        const stripe = Stripe(this._runtimeConfigService.get('STRIPE_PUBLISH_KEY'));
        observer.next(stripe);
        observer.complete();
      };

      this._document.getElementsByTagName('body')[0].appendChild(script);
    });
  }
}
