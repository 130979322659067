import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UiCardModule } from '@app/modules/ui-kit/_base/ui-card';
import { PuiAvatarGroupModule, PuiAvatarModule } from '@awarenow/profi-ui-core';
import { AvatarGroupModule } from '@app/modules/ui-kit/_base/avatar-group/avatar-group.module';
import { UserInfoComponent } from './user-info.component';

@NgModule({
  declarations: [UserInfoComponent],
  imports: [CommonModule, RouterModule, UiCardModule, PuiAvatarGroupModule, PuiAvatarModule, AvatarGroupModule],
  exports: [UserInfoComponent]
})
export class UserInfoModule {}
