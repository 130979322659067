import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { GuideRelationTypes } from '@app/core/users/types';
import { customValidatorWrapper } from '@app/screens/guide/guide-profile/components/guide-edit-profile/form-validators/custom-validator-wrapper';
import { EMAIL_REGEXP } from '@app/shared/constants';

const DEFAULT_MAX_LENGTH = 254;

class GuideClientDetailsField<T> {
  constructor(
    readonly label: string,
    readonly value: T,
    readonly editable = false,
    readonly emptyLabel?: string,
    readonly editorTemplate?: string,
    readonly control?: UntypedFormControl,
    readonly selfEditor?: boolean
  ) {}
}

export class GuideClientDetailsModel {
  readonly firstName = new GuideClientDetailsField(
    `First name`,
    this._raw.firstName,
    this._raw.type === GuideRelationTypes.GUIDE_CONTACT,
    'Add first name',
    undefined,
    new UntypedFormControl(this._raw?.firstName)
  );
  readonly lastName = new GuideClientDetailsField(
    `Last name`,
    this._raw.lastName,
    this._raw.type === GuideRelationTypes.GUIDE_CONTACT,
    'Add last name',
    undefined,
    new UntypedFormControl(this._raw?.lastName)
  );
  readonly email = new GuideClientDetailsField(
    `E-mail`,
    this._raw?.email,
    this._raw.type === GuideRelationTypes.GUIDE_CONTACT,
    'Add email',
    undefined,
    new UntypedFormControl(this._raw?.email, {
      validators: [
        customValidatorWrapper(Validators.required, `Email required.`),
        customValidatorWrapper(Validators.pattern(EMAIL_REGEXP), `Correct email is required.`),
        customValidatorWrapper(
          Validators.maxLength(DEFAULT_MAX_LENGTH),
          `Max length is ${DEFAULT_MAX_LENGTH} characters.`
        )
      ]
    })
  );
  readonly phone = new GuideClientDetailsField(
    `Phone`,
    this._raw?.phone,
    true,
    `Add phone number`,
    'phoneEditorFormTemplate',
    new UntypedFormControl(this._raw?.phone)
  );
  readonly organization = new GuideClientDetailsField(
    `Organization`,
    this._raw?.organization,
    true,
    'Add organization',
    undefined,
    new UntypedFormControl(this._raw?.organization)
  );
  readonly stage = new GuideClientDetailsField(
    `Stage`,
    this._raw.tag,
    true,
    `Add stage`,
    'stageEditorFormTemplate',
    new UntypedFormControl(this._raw.tag),
    true
  );
  readonly tag = new GuideClientDetailsField(
    `Tag`,
    this._raw.customFields?.client_tags,
    true,
    `Add tag`,
    'tagEditorFormTemplate',
    new UntypedFormControl(this._raw.customFields?.client_tags),
    true
  );

  constructor(private readonly _raw: CRMClient) {}
}
