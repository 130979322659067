import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { NotificationsService } from '@awarenow/profi-ui-core';
import config from '@app/core/config/config';
import {
  IServiceWorkspaceAssignee,
  SessionTemplateInterface,
  SessionTemplatePatchInterface,
  SessionTemplatePersistenceAttributes
} from '../types';
import { WorkspaceUser } from '@app/modules/workspaces/types';
import { AuthService } from '@app/core/auth/services';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { SessionTemplateCreatedEvent } from '@app/core/analytics/events/session-template-created.event';

@Injectable()
export class SessionTemplateServerStoreService {
  private readonly FTCST = `Failed to create session template`;

  private readonly FTLST = `Failed to load session template`;

  private readonly FTLSTs = `Failed to load session templates`;

  private readonly FTLTO = `Failed to load templates options`;

  private readonly FTUST = `Failed to update session template`;

  get endpoint(): string {
    return `${config.apiPath}/user/${this._authService.getUserRole()}/services/templates/sessions`;
  }

  constructor(
    private _http: HttpClient,
    private _notifications: NotificationsService,
    private readonly _authService: AuthService,
    private analyticsService: AnalyticsService
  ) {}

  createTemplate$(template: SessionTemplateInterface): Observable<SessionTemplatePersistenceAttributes> {
    return this.execute$(
      this._http
        .post<SessionTemplatePersistenceAttributes>(this.endpoint, template)
        .pipe(tap(() => this.analyticsService.emitEvent(new SessionTemplateCreatedEvent()))),
      this.FTCST
    );
  }

  getTemplate$(templateId: number, asOwner = false): Observable<SessionTemplateInterface> {
    const params: {
      [param: string]: string | number | boolean;
    } = {};
    if (asOwner) {
      params['asOwner'] = true;
    }

    return this.execute$(
      this._http
        .get<{ template: SessionTemplateInterface }>(`${this.endpoint}/${templateId}`, {
          params
        })
        .pipe(map(({ template }) => template)),
      this.FTLST
    );
  }

  getTemplateAssignees$(templateId: number): Observable<IServiceWorkspaceAssignee[]> {
    return this.execute$(
      this._http.get<{ team: IServiceWorkspaceAssignee[] }>(`${this.endpoint}/${templateId}/assignees`).pipe(
        map(response => {
          return response.team;
        })
      ),
      this.FTLST
    );
  }

  addAssignee$(templateId: number, members: WorkspaceUser[]): Observable<unknown> {
    return this.execute$(
      this._http.post(`${this.endpoint}/${templateId}/assignees`, {
        ids: members.map((member: WorkspaceUser) => member.UserId)
      }),
      this.FTLST
    ).pipe(
      tap(() =>
        members.forEach((member: WorkspaceUser) =>
          this._notifications.success(`${member.firstName} ${member.lastName} ${`added to hosts`}`)
        )
      )
    );
  }

  removeAssignee$(templateId: number, guideId: number): Observable<unknown> {
    return this.execute$(this._http.delete(`${this.endpoint}/${templateId}/assignees/${guideId}`), this.FTLST);
  }

  getTemplates$(): Observable<SessionTemplateInterface[]> {
    return this.execute$(this._http.get<SessionTemplateInterface[]>(this.endpoint), this.FTLSTs);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getTemplatesOptions$(): Observable<any> {
    return this.execute$(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this._http.get<any>(`${this.endpoint}/options`).pipe(map(({ options }) => options)),
      this.FTLTO
    );
  }

  updateTemplate$(
    templateId: number,
    templatePatch: SessionTemplatePatchInterface
  ): Observable<SessionTemplatePersistenceAttributes> {
    return this.execute$(
      this._http.put<SessionTemplatePersistenceAttributes>(`${this.endpoint}/${templateId}`, templatePatch),
      this.FTUST
    );
  }

  private execute$<T>(httpRequest$: Observable<T>, errorMessage: string): Observable<T> {
    return httpRequest$.pipe(
      catchError(error => {
        this._notifications.error(errorMessage);
        return throwError(error);
      })
    );
  }
}
