import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, merge, Observable, partition, Subject, throwError } from 'rxjs';
import { catchError, distinct, filter, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { AuthService } from '@app/core/auth/services/auth.service';
import { SystemNotificationsService } from '@app/core/notifications/system-notifications.service';
import { MarketplaceProgramApiService } from '@app/modules/marketplace-filters/services/marketplace-program-api.service';
import config from '@app/core/config/config';
import { SocketService } from '@app/core/socket/socket.service';
import { UserRoles } from '@app/shared/enums/user-roles';
import { IPublicProgramItem, ProgramGuide } from '@app/shared/interfaces/programs';
import { ISystemNotification, SystemNotificationTypes } from '@app/modules/notifications/types';
import { SocketEvents } from '@app/cdk/enums';

@Injectable()
export class ClientProgramsService implements OnDestroy {
  private readonly API_ENDPOINT = `${config.apiPath}/user/client`;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _accessibleProgramsCount$ = new BehaviorSubject<number>(0);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _doesClientHaveAccessiblePrograms$ = new BehaviorSubject<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _doesClientHaveUnenrolledPrograms$ = new BehaviorSubject<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _wasEnrolledProgram$ = new Subject<{ programId: number } | null>();

  private destroy$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _notifications: ISystemNotification[] = [];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _programs$ = new BehaviorSubject<IPublicProgramItem[]>([]);

  get doesClientHaveAccessiblePrograms$(): Observable<boolean> {
    return this._doesClientHaveAccessiblePrograms$.asObservable();
  }

  get doesClientHaveUnenrolledPrograms$(): Observable<boolean> {
    return this._doesClientHaveUnenrolledPrograms$.asObservable();
  }

  get wasEnrolledProgram$(): Observable<{ programId: number }> {
    // @ts-expect-error TS2322
    // eslint-disable-next-line id-length
    return this._wasEnrolledProgram$.asObservable().pipe(filter(v => !!v));
  }

  get programs$(): Observable<IPublicProgramItem[]> {
    // eslint-disable-next-line id-length
    return this._programs$.asObservable().pipe(filter(v => !!v?.length));
  }

  constructor(
    private _http: HttpClient,
    private _auth: AuthService,
    private _notificationsService: NotificationsService,
    private _marketplaceProgramApiService: MarketplaceProgramApiService,
    private _systemNotifications: SystemNotificationsService,
    private _socket: SocketService
  ) {
    const [authorised$, signedOut$] = partition(_auth.onAuth(), user => !!user);

    combineLatest([
      authorised$.pipe(filter(user => user.RoleId === UserRoles.CLIENT)),
      _socket.onProgramsChange().pipe(filter(data => data?.programDetails?.type === SocketEvents.PROGRAM_ENROLLED))
    ])
      .pipe(map(([, { programDetails }]) => programDetails))
      .subscribe(data => {
        this._wasEnrolledProgram$.next(data);
      });

    const programsTotalCount$ = combineLatest([
      this._getOfferedProgramsCount$(),
      this._marketplaceProgramApiService.getProgramsCount$()
    ]).pipe(map(([countOffered, countMarketplace]) => countOffered + countMarketplace));

    const newProgramsInvitationsAvailable$ = this._systemNotifications.programNotifications$.pipe(
      map(notifications =>
        notifications.filter(notification =>
          [SystemNotificationTypes.PROGRAM_INVITE_INFORMATIVE, SystemNotificationTypes.PROGRAM_INVITE_PREPAID].includes(
            notification.type
          )
        )
      ),
      filter(notifications => notifications.length > 0),
      map(notifications => {
        this._notifications = notifications;
        const orderedNotifications = notifications
          // @ts-expect-error TS2532
          .map(({ details, dateCreated, isRead }) => `${details.programId}|${dateCreated}|${isRead}`)
          .sort();
        return [...new Set(orderedNotifications)].join('-');
      }),
      distinct()
    );

    merge(
      authorised$.pipe(filter(user => user.RoleId === UserRoles.CLIENT)),
      _socket.onProgramsChange(),
      newProgramsInvitationsAvailable$
    )
      .pipe(
        mergeMap(() => programsTotalCount$),
        takeUntil(this.destroy$)
      )
      .subscribe(count => {
        this._accessibleProgramsCount$.next(count);
      });

    merge(
      authorised$.pipe(filter(user => user.RoleId === UserRoles.CLIENT)),
      _socket.onProgramsChange(),
      newProgramsInvitationsAvailable$
    )
      .pipe(
        mergeMap(() => this.getOfferedPrograms$()),
        map(programs => {
          this._programs$.next(programs);
          return this._notifications.some(({ isRead }) => !isRead);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(state => {
        this._doesClientHaveUnenrolledPrograms$.next(state);
      });

    signedOut$.pipe(takeUntil(this.destroy$)).subscribe(() => this._accessibleProgramsCount$.next(0));

    this._accessibleProgramsCount$
      .pipe(
        filter(count => count > 0),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this._doesClientHaveAccessiblePrograms$.next(true));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getOfferedPrograms$(): Observable<IPublicProgramItem[]> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.get<{ programs: any }>(`${this.API_ENDPOINT}/guides/programs/my`).pipe(
      map(({ programs }) =>
        // @ts-expect-error TS7031
        programs.map(({ guide, ...program }) => ({
          ...program,
          guide: new ProgramGuide(guide.id, guide.firstName, guide.lastName, guide.photo, guide.namedUrl, null, null)
        }))
      ),
      catchError(error => {
        const message = `Cannot load programs`;
        this._notificationsService.error(message);
        return throwError(error);
      })
    );
  }

  markNotificationRead(id: number): void {
    // @ts-expect-error TS2532
    const notification = this._notifications.find(i => i.details.programId === id && !i.isRead);

    if (notification) {
      this._systemNotifications.markNotificationsRead([notification.id]);
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _getOfferedProgramsCount$(): Observable<number> {
    return this._http.get<{ count: number }>(`${this.API_ENDPOINT}/guides/programs/my/count`).pipe(
      // eslint-disable-next-line id-length
      catchError(e => {
        return throwError(e);
      }),
      map(({ count }) => count)
    );
  }
}
