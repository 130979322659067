import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { AuthService } from '@app/core/auth/services/auth.service';
import { LocalStorageService } from '@app/core/local-storage/local-storage.service';
import { ClientInvitationActivationService } from './client-invitation-activation.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-client-invitation-activation',
  template: ``,
  providers: [ClientInvitationActivationService]
})
export class ClientInvitationActivationComponent implements OnInit, OnDestroy {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _subscription: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _invitationActivator: ClientInvitationActivationService,
    private _notifications: NotificationsService,
    private _auth: AuthService,
    private _storage: LocalStorageService
  ) {}

  ngOnInit(): void {
    this._subscription = this._route.paramMap
      .pipe(
        map(params => params.get('hash')),
        // @ts-expect-error TS2345
        mergeMap(hash => this._invitationActivator.activateClientInvitation$(hash)),
        catchError(error => {
          const timeOut = 5000;
          const title = `Client invitation activation failed`;
          this._notifications.error(title, error.error.msg, { timeOut });
          if (error.error && error.error.payload && error.error.payload.program && error.error.payload.program.id) {
            const redirectUrl = `/programs/${error.error.payload.program.id}`;
            this._router.navigate([redirectUrl], { replaceUrl: true });
          } else {
            this._router.navigate(['/dashboard'], { replaceUrl: true });
          }
          return timer(timeOut + 100);
        })
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(data => {
        if (
          this._auth.redirectUrl &&
          !this._auth.redirectUrl.includes('auth') &&
          !this._auth.redirectUrl.includes('invitations')
        ) {
          this._router.navigate([this._auth.redirectUrl], { replaceUrl: true });
          this._auth.redirectUrl = '';
        } else {
          if (data) {
            let redirectUrl = '/client/settings/edit-profile';

            if (data.guide && data.guide.namedUrl) {
              // TODO: use global constant instead of magic string
              this._storage.setItem('inviter_guide_named_url', data.guide.namedUrl);
            }

            if (data.program && data.program.id) {
              redirectUrl =
                data.program.invitationType && data.program.invitationType === 'program_prepaid'
                  ? `/client/programs/${data.program.id}/modules`
                  : `/programs/${data.program.id}`;
            }

            this._router.navigate([redirectUrl], { replaceUrl: true });
          }
        }
      });
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }
}
