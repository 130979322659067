import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BrandingService } from '@app/core/branding/branding.service';
import { FormService } from '@app/core/form/form.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { AuthProviders } from '@app/shared/enums/auth-providers';
import { GlobalConfig } from '@cnf/types';

import { AlternativeAuthProvidersService } from '../../services/alternative-auth-providers.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-sign-up-alternative',
  templateUrl: './sign-up-alternative.component.html',
  styleUrls: [
    '../../../../screens/auth/auth.scss',
    '../../styles/common.scss',
    '../../styles/auth.scss',
    './sign-up-alternative.component.scss'
  ]
})
export class SignUpAlternativeComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  config = { urlPrivacy: '#', urlTerms: '#', showNewsletter: false };

  isClientDisabled = true;

  isFetching = false;

  showRoleSelectContainer = true;

  platformName: string;

  // @ts-expect-error TS2564
  signUpForm: UntypedFormGroup;

  @Output()
  afterSignIn = new EventEmitter();

  @Input()
  onlyClient = false;

  @Input()
  onlyGuide = false;

  @Input()
  separatedAuth = false;

  private readonly isGuideSSOEnabled: boolean;
  private readonly isClientSSOEnabled: boolean;

  constructor(
    private readonly _authAlternative: AlternativeAuthProvidersService,
    private readonly _brandingService: BrandingService,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _formService: FormService,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {
    this.platformName = this._runtimeConfigService.get('platformName') || '';

    this.isGuideSSOEnabled = this._runtimeConfigService.get('isGuideSSOEnabled') || false;
    this.isClientSSOEnabled = this._runtimeConfigService.get('isClientSSOEnabled') || false;

    this.showRoleSelectContainer = this._authAlternative.signUpProvider !== AuthProviders.SSO && !this.separatedAuth;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnInit() {
    this._buildForm();
    this._brandingService.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => this._setConfig(config));

    this._authAlternative
      .onAuth()
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        if (!val) {
          return;
        }
        this.isFetching = false;
        if (!this.signUpForm.controls.promoCode.value.trim()) {
          this.afterSignIn.emit();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  signUp() {
    if (
      this._formService.markInvalidForm(this.signUpForm) ||
      this.isFetching ||
      !this.signUpForm.value.agreeWithTerms
    ) {
      return;
    }

    this.isFetching = true;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const signUpParams: any = {
      subscribeNews: this.signUpForm.value.subscribeNews
    };

    signUpParams.isClient = this.onlyClient ? true : this.signUpForm.value.isClient;
    signUpParams.isGuide = this.onlyGuide ? true : this.signUpForm.value.isGuide;

    // if sso enabled only for guide
    if (
      !this.showRoleSelectContainer &&
      this._authAlternative.signUpProvider === AuthProviders.SSO &&
      this.isGuideSSOEnabled &&
      !this.isClientSSOEnabled
    ) {
      signUpParams.isGuide = true;
    }

    // if sso enabled only for client
    if (
      !this.showRoleSelectContainer &&
      this._authAlternative.signUpProvider === AuthProviders.SSO &&
      this.isClientSSOEnabled &&
      !this.isGuideSSOEnabled
    ) {
      signUpParams.isClient = true;
    }

    if (this.signUpForm.value.promoCode) {
      signUpParams.promoCode = this.signUpForm.value.promoCode;
    }

    this._authAlternative.signUp(signUpParams);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  trimValue(formControl) {
    formControl.setValue(formControl.value.trim());
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _buildForm(): void {
    const extraFields =
      this.onlyClient || this.onlyGuide
        ? {}
        : {
            isGuide: [false],
            isClient: [true]
          };

    this.signUpForm = this._formBuilder.group(
      {
        promoCode: [''],
        subscribeNews: [false],
        agreeWithTerms: [false, [Validators.requiredTrue]],
        ...extraFields
      },
      {
        validators: [
          (group: UntypedFormGroup) => {
            if (this.onlyClient || this.onlyGuide) {
              return null;
            }

            const { isGuide, isClient } = group.controls;

            if (!isGuide.value && !isClient.value) {
              return { roleError: true };
            }

            return null;
          }
        ]
      }
    );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _setConfig({ urlPrivacy, urlTerms, showNewsletter }: GlobalConfig): void {
    this.config.urlTerms = urlTerms;
    this.config.urlPrivacy = urlPrivacy;
    this.config.showNewsletter = showNewsletter;
  }
}
