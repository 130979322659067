import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, DefaultValueAccessor, UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { QuillImageUploadDriver } from '@app/modules/quill-editor-image/types';
import { dataURIToBlob } from '@app/shared/utils/data-uri-to-blob';
import { debounceTime, filter, switchMap, takeUntil } from 'rxjs/operators';
import { combineLatest, of, Subject, Subscription } from 'rxjs';
import { registerModules } from '@app/screens/blog/components/blog-article-form/modules';
import { registerVideoBlot } from '@app/screens/blog/components/blog-article-form/parchment-blot-factories/video-blot-factory';
import { HttpEventType } from '@angular/common/http';
import { QuillImageDownloaderService } from '@app/modules/quill-editor-image/quill-image-downloader.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FileStackService } from '@app/core/filestack/filestack.service';
import { FileStackFile } from '@app/shared/interfaces/filestack';
import { IframeUploaderComponent } from '@app/modules/quill-editor-image/iframe-uploader/iframe-uploader.component';
import { HtmlUploaderComponent } from '@app/modules/quill-editor-image/html-uploader/html-uploader.component';
import { registerHtmlBlot } from '@app/screens/blog/components/blog-article-form/parchment-blot-factories/html-blot-factory';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { VideoUploaderComponent } from './video-uploader/video-uploader.component';
import { cleanHTML } from '@app/shared/utils/quill-utils';
import { QuillEntityTypes } from '@app/shared/enums/quill-entity-types';

// Because quill uses `document` directly, we cannot `import` during SSR
// instead, we load dynamically via `require('quill')` in `ngOnInit()`
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let require: any;

// tslint:disable-next-line:variable-name
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let Quill: any = null;

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-quill-editor-image',
  template: `
    <quill-editor
      #quillEditorComponent
      [modules]="mModules"
      [format]="format"
      [placeholder]="placeholder"
      [preserveWhitespace]="true"
      (onEditorCreated)="customizeEditor($event)"
      (onContentChanged)="contentChanged.emit($event)"></quill-editor>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuillEditorImageComponent),
      multi: true
    }
  ]
})
export class QuillEditorImageComponent implements OnInit, OnDestroy, ControlValueAccessor {
  // @ts-expect-error TS7008
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _quillEditor;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _toolbarDomElement: HTMLElement;

  private readonly isBrowser: boolean;

  // @ts-expect-error TS2564
  private id: string;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _uploadDriver: QuillImageUploadDriver;

  private onDestroy$ = new Subject<void>();

  // @ts-expect-error TS2564
  private fileUrlsSubscription: Subscription;

  private defaultDriverSubscription: Subscription;

  form = this._formBuilder.group({ quill: ['', []] });

  @ViewChild('quillEditorComponent', { static: true })
  // @ts-expect-error TS2564
  private defaultValueAccessor: DefaultValueAccessor;

  modulesDefault = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }],
        ['link', 'image', 'video', 'html'],
        ['fs']
      ],
      handlers: {
        fs: () => this.handleFileStackEmbedding(),
        // iframe: () => this.handleIframeEmbedding(),
        html: () => this.handleHtmlEmbedding()
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get mModules() {
    return Object.assign(this.modulesDefault, this.modules || {});
  }

  @Input()
  format: 'html' | 'object' | 'text' | 'json' = 'html';

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modules: any;

  @Input()
  // @ts-expect-error TS2564
  placeholder: string;

  @Input()
  set uploadDriver(driver: QuillImageUploadDriver) {
    this._uploadDriver = driver;

    if (this.fileUrlsSubscription) {
      this.fileUrlsSubscription.unsubscribe();
    }

    if (this.defaultDriverSubscription) {
      this.defaultDriverSubscription.unsubscribe();
    }

    this.fileUrlsSubscription = driver.fileUrls$
      .pipe(
        filter(({ id }) => id === this.id),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ url }) => this.setQuillImage(url));
  }

  @Input()
  extendedVideoUploader = false;

  @Input()
  // @ts-expect-error TS2564
  qaId: string;

  @Output()
  // @ts-expect-error TS7008
  contentChanged = new EventEmitter<{ html: string; text: string; delta }>();

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures, @typescript-eslint/explicit-function-return-type
  get uploadDriver() {
    return this._uploadDriver;
  }

  private static createRandomFileName(type: string): string {
    // @ts-expect-error TS2531
    return [Math.floor(Math.random() * 1e12), '-', new Date().getTime(), '.', type.match(/^image\/(\w+)$/i)[1]].join(
      ''
    );
  }

  constructor(
    private readonly _renderer: Renderer2,
    private readonly _elementRef: ElementRef,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _zone: NgZone,
    private readonly _quillImageDownloaderService: QuillImageDownloaderService,
    private readonly _modal: NgbModal,
    private readonly _fileStackService: FileStackService,
    private readonly _runtimeConfigService: RuntimeConfigService,
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID) private readonly _platformId
  ) {
    this.isBrowser = isPlatformBrowser(_platformId);

    this.uploadDriver = new QuillImageUploadDriver();
    // @ts-expect-error TS2565
    this.defaultDriverSubscription = this._uploadDriver.uploadRequests$
      .pipe(
        switchMap(({ id, file }) =>
          combineLatest([of(id), this._quillImageDownloaderService.uploadFile$(file).pipe(debounceTime(200))])
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(([id, event]) => {
        if (event.type === HttpEventType.Response) {
          const { url } = event.body;
          if (!url) {
            return;
          }
          this.uploadDriver.passUrl(id, url);
        }
      });
  }

  ngOnInit(): void {
    this.id = Math.floor(Math.random() * 1e12).toString();

    if (this.isBrowser) {
      if (!Quill) {
        Quill = require('quill');
        registerModules(Quill);
        registerVideoBlot(Quill);
        // registerIframeBlot(Quill);
        registerHtmlBlot(Quill);
      }
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customizeEditor(editor: any): void {
    if (editor.editor.scroll.domNode) {
      this._renderer.setAttribute(editor.editor.scroll.domNode, 'data-qa-id', this.qaId);
    }

    this._toolbarDomElement = this._elementRef.nativeElement.querySelector('.ql-toolbar');
    this._quillEditor = editor;
    const toolbar = this._quillEditor.getModule('toolbar');
    toolbar.addHandler('image', () => this.handleImageEmbedding());
    // @ts-expect-error TS7006
    this._quillEditor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
      // @ts-expect-error TS7034
      const ops = [];
      // @ts-expect-error TS7006
      delta.ops.forEach(op => {
        if (op.insert && typeof op.insert === 'string') {
          ops.push({
            insert: op.insert,
            attributes: op.attributes
          });
        }
      });
      // @ts-expect-error TS7005
      delta.ops = ops;
      return delta;
    });
    if (this.extendedVideoUploader) {
      toolbar.addHandler('video', () => this.handleVideoEmbedding());
    }
  }

  getContentMarkup(): string {
    return this._quillEditor ? this._quillEditor.container.querySelector('.ql-editor').innerHTML : null;
  }

  getContentText(): string {
    return this._quillEditor ? this._quillEditor.getText() : null;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  registerOnChange(fn: any) {
    this.defaultValueAccessor.registerOnChange(fn);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any) {
    this.defaultValueAccessor.registerOnTouched(fn);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setDisabledState(isDisabled: boolean) {
    this.defaultValueAccessor.setDisabledState(isDisabled);
  }

  setQuillDownloadLink(fileName: string, url: string): void {
    if (this.isBrowser) {
      const range = this._quillEditor.getSelection(true);
      this._quillEditor.insertText(range.index + 1, fileName, 'link', url, Quill.sources.USER);
      this._quillEditor.setSelection(range.index + 12, Quill.sources.SILENT);
    }
  }

  setQuillImage(imageUrl: string): void {
    if (this.isBrowser) {
      const range = this._quillEditor.getSelection(true);
      this._quillEditor.insertEmbed(range.index + 1, 'image', imageUrl, Quill.sources.USER);
      this._quillEditor.insertText(range.index + 2, '\n', Quill.sources.USER);
      this._quillEditor.setSelection(range.index + 3, Quill.sources.SILENT);
    }
  }

  setQuillVideo(videoUrl: string): void {
    if (this.isBrowser) {
      const range = this._quillEditor.getSelection(true);
      this._quillEditor.insertEmbed(range.index + 1, 'video', videoUrl, Quill.sources.USER);
      this._quillEditor.insertText(range.index + 2, '\n', Quill.sources.USER);
      this._quillEditor.setSelection(range.index + 3, Quill.sources.SILENT);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  writeValue(obj: any) {
    this.defaultValueAccessor.writeValue(obj);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private createQuillFileInput() {
    const fileInput = this._renderer.createElement('input');

    this._renderer.setProperty(fileInput, 'type', 'file');
    this._renderer.setProperty(fileInput, 'accept', 'image/*');
    this._renderer.setProperty(fileInput, 'capture', 'camera');
    this._renderer.addClass(fileInput, 'ql-image');

    this._zone.runOutsideAngular(() =>
      // eslint-disable-next-line id-length
      this._renderer.listen(fileInput, 'change', e => {
        this.onQuillImageInputChange(e);
        fileInput.value = '';
      })
    );

    this._renderer.appendChild(this._toolbarDomElement, fileInput);

    return fileInput;
  }

  private handleImageEmbedding(): void {
    // @ts-expect-error TS2322
    let fileInput: HTMLElement = this._toolbarDomElement.querySelector('input.ql-image[type=file]');

    if (fileInput == null) {
      fileInput = this.createQuillFileInput();
    }

    fileInput.click();
  }

  private handleVideoEmbedding(): void {
    const modalRef: NgbModalRef = this._modal.open(VideoUploaderComponent, { size: 'lg' });

    modalRef.result.then(data => this.setQuillEntity(QuillEntityTypes.VIDEO, data)).catch(() => {});
  }

  private handleFileStackEmbedding(): void {
    this._fileStackService.openUploadWindow((file: FileStackFile) => {
      const url = this._runtimeConfigService.get('filestackS3Url') + file.key;
      if (/(\.jpg|\.jpeg|\.png)$/i.test(file.filename)) {
        this.setQuillImage(url);
      } else {
        this.setQuillDownloadLink(file.filename, url);
      }
    });
  }

  // temporary unused
  private handleIframeEmbedding(): void {
    const modalRef: NgbModalRef = this._modal.open(IframeUploaderComponent, { size: 'sm' });

    modalRef.result.then(data => this.setQuillEntity(QuillEntityTypes.IFRAME, data)).catch(() => {});
  }

  private handleHtmlEmbedding(): void {
    const modalRef: NgbModalRef = this._modal.open(HtmlUploaderComponent, { size: 'sm' });

    modalRef.result.then(data => this.setQuillEntity(QuillEntityTypes.HTML, data)).catch(() => {});
  }

  // @ts-expect-error TS7006
  private handleImageDroppedOrPasted(imageDataUri, type: string): void {
    if (!type) {
      type = 'image/png';
    }

    const blob = dataURIToBlob(imageDataUri);
    const fileName = QuillEditorImageComponent.createRandomFileName(type);
    const file = new File([blob], fileName);

    this._uploadDriver.uploadFile(this.id, file);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line id-length
  private onQuillImageInputChange(e): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const file = (e as any).target.files[0];
    if (!file || !/(\.jpg|\.jpeg|\.png)$/i.test(file.name)) {
      return;
    }

    this._uploadDriver.uploadFile(this.id, file);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setQuillEntity(type: QuillEntityTypes, value: string | any): void {
    if (type === QuillEntityTypes.HTML) {
      value.html = cleanHTML(value.html);
    }

    if (this.isBrowser) {
      const range = this._quillEditor.getSelection(true);
      this._quillEditor.insertEmbed(range.index + 1, type, value, Quill.sources.USER);
      this._quillEditor.insertText(range.index + 2, '\n', Quill.sources.USER);
      this._quillEditor.setSelection(range.index + 3, Quill.sources.SILENT);
    }
  }
}
