import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ClipboardModule } from 'ngx-clipboard';
import { SharedModule } from '@app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PoweredByLinkModule } from '@app/modules/powered-by-link/powered-by-link.module';
import { ServiceLandingComponent } from './components/service-landing/service-landing.component';
import { ServiceLandingTabComponent } from './components/service-landing-tab/service-landing-tab.component';
import { ServiceBadgeComponent } from './components/service-badge/service-badge.component';
import { ServiceHostedComponent } from '@app/modules/services/components/service-hosted/service-hosted.component';
import { WorkspacesModule } from '@app/modules/workspaces/workspaces.module';
import { GuideServiceModule } from '@app/modules/guide-service/guide-service.module';
import {
  PuiAvatarModule,
  PuiButtonModule,
  PuiIconModule,
  PuiLetModule,
  PuiLinkModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { ServiceHostComponent } from './components/service-host/service-host.component';
import { NavTabComponent } from '@app/modules/services/components/nav-tab/nav-tab.component';
import { ServiceHostsComponent } from './components/service-hosts/service-hosts.component';

@NgModule({
  imports: [
    ClipboardModule,
    CommonModule,
    RouterModule,
    SharedModule,
    NgbModule,
    PoweredByLinkModule,
    WorkspacesModule,
    GuideServiceModule,
    PuiIconModule,
    PuiAvatarModule,
    PuiTypographyModule,
    PuiButtonModule,
    PuiLinkModule,
    PuiLetModule
  ],
  exports: [
    NavTabComponent,
    ServiceLandingComponent,
    ServiceLandingTabComponent,
    ServiceBadgeComponent,
    ServiceHostedComponent
  ],
  declarations: [
    ServiceLandingComponent,
    ServiceLandingTabComponent,
    ServiceBadgeComponent,
    ServiceHostedComponent,
    ServiceHostComponent,
    NavTabComponent,
    ServiceHostsComponent
  ]
})
export class ServicesModule {}
