import { NotificationsService } from '@awarenow/profi-ui-core';
import { DateTime } from 'luxon';
import { Observable, of, partition } from 'rxjs';
import { catchError, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticServiceTypes, AnalyticSourceTypes, InternalEvents } from '@app/core/analytics/types';
import { AuthService } from '@app/core/auth/services/auth.service';
import { BrandingService } from '@app/core/branding/branding.service';
import { CurrencyService } from '@app/core/currency/currency.service';
import { GLOBAL_SESSION_STORAGE } from '@app/core/session-storage/session-storage-provider';
import { SessionsService } from '@app/core/session/sessions.service';
import { AuthModalComponent } from '@app/modules/auth/components/auth-modal/auth-modal.component';
import { IGuideService, IServiceBookingOptions, ServiceBookingService } from '@app/modules/book-service';
import { IPayWithModalOptions } from '@app/modules/current-payment/components/pay-with-modal/pay-with-modal.component';
import { PaymentOptionsModalComponent } from '@app/modules/current-payment/components/payment-options-modal/payment-options-modal.component';
import { CurrentPaymentService } from '@app/modules/current-payment/services/current-payment.service';
import { PublicAssignee } from '@app/modules/services/interfaces';
import { getBookingRedirectRoute } from '@app/modules/services/utils/get-booking-path';
import { makeUriFromString } from '@app/screens/blog/utils';
import { GuidePackageService } from '@app/screens/guide/guide-packages/guide-package.service';
import { IServiceWorkspaceAssignee } from '@app/screens/guide/guide-sessions-templates/types';
import { PublicPackage } from '@app/screens/packages/interfaces';
import { SwitchToAlternativeAccountConfirmModalComponent } from '@app/shared/components/switch-to-alternative-account-confirm-modal/switch-to-alternative-account-confirm-modal.component';
import { PaymentOptions } from '@app/shared/enums/payment-options';
import { UserRoles } from '@app/shared/enums/user-roles';
import { WorkspacesTypes } from '@app/shared/enums/workspaces-types';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { modalResultToObservable$ } from '@app/shared/utils/modal-result-to-observable';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ServiceProvidersService } from '@appWidget/screens/book-session/services/service-providers';
import { PuiDestroyService } from '@awarenow/profi-ui-core';
import { GlobalConfig } from '@cnf/types';
import { MetaTagService } from '@libs/services/meta-tag/meta-tag.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PublicPackagesService } from '../../services/public-package.service';
import { LOCATION_ORIGIN } from '../../../../../consts';

@Component({
  selector: 'app-package-landing',
  templateUrl: './package-landing.component.html',
  styleUrls: ['./package-landing.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'ui-app-package-landing' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GuidePackageService, PuiDestroyService, MetaTagService]
})
export class PackageLandingComponent implements OnInit, AfterViewInit, OnChanges {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _config = {
    meta: {
      metaDescriptionPackagePage: null,
      metaImagePackagePage: null,
      metaKeywordsPackagePage: null,
      metaTitlePackagePage: null
    }
  };

  readonly GuideServiceTypes = GuideServiceTypes;

  expand = false;

  assignees: PublicAssignee[] = [];

  @Input()
  package!: PublicPackage;

  @Input()
  set invitation(invitation: string) {
    if (invitation && this.package) {
      this._sessionStorage.setItem(this.buildPackageInvitationKey(this.package.id), invitation);
      // @ts-expect-error TS2345
      this.location.replaceState(this.location.path().split('?').shift());
    }
  }

  @Input()
  isPreview?: boolean;

  get activities(): string[] {
    if (!this.package) {
      return [''];
    }

    return this.package.sessions
      .filter(session => session.template)
      .map(cur => {
        return `${cur.count} x ${cur.template.name}`;
      }, '');
  }

  get isShowEnrollButton(): boolean {
    if (!this.package.enrollOnlyByGuide && !this.package.restrictMultipleEnroll) {
      return true;
    }

    if (this.package.enrollOnlyByGuide) {
      return this.package.isInvited;
    }

    return !(this.package.restrictMultipleEnroll && this.package.isAlreadyEnrolled) || this.package.isInvited;
  }

  get isShowBookSessionButton(): boolean {
    return !!this.package.isAlreadyEnrolled && !this.isShowEnrollButton;
  }

  get isOwnPackage(): boolean {
    if (!this.package || !this.auth.isAuthorized) {
      return false;
    }

    return this.auth.user && this.auth.user.id === this.package.guideId;
  }

  get link(): string {
    if (!this.package || !this.package.id) {
      return '';
    }

    return `${LOCATION_ORIGIN}/packages/${makeUriFromString(this.package.title, this.package.id)}`;
  }

  constructor(
    private currency: CurrencyService,
    private analyticsService: AnalyticsService,
    private auth: AuthService,
    private router: Router,
    private notifications: NotificationsService,
    private location: Location,
    private packageService: PublicPackagesService,
    private sessions: SessionsService,
    private cdr: ChangeDetectorRef,
    private currentPayments: CurrentPaymentService,
    private modal: NgbModal,
    private brandingService: BrandingService,
    private elem: ElementRef,
    private providers: ServiceProvidersService,
    private serviceBooking: ServiceBookingService<IServiceBookingOptions<IGuideService>, void>,
    @Inject(GLOBAL_SESSION_STORAGE) private _sessionStorage: Storage,
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>,
    private metaTagService: MetaTagService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.auth.isPlatformAdmin() && changes.package && this.package?.id) {
      this.providers
        .getServiceProviders<IServiceWorkspaceAssignee['guide'] & { guideId: number }>(
          {
            id: this.package.id,
            type: GuideServiceTypes.PACKAGE
          },
          {
            onlyHosts: true
          }
        )
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe(assignees => {
          this.assignees = assignees as unknown as PublicAssignee[];
          this.cdr.detectChanges();
        });
    }
  }

  ngOnInit(): void {
    this.brandingService.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => {
      this.setConfig(config);
      this.setOGMeta();
    });
  }

  ngAfterViewInit(): void {
    const dropdownMenu = this.elem.nativeElement.querySelector('.dropdown-menu');
    const dropdownMenuHeight = this.activities.length > 3 ? 160 : this.activities.length * 45;
    if (dropdownMenu) {
      dropdownMenu.style.height = `${dropdownMenuHeight}px`;
      const ngScrollbar = this.elem.nativeElement.querySelector('.ng-scroll-content');
      ngScrollbar.style.height = `${dropdownMenuHeight - 32}px`;
    }
  }

  redirectToWidget(): void {
    if (this.isPreview) {
      return;
    }

    if (this.auth.user?.RoleId === UserRoles.GUIDE) {
      this.switchToClientRole();
      return;
    }

    const path = getBookingRedirectRoute(
      this.package.guide.id,
      this.package.workspace?.type || WorkspacesTypes.SOLO,
      this.package.workspace?.id || null
    );
    this.router.navigate([path]).then();
  }

  private activateInvitation(invitationCode: string): void {
    this.packageService
      .activateInvitation$(invitationCode)
      .pipe(
        map(response => response.isCustomerEnrolled),
        catchError(() => of(false)),
        tap(isEnrolled => (this.package.isAlreadyEnrolled = isEnrolled)),
        finalize(() => this.removeInvitation()),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.redirectToWidget());
  }

  private removeInvitation(): void {
    this._sessionStorage.removeItem(this.buildPackageInvitationKey(this.package.id));
  }

  private buildPackageInvitationKey(packageId: number): string {
    return `package_${packageId}_invitation`;
  }

  private switchToClientRole(): void {
    const { componentInstance, result } = this.modal.open(SwitchToAlternativeAccountConfirmModalComponent, {
      windowClass: 'switch-modal',
      centered: true
    });
    componentInstance.role = UserRoles.CLIENT;

    modalResultToObservable$(result)
      .pipe(
        switchMap(() => this.auth.signinAlternativeAccount(true)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private setConfig({
    metaDescriptionPackagePage,
    metaImagePackagePage,
    metaKeywordsPackagePage,
    metaTitlePackagePage
  }: GlobalConfig): void {
    // @ts-expect-error TS2322
    this._config.meta.metaDescriptionPackagePage = metaDescriptionPackagePage;
    // @ts-expect-error TS2322
    this._config.meta.metaImagePackagePage = metaImagePackagePage;
    // @ts-expect-error TS2322
    this._config.meta.metaKeywordsPackagePage = metaKeywordsPackagePage;
    // @ts-expect-error TS2322
    this._config.meta.metaTitlePackagePage = metaTitlePackagePage;
  }

  private setOGMeta(): void {
    if (!this.package?.id) {
      return;
    }

    this.metaTagService.upsertMetaTags({
      title: this.package.title || this._config.meta.metaTitlePackagePage || '',
      description: this.package.descriptionText || this._config.meta.metaDescriptionPackagePage || '',
      image: this.package.coverImage || this._config.meta.metaImagePackagePage || ''
    });
  }

  toggleDropdown(): void {
    this.expand = !this.expand;
  }

  enrollOrOpen(bookFromLanding = false): void {
    if (this.isPreview) {
      return;
    }

    if (!this.auth.isAuthorized) {
      this.authorise();
      return;
    }

    if (this.auth.user.RoleId === UserRoles.GUIDE) {
      this.switchToClientRole();
      return;
    }

    if (this.package.isAlreadyEnrolled && this.package.subscriptionPrice) {
      this.router.navigate(['/client', 'dashboard']);
      return;
    }

    const invitationCode = this._sessionStorage.getItem(this.buildPackageInvitationKey(this.package.id));
    if (invitationCode) {
      this.activateInvitation(invitationCode);
      return;
    }

    this.enroll(bookFromLanding);
  }

  private enroll(analyticSource = false): void {
    const free = this.package.isFree || this.auth.user.free;
    const paymentOption$ = free ? of(null) : this.selectPaymentOptions$();
    // @ts-expect-error TS2322
    let paymentOptionParams: PaymentOptions = null;

    paymentOption$
      .pipe(
        switchMap((paymentOption: PaymentOptions) => {
          paymentOptionParams = paymentOption;

          return free ? of(null) : this.selectPaymentType$(paymentOptionParams);
        }),
        switchMap(() => {
          return this.currentPayments
            .pay(free)
            .package$(this.package.id, { serviceType: GuideServiceTypes.PACKAGE, paymentOption: paymentOptionParams });
        }),
        tap(() => {
          if (analyticSource) {
            const analyticsData = {
              servicePrice: this.package.price,
              serviceType: AnalyticServiceTypes.PACKAGE,
              source: AnalyticSourceTypes.SERVICE
            };

            this.analyticsService.event(InternalEvents.SERVICE_BOOK, analyticsData);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => {
          this.sessions.refresh();
          // TODO https://profi-io.atlassian.net/browse/PR-3525
          if (this.serviceBooking._modalRef) {
            this.serviceBooking._modalRef.close();
            // @ts-expect-error TS2322
            this.serviceBooking._modalRef = null;
          }
          this.notifications.success(`You've been successfully enrolled!`);
          this.router.navigate(['/client', 'dashboard']);
        },
        error => {
          const errors = error?.error?.errors;
          const msg = error?.error?.msg;
          if (errors) {
            this.notifications.error('Error', errors, null);
          } else {
            if (msg) {
              this.notifications.error(msg);
            } else {
              this.notifications.error(`Failed to book`);
            }
          }
        }
      );
  }

  private authorise(): void {
    const invitationCode = this._sessionStorage.getItem(this.buildPackageInvitationKey(this.package.id));

    const { componentInstance } = this.modal.open(AuthModalComponent, {
      windowClass: 'auth-modal'
    });

    componentInstance.onlyClient = true;

    componentInstance.signInCanNot
      .pipe(take(1))
      .subscribe(() =>
        this.notifications.error(
          'Sorry!',
          `You need create alternative account, open user menu and click "Sign up as Client"`
        )
      );

    const [client$, guide$] = partition(
      componentInstance.afterSignIn.pipe(take(1)),
      () => this.auth.user.RoleId === UserRoles.CLIENT
    );

    guide$.pipe(takeUntil(this.destroy$)).subscribe(() => this.router.navigate(['/', 'dashboard']));

    client$
      .pipe(
        tap(
          () =>
            invitationCode &&
            this._sessionStorage.setItem(this.buildPackageInvitationKey(this.package.id), invitationCode)
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.enrollOrOpen());
  }

  private selectPaymentOptions$(): Observable<PaymentOptions | null> {
    if (this.package.price && this.package.subscriptionPrice && this.package.totalPayments) {
      const { result, componentInstance } = this.modal.open(PaymentOptionsModalComponent);
      componentInstance.fullPrice = this.package.price;
      // eslint-disable-next-line prefer-spread
      componentInstance.installmentsPrices = Array.apply(null, Array(this.package.totalPayments)).map((val, index) => ({
        amount: this.package.subscriptionPrice,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        paymentDate: DateTime.local().plus({ [this.package.subscriptionRecurrency]: index })
      }));
      return modalResultToObservable$(result);
    }

    if (this.package.price) {
      return of(PaymentOptions.FULL_PRICE);
    }

    if (this.package.subscriptionPrice) {
      return of(PaymentOptions.INSTALLMENTS);
    }

    // @ts-expect-error TS2322
    return null;
  }

  private selectPaymentType$(paymentOption: PaymentOptions): Observable<void> {
    const isSubscription = paymentOption === PaymentOptions.INSTALLMENTS;

    const modalOptions: IPayWithModalOptions = {
      disableNewCardSave: isSubscription,
      isSubscription,
      hideWallet: isSubscription,
      amount: this.package.price
    };

    return this.serviceBooking.selectPaymentType$(modalOptions);
  }

  toDashboard(): void {
    this.router.navigate(['/client/dashboard']).then();
  }
}
