<form data-qa-id="team-stepper__invitations_form" [formGroup]="invitationsForm" class="form">
  <h2 pui-typography="h2" data-qa-id="invite-team-step-title" i18n="@@inviteHeading">Invite members to your team</h2>
  <app-form-field>
    <app-input-label data-qa-id="invite-team-step-email-input-label">
      <ng-container i18n="@@inviteLabel">
        Send email invitations by entering one or more email addresses or skip for now and add them later.
      </ng-container>
    </app-input-label>
    <ui-chip-list #chipList appInput formControlName="invitations" [multiple]="true">
      <ui-chip
        *ngFor="let chip of invitationsForm.get('invitations')?.value"
        [value]="chip"
        [removable]="true"
        [selectable]="false"
        (removed)="onChipRemove($event)">
        {{ chip }}
      </ui-chip>

      <ui-chip-list-input
        #input
        qaId="invite-team-step-team-member-email-input"
        [placeholder]="'Email address'"
        [separatorKeysCodes]="separatorKeysCodes"
        [chipList]="chipList.originChipList"
        [autocompleteDisabled]="true"
        (inputTokenEnd)="onInputTokenEnd($event)"></ui-chip-list-input>
    </ui-chip-list>
    <app-form-control-error
      [control]="invitationsForm.get('invitations')"
      [show]="invitationsForm.get('invitations')?.hasError('email')">
      <ng-container i18n="@@invalidEmail">Invalid email.</ng-container>
    </app-form-control-error>
  </app-form-field>

  <div class="pui-space_top-4">
    <button
      pui-button
      data-qa-id="invite-team-step-send-invite-button"
      [disabled]="invitationsForm.get('invitations')?.value?.length === 0"
      (click)="inviteGuides()"
      i18n="@@inviteSend">
      Send invite
    </button>
    <a
      *ngIf="canSkip && invitationsForm.get('invitations')?.value?.length === 0"
      routerLink="/{{ GUIDE_LIST_ROUTE }}/services"
      data-qa-id="invite-team-step-add-later-link"
      (click)="modal.dismiss()"
      i18n="@@inviteSkip">
      Add later
    </a>
  </div>
</form>
