import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QuizQuestionType } from '@app/core/quizzes/types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-quiz-text-question',
  templateUrl: './quiz-text-question.component.html',
  styleUrls: ['./quiz-text-question.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuizTextQuestionComponent),
      multi: true
    }
  ]
})
export class QuizTextQuestionComponent implements ControlValueAccessor, OnDestroy, OnInit {
  private readonly destroy$ = new Subject<void>();

  readonly QuizQuestionType = QuizQuestionType;

  @Input()
  // @ts-expect-error TS2564
  key: number | null;

  form = this._formBuilder.group({
    id: [null],
    question: [null],
    type: [null]
  });

  get question(): string {
    return this.form.getRawValue().question || '';
  }

  get questionType(): string {
    return this.form.getRawValue().type || '';
  }

  constructor(private _formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.onChange(value);
      this.onTouched();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // @ts-expect-error TS7006
  writeValue(question): void {
    this.form.patchValue(question, { emitEvent: false });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private onChange = (_: any) => {};

  private onTouched: () => void = () => {};
}
