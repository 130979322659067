import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import { GuideClientsFeaturesService } from '@app/screens/guide/guide-clients/guide-client/services/features/guide-clients-features.service';
import {
  PuiActiveZoneService,
  PuiAutocompleteCellComponent,
  PuiDestroyService,
  PuiOption
} from '@awarenow/profi-ui-core';
import { combineLatest, fromEvent, Observable } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
// eslint-disable-next-line no-restricted-imports
import { pick, isEqual, intersectionBy, map as _map, uniqWith } from 'lodash';
import { ClientsTagsService } from '@app/screens/guide/guide-clients/guide-client/services/features/clients-tags.service';
import { ClientTag } from '@app/screens/guide/guide-clients/guide-client/store/client-tags/commons/types';
import { selectTagsStateDataList } from '@app/screens/guide/guide-clients/guide-client/store/client-tags/client-tags-store.selectors';
import { Store } from '@ngrx/store';
import { UntypedFormControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { MAX_TAGS_COUNT } from '../../../consts/max-tags-count';

const DEFAULT_TAG_COLOR = '#F0F0F0';

const COLOR_VARIANTS: string[] = [
  '#FFCCDD',
  '#FFDDCC',
  '#FFF2B2',
  '#DDFFCC',
  '#B3FFB3',
  '#CCEEFF',
  '#CCDDFF',
  '#FFCCFF',
  '#FFB3B3',
  DEFAULT_TAG_COLOR
];

export function tagToOption({ id, name, color }: ClientTag): PuiOption {
  return {
    value: id,
    label: name,
    data: {
      id,
      color
    }
  };
}

@Component({
  selector: '[guide-client-tag-selector], guide-client-tag-selector',
  templateUrl: 'guide-client-tag-selector.component.html',
  styleUrls: ['guide-client-tag-selector.component.scss', 'guide-client-tag-selector-standalone.component.scss'],
  providers: [PuiActiveZoneService, PuiDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideClientTagSelectorComponent implements OnInit {
  @ViewChild('select', { read: PuiAutocompleteCellComponent })
  selectComponent?: PuiAutocompleteCellComponent;

  @Input()
  @HostBinding('attr.data-host-standalone')
  isStandalone!: boolean;

  @Input()
  options: string[] = [];

  /**
   * @deprecated
   */
  @Input() clientId!: number;

  /**
   * Not work TODO: PR-6478
   */
  @Output()
  optionsChange = new EventEmitter<string[]>();

  readonly DEFAULT_TAG_COLOR = DEFAULT_TAG_COLOR;
  readonly COLOR_VARIANTS = COLOR_VARIANTS;
  readonly MAX_VALUE_COUNT = MAX_TAGS_COUNT;

  readonly variants$: Observable<PuiOption[]> = this.store$.select(selectTagsStateDataList).pipe(
    map((fields: ClientTag[]) =>
      fields?.reduce((acc, field) => {
        const option = tagToOption(field);

        if (option.value && option.label) {
          // @ts-expect-error TS2345
          acc.push(option);
        }

        return acc;
      }, [])
    )
  );

  get selectedOptions$(): Observable<PuiOption[]> {
    return this.variants$.pipe(
      map(variants => {
        const tmp = variants?.reduce(
          (acc, option) => ({
            ...acc,
            [option.value]: option
          }),
          {}
        );

        return Object.values(pick(tmp, this.options));
      })
    );
  }

  readonly search = new UntypedFormControl('');
  readonly canShowCreateOption$: Observable<boolean> = this.search.valueChanges.pipe(
    withLatestFrom(this.variants$),
    map(([search, options]) => !!search && options.findIndex(item => item.label === search?.trim()) === -1)
  );

  /**
   * Handle focus and hover
   */
  isFocused = false;
  isHidden = true;

  @HostBinding('attr.data-host-active')
  get isActive(): boolean {
    // @ts-expect-error TS2322
    return this.selectComponent?.isFocused;
  }

  @HostBinding('attr.data-host-has-value')
  get hasSelectedOptions(): boolean {
    // @ts-expect-error TS2532
    return this.selectComponent?.selectedOptions?.length > 0;
  }

  constructor(
    readonly changeDetectorRef: ChangeDetectorRef,
    private readonly store$: Store,
    private readonly tagsService: ClientsTagsService,
    readonly features: GuideClientsFeaturesService,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>
  ) {}

  ngOnInit(): void {
    fromEvent(this.document, 'click')
      .pipe(
        withLatestFrom(this.selectedOptions$),
        filter(([, options]) => options.length === 0),
        takeUntil(this.destroy$)
      )
      .subscribe(([, options]) => {
        setTimeout(() => {
          if (this.selectComponent?.isAttached || this.isActive) {
            return;
          }

          this.isHidden = !options ? true : options.length === 0;
          this.changeDetectorRef.markForCheck();
        });
      });
  }

  @HostListener('mouseenter', ['true'])
  @HostListener('mouseleave', ['false'])
  onFocus(isFocused: boolean): void {
    this.isFocused = isFocused;
    this.changeDetectorRef.markForCheck();
  }

  @HostListener('click')
  onClick(): void {
    if (this.selectComponent && !this.selectComponent.isFocused && !this.isHidden) {
      setTimeout(() => {
        this.selectComponent?.grabFocus();
      });
    }
  }

  change(options: PuiOption[]): void {
    if (options.length > this.MAX_VALUE_COUNT) {
      return;
    }

    const nullOptions =
      // Exclude duplicates
      uniqWith(options, (optionA, optionB) => optionA.value === optionB.label)
        // Get only creatable options
        .filter(({ data }) => data.creatable);

    if (nullOptions.length) {
      combineLatest(nullOptions.map(option => this.create(option)))
        .pipe(withLatestFrom(this.store$.select(selectTagsStateDataList)))
        // TODO: PR-6478
        // eslint-disable-next-line rxjs-angular/prefer-takeuntil
        .subscribe(([actions, clientsTags]: [{ tags: ClientTag }[], ClientTag[]]) => {
          const createdTags = intersectionBy(_map(actions, 'tag'), clientsTags, 'name');

          this.change([...options.filter(({ data }) => !data.creatable), ...createdTags.map(tagToOption)]);
        });
    } else {
      const value: string[] = options.map(({ value }) => String(value));

      if (isEqual(this.options, value)) {
        return;
      }

      if (value.length) {
        // TODO: PR-6478 workaround because Change.emit lost the context because the component was destroyed
        // this.optionsChange.emit(value);
        this.features.tagClient(this.clientId, value);
      }
    }
  }

  deleteSelectedOption(option: PuiOption): void {
    // TODO: PR-6478 workaround because Change.emit lost the context because the component was destroyed
    // this.optionsChange.emit(this.options.filter(id => id !== option.value));
    this.features.tagClient(
      this.clientId,
      this.options.filter(value => Number(value) !== Number(option.value))
    );
  }

  updateTag({ value: id, label: name }: PuiOption, { color }: Partial<Omit<ClientTag, 'id'>>): void {
    this.tagsService.update(String(id), {
      name,
      color
    });
  }

  deleteOptionConfirm(option: PuiOption): void {
    this.tagsService.deleteOptionConfirm(option.data.id);
  }

  private create({ label: name, value, data: { color } }: PuiOption): Observable<unknown> {
    return this.tagsService.create({ name: name || String(value), color });
  }
}
