import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { Injectable, OnDestroy } from '@angular/core';
import { GuideDeclineDrawerComponent } from '@app/screens/guide/guide-sessions/components/drawers/guide-decline-drawer/guide-decline-drawer.component';
import {
  DeclineData,
  DeclineResult
} from '@app/screens/guide/guide-sessions/components/drawers/guide-decline-drawer/guide-decline-drawer.types';
import { SimpleSession } from '@app/shared/interfaces/session';
import { PuiDrawerConfig, PuiDrawerRef, PuiDrawerService } from '@awarenow/profi-ui-core';

export const DRAWER_CONFIG: PuiDrawerConfig = {
  position: 'right',
  maxWidth: '600px'
};

@Injectable({ providedIn: 'root' })
export class GuideDeclineDrawerService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  constructor(private readonly drawerService: PuiDrawerService) {}

  openDeclineConformation$(session: SimpleSession, clientName?: string, date?: string): Observable<string | null> {
    const data: DeclineData = {
      duration: session.duration,
      title: session.name || '',
      date,
      clientName
    };
    data.count = session.recurringSessionsDetails?.length;

    const drawerRef: PuiDrawerRef<DeclineResult, DeclineData> = this.drawerService.open(
      GuideDeclineDrawerComponent,
      DRAWER_CONFIG,
      data
    );
    return drawerRef.afterClosed$.pipe(
      tap(cancel => !cancel?.result && this.destroy$.next()),
      map(cancel => cancel.reason),
      takeUntil(this.destroy$)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
