import { Injectable, OnDestroy } from '@angular/core';
import { ExternalCalendarSources } from '@app/core/calendars/types';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SocketService } from '@app/core/socket/socket.service';
import { ExternalEventsApiService } from './external-events-api.service';
import {
  IExternalEvent,
  IExternalEvents,
  IEventsRequestOptions,
  IExternalEventCompositeKey,
  ExternalEventTypes
} from '../../types';

@Injectable()
export class ExternalEventsService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _events = new Map<string, readonly IExternalEvent[]>();
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _rangeStart: number;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _rangeEnd: number;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _events$ = new BehaviorSubject<readonly IExternalEvent[]>([]);

  get events$(): Observable<readonly IExternalEvent[]> {
    return this._events$.asObservable();
  }

  get rangeEnd(): number {
    return this._rangeEnd;
  }

  get rangeStart(): number {
    return this._rangeStart;
  }

  constructor(private readonly _socket: SocketService, private readonly _api: ExternalEventsApiService) {
    this._socket
      .onExternalCalendarsDisconnected$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(details => {
        if (!details.accountId) {
          this.removeEvents(details.source);
        } else {
          const refreshOptions: IEventsRequestOptions = {
            source: details.source
          };

          this.refresh(refreshOptions);
        }
      });

    this._socket
      .onExternalEventsRemoved$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(details => this.removeEvents(details.source, details.events));

    this._socket
      .onExternalEventsUpdate$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(details => {
        const refreshOptions: IEventsRequestOptions = {
          source: details.source
        };

        this.refresh(refreshOptions);
      });

    this._socket
      .onExternalCalendarDisconnected$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(details => {
        const refreshOptions: IEventsRequestOptions = {
          source: details.source
        };

        this.refresh(refreshOptions);
      });

    this._socket
      .onExternalCalendarConnected$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(details => {
        const refreshOptions: IEventsRequestOptions = {
          source: details.source
        };

        this.refresh(refreshOptions);
      });

    this.refresh();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  hide$(event: IExternalEventCompositeKey): Observable<void> {
    return this._api.hideEvent$(event);
  }

  refresh(options: IEventsRequestOptions = {}): void {
    this._api.loadEvents$(options).subscribe(externalEvents => {
      this.mergeEvents(externalEvents, !options.source);
      this.updateEventsSubject();
    });
  }

  // @ts-expect-error TS7006
  private mergeEvents(externalEvents: IExternalEvents, removeOtherSources): void {
    for (const [eventsSourceName, sourceEvents] of Object.entries(externalEvents)) {
      this._events.set(eventsSourceName, sourceEvents);
    }

    if (!removeOtherSources) {
      return;
    }

    for (const removedSource of [...this._events.keys()].filter(cachedSource => !(cachedSource in externalEvents))) {
      this._events.delete(removedSource);
    }
  }

  private removeEvents(
    source?: ExternalCalendarSources,
    removedEvents?: readonly { readonly id: number; readonly type: ExternalEventTypes }[]
  ): void {
    if (!source) {
      this._events.clear();
    } else if (!removedEvents) {
      this._events.delete(source);
    } else {
      // @ts-expect-error TS2532
      const filteredEvents = this._events
        .get(source)
        .filter(
          event =>
            removedEvents.findIndex(
              removedEvent => removedEvent.type === event.type && removedEvent.id === event.id
            ) === -1
        );
      this._events.set(source, filteredEvents);
    }

    this.updateEventsSubject();
  }

  private updateEventsSubject(): void {
    const allEvents = [...this._events.values()].flat();
    this._events$.next(allEvents);
  }
}
