<ui-selector-single
  #selector
  [(ngModel)]="value"
  [disabled]="disabled"
  [placeholder]="placeholder"
  [required]="required"
  [overlayPanelClassName]="'ui-client-selector-panel'"
  [getOptionLabel]="getOptionLabel"
  [displayWith]="displayWith"
  [optionTemplateRef]="clientOptionTemplateRef || defaultClientOptionTemplateRef"
  [emptyListTemplateRef]="canAdd ? addClientTemplateRef : defaultEmptyListTemplateRef"
  [autocompleteConnectedTo]="autocompleteConnectedTo"
  [ariaLabel]="placeholder"
  (typed)="typed.emit($event)"
  (selected)="selected.emit($event)"
  (addClient)="onAddClient(selector.typedValue)"
  (opened)="onPanelOpen()"
  (closed)="onPanelClose()">
  <ui-option
    class="client-option"
    *ngFor="let option of clients$ | async | filterByNameOrEmail: selector.typedValue"
    [value]="option"
    [disabled]="option.disabled"
    [id]="option.id">
    {{ option | displayVia : getOptionLabel }}
  </ui-option>
</ui-selector-single>

<ng-template let-option #defaultClientOptionTemplateRef>
  <app-user-info
    matTooltip="You've reached your active client limit — upgrade your plan or archive other clients."
    [matTooltipDisabled]="!option.disabled"
    [matTooltipPosition]="'above'"
    i18n-matTooltip="@@contactDetailsTemplateUnarchiveClientTooltipClientLimitIsReached"
    [archived]="option.archived"
    [user]="option"></app-user-info>
</ng-template>

<ng-template #defaultEmptyListTemplateRef>
  <div class="client-option">
    <div class="client-invite-option">
      <div class="invite-option-text">{{ nothingFoundText || text['Nothing found'] }}</div>
    </div>
  </div>
</ng-template>

<ng-template #addClientTemplateRef>
  <div class="client-option">
    <div class="client-invite-option">
      <div class="invite-option-button">
        <pui-icon class="invite-option-icon" svgIcon="pui:plus"></pui-icon>
      </div>
      <div class="invite-option-text">
        {{ nothingFoundText || text['Nothing found'] }}. {{ text['invite'] }} «{{ selector.typedValue }}» {{ text['via
        email'] }}
      </div>
    </div>
  </div>
</ng-template>
