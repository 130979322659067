import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { environment } from '@env/environment';
import { AuthService } from '@app/core/auth/services';
import { ADMIN_ROUTE_PATH } from '@app/routes-consts';
import { SurveyFormService } from '../services/survey-form.service';

@Injectable()
export class CanSaveQuizAndRedirectGuard {
  private readonly route: string;

  constructor(
    private readonly router: Router,
    private readonly surveyFormService: SurveyFormService,
    private readonly auth: AuthService
  ) {
    this.route = this.auth.isPlatformAdmin() ? `${ADMIN_ROUTE_PATH}/default-form` : `${environment.guideRoute}/surveys`;
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    if (!this.surveyFormService.quizId) {
      this.surveyFormService.createSurvey$().subscribe(id => {
        if (id) {
          this.router.navigate([`/${this.route}/${id}/${route.url}`]);
        }
      });
    } else {
      this.router.navigate([`/${this.route}/${this.surveyFormService.quizId}/${route.url}`]);
    }

    return Promise.resolve(false);
  }
}
