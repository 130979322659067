import { GuideClient, GuideContact } from '@app/core/users/types';
import { ClientView } from '@app/components/clients-select/type';

export type ClientVariant = GuideClient | GuideContact;

export const toClientView = (client: ClientVariant): ClientView => {
  if (client instanceof GuideClient || client instanceof GuideContact) {
    return {
      id: client.id.toString(),
      name: client.name,
      email: client.contacts?.email as string,
      photo: client.photo
    };
  }

  throw new Error('Unknown client interface.');
};
