import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import config from '../config/config';
import { PreloaderService } from '../preloader/preloader.service';

@Injectable()
export class FirstpromoterService implements OnDestroy {
  private readonly FIRSTPROMOTER_TOKEN_ENDPOINT = `${config.apiPath}/user/common/alternative-connections/firstpromoter/token`;

  private readonly FIRSTPROMOTER_PROMOTER_ENDPOINT = `${config.apiPath}/user/common/alternative-connections/firstpromoter/promoter`;

  private readonly FIRSTPROMOTER_PROMOTER_DASHBOARD_URL: string;

  private destroy$ = new Subject<void>();

  isAvailable: boolean;

  constructor(
    private _http: HttpClient,
    private _notifications: NotificationsService,
    private _preloader: PreloaderService,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {
    this.FIRSTPROMOTER_PROMOTER_DASHBOARD_URL = `https://${this._runtimeConfigService.get(
      'FIRSTPROMOTER_PRODUCT_NAME'
    )}.firstpromoter.com/view_dashboard_as?at=`;
    this.isAvailable =
      !!this._runtimeConfigService.get('FIRSTPROMOTER_ID') &&
      !!this._runtimeConfigService.get('FIRSTPROMOTER_DOMAIN') &&
      !!this._runtimeConfigService.get('FIRSTPROMOTER_PRODUCT_NAME');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createPromoter(): Observable<any> {
    this._preloader.loadingStart();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.post<any>(this.FIRSTPROMOTER_PROMOTER_ENDPOINT, {}).pipe(
      // eslint-disable-next-line id-length, @typescript-eslint/no-unused-vars
      catchError(e => {
        const message = `Failed to create promoter account`;
        this._notifications.error(message);
        return of({ token: null });
      }),
      map(({ token }) => (token ? `${this.FIRSTPROMOTER_PROMOTER_DASHBOARD_URL}${token}` : null)),
      finalize(() => this._preloader.loadingEnd())
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getUrlIfExists(): Observable<any> {
    this._preloader.loadingStart();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.get<any>(this.FIRSTPROMOTER_TOKEN_ENDPOINT).pipe(
      // eslint-disable-next-line id-length
      catchError(e => {
        console.log(e);
        return of({ token: null });
      }),
      map(({ token }) => (token ? `${this.FIRSTPROMOTER_PROMOTER_DASHBOARD_URL}${token}` : null)),
      finalize(() => this._preloader.loadingEnd())
    );
  }
}
