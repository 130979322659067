@let isToday = isSendDateToday();

<div class="chat-item">
  <pui-badge-host>
    <pui-avatar [avatarUrl]="avatar()" [text]="alt()" [size]="40" />

    @if (unread(); as unreadCount) {
      <pui-badge pui-top-left-badge shape="square" [value]="unreadCount" />
    }
  </pui-badge-host>
  <div class="chat-item__content">
    <div class="chat-item__info">
      <span class="chat-item__sender">
        {{ title() }}
      </span>

      @if (isToday) {
        <time class="chat-item__timestamp">{{ date() | date: 'shortTime' }}</time>
      } @else {
        <time class="chat-item__timestamp">{{ date() | date: 'MMM d, y' }}</time>
      }
    </div>

    @if (subtitle(); as subtitleValue) {
      <div class="chat-item__message" [innerHTML]="subtitleValue | safeHtml"></div>
    }
  </div>
</div>
