import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IUser } from '@app/shared/interfaces/user';
import { FormService } from '@app/core/form/form.service';
import { feedbackMarkValidator } from '@app/shared/form-validators/feedback-mark.validator';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

@Component({
  selector: 'app-client-session-feedback',
  templateUrl: './client-session-feedback.component.html',
  styleUrls: ['./client-session-feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientSessionFeedbackComponent implements OnInit {
  @Input()
  privateOnly = false;

  @Input()
  // @ts-expect-error TS2564
  session: { id: number; user: IUser };

  readonly MAX_COMMENT_LENGTH = 2048;

  // @ts-expect-error TS2564
  sessionFeedbackForm: UntypedFormGroup;
  platformName: string;

  isBadReview(): boolean {
    const { mark, privateComment } = this.sessionFeedbackForm.value;
    return mark && mark <= 3 && !privateComment;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _modal: NgbActiveModal,
    private _form: FormService,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {
    // @ts-expect-error TS2322
    this.platformName = this._runtimeConfigService.get('platformName');
  }

  ngOnInit(): void {
    this.sessionFeedbackForm = this._formBuilder.group({
      mark: [0, [Validators.required, feedbackMarkValidator()]],
      comment: [null, Validators.maxLength(this.MAX_COMMENT_LENGTH)],
      privateComment: [null, Validators.maxLength(this.MAX_COMMENT_LENGTH)]
    });
  }

  cancel(): void {
    this._modal.dismiss();
  }

  sendFeedback(): void {
    if (this._form.markInvalidForm(this.sessionFeedbackForm)) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const feedback: any = {
      sessionId: this.session.id,
      mark: this.sessionFeedbackForm.controls.mark.value
    };

    const comment = this.clearFeedbackString(this.sessionFeedbackForm.controls.comment.value);
    if (comment) {
      feedback.comment = comment;
    }

    const privateComment = this.clearFeedbackString(this.sessionFeedbackForm.controls.privateComment.value);
    if (privateComment) {
      feedback.privateComment = privateComment;
    }

    this._modal.close(feedback);
  }

  private clearFeedbackString(stringValue: string): string {
    if (stringValue) {
      stringValue = stringValue.trim();
    }

    return stringValue;
  }
}
