import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpMeFindAGuideModalComponent } from '@app/layouts/main-layout/sidebar-navigation/help-me-find-a-guide-modal/help-me-find-a-guide-modal.component';
import { environment } from '@env/environment';

@Injectable()
export class SingleUrlSegmentGuard {
  constructor(private _router: Router, private _modal: NgbModal) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const { url: urlSegments } = route;
    const urlSegmentsLen = urlSegments.length;

    if (urlSegmentsLen && urlSegments[0].path === 'help') {
      this._router
        .navigate([environment.guidesRoute])
        .then(() => this.helpToFindAGuide())
        .catch(error => {
          console.log(error);
        });

      return false;
    }

    if (urlSegmentsLen > 1) {
      this._router.navigate([environment.guidesRoute, urlSegments[0].path], { replaceUrl: true });
      return false;
    }

    return urlSegmentsLen <= 1;
  }

  private helpToFindAGuide(): void {
    const { result } = this._modal.open(HelpMeFindAGuideModalComponent);
    result
      .then(() => {})
      .catch(error => {
        console.log(error);
      });
  }
}
