import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mapGuideProgramItem } from '@app/screens/guide/guide-services/utils/mappers';
import { IGuideProgramItem } from '@app/shared/interfaces/programs';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NotificationsService } from '@awarenow/profi-ui-core';
import config from '@app/core/config/config';
import { GuideService, guideServicesFactory } from '@app/shared/interfaces/services';
import { LocaleService } from '@app/core/locale/locale.service';
import { ILocale } from '@env/locale.interface';
import { AuthService } from '@app/core/auth/services';

@Injectable({
  providedIn: 'root'
})
export class AllGuideServicesApiService {
  readonly PACKAGES_ENDPOINT = `${config.apiPath}/user/${this.authService.getUserRole()}/packages`;

  readonly PROGRAMS_ENDPOINT = this.authService.isPlatformAdmin()
    ? `${config.apiPath}/user/admin/programs-templates`
    : `${config.apiPath}/user/guide/programs`;

  readonly SESSIONS_ENDPOINT = `${this.servicesEndpoint}/templates/sessions`;

  private locale: ILocale;

  get servicesEndpoint(): string {
    return `${config.apiPath}/user/${this.authService.getUserRole()}/services`;
  }

  constructor(
    private _http: HttpClient,
    private _notifications: NotificationsService,
    private _localeService: LocaleService,
    private readonly authService: AuthService
  ) {
    this.locale = this._localeService.getLocale();
  }

  duplicateProgram(programId: number): Observable<GuideService> {
    return this.execute$(
      this._http.post<{ program: IGuideProgramItem }>(`${this.PROGRAMS_ENDPOINT}/${programId}/duplicates`, {}),
      'Cannot duplicate program'
    ).pipe(map(({ program }) => mapGuideProgramItem(program, this.locale)));
  }

  getServices$(): Observable<GuideService[]> {
    return this.execute$(
      this._http
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .get<any>(this.servicesEndpoint)
        .pipe(map(({ services }) => guideServicesFactory(services, this.locale))),
      'Failed to load services'
    );
  }

  removePackage(packageId: number): Observable<void> {
    return this.execute$(this._http.delete<void>(`${this.PACKAGES_ENDPOINT}/${packageId}`), 'Failed to remove package');
  }

  removeProgram(programId: number): Observable<void> {
    return this.execute$(this._http.delete<void>(`${this.PROGRAMS_ENDPOINT}/${programId}`), 'Cannot remove program');
  }

  /**
   * @deprecated
   */
  removeSessionService$(id: number): Observable<void> {
    return this.execute$(this._http.delete<void>(`${this.SESSIONS_ENDPOINT}/${id}`), 'Failed to remove service');
  }

  private execute$<T>(httpRequest$: Observable<T>, errorMessage?: string | null): Observable<T> {
    return httpRequest$.pipe(
      catchError(error => {
        if (errorMessage) {
          this._notifications.error(errorMessage);
        }

        return throwError(error);
      })
    );
  }
}
