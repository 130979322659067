import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { environment } from '@env/environment';
import { AuthService } from '@app/core/auth/services';
import { ADMIN_ROUTE_PATH } from '@app/routes-consts';
import { WindowManagerService } from '@libs/services/window-manager/window-manager.service';
import { CHOOSE_FORMS_FEATURE } from '@app/modules/propose-forms/consts';
import { SurveyFormService } from '../../services/survey-form.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-quiz-wrapper',
  templateUrl: './quiz-wrapper.component.html',
  styleUrls: ['./quiz-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuizWrapperComponent implements OnInit {
  quizBasicInfo$!: Observable<{
    avatar: string;
    title: string;
  }>;

  readonly tabEnabled$ = this.surveyFormService.loading$.pipe(map(loading => !loading));
  readonly guideRoute = environment.guideRoute;

  isAdmin = false;
  guideRouteForms = `/${this.guideRoute}/questionnaires`;
  adminRouteForms = `/${ADMIN_ROUTE_PATH}/default-forms`;
  questionnairesRoute = this.guideRoute;

  constructor(
    private readonly route: ActivatedRoute,
    public router: Router,
    private readonly auth: AuthService,
    private readonly windowManagerService: WindowManagerService,
    private readonly surveyFormService: SurveyFormService
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.auth.isPlatformAdmin();
    this.questionnairesRoute = this.isAdmin ? this.adminRouteForms : this.guideRouteForms;

    const basicForm = this.surveyFormService.quizForm.get('basic') as UntypedFormGroup;

    this.quizBasicInfo$ = basicForm.valueChanges.pipe(
      startWith(basicForm.value),
      map(values => ({
        avatar: values?.avatar,
        title: values?.quizName
      }))
    );
  }

  navigateRelative(path: string): void {
    this.router.navigate([path], { relativeTo: this.route });
  }

  close(): void {
    // Send post message
    this.windowManagerService.postMessage(CHOOSE_FORMS_FEATURE, { command: 'close' });

    this.router.navigate([this.questionnairesRoute]);
  }
}
