import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NgModule } from '@angular/core';
import { PuiButtonModule, PuiIconModule, PuiLayoutModule, PuiScrollableModule } from '@awarenow/profi-ui-core';
import { RouterModule } from '@angular/router';
import { AlertsModule } from '@app/modules/alerts/alerts.module';
import { ScreenModule } from '@app/modules/ui-kit/_base/_common/screen/screen.module';
import { HeaderLogoModule } from '@app/modules/ui-kit/widgets/header-logo';
import { SidenavModule } from '@app/modules/sidenav/sidenav.module';
import { PrimaryLayoutService } from './primary-layout.service';
import { PrimaryLayoutHeaderComponent } from './header/primary-layout-header.component';
import { PrimaryLayoutComponent } from './primary-layout.component';

@NgModule({
  declarations: [PrimaryLayoutComponent, PrimaryLayoutHeaderComponent],
  imports: [
    AlertsModule,
    CommonModule,
    MatSidenavModule,
    PuiButtonModule,
    RouterModule,
    ScreenModule,
    SidenavModule,
    HeaderLogoModule,
    PuiIconModule,
    PuiScrollableModule,
    PuiLayoutModule
  ],
  providers: [PrimaryLayoutService]
})
export class PrimaryLayoutModule {}
