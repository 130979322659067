import { NotificationsService } from '@awarenow/profi-ui-core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { SessionBookedEvent } from '@app/core/analytics/events/session-booked.event';
import config from '@app/core/config/config';
import { EventResponse, Events } from '@app/modules/service-scheduling/types';
import { GUIDE_API, GUIDE_OFFERS_ENDPOINT, GUIDE_SCHEDULES_ENDPOINT } from '@app/shared/constants/endpoints';
import { CustomUrlQueryEncoder } from '@app/shared/utils/custom-url-query-encoder';

import {
  FreeTimeSlotRequest,
  FreeTimeSlotResponce,
  GuideOfferEvent,
  GuideOfferEventResponse,
  OfferRequest
} from './types';

@Injectable()
export class OfferGuideApiService {
  readonly EVENTS_ENDPOINT = `${config.apiPath}/user/guide/events`;
  constructor(
    private readonly http: HttpClient,
    private analyticsService: AnalyticsService,
    private notificationsService: NotificationsService
  ) {}

  offer$(data: GuideOfferEvent, serviceName: string, isClientsOffer: boolean): Observable<GuideOfferEventResponse> {
    return this.http.post<GuideOfferEventResponse>(GUIDE_OFFERS_ENDPOINT, data).pipe(
      tap(() => {
        this.analyticsService.emitEvent(new SessionBookedEvent());

        this.showNotification(data, serviceName, isClientsOffer);
      }),
      catchError(error => {
        this.notificationsService.error(error?.msg || 'Unknown error!');
        return throwError(error);
      })
    );
  }

  offerEvents$(data: GuideOfferEvent, serviceName: string, isClientsOffer: boolean): Observable<Events> {
    return this.http.post<EventResponse>(this.EVENTS_ENDPOINT, data).pipe(
      tap(() => {
        this.analyticsService.emitEvent(new SessionBookedEvent());
        this.showNotification(data, serviceName, isClientsOffer);
      }),
      map(response => response?.eventsIds),
      catchError(error => {
        this.notificationsService.error(error?.msg || 'Unknown error!');
        return throwError(error);
      })
    );
  }

  offerProgram$(id: number, data: OfferRequest): Observable<void> {
    return this.http.post<void>(`${GUIDE_API}/programs/${id}/invitations`, data);
  }

  offerPackage$(id: number, data: OfferRequest): Observable<void> {
    return this.http.post<void>(`${GUIDE_API}/packages/${id}/invitations`, data);
  }

  /**
   * @deprecated
   */
  freeTimeRanges$(options: FreeTimeSlotRequest): Observable<FreeTimeSlotResponce> {
    const params = new HttpParams({
      encoder: new CustomUrlQueryEncoder(),
      fromObject: { ...options }
    });

    return this.http.get<FreeTimeSlotResponce>(`${GUIDE_SCHEDULES_ENDPOINT}/free-time`, {
      params
    });
  }

  showNotification(data: GuideOfferEvent, serviceName: string, isClientsOffer: boolean): void {
    const attendees = isClientsOffer ? data.attendees : data.guests || [];

    if (data.attendees.length > 1) {
      this.notificationsService.success(
        `${serviceName} offer sent to ${attendees?.length} ${isClientsOffer ? `clients` : 'guests'}`
      );
    } else {
      const [attendee] = attendees;

      const name = typeof attendee === 'string' ? attendee : attendee.name;

      this.notificationsService.success(`${serviceName} offer sent to ${name}`);
    }
  }
}
