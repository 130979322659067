import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserNotesModule } from '@app/modules/user-notes/user-notes.module';
import {
  AddContactModalComponent,
  ClientAddComponent,
  ClientDetailsComponent,
  ContactDetailsComponent,
  GuideClientCardTab,
  GuideClientInfoComponent,
  GuideClientViewComponent,
  GuideRelationInfoModalComponent
} from '@app/modules/guide-client/components';
import { GuideContactsServerStoreService, GuideRelationProfileService } from '@app/modules/guide-client/services';
import { ButtonModule } from '@app/shared/components/button/button.module';
import { SharedModule } from '@app/shared';
import { AutosizeModule } from 'ngx-autosize';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormModule } from '@app/modules/ui-kit/form/form.module';
import { UiClientSelectorMultipleModule } from '@app/modules/ui-kit/widgets/ui-client-selector-multiple';
import {
  PuiAvatarModule,
  PuiBadgeModule,
  PuiButtonModule,
  PuiCardModule,
  PuiDividerModule,
  PuiDropdownModule,
  PuiIconModule,
  PuiInputCheckboxModule,
  PuiInputModule,
  PuiLetModule,
  PuiLinkModule,
  PuiList2Module,
  PuiMaskModule,
  PuiPaperModule,
  PuiPhoneNumberModule,
  PuiSelectModule,
  PuiTabsModule,
  PuiTooltipModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { GuideNotesModule } from '@app/modules/guide-notes/guide-notes.module';
import { SharedModule as SharedCoreModule } from '@libs/shared/shared.module';
import { FeatureFlagsModule } from '@libs/feature-flags';
import { GuideRelationShareNotesService } from '@app/modules/guide-client/services/guide-relation-share-notes.service';
import { GuideRelationShareNotesApiService } from '@app/modules/guide-client/services/guide-relation-share-notes-api.service';
import { WorkspacesModule } from '@app/modules/workspaces/workspaces.module';
import { TextEditorModule } from '@app/modules/text-editor/text-editor.module';
import { GUIDE_CONTACTS_STORE } from './types';
import { GuideContactsService } from './services/guide-contacts.service';
import { GuideRelationNotesBoardToolbarComponent } from './components/guide-relation-notes-board-toolbar/guide-relation-notes-board-toolbar.component';
import { GuideRelationShareNotesComponent } from './components/guide-relation-share-notes/guide-relation-share-notes.component';
import { ContactDetailsTagsSelectorModule } from '@app/modules/guide-client/components/contact-details/components/contact-details-tags-selector.module';
import { NotesFiltersComponent } from '@app/modules/guide-client/components/notes-filters/notes-filters.component';
import { Subject } from 'rxjs';
import { NOTES_UPDATE_TOKEN } from '@app/modules/guide-client/types/notes-update-token';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CLIENT_VIEW_TAB_TOKEN } from '@app/modules/guide-client/types/client-view-tab-token';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UserNotesModule,
    ButtonModule,
    SharedModule,
    AutosizeModule,
    PuiPhoneNumberModule,
    NgbTooltipModule,
    NgScrollbarModule,
    FormModule,
    UiClientSelectorMultipleModule,
    PuiIconModule,
    PuiButtonModule,
    GuideNotesModule,
    PuiTypographyModule,
    PuiLinkModule,
    PuiInputModule,
    PuiDividerModule,
    PuiMaskModule,
    PuiDropdownModule,
    PuiList2Module,
    PuiBadgeModule,
    PuiLetModule,
    SharedCoreModule,
    FeatureFlagsModule,
    PuiAvatarModule,
    PuiPaperModule,
    PuiCardModule,
    WorkspacesModule,
    PuiTabsModule,
    TextEditorModule,
    ContactDetailsTagsSelectorModule,
    PuiInputCheckboxModule,
    PuiTooltipModule,
    PuiSelectModule
  ],
  declarations: [
    AddContactModalComponent,
    GuideClientInfoComponent,
    GuideRelationInfoModalComponent,
    ClientAddComponent,
    ClientDetailsComponent,
    GuideClientViewComponent,
    ContactDetailsComponent,
    GuideRelationNotesBoardToolbarComponent,
    GuideRelationShareNotesComponent,
    NotesFiltersComponent
  ],
  exports: [
    GuideClientInfoComponent,
    GuideClientViewComponent,
    GuideRelationNotesBoardToolbarComponent,
    NotesFiltersComponent
  ],
  providers: [
    GuideContactsService,
    { provide: GUIDE_CONTACTS_STORE, useClass: GuideContactsServerStoreService },
    GuideRelationProfileService,
    GuideRelationShareNotesService,
    GuideRelationShareNotesApiService,
    { provide: NOTES_UPDATE_TOKEN, useValue: new Subject() },
    { provide: CLIENT_VIEW_TAB_TOKEN, useValue: new Subject<GuideClientCardTab>() }
  ]
})
export class GuideClientModule {}
