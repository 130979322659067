import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { CouponActivationService } from './coupon-activation.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-gift-coupon-activation',
  templateUrl: './gift-coupon-activation.component.html',
  providers: [CouponActivationService]
})
export class GiftCouponActivationComponent implements OnInit, OnDestroy {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _subscription: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _couponActivator: CouponActivationService,
    private _notifications: NotificationsService
  ) {}

  ngOnInit(): void {
    this._subscription = this._route.paramMap
      .pipe(
        map(params => params.get('hash')),
        // @ts-expect-error TS2345
        mergeMap(hash => this._couponActivator.activateGiftCoupon$(hash)),
        catchError(error => {
          const timeOut = 5000;
          const title = `Gift coupon activation failed`;
          this._notifications.error(title, error.error.errors, { timeOut });
          return timer(timeOut + 100);
        })
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(() => this._router.navigate(['/dashboard']));
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }
}
