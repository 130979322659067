// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { ChatsFilesUploaderService } from '@app/core/chat/chats-files-uploader.service';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-upload-chat-image-modal',
  templateUrl: './upload-chat-image-modal.component.html',
  styleUrls: ['./upload-chat-image-modal.component.scss']
})
export class UploadChatImageModalComponent implements OnDestroy {
  private onDestroy$ = new Subject<void>();
  images: { src?: string; loading: boolean }[] = [];
  loading = false;

  message = '';
  // @ts-expect-error TS2564
  isEmojiPickerVisible: boolean;
  quillModules = {
    toolbar: [],
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: () => this.emitSendMessage()
        }
      }
    }
  };

  constructor(
    private _modal: NgbActiveModal,
    private _uploader: ChatsFilesUploaderService,
    @Inject(GLOBAL_WINDOW) private _browserWindow: Window
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  reload() {
    this._browserWindow.location?.reload();
  }

  dismiss(): void {
    this._modal.dismiss();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  uploadImage(value: FileList) {
    if (value && value.length && !this.loading && /^image\//.test(value[0].type)) {
      this.loading = true;
      const image = { src: '', loading: true };
      this.images.push(image);
      this._uploader
        .uploadFile$(value[0])
        .pipe(debounceTime(200), takeUntil(this.onDestroy$))
        .subscribe(event => {
          if (event.type === HttpEventType.Response) {
            this.loading = false;
            // @ts-expect-error TS2339
            const { url } = event.body;
            if (!url) {
              return;
            }
            image.src = url;
            this.loading = true;

            const img = document.createElement('img');
            img.onload = () => {
              this.loading = false;
              image.loading = false;
            };
            img.onerror = () => {
              this.loading = false;
              image.loading = false;
            };
            img.src = url;
          }
        });
    }
  }

  emitSendMessage(): void {
    if (this.images.length && !this.loading) {
      const message = this.images.reduce(
        // eslint-disable-next-line id-length
        (a, img) => `${a}<img src="${img.src}" style="max-width: 100%; display: block">`,
        ''
      );
      this._modal.close(message + (this.message ? this.message.trim() : ''));
      this.message = '';
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEmojiSelected(event: any): void {
    this.message = `${this.message || ''}${event.emoji.native}`;
    this.isEmojiPickerVisible = false;
  }

  toggleEmojiPicker(): void {
    this.isEmojiPickerVisible = !this.isEmojiPickerVisible;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  onClickedOutsideEmoji(event: any): void {
    this.isEmojiPickerVisible = false;
  }
}
