import { SaveOnRouteChanged } from '@app/screens/guide/guide-shared/interfaces';
import { RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PuiDialogService } from '@awarenow/profi-ui-core';
import { ComponentType } from '@angular/cdk/portal';
import { NotificationsService } from '@awarenow/profi-ui-core';

export enum RedirectAfterSaveActions {
  SAVE = 'save',
  DISCARD = 'discard',
  CANCEL = 'cancel'
}

export function canRedirectAfterSave(
  service: PuiDialogService,
  notificationService: NotificationsService,
  sourceComponent: SaveOnRouteChanged,
  nextRoute: RouterStateSnapshot,
  template: ComponentType<unknown>
): Observable<RedirectAfterSaveActions> {
  const notFoundRouteResponse =
    nextRoute.url !== '/not-found' ? RedirectAfterSaveActions.SAVE : RedirectAfterSaveActions.CANCEL;

  if (sourceComponent.tabIsEdited) {
    return service.open(template, { size: 's' }).afterClosed$.pipe(
      map((result: RedirectAfterSaveActions | undefined) => {
        if (result === RedirectAfterSaveActions.SAVE) {
          if (!sourceComponent.tabIsValid) {
            notificationService.error('The tab contains invalid changes. Please fix or discard them');
            return RedirectAfterSaveActions.CANCEL;
          }

          sourceComponent.saveOnRouteChange(true);

          return notFoundRouteResponse;
        }

        if (result === RedirectAfterSaveActions.DISCARD) {
          if (!sourceComponent.tabIsValid) {
            notificationService.error('The tab contains invalid changes. Please fix or discard them');
            return RedirectAfterSaveActions.CANCEL;
          }

          sourceComponent.revertChanges();

          return RedirectAfterSaveActions.DISCARD;
        }

        return RedirectAfterSaveActions.CANCEL;
      })
    );
  } else {
    return of(notFoundRouteResponse);
  }
}
