import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/core/auth/services';
import { UserRoles } from '@app/shared/enums/user-roles';
import { LocalStorageKeys } from '@app/cdk/enums';
import { environment } from '@env/environment.local';

@Injectable()
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class TeamCreateActionResolver {
  constructor(private _http: HttpClient, private _router: Router, private _auth: AuthService) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isGuide = this._auth.isAuthorized && this._auth.user.RoleId === UserRoles.GUIDE;
    localStorage.setItem(LocalStorageKeys.CREATE_TEAM, true.toString());
    const redirectUrl = `/${environment.guideRoute}/settings/workspace-settings`;
    this._auth.redirectUrl = redirectUrl;
    if (this._auth.isAuthorized) {
      if (!isGuide) {
        if (!this._auth.user.hasAlternativeProfile) {
          return this._auth.signupAlternativeAccount(null, undefined, true).subscribe();
        }
        return this._auth.createAndSwitchWorkspace(false).subscribe(() => {
          this._router.navigate([redirectUrl]).then();
        });
      }
      localStorage.setItem(LocalStorageKeys.CREATE_TEAM_POPUP, true.toString());
      return this._router.navigate([redirectUrl], { replaceUrl: true }).then();
    }
    return this._router.navigate(['/auth'], { replaceUrl: true }).then();
  }
}
