import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

// [TODO] Rework template (labels and inputs should be controlled by js)
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-marketplace-sorting-filter',
  templateUrl: './marketplace-sorting-filter.component.html',
  styleUrls: ['./marketplace-sorting-filter.component.scss']
})
export class MarketplaceSortingFilterComponent {
  form = this.fb.group({
    sorting: this.fb.control('default')
  });

  valuesWhitelist = ['startDateAsc', 'priceAsc', 'priceDesc'];

  prefix: string;

  @Output()
  selectItem = new EventEmitter();

  constructor(private fb: UntypedFormBuilder) {
    this.prefix = `MarketplaceSorting_${Math.random()}_`;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getForm() {
    return this.form;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  updateForm(value: string) {
    const sorting = this.valuesWhitelist.includes(value) ? value : 'default';
    this.form.patchValue({ sorting }, { emitEvent: false });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getValues() {
    const sorting = this.form.value.sorting;
    return sorting === 'default' ? null : sorting;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onChange() {
    this.selectItem.emit(this.form.value.sorting);
  }
}
