import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ChatsService } from '@app/core/chat/chats.service';

@Injectable()
export class LastActiveChatRedirectionGuard {
  constructor(private _chats: ChatsService, private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this._chats.lastActiveChat) {
      this._router.navigate([state.url, this._chats.lastActiveChat]);
    }

    return !this._chats.lastActiveChat;
  }
}
