<div class="notifications-page">
  <div class="notifications-container px-0 px-sm-4">
    <h2 pui-typography="h2" class="pui-padding_y-6" i18n="@@inboxTitle" *ngIf="isGuideRole; else notificationTitle">
      Inbox
    </h2>
    <div *ngIf="unreadNotifications.length || readNotifications.length; else emptyNotifications">
      <ng-scrollbar [visibility]="'hover'" [appearance]="'compact'">
        <ng-container *ngIf="unreadNotifications.length">
          <div class="notification-list-title notification-list-title--dark" style="padding-top: 0 !important">
            <div class="notifications-title">
              <ng-container i18n="@@newNotifications">New</ng-container>
              &nbsp;
              <pui-badge
                shape="square"
                background="#FF5F2C"
                color="#fff"
                size="s"
                [value]="unreadNotifications.length"></pui-badge>
            </div>
          </div>
          <app-notifications-list
            class="unread-notifications"
            [notifications]="unreadNotifications"
            (redirectEvent)="onRedirectDispatcher($event)"></app-notifications-list>
        </ng-container>
        <ng-container *ngIf="readNotifications.length">
          <div class="notification-list-title notifications-title" i18n="@@viewed" style="padding-top: 0 !important">
            Viewed
          </div>
          <div class="notifications-wrapper">
            <app-notifications-list
              class="read-notifications"
              [notifications]="readNotifications"
              (redirectEvent)="onRedirectDispatcher($event)"></app-notifications-list>
          </div>
        </ng-container>
      </ng-scrollbar>
    </div>
  </div>
</div>

<ng-template #notificationTitle>
  <h2 pui-typography="h2" i18n="@@notificationTitle" class="pui-padding_y-6">Notifications</h2>
</ng-template>

<ng-template #emptyNotifications>
  <div class="empty-list">
    <pui-icon svgIcon="menu:notifications" size="xxl"></pui-icon>
    <p class="empty-list__caption" i18n="@@emptyNotifications">Notifications will show up here</p>
  </div>
</ng-template>
