import { Injectable } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { io } from 'socket.io-client';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { LogService } from '@app/core/log/log.service';
import { UserRoles } from '@app/shared/enums/user-roles';
import config from '../config/config';
import { AuthService } from '../auth/services/auth.service';
import {
  ChatMessageStatuses,
  IChatMessage,
  IServerChat,
  IServerChatDetails,
  IServerChatHistory,
  IServerChatMessagesUpdate,
  IUserInfo
} from './types';

@Injectable()
export class ChatsSocketService {
  // @ts-expect-error TS7008
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _socket;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _chatCreated$ = new Subject<IServerChat>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _chatFound$ = new Subject<IServerChatDetails>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _chatOpened$ = new Subject<IServerChatDetails>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _chatRemoved$ = new Subject<{ id: string }>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _chatParticipantRemoved$ = new Subject<{ id: string; blockedUsers: { id: number }[] }>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _chatParticipantAdded$ = new Subject<{ id: string; newUsers: { id: number; role: UserRoles }[] }>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _chatsLoaded$ = new Subject<IServerChatDetails[]>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _directChatFound$ = new Subject<IServerChat>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isSocketConnected$ = new ReplaySubject<boolean>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _messageReceived$ = new Subject<IChatMessage>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _messageSent$ = new Subject<IChatMessage>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _messagesDelivered$ = new Subject<IServerChatMessagesUpdate>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _messagesLoaded$ = new Subject<IServerChatHistory>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _messagesRead$ = new Subject<IServerChatMessagesUpdate>();

  get chatCreated$(): Observable<IServerChat> {
    return this._chatCreated$.asObservable();
  }

  get chatFound$(): Observable<IServerChatDetails> {
    return this._chatFound$.asObservable();
  }

  get chatOpened$(): Observable<IServerChatDetails> {
    return this._chatOpened$.asObservable();
  }

  get chatRemoved$(): Observable<{ id: string }> {
    return this._chatRemoved$.asObservable();
  }

  get chatParticipantRemoved$(): Observable<{ id: string; blockedUsers: { id: number }[] }> {
    return this._chatParticipantRemoved$.asObservable();
  }

  get chatParticipantAdded$(): Observable<{ id: string; newUsers: { id: number; role: UserRoles }[] }> {
    return this._chatParticipantAdded$.asObservable();
  }

  get chatsLoaded$(): Observable<IServerChatDetails[]> {
    return this._chatsLoaded$.asObservable();
  }

  get directChatFound$(): Observable<IServerChat> {
    return this._directChatFound$.asObservable();
  }

  get isSocketAlive$(): Observable<boolean> {
    return this._isSocketConnected$;
  }

  get messageReceived$(): Observable<IChatMessage> {
    return this._messageReceived$.asObservable();
  }

  get messageSent$(): Observable<IChatMessage> {
    return this._messageSent$.asObservable();
  }

  get messagesDelivered$(): Observable<IServerChatMessagesUpdate> {
    return this._messagesDelivered$.asObservable();
  }

  get messagesRead$(): Observable<IServerChatMessagesUpdate> {
    return this._messagesRead$.asObservable();
  }

  get messagesLoaded$(): Observable<IServerChatHistory> {
    return this._messagesLoaded$.asObservable();
  }

  constructor(private _auth: AuthService, private _notifier: NotificationsService, private _logService: LogService) {
    this._auth.onAuth().subscribe(user => {
      this.destroySocket();

      if (user && user.authToken && user.RoleId) {
        this.initializeSocket(user.authToken, user.RoleId);
      }
    });
  }

  createChat(
    participant: IUserInfo,
    workspace?: { id: number } | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    message?: { text?: string | null; file?: { name: string; url: string }; meta?: any }
  ): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = { participant };

    if (workspace) {
      data.workspace = workspace;
    }

    if (message) {
      data.messages = [message];
    }

    this._socket.emit('user-chats:create-direct-chat', data);
  }

  findChat(chatId: string): void {
    this._socket.emit('user-chats:find-chat', { chatId });
  }

  findDirectChat(participant: IUserInfo, workspace?: { id: number } | null): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = { participant };

    if (workspace) {
      data.workspace = workspace;
    }

    this._socket.emit('user-chats:find-direct-chat', data);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  loadChatHistory(
    chatId: string,
    cursor: number,
    limit: number,
    latestOnly?: boolean,
    reversed?: boolean,
    eventId?: number | null
  ) {
    this._socket.emit('user-chat:load-messages', {
      chatId,
      cursor,
      limit,
      latestOnly: !!latestOnly,
      reversed,
      eventId
    });
  }

  loadUserChats(): void {
    this._socket.emit('user-chats:load-chats');
  }

  markMessagesDelivered(chatId: string, messageIds: number[]): void {
    this._socket.emit('chat-messages:update-status', {
      id: chatId,
      messageIds,
      status: ChatMessageStatuses.DELIVERED
    });
  }

  markMessagesRead(chatId: string, messageIds: number[]): void {
    this._socket.emit('chat-messages:update-status', {
      id: chatId,
      messageIds,
      status: ChatMessageStatuses.READ
    });
  }

  sendMessage(
    chatId: string,
    message: // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | { text: string; meta?: any }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      | { file: { name: string; url: string; size?: number }; text?: string | null; meta?: any }
  ): void {
    this._socket.emit('user-chat:add-messages', { chatId, messages: [message] });
  }

  private destroySocket(): void {
    if (this._socket) {
      this._socket.off();
      this._socket.disconnect();
      this._socket = null;
    }
  }

  private initializeSocket(token: string, role: number): void {
    this._socket = io(config.chatSocket.host, {
      transports: ['websocket'],
      path: config.chatSocket.path,
      auth: {
        token,
        role
      }
    });
    this.setSocketListeners();
    this._isSocketConnected$.next(true);
  }

  private setSocketListeners(): void {
    // @ts-expect-error TS7006
    this._socket.on('user-chats:new-chat', createdChat => this._chatCreated$.next(createdChat));
    // @ts-expect-error TS7006
    this._socket.on('user-chats:chat-found', foundChat => this._chatFound$.next(foundChat));
    // @ts-expect-error TS7006
    this._socket.on('user-chats:direct-chat-found', chat => this._directChatFound$.next(chat));
    // @ts-expect-error TS7006
    this._socket.on('user-chats:chats-loaded', chats => this._chatsLoaded$.next(chats));
    // @ts-expect-error TS7006
    this._socket.on('user-chats:chat-opened', chat => this._chatOpened$.next(chat));
    // @ts-expect-error TS7006
    this._socket.on('user-chats:chat-removed', chat => this._chatRemoved$.next(chat));
    // @ts-expect-error TS7006
    this._socket.on('user-chats:chat-participant-removed', chat => this._chatParticipantRemoved$.next(chat));
    // @ts-expect-error TS7006
    this._socket.on('user-chats:chat-participant-added', chat => this._chatParticipantAdded$.next(chat));

    // @ts-expect-error TS7006
    this._socket.on('user-chat:messages-loaded', history => this._messagesLoaded$.next(history));
    // @ts-expect-error TS7031
    this._socket.on('user-chat:new-messages', ([message]) => this._messageReceived$.next(message));
    // @ts-expect-error TS7031
    this._socket.on('user-chat:messages-sent', ([message]) => this._messageSent$.next(message));

    // @ts-expect-error TS7006
    this._socket.on('chat-messages:status-changed', change => {
      if (change.status === ChatMessageStatuses.DELIVERED) {
        this._messagesDelivered$.next(change);
      } else if (change.status === ChatMessageStatuses.READ) {
        this._messagesRead$.next(change);
      }
    });

    this._socket.on('connect', () => {
      this._logService.log(null, 'CHAT - connect');
      this._isSocketConnected$.next(true);
    });
    // @ts-expect-error TS7006
    this._socket.on('disconnect', reason => {
      this._logService.warn(reason, 'CHAT - socket disconnect');
      this._isSocketConnected$.next(false);
      if (reason === 'io server disconnect') {
        const message = `Chat disconnected`;
        this._notifier.error(message);
      }
    });
    this._socket.on('reconnect', () => this._logService.warn(null, 'CHAT - reconnect'));
    this._socket.on('reconnecting', () => this._logService.warn(null, 'CHAT - reconnecting'));
    this._socket.on('reconnect_attempt', () => this._logService.warn(null, 'CHAT - reconnect_attempt'));
    // @ts-expect-error TS7006
    this._socket.on('reconnect_error', err => this._logService.error(err, 'CHAT - reconnect_error'));
    // @ts-expect-error TS7006
    this._socket.on('reconnect_failed', err => this._logService.error(err, 'CHAT - reconnect_failed'));
    this._socket.on('connect_timeout', () => this._logService.error(null, 'CHAT - connect_timeout'));
    // @ts-expect-error TS7006
    this._socket.on('error', err => this._logService.error(err, 'CHAT - socket error'));
  }
}
