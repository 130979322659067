import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import type { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { escapeRegExp } from '@libs/shared/utils/escape-reg-exp';

@Component({
  selector: 'app-selected-users-card',
  templateUrl: './selected-users-card.component.html',
  styleUrls: ['./selected-users-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectedUsersCardComponent {
  MAX_DISPLAYED_GUIDES = 2;

  @Input()
  clients!: Partial<CRMClient>[];

  search = '';

  // @ts-expect-error TS7006
  trackByClientId(_, client: Partial<CRMClient>): Partial<CRMClient>['id'] {
    return client.id;
  }

  readonly filterByNameOrEmail = (search: string) => (client: Partial<CRMClient>) => {
    if (!search?.trim()) {
      return true;
    }

    const searchExpression = new RegExp(escapeRegExp(search), 'i');

    return (
      searchExpression.test(String(`${client.firstName} ${client.lastName}`)) ||
      searchExpression.test(String(client?.email))
    );
  };
}
