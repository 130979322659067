import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import config from '../../../../core/config/config';

@Injectable()
export class ProgramInstructorsApiService<TInstructor> {
  constructor(private readonly _http: HttpClient, private readonly _notifications: NotificationsService) {}

  findInstructors(searchString: string): Observable<TInstructor[]> {
    const params = new HttpParams({
      fromObject: {
        search: searchString
        // 'exclude[]': exclude.map((id) => ''+id )
      }
    });

    return this._http
      .get<{ instructors: TInstructor[] }>(`${config.apiPath}/user/guide/programs/instructors`, {
        params
      })
      .pipe(
        catchError(() => {
          this._notifications.error(`Cannot find instructors`);
          return of({ instructors: [] });
        }),
        map(({ instructors }) => instructors)
      );
  }
}
