import { MaskitoOptions } from '@maskito/core';

export const GREATER_THEN_1: MaskitoOptions = {
  mask: /^\d+$/,
  preprocessors: [
    ({ elementState }) => ({
      elementState,
      value: elementState.value.replace(/\D/g, '') || '1'
    })
  ],
  postprocessors: [
    elementState => ({
      value: Number(elementState.value) < 1 ? '1' : elementState.value,
      selection: elementState.selection
    })
  ]
};
