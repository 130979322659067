import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@app/core/auth/services/auth.service';
import { FormService } from '@app/core/form/form.service';
import { HelpMeFindAGuideService } from '@app/core/public/help-me-find-a-guide.service';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { InternalEvents } from '@app/core/analytics/types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-help-me-find-a-guide-modal',
  templateUrl: './help-me-find-a-guide-modal.component.html',
  styleUrls: ['./help-me-find-a-guide-modal.component.scss'],
  providers: [HelpMeFindAGuideService]
})
export class HelpMeFindAGuideModalComponent implements OnInit {
  readonly MAX_TEXT_LENGTH = 2048;

  // @ts-expect-error TS2564
  helpForm: UntypedFormGroup;

  get showEmail(): boolean {
    return !this._auth.isAuthorized;
  }

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _form: FormService,
    private _modal: NgbActiveModal,
    private _helpMeFindAGuide: HelpMeFindAGuideService,
    private _auth: AuthService,
    private _analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.helpForm = this._formBuilder.group({
      email: [this._auth.isAuthorized ? this._auth.user.email : null, [Validators.required, Validators.email]],
      text: [null, [Validators.required, Validators.maxLength(this.MAX_TEXT_LENGTH)]]
    });
  }

  cancel(): void {
    this._modal.dismiss();
  }

  sendMessage(): void {
    if (this._form.markInvalidForm(this.helpForm)) {
      return;
    }
    const { email, text } = this.helpForm.value;
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._helpMeFindAGuide.sendMessage$(email, text).subscribe();

    this._analyticsService.event(InternalEvents.HELP_FIND_GUIDE);

    this._modal.close();
  }
}
