import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import config from '@app/core/config/config';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { CustomUrlQueryEncoder } from '@app/shared/utils/custom-url-query-encoder';
import { INoteContent, INoteUpdatedContent } from '@app/shared/interfaces/notes';
import { SIMPLE_ID } from '@app/core/simple-id';
import { NotificationsService } from '@awarenow/profi-ui-core';
import {
  IClientNote,
  IGetClientNotesParams,
  IClientNotesResponse,
  IClientAdjacentNotesResponse,
  IGetAdjacentNotesParams,
  ClientNote
} from './client-notes.type';

@Injectable({
  providedIn: 'root'
})
export class ClientNotesApiService {
  // [TODO] move token injection in wrapper service
  constructor(
    private readonly _http: HttpClient,
    private readonly _notifications: NotificationsService,
    // @ts-expect-error TS7006
    @Inject(SIMPLE_ID) private _token
  ) {}

  // @ts-expect-error TS7006
  createNote$(programId, moduleId, note): Observable<ClientNote> {
    return this._http
      .post<{ id: number }>(`${config.apiPath}/user/client/notes`, {
        programId,
        moduleId,
        note,
        token: this._token
      })
      .pipe(
        catchError(error => {
          this._notifications.error(`Note is not created`);
          return throwError(error);
        }),
        switchMap(({ id }) => this.getNote$(id))
      );
  }

  updateNote$(id: number, note: INoteContent): Observable<INoteUpdatedContent> {
    return this._http
      .put<INoteUpdatedContent>(`${config.apiPath}/user/client/notes/${id}`, {
        note,
        token: this._token
      })
      .pipe(
        catchError(error => {
          this._notifications.error(`Cannot update note`);
          return throwError(error);
        })
      );
  }

  pinNote$(id: number): Observable<{ pinnedAt: string }> {
    return this._http
      .post<{ pinnedAt: string }>(`${config.apiPath}/user/client/notes/pin/${id}`, { token: this._token })
      .pipe(
        catchError(error => {
          this._notifications.error(`Cannot pin note`);
          return throwError(error);
        })
      );
  }

  unpinNote$(id: number): Observable<void> {
    return this._http
      .delete<void>(`${config.apiPath}/user/client/notes/pin/${id}`, {
        params: new HttpParams({
          encoder: new CustomUrlQueryEncoder(),
          fromObject: { token: this._token }
        })
      })
      .pipe(
        catchError(error => {
          this._notifications.error(`Cannot unpin note`);
          return throwError(error);
        })
      );
  }

  getNotes$({
    direct,
    cursor,
    limit,
    pinned,
    search
  }: IGetClientNotesParams): Observable<{ cursor: string | null; notes: ClientNote[] }> {
    const params: { [prop: string]: string } = {};
    if (direct) {
      params.direct = direct.toString();
    }

    if (limit) {
      params.limit = limit.toString();
    }

    if (cursor) {
      params.cursor = cursor;
    }

    if (pinned) {
      params.pinned = pinned.toString();
    }

    if (search) {
      params.search = search;
    }

    return this._http
      .get<IClientNotesResponse>(`${config.apiPath}/user/client/notes`, {
        params: new HttpParams({
          encoder: new CustomUrlQueryEncoder(),
          fromObject: params
        })
      })
      .pipe(
        catchError(error => {
          this._notifications.error(`Cannot load notes.`);
          return throwError(error);
        }),
        map(({ notes, ...restResponse }) => ({
          notes: notes.map(note => new ClientNote(note)),
          ...restResponse
        }))
      );
  }

  // Temporary unused
  // TODO: This should be rewritten, before implementing search in notes widget
  getAdjacentNotes$({
    id,
    delta
  }: IGetAdjacentNotesParams): Observable<{ notes: ClientNote[]; leftCursor: string; rightCursor: string }> {
    const params: { [prop: string]: string } = {};

    if (delta) {
      params.delta = delta.toString();
    }

    return this._http
      .get<IClientAdjacentNotesResponse>(`${config.apiPath}/user/client/notes/adjacent/${id}`, {
        params: new HttpParams({
          encoder: new CustomUrlQueryEncoder(),
          fromObject: params
        })
      })
      .pipe(
        catchError(error => {
          this._notifications.error(`Cannot load adjacent notes.`);
          return throwError(error);
        }),
        map(({ notes, ...restResponse }) => ({
          notes: notes.map(note => new ClientNote(note)),
          ...restResponse
        }))
      );
  }

  getNote$(id: number): Observable<ClientNote> {
    return this._http.get<IClientNote>(`${config.apiPath}/user/client/notes/${id}`).pipe(
      catchError(error => {
        this._notifications.error(`Cannot get note`);
        return throwError(error);
      }),
      map(note => new ClientNote(note))
    );
  }

  // [TODO] Delete also should send the token, use query string to transfer token in all methods for consistency
  deleteNote$(id: number): Observable<void> {
    return this._http.delete<void>(`${config.apiPath}/user/client/notes/${id}`).pipe(
      catchError(error => {
        // this._notifications.error(`Can not delete note`);
        return throwError(error);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getSharedNotes$(noteIds: number[]) {
    const params = new HttpParams({
      encoder: new CustomUrlQueryEncoder(),
      fromObject: {
        'n[]': noteIds.map(id => `${id}`),
        t: `${Date.now()}` // Required to beat Angular inner caching
      }
    });
    return this._http.get<{ notes: IClientNote[] }>(`${config.apiPath}/user/client/notes/shared`, { params }).pipe(
      catchError(error => {
        return throwError(error);
      }),
      map(({ notes }) => notes.map(note => new ClientNote(note)))
    );
  }
}
