<div class="modal-header">
  <app-button class="closeBtn" (click)="dismiss()" className="close"></app-button>
</div>

<div class="modal-body">
  <div class="images">
    <div class="image" *ngFor="let image of images">
      <img *ngIf="!image.loading" [src]="image.src" alt="image" />
      <div class="loading" *ngIf="image.loading"></div>
    </div>
  </div>
  <input type="file" #load class="d-none" (change)="uploadImage(load.files)" accept="image/*" capture="camera" />
  <app-button className="btn-add" (click)="load.click()" i18n="@@addPhoto">Add Photo</app-button>
</div>
<div class="editor-wrapper">
  <quill-editor
    [(ngModel)]="message"
    [format]="'html'"
    [modules]="quillModules"
    [placeholder]="'Anything you\'d like to add to this image?'"
    i18n-placeholder="@@attachFilePlaceholder"></quill-editor>
  <div class="actions">
    <button type="button" class="emoji-picker-btn" (click)="toggleEmojiPicker()"></button>
    <button class="send-btn" (click)="emitSendMessage()"></button>
  </div>
</div>
