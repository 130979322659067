import { Injectable } from '@angular/core';

/**
 * @deprecated
 */
@Injectable()
export class SsrCookieService {
  private cookieStore: Record<string, unknown> = {};

  get(key: string) {
    return this.cookieStore[key] || null;
  }
}
