<div *ngIf="authService.user.RoleId === UserRoles.GUIDE" class="sidebar-nav">
  <a
    class="sessions"
    routerLink="/{{ GUIDE_LIST_ROUTE }}/dashboard"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    title="Calendar"
    i18n-title="@@calendar">
    <i>
      <sup *ngIf="sessionRequestsCounter">{{ sessionRequestsCounter }}</sup>
    </i>
    <span i18n="@@calendar">Calendar</span>
    <em i18n="@@sessionsCalendarManagement">Session and calendar management</em>
  </a>

  <a
    class="clients"
    routerLink="/{{ GUIDE_LIST_ROUTE }}/clients"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    title="Clients"
    i18n-title="@@clients">
    <span i18n="@@clients">Clients</span>
    <em i18n="@@clients">Clients</em>
  </a>
  <a
    class="chat"
    routerLink="/{{ GUIDE_LIST_ROUTE }}/chat"
    routerLinkActive="active"
    title="Secure messaging"
    i18n-title="@@secureMessaging">
    <span class="title-with-badge">
      <span i18n="@@secureMessaging">Secure messaging</span>
      <div *ngIf="unreadChatMessagesCounter" class="badge"></div>
    </span>
    <em i18n="@@secureMessagingGuideDescription">Communication with your clients in a secure channel</em>
  </a>
  <a
    class="services events"
    routerLink="/{{ GUIDE_LIST_ROUTE }}/services"
    routerLinkActive="active"
    title="Services"
    i18n-title="@@Services">
    <span i18n="@@services">Services</span>
    <em i18n="@@servicesDescription">Services</em>
  </a>
  <a
    class="public-events"
    routerLink="/public-events"
    *ngIf="PUBLIC_EVENTS_ENABLED_FOR_GUIDES"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    title="Events"
    i18n-title="@@events">
    <span i18n="@@events">Events</span>
    <em i18n="@@publicEventsDescription">Platform public events</em>
  </a>
  <a
    class="quizes"
    *ngIf="authService.user.namedUrl && config.enableECourcesNav"
    routerLink="/{{ GUIDE_LIST_ROUTE }}/questionnaires"
    routerLinkActive="active"
    title="Forms"
    i18n-title="@@forms">
    <span i18n="@@forms">Forms</span>
  </a>
  <a
    class="blog"
    routerLink="/{{ GUIDE_LIST_ROUTE }}/blog"
    routerLinkActive="active"
    title="Publishing"
    i18n-title="@@publishing"
    *ngIf="config.enableBlogNav">
    <span i18n="@@publishing">Publishing</span>
    <em i18n="@@publishingDescription">Your articles and drafts</em>
  </a>
</div>

<div *ngIf="authService.user.RoleId === UserRoles.CLIENT" class="sidebar-nav">
  <a
    class="dashboard"
    routerLink="/client/dashboard"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    title="Sessions"
    i18n-title="@@sessions">
    <i>
      <sup *ngIf="sessionRequestsCounter">{{ sessionRequestsCounter }}</sup>
    </i>
    <span i18n="@@sessions">Sessions</span>
    <em i18n="@@sessionsHistorySchedule">Session history and schedule</em>
  </a>
  <a
    *ngIf="config.enableMarketplace"
    class="find-guide"
    routerLink="/{{ GUIDES_LIST_ROUTE }}"
    routerLinkActive="active"
    title="Find your coach"
    i18n-title="@@sidebarNavigationFindYourGuideLinkPlaceholder">
    <span transloco="practitioners">Coaches</span>
    <em transloco="practitionersNote">Browse our directory of verified coaches</em>
  </a>
  <a
    class="chat"
    routerLink="/client/chat"
    routerLinkActive="active"
    title="Secure messaging"
    i18n-title="@@secureMessaging">
    <span class="title-with-badge">
      <span transloco="secureMessaging">Secure messaging</span>
      <div *ngIf="unreadChatMessagesCounter" class="badge"></div>
    </span>
    <em transloco="secureMessagingClientDescription">Communication with your coaches in a secure channel</em>
  </a>
  <a
    class="events"
    [routerLink]="'/programs'"
    routerLinkActive="active"
    title="Programs"
    i18n-title="@@programs"
    *ngIf="doesClientHaveAccessiblePrograms && config.enablePrograms">
    <i>
      <sup *ngIf="unreadProgramsCounter">{{ unreadProgramsCounter }}</sup>
    </i>
    <span i18n="@@programs">Programs</span>
    <em i18n="@@programsClientDescription">Multi-session packages and content programs</em>
  </a>
  <a
    class="public-events"
    routerLink="/public-events"
    *ngIf="PUBLIC_EVENTS_ENABLED_FOR_CLIENTS && (publicEventsService.arePublicEventsAvailableForClient$ | async)"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    title="Events"
    i18n-title="@@events">
    <span i18n="@@events">Events</span>
    <em i18n="@@publicEventsDescription">Platform public events</em>
  </a>
  <a
    class="surveys"
    [routerLink]="'/surveys'"
    routerLinkActive="active"
    title="Forms"
    i18n-title="@@forms"
    *ngIf="config.enableSurveys">
    <i>
      <sup *ngIf="surveysCounter">{{ surveysCounter }}</sup>
    </i>
    <span transloco="forms">Forms</span>
    <em transloco="formsClientDescription">Assigned by your coaches</em>
  </a>
</div>

<div *ngIf="authService.user.RoleId === UserRoles.ADMIN" class="sidebar-nav">
  <a
    class="dashboard"
    routerLink="/admin/dashboard"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }">
    <span i18n="@@dashboard">Dashboard</span>
  </a>
  <a
    class="dashboard"
    routerLink="/admin/{{ GUIDES_LIST_ROUTE }}"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }">
    <span transloco="guides">Coaches</span>
  </a>
  <a
    class="dashboard"
    routerLink="/admin/clients"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }">
    <span i18n="@@clients">Clients</span>
  </a>
  <a
    class="dashboard"
    routerLink="/admin/gift-coupons"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }">
    <span i18n="@@giftCoupons">Gift Coupons</span>
  </a>
  <a
    class="dashboard"
    routerLink="/admin/sessions"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }">
    <span i18n="@@sessions">Sessions</span>
  </a>
  <a class="dashboard" routerLink="/admin/tags" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
    <span i18n="@@tags">Tags</span>
  </a>
  <a class="dashboard" routerLink="/admin/wiki" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
    <span i18n="@@wiki">Wiki</span>
  </a>
  <a class="dashboard" routerLink="/admin/blog" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
    <span i18n="@@ownBlog">Own Blog</span>
  </a>
  <a
    class="dashboard"
    routerLink="/admin/programs"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }">
    <span i18n="@@programs">Programs</span>
  </a>
  <a class="dashboard" routerLink="/blog" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
    <span i18n="@@otherBlog">Other Blogs</span>
  </a>
  <a
    class="dashboard"
    routerLink="/business-blog"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }">
    <span i18n="@@businessBlog">Business blog</span>
  </a>
  <a
    class="dashboard"
    routerLink="/admin/branding"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }">
    <span i18n="@@branding">Branding</span>
  </a>
  <a
    class="dashboard"
    routerLink="/admin/global-config"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }">
    <span i18n="@@sidebarNavLinkHeaderGlobalConfig">Global config</span>
  </a>
  <a
    class="dashboard"
    routerLink="/admin/community-config"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }">
    <span i18n="@@sidebarNavLinkHeaderCommunityConfig">Community config</span>
  </a>
  <a
    class="dashboard header-footer"
    routerLink="/admin/header-footer-config"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }">
    <span i18n="@@sidebarNavLinkHeaderFooter">Header & footer</span>
  </a>
  <a class="dashboard" routerLink="/admin/pages" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
    <span i18n="@@sidebarNavLinkPages">Pages</span>
  </a>
  <a
    class="dashboard"
    routerLink="/admin/membership"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }">
    <span i18n="@@sidebarNavLinkPlatformSubscriptions">Platform subscriptions</span>
  </a>
  <a
    class="dashboard"
    routerLink="/admin/payment-options"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }">
    <span i18n="@@sidebarNavLinkPaymentOptions">Payment options</span>
  </a>
</div>

<div *ngIf="authService.user.RoleId === UserRoles.SUPER_ADMIN" class="sidebar-nav">
  <a
    class="organizations"
    routerLink="/super-admin/organizations"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }">
    <span i18n="@@sidebarNavigationSuperAdminOrganizations">Organizations</span>
  </a>
  <a
    class="administrators"
    routerLink="/super-admin/administrators"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }">
    <span i18n="@@sidebarNavigationSuperAdminAdministrators">Administrators</span>
  </a>
</div>

<div *ngIf="!authService.user.RoleId" class="sidebar-nav right">
  <app-button class="btn-sign-up d-sm-inline-block sign-up" className="btn-primary" (click)="openSignIn()">
    <ng-container i18n="@@signIn">Sign in</ng-container>
  </app-button>
</div>

<ng-container *ngIf="canShowHelpMeFindAGuide && config.enableHelpMeFindAGuide">
  <div class="col find-a-guide-help-block">
    <app-button className="btn-lg btn-find-guide" (click)="helpToFindAGuide()">
      <span i18n="@@getPersonalizedHelp">Get Personalized Help</span>
    </app-button>
  </div>
</ng-container>
