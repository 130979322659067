import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { clipboardPasteMatcher, keyboardBindings, toolbarDefaultModules } from '@app/modules/text-editor/modules';
import { ILabelledProgramOption } from '@app/screens/guide/guide-programs/types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-content-module',
  templateUrl: './content-module.component.html',
  styleUrls: [
    '../../../../../modules/guide-service-editor/common-styles/forms.scss',
    './content-module.component.scss'
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'content-module'
  }
})
export class ContentModuleComponent {
  @Input()
  // @ts-expect-error TS2564
  hideInstructor: boolean;

  @Input()
  // @ts-expect-error TS2564
  moduleActivations: ILabelledProgramOption<string>[];

  @Input()
  // @ts-expect-error TS2564
  moduleForm: UntypedFormGroup;

  @Output()
  titleChange = new EventEmitter<{ html: string }>();

  readonly moduleTitleQuillModules = {
    clipboard: clipboardPasteMatcher,
    keyboard: keyboardBindings,
    toolbar: toolbarDefaultModules
  };
}
