import { FieldPolicy } from '@apollo/client/cache/inmemory/policies';

export const getOfferPolicy: {
  [fieldName: string]: FieldPolicy;
} = {
  getOffer: {
    keyArgs: ['input', 'id'],
    read(existing, { args, toReference }) {
      return existing || toReference({ __typename: 'Offer', id: args?.input?.id });
    },
    merge(existing, incoming, { mergeObjects }) {
      if (!existing) {
        return incoming;
      }
      return mergeObjects(existing, incoming);
    }
  }
};
