import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmartPopupModule } from '@app/modules/ui-kit/smart-popup/smart-popup.module';
import { SidenavWorkspaceSwitchWidgetService } from '@app/modules/sidenav/components/sidenav-workspace-switch/sidenav-workspace-switch.service';
import { SidenavWorkspaceSwitchButtonComponent } from '@app/modules/sidenav/components/sidenav-workspace-switch/view/workspace-switch-button.component';
import { SidenavWorkspaceSwitchWidgetComponent } from '@app/modules/sidenav/components/sidenav-workspace-switch/sidenav-workspace-switch-widget.component';
import { SignUpAsProfiComponent } from '@app/modules/sidenav/components/sidenav-workspace-switch/view/sign-up-as-profi.component';
import { PuiButtonModule, PuiIconModule, PuiLinkModule } from '@awarenow/profi-ui-core';
import { OverlayModule } from '@angular/cdk/overlay';
import { TranslocoRootModule } from '@libs/transloco';

@NgModule({
  declarations: [SidenavWorkspaceSwitchWidgetComponent, SidenavWorkspaceSwitchButtonComponent, SignUpAsProfiComponent],
  imports: [
    CommonModule,
    SmartPopupModule,
    PuiButtonModule,
    PuiIconModule,
    OverlayModule,
    PuiLinkModule,
    TranslocoRootModule
  ],
  providers: [SidenavWorkspaceSwitchWidgetService]
})
export class SidenavWorkspaceSwitchWidgetModule {}
