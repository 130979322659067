// eslint-disable-next-line no-restricted-imports
import { addMonths, differenceInDays, endOfMonth, endOfWeek } from 'date-fns';
// eslint-disable-next-line no-restricted-imports
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { merge, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import { OfferGuideApiService } from '@app/modules/service-scheduling/services/offer-guide-api.service';
import { FreeTimeSlotResponce, TimeSlot } from '@app/modules/service-scheduling/services/types';
import { GuideServices } from '@app/modules/service-scheduling/types';
import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { PuiDateService, PuiDestroyService } from '@awarenow/profi-ui-core';
import { UserTimezoneStore } from '@libs/core/user-timezone.store';
import { AvailabilityStore, GetAvailabilityParameters } from '@libs/stores/availability/availability.store';
import { SessionType } from '@app/shared/enums/session-type';

interface FullDateInputValue {
  date: Date;
  time: Date;
  timezone: string;
  extended: boolean;
}

@Component({
  selector: 'app-full-date',
  templateUrl: './full-date.component.html',
  styleUrls: ['./full-date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FullDateComponent)
    },
    PuiDestroyService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullDateComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
  form = this.fb.group({
    date: this.fb.control(null, [Validators.required]),
    time: this.fb.control(null, [Validators.required]),
    timezone: this.fb.control(null, [Validators.required]),
    extended: this.fb.control(false, [Validators.required])
  });

  isIdle$ = this.guideAvailabilityStore.isIdle$;
  isLoading$ = this.guideAvailabilityStore.isLoading$;
  slots!: TimeSlot[];

  readonly minDate = DateTime.now().startOf('day');
  readonly availability$ = this.guideAvailabilityStore.availability$;
  readonly calendarDateChanges$ = new Subject<Date>();

  @Input()
  hostControl!: AbstractControl;

  @Input()
  service!: GuideServices.RootObject;

  @Input()
  chosenClients: CRMClient[] = [];

  @Input()
  hideTimeslotsWithAttendee = false;

  get timeControlDisabled(): boolean {
    const dateControl = this.form?.get('date') as AbstractControl;

    return dateControl?.invalid || this.hostControl.invalid;
  }

  get timezone(): string {
    const timeZoneControl = this.form?.get('timezone') as AbstractControl;

    return timeZoneControl.value || this.timezoneStore.timezone;
  }

  get extended(): boolean {
    const extendedControl = this.form.get('extended') as AbstractControl;

    return extendedControl.value;
  }

  get canBeExtended(): boolean {
    return this.service.sessionType === SessionType.PERSONAL;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private offerGuideApiService: OfferGuideApiService,
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>,
    readonly cdRef: ChangeDetectorRef,
    private guideAvailabilityStore: AvailabilityStore,
    readonly timezoneStore: UserTimezoneStore,
    private dateService: PuiDateService
  ) {}

  ngOnInit(): void {
    this.handleDaysControlsChange();
    this.handleHostChange();
    this.handleCalendarDateChanges();
  }

  ngAfterViewInit(): void {
    this.handleTimezoneChange();
  }

  ngOnDestroy(): void {
    this.guideAvailabilityStore.reset();
  }

  onChange = (_: unknown): void => undefined;
  onTouched = (): void => undefined;

  registerOnChange(fn: (value: { [key: string]: unknown }) => void): void {
    this.form?.valueChanges
      .pipe(distinctUntilChanged(isEqual), takeUntil(this.destroy$))
      .subscribe(({ time, timezone }) => {
        const chosenSlot = this.slots?.find(slot => slot.time === time);

        fn({
          date: time,
          timezone,
          isBookingExist: !!chosenSlot?.bookingUid,
          recurringEventId: chosenSlot?.recurringEventId,
          isGroupWithoutRecurrent: !chosenSlot?.recurringEventId && !!chosenSlot?.attendees,
          available: chosenSlot?.available
        });
      });
  }

  registerOnTouched(fn: () => undefined): void {
    this.onTouched = fn;
  }

  writeValue(value: FullDateInputValue): void {
    if (value?.time) {
      this.slots = [{ time: value.time, users: [], seats: 0, available: true }];
    }

    if (!isEqual(value, this.form.value)) {
      this.form.patchValue({
        date: value.date,
        time: value.time,
        timezone: value.timezone,
        extended: value.extended
      });

      this.getGuideAvailabilitySlots();
    }
  }

  readonly isPartialAvailableDate = (date: Date): boolean => {
    return this.dateService.isAfter(date, this.minDate.minus({ days: 1 }).toJSDate());
  };

  /**
   * Handle session host changes.
   * @private
   */
  private handleHostChange(): void {
    this.hostControl.valueChanges.pipe(distinctUntilChanged(isEqual), takeUntil(this.destroy$)).subscribe(() => {
      /**
       * Will fetch guide availability.
       */
      this.getGuideAvailabilitySlots();
    });
  }

  private handleTimezoneChange(): void {
    this.form
      ?.get('timezone')
      ?.valueChanges.pipe(distinctUntilChanged(isEqual), takeUntil(this.destroy$))
      .subscribe(() => {
        this.form.patchValue({
          time: null,
          date: null,
          extended: false
        });

        this.guideAvailabilityStore.reset();
        this.getGuideAvailabilitySlots();
      });
  }

  private handleDaysControlsChange(): void {
    const dateControl = this.form?.get('date') as AbstractControl;
    const timeZoneControl = this.form?.get('timezone') as AbstractControl;
    const extendedControl = this.form?.get('extended') as AbstractControl;

    merge(
      this.hostControl?.valueChanges,
      dateControl.valueChanges.pipe(
        tap(() => {
          this.disableExtended();
        })
      ),
      extendedControl.valueChanges
    )
      .pipe(
        map(() => {
          const host = this.hostControl?.value;
          const date = dateControl.value;
          const timeZone = timeZoneControl.value;
          const extended = extendedControl.value;

          return { host, date, timeZone, extended };
        }),
        distinctUntilChanged(isEqual),
        filter(({ host, date, timeZone }) => !!date && !!host && !!timeZone),
        switchMap(({ host, date, timeZone, extended }) => {
          return this.offerGuideApiService.freeTimeRanges$({
            startTime: DateTime.fromJSDate(date).minus({ days: 1 }).toISODate(),
            endTime: DateTime.fromJSDate(date).plus({ days: 2 }).toISODate(),
            ...(host.userId ? { hostId: host.userId } : {}),
            eventTypeId: this.service.id,
            timeZone,
            extended
          });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: ({ slots }: FreeTimeSlotResponce) => {
          const startTime = DateTime.fromJSDate(dateControl.value).toISODate();
          const timeSlots = slots[startTime] || [];
          this.slots = this.extended ? timeSlots : this.filterConsideringSeatsLimit(timeSlots);
          this.checkIfValueExist();
          this.cdRef.markForCheck();
        },
        error: () => {
          this.slots = [] as TimeSlot[];
          this.form.patchValue({
            time: null
          });
          this.cdRef.markForCheck();
        }
      });
  }

  private checkIfValueExist({ time } = this.form.getRawValue()): void {
    if (!this.slots.find(slot => slot.time === time)) {
      this.form.patchValue({
        time: null
      });
    }
  }

  /**
   * Fetch guide available time.
   * @private
   */
  private getGuideAvailabilitySlots(
    { timeZone, hostId, eventTypeId, extended }: Partial<GetAvailabilityParameters> = this.getConfig()
  ): void {
    if (!(timeZone && eventTypeId)) {
      return;
    }
    const today = new Date();

    this.disableExtended();

    this.guideAvailabilityStore.getAvailability({
      startTime: DateTime.fromJSDate(today).toISODate(),
      endTime: DateTime.fromJSDate(today).plus({ month: 2 }).toISODate(),
      eventTypeId,
      hostId,
      timeZone,
      extended: extended || false
    });
  }

  /**
   * Handle calendar changes to upload new dates.
   * @private
   */
  private handleCalendarDateChanges(): void {
    this.disableExtended();
    this.calendarDateChanges$
      .pipe(
        debounceTime(300),
        mergeMap(calendarDate =>
          this.guideAvailabilityStore.lastAvailabilityDate$.pipe(
            take(1),
            map((lastEvent: string) => ({
              calendarDate: calendarDate,
              lastEventDate: new Date(lastEvent)
            }))
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(({ calendarDate, lastEventDate }) => {
        const me = endOfMonth(calendarDate);
        const endOfCalendarWeek = endOfWeek(me);
        const diff = differenceInDays(lastEventDate, endOfCalendarWeek);

        if (diff < 0) {
          this.guideAvailabilityStore.getAvailability({
            ...this.getConfig(),
            startTime: lastEventDate.toISOString(),
            endTime: addMonths(endOfCalendarWeek, 2).toISOString()
          } as GetAvailabilityParameters);
        }

        if (diff > 25 && diff < 31) {
          this.guideAvailabilityStore.getAvailability({
            ...this.getConfig(),
            startTime: lastEventDate.toISOString(),
            endTime: addMonths(lastEventDate, 2).toISOString()
          } as GetAvailabilityParameters);
        }
      });
  }

  private getConfig(): Partial<GetAvailabilityParameters> {
    return {
      hostId: this.hostControl?.value?.userId,
      eventTypeId: this.service.id,
      timeZone: this.form.value.timezone,
      extended: this.form.value.extended
    };
  }

  private filterConsideringSeatsLimit(timeSlots: TimeSlot[]): TimeSlot[] {
    return timeSlots.filter(slot => {
      if (this.hideTimeslotsWithAttendee && slot.attendees) {
        return false;
      }

      // If nobody was selected
      if (this.chosenClients.length === 0 || !slot.seats) {
        return true;
      }
      // If available seats > chosenClients.length
      return slot.seats - (slot?.attendees || 0) >= this.chosenClients.length;
    });
  }

  private disableExtended(): void {
    this.form.patchValue({
      extended: false
    });
  }
}
