import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { finalize, switchMap } from 'rxjs/operators';

import { AuthService } from '@app/core/auth/services';
import { CreateTeamModalComponent } from '@app/modules/workspaces/components/create-team-modal/create-team-modal.component';
import { FloatingPopoverService, POPOVER_COMPONENT_DATA } from '@app/modules/floating-popover/floating-popover.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import {
  ScreenSize,
  ScreenState,
  StyleBreakpointsProviderService
} from '@app/modules/ui-kit/_base/_common/screen/services';

import { IWorkspace } from '../../types';
import { WorkspacesService } from '../../services/workspaces.service';

@Component({
  selector: 'app-workspaces-menu',
  templateUrl: './workspaces-menu.component.html',
  styleUrls: ['./workspaces-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'workspace-menu-container'
  }
})
export class WorkspacesMenuComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isWorkspaceSwitching = false;

  screen$: Observable<Partial<ScreenState>>;

  // @ts-expect-error TS2564
  workspaceForm: UntypedFormGroup;

  canCreateWorkspace: boolean;

  get workspaces(): IWorkspace[] {
    return this.popoverData?.workspaces || this.bottomSheetData?.workspaces;
  }

  get activeWorkspace(): IWorkspace {
    return this.popoverData?.activeWorkspace || this.bottomSheetData?.activeWorkspace;
  }

  constructor(
    private readonly _runtimeConfigService: RuntimeConfigService,
    private readonly _modal: NgbModal,
    private readonly _floatingPopover: FloatingPopoverService,
    private readonly _bottomSheetRef: MatBottomSheetRef,
    private readonly _authService: AuthService,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _router: Router,
    private readonly _workspacesService: WorkspacesService,
    readonly _screenService: StyleBreakpointsProviderService,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(POPOVER_COMPONENT_DATA) public popoverData: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any
  ) {
    this.screen$ = _screenService.observe([ScreenSize.Mobile]);
    // @ts-expect-error TS2322
    this.canCreateWorkspace = this._runtimeConfigService.get('canCreateWorkspace');
  }

  ngOnInit(): void {
    this.workspaceForm = this._formBuilder.group({
      workspaceControl: []
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  closeWorkspaceMenu() {
    this._floatingPopover.closeModalSource.next();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this._bottomSheetRef.dismiss && this._bottomSheetRef.dismiss();
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  createTeamWorkspace($event) {
    $event.preventDefault();
    this._modal.open(CreateTeamModalComponent, { backdrop: 'static' });
    this.closeWorkspaceMenu();
  }

  switchWorkspace(workspaceId: number): void {
    if (this._isWorkspaceSwitching || !workspaceId) {
      return;
    }

    this._isWorkspaceSwitching = true;

    this._workspacesService
      .switchWorkspace$(workspaceId)
      .pipe(
        switchMap(({ done }) => (done ? this._workspacesService.setNextWorkspace$(workspaceId) : of(null))),
        finalize(() => {
          this.closeWorkspaceMenu();
          this._isWorkspaceSwitching = false;
        })
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(res => res && this._router.navigate(['/dashboard'], { replaceUrl: true }));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  signOut() {
    this._authService.signout();
  }
}
