import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from '@app/shared/components/button/button.component';

/**
 * @deprecated
 */
@NgModule({
  declarations: [ButtonComponent],
  exports: [ButtonComponent],
  imports: [FormsModule]
})
export class ButtonModule {}
