<div class="chat-message-container" [ngClass]="message?.status" data-qa-id="chat-history-message">
  <div class="chat-message__content">
    <pui-avatar
      [attr.data-qa-id]="'chat-message-sender-avatar-' + message.text"
      [ngClass]="{ clickable: doesSenderHaveNamedUrl }"
      [routerLink]="doesSenderHaveNamedUrl ? ['/', $any(sender).namedUrl] : null"
      [avatarUrl]="sender?.photo"
      [text]="sender?.name" />
    <div class="chat-message__side-content">
      <div
        [ngClass]="{ clickable: doesSenderHaveNamedUrl }"
        [routerLink]="doesSenderHaveNamedUrl ? ['/', $any(sender).namedUrl] : null">
        <span pui-typography="body-s-bold" [attr.data-qa-id]="'chat-message-sender-name-title-' + message.text">{{
          sender?.name
        }}</span>
        <small
          pui-typography="body-s"
          class="date-time pui-space_left-2"
          [attr.data-qa-id]="'chat-message-time-title-' + message.text">
          @switch (userRole) {
            @case (UserRoles.CLIENT) {
              {{ message.date | platformTime }}
            }
            @case (UserRoles.GUIDE) {
              {{ { dateStart: message.date, dateEnd: message.date } | dateRange: "h:mmaaaaa'm'" : '' | async }}
            }
          }
        </small>
      </div>
      <div #container (click)="messageClicksHandler($event)" [class.no-pre-wrap]="containText">
        <ng-container *ngIf="isSenderABot()">
          <ng-container *ngIf="expiredTag">
            <div>
              {{ expiredMessageParts[0] }}
              <a [routerLink]="'/client/programs/quiz/' + -sender.id" i18n="@@completeSurvey">complete survey</a>
              {{ expiredMessageParts[1] }}
            </div>
          </ng-container>
          <ng-container *ngIf="!expiredTag">
            <ng-template [ngIf]="!message.type || message.type === MessageTypes.MESSAGE" [ngIfElse]="typedMessage">
              <dd [innerHTML]="message.text | safeHtml"></dd>
            </ng-template>
            <ng-template #typedMessage>
              <div [formGroup]="form">
                <app-module-quiz
                  formControlName="questions"
                  [isCorrectDisplay]="false"
                  [editableAnswers]="true"
                  [displayKey]="false"></app-module-quiz>
                <button pui-button data-submit="true">
                  <ng-template i18n="@@submit" [ngIf]="!editMode" [ngIfElse]="edit">Submit</ng-template>
                  <ng-template i18n="@@edit" #edit>Edit</ng-template>
                </button>
              </div>
            </ng-template>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!isSenderABot()">
          <div
            [innerHTML]="message.text | safeHtml"
            class="fs-exclude"
            [attr.data-qa-id]="'chat-message-content-text-' + message.text"></div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
