import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { PuiDrawerConfig, PuiDrawerRef, PuiDrawerService } from '@awarenow/profi-ui-core';
import { map, tap } from 'rxjs/operators';
import { SimpleSession } from '@app/shared/interfaces/session';
import {
  CancelData,
  CancelResult
} from '@app/screens/guide/guide-sessions/components/drawers/guide-cancel-drawer/guide-cancel-drawer.types';
import { GuideCancelDrawerComponent } from '@app/screens/guide/guide-sessions/components/drawers/guide-cancel-drawer/guide-cancel-drawer.component';
import { NotificationsService } from '@awarenow/profi-ui-core';

export const DRAWER_CONFIG: PuiDrawerConfig = {
  position: 'right',
  maxWidth: '600px'
};

@Injectable({ providedIn: 'root' })
export class GuideCancelDrawerService {
  constructor(private readonly drawerService: PuiDrawerService, private readonly notifications: NotificationsService) {}

  openCancelConformation$(session: SimpleSession, cancelAllRecurring = true): Observable<boolean> {
    const data: CancelData = { session: session, cancelAllRecurring };
    const drawerRef: PuiDrawerRef<CancelResult, CancelData> = this.drawerService.open(
      GuideCancelDrawerComponent,
      DRAWER_CONFIG,
      data
    );
    return drawerRef.afterClosed$.pipe(
      map(cancel => cancel?.result),
      tap(result => {
        if (result) {
          this.notifications.success(`${session.name} is canceled`);
        }
      })
    );
  }
}
