import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { LocaleService } from '@app/core/locale/locale.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { IsoDayTime, WorkingHours } from '@app/screens/guide/guide-sessions/types';
import { SimpleTimeGenerator } from '@app/screens/guide/guide-sessions/utils/time-generators';
import { PUI_IS_MOBILE, PuiDestroyService } from '@awarenow/profi-ui-core';
import { setEndHours } from '@app/modules/session-forms/forms/working-time-form/components/working-time-slot/utils';

type WeekDay = {
  dayId: string;
  name: string;
  value: string;
};

const weekDays: WeekDay[] = [
  { dayId: '7', name: `Sunday`, value: 'sunday' },
  { dayId: '1', name: `Monday`, value: 'monday' },
  { dayId: '2', name: `Tuesday`, value: 'tuesday' },
  { dayId: '3', name: `Wednesday`, value: 'wednesday' },
  { dayId: '4', name: `Thursday`, value: 'thursday' },
  { dayId: '5', name: `Friday`, value: 'friday' },
  { dayId: '6', name: `Saturday`, value: 'saturday' }
];

interface WorkingTimeFormItem {
  dayId: string;
  isActive?: boolean;
  start: string;
  end: string;
}

export type WorkingTimeForm = WorkingTimeFormItem[];

export enum WorkingTimes {
  START_DAY = '00:00',
  START_WORKING_TIME = '09:00',
  END_WORKING_TIME = '18:00',
  END_DAY = '23:59'
}

@Component({
  selector: 'app-working-time-form',
  templateUrl: './working-time-form.component.html',
  styleUrls: ['./working-time-form.component.scss', './working-time-form-mobile.component.scss'],
  providers: [
    PuiDestroyService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => WorkingTimeFormComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkingTimeFormComponent implements AfterContentInit, ControlValueAccessor {
  weekDays = weekDays;
  fullDayHours!: IsoDayTime[];
  startHours!: IsoDayTime[];
  endHours: { [key: string]: IsoDayTime[] } = {};

  private defaultFormValue = weekDays.reduce((acc, item) => {
    const isWorkDay = ![6, 7].includes(+item.dayId);
    return {
      ...acc,
      [item.dayId]: this.formBuilder.group({
        isActive: [isWorkDay],
        dayId: [item.dayId],
        hours: this.formBuilder.array([
          this.formBuilder.group({
            start: [WorkingTimes.START_WORKING_TIME],
            end: [WorkingTimes.END_WORKING_TIME],
            timezone: [DateTime.local().zoneName]
          })
        ])
      })
    };
  }, {});

  form = this.formBuilder.group(this.defaultFormValue);

  get applyAllDaysPositionId(): string | null {
    const [positionDay, ...rest] = weekDays.filter(weekDay => this.form.get(weekDay.dayId)?.get('isActive')?.value);

    if (rest.length < 1) {
      return null;
    }

    return positionDay.dayId;
  }

  constructor(
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>,
    private readonly localeService: LocaleService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly runtimeConfigService: RuntimeConfigService,
    private readonly cdr: ChangeDetectorRef,
    @Inject(PUI_IS_MOBILE) readonly isMobile$: Observable<boolean>
  ) {
    this.fullDayHours = [
      ...new SimpleTimeGenerator(this.localeService)
        .generate(this.runtimeConfigService.get('guideCalendarWorkingTimeInterval'))
        .slice(0, -1),
      { iso: WorkingTimes.END_DAY, label: '11:59pm' }
    ];

    this.startHours = this.fullDayHours.slice(0, -1);

    this.endHours = weekDays.reduce((acc, item) => {
      return {
        ...acc,
        [item.dayId]: this.fullDayHours.slice(1)
      };
    }, {});
  }

  ngAfterContentInit(): void {
    this.setInitialFormValue();
  }

  onTouched: () => void = () => {};

  writeValue(value: {
    [key: string]: {
      start: string;
      end: string;
      timezone: string;
    }[];
  }): void {
    if (value) {
      const formValue = Object.keys(value).reduce((acc, key: string) => {
        let values = value[key];

        values = values.map(val => {
          return {
            ...val,
            end:
              val.end === WorkingTimes.START_DAY && val.start !== WorkingTimes.START_DAY
                ? WorkingTimes.END_DAY
                : val.end
          };
        });

        const interval = values.length === 1 && values[0];
        const control = this.form.get(key) as UntypedFormGroup;
        const hoursControl = control.get('hours') as UntypedFormArray;

        if (hoursControl.controls.length !== values.length) {
          for (let i = 1; i < values.length; i++) {
            hoursControl.push(
              this.formBuilder.group({
                start: '',
                end: '',
                timezone: DateTime.local().zoneName
              })
            );
          }
        }

        return {
          ...acc,
          [key]: {
            isActive: interval
              ? !(interval.start === WorkingTimes.START_DAY && interval.end === WorkingTimes.START_DAY)
              : true,
            dayId: key,
            hours: values
          }
        };
      }, {});

      this.form.setValue(formValue, { emitEvent: false });
    }
  }

  registerOnChange(fn: (value: WorkingTimeForm) => void): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      const workingDaysHours = Object.values(value).reduce((acc: {}, item: WeekDay) => {
        const { hours } = value[item.dayId];

        const removedUnsettedValues = hours.filter(
          (hour: { start: string; end: string }) => hour.start !== '' && hour.end !== ''
        );

        if (!value[item.dayId].isActive) {
          return {
            ...acc,
            [item.dayId]: [{ ...removedUnsettedValues[0], start: WorkingTimes.START_DAY, end: WorkingTimes.START_DAY }]
          };
        }

        return { ...acc, [item.dayId]: removedUnsettedValues };
      }, {}) as WorkingTimeForm;

      fn(workingDaysHours);
    });
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setInitialFormValue(): void {
    this.form.patchValue(this.defaultFormValue);
  }

  applyToAll(formGroup: UntypedFormGroup): void {
    const value = formGroup.value as WorkingHours;

    this.form.patchValue({
      ...weekDays.reduce((acc, item) => {
        return {
          ...acc,
          [item.dayId]: {
            dayId: item.dayId,
            hours: [
              {
                start: value.start,
                end: value.end
              }
            ]
          }
        };
      }, {})
    });
  }

  private addHours(time: string, plus: number): string {
    return DateTime.fromFormat(time, 'HH:mm').plus({ hour: plus }).toFormat('HH:mm');
  }

  addControl(itemDayId: string) {
    const control = this.form.get([itemDayId, 'hours']) as UntypedFormArray;
    const prevEndValue = control.value[control.value.length - 1].end;
    const lastSlotInterval = '23:00';

    if (prevEndValue > lastSlotInterval) {
      return;
    }

    const start = prevEndValue;
    let end = this.addHours(prevEndValue, 1);

    if (end === WorkingTimes.START_DAY) {
      end = WorkingTimes.END_DAY;
    }

    if (start === lastSlotInterval) {
      end = WorkingTimes.END_DAY;
    }

    control.push(
      this.formBuilder.group({
        start,
        end,
        timezone: DateTime.local().zoneName
      })
    );
  }

  removeControl(itemDayId: string, index: number) {
    const control = this.form.get([itemDayId, 'hours']) as UntypedFormArray;
    control.removeAt(index);

    this.cdr.markForCheck();
  }

  changeActivity(dayId: string) {
    const control = this.form.get([dayId, 'hours', '0']);

    if (!control) {
      return;
    }

    const { start, end } = control.value;

    if (start === WorkingTimes.START_DAY && end === WorkingTimes.START_DAY) {
      control?.patchValue({
        start: WorkingTimes.START_WORKING_TIME,
        end: WorkingTimes.END_WORKING_TIME
      });
    }

    this.cdr.markForCheck();
  }

  private setEndHours(startHour: string, endHour: string): IsoDayTime[] {
    return setEndHours(startHour, endHour, this.fullDayHours);
  }
}
