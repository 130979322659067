import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ValidateOnRouteChanged } from '@app/screens/guide/guide-shared/interfaces/validate-on-route-changed';
import {
  RedirectAfterSaveActions,
  canRedirectAfterSave
} from '@app/screens/guide/guide-shared/can-redirect-after-save';
import { PuiDialogService } from '@awarenow/profi-ui-core';
import { GuideSaveChangesDialogComponent } from '@app/screens/guide/guide-shared/components/guide-save-changes-dialog/guide-save-changes-dialog.component';
import { SaveOnRouteChanged } from '@app/screens/guide/guide-shared/interfaces';
import { AuthService } from '@app/core/auth/services';
import { GuideChangesForNewUsersDialogComponent } from '@app/screens/guide/guide-shared/components/guide-changes-for-new-users-dialog/guide-changes-for-new-users-dialog.component';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { SurveyFormService } from '../services/survey-form.service';
import { filter, take } from 'rxjs/operators';

@Injectable()
export class CanLeaveWithValidationGuard {
  constructor(
    private readonly auth: AuthService,
    private readonly dialog: PuiDialogService,
    private readonly notifications: NotificationsService,
    private readonly surveyFormService: SurveyFormService
  ) {}

  async canDeactivate(
    component: ValidateOnRouteChanged & SaveOnRouteChanged,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Promise<boolean> {
    const result = await canRedirectAfterSave(
      this.dialog,
      this.notifications,
      component,
      nextState,
      this.auth.isPlatformAdmin() ? GuideChangesForNewUsersDialogComponent : GuideSaveChangesDialogComponent
    ).toPromise();

    if (result === RedirectAfterSaveActions.CANCEL) {
      return false;
    }

    if (result === RedirectAfterSaveActions.DISCARD) {
      return true;
    }

    const isFormValid = component.isFormValid;

    if (isFormValid) {
      // wait while all requests completed
      await this.surveyFormService.loading$
        .pipe(
          filter(loading => !loading),
          take(1)
        )
        .toPromise();

      return true;
    } else {
      component.validateForm();
      return false;
    }
  }
}
