import { NotificationsService } from '@awarenow/profi-ui-core';
import { BehaviorSubject, combineLatest, forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { InternalEvents } from '@app/core/analytics/types';
import config from '@app/core/config/config';
import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { IInvitation } from '@app/screens/guide/guide-programs/components/invite-clients-modal/invite-clients-modal.component';
import {
  ClientInvitation,
  ClientInvitationTypes,
  ClientUnEnrollType,
  GuideClientProgram,
  ProgramClientProgress,
  ProgramClientProgressResponse
} from '@app/screens/guide/guide-programs/types';
import { buildProgramClientProgress } from '@app/screens/guide/guide-programs/utils/client-progress-builder';
import { IUser } from '@app/shared/interfaces/user';

export enum ModuleStatuses {
  ALLOWED = 'allowed',
  RESTRICTED = 'restricted',
  HARD_RESTRICTED = 'hard_restricted',
  SEEN = 'seen'
}

export type ProgramClientsResponse = (CRMClient & { moduleStatus: ModuleStatuses })[];

@Injectable()
export class GuideProgramClientsService implements OnDestroy {
  private readonly ENDPOINT = `${config.apiPath}/user/guide/programs`;
  clients$ = new BehaviorSubject<GuideClientProgram[]>([]);
  deletedClients$ = new BehaviorSubject<GuideClientProgram[]>([]);
  invitedClients$ = new BehaviorSubject<GuideClientProgram[]>([]);
  invitations$ = new BehaviorSubject<ClientInvitation[]>([]);
  private clientsProgress: Record<string, ProgramClientProgress> = {};

  get allClients$(): Observable<
    [GuideClientProgram[], GuideClientProgram[], GuideClientProgram[], ClientInvitation[]]
  > {
    return combineLatest([this.clients$, this.deletedClients$, this.invitedClients$, this.invitations$]);
  }

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly http: HttpClient,
    private readonly notifications: NotificationsService
  ) {}

  ngOnDestroy(): void {
    this.clients$.complete();
  }

  refresh(programId: number): void {
    this.refresh$(programId).subscribe();
  }

  refresh$(
    programId: number
  ): Observable<[[GuideClientProgram[], GuideClientProgram[], GuideClientProgram[]], ClientInvitation[]]> {
    this.clientsProgress = {};

    return forkJoin([
      this.http.get<{ customers: IUser[] }>(`${this.ENDPOINT}/${programId}/customers`).pipe(
        map(({ customers }: { customers: IUser[] }) => this.mapCustomers(customers)),
        catchError(error => {
          this.notifications.error(`Failed to load program clients`);
          return throwError(error);
        })
      ),
      this.http.get<ClientInvitation[]>(`${this.ENDPOINT}/${programId}/invitations`).pipe(
        catchError(error => {
          this.notifications.error(`Failed to load client invitations`);
          return throwError(error);
        })
      )
    ]).pipe(
      tap(([[clients, deleted, invitedClients], invitations]) => {
        this.clients$.next(clients);
        this.deletedClients$.next(deleted);
        this.invitedClients$.next(invitedClients);
        this.invitations$.next(invitations);
      })
    );
  }

  completeClientModule$(clientId: number, programId: number, moduleId: number): Observable<void> {
    return this.http.post<void>(`${this.ENDPOINT}/${programId}/customers/${clientId}/modules/${moduleId}/complete`, {});
  }

  getClientProgress$(programId: number, clientId: number): Observable<ProgramClientProgress> {
    clientId = Number(clientId);
    if (Number.isNaN(clientId)) {
      return throwError(new Error(`Invalid clientId: ${clientId}`));
    }

    // Disable because can't re-fetch client progress.
    // if (this.clientsProgress[clientId]) {
    //   return of(this.clientsProgress[clientId]);
    // }

    return this.http
      .get<ProgramClientProgressResponse>(`${this.ENDPOINT}/${programId}/customers/${clientId}/progress`)
      .pipe(
        map((progress: ProgramClientProgressResponse) => buildProgramClientProgress(progress)),
        tap((progress: ProgramClientProgress) => (this.clientsProgress[clientId] = progress)),
        catchError(error => {
          this.notifications.error(`Failed to load client progress`);
          return throwError(error);
        })
      );
  }

  sendInvitations$(
    programId: number,
    invitations: IInvitation[],
    type: ClientInvitationTypes
  ): Observable<ClientInvitation[]> {
    const invitationsWithoutClientIdForContacts = invitations.map(invitation => {
      return invitation.type === 'guideContact' ? { ...invitation, userId: undefined } : invitation;
    });

    return this.http
      .post<ClientInvitation[]>(`${this.ENDPOINT}/${programId}/invitations`, {
        invitations: invitationsWithoutClientIdForContacts,
        type
      })
      .pipe(
        tap(() => this.analyticsService.event(InternalEvents.PROGRAM_INVITED_CLIENT, {})),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = null;

          if (error.error.errors) {
            [errorMessage] = Object.values(error.error.errors).map(({ msg }) => msg);
          }

          this.notifications.error(`Failed to send invitations`, errorMessage);
          return throwError(error);
        })
      );
  }

  getBySessionTemplate$(programId: number, sessionTemplateId: number): Observable<ProgramClientsResponse> {
    const url = `${this.ENDPOINT}/${programId}/sessionTemplates/${sessionTemplateId}/clients`;
    const options = { params: new HttpParams() };
    return this.http.get<ProgramClientsResponse>(url, options).pipe(
      catchError(error => {
        this.notifications.error(`Cant load clients`);
        return throwError(error);
      })
    );
  }

  deleteInvitation$(programId: number, id: number): Observable<ClientInvitation[]> {
    return this.http.delete<ClientInvitation[]>(`${this.ENDPOINT}/${programId}/invitations/${id}`).pipe(
      catchError(error => {
        this.notifications.error(`Failed to load client invitations`);
        return throwError(error);
      })
    );
  }

  unEnroll(programId: number, userId: number, unEnrollType: ClientUnEnrollType): void {
    this.http
      .delete(`${this.ENDPOINT}/${programId}/customers/${userId}/${unEnrollType}`)
      .pipe(
        catchError(error => {
          this.notifications.error(`Failed to delete client`);
          return throwError(error);
        })
      )
      .subscribe(() => {
        const client = this.clients$.value.find(i => +i.id === +userId);

        if (client) {
          this.refreshClientsSubject(this.clients$, userId);

          if (unEnrollType !== ClientUnEnrollType.FullDelete) {
            client.status =
              unEnrollType === ClientUnEnrollType.RestrictFutureModules ? 'restrict_future_modules' : 'expelled';

            const deleted = [...this.deletedClients$.value, client];
            this.deletedClients$.next(deleted);
          }
        } else {
          const invitedClient = this.invitedClients$.value.find(i => +i.id === +userId);

          if (invitedClient) {
            this.refreshClientsSubject(this.invitedClients$, userId);
          } else {
            this.refreshClientsSubject(this.deletedClients$, userId);
          }
        }
      });
  }

  private mapCustomers(customers: IUser[]): [GuideClientProgram[], GuideClientProgram[], GuideClientProgram[]] {
    return customers
      .map(customer => ({ ...customer, contacts: { email: customer.email } }))
      .reduce(
        (mappedCustomers, customer) => {
          let index = -1;

          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
          if ((<any>customer).active) {
            index = 0;
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
          } else if ((<any>customer).deleted) {
            index = 1;
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
          } else if ((<any>customer).invited || (<any>customer).inactive) {
            index = 2;
          }

          if (index !== -1) {
            // @ts-expect-error TS7006
            mappedCustomers[index].push(new GuideClientProgram(customer));
          }

          return mappedCustomers;
        },
        [[], [], []]
      );
  }

  private refreshClientsSubject(subject: BehaviorSubject<GuideClientProgram[]>, clientId: string | number): void {
    const valueFromSubject = subject.value;
    const filteredValue = valueFromSubject.filter(client => +client.id !== +clientId);
    subject.next(filteredValue);
  }
}
