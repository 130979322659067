import { Directive, inject, output } from '@angular/core';
import { PuiDialogService } from '@awarenow/profi-ui-core';
import { ClientsSelectModalComponent } from '@app/features/clients-select-feature/components/clients-select-modal/clients-select-modal.component';
import { firstValueFrom } from 'rxjs';

@Directive({ selector: '[clientsSelect]', standalone: true, exportAs: 'ClientsSelect', providers: [PuiDialogService] })
export class ClientsSelectFeatureDirective {
  private dialog = inject(PuiDialogService);

  onClientsSelected = output<any>();

  async open(clients: any[]) {
    const dialogRef = this.dialog.open(ClientsSelectModalComponent, {
      data: {
        clients
      }
    });

    const result = await firstValueFrom(dialogRef.afterClosed$);

    this.onClientsSelected.emit(result);
  }
}
