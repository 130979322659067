// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BillingService } from '@app/modules/billing/services';
import { UntypedFormGroup } from '@angular/forms';
import { AbsComponentWithBilling } from '@app/modules/billing/abs/abs-component-with-billing';
import { BrandingService } from '@app/core/branding/branding.service';
import { IBillingInfo } from '@app/modules/billing/interfaces';

@Component({
  selector: 'app-billing-form-modal',
  templateUrl: './billing-form.modal.html',
  styleUrls: ['./billing-form.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class BillingFormModal extends AbsComponentWithBilling {
  // @ts-expect-error TS2564
  billingForm: UntypedFormGroup;

  @Output()
  billingDataChange: EventEmitter<IBillingInfo> = new EventEmitter<IBillingInfo>();

  constructor(
    readonly _billing: BillingService,
    readonly _cdr: ChangeDetectorRef,
    readonly _configProvider: BrandingService,
    private _modal: NgbActiveModal
  ) {
    super(_billing, _configProvider, _cdr);
  }

  cancel(): void {
    this._modal.close();
  }

  handleSubmit(data: IBillingInfo): void {
    this.billingDataChange.emit(data);
  }
}
