import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CLIENT_STRIPE_CREDIT_CARDS_ENDPOINT } from '@app/shared/constants/endpoints';
import { ICard } from '@app/shared/interfaces/card';
import { PaymentGateways } from '@app/shared/enums/payment-gateways';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

@Injectable()
export class ClientCardsService {
  private title = `Server error`;

  constructor(
    private _http: HttpClient,
    private _notifier: NotificationsService,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {}

  load$(): Observable<ICard[]> {
    const uri = this.getEndpointUri();
    if (!uri) {
      return of();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.get<any>(uri).pipe(
      catchError(err => {
        this._notifier.error(this.title, err.error.msg);
        return throwError(err);
      }),
      map(data => {
        if (!data.cards) {
          return [];
        }
        // @ts-expect-error TS7006
        return data.cards.map(card => ({
          id: card.id,
          isDefault: card.id === data.defaultCard,
          type: card.brand.toLowerCase(),
          number: card.last4,
          exp_year: card.exp_year,
          exp_month: card.exp_month
        }));
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  select$(cardId: number): Observable<any> {
    const uri = this.getEndpointUri();
    if (!uri) {
      return of();
    }

    return this._http.post(`${uri}/set-default/`, { cardId }).pipe(
      catchError(err => {
        this._notifier.error(this.title, err.error.msg);
        return throwError(err);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private getEndpointUri() {
    let uri = null;
    switch (this._runtimeConfigService.get('paymentGateway')) {
      case PaymentGateways.STRIPE:
        uri = CLIENT_STRIPE_CREDIT_CARDS_ENDPOINT;
        break;
    }

    return uri;
  }
}
