import { NotificationsService } from '@awarenow/profi-ui-core';
import { DateTime } from 'luxon';
import { EMPTY, Observable } from 'rxjs';
import { catchError, exhaustMap, filter, pluck, switchMap, tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/auth/services';
import { PublicProfileInfo } from '@app/screens/guide/guide-profile/services/guide-profile.service';
import { GUIDE_PROFILE } from '@app/shared/constants/endpoints';
import { UserRoles } from '@app/shared/enums/user-roles';
import { ComponentStore } from '@ngrx/component-store';
import { TimezoneProvider } from '@libs/services/timezone/timezone';

export type TimezoneValue = string;

export interface UserTimezoneState {
  timezone: TimezoneValue | undefined;
}

const initialState = {
  timezone: undefined
};

@Injectable({
  providedIn: 'root'
})
export class UserTimezoneStore extends ComponentStore<UserTimezoneState> {
  readonly timezone$ = this.select<TimezoneValue>(state => state.timezone as TimezoneValue).pipe(
    filter(Boolean)
  ) as Observable<TimezoneValue>;

  // Don't use it because it doesn't show the correct offset for timezones
  // which don't have a difference with UTC now,
  // but will have after Winter/Summer time chages
  readonly timezoneOffset$ = this.select(this.timezone$, (timezone: string) => {
    const offset = DateTime.local().setZone(timezone).offsetNameShort;

    return offset;
  });

  readonly getTimezone = this.effect(trigger$ =>
    trigger$.pipe(
      exhaustMap(() => {
        if (this.authService.user.RoleId === UserRoles.GUIDE) {
          return this.getPublicProfile().pipe(
            tap({
              next: ({ timezone }) => this.patchState({ timezone: timezone || DateTime.local().zoneName }),
              error: error => {
                this.notificationsService.error('System error', 'Error while fetching profile details', null, error);
              }
            }),
            // 👇 Handle potential error within inner pipe.
            catchError(() => EMPTY)
          );
        } else {
          return this.timezoneProvider.pipe(
            tap(timezone => {
              return this.patchState({ timezone: timezone });
            })
          );
        }
      })
    )
  );

  readonly setTimezone = this.effect<TimezoneValue>(timezone$ =>
    timezone$.pipe(
      switchMap(timezone =>
        this.changeTimezone$(timezone).pipe(
          tap(
            () => {
              this.patchState({ timezone });
            },
            error =>
              this.notificationsService.error('System error', 'Error while updating profile details', null, error)
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  get timezone(): UserTimezoneState['timezone'] {
    return this.get().timezone;
  }

  constructor(
    private httpClient: HttpClient,
    private notificationsService: NotificationsService,
    private authService: AuthService,
    private readonly timezoneProvider: TimezoneProvider
  ) {
    super(initialState);

    this.getTimezone();
  }

  // TODO Write profile store and handle that changes
  private getPublicProfile(): Observable<PublicProfileInfo> {
    return this.httpClient.get<{ profile: PublicProfileInfo }>(`${GUIDE_PROFILE}/public`).pipe(pluck('profile'));
  }

  private changeTimezone$(timezone: string): Observable<void> {
    return this.httpClient.post<void>(`${GUIDE_PROFILE}/public`, {
      timezone
    });
  }
}
