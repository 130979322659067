import { NotificationsService } from '@awarenow/profi-ui-core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { SessionsApiService } from '@app/core/session/sessions-api.service';
import { SessionsService } from '@app/core/session/sessions.service';
import { GuideServiceScheduleApiService } from '@app/screens/guide/guide-sessions/services/events/guide-service-schedule-api.service';
import { GuideSessionsService } from '@app/screens/guide/guide-sessions/services/events/guide-sessions.service';
import { GUIDE_GUESTS_ENDPOINT } from '@app/shared/constants/endpoints';
import { isOfferOrPending, SessionStatuses } from '@app/shared/enums/session-statuses';
import { GroupSession, SimpleSession } from '@app/shared/interfaces/session';
import { CancelationType } from '@app/screens/guide/guide-sessions/components/drawers/cancel-session-event/types';

export interface Guest {
  id: number;
  email: string;
}

@Injectable()
export class CancelEventService implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private readonly sessions: SessionsService,
    private readonly guideSessions: GuideSessionsService,
    private readonly api: SessionsApiService,
    private readonly notificationsService: NotificationsService,
    private readonly guideServiceScheduleApiService: GuideServiceScheduleApiService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  cancelClientSubscribe$(
    session: SimpleSession,
    reasonMessage: string,
    cancelAllRecurring: boolean
  ): Observable<Object> {
    if (session.status !== SessionStatuses.PENDING_APPROVEMENT && isOfferOrPending(session.status)) {
      return this.api.declineSessionOffer$(session.id, reasonMessage, cancelAllRecurring);
    }
    return this.api.refuseSession$(session.id, reasonMessage, cancelAllRecurring);
  }

  cancelEvent$(
    session: GroupSession,
    message: string,
    cancelAllRecurring = true,
    cancellationType: CancelationType
  ): Observable<unknown> {
    if (cancellationType === CancelationType.FOR_PARTICIPANT) {
      const sessionOffer = session?.sessions![0];
      return this.sessions
        .refuseSession$(
          sessionOffer.id,
          message,
          session.collectionType,
          sessionOffer?.clientId,
          session,
          cancelAllRecurring
        )
        .pipe(tap(() => this.notificationsService.success(this.getNotifyMessage(session.sessions![0]))));
    }
    return this.guideServiceScheduleApiService
      .cancelEvent$(
        session.eventId,
        {
          type: 'cancel',
          cancelAllRecurring
        },
        message
      )
      .pipe(tap(() => this.notificationsService.success(this.getNotifyMessage(session.sessions![0]))));
  }

  deleteGuest$(
    session: GroupSession,
    guest: Guest,
    data: { reason: string; cancelAllRecurring: boolean }
  ): Observable<Object> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        eventId: session.eventId,
        type: 'cancel',
        ...data
      }
    };

    return this.http.delete(`${GUIDE_GUESTS_ENDPOINT}/${guest.id}`, options).pipe(
      tap(() => {
        this.notificationsService.success(`Guest is deleted`);
      })
    );
  }

  private getNotifyMessage(session: SimpleSession, isRecurring = false): string {
    const isRecurringResult =
      Array.isArray(session.recurringSessionsDetails) && session.recurringSessionsDetails?.length > 1 && isRecurring;
    if (isOfferOrPending(session.status)) {
      if (isRecurringResult) {
        return `The request for ${session.recurringSessionsDetails?.length} x ${session.name} is declined`;
      } else {
        return `The request for ${session.name} is declined`;
      }
    } else {
      if (isRecurringResult) {
        return `The recurring ${session.recurringSessionsDetails?.length} x ${session.name} are canceled`;
      } else {
        return `${session.name} is canceled`;
      }
    }
  }
}
