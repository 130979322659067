import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormService } from '@app/core';
import { ClientUnEnrollType, GuideClientProgram } from '../../types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IDeleteModalResponse {
  deleteClient: boolean;
  programId: number;
  unEnrollType: ClientUnEnrollType;
}

@Component({
  selector: 'app-delete-client-modal',
  templateUrl: './delete-client-modal.component.html',
  styleUrls: [
    '../../../../../modules/guide-service-editor/common-styles/forms.scss',
    './delete-client-modal.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteClientModalComponent {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _client: GuideClientProgram;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isExpulsion: boolean;

  @Input()
  set client(client: GuideClientProgram) {
    this._client = client;

    if (client.status === 'restrict_future_modules' || client.status === 'expelled') {
      // @ts-expect-error TS2531
      this.form.get('blockAccess').setValue(true);
      // @ts-expect-error TS2531
      this.form.get('blockAccess').disable();
    }
  }

  @Input()
  // @ts-expect-error TS2564
  programName: string;

  @Input()
  set isExpulsion(value: boolean) {
    this._isExpulsion = value;

    if (this.form && this._isExpulsion) {
      // @ts-expect-error TS2531
      this.form.get('blockAccess').setValue(true);
      // @ts-expect-error TS2531
      this.form.get('blockAccess').disable();
    }
  }

  form: UntypedFormGroup;

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get client(): GuideClientProgram {
    return this._client;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get isExpulsion(): boolean {
    return this._isExpulsion;
  }

  constructor(
    private _modal: NgbActiveModal,
    private _formBuilder: UntypedFormBuilder,
    private _formService: FormService
  ) {
    this.form = _formBuilder.group({
      program: [0, [Validators.required]],
      blockAccess: [false, []]
    });
  }

  close(): void {
    this._modal.close({ deleteClient: false } as IDeleteModalResponse);
  }

  unEnroll(): void {
    if (this._formService.markInvalidForm(this.form)) {
      return;
    }

    const { program, blockAccess } = this.form.getRawValue();
    let unEnrollType;

    if (this._isExpulsion) {
      unEnrollType = this._client.status === 'expelled' ? ClientUnEnrollType.FullDelete : ClientUnEnrollType.EXPULSION;
    } else {
      unEnrollType = blockAccess ? ClientUnEnrollType.FullDelete : ClientUnEnrollType.RestrictFutureModules;
    }

    this._modal.close({
      deleteClient: true,
      programId: +program,
      unEnrollType
    } as IDeleteModalResponse);
  }
}
