import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { interval, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-horizontal-slider',
  templateUrl: './horizontal-slider.component.html',
  styleUrls: ['./horizontal-slider.component.scss']
})
export class HorizontalSliderComponent implements OnDestroy, AfterViewInit {
  private onDestroy$ = new Subject<void>();
  private stopAnimation$ = new Subject<void>();

  scrollLeft = 10000;

  @Input()
  contentHeight = 0;

  @Input()
  contentWidth = 0;

  @ViewChild('scroll', { static: true })
  // @ts-expect-error TS2564
  scroll: ElementRef;

  @ViewChild('wrap', { static: true })
  // @ts-expect-error TS2564
  wrap: ElementRef;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.stopAnimation$.next();
    this.stopAnimation$.complete();
  }

  ngAfterViewInit(): void {
    this.contentWidth = this.wrap.nativeElement.offsetWidth;
    this.contentHeight = this.wrap.nativeElement.offsetHeight;
    if (this.contentWidth < this.scroll.nativeElement.offsetWidth) {
      this.scrollLeft = 0;
      this.cdr.detectChanges();
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  scrollLeftAct() {
    this.stopAnimation$.next();
    const width = this.scroll.nativeElement.offsetWidth;
    const start = this.scroll.nativeElement.scrollLeft;
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.animation$().subscribe(i => {
      this.scroll.nativeElement.scrollTo(start - i * width, 0);
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  scrollRightAct() {
    this.stopAnimation$.next();
    const width = this.scroll.nativeElement.offsetWidth;
    const start = this.scroll.nativeElement.scrollLeft;
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.animation$().subscribe(i => {
      this.scroll.nativeElement.scrollTo(start + i * width, 0);
    });
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  resize() {
    this.contentWidth = 0;

    setTimeout(() => {
      this.contentWidth = this.wrap.nativeElement.offsetWidth;
      this.cdr.detectChanges();
    }, 0);
  }

  private animation$(time: number = 400): Observable<number> {
    // @ts-expect-error TS7006
    // eslint-disable-next-line id-length, @typescript-eslint/explicit-function-return-type
    const c = t => 1 - Math.sin(Math.acos(t));

    // @ts-expect-error TS7006
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    function easeInOutCirc(timeFraction) {
      if (timeFraction < 0.5) {
        return c(2 * timeFraction) / 2;
      } else {
        return (2 - c(2 * (1 - timeFraction))) / 2;
      }
    }
    const stop$ = new Subject<void>();
    let nextStop = false;
    return interval(30).pipe(
      map(i => [i, easeInOutCirc((i * 30) / time)]),
      map(([i, j]) => [i, isNaN(j) ? 1 : j]),
      map(([i, j]) => {
        if (nextStop) {
          stop$.next();
        }
        if (i * 30 >= time) {
          nextStop = true;
          return 1;
        } else {
          return j;
        }
      }),
      // tslint:disable-next-line
      takeUntil(stop$),
      takeUntil(this.stopAnimation$)
    );
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, id-length
  scrollListener(e) {
    this.scrollLeft = e.target.scrollLeft;
  }
}
