import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { PuiPaperModule } from '@awarenow/profi-ui-core';
import { PuiChipComponent } from '@libs/components/chip/pui-chip.component';
import { ClientsSelectStore } from '@app/components/clients-select/clients-select.store';

@Component({
  selector: 'clients-select-field',
  standalone: true,
  templateUrl: 'clients-select-field.component.html',
  imports: [AsyncPipe, PuiPaperModule, PuiChipComponent],
  styleUrl: 'clients-select-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientsSelectFieldComponent {
  protected store = inject(ClientsSelectStore);
}
