import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormService } from '@app/core/form/form.service';
import { CSVCell, CsvReader } from '@app/shared/utils/csv-reader';
import { IGuideContactDetails } from '../../types';
import { NotificationsService } from '@awarenow/profi-ui-core';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-add-contact-modal',
  templateUrl: './add-contact-modal.component.html',
  styleUrls: ['./add-contact-modal.component.scss']
})
export class AddContactModalComponent {
  private DEFAULT_MAX_LENGTH = 254;

  @Input() clientsNumberLeft = Infinity;

  contactsForm = this._formBuilder.group({
    contacts: this._formBuilder.array([this.createNewContactFormFields()])
  });

  get canRemoveContact(): boolean {
    return this.contactsFormArray.length > 1;
  }

  get contactsFormArray(): UntypedFormArray {
    return this.contactsForm.get('contacts') as UntypedFormArray;
  }

  get isDisabledAddClient(): boolean {
    return this.contactsFormArray.length >= this.clientsNumberLeft;
  }

  constructor(
    public modal: NgbActiveModal,
    private _formBuilder: UntypedFormBuilder,
    private _formService: FormService,
    private _notifications: NotificationsService
  ) {}

  addAnotherContact(contactDetails?: IGuideContactDetails): void {
    (this.contactsForm.get('contacts') as UntypedFormArray).push(this.createNewContactFormFields(contactDetails));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  importCSV(files: FileList) {
    if (!files.length) {
      return;
    }

    this.removeEmptyContacts();

    const file = files[0];

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    CsvReader.parse(file, true).subscribe(csv => {
      let validRowsCount = 0;

      [{ cells: csv.header }, ...csv.rows].forEach(row => {
        if (row?.cells) {
          const [emailCsv, firstNameCsv, lastNameCsv, phoneCsv] = row.cells;

          const contactDetails = this.parseCsvCells(firstNameCsv, lastNameCsv, emailCsv, phoneCsv);
          if (contactDetails && !this.isDisabledAddClient) {
            this.addAnotherContact(contactDetails);
            validRowsCount++;
          }
        }
      });

      if (!validRowsCount && csv.rows.length) {
        const title = `Invalid file format. Use "Email | First Name | Last name | Phone ..."`;
        this._notifications.error(title);
      }
    });
  }

  removeContactAt(index: number): void {
    (this.contactsForm.get('contacts') as UntypedFormArray).removeAt(index);
  }

  saveContacts(): void {
    if (this._formService.markInvalidForm(this.contactsForm)) {
      return;
    }

    this.modal.close(this.contactsForm.value.contacts);
  }

  private createNewContactFormFields(contactDetails?: IGuideContactDetails): UntypedFormGroup {
    const contactValues = {
      firstName: '',
      lastName: '',
      organization: '',
      email: '',
      phone: '',
      notes: '',
      ...(contactDetails || {})
    };

    return this._formBuilder.group({
      firstName: [contactValues.firstName, [Validators.required, Validators.maxLength(this.DEFAULT_MAX_LENGTH)]],
      lastName: [contactValues.lastName, [Validators.required, Validators.maxLength(this.DEFAULT_MAX_LENGTH)]],
      organization: [contactValues.organization, [Validators.maxLength(this.DEFAULT_MAX_LENGTH)]],
      email: [
        contactValues.email,
        [Validators.required, Validators.email, Validators.maxLength(this.DEFAULT_MAX_LENGTH)]
      ],
      phone: [contactValues.phone],
      notes: [contactValues.notes]
    });
  }

  private parseCsvCells(
    firstNameCsv: CSVCell,
    lastNameCsv: CSVCell,
    emailCsv: CSVCell,
    phoneCsv: CSVCell
  ): IGuideContactDetails | null {
    if (!firstNameCsv || !lastNameCsv || !emailCsv) {
      return null;
    }

    const firstName = firstNameCsv.value;
    const lastName = lastNameCsv.value;
    const email = emailCsv.value;
    const phone = phoneCsv ? phoneCsv.value : '';

    if (!firstName || !lastName || !email || !!Validators.email({ value: email } as AbstractControl)) {
      return null;
    }

    return { firstName, lastName, email, phone };
  }

  private removeEmptyContacts(): void {
    this.contactsFormArray.controls
      .reduce((toRemove, control, index) => {
        if (Object.values(control.value).every((value: string | null) => !value || !value.trim())) {
          // @ts-expect-error TS2345
          toRemove.push(index);
        }

        return toRemove;
      }, [])
      .forEach(index => this.removeContactAt(index));
  }
}
