import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { Injectable, OnDestroy } from '@angular/core';
import { GuideRescheduleDrawerComponent } from '@app/screens/guide/guide-sessions/components/drawers/guide-reschedule-drawer/guide-reschedule-drawer.component';
import {
  RescheduleData,
  RescheduleResult
} from '@app/screens/guide/guide-sessions/components/drawers/guide-reschedule-drawer/guide-reschedule-drawer.types';
import { SimpleSession } from '@app/shared/interfaces/session';
import { PuiDrawerConfig, PuiDrawerRef, PuiDrawerService } from '@awarenow/profi-ui-core';

export const DRAWER_CONFIG: PuiDrawerConfig = {
  position: 'right',
  maxWidth: '600px'
};

@Injectable({ providedIn: 'root' })
export class GuideRescheduleDrawerService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  constructor(private readonly drawerService: PuiDrawerService) {}

  openRescheduleConfirmation$(session: SimpleSession, hasBackButton = false): Observable<RescheduleResult | null> {
    const data: RescheduleData = {
      title: session.name || '',
      sessionTemplateId: session.templateId!,
      hostId: session.guideId,
      hasBackButton,
      sessionType: session.sessionType
    };

    const drawerRef: PuiDrawerRef<RescheduleResult, RescheduleData> = this.drawerService.open(
      GuideRescheduleDrawerComponent,
      DRAWER_CONFIG,
      data
    );
    return drawerRef.afterClosed$.pipe(
      tap(reschedule => !reschedule?.date && this.destroy$.next()),
      map(reschedule => reschedule),
      takeUntil(this.destroy$)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
