import { combineLatest, Observable, of } from 'rxjs';
import { filter, finalize, switchMap, take, takeUntil } from 'rxjs/operators';

import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AuthService } from '@app/core/auth/services/auth.service';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { FormService } from '@app/core/form/form.service';
import { RETURN_AFTER_SAVE_PARAM } from '@app/modules/new-service-button/components/service-selector/service-selector.component';
import { IPublicProgram } from '@app/modules/program/types';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { ADMIN_ROUTE_PATH } from '@app/routes-consts';
import { confirmChangesForNewUsersOnly } from '@app/screens/admin/decorators/confirm-changes-for-new-users-only';
import { ProgramContentPreviewModalComponent } from '@app/screens/guide/guide-programs/components/program-content-preview-modal/program-content-preview-modal.component';
import { ProgramFormBaseComponent } from '@app/screens/guide/guide-programs/components/program-forms/program-form-base.component';
import { ProgramModulesPreviewModalComponent } from '@app/screens/guide/guide-programs/components/program-modules-preview-modal/program-modules-preview-modal.component';
import { GuideProfileService } from '@app/screens/guide/guide-programs/services/guide-profile.service';
import { GuideProgramOptionsService } from '@app/screens/guide/guide-programs/services/guide-program-options.service';
import { GuideProgramStateService } from '@app/screens/guide/guide-programs/services/guide-program-state.service';
import { NewGuideProgramStateService } from '@app/screens/guide/guide-programs/services/new-guide-program-state.service';
import { ProgramCoauthorsService } from '@app/screens/guide/guide-programs/services/program-coauthors.service';
import { ProgramInstructorsServicesService } from '@app/screens/guide/guide-programs/services/program-instructors-services.service';
import {
  IProgramAuthor,
  IProgramContentPersistenceAttributes,
  ProgramAccessRoles,
  ProgramContent
} from '@app/screens/guide/guide-programs/types';
import { createProgramContentPreview, createProgramLandingPreview } from '@app/screens/guide/guide-programs/utils';
import { ScrollToFirstInvalidDirective } from '@app/shared/directives/scroll-to-first-invalid.directive';
import { ClientProgram } from '@app/shared/interfaces/programs/client-programs';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { normalizeFullName } from '@app/shared/utils/full-name';
import { PuiDestroyService, PuiDialogService, PuiDrawerService } from '@awarenow/profi-ui-core';
import { environment } from '@env/environment';
import { WindowManagerService } from '@libs/services/window-manager/window-manager.service';
import { NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-program-constructor',
  templateUrl: './program-constructor.component.html',
  styleUrls: [
    '../../../../../modules/guide-service-editor/common-styles/program.scss',
    './program-constructor.component.scss'
  ],
  providers: [
    NewGuideProgramStateService,
    GuideProgramStateService /* is required for NewGuideProgramStateService */,
    ProgramCoauthorsService,
    GuideProfileService,
    ProgramInstructorsServicesService,
    PuiDestroyService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgramConstructorComponent extends ProgramFormBaseComponent implements OnInit, OnDestroy {
  private readonly STEPS_COUNT = 2;

  private stepDoneActions = [() => this.updateContent(!this.auth.isPlatformAdmin()), () => this.updateModules()];

  @ViewChild(ScrollToFirstInvalidDirective, { static: false })
  invalidContentElementScroll: ScrollToFirstInvalidDirective | undefined;

  @ViewChild('stepNavigator', { static: false })
  stepNavigator: NgbNav | undefined;

  serviceRoute = this.auth.isPlatformAdmin()
    ? [ADMIN_ROUTE_PATH, 'default-services']
    : [environment.guideRoute, 'services'];

  canSaveChanges = true;
  currentStep = 1;
  lastStepOpened = 1;
  programStartDate: string | null = null;

  readonly ProgramAccessRoles = ProgramAccessRoles;

  get author(): IProgramAuthor {
    return {
      name: normalizeFullName(this.auth.user) || '',
      id: this.auth.isAuthorized ? this.auth.user.id : null,
      permissions: { canEditSettings: true }
    };
  }

  get authorId(): number {
    return this.auth.user.id;
  }

  get isTeamAdmin(): boolean {
    return this.workspaceService.isAdmin;
  }

  constructor(
    @Inject(GLOBAL_WINDOW) private readonly window: Window,
    analytics: AnalyticsService,
    auth: AuthService,
    drawer: PuiDrawerService,
    formBuilder: UntypedFormBuilder,
    private readonly formService: FormService,
    private readonly modal: NgbModal,
    private readonly profile: GuideProfileService,
    private readonly router: Router,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly windowManagerService: WindowManagerService,
    programCoauthors: ProgramCoauthorsService,
    programOptions: GuideProgramOptionsService,
    readonly dialog: PuiDialogService,
    readonly programState: NewGuideProgramStateService,
    workspaceService: WorkspacesService,
    private readonly destroy$: PuiDestroyService
  ) {
    super(programOptions, programCoauthors, formBuilder, analytics, auth, workspaceService, drawer, dialog);
  }

  ngOnInit(): void {
    this.programState.content$.pipe(takeUntil(this.destroy$)).subscribe(content => {
      this.setContentForm(content);
      this.updateProgramStartDate(content);
    });
    this.programState.modules$.pipe(takeUntil(this.destroy$)).subscribe(modules => this.setModulesForm(modules));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  beforeStepChange(activeTabId: number): void {
    if (activeTabId === 2) {
      if (!this.stepDoneActions[0]()) {
        this.currentStep = activeTabId;
        this.changeLastStep();
      }
    }
  }

  closeConstructor(): void {
    this.windowManagerService.postMessage('propose-service', { command: 'close' });
    if ((this.currentStep === 1 && this.lastStepOpened === 1) || this.stepDoneActions[this.currentStep - 1]()) {
      this.router.navigate(['/', ...this.serviceRoute]);
    }
  }

  setStep(step: number): void {
    this.currentStep = step;
  }

  // TODO: this method create for link that has similiar behavior as close button but in future can be refactored
  backToTemplates(): void {
    this.windowManagerService.postMessage('propose-service', { command: 'close' });
    this.closeConstructor();
  }

  nextStep(): void {
    this.currentStep += 1;
    this.changeLastStep();
    if (this.stepNavigator) {
      this.stepNavigator.select(this.currentStep);
    }
  }

  previewProgramContent(): void {
    if (this.validateContentForm()) {
      combineLatest([
        of(this.convertFormDataToProgramContent()),
        of(this.modulesFormToProgramModules(this.lastStepOpened === 1 ? [] : this.modulesForm.value.modules)),
        this.programOptions.options$,
        this.profile.getPublicProfile$()
      ])
        .pipe(takeUntil(this.destroy$))
        .subscribe(previewParts => this.showProgramContentPreview(createProgramLandingPreview(previewParts)));
    }
  }

  previewProgramModules(): void {
    if (this.validateModulesForm()) {
      combineLatest([
        of(this.convertFormDataToProgramContent()),
        of(this.modulesFormToProgramModules()),
        of(this.auth.user)
      ])
        .pipe(takeUntil(this.destroy$))
        .subscribe(previewParts => this.showProgramModulesPreview(createProgramContentPreview(previewParts)));
    }
  }

  saveChanges(): void {
    this.updateModules(true);
  }

  uploadImage(images: { coverImage: File; coverImageThumb: File }): void {
    this.programState
      .uploadCover$(images)
      .pipe(takeUntil(this.destroy$))
      .subscribe(url => {
        this.addCoverImages(url);
      });
  }

  removeCover(): void {
    this.programState
      .removeCover$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.addCoverImages({ coverImage: null, coverImageThumb: null }));
  }

  private changeLastStep(): void {
    if (this.lastStepOpened < this.STEPS_COUNT) {
      this.lastStepOpened = this.currentStep;
    }
  }

  private convertFormDataToProgramContent(): ProgramContent {
    const programId = this.contentForm.value.id;
    const programContent = this.contentFormToProgramContent();
    const cleanContent = ProgramContent.clean(programContent);
    return new ProgramContent().setValues({ ...cleanContent, id: programId });
  }

  private scrollToInvalidContentElement(): void {
    if (this.invalidContentElementScroll) {
      this.invalidContentElementScroll.scroll();
    }
  }

  private async showProgramContentPreview(preview: IPublicProgram): Promise<void> {
    try {
      const { componentInstance, result } = this.modal.open(ProgramContentPreviewModalComponent, {
        windowClass: 'full-screen-modal'
      });
      componentInstance.program = preview;
      await result;
    } catch (error: unknown) {
      console.warn(error);
    }
  }

  private async showProgramModulesPreview(preview: ClientProgram): Promise<void> {
    try {
      const { componentInstance, result } = this.modal.open(ProgramModulesPreviewModalComponent, {
        windowClass: 'full-screen-modal'
      });
      componentInstance.program = preview;
      await result;
    } catch (error: unknown) {
      console.warn(error);
    }
  }

  private updateContent(saveContent = true): boolean {
    const valid = this.validateContentForm();
    if (valid && saveContent) {
      this.programState.updateContent(this.convertFormDataToProgramContent());
    }
    return valid;
  }

  @confirmChangesForNewUsersOnly()
  private updateModules(waitForRedirect = false): boolean {
    const valid = this.validateModulesForm();
    if (valid) {
      this.canSaveChanges = false;
      const saveContent: Observable<IProgramContentPersistenceAttributes | {}> = this.auth.isPlatformAdmin()
        ? this.programState.createContentObs(this.convertFormDataToProgramContent())
        : of({});
      saveContent
        .pipe(
          switchMap(() => {
            return this.programState.updateModules(this.modulesFormToProgramModules()).pipe(
              filter(() => waitForRedirect),
              finalize(() => (this.canSaveChanges = true)),
              take(1)
            );
          }),
          takeUntil(this.destroy$)
        )
        .subscribe(() => {
          this.windowManagerService.postMessage('propose-service', {
            templateId: this.savedContent?.id,
            templateType: GuideServiceTypes.PROGRAM,
            command: 'close'
          });

          const { [RETURN_AFTER_SAVE_PARAM]: shouldReturnBack = false } = this.route.snapshot.queryParams;

          if (shouldReturnBack) {
            this.location.back();
          } else {
            this.router.navigate(['/', ...this.serviceRoute]);
          }
        });
    }
    return valid;
  }

  private updateProgramStartDate(programContent: ProgramContent): void {
    const { startType, startDate } = programContent;
    this.programStartDate = startType === 'program_release' ? startDate : null;
  }

  private validateContentForm(): boolean {
    const invalid = this.formService.markInvalidForm(this.contentForm);
    if (invalid) {
      this.scrollToInvalidContentElement();
    }
    return !invalid;
  }

  private validateModulesForm(): boolean {
    return !this.formService.markInvalidForm(this.modulesForm);
  }
}
