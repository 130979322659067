import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { BrandingService } from '@app/core/branding/branding.service';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@app/core/auth/services/auth.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['../../main-layout/header/header.component.scss', './header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  mainLogo = '';

  get isAuthorised(): boolean {
    return this._auth.isAuthorized;
  }

  constructor(private _auth: AuthService, private _brandingService: BrandingService) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnInit() {
    this._brandingService.mainLogo$.pipe(takeUntil(this.destroy$)).subscribe(({ logo }) => (this.mainLogo = logo));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
