import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { buildDirectChatLinkId } from '@app/shared/utils/direct-chat-user-id-builder';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BrandingService } from '@app/core/branding/branding.service';
import { FacebookService } from '@app/core/social-services/facebook.service';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { IServiceGuide } from '@app/shared/interfaces/services/service-guide';
import { TwitterService } from '@app/core/social-services/twitter.service';
import { environment } from '@env/environment';
import { SERVICE_VALUES } from '@app/modules/powered-by-link/powered-by-link/powered-by-link.component';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { IWorkspaceSettingsData } from '@app/modules/workspaces/types';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { GoogleMapsService } from '@app/core/google-maps/google-maps.service';
import { AuthService } from '@app/core/auth/services';
import { PublicAssignee } from '@app/modules/services/interfaces';
import { SessionType } from '@app/shared/enums/session-type';
import { PUI_IS_MOBILE } from '@awarenow/profi-ui-core';

@Component({
  selector: 'app-service-landing',
  templateUrl: './service-landing.component.html',
  styleUrls: ['./service-landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FacebookService, TwitterService],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'app-service-landing'
  }
})
export class ServiceLandingComponent implements OnInit, OnChanges, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _guide: IServiceGuide & { logo?: string | null };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _directChatId = '';

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _type: GuideServiceTypes;

  readonly carouselTile = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    slide: 1,
    velocity: 0,
    touch: true,
    loop: true
  };

  readonly env = environment;

  showPoweredBy = false;

  // @ts-expect-error TS2564
  typeOfLink: SERVICE_VALUES;

  // @ts-expect-error TS2564
  workspaceInfo: IWorkspaceSettingsData;

  @Input()
  assignees: PublicAssignee[] = [];

  @Input()
  workspaceId: number | null = null;

  @Input()
  // @ts-expect-error TS2564
  description: string;

  @Input()
  set guide(value: IServiceGuide & { logo?: string | null }) {
    this._guide = value;
    this._directChatId = value ? buildDirectChatLinkId(value) : '';
  }

  @Input()
  link!: string;

  @Input()
  templateId!: number;

  @Input()
  logo!: string;

  @Input()
  showBookButton = true;

  @Input()
  sessionType!: SessionType;

  @Input()
  // @ts-expect-error TS2564
  title: string;

  @Input()
  // @ts-expect-error TS2564
  coverImageUrl: string;

  @Input()
  isPackage = false;

  @Input()
  set type(value: GuideServiceTypes) {
    this._type = value;
    this.typeOfLink = ServiceLandingComponent.calculateTypeOfReferralLink(value);
  }

  @Input()
  testimonials: {
    text: string;
    clientInfo: string;
  }[] = [];

  @Input()
  faq: {
    question: string;
    answer: string;
  }[] = [];

  @Input() readonly = false;
  @Input() address = '';
  @Input() connectionType!: SessionConnectionTypes;
  @Input() hasSingleHost = false;

  @ContentChild('submitButtonTemplate', { static: true }) submitButtonTemplate?: TemplateRef<unknown>;

  get directChatId(): string {
    return this._directChatId;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get guide(): IServiceGuide & { logo?: string | null } {
    return this._guide;
  }

  get displayPoweredBy(): boolean {
    // @ts-expect-error TS2322
    return this._runtimeConfigService.get('displayPoweredBy');
  }

  get googleMapsLink(): string {
    if (!this.address) {
      // @ts-expect-error TS2322
      return;
    }

    return this._googleMapsService.generateGoogleMapsLink(this.address);
  }

  GuideServiceTypes = GuideServiceTypes;
  SessionConnectionTypes = SessionConnectionTypes;

  get showFaqAndTestimonials(): boolean {
    return !this._auth.isPlatformAdmin();
  }

  copyLinkText = `Copy link`;
  showAllHosts = false;

  constructor(
    private readonly _brandingService: BrandingService,
    private readonly _workspacesService: WorkspacesService,
    private readonly _changeDetectionRef: ChangeDetectorRef,
    private readonly _notifications: NotificationsService,
    private readonly _facebook: FacebookService,
    private readonly _twitter: TwitterService,
    private readonly _runtimeConfigService: RuntimeConfigService,
    private readonly _googleMapsService: GoogleMapsService,
    private _auth: AuthService,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(PLATFORM_ID) private platform: any,
    @Inject(GLOBAL_WINDOW) private window: Window,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PUI_IS_MOBILE) readonly isMobile$: Observable<boolean>
  ) {}

  toggleShowAllHosts() {
    this.showAllHosts = !this.showAllHosts;
  }

  private static calculateTypeOfReferralLink(serviceType: string): SERVICE_VALUES {
    switch (serviceType) {
      case GuideServiceTypes.SESSION:
        return 'individual_session_landing_page';
      case GuideServiceTypes.GROUP_SESSION:
        return 'group_session_landing_page';
      case GuideServiceTypes.PACKAGE:
        return 'package_landing_page';
      default:
        // @ts-expect-error TS2322
        return null;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.workspaceId || changes.assigneesIds) {
      if (this.workspaceId) {
        this.loadWorkspaceData(this.workspaceId);
      }
    }
  }

  ngOnInit(): void {
    this._brandingService.branding$.pipe(takeUntil(this.destroy$)).subscribe(branding => {
      if (branding && branding.isGuideBranding) {
        // @ts-expect-error TS2322
        this.showPoweredBy = this._runtimeConfigService.get('displayPoweredByForSoloCoaches');
      }
    });
  }

  loadWorkspaceData(workspaceId: number): void {
    if (workspaceId) {
      this._workspacesService
        .getInfo(workspaceId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((info: IWorkspaceSettingsData) => {
          this.workspaceInfo = info;
          if (isPlatformBrowser(this.platform) && info.branding.icon) {
            const faviconEl = this._document.getElementById('favicon');
            // @ts-expect-error TS2531
            faviconEl.setAttribute('href', info.branding.icon);
          }
          this._changeDetectionRef.detectChanges();
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onCopied(): void {
    const title = `Copied`;
    this._notifications.success(title);
  }

  onCopyFailed(): void {
    const title = `Copy failed`;
    this._notifications.error(title);
  }
}
