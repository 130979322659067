import { ClickOutsideModule } from 'ng-click-outside';
import { ClipboardModule } from 'ngx-clipboard';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertsModule } from '@app/modules/alerts/alerts.module';
import { GuideServiceEditorModule } from '@app/modules/guide-service-editor/guide-service-editor.module';
import { GuideServiceModule } from '@app/modules/guide-service/guide-service.module';
import { ProgramModule } from '@app/modules/program/program.module';
import { ScrollbarModule } from '@app/modules/scrollbar/scrollbar.module';
import { GroupServicesModule } from '@app/modules/service-scheduling/components/group-services/group-services.module';
import { TextEditorModule } from '@app/modules/text-editor/text-editor.module';
import { AvatarGroupModule } from '@app/modules/ui-kit/_base/avatar-group/avatar-group.module';
import { CoverImageModule } from '@app/modules/ui-kit/cover-image/cover-image.module';
import { FileCardModule } from '@app/modules/ui-kit/file-card/file-card.module';
import { FormModule } from '@app/modules/ui-kit/form/form.module';
import { ScheduleDateTimePickerModule } from '@app/modules/ui-kit/schedule';
import { UsersTypeaheadModule } from '@app/modules/ui-kit/users-typeahead';
import { UiClientSelectorMultipleModule } from '@app/modules/ui-kit/widgets/ui-client-selector-multiple';
import { UiClientSelectorSingleModule } from '@app/modules/ui-kit/widgets/ui-client-selector-single';
import { WorkspacesModule } from '@app/modules/workspaces/workspaces.module';
import { ProgramShowSettingsControlComponent } from '@app/screens/guide/guide-programs/components/program-settings/controls/program-show-settings-control/program-show-settings-control.component';
import { ProgramModulesInstructorPipe } from '@app/screens/guide/guide-programs/pipes/program-modules-instructor.pipe';
import { GuideProgramAnswersService } from '@app/screens/guide/guide-programs/services/guide-program-answers.service';
import { ProgramAssigneesService } from '@app/screens/guide/guide-programs/services/program-assignees.service';
import { GuideCancelDrawerModule } from '@app/screens/guide/guide-sessions/components/drawers/guide-cancel-drawer/guide-cancel-drawer.module';
import { GuideSharedModule } from '@app/screens/guide/guide-shared/guide-shared.module';
import { GuideServicesModule } from '@app/screens/guide/modules/widgets/guide-services/guide-services.module';
import { ProgramsModule } from '@app/screens/programs/programs.module';
import { SharedModule } from '@app/shared';
import { ToArrayModule } from '@app/shared/pipes/to-array/to-array.module';
import {
  PuiAlertModule,
  PuiAvatarModule,
  PuiButtonModule,
  PuiCardModule,
  PuiIconModule,
  PuiInputDateModule,
  PuiInputModule,
  PuiListModule,
  PuiOptionModule,
  PuiSelectModule,
  PuiToggleModule,
  PuiTooltipModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClientProgressComponent } from './components/client-progress/client-progress.component';
import { ClientsListComponent } from './components/clients-list/clients-list.component';
import { ContentModuleComponent } from './components/content-module/content-module.component';
import { DeactivateOfferModalComponent } from './components/deactivate-offer-modal/deactivate-offer-modal.component';
import { DeleteClientModalComponent } from './components/delete-client-modal/delete-client-modal.component';
import { InviteClientsModalComponent } from './components/invite-clients-modal/invite-clients-modal.component';
import { LoadModuleFormComponent } from './components/load-module-form/load-module-form.component';
import { ModuleActivationSelectComponent } from './components/module-activation-select/module-activation-select.component';
import { ModuleCompletionSelectComponent } from './components/module-completion-select/module-completion-select.component';
import { ModuleInstructorComponent } from './components/module-instructor/module-instructor.component';
import { ModuleServiceDateModalComponent } from './components/module-service-date-modal/module-service-date-modal.component';
import { ModuleServiceDateComponent } from './components/module-service-date/module-service-date.component';
import { ModuleServiceDetailsAdaptorPipe } from './components/module-service-details/module-service-details-adaptor.pipe';
import { ModuleServiceDetailsComponent } from './components/module-service-details/module-service-details.component';
import { ProgramAssigneesComponent } from './components/program-assignees/program-assignees.component';
import { ProgramClientsComponent } from './components/program-clients/program-clients.component';
import { ProgramConstructorComponent } from './components/program-constructor/program-constructor.component';
import { ProgramContentPreviewModalComponent } from './components/program-content-preview-modal/program-content-preview-modal.component';
import { ProgramContentComponent } from './components/program-content/program-content.component';
import { ProgramCoverCropModalComponent } from './components/program-cover-crop-modal/program-cover-crop-modal.component';
import { ProgramEditorComponent } from './components/program-editor/program-editor.component';
import { ProgramFormBaseComponent } from './components/program-forms/program-form-base.component';
import { ProgramModulesPreviewModalComponent } from './components/program-modules-preview-modal/program-modules-preview-modal.component';
import { ProgramModulesComponent } from './components/program-modules/program-modules.component';
import { ProgramPromotionComponent } from './components/program-promotion/program-promotion.component';
import { ProgramSettingsComponent } from './components/program-settings/program-settings.component';
import { QuizModuleComponent } from './components/quiz-module/quiz-module.component';
import { SessionModuleComponent } from './components/session-module/session-module.component';
import { LanguageTagsComponent } from '@app/screens/find-your-guide/components/find-your-guide-home/components/language-tags/language-tags.component';
import { CanLeaveEditorTabGuard } from './guards/can-leave-editor-tab.guard';
import { GuideProgramsRoutingModule } from './guide-programs-routing.module';
import { ModuleSessionDraftSessionTemplateTitlePipe } from './pipes/module-session-draft-session-template-title.pipe';
import { GuideProgramOptionsService } from './services/guide-program-options.service';
import { GuideProgramServerStoreService, PROGRAMS_STORE } from './services/guide-program-server-store.service';
import { TranslocoRootModule } from '@libs/transloco';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';
import { ProgramUpdateSubscriptionModule } from './features/update-subscription/program-update-subscription.module';
import { SharedModule as LibSharedModule } from '@libs/shared/shared.module';
import { RuntimeConfigModule } from '@app/core/runtime-config/runtime-config.module';
import { ClientsSelectComponent } from '@app/components/clients-select/clients-select.component';
import { ClientsSelectInputDirective } from '@app/components/clients-select/clients-select-input.directive';

@NgModule({
  imports: [
    AlertsModule,
    ClickOutsideModule,
    ClipboardModule,
    CommonModule,
    CoverImageModule,
    DragDropModule,
    FileCardModule,
    FormModule,
    FormsModule,
    GuideProgramsRoutingModule,
    GuideServiceEditorModule,
    ImageCropperComponent,
    NgScrollbarModule,
    NgbModule,
    ProgramModule,
    ReactiveFormsModule,
    ScheduleDateTimePickerModule,
    ScrollbarModule,
    SharedModule,
    UiClientSelectorMultipleModule,
    UiClientSelectorSingleModule,
    UsersTypeaheadModule,
    PuiAvatarModule,
    GuideServicesModule,
    WorkspacesModule,
    PuiTooltipModule,
    ProgramsModule,
    AvatarGroupModule,
    ToArrayModule,
    GuideServiceModule,
    PuiInputDateModule,
    PuiInputModule,
    PuiButtonModule,
    PuiIconModule,
    TextEditorModule,
    PuiTypographyModule,
    PuiListModule,
    PuiSelectModule,
    PuiOptionModule,
    GuideSharedModule,
    GroupServicesModule,
    GuideCancelDrawerModule,
    PuiToggleModule,
    PuiAlertModule,
    TranslocoRootModule,
    PlatformStyleModule,
    ProgramUpdateSubscriptionModule,
    PuiCardModule,
    LibSharedModule,
    RuntimeConfigModule,
    ClientsSelectComponent,
    ClientsSelectInputDirective
  ],
  declarations: [
    ClientProgressComponent,
    ClientsListComponent,
    ContentModuleComponent,
    DeactivateOfferModalComponent,
    DeleteClientModalComponent,
    InviteClientsModalComponent,
    ModuleActivationSelectComponent,
    ModuleCompletionSelectComponent,
    ModuleInstructorComponent,
    ModuleServiceDateComponent,
    ModuleServiceDateModalComponent,
    ModuleServiceDetailsComponent,
    ProgramConstructorComponent,
    ProgramContentComponent,
    ProgramContentPreviewModalComponent,
    ProgramCoverCropModalComponent,
    ProgramClientsComponent,
    ProgramEditorComponent,
    ProgramFormBaseComponent,
    ProgramModulesComponent,
    ProgramModulesPreviewModalComponent,
    ProgramPromotionComponent,
    ProgramSettingsComponent,
    QuizModuleComponent,
    SessionModuleComponent,
    LanguageTagsComponent,
    ProgramAssigneesComponent,
    ProgramModulesInstructorPipe,
    LoadModuleFormComponent,
    ModuleSessionDraftSessionTemplateTitlePipe,
    ModuleServiceDetailsAdaptorPipe,
    ProgramShowSettingsControlComponent
  ],
  exports: [ProgramConstructorComponent, ProgramEditorComponent, LanguageTagsComponent],
  providers: [
    CanLeaveEditorTabGuard,
    GuideProgramOptionsService,
    ProgramAssigneesService,
    GuideProgramAnswersService,
    {
      provide: PROGRAMS_STORE,
      useClass: GuideProgramServerStoreService
    }
  ]
})
export class GuideProgramsModule {}
