import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { Observable, throwError } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { catchError, finalize } from 'rxjs/operators';
import config from '@app/core/config/config';

@Injectable()
export class HelpMeFindAGuideService {
  constructor(private _http: HttpClient, private _notifications: NotificationsService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendMessage$(email: string, text: string): Observable<any> {
    return this._http.post(`${config.apiPath}/public/find-guide-help`, { email, text }).pipe(
      catchError(error => {
        const title = `Something wrong happened`;
        this._notifications.error(title);
        return throwError(error);
      })
    );
  }
}
