import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

@Injectable()
export class RedirectGuard {
  constructor(private _router: Router) {}

  canActivate(): UrlTree {
    return this._router.parseUrl('/auth?signUp=true');
  }
}
