import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-theming',
  templateUrl: './theming.component.html',
  styleUrls: ['./theming.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemingComponent {
  // @ts-expect-error TS2564
  formGroup: UntypedFormGroup;
  formArray: UntypedFormArray = new UntypedFormArray([
    new UntypedFormControl('Test'),
    new UntypedFormControl('Test 2')
  ]);
}
