import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '@env/environment';
import { GuideSaveChangesDialogComponent } from '@app/screens/guide/guide-shared/components/guide-save-changes-dialog/guide-save-changes-dialog.component';
import {
  RedirectAfterSaveActions,
  canRedirectAfterSave
} from '@app/screens/guide/guide-shared/can-redirect-after-save';
import { PuiDialogService } from '@awarenow/profi-ui-core';
import { AuthService } from '@app/core/auth/services';
import { GuideChangesForNewUsersDialogComponent } from '@app/screens/guide/guide-shared/components/guide-changes-for-new-users-dialog/guide-changes-for-new-users-dialog.component';
import { ADMIN_ROUTE_PATH } from '@app/routes-consts';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { QuizContentEditorComponent } from '../components/quiz-content-editor/quiz-content-editor.component';

@Injectable()
export class CanLeaveEditorGuard {
  private readonly route: string;

  constructor(
    private readonly auth: AuthService,
    private readonly dialog: PuiDialogService,
    private readonly notifications: NotificationsService
  ) {
    this.route = this.auth.isPlatformAdmin() ? `${ADMIN_ROUTE_PATH}/default-form` : `${environment.guideRoute}/surveys`;
  }

  async canDeactivate(
    component: QuizContentEditorComponent,
    _currentRoute: ActivatedRouteSnapshot,
    _currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Promise<boolean> {
    if (component.quizForm.controls.basic.untouched && component.quizForm.controls.questions.untouched) {
      return Promise.resolve(true);
    }

    const result = await canRedirectAfterSave(
      this.dialog,
      this.notifications,
      component,
      nextState,
      this.auth.isPlatformAdmin() ? GuideChangesForNewUsersDialogComponent : GuideSaveChangesDialogComponent
    ).toPromise();

    return !(result === RedirectAfterSaveActions.CANCEL);
  }
}
