<div class="buttons">
  <app-button className="btn-primary" (click)="saveChanges()" i18n="@@sessionTemplateSaveButton">
    Save changes
  </app-button>
</div>

<form [formGroup]="form" class="form" appScrollToFirstInvalid triggers="manual" [offset]="100">
  <section class="section form-section row">
    <div class="col-md-3 offset-md-2 padding-right-md-05">
      <h3 class="section-title" i18n="@@sessionTemplateQuestionnairesTitle">Questionnaires</h3>

      <div class="section-note" i18n="@@sessionTemplateQuestionnairesNote">
        Create a form for clients who book this session.
      </div>
    </div>

    <div class="col-md-5">
      <div class="form-group"></div>

      <div class="form-group"></div>
    </div>
  </section>
</form>
