import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, filter, map, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { NgScrollbar } from 'ngx-scrollbar';
import { INoteCardUpdateContent } from '@app/shared/interfaces/notes';
import { ClientNotesBoardService } from '@app/modules/client-notes/client-notes-board.service';
import { PuiDrawerRef, PUI_DIALOG_DATA, PUI_IS_MOBILE } from '@awarenow/profi-ui-core';
import { ClientNote } from '../../client-notes.type';
import { UntypedFormControl } from '@angular/forms';

export interface DialogData {
  type: 'all-notes' | 'adjacent-notes';
  id?: number; // Only for adjacent-notes
}

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-client-all-notes-board',
  templateUrl: './client-all-notes-board.component.html',
  styleUrls: ['./client-all-notes-board.component.scss'],
  providers: [ClientNotesBoardService]
})
export class ClientAllNotesBoardComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(NgScrollbar, { static: false })
  // @ts-expect-error TS2564
  scrollbar: NgScrollbar;

  private destroy$ = new Subject<void>();

  readonly searchControl = new UntypedFormControl();

  readonly notes$: Observable<ClientNote[]> = this.clientBoard.state$.pipe(
    map(state => state.notes),
    tap(() => {
      console.log('UPDATE');

      // this.scrollbar?.update();
    })
  );

  constructor(
    private readonly clientBoard: ClientNotesBoardService,
    readonly drawerRef: PuiDrawerRef<DialogData>,
    @Inject(PUI_IS_MOBILE) readonly isMobile$: Observable<boolean>
  ) {}

  ngOnInit(): void {
    if (this.drawerRef.data) {
      switch (this.drawerRef.data.type) {
        case 'all-notes':
          this.showAllNotes();

          break;
        case 'adjacent-notes':
          this.showAdjacentNotes(this.drawerRef.data['id'] as number);

          break;
      }
    }
  }

  // @ts-expect-error TS7006
  updateScroll(event): void {
    console.log(1, event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    const search$ = this.searchControl.valueChanges.pipe(debounceTime(600));

    this.scrollbar.scrolled
      .pipe(
        map(event => event.target as HTMLElement),
        filter(element => element.scrollHeight - element.scrollTop - element.clientHeight < 1),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.clientBoard.loadEarliestNotes(null, this.searchControl.value);
      });

    search$.pipe(takeUntil(this.destroy$)).subscribe(search => this.clientBoard.showAllNotes(search));
  }

  onNoteDelete(index: number): void {
    this.clientBoard.deleteNote(index);
  }
  onNoteUpdate({ index, text, content, html }: INoteCardUpdateContent, stopEditing: boolean): void {
    this.clientBoard.updateNote({ index, text, content, html }, stopEditing);
  }

  showAllNotes(): void {
    this.clientBoard.showAllNotes();
  }

  showAdjacentNotes(id: number): void {
    this.clientBoard.showAdjacentNotes(id);
  }

  onNotePin(index: number): void {
    this.clientBoard.pinNote(index);
  }

  onNoteUnpin(index: number): void {
    this.clientBoard.unpinNote(index);
  }
}
