<div class="modal-header">
  <h4 class="modal-title" transloco="uploadPhoto">Upload photo</h4>
  <button type="button" class="btn close-btn" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h3 *ngIf="!fullImage" transloco="selectAreaOfPhoto">Select area of your photo:</h3>
  <h3 *ngIf="fullImage" transloco="selectAreaForThumbnail">Select area for thumbnail:</h3>
  <image-cropper
    #imageCropper
    class="image-cropper"
    output="base64"
    [maintainAspectRatio]="true"
    [roundCropper]="roundCropper"
    [format]="imageFormat"
    [imageQuality]="imageQuality"
    [resizeToWidth]="resizeToWidth"
    [aspectRatio]="aspectRatio"
    [imageChangedEvent]="event"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"></image-cropper>
</div>
<div class="modal-footer">
  <app-button buttonType="button" className="btn-primary btn-md" (click)="save()" i18n="save">Save</app-button>
  <app-button buttonType="button" className="btn-link" (click)="cancel()" i18n="cancel">Cancel</app-button>
</div>
