import { Component, OnInit, TemplateRef } from '@angular/core';
import { FloatingPopoverRef } from './floating-popover-ref';
import { FloatingPopoverContent } from './types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  template: `
    <div>
      <ng-container [ngSwitch]="renderMethod">
        <div *ngSwitchCase="'text'" [innerHTML]="content"></div>

        <ng-container *ngSwitchCase="'template'">
          <ng-container *ngTemplateOutlet="content; context: context"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'component'">
          <ng-container *ngComponentOutlet="content"></ng-container>
        </ng-container>
      </ng-container>
    </div>
  `
})
export class FloatingPopoverComponent implements OnInit {
  renderMethod: 'template' | 'component' | 'text' = 'component';
  // @ts-expect-error TS2564
  content: FloatingPopoverContent;
  // @ts-expect-error TS7008
  context;

  constructor(private _popoverRef: FloatingPopoverRef) {}

  ngOnInit(): void {
    this.content = this._popoverRef.content;

    if (typeof this.content === 'string') {
      this.renderMethod = 'text';
    } else if (this.content instanceof TemplateRef) {
      this.renderMethod = 'template';
      this.context = { close: this._popoverRef.close.bind(this._popoverRef) };
    }
  }
}
