import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GuideRelation } from '@app/core/users/types';
import { GuideClientsService } from '@app/core/users/guide-clients.service';
import { takeUntil } from 'rxjs/operators';
import { GuideRelationInfoModalComponent } from '@app/modules/guide-client/components';

@Injectable()
export class GuideRelationProfileService implements OnDestroy {
  private destroy$ = new Subject<void>();
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _openRelationId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _profileModalInstance: any;

  constructor(private _modal: NgbModal, private _guideClients: GuideClientsService) {}

  ngOnDestroy(): void {
    if (this._profileModalInstance) {
      this._profileModalInstance.modal.close();
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  openProfile(relationLocalId: string | null) {
    if (relationLocalId != null && this._openRelationId !== relationLocalId) {
      this.clean();
      this.findRelation(relationLocalId);
    }
  }

  private clean(): void {
    this.destroy$.next();

    if (this._profileModalInstance) {
      this._profileModalInstance = null;
    }

    // @ts-expect-error TS2322
    this._openRelationId = null;
  }

  private findRelation(relationLocalId: string): void {
    this._guideClients
      .getUser$(relationLocalId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(relation => this.onRelationFound(relation));
  }

  private onRelationFound(relation: GuideRelation): void {
    if (!this._profileModalInstance) {
      this.openProfileModal(relation);
    }

    this._profileModalInstance.relation = relation;
  }

  private async openProfileModal(relation: GuideRelation): Promise<void> {
    try {
      const { componentInstance, result } = this._modal.open(GuideRelationInfoModalComponent, {
        windowClass: 'guide-relation-info-modal'
      });
      this._profileModalInstance = componentInstance;
      componentInstance.relation = relation;
      await result;
      // eslint-disable-next-line id-length
    } catch (e) {}

    this.clean();
  }
}
