import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from '@app/core/auth/services/auth.service';
import { BrandingService } from '@app/core/branding/branding.service';
import { EmailSubscribeService } from '@app/core/public/email-subscribe.service';
import { IMenuConfig, IMenuLink, MenuLinkTypes } from '@app/screens/admin/types';
import { UrlTypes } from '@app/shared/enums/url-types';
import { WidgetModalComponent } from '@app/shared/components/widget-modal/widget-modal.component';
import { getUrlType, ILogoLink, prepareLogoUrlData } from '@app/shared/utils/url';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-common-footer',
  templateUrl: './common-footer.component.html',
  styleUrls: ['./common-footer.component.scss']
})
export class CommonFooterComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  LINK_TYPE = MenuLinkTypes;
  URL_TYPE = UrlTypes;
  // @ts-expect-error TS2564
  emailHelp: string;
  // @ts-expect-error TS2564
  form: UntypedFormGroup;
  // @ts-expect-error TS2564
  logoUrl: ILogoLink;
  // @ts-expect-error TS2564
  mainLogo: string;
  menuConfig: IMenuConfig = {};
  showPoweredBy = false;

  get canShowLanguageSelector(): boolean {
    // @ts-expect-error TS2532
    return this._runtimeConfigService.get('availableLanguages').length > 1;
  }

  get displayPoweredBy(): boolean {
    // @ts-expect-error TS2322
    return this._runtimeConfigService.get('displayPoweredBy');
  }

  constructor(
    private readonly _brandingService: BrandingService,
    private readonly _modal: NgbModal,
    private readonly _emailSubscribeService: EmailSubscribeService,
    private readonly _notifications: NotificationsService,
    private readonly _router: Router,
    private readonly _authService: AuthService,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {}

  ngOnInit(): void {
    this._brandingService.mainLogo$.pipe(takeUntil(this.destroy$)).subscribe(({ logo }) => (this.mainLogo = logo));

    this._brandingService.menuConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => {
      this.menuConfig = config;
      // @ts-expect-error TS2345
      this.menuConfig.footerLinks = this.parseLink(config.footerLinks);
    });

    this._brandingService.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(({ emailHelp, urlInLogo }) => {
      this.logoUrl = prepareLogoUrlData(urlInLogo);
      this.emailHelp = emailHelp;
    });

    this._brandingService.branding$.pipe(takeUntil(this.destroy$)).subscribe(branding => {
      if (branding && branding.isGuideBranding) {
        this.showPoweredBy = true;
      }
    });

    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email])
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private parseLink(links: IMenuLink[]): IMenuLink[] {
    return links.map(link => {
      if (link.children && link.children.length) {
        link.children = this.parseLink(link.children);
      }

      // @ts-expect-error TS2322
      link.urlType = getUrlType(link.url);

      if (link.urlType === UrlTypes.RELATIVE && link.url.charAt(0) !== '/') {
        link.url = '/' + link.url;
      }

      if (link.url.indexOf('?') !== -1 && link.urlType === UrlTypes.RELATIVE) {
        return {
          ...link,
          url: link.url.split('?')[0],
          queryParams: this._router.parseUrl(link.url).queryParams
        };
      }

      return link;
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, id-length
  openWidgetModal(e: Event, url: string) {
    e.preventDefault();
    const modalRef = this._modal.open(WidgetModalComponent, {
      scrollable: false,
      centered: true,
      windowClass: 'widget-modal'
    });
    modalRef.componentInstance.link = url;
    return false;
  }

  submit(): void {
    if (this.form.invalid) {
      return;
    }

    const data: { email?: string } = {};
    data.email = this.form.value.email;
    this._emailSubscribeService
      .sendEmailForSubscribe(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const title = `Success`;
        const message = `You have subscribed`;
        this._notifications.success(title, message);
        this.form.reset();
      });
  }
}
