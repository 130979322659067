import { ChangeDetectionStrategy, Component, Inject, Optional } from '@angular/core';
import { PUI_DRAWER_DATA, PuiDestroyService, PuiDrawerRef } from '@awarenow/profi-ui-core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  CancelData,
  CancelResult
} from '@app/screens/guide/guide-sessions/components/drawers/guide-cancel-drawer/guide-cancel-drawer.types';
import { BehaviorSubject, Subject } from 'rxjs';
import { SimpleSession } from '@app/shared/interfaces/session';
import { map, takeUntil } from 'rxjs/operators';
import { isCancelableStatus, isOfferOrPending } from '@app/shared/enums/session-statuses';
import { CancelEventService } from '@app/screens/guide/guide-sessions/services/events/cancel-event.service';
import { SessionsService } from '@app/core/session/sessions.service';

@Component({
  selector: 'app-guide-cancel-drawer',
  templateUrl: './guide-cancel-drawer.component.html',
  styleUrls: ['./guide-cancel-drawer.component.scss'],
  providers: [PuiDestroyService, CancelEventService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideCancelDrawerComponent {
  private readonly sessionSubject$ = new BehaviorSubject<SimpleSession | null>(null);
  readonly session$ = this.sessionSubject$.asObservable();
  readonly recurring$ = this.session$.pipe(
    map(session => session?.recurringSessionsDetails?.filter(booking => isCancelableStatus(booking.status)).length)
  );
  readonly isOffer$ = this.sessionSubject$
    .asObservable()
    .pipe(map(session => (session ? isOfferOrPending(session?.status) : false)));

  readonly form = new UntypedFormGroup({
    message: new UntypedFormControl('')
  });

  isLoading = false;
  cancelAllRecurring = true;
  constructor(
    @Inject(PuiDestroyService) private readonly destroy$: Subject<void>,
    @Inject(PUI_DRAWER_DATA) private readonly data: CancelData,
    private readonly sessions: SessionsService,
    @Optional() private readonly ref?: PuiDrawerRef<CancelResult>
  ) {
    this.sessionSubject$.next(this.data.session);
    this.cancelAllRecurring = this.data.cancelAllRecurring;
  }

  back(): void {
    this.ref?.close();
  }

  cancel({ message }: { message: string } = this.form.getRawValue()): void {
    const session = this.sessionSubject$.value;
    if (session) {
      this.isLoading = true;
      const cancel$ = this.sessions.refuseSession$(
        session.id,
        message,
        session.collectionType,
        session.clientId,
        session,
        this.cancelAllRecurring
      );

      cancel$.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.ref?.close({ result: true });
      });
    }
  }
}
