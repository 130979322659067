import { Directive, Input, inject } from '@angular/core';
import { ClientView } from '@app/components/clients-select/type';
import { ClientsSelectStore } from '@app/components/clients-select/clients-select.store';
import { ClientVariant, toClientView } from '@app/components/clients-select/mapper';

@Directive({ selector: 'clients-select[clients]', standalone: true })
export class ClientsSelectInputDirective {
  private store = inject(ClientsSelectStore);

  @Input({
    required: true,
    alias: 'clients',
    transform: (clients: ClientVariant[]) => clients.map(toClientView)
  })
  set clients(clients: ClientView[]) {
    this.store.setState(() => ({
      clients,
      selectedClients: []
    }));
  }
}
