import { NotificationsService } from '@awarenow/profi-ui-core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { LocaleService } from '@app/core/locale/locale.service';
import { IBranding, IGuideBranding, IMenuConfig, IOtherPaymentOptions } from '@app/screens/admin/types';
import { BRANDING_ENDPOINT, GUIDE_BRANDING_ENDPOINT } from '@app/shared/constants/endpoints';
import { IQualification } from '@app/shared/interfaces/qualification';
import { GlobalConfig } from '@cnf/types';
import { ICommunityConfig } from '@cnf/types/community-config';
import { getCommunityConfigBase, getConfigBase } from '@cnf/utils';
import { ILocale } from '@env/locale.interface';
import { LOCATION_ORIGIN } from '../../../consts';

export const defaultMainLogo = `${LOCATION_ORIGIN}/assets/images/branding/platform-logo.svg`;
const defaultWhiteLogo = `${LOCATION_ORIGIN}/assets/images/branding/platform-white-logo.svg`;

// eslint-disable-next-line @typescript-eslint/naming-convention
interface ILogo {
  logo: string;
  isDefault: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class BrandingService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _branding$: ReplaySubject<IBranding> = new ReplaySubject<IBranding>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _communityConfig$: ReplaySubject<ICommunityConfig> = new ReplaySubject<ICommunityConfig>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _globalConfig$: ReplaySubject<GlobalConfig> = new ReplaySubject<GlobalConfig>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _language$: ReplaySubject<string> = new ReplaySubject<string>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _mainLogo$: ReplaySubject<ILogo> = new ReplaySubject<ILogo>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _menuConfig$: ReplaySubject<IMenuConfig> = new ReplaySubject<IMenuConfig>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _otherPaymentOptions$: ReplaySubject<IOtherPaymentOptions> = new ReplaySubject<IOtherPaymentOptions>(
    1
  );

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _qualifications$: ReplaySubject<IQualification[]> = new ReplaySubject<IQualification[]>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _whiteLogo$: ReplaySubject<ILogo> = new ReplaySubject<ILogo>(1);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly faviconEl: any;

  private readonly isBrowser;

  private locale: ILocale;

  get branding$(): Observable<IBranding> {
    return this._branding$.asObservable();
  }

  get mainLogo$(): Observable<ILogo> {
    return this._mainLogo$.asObservable();
  }

  get whiteLogo$(): Observable<ILogo> {
    return this._whiteLogo$.asObservable();
  }

  get otherPaymentOptions$(): Observable<IOtherPaymentOptions> {
    return this._otherPaymentOptions$.asObservable();
  }

  get qualifications$(): Observable<IQualification[]> {
    return this._qualifications$.asObservable();
  }

  get menuConfig$(): Observable<IMenuConfig> {
    return this._menuConfig$.asObservable();
  }

  get communityConfig$(): Observable<ICommunityConfig> {
    return this._communityConfig$.asObservable();
  }

  get globalConfig$(): Observable<GlobalConfig> {
    return this._globalConfig$.asObservable();
  }

  get language$(): Observable<string> {
    return this._language$.asObservable();
  }

  constructor(
    private _http: HttpClient,
    private _notifications: NotificationsService,
    private _localeService: LocaleService,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(PLATFORM_ID) private platform: any,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.isBrowser = isPlatformBrowser(this.platform);
    if (this.isBrowser) {
      this.faviconEl = this._document.getElementById('favicon');
    }
    this.locale = this._localeService.getLocale();
  }

  resetMainLogo(): void {
    // @ts-expect-error TS2345
    this.updateMainLogo(null);
  }

  resetWhiteLogo(): void {
    // @ts-expect-error TS2345
    this.updateWhiteLogo(null);
  }

  updateMainLogo(url: string): void {
    const logo: ILogo = url
      ? {
          logo: url,
          isDefault: false
        }
      : {
          logo: '',
          isDefault: true
        };
    this._mainLogo$.next(logo);
  }

  updateOtherPaymentOptions(paymentOptions: IOtherPaymentOptions): void {
    this._otherPaymentOptions$.next(paymentOptions);
  }

  updateWhiteLogo(url: string): void {
    const logo: ILogo = url
      ? {
          logo: url,
          isDefault: false
        }
      : {
          logo: defaultWhiteLogo,
          isDefault: true
        };
    this._whiteLogo$.next(logo);
  }

  updateGlobalConfig(globalConfig: GlobalConfig): void {
    const config = getConfigBase();
    if (globalConfig) {
      Object.entries(config).forEach(([key, value]) => {
        // @ts-expect-error TS7053
        config[key] = globalConfig[key] ?? value;
      });
    }
    this._globalConfig$.next(config);
  }

  updateQualifications(qualifications: IQualification[]): void {
    this._qualifications$.next(qualifications);
  }

  updateMenuConfig(menuConfig: IMenuConfig): void {
    this._menuConfig$.next(menuConfig);
  }

  updateCommunityConfig(communityConfig: ICommunityConfig): void {
    const config = getCommunityConfigBase();
    if (communityConfig && communityConfig.hasOwnProperty) {
      Object.entries(config).forEach(([key, value]) => {
        // @ts-expect-error TS7053
        config[key] = communityConfig.hasOwnProperty(key) ? communityConfig[key] : value;
      });
    }
    this._communityConfig$.next(config);
  }

  updateLanguage(language: string): void {
    this._language$.next(language);
  }

  // @ts-expect-error TS7006
  getGuideBranding(guideId): Observable<{ branding: IGuideBranding }> {
    return this._http.get<{ branding: IGuideBranding }>(`${GUIDE_BRANDING_ENDPOINT}/${guideId}`).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  loadBranding(language = this.locale.language): void {
    this._http.get<{ branding: IBranding }>(BRANDING_ENDPOINT, { params: { language } }).subscribe(({ branding }) => {
      this._branding$.next(branding);
      this.updateCommunityConfig(branding.communityConfig);
      this.updateGlobalConfig(branding.globalConfig);
      this.updateLanguage(branding.language);
      this.updateMainLogo(branding.mainLogo);
      this.updateMenuConfig(branding.menuConfig);
      this.updateOtherPaymentOptions(branding.paymentOptions || {});
      this.updateQualifications(branding.qualifications || []);
      this.updateWhiteLogo(branding.whiteLogo);

      if (branding.favicon && this.faviconEl) {
        this.faviconEl.setAttribute('href', branding.favicon);
      }
    });
  }
}
